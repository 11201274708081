import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';

const Portal: FunctionComponent = ({ children }) =>
  ReactDOM.createPortal(
    <div>{children}</div>,
    document.getElementById('modal-root') as HTMLElement,
  );

export default Portal;
