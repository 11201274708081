import React, { FunctionComponent, useEffect, useRef } from 'react';

import './Dropdown.scss';

const Dropdown: FunctionComponent<IDropdownProps> = ({
  children,
  onClose,
  open,
}) => {
  const node = useRef(document.createElement('ul'));

  useEffect(() => {
    const onOutsideClick = (e: MouseEvent) => {
      if (
        node.current &&
        (node.current.offsetParent as Node)?.contains(e.target as Node)
      ) {
        return;
      }

      onClose();
    };

    if (open && onClose) {
      document.addEventListener('mousedown', onOutsideClick);
    }

    return () => {
      if (open && onClose) {
        document.removeEventListener('mousedown', onOutsideClick);
      }
    };
  }, [open, onClose]);

  return (
    <>
      {open && (
        <ul ref={node} className="dropdown-menu">
          {children}
        </ul>
      )}
    </>
  );
};

interface IDropdownProps {
  onClose: () => void;
  open: boolean;
}

export default Dropdown;
