import React, { FunctionComponent } from 'react';

import x from '../../assets/X.svg';

import './Panel.scss';

interface IPanelProps {
  isOpen: boolean;
  closePanel: () => void;
  title: string;
}

const Panel: FunctionComponent<IPanelProps> = ({
  isOpen,
  title,
  closePanel,
  children,
}) => {
  return (
    <div className={`panel-wrapper ${isOpen ? 'panel-open' : ''}`}>
      <div className="panel-header">
        <div className="panel-title">{title}</div>
        <div className="img-container" onClick={closePanel}>
          <img src={x} alt="close" className="close-x" />
        </div>
      </div>
      <div className="panel-body">{children}</div>
    </div>
  );
};

export default Panel;
