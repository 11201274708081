import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useResourceContext } from '../../../common/resource-context';
import { CheckIsMultiClientAdmin } from '../../../common/utilities/_authHelpers';
import { applyRSMClassName } from '../../../common/utilities/_helpers';
import { IPlanModel } from '../../../interfaces';
import { IStore } from '../../../store';
import { findAvailablePlansToUpgrade } from '../../plans/helpers';
import { getFeatureList } from '../helpers/helpers';
import Counter from './components/Counter';
import FeatureList from './components/FeatureList';

import './PlanCard.scss';

enum ArrowClickDirection {
  left,
  right,
}

export interface PlanCardProps {
  selectedPlan: IPlanModel;
  setSelectedPlan: (selectedPlan: IPlanModel) => void;
  children?: ReactNode;
}

const defaultDisabledState = { left: true, right: false };

export default function PlanCard({
  selectedPlan,
  setSelectedPlan,
  children,
}: PlanCardProps) {
  const resources = useResourceContext();

  const currentUser = useSelector((state: IStore) => state.user);

  const isMultiClientAdmin = CheckIsMultiClientAdmin(currentUser);

  const currentPlan: IPlanModel = useSelector((store: IStore) => {
    return isMultiClientAdmin
      ? store.currentAdminPlan
      : store.user.company.plan!;
  });

  const allPlans: IPlanModel[] = useSelector((store: IStore) => {
    return isMultiClientAdmin ? store.multiClientPlans : store.plans;
  });
  const [planToDisplay, setPlanToDisplay] = useState<IPlanModel>(selectedPlan);
  const [availablePlans, setAvailablePlans] = useState<IPlanModel[]>();
  const [disabledState, setDisabledState] = useState(defaultDisabledState);
  const [subHeaders, setSubHeaders] = useState<IPlanModel[] | undefined>();

  useEffect(() => {
    const sortedPlans = findAvailablePlansToUpgrade(allPlans, currentPlan);
    setSubHeaders(sortedPlans);
    if (sortedPlans) {
      const indexOfSelectedPlan = sortedPlans?.findIndex(
        (plan) => plan.id === selectedPlan?.id,
      );
      if (selectedPlan?.id) {
        const updatedDisabledState = {
          left: indexOfSelectedPlan === 0 ?? sortedPlans.length < 1,
          right: indexOfSelectedPlan === sortedPlans.length - 1,
        };
        setDisabledState(updatedDisabledState);
      }
      if (indexOfSelectedPlan === -1) {
        setPlanToDisplay(sortedPlans[0]);
        setSelectedPlan(sortedPlans[0]);
      } else {
        setPlanToDisplay(selectedPlan);
        setSelectedPlan(selectedPlan);
      }

      setAvailablePlans(sortedPlans);
    }
  }, [
    allPlans,
    currentPlan,
    isMultiClientAdmin,
    selectedPlan,
    setSelectedPlan,
  ]);

  const onArrowClick = (direction: ArrowClickDirection): void => {
    const indexOfCurrentPlan = availablePlans!.findIndex(
      (plan) => plan.id === planToDisplay!.id,
    );

    const nextPlan =
      direction === ArrowClickDirection.right
        ? availablePlans?.[indexOfCurrentPlan + 1]
        : availablePlans?.[indexOfCurrentPlan - 1];

    const nextPlanIndex =
      direction === ArrowClickDirection.right
        ? indexOfCurrentPlan + 1
        : indexOfCurrentPlan - 1;

    if (nextPlan) {
      setPlanToDisplay(nextPlan);
      setSelectedPlan(nextPlan);
    }
    const disableRight = !availablePlans?.[nextPlanIndex + 1];
    const disableLeft = !availablePlans?.[nextPlanIndex - 1];

    const updatedState = { left: disableLeft, right: disableRight };
    setDisabledState(updatedState);
  };

  if (!planToDisplay) {
    return null;
  }

  const generateCurrentRange = () => {
    if (isMultiClientAdmin) {
      const clientCount = planToDisplay.features.find(
        (f) => (f.name = 'Client Count'),
      )?.attributes[0].values[0];
      return clientCount ?? '';
    }
    return resources.text(`${planToDisplay.displayName}_LeaseCountRange`);
  };

  return (
    <div className={applyRSMClassName('card-body')}>
      <span className="card-heading">
        {isMultiClientAdmin ? 'Full Multi-Company Plan' : 'Premium'}
      </span>
      {!isMultiClientAdmin && (
        <div className="card-sub-heading">
          Plans at {subHeaders?.map((p) => `$${p.price}`).join(' / ')}
        </div>
      )}

      <Counter
        onArrowLeft={() => onArrowClick(ArrowClickDirection.left)}
        onArrowRight={() => onArrowClick(ArrowClickDirection.right)}
        price={`$${planToDisplay.price}`}
        priceIncrement="/ yr"
        currentRange={generateCurrentRange()}
        rangeTitle={
          isMultiClientAdmin
            ? generateCurrentRange() === '1'
              ? 'Company'
              : 'Companies'
            : 'Leases'
        }
        disabled={disabledState}
        isCPAUpgrade={isMultiClientAdmin}
      />
      <div className="divider" />
      <FeatureList featureList={getFeatureList(isMultiClientAdmin)} />
      {children}
    </div>
  );
}
