import React, { FunctionComponent } from 'react';

import Modal from '../modal/Modal';

interface IConfirmationModalProps {
  open: boolean;
  hideModal: () => void;
  confirmAction: () => void;
  confirmText: string | JSX.Element;
  buttonText: string;
  title?: string;
  areYouSureText?: boolean;
}

const ConfirmationModal: FunctionComponent<IConfirmationModalProps> = ({
  open,
  hideModal,
  confirmAction,
  confirmText,
  buttonText,
  title,
  areYouSureText = true,
}) => (
  <Modal
    open={open}
    modalSize="small"
    onConfirm={confirmAction}
    hideModal={hideModal}
    negativeButtonText={buttonText}
    neutralButtonText="Cancel"
    title={title || ' '}
  >
    <div style={{ textAlign: 'center' }}>
      <p className="medium-darker-text">{confirmText}</p>
      {areYouSureText && <p className="medium-darker-text">Are you sure?</p>}
    </div>
  </Modal>
);

export default ConfirmationModal;
