import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionSettings } from '../../api';
import discover from '../../assets/14.png';
import mastercard from '../../assets/2.png';
import americanexpress from '../../assets/22.png';
import visa from '../../assets/visa-blue.svg';
import { Currency, Divider } from '../../common';
import FormError from '../../common/form-error/FormError';
import GuruLogoSpinner from '../../common/loaders/GuruLogoSpinner';
import { showModal } from '../../common/modal/actions';
import Modal from '../../common/modal/Modal';
import { useResourceContext } from '../../common/resource-context';
import { CheckIsMultiClientAdmin } from '../../common/utilities/_authHelpers';
import { useFeatureFlags } from '../../featureQuery';
import {
  FeatureFlagKeys,
  IPlanModel,
  ISubscriptionSettings,
  PlanTier,
} from '../../interfaces';
import { IStore } from '../../store';
import { findNextAvailableUpgrade } from '../plans/helpers';
import { SubscriptionModalPages } from './SubscriptionModal';

import './SubscriptionSettingsModal.scss';

interface ISubscriptionSettingsModalProps {
  open: boolean;
  hideModal: () => void;
}

const SubscriptionSettingsModal: React.FC<ISubscriptionSettingsModalProps> = ({
  hideModal,
}) => {
  const resources = useResourceContext();
  const dispatch = useDispatch();

  const currentUser = useSelector((state: IStore) => state.user);

  const isMultiClientAdmin = CheckIsMultiClientAdmin(currentUser);

  const planDetails: IPlanModel | undefined = useSelector((state: IStore) => {
    return isMultiClientAdmin
      ? state.currentAdminPlan
      : state.user.company.plan!;
  });

  const plans: IPlanModel[] = useSelector((store: IStore) => {
    return isMultiClientAdmin ? store.multiClientPlans : store.plans;
  });

  const { getSingleFlag } = useFeatureFlags();
  const guruPlanPricingFlag = getSingleFlag(FeatureFlagKeys.PlansAndPricing);

  const companySubscriptionInfoDate: any = useSelector((state: IStore) => {
    return state.user.company?.trialEndDate;
  });
  const [subscriptionSettings, setSubscriptionSettings] = useState<
    ISubscriptionSettings
  >();
  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const planTier = planDetails?.tier;

  useEffect(() => {
    const getCompanySubscriptionSettings = async () => {
      try {
        setShowLoadingSpinner(true);
        const companySubscriptionSettings: ISubscriptionSettings = await getSubscriptionSettings();
        setSubscriptionSettings(companySubscriptionSettings);
        setShowLoadingSpinner(false);
      } catch (error) {
        setShowLoadingSpinner(false);
        setErrorMessage(
          'Sorry, there was a problem retrieving your subscription information',
        );
      }
    };

    if (planTier) {
      getCompanySubscriptionSettings();
    }
  }, [planTier]);

  const showTierPageModal = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'upgrade_subscription_cta',
        cta_location: 'Company Settings Dropdown > Subscription Settings',
        cta_creative: 'Upgrade Plan Button',
      },
    });
    dispatch(
      showModal({
        modal: {
          modalType: 'SUBSCRIPTIONS',
          modalProps: {
            open: true,
            hideModal,
          },
        },
      }),
    );
  };

  const showUpdatePaymentModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'UPDATE_PAYMENT',
          modalProps: {
            open: true,
            hideModal,
            context: subscriptionSettings,
          },
        },
      }),
    );
  };

  const availablePlanToUpgradeTo = findNextAvailableUpgrade(
    plans,
    planDetails!,
  );

  const showCheckoutModal = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'upgrade_subscription_cta',
        cta_location: 'Company Settings Dropdown > Subscription Settings',
        cta_creative: 'Upgrade Plan Button',
      },
    });
    dispatch(
      showModal({
        modal: {
          modalType: 'SUBSCRIPTIONS',
          modalProps: {
            open: true,
            hideModal,
            initialSubscriptionModalPage: SubscriptionModalPages.payment,
            initialPlanFeatures: availablePlanToUpgradeTo?.features,
            initialSelectedPlan: availablePlanToUpgradeTo,
          },
        },
      }),
    );
  };

  const determineCardType = () => {
    const brand = subscriptionSettings?.paymentMethod?.card.brand;

    switch (brand) {
      case 'visa':
        return <img src={visa} alt="card" className="payment-logo" />;
      case 'mastercard':
        return <img src={mastercard} alt="card" className="payment-logo" />;
      case 'discover':
        return <img src={discover} alt="card" className="payment-logo" />;
      case 'amex':
        return (
          <img src={americanexpress} alt="card" className="payment-logo" />
        );
      default:
        return '';
    }
  };

  const getTrialDaysLeft = () => {
    const date: any = new Date();
    const trialDate: any = new Date(companySubscriptionInfoDate);
    const difference = trialDate - date;
    const days = difference / (1000 * 3600 * 24);
    return days.toFixed(0);
  };

  const buildSubscriptionText = () => {
    if (isMultiClientAdmin && planTier === PlanTier.PREMIUM) {
      return availablePlanToUpgradeTo
        ? resources.text('LimitedMultiClientText')
        : resources.text('FullMultiClientText');
    }

    return planTier === PlanTier.BASIC
      ? 'Basic Plan'
      : planTier === PlanTier.TRIAL
      ? 'Trial Plan'
      : 'Premium Plan';
  };

  const pricingRoute = isMultiClientAdmin
    ? '/multicompany-pricing'
    : '/pricing';

  return (
    <Modal
      title="Subscription Settings"
      open={true}
      modalSize="medium"
      hideModal={hideModal}
      neutralButtonText="Close"
    >
      <div className="settings-content">
        <div className="contact-row">
          <div className="info-text">
            For subscription questions, please contact{' '}
            <a
              className="info-email"
              href={resources.text(
                'General_Email_MailTo',
                'mailto:info@leaseguru.com',
              )}
            >
              {resources.text('General_Email_Address', 'info@leaseguru.com')}
            </a>
          </div>
          {guruPlanPricingFlag && (
            <a className="info-email pricing-link" href={pricingRoute}>
              View Plans and Pricing
            </a>
          )}
        </div>
        <Divider />
        <div className="settings-row">
          <label className="section-subscription-label">
            Current Subscription
          </label>
          <label className="section-value">{buildSubscriptionText()}</label>
          {guruPlanPricingFlag ? (
            availablePlanToUpgradeTo ? (
              <button
                className="upgrade button"
                type="button"
                onClick={showCheckoutModal}
              >
                Upgrade Plan
              </button>
            ) : (
              <a className="upgrade" type="button" href={pricingRoute}>
                View Plan Details
              </a>
            )
          ) : availablePlanToUpgradeTo ? (
            <button
              className="upgrade button"
              type="button"
              onClick={showTierPageModal}
            >
              Upgrade Plan
            </button>
          ) : (
            <button
              className="action link-text button-link upgrade"
              type="button"
              onClick={showTierPageModal}
            >
              View Plan Details
            </button>
          )}
        </div>
        {planTier === PlanTier.TRIAL ? (
          <>
            <Divider />
            <div className="settings-row">
              <label className="section-subscription-label">Trial Ends</label>
              <label className="section-value">
                {companySubscriptionInfoDate}
              </label>
              <label className="section-value-trial-days">
                {getTrialDaysLeft() + ' days left'}
              </label>
            </div>
          </>
        ) : null}
        {planTier === PlanTier.PREMIUM || PlanTier.Legacy ? (
          showLoadingSpinner ? (
            <div className="loading-block">
              <Divider />
              <GuruLogoSpinner inProgress />
              <span>Loading payment information...</span>
            </div>
          ) : errorMessage ? (
            <div>
              <Divider />
              <FormError error={!!errorMessage} errorMessage={errorMessage} />
            </div>
          ) : (
            <div className="premium-settings">
              <Divider />
              <div className="settings-row">
                <label className="section-subscription-label">
                  Next Payment Date of
                  <span className="amount">
                    $
                    <Currency
                      value={
                        planDetails && planDetails.price ? planDetails.price : 0
                      }
                    />
                  </span>
                </label>
                <label className="section-value">
                  {subscriptionSettings?.paymentDate}
                </label>
              </div>
              <Divider />
              <div className="settings-row">
                <label className="section-subscription-label">
                  Current Payment Method
                </label>
                <label className="section-value">
                  {determineCardType()}
                  {subscriptionSettings?.paymentMethod?.card.last4 ? (
                    '... '
                  ) : (
                    <label className="no-card">No card on file</label>
                  )}
                  {subscriptionSettings?.paymentMethod?.card.last4}
                </label>
                <button
                  className="action link-text button-link upgrade"
                  type="button"
                  onClick={showUpdatePaymentModal}
                >
                  Update Payment
                </button>
              </div>
            </div>
          )
        ) : null}
      </div>
    </Modal>
  );
};
export default SubscriptionSettingsModal;
