import {
  IPlanFeatureModel,
  IPlanModel,
  IStaticTierFeature,
  IUserModel,
  PlanFeatureNames,
  PlanTier,
} from '../../interfaces';

function sortPlansByCheapest(plans: IPlanModel[]) {
  return [...plans].sort((planA, planB) =>
    planA.price < planB.price ? -1 : 1,
  );
}

export function sortPlansByMostExpensive(plans: IPlanModel[]) {
  return [...plans].sort((planA, planB) =>
    planA.price < planB.price ? 1 : -1,
  );
}

export function getMaxLeasePlan(plans: IPlanModel[]) {
  return [...plans].sort((planA, planB) => {
    const planALeaseCount = Number(
      getFeatureValue(PlanFeatureNames.leaseCount, planA.features),
    );
    const planBLeaseCount = Number(
      getFeatureValue(PlanFeatureNames.leaseCount, planB.features),
    );

    if (planALeaseCount && planBLeaseCount) {
      return planALeaseCount < planBLeaseCount ? 1 : -1;
    } else {
      return 0;
    }
  })[0];
}

export function getPlanByNumberOfLeases(
  plans: IPlanModel[],
  numberOfLeasesNeeded: number,
  tierToCheck: PlanTier,
) {
  const planNeeded = [...plans]
    // start with the cheapest plan
    .sort((planA, planB) => (planA.price < planB.price ? -1 : 1))
    .find((plan) => {
      const leaseCount = Number(
        getFeatureValue(PlanFeatureNames.leaseCount, plan.features),
      );

      if (
        leaseCount &&
        leaseCount >= numberOfLeasesNeeded &&
        plan.tier === tierToCheck
      ) {
        return plan;
      }

      return undefined;
    });

  return planNeeded;
}

function getPlansByTier(tier: PlanTier, plans: IPlanModel[]) {
  return plans.filter((plan) => plan.tier === tier);
}

export function getPlanTierPriceRange(plans: IPlanModel[], tier: PlanTier) {
  const currentTierPlans = getPlansByTier(tier, plans);

  const lowestPricePlan = sortPlansByCheapest(currentTierPlans)[0];
  const highestPricePlan = sortPlansByMostExpensive(currentTierPlans)[0];

  if (lowestPricePlan && highestPricePlan) {
    // if prices are the same, just show a single price
    if (lowestPricePlan.price === highestPricePlan.price) {
      // show free if $0
      const lowestPriceString =
        lowestPricePlan.price === 0 ? 'Free' : lowestPricePlan.price.toString();

      return lowestPriceString;
    } else {
      // if prices are different, show the price range
      return `$${lowestPricePlan.price} - $${highestPricePlan.price} annually`;
    }
  } else {
    return '';
  }
}

export function getMostExpensivePlanByType(
  planTierToGet: PlanTier,
  plans: IPlanModel[],
): IPlanModel {
  const plansByType = getPlansByTier(planTierToGet, plans);

  const maxPlan = sortPlansByMostExpensive(plansByType)[0];

  return maxPlan;
}

export function getTierFeatureList(
  planTypeToGet: PlanTier,
  plans: IPlanModel[],
): IPlanFeatureModel[] {
  let featureList: IPlanFeatureModel[] = [];

  const tierPlans = getPlansByTier(planTypeToGet, plans);

  const sortedTierPlans = sortPlansByMostExpensive(tierPlans);

  sortedTierPlans.forEach((tierPlan) => {
    // add unique features to featureList
    featureList = mergeUniqueFeatures(tierPlan, featureList);
  });

  return featureList.filter((f) => !f.isNewPlanPage);
}

// this could probably be more efficient but i have to move on
// try utilizing a new Set in the future?
function mergeUniqueFeatures(
  plan: IPlanModel,
  uniqueFeatureList: IPlanFeatureModel[],
) {
  return plan.features.reduce(
    (newFeatureList: IPlanFeatureModel[], currentFeature) => {
      const featureIsUnique =
        uniqueFeatureList.findIndex(
          (uniqueFeature) => uniqueFeature.id === currentFeature.id,
        ) === -1;

      // if id doesn't exist in featureList, add it
      if (featureIsUnique && currentFeature.isActive) {
        return [...newFeatureList, currentFeature];
      }

      return newFeatureList;
    },
    uniqueFeatureList,
  );
}

export function checkIfUserIsOnMaxPlan(user: IUserModel, plans: IPlanModel[]) {
  const premiumOnlyPlans = getPlansByTier(PlanTier.PREMIUM, plans);

  const mostExpensivePlan = sortPlansByMostExpensive(premiumOnlyPlans)[0];

  // TODO: GURU PAYMENTS. possible changes to this once API pushes change to names or implements unique ids
  const userPlanName = user.company.plan?.name;
  const mostExpensivePlanName = mostExpensivePlan?.name;

  return userPlanName === mostExpensivePlanName;
}

export function checkIfUserHasAccessToFeature(
  featureToCheck: string,
  features: IPlanFeatureModel[] | undefined,
  userIsAdmin?: boolean,
): boolean {
  if (userIsAdmin) {
    return true;
  }

  if (!features) {
    return false;
  }

  const activeFeatures = filterFeaturesByActive(features);

  const userHasAccess = featureNameExists(activeFeatures, featureToCheck);

  return userHasAccess;
}

export function convertStaticFeatureListToPlanFeatures(
  staticFeatureList: IStaticTierFeature[],
): IPlanFeatureModel[] {
  return staticFeatureList
    .map((feature: IStaticTierFeature) => {
      return {
        id: feature.id,
        name: feature.featureDescription,
        description: '',
        isActive: true,
        attributes: [],
        orderNumber: feature.orderNumber,
        groupNumber: feature.groupNumber,
        isNewPlanPage: feature.isNewPlanPage,
      };
    })
    .sort((a, b) => (a.id < b.id ? -1 : 1));
}

export function filterFeaturesByActive(features: IPlanFeatureModel[]) {
  return features.filter((feature) => feature.isActive === true);
}

export function filterPlansByActive(plans: IPlanModel[]): IPlanModel[] {
  return plans.filter((plan) => plan.isActive === true);
}

export function checkIfUserHasAccessToAnyFeature(
  featuresToCheck: Array<PlanFeatureNames | string>,
  features: IPlanFeatureModel[] | undefined,
  userIsAdmin?: boolean,
): boolean {
  if (userIsAdmin) {
    return true;
  }

  if (!features) {
    return false;
  }

  const activeFeatures = filterFeaturesByActive(features);

  const userHasAccess = featuresToCheck.some((featureToCheck: string) =>
    featureNameExists(activeFeatures, featureToCheck),
  );

  return userHasAccess;
}

function featureNameExists(
  activeFeatures: IPlanFeatureModel[],
  featureName: PlanFeatureNames | string,
) {
  return (
    activeFeatures.findIndex((feature) => feature.name === featureName) !== -1
  );
}

export function getFeatureValue(
  featureName: PlanFeatureNames | string,
  features: IPlanFeatureModel[],
): string | null {
  const pickedFeature: IPlanFeatureModel | undefined = features?.find(
    (feature: IPlanFeatureModel) => feature.name === featureName,
  );

  if (pickedFeature) {
    return pickedFeature.attributes[0].values[0];
  }

  return null;
}

// TODO: GURU PAYMENTS. if we add back in juridiction as a feature
// function getFeatureListValue(
//   featureName: PlanFeatureNames | string,
//   features: IPlanFeatureModel[],
// ): string[] | null {
//   const pickedFeature: IPlanFeatureModel | undefined = features.find(
//     (feature: IPlanFeatureModel) => feature.name === featureName,
//   );

//   if (pickedFeature) {
//     return pickedFeature.attributes[0].values;
//   }

//   return null;
// }

export function getFeatureDisplayText(feature: IPlanFeatureModel) {
  if (feature.name === PlanFeatureNames.leaseCount) {
    const maxLeaseCount = getFeatureValue(PlanFeatureNames.leaseCount, [
      feature,
    ]);

    return Number(maxLeaseCount) === 1
      ? '1 lease'
      : `Up to ${maxLeaseCount} leases`;
  }

  if (feature.name === PlanFeatureNames.users) {
    const maxUserCount = getFeatureValue(PlanFeatureNames.users, [feature]);

    return Number(maxUserCount) === 1
      ? '1 Contributing User'
      : `${maxUserCount} Contributing Users`;
  }
  if (feature.name === PlanFeatureNames.guest) {
    const maxUserCount = getFeatureValue(PlanFeatureNames.guest, [feature]);

    return Number(maxUserCount) === 1
      ? '1 Guest (read-only) User'
      : `${maxUserCount} Guest (read-only) Users`;
  }

  return feature.name;
}

export function findAvailablePlansToUpgrade(
  plans: IPlanModel[],
  currentPlan: IPlanModel,
): IPlanModel[] | undefined {
  const premiumOnlyPlans = getPlansByTier(PlanTier.PREMIUM, plans);

  const sortedByCheapest = sortPlansByCheapest(premiumOnlyPlans);

  const indexOfCurrentPlan = sortedByCheapest.findIndex(
    (plan) => plan.id === currentPlan.id,
  );

  const sortedPlans = sortedByCheapest.slice(indexOfCurrentPlan + 1);

  return sortedPlans;
}

export function findNextAvailableUpgrade(
  plans: IPlanModel[],
  currentPlan: IPlanModel,
): IPlanModel | undefined {
  const sortedPlans = findAvailablePlansToUpgrade(plans, currentPlan);

  if (!sortedPlans) {
    // returns undefined if user is on highest available plan
    return undefined;
  }

  // if there is only a single plan the user can upgrade to, return that one
  return sortedPlans[0];
}

export function checkIfUserIsOnMaxCPAPlan(
  currentPlan: IPlanModel | undefined,
  leaseCount: number,
  maxLeases: number,
) {
  return (
    currentPlan?.displayName.startsWith('Multi Company') &&
    leaseCount === maxLeases &&
    currentPlan !== undefined &&
    currentPlan.tier !== 'Basic'
  );
}
