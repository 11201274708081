import React, { FunctionComponent } from 'react';
import MaskedInput from 'react-text-mask';

// import calendarIcon from '../../assets/calendar-icon.svg';

import './Datepicker.scss';

interface IDatepickerInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  id?: string;
  placeholderText?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  withPortal?: boolean;
  fullWidth?: boolean;
  preventOverflow?: boolean;
}

const DatepickerInput: FunctionComponent<IDatepickerInputProps> = ({
  onChange,
  name,
  id,
  placeholderText,
  label,
  required,
  disabled,
  error,
  errorMessage,
  value,
}) => {
  return (
    <div className={`datepicker-wrapper`}>
      {label && (
        <label
          htmlFor={id || name}
          className={`datepicker-label ${disabled ? 'disabled' : ''} ${
            required && !disabled ? 'required' : ''
          }`}
        >
          {label}
        </label>
      )}
      <div className="datepicker-input-wrapper">
        <>
          <MaskedInput
            className={`datepicker-input ${error ? 'error' : ''}`}
            onChange={onChange}
            value={value}
            placeholder={placeholderText}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            name={name}
            id={id || name}
            disabled={disabled}
          />
        </>
      </div>
      {error && (
        <span className="form-input-error-message">
          {errorMessage || 'There is an issue with this field'}
        </span>
      )}
    </div>
  );
};

export default DatepickerInput;
