import React, { FunctionComponent } from 'react';

interface IAddDetailsGroupProps {
  header: string;
}

const AddDetailsGroup: FunctionComponent<IAddDetailsGroupProps> = ({
  header,
  children,
}) => (
  <div className="add-group-wrapper">
    <div className="add-header">{header}</div>
    {children}
  </div>
);

export default AddDetailsGroup;
