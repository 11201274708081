import React, { FunctionComponent } from 'react';
import TagManager from 'react-gtm-module';
import { useResourceContext } from '../../../common/resource-context';
import { IPlanModel } from '../../../interfaces';
import {
  isCurrentPlan,
  mapPlanDisplayNameToPlanSubTier,
  mapPlanNameToDisplayName,
} from '../helpers/helpers';
import './PlanHeader.scss';
import './PremiumPlanHeader.scss';

interface IPremiumPlanHeaderProps {
  planModels: IPlanModel[];
  userCurrentPlan?: IPlanModel;
  checkOut: (planName: string) => void;
}

const PremiumPlanHeader: FunctionComponent<IPremiumPlanHeaderProps> = ({
  planModels,
  userCurrentPlan,
  checkOut,
}) => {
  const resources = useResourceContext();
  const currentPlanPrice = userCurrentPlan ? userCurrentPlan.price : 0;
  const getPlanPrice = (planSubTier: string): number => {
    const plan = planModels.find((p) => p.name.endsWith(planSubTier));
    return plan !== undefined ? plan.price : 0;
  };

  const getPlanLeaseRange = (displayName: string) => {
    return `${resources.text(`${displayName}_LeaseCountRange`)} Leases`;
  };

  const checkOutHandler = (planName: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'upgrade_subscription_cta',
        cta_location: 'In-Product Plans and Pricing Screen',
        cta_creative: `Buy Now - ${getPlanLeaseRange(
          mapPlanNameToDisplayName(planName),
        )} Premium`,
      },
    });

    const isCurrent: boolean = isCurrentPlan(
      planName,
      planModels,
      userCurrentPlan,
    );
    if (!isCurrent) {
      checkOut(planName);
    }
  };

  const isRSM = process.env.REACT_APP_ENABLE_RSM === 'true';

  const displayCheckOutButton = (planName: string) => {
    const planPrice = getPlanPrice(planName);
    const isCurrent: boolean = isCurrentPlan(
      planName,
      planModels,
      userCurrentPlan,
    );
    return planPrice >= currentPlanPrice ? (
      <div className={'button ' + (isCurrent && ' disable ')}>
        <button
          type="button"
          disabled={isCurrent}
          onClick={() => {
            checkOutHandler(planName);
          }}
        >
          <span>{isCurrent ? 'Current' : 'BUY NOW'}</span>
        </button>
      </div>
    ) : null;
  };
  return (
    <div className={`${isRSM && 'rsm'}`}>
      <div className="plan-wrapper">
        <div className="card-title">Premium</div>
        <div className="card-content">
          {planModels.map((p, i) => (
            <div key={i} className="card">
              <div className="lease-count">
                {getPlanLeaseRange(p.displayName)}
              </div>
              <div className="cost">
                ${getPlanPrice(mapPlanDisplayNameToPlanSubTier(p.displayName))}
                <div>per year</div>
              </div>
              {displayCheckOutButton(
                mapPlanDisplayNameToPlanSubTier(p.displayName),
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PremiumPlanHeader;
