import { combineReducers } from 'redux';
import { modalReducer, steppedModalReducer } from '../common/modal/reducers';
import { userReducer } from '../features/auth/reducers';
import { glossaryReducer } from '../features/help/reducers';
import { leaseReducer } from '../features/lease/reducers';
import { leaseDetailsReducer } from '../features/leaseDetails/reducers';
import { leaseListReducer } from '../features/leaseList/reducers';
import {
  currentAdminPlanReducer,
  multiClientPlanReducer,
} from '../features/multiClient/reducers';
import { plansReducer } from '../features/plans/reducers';
import { quickBooksReducer } from '../features/quickbooks/reducers';

const entitiesReducer = combineReducers({
  modal: modalReducer,
  steppedModal: steppedModalReducer,
  user: userReducer,
  plans: plansReducer,
  lease: leaseReducer,
  leaseList: leaseListReducer,
  leaseDetails: leaseDetailsReducer,
  glossaryItems: glossaryReducer,
  quickBooksSettings: quickBooksReducer,
  multiClientPlans: multiClientPlanReducer,
  currentAdminPlan: currentAdminPlanReducer,
});

export default entitiesReducer;
