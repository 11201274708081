import { Formik } from 'formik';
import { generateTax } from '../../../api';
import { displayCurrency } from '../helpers';
import { ICheckoutFormState } from '../PaymentPage';

export interface IPaymentFormState {
  total: string | undefined;
  subTotal: string;
  tax: string | undefined;
  displayZipError: boolean;
  taxError: string | undefined;
  disablePay: boolean;
  isUSA: boolean;
  taxInProgress: string | undefined;
  totalWithTax: string;
}
let errorTimeout: number;

const promiseTimeout = (
  paymentFormState: IPaymentFormState,
): Promise<IPaymentFormState> => {
  window.clearTimeout(errorTimeout);
  return new Promise((resolve) => {
    errorTimeout = window.setTimeout(() => {
      if (!paymentFormState.tax && !paymentFormState.displayZipError) {
        resolve({ ...paymentFormState, displayZipError: true });
      }
    }, 2000);
  });
};

export const calculateTax = async (planAmount: number, zipCode: string) => {
  const taxObject = await generateTax(planAmount, zipCode, 'US');
  return taxObject;
};

export const generateTotal = (
  planAmount: number,
  taxAmount: number,
  paymentFormState: IPaymentFormState,
): IPaymentFormState => {
  const calculatedTotal = planAmount + taxAmount;
  const updatedPaymentFormState = {
    ...paymentFormState,
    subTotal: displayCurrency(planAmount),
    tax: displayCurrency(taxAmount),
    total: displayCurrency(calculatedTotal),
    disablePay: false,
    displayZipError: false,
    totalWithTax: displayCurrency(calculatedTotal),
  };
  return updatedPaymentFormState;
};

export const processCountryOtherThanUSA = (
  subTotal: number,
  paymentFormState: IPaymentFormState,
): IPaymentFormState => {
  // tax is not included
  const updatedPaymentFormState = {
    ...paymentFormState,
    tax: displayCurrency(0.0),
    disablePay: false,
    displayZipError: false,
    isUSA: false,
  };
  return generateTotal(subTotal, 0, updatedPaymentFormState);
};

export const handleAvalaraError = (
  error: string,
  formState: Formik<ICheckoutFormState>,
  paymentFormState: IPaymentFormState,
): IPaymentFormState => {
  const updatedPaymentFormState = {
    ...paymentFormState,
    tax: undefined,
    total: undefined,
    disablePay: true,
    displayZipError: true,
    taxError: error,
  };
  formState.setFieldError('zip', ' ');
  formState.setFieldError('country', ' ');
  return updatedPaymentFormState;
};

export const processAvalaraResponse = (
  taxAmount: number,
  subTotal: number,
  paymentFormState: IPaymentFormState,
): IPaymentFormState => {
  const updatedPaymentFormState = {
    ...paymentFormState,
    isUSA: true,
    displayZipError: false,
    taxError: undefined,
  };

  return generateTotal(subTotal, taxAmount, updatedPaymentFormState);
};

export const validateCountryZip = async (
  formValues: ICheckoutFormState,
  subTotal: number,
  formState: Formik<ICheckoutFormState>,
  paymentFormState: IPaymentFormState,
  updatePaymentFormState: (state: IPaymentFormState) => void,
): Promise<IPaymentFormState> => {
  window.clearTimeout(errorTimeout);
  const { country, zip } = formValues;

  if (!zip || !country) {
    return {
      ...paymentFormState,
      disablePay: true,
      tax: undefined,
      displayZipError: false,
      total: undefined,
      taxError: undefined,
      taxInProgress: 'Enter contact information to calculate tax',
    };
  }

  if (country === 'US' && zip.length === 5) {
    const updatedPaymentFormState = {
      ...paymentFormState,
      displayZipError: false,
      taxInProgress: 'Calculating tax...',
    };
    updatePaymentFormState(updatedPaymentFormState);
    const taxAmount: any = await calculateTax(subTotal, zip);
    if (taxAmount.status >= 300) {
      return handleAvalaraError(taxAmount.error, formState, {
        ...paymentFormState,
        disablePay: true,
      });
    } else {
      return processAvalaraResponse(taxAmount.tax, subTotal, paymentFormState);
    }
  } else if (country === 'US' && zip.length < 5) {
    return promiseTimeout(paymentFormState);
  } else if (country !== 'US' && country !== undefined && country !== '') {
    return processCountryOtherThanUSA(subTotal, paymentFormState);
  }
  return paymentFormState;
};
