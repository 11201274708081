import React, { FunctionComponent } from 'react';

import './InlineButton.scss';

const InlineButton: FunctionComponent<IInlineButtonProps> = ({
  onClick,
  disabled,
  iconType,
  buttonText,
  id,
  type = 'button',
}) => (
  <button
    onClick={onClick}
    className="button-inline"
    disabled={!!disabled}
    id={id}
    type={type}
  >
    {disabled ? (
      buttonText
    ) : (
      <span className="button-label">
        {iconType ? (
          <img
            src={require(`../../assets/${iconType}.svg`)}
            className="button-icon"
            alt=""
          />
        ) : null}
        {buttonText}
      </span>
    )}
  </button>
);

interface IInlineButtonProps {
  buttonText: string;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconType?: IconTypes;
  id?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

type IconTypes = 'blue-plus';

export default InlineButton;
