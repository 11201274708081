import React, { FunctionComponent } from 'react';

import AZdark from '../../assets/AZdark.svg';
import numAscending from '../../assets/numAscending.svg';
import numDescending from '../../assets/numDescending.svg';
import rsmAZdark from '../../assets/rsmAZdark.svg';
import rsmnumAscending from '../../assets/rsmnumAscending.svg';
import rsmnumDescending from '../../assets/rsmnumDescending.svg';
import rsmZAdark from '../../assets/rsmZAdark.svg';
import ZAdark from '../../assets/ZAdark.svg';
import { IActiveSortState, IFilterCategories, SortTypes } from './Dashboard';

interface ILeaseListHeaderProps {
  activeSort: IActiveSortState;
  handleSort: (
    propToSort: keyof IFilterCategories,
    direction: 'ascending' | 'descending',
    type: SortTypes,
  ) => void;
}

const LeaseListHeader: FunctionComponent<ILeaseListHeaderProps> = ({
  handleSort,
  activeSort,
}: ILeaseListHeaderProps) => {
  let alphaSortImg = '';
  let numSortImg = '';

  switch (activeSort.sortDirection) {
    case 'ascending':
      if (process.env.REACT_APP_ENABLE_RSM === 'true') {
        alphaSortImg = rsmAZdark;
        numSortImg = rsmnumAscending;
      } else {
        alphaSortImg = AZdark;
        numSortImg = numAscending;
      }
      break;
    default:
      if (process.env.REACT_APP_ENABLE_RSM === 'true') {
        alphaSortImg = rsmZAdark;
        numSortImg = rsmnumDescending;
      } else {
        alphaSortImg = ZAdark;
        numSortImg = numDescending;
      }
      break;
  }

  return (
    <div className="lease-list-header">
      <div
        className="table-heading"
        onClick={() =>
          handleSort(
            'assetName',
            activeSort.sortDirection === 'ascending'
              ? 'descending'
              : 'ascending',
            'text',
          )
        }
      >
        Lease Name
        {activeSort.field === 'assetName' ? (
          <img src={alphaSortImg} alt="lease sorting icon" />
        ) : null}
      </div>

      <div
        className="table-heading"
        onClick={() =>
          handleSort(
            'nextPaymentDate',
            activeSort.sortDirection === 'ascending'
              ? 'descending'
              : 'ascending',
            'date',
          )
        }
      >
        Next PMT
        <br />
        Date
        {activeSort.field === 'nextPaymentDate' ? (
          <img src={numSortImg} alt="lease sorting icon" />
        ) : null}
      </div>

      <div
        className="table-heading"
        onClick={() =>
          handleSort(
            'nextPaymentAmt',
            activeSort.sortDirection === 'ascending'
              ? 'descending'
              : 'ascending',
            'number',
          )
        }
      >
        Next PMT
        <br />
        Amount
        {activeSort.field === 'nextPaymentAmt' ? (
          <img src={numSortImg} alt="lease sorting icon" />
        ) : null}
      </div>

      <div
        className="table-heading"
        onClick={() =>
          handleSort(
            'remainingPayments',
            activeSort.sortDirection === 'ascending'
              ? 'descending'
              : 'ascending',
            'number',
          )
        }
      >
        Remaining
        <br />
        PMT
        {activeSort.field === 'remainingPayments' ? (
          <img src={numSortImg} alt="lease sorting icon" />
        ) : null}
      </div>

      <div
        className="table-heading"
        onClick={() =>
          handleSort(
            'leaseBeginDt',
            activeSort.sortDirection === 'ascending'
              ? 'descending'
              : 'ascending',
            'date',
          )
        }
      >
        Start Date
        {activeSort.field === 'leaseBeginDt' ? (
          <img src={numSortImg} alt="lease sorting icon" />
        ) : null}
      </div>

      <div
        className="table-heading"
        onClick={() =>
          handleSort(
            'leaseEndDt',
            activeSort.sortDirection === 'ascending'
              ? 'descending'
              : 'ascending',
            'date',
          )
        }
      >
        End Date
        {activeSort.field === 'leaseEndDt' ? (
          <img src={numSortImg} alt="lease sorting icon" />
        ) : null}
      </div>
      <div
        className="table-heading"
        onClick={() =>
          handleSort(
            'assetType',
            activeSort.sortDirection === 'ascending'
              ? 'descending'
              : 'ascending',
            'text',
          )
        }
      >
        Asset Type
        {activeSort.field === 'assetType' ? (
          <img src={alphaSortImg} alt="lease sorting icon" />
        ) : null}
      </div>
    </div>
  );
};

export default LeaseListHeader;
