import React, { FunctionComponent, ReactNode } from 'react';
import TripleDotLoader from '../loaders/TripleDotLoader';
import './Button.scss';

const Button: FunctionComponent<IButtonProps> = ({
  buttonType,
  buttonText,
  id,
  onClick = () => false,
  block = false,
  disabled = false,
  type = 'button',
  processing = false,
  processingText = buttonText,
  classes = '',
  isRounded = false,
  children = null,
}) => (
  <button
    onClick={onClick}
    className={`button-${buttonType} ${block ? 'button-block' : ''} ${
      isRounded ? 'button-rounded' : ''
    }
      ${classes ? classes : ''}
    `}
    disabled={processing || disabled}
    id={id}
    type={type}
  >
    {children && <span className="button-content">{children}</span>}
    {processing ? <TripleDotLoader text={processingText} /> : buttonText}
  </button>
);

interface IButtonProps {
  buttonType: ButtonTypes;
  buttonText: string;
  onClick?: () => void;
  block?: boolean;
  disabled?: boolean;
  id?: string;
  type?: 'submit' | 'button';
  processing?: boolean;
  processingText?: string;
  classes?: string;
  isRounded?: boolean;
  children?: ReactNode;
}

export type ButtonTypes =
  | 'positive'
  | 'positive-dark'
  | 'positive-green'
  | 'positive-kraken'
  | 'negative'
  | 'neutral';

export default Button;
