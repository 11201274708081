import React from 'react';
import { useSelector } from 'react-redux';
import {
  ICPACompanyDetail,
  IPlanFeatureModel,
  PlanFeatureNames,
} from '../../../interfaces';
import { IStore } from '../../../store';
import { getFeatureValue } from '../../plans/helpers';
import { isAccountOnFreeCPAPlan } from '../helpers/helper';
import AddCompanyCard from './AddCompanyCard';
import CompanyCard from './CompanyCard';

import './CompanyList.scss';
import EmptyCard from './EmptyCard';
import MaxCompanyCard from './MaxCompanyCard';

export interface CompanyListProps {
  companyList: ICPACompanyDetail[];
  onAddClientClick: () => void;
  onDelete: (companyId: number) => void;
}

function CompanyList({
  companyList,
  onAddClientClick,
  onDelete,
}: CompanyListProps) {
  const loggedInUser = useSelector((state: IStore) => {
    return state.user;
  });

  const maxClients = Number(
    getFeatureValue(
      PlanFeatureNames.clientCount,
      loggedInUser.company.plan?.features as IPlanFeatureModel[],
    ),
  );

  const buildCompanyList = () => {
    return (
      <>
        <div className="company-list-cta">
          {companyList.length === 0
            ? 'To get started, add a company to your list.'
            : 'Select a company to view their lease portfolio data.'}
        </div>
        <div className="company-list-row">
          {companyList.map((company) => {
            return (
              <CompanyCard
                company={company}
                key={company.id}
                onDelete={onDelete}
              />
            );
          })}
          {companyList.length < maxClients && (
            <AddCompanyCard onAddClientClick={onAddClientClick} />
          )}
          {companyList.length === maxClients &&
            isAccountOnFreeCPAPlan(loggedInUser.company.plan) && (
              <MaxCompanyCard />
            )}
          {companyList.length < maxClients - 1
            ? Array.from(Array(maxClients - 1 - companyList.length).keys()).map(
                (i) => {
                  return <EmptyCard key={companyList.length + i} />;
                },
              )
            : null}
        </div>
      </>
    );
  };

  return <div className="company-list-wrapper">{buildCompanyList()}</div>;
}

export default CompanyList;
