import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  getActivePlans,
  getClient,
  getCountryList,
  getCurrentUser,
  makeStripePayment,
} from '../../api';
import visa from '../../assets/1.png';
import discover from '../../assets/14.png';
import ms from '../../assets/2.png';
import american from '../../assets/22.png';
import lock from '../../assets/lock.svg';
import { Autocomplete, Divider, Input, Section } from '../../common';
import { IAutocompleteOption } from '../../common/autocomplete/Autocomplete';
import { Button } from '../../common/buttons';
import FormError from '../../common/form-error/FormError';
import FormGrid from '../../common/form-grid/FormGrid';
import { hideModal } from '../../common/modal/actions';
import { useResourceContext } from '../../common/resource-context';
import { CheckIsMultiClientAdmin } from '../../common/utilities/_authHelpers';
import { processErrorMessage } from '../../common/utilities/_helpers';
import { loadGetSiteControlData } from '../../common/utilities/_thirdPartyScripts';
import { fieldHasError } from '../../common/utilities/FormikHelpers';
import { storePlans } from '../../features/plans/actions';
import {
  ICompanyModel,
  IPaymentMethodModel,
  IPlanModel,
  IUserModel,
} from '../../interfaces';
import { IStore } from '../../store';
import { saveUser, updateUserCompanySettings } from '../auth/actions';
import PlanCard from '../plansAndPricing/planCard/PlanCard';
import { displayCurrency, renewalDate, toTitleCase } from './helpers';
import { IPaymentFormState } from './helpers/taxHelpers';
import './PaymentPage.scss';
import { SubscriptionModalPages } from './SubscriptionModal';

export interface ICheckoutFormState {
  addressLine1: string;
  city: string;
  zip: string;
  country?: string;
}

interface IPaymentPageProps {
  proratedPrice?: number;
  selectedPlan: IPlanModel;
  setNavigation: (navigateTo: SubscriptionModalPages) => void;
  setSelectedPlan: (selectedPlan: IPlanModel) => void;
  setTaxData?: (data: { zip: string; country: string }) => void;
  taxAmount: number | undefined;
  taxErrorText: string | undefined;
  totalWithTax?: string | undefined;
  currentProrationDate?: Date;
}

interface ICountryOptions {
  key: number;
  value: string;
  label: string;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || '');

const PaymentPage: React.FC<IPaymentPageProps> = ({
  proratedPrice,
  selectedPlan,
  setNavigation,
  setSelectedPlan,
  setTaxData,
  taxAmount,
  taxErrorText,
  totalWithTax,
  currentProrationDate,
}) => {
  const resources = useResourceContext();
  const loggedInUser: IUserModel = useSelector((state: IStore) => state.user);
  const formikRef = useRef<Formik<ICheckoutFormState>>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const companySubscription = useSelector((state: IStore) => {
    return state.user.company?.subscription;
  });
  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();

  const PaymentFormSchema = (USA: boolean) =>
    yup.object().shape({
      addressLine1: yup
        .string()
        .label('Address')
        .required(),
      city: yup
        .string()
        .label('City')
        .required(),
      country: yup
        .string()
        .label('Country')
        .required('Country is a required field'),
      zip: USA
        ? yup
            .string()
            .min(5, 'Please enter a valid Zip/Postal Code')
            .label('Zip')
            .required('Zip / Postal Code is a required field')
        : yup
            .string()
            .required('Zip / Postal Code is a required field')
            .label('Zip'),
    });

  const handleCancel = () => {
    dispatch(hideModal());
  };

  const getPaymentValue = () => {
    const paymentValue = totalWithTax
      ? totalWithTax
      : proratedPrice
      ? proratedPrice
      : paymentFormState.subTotal;

    return paymentValue.toString().replace(/[^0-9.]+/g, '');
  };

  const handleSubmit = async (values: ICheckoutFormState) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'purchase',
      },
    });

    setErrorMessage('');
    setProcessing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: loggedInUser.company.companyName,
          email: loggedInUser.email,
          address: {
            city: values.city,
            line1: values.addressLine1,
            postal_code: values.zip,
          },
        },
      });

      if (error || !paymentMethod) {
        setErrorMessage('There was an issue with your payment method');
        setProcessing(false);
        return;
      } else {
        const stripePaymentMethod: IPaymentMethodModel = {
          paymentMethodId: paymentMethod.id,
          companyEmail: loggedInUser.email,
          companyName: loggedInUser.company.companyName,
          companyAddress: values.addressLine1,
          city: values.city,
          zip: values.zip,
          planId: selectedPlan?.id.toString(),
          stripePlanId: selectedPlan?.stripePlanId,
          country: values.country,
          prorationDate: currentProrationDate,
        };

        try {
          const response = await makeStripePayment(stripePaymentMethod);

          if (response) {
            setNavigation(SubscriptionModalPages.confirm);
            const updatedCompany: ICompanyModel = {
              ...loggedInUser.company,

              subscription: {
                addressModel: {
                  companyAddress: values.addressLine1,
                  city: values.city,
                  zip: values.zip,
                  country: values.country,
                },
              },
            };
            dispatch(updateUserCompanySettings(updatedCompany));

            // reload the user
            let user: IUserModel = await getCurrentUser();

            loadGetSiteControlData(user);

            if (user.userRole === 1) {
              const companySelection = Number(
                sessionStorage.getItem('admin-company-selection'),
              );

              if (companySelection) {
                const selectedCompany = await getClient(companySelection);
                if (selectedCompany) {
                  user = { ...user, company: selectedCompany };
                }
              }
            }

            // load all LeaseGuru Plans
            await loadPlans().then(() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: 'purchase',
                  ecommerce: {
                    transaction_id: response.invoice_prefix,
                    value: getPaymentValue(),
                    currency: 'USD',
                  },
                },
              });
            });

            dispatch(saveUser(user));
          }
        } catch (error) {
          setErrorMessage(
            'Oops! Payment was unsuccessful. ' + processErrorMessage(error),
          );
        }
      }

      setProcessing(false);
    }
  };

  const loadPlans = async () => {
    if (loggedInUser.company?.jurisdiction) {
      const planData = await getActivePlans(loggedInUser.company.jurisdiction);

      dispatch(storePlans(planData));
    }
  };

  const handleStripeChange = (event: StripeCardElementChangeEvent) => {
    if (event.error) {
      setErrorMessage(event.error.message);
    } else {
      setErrorMessage('');
    }
  };

  const initialPaymentFormState: IPaymentFormState = useMemo(() => {
    return {
      total: undefined,
      subTotal: proratedPrice
        ? displayCurrency(proratedPrice)
        : displayCurrency(selectedPlan.price),
      tax: taxAmount !== undefined ? displayCurrency(taxAmount) : undefined,
      displayZipError: false,
      taxError: taxErrorText,
      disablePay: taxAmount !== undefined ? false : true,
      isUSA: true,
      taxInProgress: 'Enter company information to calculate tax',
      totalWithTax: '',
    };
  }, [selectedPlan, proratedPrice, taxAmount, taxErrorText]);

  const [paymentFormState, updatePaymentFormState] = useState<
    IPaymentFormState
  >(initialPaymentFormState);

  // update price as selectedPlan changes
  useEffect(() => {
    updatePaymentFormState(initialPaymentFormState);
  }, [selectedPlan, initialPaymentFormState, taxAmount]);

  const [countryList, setCountries] = useState<ICountryOptions[]>([
    { key: 0, value: '', label: '' },
  ]);
  const proratedRenewalDate = loggedInUser.company?.subscriptionEndDate;

  useEffect(() => {
    const generateCountryList = async (): Promise<void> => {
      const listOfCountries = await getCountryList();
      const formattedListOfCountries: ICountryOptions[] = [];
      listOfCountries.forEach(
        (country: { name: string; code: string }, index: number) => {
          formattedListOfCountries.push({
            key: index,
            label: toTitleCase(country.name),
            value: country.code,
          });
        },
      );
      setCountries(formattedListOfCountries);
    };
    generateCountryList();
  }, []);

  const handleCountryOrZipChange = (
    formProps: FormikProps<ICheckoutFormState>,
    selectedCountry?: IAutocompleteOption<string>,
    zipInputEvent?: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const taxData = {
      zip: formProps.values.zip,
      country: formProps.values.country ?? '',
    };
    if (selectedCountry) {
      formProps.setFieldValue('country', selectedCountry.value);

      taxData.country = selectedCountry?.value ?? '';
    }
    if (zipInputEvent) {
      zipInputEvent.target.value = zipInputEvent.target.value.replace(
        /\D/g,
        '',
      );
      formProps.handleChange(zipInputEvent);
      taxData.zip = zipInputEvent.target.value;
    }

    // pass up to calculate tax
    if (setTaxData) {
      setTaxData(taxData);
    }
  };

  const enterpriseUrl = resources.text('EnterprisePricingUrl');

  return (
    <Formik
      ref={formikRef}
      validateOnChange={true}
      initialValues={{
        addressLine1: companySubscription
          ? companySubscription.addressModel?.companyAddress
          : '',
        city: companySubscription ? companySubscription.addressModel?.city : '',
        zip: '',
        country: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={PaymentFormSchema(paymentFormState.isUSA)}
    >
      {(formProps: FormikProps<ICheckoutFormState>) => {
        return (
          <Form noValidate={true}>
            <div className="payment-page-wrapper">
              <div className="plan-picker-section">
                <div className="plan-picker-details">
                  <PlanCard
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                  />
                </div>
                <div className="payment-submission">
                  <div className="trial-message">
                    <label className="payment-help-text">
                      Subscription renews annually and purchases made can't be
                      refunded. Contact{' '}
                      <a
                        href={resources.text(
                          'General_Email_MailTo',
                          'mailto:info@leaseguru.com',
                        )}
                        className="mailTo"
                      >
                        {resources.text(
                          'General_Email_Address',
                          'info@leaseguru.com',
                        )}
                      </a>{' '}
                      for questions.
                    </label>
                    <br />
                    <br />
                    <label className="payment-help-text">
                      {proratedPrice
                        ? `Your renewal date remains ${proratedRenewalDate}. Your plan amount has been prorated accordingly.`
                        : `Your subscription will automatically renew on ${renewalDate()}.`}
                    </label>
                  </div>
                </div>
              </div>
              <div className="payment-form-section">
                <div className="payment-form">
                  <label className="payment-help-text">
                    <u>
                      Select your plan size by number of
                      {CheckIsMultiClientAdmin(loggedInUser)
                        ? ' companies'
                        : ' leases'}{' '}
                      on the left
                    </u>{' '}
                    then fill out the information below.
                  </label>
                  <Section label="Company Information">
                    <Input
                      name="addressLine1"
                      label="Company Address"
                      onChange={formProps.handleChange}
                      value={formProps.values.addressLine1}
                      error={fieldHasError(formProps, 'addressLine1')}
                      errorMessage={formProps.errors.addressLine1}
                      required
                    />
                    <FormGrid colWidth={3}>
                      <Input
                        id="city"
                        label="City"
                        name="city"
                        onChange={formProps.handleChange}
                        value={formProps.values.city}
                        error={fieldHasError(formProps, 'city')}
                        errorMessage={formProps.errors.city}
                        required
                      />
                      <div className="form-input-wrapper">
                        <Autocomplete
                          label="Country"
                          value={formProps.values.country}
                          options={countryList}
                          onSelection={(e: IAutocompleteOption<string>) =>
                            handleCountryOrZipChange(formProps, e)
                          }
                          onSelectionClear={() =>
                            formProps.setFieldValue('country', '')
                          }
                          error={fieldHasError(formProps, 'country')}
                          errorMessage={
                            paymentFormState.displayZipError
                              ? ' '
                              : formProps.errors.country
                          }
                          errorSize={'small'}
                          required
                        />
                      </div>
                      <Input
                        label="Zip / Postal Code"
                        name="zip"
                        onChange={(e: React.ChangeEvent<any>) =>
                          handleCountryOrZipChange(formProps, undefined, e)
                        }
                        value={formProps.values.zip}
                        error={fieldHasError(formProps, 'zip')}
                        errorMessage={formProps.errors.zip}
                        required
                        maxLength={paymentFormState.isUSA ? 5 : 15}
                      />
                    </FormGrid>
                  </Section>
                  <Section label="Payment Information">
                    <div className="card-element">
                      <CardElement
                        options={{
                          style: {
                            base: {
                              color: '#32325d',
                              fontFamily: '"Lato", sans-serif',
                              fontSmoothing: 'antialiased',
                              fontSize: '16px',
                              '::placeholder': {
                                color: '#aab7c4',
                              },
                            },
                            invalid: {
                              color: '#fa755a',
                              iconColor: '#fa755a',
                            },
                          },
                        }}
                        onChange={handleStripeChange}
                      />
                      {errorMessage ? (
                        <FormError
                          error={!!errorMessage}
                          errorMessage={errorMessage}
                        />
                      ) : null}
                    </div>

                    <div className="secure">
                      <div className="secure-lock">
                        <img src={lock} alt="card" className="lock-image" />
                        <span>Your payment data is encrypted and secure.</span>
                      </div>
                      <div className="secure-card-images">
                        <img src={visa} alt="card" className="payment-logo" />
                        <img src={ms} alt="card" className="payment-logo" />
                        <img
                          src={discover}
                          alt="card"
                          className="payment-logo"
                        />
                        <img
                          src={american}
                          alt="card"
                          className="payment-logo"
                        />
                      </div>
                    </div>
                    <Divider />
                    <div className="paymentBox">
                      <div className="subtotalRow">
                        <div className="subtotalLabel">Subtotal</div>
                        <div className="subtotal">
                          <span className="price">
                            {proratedPrice
                              ? displayCurrency(proratedPrice)
                              : paymentFormState.subTotal}
                          </span>
                        </div>
                      </div>
                      <div className="taxRow">
                        <div className="taxLabel">Tax</div>
                        {paymentFormState.tax ? (
                          <div className="tax">{paymentFormState.tax}</div>
                        ) : paymentFormState.taxError ? (
                          <div className="tax error">
                            {paymentFormState.taxError}
                          </div>
                        ) : (
                          <div className="tax placeholder">
                            {paymentFormState.taxInProgress}
                          </div>
                        )}
                      </div>
                      <div className="totalRow">
                        <div className="totalLabel">Today's Total</div>
                        <div className="total">
                          <span className="price">
                            {totalWithTax
                              ? totalWithTax
                              : proratedPrice
                              ? displayCurrency(proratedPrice)
                              : paymentFormState.subTotal}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Section>
                </div>
              </div>
              <div className="bottom-left">
                <Divider />
                <label className="need-more payment-help-text">
                  Need more?&nbsp;
                  <a
                    href={enterpriseUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Talk to us about LeaseQuery.
                  </a>
                </label>
              </div>
              <div className="bottom-right">
                <Divider />
                <div className="button-group">
                  <Button
                    buttonType="neutral"
                    buttonText="Cancel"
                    onClick={handleCancel}
                  />
                  <Button
                    buttonType="positive-green"
                    buttonText="Pay"
                    type="submit"
                    processing={processing}
                    disabled={processing || paymentFormState.disablePay}
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const WrappedPaymentPage: React.FC<IPaymentPageProps> = (props) => (
  <Elements stripe={stripePromise}>
    <PaymentPage {...props} />
  </Elements>
);

export default WrappedPaymentPage;
