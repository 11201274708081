import React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IModal } from '../../../common/modal/types';
import { IStore } from '../../../store';

import SteppedModal from './SteppedModal';

const MODAL_COMPONENTS: IModalComponents = {
  STEPPED_MODAL: SteppedModal,
};

const SteppedModalRoot = ({
  modal: { modalType, modalProps },
}: IModalRootProps) => {
  if (!modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];

  return <SpecificModal {...modalProps} />;
};

interface IModalComponents {
  [key: string]: any;
}

interface IModalRootProps {
  dispatch: Dispatch;
  modal: IModal;
}

export default connect((state: IStore) => ({
  modal: state.steppedModal,
}))(SteppedModalRoot);
