import React, { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getLeases, getLeasesByClient } from '../../api';
import { hideModal, showModal } from '../../common/modal/actions';
import { isAdminGuestOrContributing } from '../../common/utilities/_authHelpers';
import { checkIfUserIsGuest } from '../../common/utilities/_guestUserHelper';
import {
  DashboardType,
  ILeaseHeaderModel,
  IUserModel,
  PlanFeatureNames,
} from '../../interfaces';
import { IStore } from '../../store';
import { setReloadLeases } from '../lease/actions';
import { LeasesCardView } from '../leaseDashboard';
import { storeLeases } from '../leaseList/actions';
import {
  findAvailablePlansToUpgrade,
  findNextAvailableUpgrade,
  getFeatureValue,
} from '../plans/helpers';
import { SubscriptionModalPages } from '../subscriptions/SubscriptionModal';
import BuyNowPopUp, { IBuyNowPlanInfo } from './BuyNowPopUp';
import './Dashboard.scss';
import DashboardFilterHeader from './DashboardFilterHeader';
import DashboardLeaseList from './DashboardLeaseList';
import FilterContainer from './FilterContainer';
import LeaseListHeader from './LeaseListHeader';
import QuickLinks from './QuickLinks';

export type FilterValueTypes = IMinMaxModel | string;

export interface IMinMaxModel {
  minValue: string;
  maxValue: string;
}

export interface IFilterCategories {
  assetName: [];
  nextPaymentAmt: [];
  nextPaymentDate: [];
  remainingPayments: [];
  leaseBeginDt: [];
  leaseEndDt: [];
  assetType: [];
}

export interface IActiveSortState {
  field: keyof IFilterCategories | '';
  sortDirection: 'ascending' | 'descending' | '';
  type: SortTypes | '';
}

export type FilterTypes = 'minMax' | 'date' | 'text';
export type SortTypes = 'text' | 'number' | 'date';

const Dashboard: React.FC = () => {
  const history = useHistory();

  const loggedInUser: IUserModel = useSelector((state: IStore) => state.user);

  const isGuestUser = checkIfUserIsGuest(loggedInUser);

  const leaseList: ILeaseHeaderModel[] = useSelector((state: IStore) => {
    return state.leaseList;
  });
  const storeLease = useSelector((state: IStore) => {
    return state.lease;
  });

  const plans = useSelector((state: IStore) => {
    return state.plans;
  });

  const currentPlan = useSelector((state: IStore) => {
    return state.user.company.plan;
  });

  const features = currentPlan?.features;

  const maxLeaseCount: number = features
    ? Number(getFeatureValue(PlanFeatureNames.leaseCount, features))
    : 0;

  const companySubscriptionType = useSelector((state: IStore) => {
    return state.user.company?.plan?.tier;
  });
  const dispatch = useDispatch();

  const [activeSort, setActiveSort] = useState<IActiveSortState>({
    field: 'assetName',
    sortDirection: 'ascending',
    type: 'text',
  });

  const [activeFilters, setActiveFilters] = useState<IFilterCategories>({
    nextPaymentDate: [],
    nextPaymentAmt: [],
    remainingPayments: [],
    leaseBeginDt: [],
    leaseEndDt: [],
    assetName: [],
    assetType: [],
  });

  const [assignedSectionLeases, setAssignedSectionLeases] = useState<
    IFilterCategories
  >({
    nextPaymentDate: [],
    nextPaymentAmt: [],
    remainingPayments: [],
    leaseBeginDt: [],
    leaseEndDt: [],
    assetName: [],
    assetType: [],
  });

  useEffect(() => {
    let leases: ILeaseHeaderModel[] = [];

    const loadLeases = async () => {
      if (isAdminGuestOrContributing(loggedInUser.userRole)) {
        if (loggedInUser.company && loggedInUser.company.clientId) {
          leases = await getLeasesByClient(loggedInUser.company.clientId);
        }
      } else {
        leases = await getLeases(Number(loggedInUser.userId));
      }

      if (leases.length) {
        dispatch(storeLeases(leases));
      }
    };

    if (!!(storeLease && storeLease.reloadLeases)) {
      dispatch(setReloadLeases(false));
      loadLeases();
    }
  }, [
    storeLease,
    dispatch,
    loggedInUser.company,
    loggedInUser.userId,
    loggedInUser.userRole,
    companySubscriptionType,
  ]);

  useEffect(() => {
    setAllFilteredLeases(
      leaseList.sort((a, b) => {
        if (a.assetName.toUpperCase() < b.assetName.toUpperCase()) {
          return -1;
        } else {
          return 1;
        }
      }),
    );
  }, [leaseList]);

  const [allFilteredLeases, setAllFilteredLeases] = useState<
    ILeaseHeaderModel[]
  >(leaseList);

  const [filterHeader, setFilterHeader] = useState('All Leases');

  const [quickFilter, setQuickFilter] = useState(false);

  const updateQuickFilter = (isSet: boolean, filter: string) => {
    if (isSet) {
      let filterList: React.SetStateAction<ILeaseHeaderModel[]> = [];

      switch (filter) {
        /*
        case 'favorites':
          filterList = leaseList.filter((item) => {
            return item.leaseStatus === 'Expires Soon';
          });
          break;
        */
        case 'expired':
          filterList = leaseList.filter((item) => {
            return item.leaseStatus === 'Expired';
          });
          break;
        case 'recently-viewed':
          const filterArray: ILeaseHeaderModel[] = [];

          recentlyViewedList.forEach((lease, index) => {
            const leaseId = lease.leaseId;

            leaseList.forEach((updatedLease) => {
              const updatedLeaseId = updatedLease.leaseId;

              if (updatedLeaseId === leaseId) {
                filterArray.push(updatedLease);
              }
            });
          });

          filterList = filterArray;
          break;
        case 'expiring':
          filterList = leaseList.filter((item) => {
            return item.leaseStatus === 'Expires Soon';
          });
          break;
      }

      handleClearAllFilters();

      const newLeastList: ILeaseHeaderModel[] = filterList;

      setQuickFilter(true);
      setAllFilteredLeases(newLeastList);
    } else {
      setFilterHeader('All Leases');
      setQuickFilter(false);
      setAllFilteredLeases(leaseList);
    }
  };

  const sessionDataRecentlyViewed = sessionStorage.getItem('recently-viewed');
  let sessionLeaseList: ILeaseHeaderModel[] = [];

  if (sessionDataRecentlyViewed) {
    sessionLeaseList = JSON.parse(sessionDataRecentlyViewed);
  }

  const [recentlyViewedList, setRecentlyViewed] = useState(sessionLeaseList);

  const addToRecentList = (lease: ILeaseHeaderModel) => {
    const sessionData = sessionStorage.getItem('recently-viewed');
    let currentRecentlyViewed: ILeaseHeaderModel[] = [];

    if (sessionData) {
      currentRecentlyViewed = JSON.parse(sessionData);
    }

    let doesLeaseExist = false;
    let indexOfLease: number = 0;

    currentRecentlyViewed.forEach((item, index) => {
      if (item.leaseId === lease.leaseId) {
        doesLeaseExist = true;
        indexOfLease = index;
      }
    });

    if (doesLeaseExist) {
      currentRecentlyViewed.splice(indexOfLease, 1);
      currentRecentlyViewed.unshift(lease);
    } else {
      currentRecentlyViewed.push(lease);
    }

    sessionStorage.setItem(
      'recently-viewed',
      JSON.stringify(currentRecentlyViewed),
    );

    setRecentlyViewed(currentRecentlyViewed);
  };

  const handleTextFilterSubmit = (
    filterValue: string,
    filterName: keyof IFilterCategories,
    filterType: FilterTypes,
  ) => {
    // get leases that apply to new filter
    const newFilteredLeases = getCurrentFilterLeases(
      filterValue,
      filterName,
      filterType,
    );

    TagManager.dataLayer({
      dataLayer: {
        event: 'lease_name_search',
        'search term': filterValue,
        number_of_results: newFilteredLeases.length,
      },
    });

    // set section leases
    const newSectionLeaseIds = getNewSectionLeasesIds(newFilteredLeases);

    // if filterValue is blank, reset filters leaseIds
    const newAllLeaseIdSections = {
      ...assignedSectionLeases,
      [filterName]: filterValue ? newSectionLeaseIds : [],
    };

    setAssignedSectionLeases(newAllLeaseIdSections);

    // filter down all leases to only the leases that exist in EVERY section
    const mergedMainLeases = handleMergeToMain(newAllLeaseIdSections);

    // get the number of active filter sections
    const activeFilterSections = getActiveFilterSections(newAllLeaseIdSections);

    let newAllFilteredLeases = mergedMainLeases;

    // ONLY during a remove, if number of leases is 0 AND there are no active filters, add all leases again.
    if (mergedMainLeases.length === 0 && activeFilterSections === 0) {
      newAllFilteredLeases = leaseList;
    }

    // check for an active sort in order to maintain current sort order
    const sortedFilteredLeases = sortIfActive(newAllFilteredLeases);

    setAllFilteredLeases(sortedFilteredLeases);
  };

  const handleFilterSubmit = (
    filterValues: FilterValueTypes,
    filterName: keyof IFilterCategories,
    filterType: FilterTypes,
  ) => {
    // add new filter to the activeFilters
    const newFilters = addActiveFilter(filterValues, filterName);

    // get SECTION leases that apply to new filter
    const newFilteredLeases = newFilters.map((filter) =>
      getCurrentFilterLeases(filter, filterName, filterType),
    );

    // flatten SECTION array
    const flatNewFilteredLeases = newFilteredLeases.flat();

    // remove dupe leases from SECTION
    const noDupeNewFilteredLeases = removeDuplicateLeases(
      flatNewFilteredLeases,
    );

    // set section leases
    const newSectionLeaseIds = getNewSectionLeasesIds(noDupeNewFilteredLeases);

    const newAllLeaseIdSections = {
      ...assignedSectionLeases,
      [filterName]: newSectionLeaseIds,
    };

    setAssignedSectionLeases(newAllLeaseIdSections);

    // filter down all leases to only the leases that exist in EVERY section
    const mergedMainLeases = handleMergeToMain(newAllLeaseIdSections);

    // check for an active sort in order to maintain current sort order
    const sortedFilteredLeases = sortIfActive(mergedMainLeases);

    setAllFilteredLeases(sortedFilteredLeases);
    setFilterHeader('All Leases');
    setQuickFilter(false);
  };

  const sortIfActive = (leases: ILeaseHeaderModel[]) => {
    // if there is an active sort, re-apply it
    if (activeSort.field && activeSort.sortDirection && activeSort.type) {
      return handleSort(
        activeSort.field,
        activeSort.sortDirection,
        activeSort.type,
        leases,
      );
    }
    // otherwise, just return the leases
    return leases;
  };

  const handleFilterRemove = (
    filterName: keyof IFilterCategories,
    index: number,
    filterType: FilterTypes,
  ) => {
    // remove filter from the activeFilters
    const newFilters = removeActiveFilter(filterName, index);

    // get SECTION leases that apply to new filter
    const newFilteredLeases = newFilters.map((filter) =>
      getCurrentFilterLeases(filter, filterName, filterType),
    );

    // flatten SECTION array
    const flatNewFilteredLeases = newFilteredLeases.flat();

    // remove dupe leases from SECTION
    const noDupeNewFilteredLeases = removeDuplicateLeases(
      flatNewFilteredLeases,
    );

    // set section leases
    const newSectionLeaseIds = getNewSectionLeasesIds(noDupeNewFilteredLeases);

    const newAllLeaseIdSections = {
      ...assignedSectionLeases,
      [filterName]: newSectionLeaseIds,
    };

    setAssignedSectionLeases(newAllLeaseIdSections);

    // filter down all leases to only the leases that exist in EVERY section
    const mergedMainLeases = handleMergeToMain(newAllLeaseIdSections);

    // get the number of active filter sections
    const activeFilterSections = getActiveFilterSections(newAllLeaseIdSections);

    let newAllFilteredLeases = mergedMainLeases;

    // ONLY during a remove, if number of leases is 0 AND there are no active filters, add all leases again.
    if (mergedMainLeases.length === 0 && activeFilterSections === 0) {
      newAllFilteredLeases = leaseList;
    }

    // check for an active sort in order to maintain current sort order
    const sortedFilteredLeases = sortIfActive(newAllFilteredLeases);

    setAllFilteredLeases(sortedFilteredLeases);
  };

  const getActiveFilterSections = (allLeaseIdSections: IFilterCategories) => {
    return Object.values(allLeaseIdSections).reduce((previous, current) => {
      if (current.length !== 0) {
        return previous + 1;
      }

      return previous;
    }, 0);
  };

  const handleMergeToMain = (allLeaseIdSections: IFilterCategories) => {
    // merge all SECTION arrays together
    const merged = Object.values(allLeaseIdSections)
      .reduce((previousSectionArray: any[], currentSectionArray) => {
        if (currentSectionArray.length !== 0) {
          return [...previousSectionArray, ...currentSectionArray];
        }
        return previousSectionArray;
      }, [])
      .flat();

    // get a list of unique lease ids to loop through
    const uniqueIds = Array.from(new Set(merged));

    // get the number of active filter sections
    const activeFilterSections = getActiveFilterSections(allLeaseIdSections);

    // for every unique id, check to see if that leaseId exists in all active filter sections
    const leaseIdsInEverySection = uniqueIds.reduce(
      (previousUniqueIds, currentId) => {
        const frequency = getArrayFrequency(merged, currentId);
        if (frequency === activeFilterSections) {
          return [...previousUniqueIds, currentId];
        }
        return previousUniqueIds;
      },
      [],
    );

    // get the list of leases to display to user
    const listOfLeasesToDisplay: ILeaseHeaderModel[] = leaseIdsInEverySection.map(
      (leaseId: number) => leaseList.find((lease) => lease.leaseId === leaseId),
    );

    return listOfLeasesToDisplay;
  };

  const getArrayFrequency = (arr: any[], val: any): number =>
    arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

  const getNewSectionLeasesIds = (
    noDupeNewFilteredLeases: ILeaseHeaderModel[],
  ): number[] => {
    return noDupeNewFilteredLeases.map((leases) => leases.leaseId);
  };

  const addActiveFilter = (
    filterValues: FilterValueTypes,
    filterName: keyof IFilterCategories,
  ) => {
    const currentFilters: any[] = activeFilters[filterName];

    const newFilters = [...currentFilters, filterValues];

    setActiveFilters({ ...activeFilters, [filterName]: newFilters });

    return newFilters;
  };

  const removeActiveFilter = (
    filterName: keyof IFilterCategories,
    index: number,
  ) => {
    const newFilters: any[] = activeFilters[filterName];

    newFilters.splice(index, 1);

    setActiveFilters({ ...activeFilters, [filterName]: newFilters });

    return newFilters;
  };

  const removeDuplicateLeases = (newFilteredLeases: ILeaseHeaderModel[]) => {
    return Array.from(new Set(newFilteredLeases));
  };

  const getCurrentFilterLeases = (
    filterValues: FilterValueTypes,
    filterName: keyof IFilterCategories,
    filterType: FilterTypes,
  ): ILeaseHeaderModel[] => {
    switch (filterType) {
      case 'minMax':
        return getMinMaxFilteredLeases(
          filterValues as IMinMaxModel,
          filterName,
        );
      case 'date':
        return getDateFilteredLeases(filterValues as IMinMaxModel, filterName);
      case 'text':
        return getTextFilteredLeases(filterValues as string, filterName);
      default:
        return [];
    }
  };

  const getTextFilteredLeases = (
    filterValues: string,
    filterName: keyof IFilterCategories,
  ) => {
    return leaseList.filter((lease: any) => {
      return lease[filterName]
        .toLowerCase()
        .includes(filterValues.toLowerCase());
    });
  };

  const getMinMaxFilteredLeases = (
    filterValues: IMinMaxModel,
    filterName: keyof IFilterCategories,
  ) => {
    return leaseList.filter(
      (lease) =>
        lease[filterName] >= filterValues.minValue &&
        lease[filterName] <= filterValues.maxValue,
    );
  };

  const getDateFilteredLeases = (
    filterValues: IMinMaxModel,
    filterName: keyof IFilterCategories,
  ) => {
    return leaseList.filter(
      (lease: any) =>
        Date.parse(lease[filterName]) >= Date.parse(filterValues.minValue) &&
        Date.parse(lease[filterName]) <= Date.parse(filterValues.maxValue),
    );
  };

  const sortLeases = (
    field: keyof IFilterCategories,
    sortDirection: 'ascending' | 'descending',
    type: SortTypes,
  ) => {
    setActiveSort({
      field,
      sortDirection,
      type,
    });

    const newSort = handleSort(field, sortDirection, type);

    setAllFilteredLeases([...newSort]);
  };

  const handleSort = (
    propToSort: keyof IFilterCategories,
    direction: 'ascending' | 'descending',
    type: SortTypes,
    leases: ILeaseHeaderModel[] = [...allFilteredLeases],
  ) => {
    const newSorted = [...leases].sort((leaseA, leaseB) => {
      let valueB;
      let valueA;

      switch (type) {
        case 'text':
          valueA = leaseA[propToSort].toString().toUpperCase();
          valueB = leaseB[propToSort].toString().toUpperCase();
          break;
        case 'number':
          valueA = Number(leaseA[propToSort]);
          valueB = Number(leaseB[propToSort]);
          break;
        case 'date':
          valueA = new Date(leaseA[propToSort]);
          valueB = new Date(leaseB[propToSort]);
          break;
      }

      if (valueA < valueB) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (valueA > valueB) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return newSorted;
  };

  const handleClearAllFilters = () => {
    setAllFilteredLeases(leaseList);
    setActiveFilters({
      nextPaymentDate: [],
      nextPaymentAmt: [],
      remainingPayments: [],
      leaseBeginDt: [],
      leaseEndDt: [],
      assetName: [],
      assetType: [],
    });
    setAssignedSectionLeases({
      nextPaymentDate: [],
      nextPaymentAmt: [],
      remainingPayments: [],
      leaseBeginDt: [],
      leaseEndDt: [],
      assetName: [],
      assetType: [],
    });
  };

  const getUsersSelectedView = () => {
    const userView = sessionStorage.getItem('DashboardView');
    if (userView !== null || userView !== undefined) {
      if (userView === '0') {
        return DashboardType.GRID;
      }
      if (userView === '1') {
        return DashboardType.LIST;
      }
    }
    return DashboardType.GRID;
  };

  const [dashboardView, setDashboardView] = useState<DashboardType>(
    getUsersSelectedView(),
  );

  useEffect(() => {
    sessionStorage.setItem('DashboardView', JSON.stringify(dashboardView));
  }, [dashboardView]);

  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
  const [leaseCountInfo, setLeaseCountInfo] = useState<IBuyNowPlanInfo>();

  useEffect(() => {
    const buyNowFlowLeaseCount = sessionStorage.getItem('leaseCount');
    const leaseCountData =
      buyNowFlowLeaseCount && JSON.parse(buyNowFlowLeaseCount);

    if (leaseCountData) {
      setLeaseCountInfo(leaseCountData);
      setTimeout(() => {
        setIsPopUpOpen(true);
      }, 1000);
    }
  }, [dashboardView]);

  const showCheckoutModal = useCallback(
    (tier?: string, newUpgrade?: boolean) => {
      if (currentPlan) {
        let initialPlan;
        if (tier) {
          const availablePlans =
            findAvailablePlansToUpgrade(plans, currentPlan) || [];
          initialPlan = availablePlans.find(
            (p) => p.displayName.toLowerCase() === tier.toLowerCase(),
          );
        } else {
          initialPlan = findNextAvailableUpgrade(plans, currentPlan);
        }

        const newUpgradeButton = () => {
          return !newUpgrade ? (
            undefined
          ) : (
            <>
              Not ready to go Premium? <u>Start with the free Basic Plan.</u>
            </>
          );
        };

        if (initialPlan) {
          dispatch(
            showModal({
              modal: {
                modalType: 'SUBSCRIPTIONS',
                modalProps: {
                  open: true,
                  hideModal,
                  initialSubscriptionModalPage: SubscriptionModalPages.payment,
                  initialPlanFeatures: initialPlan?.features,
                  initialSelectedPlan: initialPlan,
                  customCloseButton: newUpgradeButton(),
                },
              },
            }),
          );
        } else {
          // route to pricing page'
          history.push('/pricing');
        }
      }
    },
    [dispatch, plans, currentPlan, history],
  );

  useEffect(() => {
    // display upgrade checkoutModal if user navigates to leaseguru/auth/sign-in/upgrade
    const displayUpgrade = sessionStorage.getItem('displayUpgrade') ?? false;
    const tier = sessionStorage.getItem('tier') ?? false;
    const newUpgrade = sessionStorage.getItem('newUpgrade') ?? false;

    if (displayUpgrade as boolean) {
      setTimeout(() => {
        showCheckoutModal();
      }, 1000);
      sessionStorage.removeItem('displayUpgrade');
    }

    // Can also do things here to show checkout
    if (tier) {
      setTimeout(() => {
        showCheckoutModal(tier, !!newUpgrade);
      }, 1000);
      sessionStorage.removeItem('tier');
      sessionStorage.removeItem('newUpgrade');
    }
  }, [showCheckoutModal]);

  return (
    <div
      className={`dashboard-wrapper ${
        process.env.REACT_APP_ENABLE_RSM === 'true' ? 'rsm-class-dashboard' : ''
      }`}
    >
      {isPopUpOpen && leaseCountInfo ? (
        <BuyNowPopUp
          setIsPopUpOpen={setIsPopUpOpen}
          leaseCountInfo={leaseCountInfo}
        />
      ) : null}

      <div className="left-column">
        <QuickLinks
          leaseList={leaseList}
          setFilterHeader={setFilterHeader}
          updateQuickFilter={updateQuickFilter}
        />
        <FilterContainer
          activeFilters={activeFilters}
          onFilterSubmit={handleFilterSubmit}
          onFilterRemove={handleFilterRemove}
          onClearAllFilters={handleClearAllFilters}
        />
      </div>
      <div className="dashboard-content">
        <div className="dashboard-header">
          <input
            type="text"
            placeholder="search by lease name..."
            className="search-bar"
            onChange={(e) => {
              handleTextFilterSubmit(e.target.value, 'assetName', 'text');
            }}
          />
          <DashboardFilterHeader
            leaseCount={leaseList.length}
            title={filterHeader}
            setDashboardView={setDashboardView}
            isQuickFilterEnabled={quickFilter}
            disableQuickFilter={updateQuickFilter}
            currentView={dashboardView}
          />
        </div>
        {dashboardView === 1 ? (
          <>
            <LeaseListHeader handleSort={sortLeases} activeSort={activeSort} />

            <DashboardLeaseList
              leaseList={allFilteredLeases}
              addToRecentList={addToRecentList}
            />
          </>
        ) : (
          <LeasesCardView
            leases={allFilteredLeases}
            leaseCount={leaseList.length}
            maxLeases={maxLeaseCount}
            isQuickFilterEnabled={quickFilter}
            addToRecentList={addToRecentList}
            isGuestUser={isGuestUser}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
