import React, { FunctionComponent } from 'react';

import { useResourceContext } from '../../common/resource-context';
import './UnauthenticatedPage.scss';

export const UnauthenticatedPage: FunctionComponent = (props) => {
  const resources = useResourceContext();
  const termsLink = resources.text('TermsAndConditionsLink');
  return (
    <div
      className={`unauthenticatedPage ${process.env.REACT_APP_ENABLE_RSM ===
        'true' && 'rsm-class-login-page'}`}
    >
      <div className="logo">
        <img
          src={resources.img('HeaderLogo')}
          alt={resources.text('HeaderLogoAlt')}
        />
      </div>

      <div className="content">{props.children}</div>

      <div className="footer">
        <div>
          &copy; {new Date().getFullYear()} LEASEQUERY, LLC. ALL RIGHTS
          RESERVED.
        </div>
        <div className="footerLinks">
          <a href="https://leasequery.com/privacy-statement/">Privacy Policy</a>
          |<a href={termsLink}>Terms of Use</a>
        </div>
      </div>
    </div>
  );
};
