import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import {
  getActivePlans,
  getClient,
  getMultiClientPlans,
  signIn,
} from '../../api';
import { Button } from '../../common/buttons';
import Input from '../../common/input/Input';
import { useResourceContext } from '../../common/resource-context';
import {
  CheckIsMultiClientAdmin,
  doesUserHaveAccessToMultipleClients,
  isAdminOrGuest,
  setAdminSelection,
  setBearerToken,
  UserRole,
} from '../../common/utilities/_authHelpers';
import { processErrorMessage } from '../../common/utilities/_helpers';
import { loadAllThirdPartyScriptData } from '../../common/utilities/_thirdPartyScripts';
import {
  fieldHasError,
  shouldDisableSubmit,
} from '../../common/utilities/FormikHelpers';
import {
  ICompanyModel,
  ICompanySelectorRoleOption,
  IUserModel,
} from '../../interfaces';
import {
  storeCurrentAdminPlan,
  storeMultiClientPlans,
} from '../multiClient/actions';
import { storePlans } from '../plans/actions';
import { saveUser } from './actions';
import { AuthCard, AuthCardSection } from './AuthCard';
import CompanySelectionForm from './CompanySelectionForm';
import './SignIn.scss';
import SignInSuspended from './SignInSuspended';

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email address is required')
    .email('Email address must be a valid email.'),
  password: yup.string().required('Password is required'),
});

interface IFormState {
  email: string;
  password: string;
  company?: number;
}

export const SignIn: React.FC = () => {
  const dispatch = useDispatch();
  const formikRef = useRef<Formik<IFormState>>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isUserSuspended, setIsUserSuspended] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<IUserModel>();
  const [showClientSelector, setShowClientSelector] = useState<boolean>(false);
  const [clientList, setClientList] = useState<ICompanySelectorRoleOption[]>();
  const [formData, setFormData] = useState<IFormState>({
    email: '',
    password: '',
    company: undefined,
  });
  const resources = useResourceContext();
  const [isNoCompanyAccess, setIsNoCompanyAccess] = useState<boolean>(false);

  const history = useHistory();
  const { tier } = useParams<{
    tier: string;
  }>();

  useEffect(() => {
    sessionStorage.removeItem('newUpgrade');
  }, []);

  useEffect(() => {
    // display upgrade calculator if user navigates to leaseguru/auth/sign-in-upgrade
    if (history.location.pathname === '/auth/sign-in-upgrade') {
      sessionStorage.setItem('displayUpgrade', 'true');
    }
    // Get querystrings if they are for what tier so the dashboard can display things based on that
    if (tier) {
      sessionStorage.setItem('tier', tier);
    }
  }, [history, tier]);

  const onSubmit = async (values: IFormState) => {
    setFormData(values);
    setProcessing(true);

    if (showClientSelector && currentUser) {
      if (values.company) {
        // authenticate user to the selected company, which will apply the correct user role
        const user: IUserModel = await signIn(
          values.email,
          values.password,
          values.company,
        );
        setBearerToken(user.authToken);

        // load company data
        const selectedCompany: ICompanyModel = await getClient(values.company);
        setAdminSelection(values.company);

        // load all plans
        await loadPlans(selectedCompany.jurisdiction);

        user.company = selectedCompany;
        TagManager.dataLayer({
          dataLayer: {
            event: 'login',
            method: 'Email',
          },
        });

        dispatch(saveUser(user));
      }
    } else {
      try {
        const user: IUserModel = await signIn(values.email, values.password);
        setBearerToken(user.authToken);
        TagManager.dataLayer({
          dataLayer: {
            event: 'login',
            method: 'Email',
          },
        });

        if (user) {
          loadAllThirdPartyScriptData(user);
        }

        setCurrentUser(user);

        // Admin and Guest always see selector
        if (isAdminOrGuest(user.userRole)) {
          setShowClientSelector(true);
          setProcessing(false);
          return;
        }

        // Contributing sees selector if they have access to multiple clients
        if (user.userRole === UserRole.ContributingUser) {
          const {
            clientAccessList,
            hasMultipleClientAccess,
          } = await doesUserHaveAccessToMultipleClients(user.userId);

          if (hasMultipleClientAccess) {
            // pass list to avoid re-fetching
            setClientList(clientAccessList);
            setShowClientSelector(true);
            setProcessing(false);
            return;
          } else {
            // User should not see selector, continue log-in process
            setBearerToken(user.authToken);

            if (user.company.jurisdiction) {
              // load all plans
              await loadPlans(user.company.jurisdiction);
            }

            dispatch(saveUser(user));
          }
        } else {
          setBearerToken(user.authToken);

          if (user.company.jurisdiction) {
            // load all plans
            await loadPlans(user.company.jurisdiction);

            if (CheckIsMultiClientAdmin(user)) {
              await loadMultiClientPlans(user);

              dispatch(saveUser(user));
              // push only after dispatching plans and user
              history.push('/multicompany-landing');

              return;
            }

            dispatch(saveUser(user));
            return;
          }
        }
      } catch (error) {
        setProcessing(false);
        const errorMsg = processErrorMessage(error);
        if (errorMsg === 'NoCompanyAccess') {
          setErrorMessage('');
          setIsNoCompanyAccess(true);
        } else {
          setErrorMessage(errorMsg);
          setIsNoCompanyAccess(false);
        }
        if (errorMsg === 'User is Suspended') {
          setIsUserSuspended(true);
        }
      }
    }
  };

  const handleBack = () => {
    setShowClientSelector(false);
  };

  const loadPlans = async (jurisdiction?: string) => {
    const planData = await getActivePlans(jurisdiction);

    dispatch(storePlans(planData));
  };

  const loadMultiClientPlans = async (user: IUserModel) => {
    const planData = await getMultiClientPlans();
    const store = storeMultiClientPlans(planData);
    dispatch(store);
    dispatch(storeCurrentAdminPlan(user.company.plan));
  };

  return (
    <div className="signInContainer">
      {!isUserSuspended ? (
        <Formik
          ref={formikRef}
          initialValues={formData}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {(formProps) => {
            return (
              <Form noValidate={true}>
                <AuthCard>
                  {!showClientSelector ? (
                    <>
                      <AuthCardSection>
                        <div className="header">
                          <h1 className="h1">Sign In</h1>
                          <p className="error">{errorMessage}</p>
                          {isNoCompanyAccess && (
                            <div className="noCompanyError">
                              <img
                                className="icon"
                                src={require(`../../assets/warning.svg`)}
                                alt="warning"
                              />
                              <div>
                                {resources.text('UserLogin_NoCompanyAccess')}
                                <a
                                  className="sign-in-link"
                                  href={resources.text(
                                    'General_Email_MailTo',
                                    'mailto:info@leaseguru.com',
                                  )}
                                >
                                  {resources.text(
                                    'General_Email_Address',
                                    'info@leaseguru.com',
                                  )}
                                </a>
                                .
                              </div>
                            </div>
                          )}
                        </div>
                        <Input
                          label="Email Address"
                          required
                          name="email"
                          type="email"
                          error={fieldHasError(formProps, 'email')}
                          errorMessage={formProps.errors.email}
                          value={formProps.values.email}
                          onChange={formProps.handleChange}
                        />
                        <Input
                          label="Password"
                          required
                          name="password"
                          type="password"
                          error={fieldHasError(formProps, 'password')}
                          errorMessage={formProps.errors.password}
                          value={formProps.values.password}
                          onChange={formProps.handleChange}
                        />
                        <div className="submit">
                          <Button
                            buttonType="positive"
                            buttonText="Sign In"
                            type="submit"
                            block={true}
                            processing={processing}
                            processingText="Signing In"
                            disabled={
                              shouldDisableSubmit(formProps) || processing
                            }
                          />
                        </div>
                        <div className="sign-in-link-wrapper">
                          <Link to="/get-started" className="sign-in-link">
                            Create an account
                          </Link>
                          <Link
                            to={{
                              pathname: '/auth/forgot-password',
                              state: { email: formProps.values.email },
                            }}
                            className="sign-in-link"
                          >
                            Forgot your password?
                          </Link>
                        </div>
                      </AuthCardSection>
                      {/* <div className="tryNowOptions">
                      <AuthCardDividerFull />
                      <AuthCardSection>
                        <div className="tryNow">
                          New to LeaseGuru?{' '}
                          <Link to="/try-now" className="tryNowLink">
                            Try it now!
                          </Link>
                        </div>
                      </AuthCardSection>
                    </div> */}
                    </>
                  ) : (
                    currentUser && (
                      <CompanySelectionForm
                        formProps={formProps}
                        handleBack={handleBack}
                        shouldDisableSubmit={shouldDisableSubmit}
                        processing={processing}
                        authenticatedUser={currentUser}
                        clientList={clientList}
                      />
                    )
                  )}
                </AuthCard>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <SignInSuspended setIsUserSuspended={setIsUserSuspended} />
      )}
    </div>
  );
};
