import {
  convertStaticFeatureListToPlanFeatures,
  filterPlansByActive,
} from '../features/plans/helpers';
import { IPlanFeatureModel, IPlanModel } from '../interfaces';
import { api, formatError } from './api';

export async function getActivePlans(jurisdiction?: string) {
  const rawResponse = await api.get(
    jurisdiction
      ? `/Plans/GetByJurisdiction?jurisdiction=${jurisdiction}`
      : '/Plans',
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  const activePlans = filterPlansByActive(rawResponse.data);

  return activePlans;
}

const removeFeaturesDisplayOnNewPlanPage = (
  features: IPlanFeatureModel[],
): IPlanFeatureModel[] => {
  return features.filter((f) => !f.isNewPlanPage);
};

export async function getEnterpriseFeatureList(
  excludeNewFeatures: boolean = true,
) {
  const rawResponse = await api.get(
    `/Plans/GetTieredPlanFeatures?tier=Enterprise`,
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  const responseData = rawResponse.data;

  const convertedPlanFeatures = convertStaticFeatureListToPlanFeatures(
    responseData,
  );

  return excludeNewFeatures
    ? removeFeaturesDisplayOnNewPlanPage(convertedPlanFeatures)
    : convertedPlanFeatures;
}

export async function getMultiClientPlans() {
  const rawResponse = await api.get<IPlanModel[]>(`/Plans/GetMultiClientPlans`);
  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}
