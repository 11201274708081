import React, { FunctionComponent } from 'react';

const Currency: FunctionComponent<ICurrencyProps> = ({ value }) => {
  const displayValue: string =
    // checking for undefined and null allows 0 to return 0.00
    typeof value !== 'undefined' && value !== null
      ? value >= 0
        ? value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : `(${value
            .toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
            .replace('-', '')})`
      : '';
  return <>{displayValue}</>;
};

interface ICurrencyProps {
  value?: number;
}

export default Currency;
