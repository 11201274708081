import React, { FunctionComponent } from 'react';

interface IAddDetailsRowProps {
  title: string;
  value: string | undefined;
}

const AddDetailsRow: FunctionComponent<IAddDetailsRowProps> = ({
  title,
  value,
}) => (
  <div className="add-row">
    <div className="add-title">{title}</div>
    <div className="add-value">
      {value && value.trim() ? value : String.fromCharCode(8212)}
    </div>
  </div>
);

export default AddDetailsRow;
