import { IPlanModel } from '../../../interfaces';

export const initEmptySelectedPlan = (): IPlanModel => {
  return ({
    description: '',
    displayName: '',
    features: [],
    id: 0,
    isActive: false,
    name: '',
    price: 0,
    stripePlanId: '',
    tier: '',
  } as unknown) as IPlanModel;
};
