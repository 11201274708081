import { Formik, FormikContext } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as yup from 'yup';

import {
  confirmEmailCode,
  getActivePlans,
  getClient,
  sendVerificationCode,
  signIn,
  updatePassword,
} from '../../api';
import {
  doesUserHaveAccessToMultipleClients,
  isAdminOrGuest,
  setAdminSelection,
  setBearerToken,
  UserRole,
} from '../../common/utilities/_authHelpers';
import { processErrorMessage } from '../../common/utilities/_helpers';
import { PasswordSchema } from '../../common/utilities/_schemas';
import { shouldDisableSubmit } from '../../common/utilities/FormikHelpers';
import {
  ICompanyModel,
  ICompanySelectorRoleOption,
  IUserModel,
  VerificationCodes,
} from '../../interfaces';
import { saveUser } from '../auth/actions';
import CompanySelectionForm, {
  ICompanySelectionFormState,
} from '../auth/CompanySelectionForm';
import VerificationForm, {
  IVerificationFormState,
} from '../auth/VerificationForm';
import { storePlans } from '../plans/actions';
import EmailConfirmationForm, {
  IEmailConfirmationFormState,
} from './components/EmailConfirmationForm';
import UpdatePassForm, {
  IUpdatePassFormState,
} from './components/UpdatePassForm';

import './ForgotPassword.scss';

export interface IForgotPasswordState
  extends IEmailConfirmationFormState,
    IVerificationFormState,
    IUpdatePassFormState,
    ICompanySelectionFormState {}

enum StepNumber {
  Email,
  Verify,
  ResetPassword,
  CompanySelection,
}

type ForgotPasswordFormValues =
  | IEmailConfirmationFormState
  | IVerificationFormState
  | IUpdatePassFormState
  | ICompanySelectionFormState;

const schemas = {
  [StepNumber.Email]: yup.object().shape({
    email: yup
      .string()
      .required('Email address is required.')
      .email('Email address must be a valid email.'),
  }),
  [StepNumber.Verify]: yup.object().shape({
    verificationCode: yup
      .string()
      .required('A verification number is required'),
  }),
  [StepNumber.ResetPassword]: yup.object().shape({
    password: PasswordSchema.required('Please enter your new password'),
    confirmPassword: yup
      .string()
      .required('Please confirm your new password')
      .oneOf([yup.ref('password')], 'Passwords must match'),
  }),
  [StepNumber.CompanySelection]: yup.object().shape({
    company: yup.number().required('Please select a company'),
  }),
};

export const ForgotPassword: FunctionComponent<RouteComponentProps> = ({
  location: { state },
}: {
  location: { state: any };
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<IForgotPasswordState>({
    email: state.email || '',
    verificationCode: '',
    password: '',
    confirmPassword: '',
    company: undefined,
  });
  const [stepNumber, setStepNumber] = useState(StepNumber.Email);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [verifyCode, setVerifyCode] = useState<number>();
  const [currentUser, setCurrentUser] = useState<IUserModel>();
  const [processing, setProcessing] = useState<boolean>(false);
  const [clientList, setClientList] = useState<ICompanySelectorRoleOption[]>();

  const renderForm = (step: StepNumber) => {
    switch (step) {
      case StepNumber.Email:
        return (
          <Formik
            key={step}
            initialValues={{ email: formData.email }}
            validationSchema={schemas[step]}
            onSubmit={onSubmit}
          >
            {(formProps: FormikContext<IEmailConfirmationFormState>) => {
              return (
                <EmailConfirmationForm
                  formProps={formProps}
                  processing={processing}
                />
              );
            }}
          </Formik>
        );

      case StepNumber.Verify:
        return (
          <Formik
            key={step}
            initialValues={{
              verificationCode: formData.verificationCode,
            }}
            validationSchema={schemas[step]}
            onSubmit={onSubmit}
          >
            {(formProps: FormikContext<IVerificationFormState>) => {
              return (
                <VerificationForm
                  formProps={formProps}
                  setStepNumber={setStepNumber}
                  emailAddress={formData.email}
                  stepNumber={stepNumber}
                  errorMessage={errorMessage}
                  processing={processing}
                />
              );
            }}
          </Formik>
        );
      case StepNumber.ResetPassword:
        return (
          <Formik
            key={step}
            initialValues={{
              password: formData.password,
              confirmPassword: formData.confirmPassword,
            }}
            validationSchema={schemas[step]}
            onSubmit={onSubmit}
          >
            {(formProps: FormikContext<IUpdatePassFormState>) => {
              return (
                <UpdatePassForm
                  formProps={formProps}
                  processing={processing}
                  errorMessage={errorMessage}
                />
              );
            }}
          </Formik>
        );
      case StepNumber.CompanySelection:
        return (
          currentUser && (
            <Formik
              key={step}
              initialValues={{ company: formData.company }}
              validationSchema={schemas[step]}
              onSubmit={onSubmit}
            >
              {(formProps: FormikContext<ICompanySelectionFormState>) => {
                return (
                  <CompanySelectionForm
                    formProps={formProps}
                    shouldDisableSubmit={shouldDisableSubmit}
                    processing={processing}
                    authenticatedUser={currentUser}
                    clientList={clientList}
                  />
                );
              }}
            </Formik>
          )
        );
    }
  };

  async function handleVerifyEmail(email: string) {
    try {
      const response = await sendVerificationCode(
        email,
        VerificationCodes.ResetPassword,
      );
      return response;
    } catch (error) {
      setErrorMessage(processErrorMessage(error));
    }
  }

  async function handleConfirmation(confirmId: number, emailCode: string) {
    try {
      const user: IUserModel = await confirmEmailCode(confirmId, emailCode);
      if (user && user.authToken) {
        setBearerToken(user.authToken);
        return user.authToken;
      }
    } catch (error) {
      setErrorMessage(processErrorMessage(error));
    }
  }

  async function handleUpdatePass(password: string) {
    let responseSuccessful = true;

    try {
      await updatePassword(password);
    } catch (error) {
      responseSuccessful = false;
      setErrorMessage(processErrorMessage(error));
    }
    return responseSuccessful;
  }

  const loadPlans = async (jurisdiction?: string) => {
    const planData = await getActivePlans(jurisdiction);

    dispatch(storePlans(planData));
  };

  async function handleSignIn(email: string, password: string) {
    try {
      const user: IUserModel = await signIn(email, password);

      if (
        isAdminOrGuest(user.userRole) ||
        user.userRole === UserRole.ContributingUser
      ) {
        setCurrentUser(user);
      } else {
        setBearerToken(user.authToken);

        if (user.company.jurisdiction) {
          await loadPlans(user.company.jurisdiction);
        }

        dispatch(saveUser(user));
      }
      return user;
    } catch (error) {
      setErrorMessage(processErrorMessage(error));
    }
  }

  const onSubmit = async (values: ForgotPasswordFormValues) => {
    const updatedFormData: IForgotPasswordState = { ...formData, ...values };
    const { email, password } = updatedFormData;
    setFormData(updatedFormData);
    setErrorMessage('');
    setProcessing(true);
    switch (stepNumber) {
      case StepNumber.Email:
        const verifyResponse = await handleVerifyEmail(updatedFormData.email);
        if (verifyResponse) {
          setVerifyCode(verifyResponse);
          setStepNumber(StepNumber.Verify);
        }
        break;
      case StepNumber.Verify:
        if (verifyCode) {
          const token = await handleConfirmation(
            verifyCode,
            updatedFormData.verificationCode,
          );

          if (token) {
            setStepNumber(StepNumber.ResetPassword);
          }
        }
        break;
      case StepNumber.ResetPassword:
        const updatePassSuccess = await handleUpdatePass(password);
        if (updatePassSuccess) {
          const user = await handleSignIn(email, password);

          if (user && user.authToken) {
            setBearerToken(user.authToken);
          }

          if (user && isAdminOrGuest(user.userRole)) {
            setStepNumber(StepNumber.CompanySelection);
          }

          if (user && user.userRole === UserRole.ContributingUser) {
            const {
              clientAccessList,
              hasMultipleClientAccess,
            } = await doesUserHaveAccessToMultipleClients(user.userId);

            if (hasMultipleClientAccess) {
              setClientList(clientAccessList);
              setStepNumber(StepNumber.CompanySelection);
            } else {
              setBearerToken(user.authToken);

              if (user.company.jurisdiction) {
                await loadPlans(user.company.jurisdiction);
              }

              dispatch(saveUser(user));
            }
          }
        }
        break;
      case StepNumber.CompanySelection:
        let selectedCompany: ICompanyModel = {};
        if (updatedFormData.company) {
          // authenticate user to the selected company, which will apply the correct user role
          const user: IUserModel = await signIn(
            email,
            password,
            updatedFormData.company,
          );
          setBearerToken(user.authToken);

          // load company data
          selectedCompany = await getClient(updatedFormData.company);
          setAdminSelection(updatedFormData.company);

          await loadPlans(selectedCompany.jurisdiction);

          user.company = selectedCompany;

          dispatch(saveUser(user));
        }
        break;
    }
    setProcessing(false);
  };

  return (
    <div className="forgotPasswordContainer">{renderForm(stepNumber)}</div>
  );
};
