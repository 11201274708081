import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../common/buttons';
import { AccentedHeader } from '../../common/headers';
import StyledLink from '../../common/link/StyledLink';
import { hideModal } from '../../common/modal/actions';
import { useResourceContext } from '../../common/resource-context';
import { IUserModel } from '../../interfaces';
import { IStore } from '../../store';

import './PaymentConfirmationPage.scss';

interface IPaymentConfirmationProps {
  totalWithTax?: string;
}

const PaymentConfirmation: React.FC<IPaymentConfirmationProps> = ({
  totalWithTax,
}) => {
  const resources = useResourceContext();
  const dispatch = useDispatch();
  const loggedInUser: IUserModel = useSelector((state: IStore) => state.user);

  return (
    <div className="payment-confirmation-wrapper">
      <AccentedHeader color="blue" centered>
        Thank you for your order!
      </AccentedHeader>
      <div className="confirmation-text">
        <div>Your subscription of {totalWithTax} is complete.</div>
        <div>
          A receipt was sent to <b>{loggedInUser.company?.contactEmail}</b>.
        </div>
        <div>
          If you have any questions about your order, please contact us at
        </div>
        <StyledLink
          href={resources.text(
            'General_Email_MailTo',
            'mailto:info@leaseguru.com',
          )}
        >
          {resources.text('General_Email_Address', 'info@leaseguru.com')}
        </StyledLink>
      </div>
      <div className="confirmation-button">
        <Button
          type="submit"
          buttonType="positive"
          buttonText="Close"
          onClick={() => dispatch(hideModal())}
        />
      </div>
    </div>
  );
};

export default PaymentConfirmation;
