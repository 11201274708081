import Downshift from 'downshift';
import React, { useEffect, useState } from 'react';

import { AutocompleteType } from '../../interfaces';
import FormError from '../form-error/FormError';

import './Autocomplete.scss';

export const UserRoleTypes: { [key: number]: string } = {
  1: 'Admin',
  3: 'Contributing',
  6: 'Guest',
};

const Autocomplete = <T extends {}>({
  label,
  id,
  options,
  filterableOptions = options,
  required,
  placeholder,
  onSelection,
  error,
  errorMessage,
  value,
  onSelectionClear,
  errorSize,
  autoCompleteType,
}: IAutocompleteProps<T>) => {
  const [itemLabel, setItemLabel] = useState('');
  const clientSelectorType =
    autoCompleteType === AutocompleteType.ClientSelector;

  useEffect(() => {
    const getItemLabel = () => {
      if (value) {
        const selectedOption = options.find((option) => option.value === value);
        setItemLabel(
          selectedOption && selectedOption.label ? selectedOption.label : '',
        );
      }
    };

    if (value) {
      getItemLabel();
    }
  }, [itemLabel, value, options]);

  const buildCompanyOption = (
    item: IAutocompleteOption<T>,
    index: number,
  ): JSX.Element => {
    const isUserContributor = index === 0 && item.userRole! === 3;

    return (
      <div
        className={
          isUserContributor
            ? 'autocomplete-client-item contributor'
            : 'autocomplete-client-item'
        }
      >
        <div>{item.label || item.value}</div>
        <div className={'autocomplete-client-item-role'}>
          <div>{UserRoleTypes[item.userRole!]}</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Downshift
        itemToString={(item) => {
          return item ? item.label || item.value : '';
        }}
        onChange={(selectedItem) => {
          onSelection(selectedItem);
        }}
        onInputValueChange={(val: string) => {
          setItemLabel(val);
        }}
        stateReducer={(state, changes) => {
          // Do not clear search input content on blur
          if (changes.type === Downshift.stateChangeTypes.blurInput) {
            return state;
          }

          return { ...state, ...changes };
        }}
        initialInputValue={options.find((o) => o.value === value)?.label}
      >
        {({
          getLabelProps,
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          clearSelection,
        }) => (
          <div className="autocomplete-wrapper">
            <label
              {...getLabelProps()}
              className={`autocomplete-label ${required ? 'required' : ''}`}
            >
              {label}
            </label>
            <input
              {...getInputProps({
                onChange: (e) => {
                  if (e.target.value === '') {
                    if (onSelectionClear) {
                      clearSelection();
                      onSelectionClear();
                    }
                  }
                },
              })}
              className={`autocomplete-input ${
                error ? ' form-input error' : ''
              }`}
              placeholder={placeholder}
              id={id}
            />
            <ul
              {...getMenuProps()}
              className={
                isOpen
                  ? clientSelectorType
                    ? 'clientSelector-menu'
                    : 'autocomplete-menu'
                  : 'autocomplete-menu--closed'
              }
            >
              {isOpen
                ? filterableOptions
                    .filter(
                      (option) =>
                        !inputValue ||
                        (option.label || String(option.value))
                          .toLocaleLowerCase()
                          .includes(inputValue.toLowerCase()),
                    )
                    .map((item, index) => (
                      <li
                        {...getItemProps({
                          item,
                          index,
                        })}
                        key={`${item.key}-${item.value}-${index}`}
                        className={
                          highlightedIndex === index ? 'option-active' : ''
                        }
                      >
                        {clientSelectorType ? (
                          buildCompanyOption(item, index)
                        ) : (
                          <>{item.label || item.value}</>
                        )}
                      </li>
                    ))
                : null}
            </ul>
          </div>
        )}
      </Downshift>
      <FormError error={error} errorMessage={errorMessage} size={errorSize} />
    </div>
  );
};

interface IAutocompleteProps<T> {
  onSelection: (selectedItem: IAutocompleteOption<T>) => void;
  onSelectionClear?: () => void;
  options: Array<IAutocompleteOption<T>>;
  filterableOptions?: Array<IAutocompleteOption<T>>;
  id?: string;
  value?: T;
  label?: string;
  required?: boolean;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  errorSize?: string;
  autoCompleteType?: AutocompleteType;
}

export interface IAutocompleteOption<T> {
  key: number;
  value: T;
  label: string;
  userRole?: number;
}

export default Autocomplete;
