import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import ReactTable from 'react-table';
import lock from '../../../../assets/bluelock.svg';
import exclamation from '../../../../assets/exclamation.svg';
import graphic from '../../../../assets/graphic.svg';
import { ButtonLink } from '../../../../common/buttons';
import { hideModal, showModal } from '../../../../common/modal/actions';
import { useResourceContext } from '../../../../common/resource-context';
import Tooltip from '../../../../common/tooltips/Tooltip';
import { formatCurrencyValue } from '../../../../common/utilities/_helpers';
import {
  ClientIntegration,
  IJournalEntryModel,
  PlanFeatureNames,
} from '../../../../interfaces';
import { IStore } from '../../../../store';
import { checkIfUserHasAccessToFeature } from '../../../plans/helpers';
import { checkBalance } from '../BalanceChecker';
import '../table-styles.scss';
import {
  journalEntryColumnConfiguration,
  JournalEntryColumnDefaults,
} from './journal-entries-columns';
import './JournalEntries.scss';
import { JEExportOptions } from './JournalEntriesContainer';
import './table-styles-overrides.scss';

const columnConfiguration = journalEntryColumnConfiguration();

interface IProps {
  data: IJournalEntryModel[];
  loading: boolean;
  exportJE?: (exportTo: JEExportOptions) => void;
  headerText?: string;
  isTotalOrg?: boolean;
  ERPConnected?: boolean;
}

const integrationsIsEnabled =
  process.env.REACT_APP_ENABLE_EXTERNAL_TOOLS === 'true';

export const JournalEntriesTable: FunctionComponent<IProps> = ({
  data,
  exportJE,
  headerText,
  isTotalOrg,
  ERPConnected,
}) => {
  const resources = useResourceContext();
  const dispatch = useDispatch();

  const loggedInUser = useSelector((state: IStore) => {
    return state.user;
  });

  const features = loggedInUser.company.plan?.features;

  const userHasAccessToExportJE = useMemo(
    () =>
      checkIfUserHasAccessToFeature(
        PlanFeatureNames.exportJE,
        features,
        loggedInUser.userRole === 1,
      ),
    [features, loggedInUser],
  );

  const userHasAccessToExportTotalOrg = useMemo(
    () =>
      checkIfUserHasAccessToFeature(
        PlanFeatureNames.exportTotalOrg,
        features,
        loggedInUser.userRole === 1,
      ),
    [features, loggedInUser],
  );

  const userHasAccessToQuickbooks = useMemo(
    () =>
      checkIfUserHasAccessToFeature(
        PlanFeatureNames.quickbooks,
        features,
        loggedInUser.userRole === 1,
      ),
    [features, loggedInUser],
  );

  const userHasAccessToSage = useMemo(
    () =>
      checkIfUserHasAccessToFeature(
        PlanFeatureNames.sage,
        features,
        loggedInUser.userRole === 1,
      ),
    [features, loggedInUser],
  );

  const clientIntegrationType = useSelector((state: IStore) => {
    return state.user.company?.clientIntegrations;
  });

  let integrationName = '';
  if (clientIntegrationType !== undefined && clientIntegrationType !== null) {
    switch (clientIntegrationType[0]) {
      case ClientIntegration.SAGE:
        integrationName = 'Sage';
        break;
      case ClientIntegration.XERO:
        integrationName = 'Xero';
        break;
      case ClientIntegration.QUICKBOOKS:
        integrationName = 'Quickbooks';
        break;
      default:
        integrationName = '';
        break;
    }
  }
  const [totalCredit, setTotalCredit] = useState<number>();
  const [totalDebit, setTotalDebit] = useState<number>();
  const [isBalanced, setIsBalanced] = useState<boolean>(false);

  useEffect(() => {
    const balanceObject = checkBalance(data);
    setTotalDebit(balanceObject.totalDebitSum);
    setTotalCredit(balanceObject.totalCreditSum);
    setIsBalanced(balanceObject.isBalanced);
  }, [data]);

  const showUpgradeModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'UPGRADE_TIER',
          modalProps: {
            open: true,
            hideModal: () => dispatch(hideModal()),
          },
        },
      }),
    );
  };

  const handleExportClick = (
    exportFunction: (exportTo: JEExportOptions) => void,
  ) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'journal_entry_interaction',
        journal_entry_action: isTotalOrg
          ? 'Download Total Org to Excel'
          : 'Download Individual to Excel',
      },
    });
    if (isTotalOrg && userHasAccessToExportTotalOrg) {
      return exportFunction('totalOrg');
    }

    if (!isTotalOrg && userHasAccessToExportJE) {
      return exportFunction('download');
    }

    return showUpgradeModal();
  };

  const handleExportLock = () => {
    const lockElement = (
      <img src={lock} alt="header-lock" className="dropdown-lock" />
    );

    if (isTotalOrg) {
      return userHasAccessToExportTotalOrg ? null : lockElement;
    } else {
      return userHasAccessToExportJE ? null : lockElement;
    }
  };

  return (
    <div className="journal-entries-table">
      <div className="table-container">
        <div className="table-header">
          <div className="header-title">{headerText}</div>
          <div className="header-label" />
          <div className="header-actions">
            {exportJE ? (
              <div className="div-lock">
                <ButtonLink
                  id="button=link-text"
                  text={
                    isTotalOrg
                      ? 'Download Total Org to Excel'
                      : 'Export To Excel'
                  }
                  styles="action link-text"
                  onClick={() => handleExportClick(exportJE)}
                />
                {handleExportLock()}
              </div>
            ) : null}
            {exportJE &&
            !isTotalOrg &&
            ERPConnected &&
            integrationsIsEnabled ? (
              <div className="div-lock">
                <ButtonLink
                  id="button=link-text"
                  text="Export to QuickBooks"
                  styles="action link-text"
                  onClick={
                    userHasAccessToQuickbooks
                      ? () => exportJE('quickbooks')
                      : showUpgradeModal
                  }
                />
                {userHasAccessToQuickbooks ? null : (
                  <img src={lock} alt="header-lock" className="header-lock" />
                )}
              </div>
            ) : null}
            {/* {exportJE && isTotalOrg && ERPConnected && premiumEnabled ? (
              <div className="div-lock">
                <ButtonLink
                  id="button=link-text"
                  text="Export to QuickBooks"
                  styles="action link-text"
                  onClick={
                    userHasAccessToQuickbooks
                      ? () => exportJE('quickbooks')
                      : showUpgradeModal
                  }
                />
                {userHasAccessToQuickbooks ? null : (
                  <img src={lock} alt="header-lock" className="header-lock" />
                )}
              </div>
            ) : null} */}
            {exportJE && !isTotalOrg && integrationName === 'Sage' ? (
              <div className="div-lock">
                <ButtonLink
                  id="button=link-text"
                  text={`Export to ${integrationName}`}
                  styles="action link-text"
                  onClick={
                    userHasAccessToSage
                      ? () => exportJE('sage')
                      : showUpgradeModal
                  }
                />
                {userHasAccessToSage ? null : (
                  <img src={lock} alt="header-lock" className="header-lock" />
                )}
              </div>
            ) : null}
          </div>
        </div>
        <ReactTable
          columns={columnConfiguration}
          column={JournalEntryColumnDefaults}
          data={data}
          minRows={1}
          defaultPageSize={data.length}
          showPagination={false}
          sortable={false}
        />

        <div
          className={
            isBalanced ? 'balance-checker' : 'balance-checker un-balanced'
          }
        >
          <div className="total">
            {!isBalanced ? (
              <Tooltip
                image={exclamation}
                tooltipPosition="top-left"
                footer={<img src={graphic} alt="graphic" />}
                showImageOnHover
              >
                <div className="oops">
                  <span>
                    Well... something seems unbalanced. Our developers have been
                    notified and are working on it. Sorry for the inconvenience.
                  </span>
                  <span className="contact">
                    To follow up, please contact{' '}
                    <a
                      href={resources.text(
                        'General_Email_Mailto',
                        'mailto:info@leaseguru.com',
                      )}
                    >
                      {resources.text(
                        'General_Email_Address',
                        'info@leaseguru.com',
                      )}
                    </a>
                  </span>
                </div>
              </Tooltip>
            ) : null}
            <span className="total-label">TOTAL</span>
          </div>
          <span className="total-credits">
            {formatCurrencyValue(totalCredit)}
          </span>
          <span className="total-debits">
            {formatCurrencyValue(totalDebit)}
          </span>
        </div>
      </div>
    </div>
  );
};
