import { FormikContext } from 'formik';
import get from 'lodash/get';

export const shouldDisableSubmit = (formProps: FormikContext<any>): boolean => {
  return formProps.submitCount > 0 && !formProps.isValid;
};

export const fieldHasError = (
  formProps: FormikContext<any>,
  name: string,
): boolean => {
  return formProps.submitCount > 0 && !!get(formProps.errors, name);
};

export const joinPath = (suffix: string, path: string) => {
  if (path.length > 0) {
    let joinChar = '.';
    if (suffix.charAt(0) === '[') {
      joinChar = '';
    }
    return [path, suffix].join(joinChar);
  }
  return suffix;
};
