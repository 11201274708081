import React, { FunctionComponent } from 'react';

import './TripleDotLoader.scss';

interface ITripleDotLoader {
  text: string;
}

const TripleDotLoader: FunctionComponent<ITripleDotLoader> = ({ text }) => {
  return (
    <div className="saving">
      {text} <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  );
};

export default TripleDotLoader;
