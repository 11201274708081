import React, { FunctionComponent, ReactNode, useState } from 'react';
import rsm_trash_can from '../../assets/rsm-trash.svg';
import trash_can from '../../assets/trash.svg';
import uh_oh from '../../assets/UHOH.svg';

import './ExpandSection.scss';

const ExpandSection: FunctionComponent<IExpandSectionProps> = ({
  id,
  children,
  label,
  open,
  hideContentBorder,
  labelContent,
  labelContentCenter,
  handleDelete,
  error,
  headerImage,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  return (
    <div className="expand-section-wrap">
      <div
        className={`expand-clicky ${
          !isOpen && error ? 'expand-clicky--error' : ''
        }`}
        onClick={() => setIsOpen(!isOpen)}
        id={id}
      >
        <div
          className={`expand-caret ${
            isOpen ? 'expand-caret--open' : 'expand-caret--closed'
          }`}
        />
        {!isOpen && error ? (
          <div className="expand-error-icon">
            <img src={uh_oh} alt="error" />
          </div>
        ) : null}
        <div
          className={`expand-label ${!isOpen && error ? 'expand-error' : ''}`}
        >
          {label}
          {labelContent !== undefined ? (
            <span className="label-contents">{labelContent}</span>
          ) : null}
        </div>
        {labelContentCenter !== undefined ? (
          <div className="label-info">
            <span className="label-contents-middle">{labelContentCenter}</span>
          </div>
        ) : null}
        {headerImage !== undefined ? (
          <div className="header-img">{headerImage}</div>
        ) : null}
        {handleDelete !== undefined ? (
          <img
            src={
              process.env.REACT_APP_ENABLE_RSM === 'true'
                ? rsm_trash_can
                : trash_can
            }
            alt="delete"
            onClick={handleDelete}
          />
        ) : null}
      </div>
      {isOpen && (
        <div
          className={`expand-section-content ${
            hideContentBorder ? 'hide-border' : ''
          }`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

interface IExpandSectionProps {
  id?: string;
  children: ReactNode;
  label?: string;
  open?: boolean;
  hideContentBorder?: boolean;
  labelContent?: string;
  labelContentCenter?: string;
  handleDelete?: () => void;
  stayOpen?: boolean;
  error?: boolean;
  headerImage?: React.ReactNode;
}

export default ExpandSection;
