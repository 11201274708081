import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hideModal, showModal } from '../../../common/modal/actions';
import { useResourceContext } from '../../../common/resource-context';
import { IStore } from '../../../store';
import {
  checkIfUserIsOnMaxCPAPlan,
  checkIfUserIsOnMaxPlan,
  findNextAvailableUpgrade,
  getFeatureValue,
} from '../../plans/helpers';

import RSMcta from '../../../assets/rsm-cta.svg';
import upgradeCity from '../../../assets/upgrade-city.svg';
import {
  applyRSMClassName,
  isEnvRSM,
} from '../../../common/utilities/_helpers';
import { IPlanFeatureModel, IPlanModel } from '../../../interfaces';
import { SubscriptionModalPages } from '../../subscriptions/SubscriptionModal';

import { CheckIsMultiClientAdmin } from '../../../common/utilities/_authHelpers';
import './MaxLeaseCardGrid.scss';

interface MaxLeaseCardGridProps {
  leaseCount: number;
  maxLeases: number;
}

const MaxLeaseCardGrid: FunctionComponent<MaxLeaseCardGridProps> = ({
  leaseCount,
  maxLeases,
}) => {
  const resources = useResourceContext();
  const user = useSelector((state: IStore) => {
    return state.user;
  });

  const isMultiClientAdmin = CheckIsMultiClientAdmin(user);

  const allPlans = useSelector((state: IStore) => {
    return state.plans;
  });

  const userIsOnMaxPlan =
    checkIfUserIsOnMaxPlan(user, allPlans) ||
    checkIfUserIsOnMaxCPAPlan(user.company.plan, leaseCount, maxLeases);
  const isUserOnPremiumPlan = (user.company.plan?.price || 0) > 0;
  const nextAvailablePlan = findNextAvailableUpgrade(
    allPlans,
    user.company?.plan as IPlanModel,
  );
  const nextPlanLeaseCount = getFeatureValue(
    'Lease Count',
    nextAvailablePlan?.features as IPlanFeatureModel[],
  );

  const showCheckoutModal = (
    availablePlanToUpgradeTo: IPlanModel | undefined,
  ) => {
    dispatch(
      showModal({
        modal: {
          modalType: 'SUBSCRIPTIONS',
          modalProps: {
            open: true,
            hideModal,
            initialSubscriptionModalPage: SubscriptionModalPages.payment,
            initialPlanFeatures: availablePlanToUpgradeTo?.features,
            initialSelectedPlan: availablePlanToUpgradeTo,
          },
        },
      }),
    );
  };

  const displayUpgradeOptions = () => {
    if (userIsOnMaxPlan) {
      const enterpriseUrl = resources.text('EnterprisePricingUrl');
      window.open(enterpriseUrl, '_blank');
    } else {
      showCheckoutModal(nextAvailablePlan);
    }
  };

  const dispatch = useDispatch();
  const getLeaseMaxCardHeader = () => {
    return (
      <span className="max-lease-header">
        {isMultiClientAdmin && !userIsOnMaxPlan ? (
          <>
            Purchase now to add up <br />
            to 10 leases per company.
          </>
        ) : (
          <>
            You’ve reached the
            <br />
            max number of leases.
          </>
        )}
      </span>
    );
  };

  const getLeaseMaxCardDescription = () => {
    return userIsOnMaxPlan ? (
      <>
        For unlimited leases and advanced features, consider upgrading to{' '}
        <strong>LeaseQuery.</strong>
      </>
    ) : isMultiClientAdmin ? (
      <div>
        Up to 5 companies at <br />
        <span className="next-active-plan"> $500 per company, yearly </span>
      </div>
    ) : (
      <div>
        Consider {resources.text('SageLogin_CredentialText', 'LeaseGuru')}{' '}
        Premium at
        <div className="next-active-plan">
          {nextPlanLeaseCount} leases for ${nextAvailablePlan?.price}/yr
          {isUserOnPremiumPlan ? '.' : ','}
        </div>
        {isUserOnPremiumPlan ? <br /> : 'plus premium features.'}
      </div>
    );
  };

  const isRSM = isEnvRSM();

  return (
    <div className={applyRSMClassName('max-lease-card-wrapper')}>
      <div className="max-lease-card-body">
        {getLeaseMaxCardHeader()}
        <span className="max-lease-description">
          {getLeaseMaxCardDescription()}
        </span>
        <button className="cta-button" onClick={() => displayUpgradeOptions()}>
          {userIsOnMaxPlan
            ? 'Request Demo'
            : isMultiClientAdmin
            ? 'BUY NOW'
            : 'Upgrade'}
        </button>
        {!isMultiClientAdmin ? (
          <a className="pricing" href="/pricing">
            View All Plans
          </a>
        ) : (
          <a className="pricing" href="/multicompany-pricing">
            View All Plans
          </a>
        )}
      </div>
      <div className="image-footer">
        <img
          src={`${isRSM ? RSMcta : upgradeCity}`}
          alt="cta"
          className={`${isRSM ? 'cta-image-rsm' : 'cta-image'}`}
        />
      </div>
    </div>
  );
};

export default MaxLeaseCardGrid;
