import { Form, Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { hideModal, showModal } from '../../common/modal/actions';

import { Input } from '../../common';
import { InlineButton } from '../../common/buttons';
import FormGrid from '../../common/form-grid/FormGrid';
import TripleDotLoader from '../../common/loaders/TripleDotLoader';
import { IUserModel, PlanTier } from '../../interfaces';
import { IStore } from '../../store';
import { getFeatureValue } from '../plans/helpers';
import { UserListType } from './ManageUsersModal';
import ManageUsersRow from './ManageUsersRow';
import MoreUsersUpgradeLink from './MoreUsersUpgradeLink';

import { CheckIsMultiClientAdmin } from '../../common/utilities/_authHelpers';
import './ManageUsersModal.scss';

interface IManageUsersFormState {
  email: string;
}

interface IManageUsersListProps {
  userList?: IUserModel[];
  errorMessage: string;
  setErrorMessage: (value: string) => void;
  createUser: (user: IUserModel) => void;
  deleteUser: (id: number) => void;
  resendUserAccess: (user: IUserModel) => void;
  accessProcessing: boolean;
  removeProcessing: boolean;
  domain: string;
  userListType: UserListType;
  handleUndoRemovedUser: (userToUndo: IUserModel, type: UserListType) => void;
  allowGuestUserAccess?: boolean;
}

const ManageUsersList: React.FC<IManageUsersListProps> = ({
  domain,
  userList,
  createUser,
  deleteUser,
  errorMessage,
  setErrorMessage,
  resendUserAccess,
  accessProcessing = false,
  removeProcessing = false,
  userListType,
  handleUndoRemovedUser,
  allowGuestUserAccess = false,
}) => {
  const dispatch = useDispatch();
  const [showAddUserForm, setShowAddUserForm] = useState<boolean>(
    userListType === UserListType.Guest && allowGuestUserAccess,
  );
  const formikRef = useRef<Formik<IManageUsersFormState>>(null);

  const loggedInUser = useSelector((state: IStore) => {
    return state.user;
  });

  const planAllowsUsers = getFeatureValue(
    'Users',
    loggedInUser.company.plan?.features ?? [],
  );

  const isMultiClientAdmin = CheckIsMultiClientAdmin(loggedInUser);

  const features = loggedInUser.company.plan?.features;
  const featuresName =
    userListType === UserListType.Guest ? 'Guest User' : 'Users';
  const maxUsers: number = features
    ? Number(getFeatureValue(featuresName, features))
    : 0;

  // need to remove the current users that can be undone.
  const numberOfUndoUsers =
    userList?.filter((user) => user.canUndoUser).length || 0;

  const maxUsersReached =
    userList && userList.length - numberOfUndoUsers >= maxUsers;

  const objSchema =
    userListType === UserListType.Guest
      ? {
          email: yup
            .string()
            .required('You must enter an email')
            .email('Email address must be a valid email.'),
        }
      : {
          email: yup
            .string()
            .required('You must enter an email')
            .email('Email address must be a valid email.')
            .test('len', 'Email must be the same domain', (val) => {
              setErrorMessage('');
              return val ? val.split('@')[1] === domain : false;
            }),
        };
  const schema = yup.object().shape(objSchema);

  const showUpgradeModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'UPGRADE_TIER',
          modalProps: {
            open: true,
            hideModal: () => dispatch(hideModal()),
          },
        },
      }),
    );
  };

  // handle email input
  const handleReset = () => {
    setErrorMessage('');
    formikRef.current!.resetForm();
    setShowAddUserForm(false);
  };

  const handleDeleteUser = (id: number) => {
    deleteUser(id);

    if (userListType === UserListType.Contributing) {
      setShowAddUserForm(false);
    }
  };

  // handling create new user with email
  const addUser = (newUserEmail: string) => {
    const newUser: IUserModel = {
      email: newUserEmail,
      company: {},
    };
    createUser(newUser);
  };

  const handleSubmit = (values: IManageUsersFormState) => {
    addUser(values.email);
    formikRef.current!.resetForm();
  };

  const displayProperForm = (formProps: FormikProps<IManageUsersFormState>) => {
    // Does not show contributing users session if user is CPA Admin
    if (!isMultiClientAdmin) {
      const planTier = loggedInUser.company.plan?.tier;
      if (planTier === PlanTier.BASIC || !planAllowsUsers) {
        return userListType === UserListType.Contributing ? (
          <div className="max-user-label">
            <label>
              You’ve reached the max number of users under your current plan.
            </label>
            <button className="button-request" onClick={showUpgradeModal}>
              Upgrade
            </button>
          </div>
        ) : null;
      }
      // if max users has been reached, we don't want to show anything except the message
      // only show the upgrade on the contributors list
      else if (maxUsersReached) {
        return userListType === UserListType.Contributing ? (
          <MoreUsersUpgradeLink userType="contributing" />
        ) : null;
      }
    }

    // if max users hasn't been reached, show input form
    // show input if guest section and guest access is toggled on
    // show input if contribute section and showAddUserForm is true
    // show input if CPA Admin and guest access is toggled on
    if (
      (userListType === UserListType.Guest && allowGuestUserAccess) ||
      (userListType === UserListType.Contributing && showAddUserForm) ||
      (isMultiClientAdmin && allowGuestUserAccess)
    ) {
      return (
        <div className="add-user-input">
          <Input
            label=""
            required
            name="email"
            type="email"
            error={!!formProps.errors.email}
            errorMessage={formProps.errors.email}
            value={formProps.values.email}
            onChange={formProps.handleChange}
            placeholder={'Please enter an email...'}
          />
          <button className="buttons add add-button" type="submit">
            {accessProcessing ? <TripleDotLoader text="Add" /> : 'Add'}
          </button>
          {userListType === UserListType.Contributing && (
            <button
              className="buttons close close-button"
              onClick={() => handleReset()}
            >
              Cancel
            </button>
          )}
        </div>
      );
    }

    // if max users hasn't been reached, and the form isn't being shown, show the button only for contributing form
    return userListType === UserListType.Contributing ? (
      <FormGrid colWidth={1}>
        <InlineButton
          buttonText="Add Additional User"
          onClick={() => setShowAddUserForm(true)}
          iconType="blue-plus"
          type="button"
        />
      </FormGrid>
    ) : null;
  };

  return (
    <Formik
      ref={formikRef}
      initialValues={{
        email: '',
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
      validateOnChange={false}
    >
      {(formProps: FormikProps<IManageUsersFormState>) => {
        return (
          <Form noValidate={true}>
            {userListType === UserListType.Guest && maxUsers === 1 ? (
              <>
                {displayProperForm(formProps)}
                {errorMessage ? <p className="error">{errorMessage}</p> : null}
                <div className="user-list">
                  {userList &&
                    userList.map((user, index) => {
                      return (
                        <ManageUsersRow
                          key={index}
                          user={user}
                          deleteUser={handleDeleteUser}
                          resendUserAccess={resendUserAccess}
                          removeProcessing={removeProcessing}
                          userListType={userListType}
                          handleUndoRemovedUser={handleUndoRemovedUser}
                          domain={domain}
                        />
                      );
                    })}
                </div>
              </>
            ) : (
              <>
                <div className="user-list">
                  {userList &&
                    userList.map((user, index) => {
                      return (
                        <ManageUsersRow
                          key={index}
                          user={user}
                          deleteUser={handleDeleteUser}
                          resendUserAccess={resendUserAccess}
                          removeProcessing={removeProcessing}
                          userListType={userListType}
                          handleUndoRemovedUser={handleUndoRemovedUser}
                          domain={domain}
                        />
                      );
                    })}
                </div>
                {displayProperForm(formProps)}
                {errorMessage ? <p className="error">{errorMessage}</p> : null}
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ManageUsersList;
