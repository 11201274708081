import { AnyAction } from 'redux';
import { PlanActions } from './actions';

export const plansReducer = (state = [], { type, payload }: AnyAction) => {
  switch (type) {
    case PlanActions.STORE_PLANS:
      return payload.plans;
    case PlanActions.CLEAR_PLANS:
      return [];
    default:
      return state;
  }
};
