import { IAutocompleteOption } from '../autocomplete/Autocomplete';
import { ISelectOptions } from '../select/Select';

export const GURU_ACCT_NAMES: Array<IAutocompleteOption<string>> = [
  { key: 1, value: 'cash', label: 'Cash' },
  { key: 2, value: 'expense', label: 'Expense' },
  { key: 3, value: 'shortTermPreRent', label: 'ST Prepaid Rent' },
  { key: 4, value: 'longTermPreRent', label: 'LT Prepaid Rent' },
  { key: 5, value: 'shortTermDefRent', label: 'ST Deferred Rent' },
  { key: 6, value: 'longTermDefRent', label: 'LT Deferred Rent' },
  { key: 7, value: 'shortTermLiability', label: 'ST Liability' },
  { key: 8, value: 'longTermLiability', label: 'LT Liability' },
  { key: 9, value: 'depreciationExpense', label: 'Depreciation Expense' },
  {
    key: 10,
    value: 'accumulatedDepreciation',
    label: 'Accumulated Depreciation',
  },
  { key: 11, value: 'rouAsset', label: 'ROU Asset' },
  { key: 12, value: 'equity', label: 'Equity' },
  {
    key: 13,
    value: 'interestExpense',
    label: 'Capital Interest Expense',
  },
  {
    key: 14,
    value: 'otherExpense',
    label: 'Capital Other Expense',
  },
  {
    key: 15,
    value: 'assetBalance',
    label: 'Capital Asset',
  },
];

export const monthOptions: Array<ISelectOptions<number>> = [
  { key: 1, value: 1, label: 'January' },
  { key: 2, value: 2, label: 'February' },
  { key: 3, value: 3, label: 'March' },
  { key: 4, value: 4, label: 'April' },
  { key: 5, value: 5, label: 'May' },
  { key: 6, value: 6, label: 'June' },
  { key: 7, value: 7, label: 'July' },
  { key: 8, value: 8, label: 'August' },
  { key: 9, value: 9, label: 'September' },
  { key: 10, value: 10, label: 'October' },
  { key: 11, value: 11, label: 'November' },
  { key: 12, value: 12, label: 'December' },
];

export const yearOptions1: Array<ISelectOptions<number>> = [
  { key: 2018, value: 2018, label: '2018' },
  { key: 2019, value: 2019, label: '2019' },
  { key: 2020, value: 2020, label: '2020' },
  { key: 2021, value: 2021, label: '2021' },
  { key: 2022, value: 2022, label: '2022' },
];

export const MAX_PAYMENT_STREAMS = 20;
export const MAX_ADDITIONAL_PAYMENTS = 20;
export const MAX_USERS = 2;

export const MOBILE_WIDTH_SIZE = 480;

export const MAX_LEASE_PREMIUM = 100;
export const IFRS_TRANSITION_YEAR = 2019;
