import { format, parseISO } from 'date-fns';
import isAfter from 'date-fns/isAfter';
import { Form, Formik } from 'formik';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_DATE_FORMAT } from '../../../common/utilities/_dates';
import { IRenewOptionsModel } from '../../../interfaces';
import { IStore } from '../../../store';
import { LeaseForms, saveForm } from '../actions';
import PaymentForm, { frequencyOptions } from '../components/PaymentForm';
import { IAdditionalFormState } from './AdditionalPaymentsForm';
import { BaseRentFormSchema } from './validationSchemas';

import TagManager from 'react-gtm-module';
import { getGTMDataLayerEventByAmendType } from '../../../common/utilities/_helpers';
import './add-lease-form-styles.scss';
import './BaseRentForm.scss';

export const getEndDate = (
  leaseEndDt: Date,
  renewalOptions: IRenewOptionsModel[],
): Date => {
  let endDate = leaseEndDt;
  // if there are any renewal options
  if (renewalOptions?.length !== 0) {
    renewalOptions.forEach((renewalOption: IRenewOptionsModel) => {
      // if any of the renewal options are set to reasonableToRenew
      if (renewalOption.reasonableToRenew) {
        // if any of the renewEndDt exists
        if (renewalOption.renewEndDt) {
          const renewEndDt = parseISO(renewalOption.renewEndDt);
          // if any of the renewEndDt are later than leaseEndDt
          if (isAfter(renewEndDt, leaseEndDt)) {
            endDate = renewEndDt;
          }
        }
      }
    });
  }
  return endDate;
};

const BaseRentForm = forwardRef((_, ref) => {
  const amendType = useSelector((state: IStore) => {
    return state.steppedModal!.modalProps!.amendType;
  });
  const storedData = useSelector((state: IStore) => {
    return state.lease.form![LeaseForms.BASE];
  });

  const {
    leaseBeginDt,
    leaseEndDt,
  }: { leaseBeginDt: Date; leaseEndDt: Date } = useSelector((state: IStore) => {
    return state.lease.form![LeaseForms.DATE];
  });
  const renewalOptions = useSelector((state: IStore) => {
    return state.lease.form![LeaseForms.OPTIONS]?.renewalOptions || [];
  });
  const isCapital = useSelector((state: IStore) =>
    state.lease.form![LeaseForms.GENERAL]
      ? state.lease.form![LeaseForms.GENERAL].isCapital
      : false,
  );

  const initialData = storedData || {
    additionalPayments: false,
    otherPayments: [
      {
        paymentName: 'Base',
        paymentOptionType: undefined,
        borrowingRate: undefined,
        transitionRate: undefined,
        beginningPrepaidDeferredRent: 0,
        fairValue: undefined,
        incentiveAmount: undefined,
        isFixed: undefined,
        paymentDetail: [
          {
            paymentFrequency: undefined,
            paymentAmt: undefined,
            firstPaymentDt: format(new Date(leaseBeginDt), DEFAULT_DATE_FORMAT),
            lastPaymentDt: format(new Date(leaseEndDt), DEFAULT_DATE_FORMAT),
            isIncrementPct: undefined,
            incrementDecrementAmt: 0,
          },
        ],
      },
    ],
  };
  const dispatch = useDispatch();
  const formikRef = useRef<Formik<IAdditionalFormState>>(null);
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState<IAdditionalFormState>(initialData);

  useImperativeHandle(ref, () => ({
    saveForm() {
      return handleSaveForm(formikRef.current!.state.values);
    },
    validate() {
      return formikRef.current!.submitForm();
    },
    isValid() {
      return isValid;
    },
  }));

  const onSubmit = (values: IAdditionalFormState) => {
    setIsValid(true);
    setFormData({ ...initialData, ...values });

    if (!values.additionalPayments && values.otherPayments.length) {
      TagManager.dataLayer({
        dataLayer: {
          event: getGTMDataLayerEventByAmendType(amendType),
          step_completion_step: '4',
          step_completion_name: 'Base Rent',
          lease_borrowing_rate: values.otherPayments[0].borrowingRate,
          lease_transition_rate: values.otherPayments[0].transitionRate
            ? values.otherPayments[0].transitionRate
            : values.otherPayments[0].borrowingRate,
          lease_payment_amount:
            values.otherPayments[0].paymentDetail[0].paymentAmt,
          lease_payment_frequency: frequencyOptions.find(
            (item) =>
              item.key ===
              values.otherPayments[0].paymentDetail[0].paymentFrequency,
          )?.label,
          lease_first_payment_date:
            values.otherPayments[0].paymentDetail[0].firstPaymentDt,
          lease_last_payment_date:
            values.otherPayments[0].paymentDetail[0].lastPaymentDt,
        },
      });
    }

    dispatch(saveForm(LeaseForms.BASE, { ...initialData, ...values }));
  };

  const handleSaveForm = (values: IAdditionalFormState) => {
    dispatch(saveForm(LeaseForms.BASE, { ...initialData, ...values }));
  };

  return (
    <div className="form-container">
      <Formik
        ref={formikRef}
        initialValues={formData}
        validationSchema={BaseRentFormSchema(
          leaseBeginDt,
          getEndDate(leaseEndDt, renewalOptions),
          isCapital,
        )}
        onSubmit={onSubmit}
      >
        {(formProps) => {
          return (
            <Form noValidate={true}>
              <div className="form-body">
                <PaymentForm
                  formProps={formProps}
                  path="otherPayments[0]"
                  isCapital={isCapital}
                  showTooltips
                  leaseBeginDt={new Date(leaseBeginDt)}
                  leaseEndDt={new Date(leaseEndDt)}
                  isAddlPayments={false}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
});

export interface IBaseFormState {
  id?: number;
  paymentName: string;
  paymentOptionType?: number;
  borrowingRate?: number;
  beginningPrepaidDeferredRent?: number;
  transitionRate?: number;
  fairValue?: number;
  incentiveAmount?: number;
  isFixed?: boolean;
  paymentDetail: IBaseFormDetailState[];
}

export interface IBaseFormDetailState {
  paymentFrequency?: number;
  paymentAmt?: number;
  firstPaymentDt?: string;
  lastPaymentDt?: string;
  isIncrementPct?: boolean;
  incrementDecrementAmt?: number;
}

export default BaseRentForm;
