import { FormikProps } from 'formik';

// adds commas to input
export const formatAddCommas = (
  e: number | undefined,
): number | string | undefined => {
  if (e) {
    const value = (
      Number(e.toString().replace(/\D/g, '')) || ''
    ).toLocaleString();
    return value;
  }
  return e;
};

// strips commas before handling change event
export const formatRemoveCommas = (
  e: React.ChangeEvent<HTMLInputElement>,
  props?: FormikProps<{}>,
) => {
  if (e.target.value) {
    let formattedValue: number | string = Number(
      e.target.value.replace(/,/g, ''),
    );
    if (typeof formattedValue === 'number') {
      formattedValue = formattedValue.toString();
    }
    e.target.value = formattedValue;
    props?.handleChange(e);
    return formattedValue;
  }
  // if input has been cleared send the event without formatting
  props?.handleChange(e);
};
