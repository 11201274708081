import { IUserModel } from '../../interfaces';

function loadAllThirdPartyScriptData(user: IUserModel) {
  loadGetSiteControlData(user);
  loadHeapData(user);
  loadFullstoryData(user);
  loadGTMData(user);
}

function loadGTMData(user: IUserModel) {
  const tagManagerUser = {
    Name: `${user?.firstName + ' ' + user?.lastName}`,
    Email: user.email,
    UserID: user.userId,
    ClientName: user.company?.companyName,
    ClientID: user.company?.clientId,
    Jurisdiction: user.company?.jurisdiction,
    UserType: user?.userRole,
    JoinDate: user.createdDate,
    Plan: user.company.plan?.description,
  };

  window.dataLayer.push({
    event: 'guru_user_data',
    ...tagManagerUser,
  });
}

function loadGetSiteControlData(user: IUserModel) {
  if (window?.gsc) {
    window.gsc('params', {
      email: user.email,
      name: user?.firstName + ' ' + user?.lastName,
    });
  }
}

function loadHeapData(user: IUserModel) {
  if (window?.heap) {
    window.heap.identify(user.email);
    window.heap.addUserProperties({
      Name: `${user?.firstName + ' ' + user?.lastName}`,
      Email: user.email,
      'LG UserID': user.userId,
      'Client Name': user.company?.companyName,
      'LG ClientID': user.company?.clientId,
      Jurisdiction: user.company?.jurisdiction,
      'LG User Type': user?.userRole,
      'LG Join Date': user.createdDate,
    });
  }
}

function clearHeapData() {
  if (window?.heap) {
    window.heap.resetIdentity();
  }
}

function loadFullstoryData(user: IUserModel) {
  if (window?.FS) {
    window.FS.identify(user.userId, {
      displayName: `${user?.firstName + ' ' + user?.lastName}`,
      email: user.email,
      'Company Name': user.company?.companyName,
    });
  }
}

function clearFullstoryData() {
  if (window?.FS) {
    window.FS.anonymize();
  }
}

function clearAllThirdPartyData() {
  clearHeapData();
  clearFullstoryData();
}

export {
  loadAllThirdPartyScriptData,
  loadGetSiteControlData,
  loadHeapData,
  loadFullstoryData,
  clearAllThirdPartyData,
};
