import { Form, Formik } from 'formik';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import get from 'lodash/get';
import { Select } from '../../../common';
import CircleIcon from '../../../common/circle-icon/CircleIcon';
import Input from '../../../common/input/Input';
import RadioGroup from '../../../common/radio-group/RadioGroup';
import { ISelectOptions } from '../../../common/select/Select';
import { fieldHasError } from '../../../common/utilities/FormikHelpers';
import { AssetTypes } from '../../../interfaces';
import { IStore } from '../../../store';
import { LeaseForms, saveForm } from '../actions';
import { GeneralFormSchema } from './validationSchemas';

import TagManager from 'react-gtm-module';
import { getGTMDataLayerEventByAmendType } from '../../../common/utilities/_helpers';
import './add-lease-form-styles.scss';
import './GeneralForm.scss';

// this is due to cap v op needing to
export type IsCapitalValues = boolean | 'help';
export const AssetIcon = {
  Land: 'map',
  Building: 'office',
  Vehicle: 'truck',
  Equipment: 'gears',
  Other: 'tools',
};
const GeneralForm = forwardRef((_, ref) => {
  const storedData = useSelector((state: IStore) => {
    return state.lease.form![LeaseForms.GENERAL];
  });
  const amendType = useSelector((state: IStore) => {
    return state.steppedModal!.modalProps!.amendType;
  });
  const jurisdiction = useSelector(
    (state: IStore) => state.user.company?.jurisdiction,
  );

  const initialData = storedData || {
    assetName: '',
    description: '',
    serialNbr: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    assetType: '',
    iconURI: '',
    fairValue: undefined,
    isCapital: jurisdiction === 'IFRS',
  };
  const dispatch = useDispatch();
  const formikRef = useRef<Formik<IGeneralFormState>>(null);
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState<IGeneralFormState>(initialData);

  useImperativeHandle(ref, () => ({
    saveForm() {
      return handleSaveForm(formikRef.current!.state.values);
    },
    validate() {
      return formikRef.current!.submitForm();
    },
    isValid() {
      return isValid;
    },
  }));

  const onSubmit = async (values: IGeneralFormState) => {
    setIsValid(true);
    setFormData(values);

    const classificationType =
      values.isCapital && typeof values.isCapital === 'boolean'
        ? 'Capital'
        : !values.isCapital && typeof values.isCapital === 'boolean'
        ? 'Operating'
        : 'Help me decide';

    TagManager.dataLayer({
      dataLayer: {
        event: getGTMDataLayerEventByAmendType(amendType),
        step_completion_step: '1',
        step_completion_name: 'General',
        lease_classification_type: classificationType,
        lease_asset_type: values.assetType,
      },
    });
    dispatch(saveForm(LeaseForms.GENERAL, values));
  };

  const handleSaveForm = async (values: IGeneralFormState) => {
    dispatch(saveForm(LeaseForms.GENERAL, values));
  };

  const getCapOpOptions = () => {
    const options: Array<{
      key: number;
      value: IsCapitalValues;
      label: string;
    }> = [
      { key: 0, value: false, label: 'Operating' },
      { key: 1, value: true, label: 'Capital' },
    ];

    if (jurisdiction !== 'IFRS') {
      options.push({ key: 2, value: 'help', label: 'Help me decide' });
    }

    return options;
  };

  const assetTypeOptions: Array<{
    key: number;
    value: AssetTypes;
    label: AssetTypes;
  }> = [
    { key: 1, value: 'Land', label: 'Land' },
    { key: 2, value: 'Building', label: 'Building' },
    { key: 3, value: 'Vehicle', label: 'Vehicle' },
    { key: 4, value: 'Equipment', label: 'Equipment' },
    { key: 5, value: 'Other', label: 'Other' },
  ];

  return (
    <div className="form-container">
      <Formik
        ref={formikRef}
        initialValues={formData}
        validationSchema={GeneralFormSchema}
        onSubmit={onSubmit}
      >
        {(formProps) => {
          return (
            <Form noValidate={true}>
              <div className="General-form">
                <div className="icon-column">
                  {formProps.values.iconURI ? (
                    <>
                      <CircleIcon
                        iconURI={get(AssetIcon, formProps.values.assetType)}
                      />
                      <div className="icon-name small-text">
                        {formProps.values.assetType}
                      </div>
                    </>
                  ) : (
                    <div className="Circle-icon" />
                  )}
                </div>
                <div className="form-column">
                  <Input
                    label="Asset Name"
                    required
                    name="assetName"
                    value={formProps.values.assetName}
                    error={fieldHasError(formProps, 'assetName')}
                    errorMessage={formProps.errors.assetName}
                    onChange={formProps.handleChange}
                    maxLength={50}
                  />
                  {amendType === 'OVERRIDE' || amendType === undefined ? (
                    <RadioGroup
                      name="isCapital"
                      groupLabel="Classification Type"
                      onSelect={(
                        e: React.ChangeEvent<HTMLInputElement>,
                        selectedValue: IsCapitalValues,
                      ) => {
                        formProps.setFieldValue('isCapital', selectedValue);
                      }}
                      value={formProps.values.isCapital}
                      options={getCapOpOptions()}
                    />
                  ) : null}
                  <Input
                    label="Description"
                    name="description"
                    value={formProps.values.description}
                    multiline
                    onChange={formProps.handleChange}
                  />
                  <Select
                    name={'Asset Type'}
                    label="Asset Type"
                    required
                    id={formProps.values.assetType}
                    value={formProps.values.assetType}
                    error={fieldHasError(formProps, 'assetType')}
                    errorMessage={formProps.errors.assetType}
                    onClick={(name: string, option: ISelectOptions<string>) => {
                      formProps.setFieldValue('assetType', option.label);
                      formProps.setFieldValue(
                        'iconURI',
                        get(AssetIcon, option.label),
                      );
                    }}
                    options={assetTypeOptions}
                  />
                  {/* <Divider /> */}
                  <Input
                    label="Serial/ID Number"
                    name="serialNbr"
                    value={formProps.values.serialNbr}
                    onChange={formProps.handleChange}
                  />
                  <Input
                    label="Address"
                    name="address"
                    value={formProps.values.address}
                    onChange={formProps.handleChange}
                  />
                  <div className="address-line-two">
                    <div className="city">
                      <Input
                        label="City"
                        name="city"
                        value={formProps.values.city}
                        onChange={formProps.handleChange}
                      />
                    </div>
                    <div className="state">
                      <Input
                        label="State"
                        name="state"
                        value={formProps.values.state}
                        onChange={formProps.handleChange}
                        maxLength={2}
                      />
                    </div>
                    <div className="zip">
                      <Input
                        label="zip"
                        name="zip"
                        value={formProps.values.zip}
                        onChange={formProps.handleChange}
                        maxLength={10}
                        error={fieldHasError(formProps, 'zip')}
                        errorMessage={formProps.errors.zip}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
});

export interface IGeneralFormState {
  assetName: string;
  description: string;
  serialNbr: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  iconURI: string;
  fairValue?: number;
  isCapital: IsCapitalValues;
  assetType: AssetTypes;
}

export default GeneralForm;
