import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import { Form, Formik } from 'formik';
import get from 'lodash/get';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Divider,
  ExpandSection,
  Input,
  Toggle,
  ToggleSection,
} from '../../../common';
import { API_DATE_FORMAT } from '../../../common/utilities/_dates';
import { fieldHasError } from '../../../common/utilities/FormikHelpers';
import {
  IPurchaseOptionModel,
  IRenewOptionsModel,
  ITerminateOptionModel,
} from '../../../interfaces';
import { IStore } from '../../../store';
import { LeaseForms, saveForm } from '../actions';
import { initialRenewalOption } from '../components/RenewalOptionsList';
import { RenewalOptionsList } from '../index';
import { OptionsFormSchema } from './validationSchemas';

import TagManager from 'react-gtm-module';
import { formatRemoveCommas } from '../../../common/formatters/FormatInputCommas';
import { getGTMDataLayerEventByAmendType } from '../../../common/utilities/_helpers';
import './add-lease-form-styles.scss';
import './OptionsForm.scss';

const OptionsForm = forwardRef((props, ref) => {
  const storedData = useSelector((state: IStore) => {
    return state.lease.form![LeaseForms.OPTIONS];
  });
  const amendType = useSelector((state: IStore) => {
    return (
      state.steppedModal.modalProps && state.steppedModal.modalProps.amendType
    );
  });
  const initialData = {
    canTransferTitle: (storedData && storedData.canTransferTitle) || false,
    isSpecializedUse: (storedData && storedData.isSpecializedUse) || false,
    isRenewalOption: (storedData && storedData.isRenewalOption) || false,
    isMoreOptionsOpen: (storedData && storedData.isMoreOptionsOpen) || false,
    isPurchaseOption: (storedData && storedData.isPurchaseOption) || false,
    isTerminateOption: (storedData && storedData.isTerminateOption) || false,
    renewalOptions: (storedData && storedData.renewalOptions) || [],
    purchaseOption: (storedData && storedData.purchaseOption) || {},
    terminateOption: (storedData && storedData.terminateOption) || {},
  };
  const dispatch = useDispatch();
  const formikRef = useRef<Formik<IOptionsFormState>>(null);
  const defaultRenewDate = useSelector((state: IStore) =>
    addDays(new Date(state.lease.form![LeaseForms.DATE].leaseEndDt), 1),
  );
  const [isMoreOptionsOpen] = useState(
    initialData.isTerminateOption || initialData.isPurchaseOption,
  );
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState<IOptionsFormState>(initialData);

  useImperativeHandle(ref, () => ({
    saveForm() {
      return handleSaveForm(formikRef.current!.state.values);
    },
    validate() {
      return formikRef.current!.submitForm();
    },
    isValid() {
      return isValid;
    },
  }));

  const onSubmit = async (values: IOptionsFormState) => {
    setIsValid(true);
    setFormData(values);
    TagManager.dataLayer({
      dataLayer: {
        event: getGTMDataLayerEventByAmendType(amendType),
        step_completion_step: '3',
        step_completion_name: 'Options',
      },
    });

    dispatch(saveForm(LeaseForms.OPTIONS, values));
  };

  const handleSaveForm = async (values: IOptionsFormState) => {
    dispatch(saveForm(LeaseForms.OPTIONS, values));
  };

  const capOpDisableFields = amendType === 'MODIFY' || amendType === 'RENEW';

  return (
    <div className="form-container">
      <Formik
        ref={formikRef}
        initialValues={formData}
        validationSchema={OptionsFormSchema}
        onSubmit={onSubmit}
      >
        {(formProps) => {
          return (
            <Form noValidate={true}>
              <div className="form-body">
                <Toggle
                  id="isSpecializedUse"
                  label="Is the asset specialized in nature?"
                  name="isSpecializedUse"
                  checked={formProps.values.isSpecializedUse}
                  onChange={() => {
                    formProps.setFieldValue(
                      'isSpecializedUse',
                      !formProps.values.isSpecializedUse,
                    );
                  }}
                  disabled={capOpDisableFields}
                />
                <Divider />
                <Toggle
                  id="canTransferTitle"
                  label="Title transfer at lease end?"
                  name="canTransferTitle"
                  checked={formProps.values.canTransferTitle}
                  onChange={() => {
                    formProps.setFieldValue(
                      'canTransferTitle',
                      !formProps.values.canTransferTitle,
                    );
                  }}
                  disabled={capOpDisableFields}
                />
                <Divider />
                <div className="renewal-options-row">
                  <ToggleSection
                    id="isRenewalOption"
                    name="isRenewalOption"
                    label="Is there a renewal option?"
                    isOpen={formProps.values.isRenewalOption}
                    onClick={() => {
                      formProps.setFieldValue(
                        'isRenewalOption',
                        !formProps.values.isRenewalOption,
                      );
                      formProps.setFieldValue('renewalOptions', [
                        {
                          ...initialRenewalOption,
                          renewStartDt: format(
                            defaultRenewDate,
                            API_DATE_FORMAT,
                          ),
                          renewEndDt: format(defaultRenewDate, API_DATE_FORMAT),
                        },
                      ]);
                      if (formProps.values.isRenewalOption) {
                        formProps.setFieldValue('renewalOptions', []);
                      }
                    }}
                    hideBorder={true}
                  >
                    <RenewalOptionsList
                      formProps={formProps}
                      path={'renewalOptions'}
                    />
                  </ToggleSection>
                </div>
                <Divider />
                <ExpandSection label="More Options" open={isMoreOptionsOpen}>
                  <ToggleSection
                    id="purchaseOption"
                    name="purchase-option"
                    label="Is there a purchase option?"
                    isOpen={formProps.values.isPurchaseOption}
                    onClick={() => {
                      formProps.setFieldValue(
                        'isPurchaseOption',
                        !formProps.values.isPurchaseOption,
                      );
                      if (formProps.values.isPurchaseOption) {
                        formProps.setFieldValue('purchaseOption', {});
                      } else {
                        formProps.setFieldValue('purchaseOption', {
                          reasonableToPurchase: false,
                        });
                      }
                    }}
                    hideBorder={true}
                    disabled={capOpDisableFields}
                  >
                    <Input
                      type="number"
                      withComma={true}
                      label="Purchase Amount"
                      name="purchaseOption.purchaseAmt"
                      value={formProps.values.purchaseOption.purchaseAmt}
                      onChange={(e: any) => formatRemoveCommas(e, formProps)}
                      error={fieldHasError(
                        formProps,
                        'purchaseOption.purchaseAmt',
                      )}
                      errorMessage={get(
                        formProps.errors,
                        'purchaseOption.purchaseAmt',
                      )}
                    />
                    <div className="radio-toggle-body">
                      <Toggle
                        id="bargainPurchaseOption"
                        name="purchaseOption.canBargainPurchase"
                        label="Is there a bargain purchase option?"
                        checked={
                          formProps.values.purchaseOption.canBargainPurchase
                        }
                        onChange={() => {
                          formProps.setFieldValue(
                            'purchaseOption.canBargainPurchase',
                            !formProps.values.purchaseOption.canBargainPurchase,
                          );
                        }}
                        disabled={capOpDisableFields}
                      />
                    </div>
                    <div className="radio-toggle-body">
                      <Toggle
                        id="reasonableToPurchase"
                        name="purchaseOption.reasonableToPurchase"
                        label="Reasonably certain to exercise this option?"
                        checked={
                          formProps.values.purchaseOption.reasonableToPurchase
                        }
                        onChange={() => {
                          formProps.setFieldValue(
                            'purchaseOption.reasonableToPurchase',
                            !formProps.values.purchaseOption
                              .reasonableToPurchase,
                          );
                        }}
                      />
                    </div>
                  </ToggleSection>
                  <Divider />
                  <ToggleSection
                    id="isTerminateOption"
                    name="isTerminateOption"
                    label="Is there a termination option?"
                    isOpen={formProps.values.isTerminateOption}
                    onClick={() => {
                      formProps.setFieldValue(
                        'isTerminateOption',
                        !formProps.values.isTerminateOption,
                      );
                      formProps.setFieldValue(
                        'terminateOption.hasFee',
                        !formProps.values.terminateOption.hasFee,
                      );
                      if (formProps.values.isTerminateOption) {
                        formProps.setFieldValue('terminateOption', {});
                      }
                    }}
                    hideBorder={true}
                  >
                    <Input
                      type="number"
                      withComma={true}
                      label="Termination Amount"
                      name="terminateOption.feeAmt"
                      value={formProps.values.terminateOption.feeAmt}
                      onChange={(e: any) => formatRemoveCommas(e, formProps)}
                      error={fieldHasError(formProps, 'terminateOption.feeAmt')}
                      errorMessage={get(
                        formProps.errors,
                        'terminateOption.feeAmt',
                      )}
                    />
                  </ToggleSection>
                </ExpandSection>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
});

export interface IOptionsFormState {
  canTransferTitle: boolean;
  isSpecializedUse: boolean;
  isRenewalOption: boolean;
  isMoreOptionsOpen: boolean;
  isPurchaseOption: boolean;
  isTerminateOption: boolean;
  renewalOptions: IRenewOptionsModel[];
  purchaseOption: IPurchaseOptionModel;
  terminateOption: ITerminateOptionModel;
}

export default OptionsForm;
