import { Formik, FormikContext } from 'formik';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import {
  addCompanyToUser,
  clientIndustriesGet,
  editUser,
  getActivePlans,
  getCompanyUserLinkManagerInfo,
  getGeneralLedgerItems,
  getMultiClientPlans,
  sendVerificationCode,
  signUp,
} from '../../api';
import { api } from '../../api/api';
import {
  CheckIsMultiClientAdmin,
  setBearerToken,
} from '../../common/utilities/_authHelpers';
import { processErrorMessage } from '../../common/utilities/_helpers';
import { loadAllThirdPartyScriptData } from '../../common/utilities/_thirdPartyScripts';
import { useFeatureFlags } from '../../featureQuery';
import {
  FeatureFlagKeys,
  IClientIndustriesModel,
  IGeneralLedgerModel,
  IPlanModel,
  IUserAddModel,
  IUserEditModel,
  IUserModel,
  VerificationCodes,
} from '../../interfaces';
import { IStore } from '../../store';
import { saveUser } from '../auth/actions';
import VerificationForm, {
  IVerificationFormState,
} from '../auth/VerificationForm';
import { storePlans } from '../plans/actions';
import ChoosePlan, {
  ChoosePlanState,
  UserTypeId,
} from './components/choosePlan/ChoosePlan';
import CompanyInfoForm, {
  ICompanyInfoFormState,
} from './components/CompanyInfoForm';
import CredentialsForm, {
  ICredentialsFormState,
} from './components/CredentialsForm';
import DateInfoForm, { IDateInfoFormState } from './components/DateInfoForm';
import ExpiredWarning from './components/ExpiredWarning';
import SteppedFormContainer from './components/SteppedFormContainer';
import WelcomeForm, { IWelcomeFormState } from './components/WelcomeForm';
import { schemas, StepNumber } from './schemas';

import TagManager from 'react-gtm-module';
import { hideModal } from '../../common/modal/actions';
import {
  storeCurrentAdminPlan,
  storeMultiClientPlans,
} from '../multiClient/actions';
import './CreateAccount.scss';

export enum AccountType {
  SingleCompany = 'Single Company',
  MultiCompany = 'Multi-Company',
}

export interface ITryItNowState
  extends IWelcomeFormState,
    ICompanyInfoFormState,
    IDateInfoFormState,
    ICredentialsFormState,
    ChoosePlanState,
    IVerificationFormState {
  isCPAAdmin?: boolean;
}

type TryItNowFormValues =
  | IWelcomeFormState
  | ICompanyInfoFormState
  | IDateInfoFormState
  | ICredentialsFormState
  | IVerificationFormState
  | ChoosePlanState;

interface CreateAccountProps {
  isCreateClientOnly?: boolean;
  onClientCreateHandlder?: () => void;
}

const CreateAccount: FunctionComponent<CreateAccountProps> = ({
  isCreateClientOnly,
  onClientCreateHandlder,
}) => {
  const { getSingleFlag } = useFeatureFlags();
  const guruCPAFlag = getSingleFlag(FeatureFlagKeys.GuruCPA);

  const { linkManagerID, tier } = useParams<{
    linkManagerID: string;
    tier: string;
  }>();

  const history = useHistory();
  const location = useLocation();
  const existingUser = location.state ? location.state : isCreateClientOnly;
  const dispatch = useDispatch();
  const [stepNumber, setStepNumber] = useState<StepNumber>(
    existingUser ? StepNumber.CompanyInfo : StepNumber.Default,
  );
  const [formData, setFormData] = useState<ITryItNowState>({
    companyName: '',
    companyType: 2,
    industryType: undefined,
    jurisdiction: 'GAAP',
    generalLedger: undefined,
    otherSoftware: '',
    transitionMonth: undefined,
    transitionYear: undefined,
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    passwordConfirmation: '',
    verificationCode: '',
    selectedUserType: undefined,
    leaseCountEstimation: undefined,
  });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const [verifyCode, setVerifyCode] = useState<number>();
  const [linkManagerUser, setLinkManagerUser] = useState<IUserEditModel>();
  const [industryTypeItems, setIndustryTypeItems] = useState<
    IClientIndustriesModel[]
  >();
  const [generalLedgerItems, setGeneralLedgerItems] = useState<
    IGeneralLedgerModel[] | undefined
  >(undefined);
  const plans: IPlanModel[] = useSelector((store: IStore) => {
    return store.plans;
  });

  const loadPlans = useCallback(
    async (jurisdiction?: string) => {
      const planData = await getActivePlans(jurisdiction);

      dispatch(storePlans(planData));
    },
    [dispatch],
  );

  useEffect(() => {
    if (plans.length === 0) {
      loadPlans();
    }
  }, [loadPlans, plans.length]);

  useEffect(() => {
    if (tier) {
      sessionStorage.setItem('tier', tier);
      sessionStorage.setItem('newUpgrade', 'true');
    }
  }, [tier]);

  useEffect(() => {
    const fetchLinkManager = async () => {
      if (history.location.pathname !== '/multicompany-landing') {
        TagManager.dataLayer({ dataLayer: { event: 'account_sign_up_start' } });
      }
      // create user access flow
      if (linkManagerID !== undefined) {
        try {
          setProcessing(true);
          const lmuser: IUserModel = await getCompanyUserLinkManagerInfo(
            linkManagerID!,
          );

          setLinkManagerUser(lmuser);

          setFormData((prevFormData) => ({
            ...prevFormData,
            emailAddress: lmuser!.email!,
          }));

          setStepNumber(StepNumber.Welcome);
        } catch (error) {
          setStepNumber(StepNumber.LinkExpired);
        }
        setProcessing(false);
      } else {
        // regular flow
        if (!existingUser) {
          setStepNumber(StepNumber.Welcome);
        }
      }
    };

    const getClientIndustryItems = async () => {
      const items: IClientIndustriesModel[] = await clientIndustriesGet();
      setIndustryTypeItems(items);
    };

    const fetchGeneralLedger = async () => {
      const items: IGeneralLedgerModel[] = await getGeneralLedgerItems();
      setGeneralLedgerItems(items);
    };

    fetchLinkManager();
    getClientIndustryItems();
    fetchGeneralLedger();
  }, [linkManagerID, existingUser, history.location.pathname]);

  const isMultiCompany = (userType: number | undefined): boolean => {
    return (
      userType === UserTypeId.MULTI ||
      history.location.pathname === '/multicompany-landing'
    );
  };

  const sendGAEvent = (step: StepNumber) => {
    let stepWording = '';
    switch (step) {
      case StepNumber.Welcome:
        stepWording = 'Welcome and T&C Accepted';
        break;
      case StepNumber.Credentials:
        stepWording = 'Credentials';
        break;
      case StepNumber.CompanyInfo:
        stepWording = 'CompanyInfo';
        break;
      case StepNumber.DateInfo:
        stepWording = 'DateInfo';
        break;
      case StepNumber.Verify:
        stepWording = 'Verify';
        break;
    }
    ReactGA.event({
      category: 'Create Account',
      action: `User completed step: ${stepWording}`,
    });
  };

  const onSubmit = async (values: TryItNowFormValues) => {
    const updatedFormData: ITryItNowState = { ...formData, ...values };
    setFormData(updatedFormData);
    setErrorMessage('');
    setProcessing(true);
    sendGAEvent(stepNumber);

    switch (stepNumber) {
      case StepNumber.Welcome:
        TagManager.dataLayer({
          dataLayer: {
            event: 'account_sign_up_progress',
            step_completion_step: '1',
            step_completion_name: 'Company Email Address',
          },
        });
        if (linkManagerID !== undefined) {
          setStepNumber(StepNumber.Credentials);
        } else {
          const verifyValidEmail = await handleEmailValidation(
            updatedFormData.emailAddress,
            ``,
          );
          if (verifyValidEmail) {
            if (guruCPAFlag) {
              setStepNumber(StepNumber.ChoosePlan);
            } else {
              setStepNumber(StepNumber.Credentials);
            }
          }
        }
        break;

      case StepNumber.ChoosePlan:
        TagManager.dataLayer({
          dataLayer: {
            event: 'account_sign_up_progress',
            step_completion_step: '2',
            step_completion_name: 'Choose Account Type',
            step_completion_detail: isMultiCompany(
              updatedFormData.selectedUserType,
            )
              ? AccountType.MultiCompany
              : AccountType.SingleCompany,
          },
        });
        setStepNumber(StepNumber.Credentials);
        break;

      case StepNumber.CompanyInfo:
        TagManager.dataLayer({
          dataLayer: {
            event: 'account_sign_up_progress',
            step_completion_step: isMultiCompany(
              updatedFormData.selectedUserType,
            )
              ? '6'
              : '4',
            step_completion_name: 'Company Details',
            step_completion_detail: isMultiCompany(
              updatedFormData.selectedUserType,
            )
              ? AccountType.MultiCompany
              : AccountType.SingleCompany,
          },
        });
        setStepNumber(StepNumber.DateInfo);
        break;
      case StepNumber.DateInfo:
        TagManager.dataLayer({
          dataLayer: {
            event: 'account_sign_up_progress',
            step_completion_step: isMultiCompany(
              updatedFormData.selectedUserType,
            )
              ? '7'
              : '5',
            step_completion_name: 'Additional Information',
            step_completion_detail: isMultiCompany(
              updatedFormData.selectedUserType,
            )
              ? AccountType.MultiCompany
              : AccountType.SingleCompany,
          },
        });
        if (!existingUser) {
          const verifyResponse = await handleSendVerificationCode(
            updatedFormData.emailAddress,
          );
          if (verifyResponse) {
            setVerifyCode(verifyResponse);
            setStepNumber(StepNumber.Verify);
          }
        } else if (existingUser) {
          if (isCreateClientOnly) {
            updatedFormData.isCPAAdmin = true;
          }
          await buildAndAddCompanyToUser(updatedFormData);
        }
        break;
      case StepNumber.Credentials:
        TagManager.dataLayer({
          dataLayer: {
            event: 'account_sign_up_progress',
            step_completion_step: '3',
            step_completion_name: 'Create Log In Credentials',
            step_completion_detail: isMultiCompany(
              updatedFormData.selectedUserType,
            )
              ? AccountType.MultiCompany
              : AccountType.SingleCompany,
          },
        });
        if (linkManagerID !== undefined) {
          await addUserAccess(updatedFormData);
        } else {
          if (formData.selectedUserType === UserTypeId.MULTI) {
            const verifyResponse = await handleSendVerificationCode(
              updatedFormData.emailAddress,
            );
            if (verifyResponse) {
              setVerifyCode(verifyResponse);
              setStepNumber(StepNumber.Verify);
            }
          } else {
            setStepNumber(StepNumber.CompanyInfo);
          }
        }
        break;
      case StepNumber.Verify:
        if (verifyCode) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'account_sign_up_progress',
              step_completion_step: isMultiCompany(
                updatedFormData.selectedUserType,
              )
                ? '4'
                : '6',
              step_completion_name: 'Verify Email Address',
              step_completion_detail: isMultiCompany(
                updatedFormData.selectedUserType,
              )
                ? AccountType.MultiCompany
                : AccountType.SingleCompany,
            },
          });
          if (formData.selectedUserType === UserTypeId.MULTI) {
            updatedFormData.companyName = `${updatedFormData.firstName} ${updatedFormData.lastName}'s Company`;
          }
          await createUser(updatedFormData);
        }
        break;
    }

    setProcessing(false);
  };

  const buildAndAddCompanyToUser = async (
    values: ITryItNowState,
  ): Promise<void> => {
    const userWithAddedCompany = await addCompanyToUser(values);

    if (userWithAddedCompany) {
      loadAllThirdPartyScriptData(userWithAddedCompany);
    }

    setBearerToken(userWithAddedCompany.authToken);

    await loadPlans(userWithAddedCompany.company.jurisdiction);

    dispatch(saveUser(userWithAddedCompany));
    dispatch(hideModal());
    TagManager.dataLayer({ dataLayer: { event: 'account_sign_up_complete' } });
    history.push('/leases');
  };

  async function addUserAccess(
    values: ITryItNowState,
  ): Promise<IUserAddModel | undefined> {
    try {
      const newUser = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.emailAddress,
        password: values.password,
        linkManagerCode: linkManagerID,
        company: {},
      };
      delete values.passwordConfirmation;
      const user = await editUser(newUser, true);

      await setUserToken(user.authToken);

      await loadPlans(user?.company?.jurisdiction);

      dispatch(saveUser(user));

      return user;
    } catch (error) {
      setErrorMessage(processErrorMessage(error));
    }
  }

  async function createUser(
    updatedFormData: ITryItNowState,
  ): Promise<IUserAddModel | undefined> {
    try {
      const newUser: IUserAddModel = {
        firstName: updatedFormData.firstName,
        lastName: updatedFormData.lastName,
        emailAddress: updatedFormData.emailAddress,
        password: updatedFormData.password,
        transitionMonth: updatedFormData.transitionMonth,
        transitionYear: updatedFormData.transitionYear,
        companyName: updatedFormData.companyName,
        clientIndustryId: updatedFormData.industryType,
        jurisdiction: updatedFormData.jurisdiction,
        ifrsApproach: '2',
        userTypeId: updatedFormData.selectedUserType,
        clientTypeId:
          updatedFormData.selectedUserType === UserTypeId.MULTI ? 2 : 1,
        leaseCountEstimation: updatedFormData.leaseCountEstimation,
        linkManagerCode: updatedFormData.verificationCode,
        linkManagerId: verifyCode,
        generalLedger:
          updatedFormData.generalLedger !== 'Other'
            ? updatedFormData.generalLedger
            : updatedFormData.otherSoftware,
      };

      const user = await signUp(newUser);

      if (user) {
        if (updatedFormData.selectedUserType === UserTypeId.CONTRIBUTING) {
          TagManager.dataLayer({
            dataLayer: { event: 'account_sign_up_complete' },
          });
        }

        await setUserToken(user.authToken);

        if (user) {
          loadAllThirdPartyScriptData(user);
        }

        // load all LeaseGuru Plans
        await loadPlans(newUser.jurisdiction);

        if (CheckIsMultiClientAdmin(user)) {
          await loadMultiClientPlans(user);

          dispatch(saveUser(user));
          // push only after dispatching plans and user
          history.push('/multicompany-landing');

          return user;
        }

        dispatch(saveUser(user));

        return user;
      }
    } catch (error) {
      setErrorMessage(processErrorMessage(error));
    }
  }

  const loadMultiClientPlans = async (user: IUserModel) => {
    const planData = await getMultiClientPlans();
    dispatch(storeMultiClientPlans(planData));
    dispatch(storeCurrentAdminPlan(user.company.plan));
  };

  async function setUserToken(authToken: string) {
    sessionStorage.setItem('userToken', `Bearer ${authToken}` || '');
    api.defaults.headers.common.Authorization = `Bearer ${authToken}`;
  }

  const handleBack = (values?: TryItNowFormValues) => {
    const updatedFormData: ITryItNowState = { ...formData, ...values };
    setFormData(updatedFormData);
    if (stepNumber === StepNumber.Credentials) {
      if (guruCPAFlag) {
        setStepNumber(StepNumber.ChoosePlan);
      } else {
        setStepNumber(StepNumber.Welcome);
      }
    } else if (stepNumber === StepNumber.ChoosePlan) {
      setStepNumber(StepNumber.Welcome);
    } else if (stepNumber === StepNumber.CompanyInfo) {
      setStepNumber(StepNumber.Credentials);
    } else {
      setStepNumber(stepNumber - 1);
    }
  };

  async function handleSendVerificationCode(email: string) {
    try {
      const response = await sendVerificationCode(
        email,
        VerificationCodes.SendVerificationCode,
      );
      return response;
    } catch (error) {
      setErrorMessage(processErrorMessage(error));
    }
  }

  async function handleEmailValidation(email: string, userName: string) {
    try {
      const response = await sendVerificationCode(
        email,
        VerificationCodes.VerifyEmail,
        userName,
      );
      return response;
    } catch (error) {
      setErrorMessage(processErrorMessage(error));
    }
  }

  const renderForm = (step: StepNumber) => {
    switch (step) {
      case StepNumber.LinkExpired:
        return <ExpiredWarning warningText="Sorry, this link has Expired!" />;
      case StepNumber.Welcome:
        return (
          <Formik
            key={step}
            initialValues={{
              emailAddress: formData.emailAddress,
            }}
            validationSchema={schemas[step]}
            onSubmit={onSubmit}
          >
            {(formProps: FormikContext<IWelcomeFormState>) => {
              return (
                <WelcomeForm
                  formProps={formProps}
                  fromLinkManager={!!linkManagerUser}
                  errorMessage={errorMessage}
                  buyNow={!!tier}
                />
              );
            }}
          </Formik>
        );
      case StepNumber.CompanyInfo:
        const validationSchema = isCreateClientOnly
          ? schemas[step].concat(schemas[StepNumber.CompanyType])
          : schemas[step]
              .concat(schemas[StepNumber.LeaseCount])
              .concat(schemas[StepNumber.DateInfo]);
        return (
          <Formik
            key={step}
            initialValues={{
              companyName: formData.companyName,
              companyType: formData.companyType,
              industryType: formData.industryType,
              jurisdiction: formData.jurisdiction,
              leaseCountEstimation: formData.leaseCountEstimation,
              transitionMonth: formData.transitionMonth,
              transitionYear: formData.transitionYear,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formProps: FormikContext<ICompanyInfoFormState>) => {
              return (
                <SteppedFormContainer
                  stepNumber={existingUser ? 1 : stepNumber}
                  headerText="Let's meet your company"
                  handleBack={() => handleBack(formProps.values)}
                  numOfSteps={existingUser ? 2 : 3}
                  hideBackButton={!!existingUser}
                  hideHeading={isCreateClientOnly}
                >
                  <CompanyInfoForm
                    formProps={formProps}
                    industryList={industryTypeItems}
                    isCreateClientOnly={isCreateClientOnly}
                  />
                </SteppedFormContainer>
              );
            }}
          </Formik>
        );
      case StepNumber.DateInfo:
        return (
          <Formik
            key={step}
            initialValues={{
              generalLedger: formData.generalLedger,
              otherSoftware: formData.otherSoftware,
              transitionMonth: formData.transitionMonth,
              transitionYear: formData.transitionYear,
            }}
            validationSchema={isCreateClientOnly ? schemas[step] : null}
            onSubmit={onSubmit}
          >
            {(formProps: FormikContext<IDateInfoFormState>) => {
              return (
                <SteppedFormContainer
                  stepNumber={existingUser ? 2 : stepNumber}
                  headerText="Additional Information"
                  handleBack={() => handleBack(formProps.values)}
                  numOfSteps={existingUser ? 2 : 3}
                  processing={processing}
                  nextButtonOverride={
                    isCreateClientOnly
                      ? 'CREATE COMPANY'
                      : existingUser
                      ? 'Complete Sign Up'
                      : undefined
                  }
                  hideHeading={isCreateClientOnly}
                >
                  <DateInfoForm
                    formProps={formProps}
                    formData={formData}
                    isCreateClientOnly={isCreateClientOnly}
                    generalLedgerList={generalLedgerItems}
                  />
                </SteppedFormContainer>
              );
            }}
          </Formik>
        );
      case StepNumber.Credentials:
        return (
          <Formik
            key={step}
            initialValues={{
              firstName: formData.firstName,
              lastName: formData.lastName,
              password: formData.password,
              passwordConfirmation: formData.passwordConfirmation,
            }}
            validationSchema={schemas[step]}
            onSubmit={onSubmit}
          >
            {(formProps: FormikContext<ICredentialsFormState>) => {
              return (
                <SteppedFormContainer
                  stepNumber={1}
                  headerText="Let's get started"
                  handleBack={() => handleBack(formProps.values)}
                  processing={processing}
                  numOfSteps={linkManagerID === undefined ? 3 : 1}
                  hideHeading={formData.selectedUserType === UserTypeId.MULTI}
                >
                  <CredentialsForm
                    formProps={formProps}
                    errorMessage={errorMessage}
                  />
                </SteppedFormContainer>
              );
            }}
          </Formik>
        );
      case StepNumber.Verify:
        return (
          <Formik
            key={step}
            initialValues={{
              verificationCode: formData.verificationCode,
            }}
            validationSchema={schemas[step]}
            onSubmit={onSubmit}
          >
            {(formProps: FormikContext<IVerificationFormState>) => {
              return (
                <VerificationForm
                  formProps={formProps}
                  setStepNumber={setStepNumber}
                  errorMessage={errorMessage}
                  processing={processing}
                  emailAddress={formData.emailAddress}
                  stepNumber={stepNumber}
                  buyNow={!!tier}
                  isMulti={formData.selectedUserType === UserTypeId.MULTI}
                />
              );
            }}
          </Formik>
        );
      case StepNumber.ChoosePlan:
        return (
          <Formik
            key={step}
            initialValues={{
              selectedUserType: undefined,
            }}
            validationSchema={schemas[step]}
            onSubmit={onSubmit}
          >
            {(formProps: FormikContext<ChoosePlanState>) => {
              return <ChoosePlan formProps={formProps} onBack={handleBack} />;
            }}
          </Formik>
        );

      default:
        return null;
    }
  };

  return <div className="try-it-wrapper">{renderForm(stepNumber)}</div>;
};

export default CreateAccount;
