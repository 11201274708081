import {
  IPlanFeatureModel,
  IPlanModel,
  PremiumPlanSubTier,
} from '../../../interfaces';

export interface IPlanFeatureTableItem {
  id: number;
  name: string;
  isBasic: boolean;
  isPremium1: boolean;
  isPremium2: boolean;
  isPremium3: boolean;
  isPremium4: boolean;
  isPremium5: boolean;
}

export const getFeaturesByPlan = (
  planName: string,
  planModels: IPlanModel[],
): IPlanFeatureModel[] => {
  const plan = planModels.find(
    (p) => p.name.endsWith(planName) || p.tier === planName,
  );
  return plan !== undefined ? plan.features : [];
};

const hasSupportFeatureOnPlan = (
  feature: string,
  features: IPlanFeatureModel[],
  hasFeatureSupportOnChildPlan: boolean = false,
) => {
  return features.some(
    (f) => f.name === feature || hasFeatureSupportOnChildPlan,
  );
};

export const buildPlanTableDetail = (
  features: IPlanFeatureModel[],
  planModels: IPlanModel[],
): IPlanFeatureTableItem[][] => {
  const basicPlan = getFeaturesByPlan('Basic', planModels);
  const premium1Plan = getFeaturesByPlan(
    PremiumPlanSubTier.PREMIUM_1,
    planModels,
  );
  const premium2Plan = getFeaturesByPlan(
    PremiumPlanSubTier.PREMIUM_2,
    planModels,
  );
  const premium3Plan = getFeaturesByPlan(
    PremiumPlanSubTier.PREMIUM_3,
    planModels,
  );
  const premium4Plan = getFeaturesByPlan(
    PremiumPlanSubTier.PREMIUM_4,
    planModels,
  );
  const premium5Plan = getFeaturesByPlan(
    PremiumPlanSubTier.PREMIUM_5,
    planModels,
  );

  const result: IPlanFeatureTableItem[][] = [];
  features.forEach((f, index) => {
    if (result.length < f.groupNumber) {
      result.push([]);
    }
    const groupfeature = result[f.groupNumber - 1];
    const hasSupportFeatureOnBasicPlan: boolean = hasSupportFeatureOnPlan(
      f.name,
      basicPlan,
    );
    const hasSupportFeatureOnPremium1Plan: boolean = hasSupportFeatureOnPlan(
      f.name,
      premium1Plan,
      hasSupportFeatureOnBasicPlan,
    );
    groupfeature?.push({
      id: f.id,
      name: f.name,
      isBasic: hasSupportFeatureOnBasicPlan,
      isPremium1: hasSupportFeatureOnPremium1Plan,
      isPremium2: hasSupportFeatureOnPlan(
        f.name,
        premium2Plan,
        hasSupportFeatureOnPremium1Plan,
      ),
      isPremium3: hasSupportFeatureOnPlan(
        f.name,
        premium3Plan,
        hasSupportFeatureOnPremium1Plan,
      ),
      isPremium4: hasSupportFeatureOnPlan(
        f.name,
        premium4Plan,
        hasSupportFeatureOnPremium1Plan,
      ),
      isPremium5: hasSupportFeatureOnPlan(
        f.name,
        premium5Plan,
        hasSupportFeatureOnPremium1Plan,
      ),
    });
  });

  return result;
};

export const getUsersOnPlan = (planName: string, planModels: IPlanModel[]) => {
  if (planName === 'Enterprise') {
    return 'Unlimited';
  }
  const features = getFeaturesByPlan(planName, planModels);
  const pickedFeature: IPlanFeatureModel | undefined = features.find(
    (feature: IPlanFeatureModel) => feature.name === 'Users',
  );

  if (pickedFeature) {
    return pickedFeature.attributes[0].values[0];
  }
};

export const getPlanByName = (
  planName: string,
  planModels: IPlanModel[],
): IPlanModel => {
  const plan = planModels.find(
    (p) => p.name.endsWith(planName) || p.tier === planName,
  );
  return plan !== undefined ? plan : (({} as unknown) as IPlanModel);
};

export const isCurrentPlan = (
  planToCompare: string,
  planModels: IPlanModel[],
  userCurrentPlan?: IPlanModel,
): boolean => {
  if (!userCurrentPlan) {
    return planToCompare === 'Basic';
  }
  const plan = getPlanByName(planToCompare, planModels);
  return plan.name === userCurrentPlan.name;
};

export const getFeatureList = (isCPAPlan?: boolean) => {
  const tempFeatureList = [
    {
      id: 1,
      name: 'Users',
      description: '2 Contributing Users',
      isActive: true,
      orderNumber: 2,
      isNewPlanPage: false,
      attributes: [],
      groupNumber: 0,
    },
    {
      id: 2,
      name: 'Guest User',
      description: '1 Guest (read-only) User',
      isActive: true,
      orderNumber: 3,
      isNewPlanPage: false,
      attributes: [],
      groupNumber: 0,
    },
    {
      id: 3,
      name: 'View Journal Entries',
      description: 'View & Export Journal Entries',
      isActive: true,
      orderNumber: 4,
      isNewPlanPage: false,
      attributes: [],
      groupNumber: 0,
    },
    {
      id: 4,
      name: 'View Amortization Schedules',
      description: 'View & Export Amortization Schedules',
      isActive: true,
      orderNumber: 6,
      isNewPlanPage: false,
      attributes: [],
      groupNumber: 0,
    },
    {
      id: 5,
      name: 'View Disclosures',
      description: 'View Disclosures Reports',
      isActive: true,
      orderNumber: 6,
      isNewPlanPage: false,
      attributes: [],
      groupNumber: 0,
    },
    {
      id: 6,
      name: 'Jurisdiction',
      description: 'GAAP, IFRS',
      isActive: true,
      orderNumber: 10,
      isNewPlanPage: false,
      attributes: [],
      groupNumber: 0,
    },
  ];

  if (isCPAPlan) {
    tempFeatureList.splice(0, 1);
    tempFeatureList.unshift({
      id: 0,
      name: 'Leases Count',
      description: '10 leases per company',
      isActive: true,
      orderNumber: 2,
      isNewPlanPage: false,
      attributes: [],
      groupNumber: 0,
    });
    tempFeatureList[1].description = '1 Guest (read-only) User per company';
  }

  return tempFeatureList;
};

export const mapPlanDisplayNameToPlanSubTier = (
  planDisplayName: string,
): string => {
  switch (planDisplayName) {
    case `Premium 5`:
      return PremiumPlanSubTier.PREMIUM_1;
    case `Premium 10`:
      return PremiumPlanSubTier.PREMIUM_2;
    case `Premium 15`:
      return PremiumPlanSubTier.PREMIUM_3;
    case `Premium 20`:
      return PremiumPlanSubTier.PREMIUM_4;
    case `Premium 25`:
      return PremiumPlanSubTier.PREMIUM_5;
    default:
      return planDisplayName;
  }
};

export const mapPlanNameToDisplayName = (planName: string) => {
  switch (planName) {
    case PremiumPlanSubTier.PREMIUM_1:
      return 'Premium 5';
    case PremiumPlanSubTier.PREMIUM_2:
      return 'Premium 10';
    case PremiumPlanSubTier.PREMIUM_3:
      return 'Premium 15';
    case PremiumPlanSubTier.PREMIUM_4:
      return 'Premium 20';
    case PremiumPlanSubTier.PREMIUM_5:
      return 'Premium 25';
    default:
      return 'Unknown plan name';
  }
};
