import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import TripleDotLoader from '../../common/loaders/TripleDotLoader';
import { checkIfUserIsGuest } from '../../common/utilities/_guestUserHelper';
import { IUserModel } from '../../interfaces';
import { IStore } from '../../store';
import { UserListType } from './ManageUsersModal';

import checkmark from '../../assets/checkmark.svg';

import './ManageUsersModal.scss';

interface IManageUsersRowProps {
  user: IUserModel;
  deleteUser: (id: number) => void;
  resendUserAccess: (user: IUserModel) => void;
  removeProcessing: boolean;
  userListType: UserListType;
  handleUndoRemovedUser: (userToUndo: IUserModel, type: UserListType) => void;
  domain: string;
}

const ManageUsersRow: React.FC<IManageUsersRowProps> = ({
  user,
  deleteUser,
  resendUserAccess,
  removeProcessing = false,
  userListType,
  handleUndoRemovedUser,
  domain,
}) => {
  const loggedInUser: IUserModel = useSelector((state: IStore) => state.user);
  const [resent, isResent] = useState<boolean>(false);

  const [selectedDeletedUser, setSelectedDeletedUser] = useState<number>();
  const iconType = userListType === UserListType.Guest ? 'guest' : 'user';
  const isDisabledGuest = loggedInUser.company.allowGuestUser !== true;
  const outsideDomain = user?.email?.split('@')[1] !== domain;
  const removeUser = () => {
    deleteUser(user.userId!);
    setSelectedDeletedUser(user.userId!);
  };

  const resendRequest = (resendUser: IUserModel) => {
    resendUserAccess(resendUser);
    isResent(true);

    setTimeout(() => {
      isResent(false);
    }, 60000);
  };

  const getSectionMessage = () => {
    let message = '';

    if (!user!.isSuspended) {
      message =
        userListType === UserListType.Guest
          ? 'Guest has read-only access to this company'
          : 'User is added to this company';
    } else if (user!.isSuspended && !resent) {
      message = 'Email sent to this address. They have 48 hours to accept.';
    } else if (resent) {
      message =
        'Request access has been re-sent to this address. They have 48 hours to accept.';
    } else if (user!.isSuspended) {
      message = 'User is disabled';
    }

    return message;
  };

  const getUserStatus = (): JSX.Element => {
    if (checkIfUserIsGuest(user)) {
      if (isDisabledGuest) {
        return <label className="section-element disabled">DISABLED</label>;
      } else if (!user!.isSuspended) {
        return <label className="section-element active">ACTIVE</label>;
      } else {
        return <span className="section-element pending">PENDING</span>;
      }
    }

    if (!user!.isSuspended) {
      return <label className="section-element active">ACTIVE</label>;
    } else {
      return <span className="section-element pending">PENDING</span>;
    }
  };

  return (
    <div>
      {user.canUndoUser ? (
        <div className="undo-user">
          <img
            src={require(`../../assets/${iconType}-removed.svg`)}
            alt="removed"
          />
          <p>
            <b>{user.email}</b> has been removed and no longer has access to
            this company.
          </p>
          <button
            className="undo-dismiss-btn"
            onClick={(e) => {
              e.preventDefault();
              handleUndoRemovedUser(user, userListType);
            }}
          >
            Undo
          </button>
        </div>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="manage-user-row-wrapper">
            <div className="section-labels">
              <div className="icon-wrapper">
                <img src={require(`../../assets/${iconType}.svg`)} alt="" />
              </div>
              <div>
                <p className={isDisabledGuest ? 'disabled' : ''}>
                  {user && user.email}
                </p>
                <p className="section-message">{getSectionMessage()}</p>
              </div>
            </div>
            <div className="add-users-wrap">
              <div className="status-label">{getUserStatus()}</div>
              {!removeProcessing || selectedDeletedUser !== user.userId ? (
                <div className="buttons-with-resend">
                  {user!.isSuspended &&
                  (!isDisabledGuest ||
                    userListType === UserListType.Contributing) ? (
                    <button
                      onClick={(e) => {
                        // prevent input validation from firing
                        e.preventDefault();
                        resendRequest(user);
                      }}
                      className="section-element resend"
                      disabled={resent}
                    >
                      Resend
                    </button>
                  ) : (
                    <div />
                  )}
                  {user.userId !== loggedInUser.userId ? (
                    <button
                      onClick={(e) => {
                        // prevent input validation from firing
                        e.preventDefault();
                        removeUser();
                      }}
                      className="section-element remove"
                    >
                      Remove
                    </button>
                  ) : null}
                </div>
              ) : removeProcessing && selectedDeletedUser === user.userId ? (
                <div className="section-element processing">
                  <TripleDotLoader text="" />
                </div>
              ) : null}
            </div>
          </div>
          {resent ? (
            <div className="manage-user-dialog">
              <div className="icon-wrapper">
                <img src={checkmark} alt="green_check" />
              </div>
              <div className="resend-message">
                Another email invite has been sent to the user’s email address.
              </div>
              <button
                className="undo-dismiss-btn"
                onClick={() => isResent(false)}
              >
                Dismiss
              </button>
            </div>
          ) : null}
        </form>
      )}
      {outsideDomain ? (
        <div className="manage-user-dialog">
          <div className="icon-wrapper">
            <img src={require(`../../assets/exclamation-yellow.svg`)} alt="" />
          </div>
          <div>
            <div className="section-header">
              The entered email is from an outside domain.
            </div>
            <div className="section-message">
              By adding this user, you accept the security risk of giving
              someone from outside your company domain access to your data.
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ManageUsersRow;
