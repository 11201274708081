import React, { FunctionComponent } from 'react';
import { useResourceContext } from '../../../common/resource-context';
import { IPlanModel } from '../../../interfaces';
import './BasicPlanHeader.scss';
import './PlanHeader.scss';

interface IBasicPlanHeader {
  isDisplayOnCPAPricingPage?: boolean;
  userCurrentPlan?: IPlanModel;
}
const BasicPlanHeader: FunctionComponent<IBasicPlanHeader> = ({
  isDisplayOnCPAPricingPage,
  userCurrentPlan,
}) => {
  const isCurrentBasicPlan = userCurrentPlan?.tier === 'Basic';
  const resources = useResourceContext();

  const isRSM = process.env.REACT_APP_ENABLE_RSM === 'true';

  const getButtonText = (): string => {
    return isCurrentBasicPlan ? 'Current' : '';
  };
  return (
    <div className={`${isRSM && 'rsm'}`}>
      <div className="basic-plan-wrapper">
        <div className="card-title">
          {isDisplayOnCPAPricingPage ? 'Limited' : 'Basic'}
        </div>
        <div className="lease-count">
          {isDisplayOnCPAPricingPage
            ? resources.text('Basic_Company_Count')
            : resources.text('Basic_LeaseCountRange') + ' Leases'}
        </div>
        <div className="cost">
          $0 <br />
          <div>always</div>
        </div>
        {isCurrentBasicPlan ? (
          <div className={'button ' + (isCurrentBasicPlan && ' disable ')}>
            <button disabled={isCurrentBasicPlan}>
              <span>{getButtonText()}</span>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default BasicPlanHeader;
