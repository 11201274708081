import React from 'react';

import { Button } from '../../common/buttons';
import { IPlanFeatureModel, IPlanModel } from '../../interfaces';
import { getFeatureDisplayText } from '../plans/helpers';

import checkmark from '../../assets/checkmark.svg';
import rsmCheckmark from '../../assets/rsm-checkmark.svg';

interface ITierCardProps {
  upgradeButtonText?: string;
  upgradeText?: any;
  disableUpgrade?: boolean;
  setSelectedPlan?: (selectedPlan: IPlanModel) => void;
  isCalculator?: boolean;
  tierDescription?: string;
  tierTitle: string;
  tierPrice: string;
  tierFeatures: IPlanFeatureModel[];
  setSelectedTierFeatures?: () => void;
  setNavigation: () => void;
}
const TierCard: React.FC<ITierCardProps> = ({
  disableUpgrade = false,
  upgradeButtonText,
  upgradeText,
  isCalculator = false,
  tierTitle,
  tierDescription,
  tierPrice,
  tierFeatures,
  setNavigation,
  setSelectedTierFeatures,
}) => {
  return (
    <div
      className={`tier-card-wrapper ${process.env.REACT_APP_ENABLE_RSM ===
        'true' && 'rsm-class-tier-card'}`}
    >
      <div className="tier-header">{tierTitle}</div>
      <div className={`tier-price ${tierTitle}`}>{tierPrice}</div>
      <div
        className={`${
          !isCalculator ? 'tier-description-no-calculator' : ''
        } tier-description`}
      >
        {tierDescription && (
          <span className={'tier-sub-description'}>{tierDescription}</span>
        )}

        {tierFeatures &&
          tierFeatures.map((feature, index) => {
            return (
              <div className="tier-feature" key={index}>
                <span className="check-mark">
                  <img
                    src={
                      process.env.REACT_APP_ENABLE_RSM === 'true'
                        ? rsmCheckmark
                        : checkmark
                    }
                    alt="checkmark"
                  />
                </span>
                <span className="feature-item">
                  {getFeatureDisplayText(feature)}
                </span>
              </div>
            );
          })}
      </div>
      <div className="tier-footer">
        {upgradeButtonText ? (
          <Button
            onClick={() => {
              if (setSelectedTierFeatures) {
                setSelectedTierFeatures();
              }
              setNavigation();
            }}
            buttonType="positive"
            buttonText={upgradeButtonText}
            disabled={disableUpgrade}
          />
        ) : null}

        {upgradeText && <>{upgradeText}</>}
      </div>
    </div>
  );
};

export default TierCard;
