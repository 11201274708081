import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import upgradeCityRSM from '../../assets/upgrade-city-rsm.svg';
import upgradeCity from '../../assets/upgrade-city.svg';
import { hideModal, showModal } from '../../common/modal/actions';
import { useResourceContext } from '../../common/resource-context';
import { CheckIsMultiClientAdmin } from '../../common/utilities/_authHelpers';
import { applyRSMClassName, isEnvRSM } from '../../common/utilities/_helpers';
import { IPlanFeatureModel, IPlanModel } from '../../interfaces';
import { IStore } from '../../store';
import {
  checkIfUserIsOnMaxCPAPlan,
  checkIfUserIsOnMaxPlan,
  findNextAvailableUpgrade,
  getFeatureValue,
} from '../plans/helpers';
import { SubscriptionModalPages } from '../subscriptions/SubscriptionModal';

import './MaxLeaseListItem.scss';

interface MaxLeaseListItemProps {
  leaseCount: number;
  maxLeases: number;
}

const MaxLeaseListItem: FunctionComponent<MaxLeaseListItemProps> = ({
  leaseCount,
  maxLeases,
}) => {
  const resources = useResourceContext();

  const isRSM = isEnvRSM();

  const user = useSelector((state: IStore) => {
    return state.user;
  });

  const isMultiClientAdmin = CheckIsMultiClientAdmin(user);

  const allPlans = useSelector((state: IStore) => {
    return state.plans;
  });

  const userIsOnMaxPlan =
    checkIfUserIsOnMaxPlan(user, allPlans) ||
    checkIfUserIsOnMaxCPAPlan(user.company.plan, leaseCount, maxLeases);
  const isUserOnPremiumPlan = (user.company.plan?.price || 0) > 0;
  const nextAvailablePlan = findNextAvailableUpgrade(
    allPlans,
    user.company?.plan as IPlanModel,
  );
  const nextPlanLeaseCount = getFeatureValue(
    'Lease Count',
    nextAvailablePlan?.features as IPlanFeatureModel[],
  );

  const showCheckoutModal = (
    availablePlanToUpgradeTo: IPlanModel | undefined,
  ) => {
    dispatch(
      showModal({
        modal: {
          modalType: 'SUBSCRIPTIONS',
          modalProps: {
            open: true,
            hideModal,
            initialSubscriptionModalPage: SubscriptionModalPages.payment,
            initialPlanFeatures: availablePlanToUpgradeTo?.features,
            initialSelectedPlan: availablePlanToUpgradeTo,
          },
        },
      }),
    );
  };

  const displayUpgradeOptions = () => {
    if (userIsOnMaxPlan) {
      const enterpriseUrl = resources.text('EnterprisePricingUrl');
      window.open(enterpriseUrl, '_blank');
    } else {
      showCheckoutModal(nextAvailablePlan);
    }
  };

  const dispatch = useDispatch();
  const getLeaseMaxCardHeader = () => {
    return (
      <span className="max-lease-header">
        {isMultiClientAdmin && !userIsOnMaxPlan ? (
          <>
            Purchase now to add up <br />
            to 10 leases per company.
          </>
        ) : (
          <>
            You’ve reached the
            <br />
            max number of leases.
          </>
        )}
      </span>
    );
  };
  const getLeaseMaxCardDescription = () => {
    return userIsOnMaxPlan ? (
      <>
        For unlimited leases and advanced features,
        <br />
        consider upgrading to <strong>LeaseQuery.</strong>
      </>
    ) : isMultiClientAdmin ? (
      <div>
        Up to 5 companies at <br />
        <span className="next-active-plan"> $500 per company, yearly </span>
      </div>
    ) : (
      <div>
        Consider {resources.text('SageLogin_CredentialText', 'LeaseGuru')}{' '}
        Premium at
        <br />
        <span className="next-active-plan">
          {nextPlanLeaseCount} leases for ${nextAvailablePlan?.price}
          /yr
          {isUserOnPremiumPlan ? '.' : ','}
        </span>
        {isUserOnPremiumPlan ? null : ' plus premium features.'}
      </div>
    );
  };

  return (
    <div className={`max-lease-list-wrapper ${isRSM ? 'rsm' : ''}`}>
      <img
        src={`${isRSM ? upgradeCityRSM : upgradeCity}`}
        alt="cta"
        className={`${isRSM ? 'cta-image-rsm' : 'cta-image'}`}
      />
      {isRSM && <div className="max-lease-list-rsm-border" />}
      <div className={applyRSMClassName('max-lease-list-body-wrapper')}>
        <div className="max-lease-list-body">
          {getLeaseMaxCardHeader()}
          <span className="max-lease-description">
            {getLeaseMaxCardDescription()}
          </span>
          <div className="upgrade">
            <button
              className="cta-button"
              onClick={() => displayUpgradeOptions()}
            >
              {userIsOnMaxPlan
                ? 'Request Demo'
                : isMultiClientAdmin
                ? 'BUY NOW'
                : 'Upgrade'}
            </button>
            {!isMultiClientAdmin ? (
              <a className="pricing" href="/pricing">
                View All Plans
              </a>
            ) : (
              <a className="pricing" href="/multicompany-pricing">
                View All Plans
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaxLeaseListItem;
