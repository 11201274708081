import { IUserAddModel, VerificationCodes } from '../interfaces';
import { api, formatError } from './api';

export async function signIn(
  username: string,
  password: string,
  clientId?: number,
) {
  const rawResponse = await api.post(
    '/Login',
    {
      username,
      password,
      clientId,
    },
    { withCredentials: true },
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  if (rawResponse.data.userRole === 6 && rawResponse.data.company === null) {
    throw Error('NoCompanyAccess');
  }
  if (!rawResponse.data.authToken) {
    throw Error('No authentication token received.');
  }

  api.defaults.headers.common.Authorization = `Bearer ${rawResponse.data.authToken.toString()}`;

  return rawResponse.data;
}

export async function signUp(user: IUserAddModel) {
  const rawResponse = await api.post('/AddUser', { ...user });

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  if (rawResponse.data.authToken) {
    api.defaults.headers.common.Authorization = `Bearer ${rawResponse.data.authToken.toString()}`;
  }

  return rawResponse.data;
}

export async function sendVerificationCode(
  emailAddress: string,
  verificationCodeType: VerificationCodes,
  userName?: string,
) {
  const rawResponse = await api.post('/SendVerificationCode', {
    actionCode: verificationCodeType,
    emailAddress,
    userName,
  });

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function confirmEmailCode(confirmId: number, emailCode: string) {
  const rawResponse = await api.get(
    `/ConfirmEmailCode/${confirmId}/${emailCode}`,
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function forgotPassword(email: string) {
  const rawResponse = await api.post('/ForgotPassword', JSON.stringify(email));

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function updatePassword(password: string) {
  const rawResponse = await api.put(
    '/UpdatePassword',
    JSON.stringify(password),
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export function cookieExists(cookie: string) {
  return document.cookie.indexOf(cookie) !== -1;
}
