import React, { FunctionComponent } from 'react';

import { circleIcons } from '../../assets/index';
import './CircleIcon.scss';

const CircleIcon: FunctionComponent<ICircleIconProps> = ({ iconURI }) => {
  const iconImg: string | null = iconURI
    ? circleIcons[iconURI.toLowerCase()]
    : null;

  let icon = <div className="generic-icon" />;

  if (iconImg) {
    icon = (
      <img
        id={`Circle-icon-image-${iconURI}`}
        src={iconImg}
        alt={`lease icon ${iconURI ? iconURI.toLowerCase() : ''}`}
        // width={'44px'}
        // height={'50px'}
      />
    );
  }

  return (
    <div className={'Circle-icon' + (iconImg ? '' : ' default')}>{icon}</div>
  );
};

interface ICircleIconProps {
  iconURI?: string;
}

export default CircleIcon;
