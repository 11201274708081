import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCompanyUsers,
  addGuestUsers,
  getCompanyUsers,
  removeUser,
  resendUserAccess,
  toggleGuestUsers,
  undoDeleteClientUser,
} from '../../api';
import GuruLogoSpinner from '../../common/loaders/GuruLogoSpinner';
import Modal from '../../common/modal/Modal';
import Tooltip from '../../common/tooltips/Tooltip';
import { CheckIsMultiClientAdmin } from '../../common/utilities/_authHelpers';
import { filterUsersByType } from '../../common/utilities/_guestUserHelper';
import { processErrorMessage } from '../../common/utilities/_helpers';
import { IUserModel } from '../../interfaces';
import { IStore } from '../../store';
import { updateUserCompanySettings } from '../auth/actions';
import { getFeatureValue } from '../plans/helpers';
import GuestUserContainer from './GuestUserContainer';
import ManageUsersList from './ManageUsersList';

import Divider from './../../common/divider/Divider';
import './ManageUsersModal.scss';

export enum UserListType {
  Guest = 'guestUser',
  Contributing = 'contributingUser',
}

const ManageUsersModal: React.FC = () => {
  const dispatch = useDispatch();
  const loggedInUser: IUserModel = useSelector((state: IStore) => state.user);
  const [userList, setUserList] = useState<IUserModel[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorGuestUserMessage, setErrorGuestUserMessage] = useState<string>(
    '',
  );
  const [accessProcessing, setAccessProcessing] = useState<boolean>(false);
  const [removeProcessing, setRemoveProcessing] = useState<boolean>(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);
  const [guestList, setGuestList] = useState<IUserModel[]>([]);

  const planAllowsGuestUsers = getFeatureValue(
    'Guest User',
    loggedInUser.company.plan?.features ?? [],
  );

  const currentCompany = loggedInUser.company;

  const [allowGuestUserAccess, setAllowGuestUserAccess] = useState(
    currentCompany.allowGuestUser || false,
  );

  const isMultiClientAdmin = CheckIsMultiClientAdmin(loggedInUser);

  const handleUpdateAllowGuestUserAccess = async (): Promise<void> => {
    const updatedToggle = !allowGuestUserAccess;

    setAllowGuestUserAccess(updatedToggle);

    const updatedCompany = {
      ...currentCompany,
      allowGuestUser: updatedToggle,
    };

    toggleGuestUsers(updatedToggle);
    dispatch(updateUserCompanySettings(updatedCompany));

    handleRemoveUndoUsers(true);
  };

  useEffect(() => {
    const getData = async () => {
      setShowLoadingSpinner(true);
      const companyUsers: IUserModel[] = await getCompanyUsers();

      const { contributingUsers, guestUsers } = filterUsersByType(companyUsers);

      setUserList(contributingUsers);
      setGuestList(guestUsers);
      setShowLoadingSpinner(false);
    };
    getData();
  }, []);

  const clearRemovedUser = (userListToClear: IUserModel[]): IUserModel[] => {
    return userListToClear.filter((u) => !u.canUndoUser);
  };

  const handleAddUser = async (user: IUserModel) => {
    setAccessProcessing(true);
    try {
      const newUser: IUserModel = await addCompanyUsers(user.email!);
      const newUserListWithoutUndoUsers = handleRemoveReplaceUndoUsers(
        userList,
        newUser,
      );
      setUserList(newUserListWithoutUndoUsers);
      setGuestList(clearRemovedUser(guestList));
      setAccessProcessing(false);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(processErrorMessage(error));
      setAccessProcessing(false);
    }
  };

  const handleAddGuest = async (user: IUserModel) => {
    setAccessProcessing(true);
    try {
      const newGuest: IUserModel = await addGuestUsers(user.email!);

      const newGuestListWithoutUndoUsers = handleRemoveReplaceUndoUsers(
        guestList,
        newGuest,
      );
      setGuestList(newGuestListWithoutUndoUsers);
      setUserList(clearRemovedUser(userList));
      setAccessProcessing(false);
      setErrorGuestUserMessage('');
    } catch (error) {
      setErrorGuestUserMessage(processErrorMessage(error));
      setAccessProcessing(false);
    }
  };

  const handleDeleteUser = async (userId: number) => {
    setRemoveProcessing(true);
    await removeUser(userId);

    const newUserList = [...userList];

    const userToRemoveIndex = newUserList.findIndex(
      (foundUser) => foundUser.userId === userId,
    );

    newUserList[userToRemoveIndex].canUndoUser = true;

    setUserList(newUserList);
    setRemoveProcessing(false);
  };

  const handleDeleteGuest = async (userId: number) => {
    setRemoveProcessing(true);
    await removeUser(userId);

    const newGuestUserList = [...guestList];

    const userToRemoveIndex = newGuestUserList.findIndex(
      (foundUser) => foundUser.userId === userId,
    );

    newGuestUserList[userToRemoveIndex].canUndoUser = true;

    setGuestList(newGuestUserList);
    setRemoveProcessing(false);
  };

  const handleUndoRemovedUser = async (
    userToUndo: IUserModel,
    userListType: UserListType,
  ) => {
    setRemoveProcessing(true);

    const modifiedUser = { ...userToUndo, canUndoUser: false };

    await undoDeleteClientUser(
      modifiedUser.userId!,
      userListType === UserListType.Guest,
    );

    handleRemoveUndoUsers(userListType === UserListType.Guest, modifiedUser);

    setRemoveProcessing(false);
  };

  const handleRemoveReplaceUndoUsers = (
    users: IUserModel[],
    replacementUser?: IUserModel,
  ) => {
    let newUsers = [...users];

    if (replacementUser) {
      const oldUserIndex = newUsers.findIndex((newUser) => newUser.canUndoUser);

      if (oldUserIndex !== -1) {
        newUsers.splice(oldUserIndex, 1, replacementUser);
      } else {
        newUsers.push(replacementUser);
      }
    } else {
      newUsers = guestList.filter((user) => !user.canUndoUser);
    }

    return newUsers;
  };

  const handleRemoveUndoUsers = (
    isGuestRemove: boolean = false,
    replacementUser?: IUserModel,
  ) => {
    if (isGuestRemove) {
      const newGuestUsers = handleRemoveReplaceUndoUsers(
        guestList,
        replacementUser,
      );
      setGuestList(newGuestUsers);
    } else {
      const newUsers = handleRemoveReplaceUndoUsers(userList, replacementUser);
      setUserList(newUsers);
    }
  };

  const handleResendUserAccess = async (user: IUserModel) => {
    try {
      await resendUserAccess(user);
    } catch (error) {
      setErrorMessage(processErrorMessage(error));
    }
  };

  return (
    <Modal
      title={loggedInUser.company?.companyName + ' Users'}
      open={true}
      modalSize="medium"
      neutralButtonText="Close"
    >
      {showLoadingSpinner ? (
        <GuruLogoSpinner inProgress />
      ) : (
        <>
          {!isMultiClientAdmin && (
            <>
              <div className="contributing_title">
                <div className="title">CONTRIBUTING USERS</div>
                <Tooltip
                  header="Contributing Users"
                  tooltipPosition="inline-right"
                >
                  Contributing users can add, amend, terminate and delete
                  leases. They can also change settings and view amortization
                  schedules, journal entries, and disclosures.
                </Tooltip>
              </div>
              <ManageUsersList
                domain={loggedInUser.email!.split('@')[1]}
                setErrorMessage={setErrorMessage}
                userList={userList}
                createUser={handleAddUser}
                deleteUser={handleDeleteUser}
                errorMessage={errorMessage}
                resendUserAccess={handleResendUserAccess}
                accessProcessing={accessProcessing}
                removeProcessing={removeProcessing}
                userListType={UserListType.Contributing}
                handleUndoRemovedUser={handleUndoRemovedUser}
              />
              <Divider />
            </>
          )}

          <GuestUserContainer
            planAllowsGuestUser={!!planAllowsGuestUsers}
            userList={guestList}
            handleUpdateAllowGuestUserAccess={handleUpdateAllowGuestUserAccess}
            allowGuestUserAccess={allowGuestUserAccess}
          >
            <ManageUsersList
              domain={loggedInUser.email!.split('@')[1]}
              setErrorMessage={setErrorMessage}
              userList={guestList}
              createUser={handleAddGuest}
              deleteUser={handleDeleteGuest}
              errorMessage={errorGuestUserMessage}
              resendUserAccess={handleResendUserAccess}
              accessProcessing={accessProcessing}
              removeProcessing={removeProcessing}
              userListType={UserListType.Guest}
              handleUndoRemovedUser={handleUndoRemovedUser}
              allowGuestUserAccess={allowGuestUserAccess}
            />
          </GuestUserContainer>
        </>
      )}
    </Modal>
  );
};

export default ManageUsersModal;
