import { CellInfo, Column } from 'react-table';

export interface ITableColumnNames {
  [key: number]: {
    name: string;
    shortName: string;
  };
}

export interface IReactTableColumns<T> {
  [key: number]: Column<T> | IFixedColumn<T>;
}

export interface IFixedColumn<T> extends Column<T> {
  fixed: string;
}

/**
 * A cell that converts a numeric month to a shortened string representation
 *
 * @param props react-table cell information
 */
export function MonthCell(props: CellInfo) {
  const date = new Date();
  date.setDate(1);
  date.setMonth(props.value - 1);
  return date.toLocaleString('en-US', { month: 'short' });
}

/**
 * A cell that formats a number to a currency, without a symbol (eg $). Currently assumed to be en-US locale.
 *
 * @param props react-table cell information
 */
export function CurrencyWithoutSymbolCell(props: CellInfo) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(props.value);
}

/**
 * A cell that formats a number to a currency. Currently assumed to be USD in en-US locale.
 *
 * @param props react-table cell information
 */
export function CurrencyCell(props: CellInfo) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(props.value);
}
