import React, { FunctionComponent } from 'react';
import './PillButton.scss';

interface IPillButtonProps {
  assetType: string;
  isSelected: boolean;
  toggleSelected: (arg0: any) => void;
}

const PillButton: FunctionComponent<IPillButtonProps> = ({
  assetType,
  isSelected,
  toggleSelected,
}) => {
  return (
    <div>
      <button
        className={`pill-button ${isSelected && 'selected'}`}
        onClick={toggleSelected}
      >
        <span>{assetType}</span>
      </button>
    </div>
  );
};

export default PillButton;
