import { Form } from 'formik';
import React, { FunctionComponent } from 'react';
import { ProgressBar } from '../../../common';
import { Button } from '../../../common/buttons';
import { StepNumber } from '../schemas';
import './SteppedFormContainer.scss';

interface ISteppedFormContainerProps {
  stepNumber: StepNumber;
  headerText: string;
  numOfSteps: number;
  handleBack?: () => void;
  processing?: boolean;
  hideHeading?: boolean;
  hideBackButton?: boolean;
  nextButtonOverride?: string;
}

const SteppedFormContainer: FunctionComponent<ISteppedFormContainerProps> = ({
  stepNumber,
  headerText,
  handleBack,
  processing,
  numOfSteps,
  children,
  hideHeading,
  hideBackButton,
  nextButtonOverride,
}) => {
  return (
    <div className="stepped-form-container">
      {hideHeading ? null : (
        <div className="stepped-form-head">
          <div className="stepped-header-text">{headerText}</div>
          <div>
            step {stepNumber} of {numOfSteps}
          </div>
          <ProgressBar startNumber={stepNumber} endNumber={numOfSteps} />
        </div>
      )}

      <div className="stepped-form-body">{children}</div>
      <div className="stepped-form-foot">
        <Form noValidate={true}>
          {!hideBackButton ? (
            <Button
              buttonText="Back"
              buttonType="neutral"
              onClick={handleBack}
            />
          ) : null}
          <Button
            buttonText={
              nextButtonOverride
                ? nextButtonOverride
                : stepNumber === StepNumber.Credentials
                ? 'Submit'
                : 'Next'
            }
            buttonType="positive"
            disabled={processing}
            type="submit"
            processing={processing}
            classes={nextButtonOverride ? 'addPadding' : ''}
          />
        </Form>
      </div>
    </div>
  );
};

export default SteppedFormContainer;
