import React, { FunctionComponent } from 'react';
import { findLeadTrailWhtspace, findWhtspace } from '../utilities/_regex';

import { statusIcons } from '../../assets/index';
import './StatusIndicator.scss';

const StatusIndicator: FunctionComponent<IStatusIndicatorProps> = ({
  statusLabel,
}) => {
  const lowerCasedLabel: string = statusLabel.toLowerCase();
  const imageIcon: string =
    statusIcons[
      lowerCasedLabel === 'expires soon' ? 'expiresSoon' : lowerCasedLabel
    ];
  // Regex for replacing whitespace between words with '-' and removing leading and trailing whitespace
  const statusKey: string = lowerCasedLabel
    .replace(findLeadTrailWhtspace, '')
    .replace(findWhtspace, '-');
  return (
    <div
      id={`status-indicator-${statusKey}`}
      className={`status-indicator ${statusKey}`}
    >
      {imageIcon && (
        <img
          id={`status-indicator-${statusKey}-icon`}
          className="status-indicator__icon"
          src={imageIcon}
          alt={`${statusKey} icon`}
        />
      )}
      {statusLabel || null}
    </div>
  );
};

interface IStatusIndicatorProps {
  statusLabel: string;
}

export default StatusIndicator;
