import { Form, FormikContext } from 'formik';
import React, { useState } from 'react';

import { Button, ButtonGroup } from '../../../../common/buttons';
import { AccentedHeader } from '../../../../common/headers';
import { applyRSMClassName } from '../../../../common/utilities/_helpers';
import { AuthCard, AuthCardDividerFull } from '../../../auth/AuthCard';
import ChoosePlanCard, {
  ChoosePlanCardVariant,
} from './components/ChoosePlanCard';

import './ChoosePlan.scss';

export enum UserTypeId {
  CONTRIBUTING = 3,
  MULTI = 11,
}

export interface ChoosePlanState {
  selectedUserType?: UserTypeId;
}

export interface ChoosePlanProps {
  formProps: FormikContext<ChoosePlanState>;
  onBack: () => void;
}

export default function ChoosePlan({ formProps, onBack }: ChoosePlanProps) {
  const [selectedUserType, setSelectedUserType] = useState<
    UserTypeId | undefined
  >();

  const handleSelect = (accountType: UserTypeId) => {
    setSelectedUserType(accountType);
    formProps.setFieldValue('selectedUserType', accountType);
  };

  return (
    <Form
      noValidate={true}
      className={applyRSMClassName('choose-plan-wrapper')}
    >
      <AuthCard>
        <div className="choose-plan-heading-section">
          <AccentedHeader color="blue" centered>
            Choose your Account Type
          </AccentedHeader>
        </div>

        <div className="error-text">
          {formProps.errors.selectedUserType &&
            'Please select an account type.'}
        </div>

        <div className="choose-plan-grid">
          <ChoosePlanCard
            variant={ChoosePlanCardVariant.CONTRIBUTING}
            onClick={() => handleSelect(UserTypeId.CONTRIBUTING)}
            selected={selectedUserType === UserTypeId.CONTRIBUTING}
          />
          <ChoosePlanCard
            variant={ChoosePlanCardVariant.MULTI}
            onClick={() => handleSelect(UserTypeId.MULTI)}
            selected={selectedUserType === UserTypeId.MULTI}
          />
        </div>

        <AuthCardDividerFull />

        <div className="button-footer">
          <div />
          <ButtonGroup>
            <Button buttonType="neutral" buttonText="Back" onClick={onBack} />
            <Button
              buttonType="positive"
              buttonText={'Next'}
              disabled={false}
              type="submit"
            />
          </ButtonGroup>
        </div>
      </AuthCard>
    </Form>
  );
}
