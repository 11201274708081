import React, { FunctionComponent } from 'react';

import Close from '../../assets/closegroup.svg';
import { FilterTypes } from '../../features/dashboard/Dashboard';

import './MultiSelectPill.scss';

interface IMultiSelectPillProps {
  min: string;
  max: string;
  type: FilterTypes;
  onRemove: () => void;
}

const MultiSelectPill: FunctionComponent<IMultiSelectPillProps> = ({
  min,
  max,
  type,
  onRemove,
}) => {
  return (
    <div>
      <button className={`multiselect ${type}`}>
        <span>{`${min} - ${max}`}</span>
        <div className="close" onClick={onRemove}>
          <img src={Close} alt="close" />
        </div>
      </button>
    </div>
  );
};

export default MultiSelectPill;
