import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getEnterpriseFeatureList } from '../../api';
import { AccentedHeader } from '../../common/headers';
import { useResourceContext } from '../../common/resource-context';
import {
  IPlanFeatureModel,
  IPlanModel,
  IUserModel,
  PlanTier,
} from '../../interfaces';
import { IStore } from '../../store';
import {
  checkIfUserIsOnMaxPlan,
  getMostExpensivePlanByType,
  getPlanTierPriceRange,
  getTierFeatureList,
} from '../plans/helpers';
import { SubscriptionModalPages } from './SubscriptionModal';
import TierCard from './TierCard';

import './TierPage.scss';

interface ITierPageProps {
  setSelectedTierFeatures: (selectedTierFeatures: IPlanFeatureModel[]) => void;
  setNavigation: (navigateTo: SubscriptionModalPages) => void;
}

const TierPage: React.FC<ITierPageProps> = ({
  setSelectedTierFeatures,
  setNavigation,
}) => {
  const resources = useResourceContext();
  const [enterpriseTierFeatures, setEnterpriseTierFeatures] = useState<
    IPlanFeatureModel[]
  >([]);

  const plans: IPlanModel[] = useSelector((store: IStore) => {
    return store.plans;
  });

  const loggedInUser: IUserModel = useSelector((state: IStore) => state.user);

  const userCurrentPlan = loggedInUser.company.plan;

  const userIsOnMaxPlan = checkIfUserIsOnMaxPlan(loggedInUser, plans);

  const basicTierPlan = getMostExpensivePlanByType(PlanTier.BASIC, plans);

  const premiumTierPlan = getMostExpensivePlanByType(PlanTier.PREMIUM, plans);

  const basicTierFeatures = getTierFeatureList(PlanTier.BASIC, plans);

  const premiumTierFeatures = getTierFeatureList(PlanTier.PREMIUM, plans);

  const premiumTierPlanPrice = getPlanTierPriceRange(plans, PlanTier.PREMIUM);

  const basicTierPlanPrice = getPlanTierPriceRange(plans, PlanTier.BASIC);

  useEffect(() => {
    const getEnterpriseFeatures = async () => {
      const features: IPlanFeatureModel[] = await getEnterpriseFeatureList();

      setEnterpriseTierFeatures(features);
    };

    getEnterpriseFeatures();
  }, []);

  const navigateToEnterprise = () => {
    const enterpriseUrl = resources.text('EnterprisePricingUrl');
    window.open(enterpriseUrl, '_blank');
  };

  return (
    <Fragment>
      <div className="plan-header">
        <AccentedHeader color="blue" centered>
          You're on the {userCurrentPlan?.displayName} Plan
        </AccentedHeader>
        <div className="tier-text">
          Start exploring our{' '}
          {userCurrentPlan?.tier === PlanTier.BASIC ? 'Premium' : 'Enterprise'}{' '}
          plan today!
        </div>
      </div>
      <div className="tier-section">
        {basicTierPlan ? (
          <TierCard
            tierTitle="Basic"
            tierPrice={basicTierPlanPrice}
            tierFeatures={basicTierFeatures}
            disableUpgrade={userCurrentPlan?.tier !== PlanTier.TRIAL}
            upgradeButtonText={
              userCurrentPlan?.tier === PlanTier.BASIC
                ? 'CURRENT PLAN'
                : undefined
            }
            setNavigation={() => {
              setNavigation(SubscriptionModalPages.calculator);
            }}
            setSelectedTierFeatures={() =>
              setSelectedTierFeatures(basicTierFeatures)
            }
          />
        ) : null}
        {premiumTierPlan ? (
          <TierCard
            tierTitle="Premium"
            tierPrice={premiumTierPlanPrice}
            tierFeatures={premiumTierFeatures}
            setNavigation={() => {
              setNavigation(SubscriptionModalPages.calculator);
            }}
            upgradeButtonText={userIsOnMaxPlan ? 'CURRENT PLAN' : 'UPGRADE'}
            setSelectedTierFeatures={() =>
              setSelectedTierFeatures(premiumTierFeatures)
            }
            disableUpgrade={userIsOnMaxPlan}
          />
        ) : null}

        <TierCard
          tierTitle="Enterprise"
          tierPrice="Contact Us"
          tierFeatures={enterpriseTierFeatures}
          upgradeButtonText="REQUEST PRICING"
          setNavigation={navigateToEnterprise}
        />
      </div>
    </Fragment>
  );
};

export default TierPage;
