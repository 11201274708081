import { AnyAction } from 'redux';
import { CurrentAdminPlanActions, MultiCLientPlanActions } from './actions';

export const multiClientPlanReducer = (
  state = [],
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case MultiCLientPlanActions.STORE_MULTI_CLIENT_PLANS:
      return payload.multiClientPlans;
    case MultiCLientPlanActions.CLEAR_MULTI_CLIENT_PLANS:
      return [];
    default:
      return state;
  }
};

export const currentAdminPlanReducer = (
  state = {},
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case CurrentAdminPlanActions.STORE_CURRENT_ADMIN_PLAN:
      return payload.currentAdminPlan;
    case CurrentAdminPlanActions.CLEAR_CURRENT_ADMIN_PLAN:
      return {};
    default:
      return state;
  }
};
