import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEnterpriseFeatureList } from '../../../api';
import checkmarkna from '../../../assets/checkmark-na.svg';
import checkmark from '../../../assets/checkmark.svg';
import rsmCheckmark from '../../../assets/rsm-checkmark.svg';
import { showModal } from '../../../common/modal/actions';
import { IPlanFeatureModel, IPlanModel } from '../../../interfaces';
import { IStore } from '../../../store';
import { SubscriptionModalPages } from '../../subscriptions/SubscriptionModal';
import {
  buildPlanTableDetail,
  IPlanFeatureTableItem,
} from '../helpers/helpers';
import BasicPlanHeader from '../plansTable/BasicPlanHeader';
import EnterprisePlanHeader from '../plansTable/EnterprisePlanHeader';
import {
  buildPlanTableCPA,
  getMultiClientFeaturesValue,
  getMultiClientLeasesCount,
} from './helpers/helpers';
import './PlansTable.scss';
import PremiumPlanHeader from './PremiumPlanHeader';

const PlansTable: FunctionComponent = () => {
  const plans: IPlanModel[] = useSelector((store: IStore) => {
    return store.plans;
  });
  const userCurrentPlan = useSelector(
    (state: IStore) => state.currentAdminPlan,
  );
  const [enterpriseTierFeatures, setEnterpriseTierFeatures] = useState<
    IPlanFeatureTableItem[][]
  >([]);
  const isRSM = process.env.REACT_APP_ENABLE_RSM === 'true';

  const multiClientPlans: IPlanModel[] = useSelector(
    (state: IStore) => state.multiClientPlans,
  );

  useEffect(() => {
    const getEnterpriseFeatures = async () => {
      const features: IPlanFeatureModel[] = await getEnterpriseFeatureList(
        false,
      );
      const summaryFeatures = features.sort((a, b) =>
        a.orderNumber < b.orderNumber ? -1 : 1,
      );
      setEnterpriseTierFeatures(
        buildPlanTableCPA(buildPlanTableDetail(summaryFeatures, plans)),
      );
    };
    getEnterpriseFeatures();
  }, [plans, multiClientPlans]);

  const displayFeatureOnPlan = (
    features: IPlanFeatureTableItem[],
    planName: string,
    index: number,
  ) => {
    return features.map((f, i) => {
      if (f.name === 'Leases per Company') {
        return <div key={i * index}>{getMultiClientLeasesCount(planName)}</div>;
      }
      if (f.name === 'CPA Admin Users') {
        return (
          <div key={i * index}>
            {getMultiClientFeaturesValue(multiClientPlans, planName, 'Users')}
          </div>
        );
      }
      if (f.name === 'Guest Users per Company') {
        return (
          <div key={i * index}>
            {getMultiClientFeaturesValue(multiClientPlans, planName, 'Users')}
          </div>
        );
      }
      const checked = (
        <img
          src={isRSM ? rsmCheckmark : checkmark}
          alt="check"
          key={i * index}
        />
      );
      const unChecked = <img src={checkmarkna} alt="check" key={i * index} />;
      switch (planName) {
        case 'Basic':
          return f.isBasic ? checked : unChecked;
        case 'Premium 2':
          return f.isPremium2 ? checked : unChecked;
        default:
          return checked;
      }
    });
  };

  const dispatch = useDispatch();
  const showCheckoutPageModal = (planName: string) => {
    dispatch(
      showModal({
        modal: {
          modalType: 'SUBSCRIPTIONS',
          modalProps: {
            open: true,
            initialSubscriptionModalPage: SubscriptionModalPages.payment,
          },
        },
      }),
    );
  };

  return (
    <table className="cpa-table">
      <thead className="header">
        <tr>
          <th />
          <th>
            <BasicPlanHeader
              userCurrentPlan={userCurrentPlan}
              isDisplayOnCPAPricingPage={true}
            />
          </th>
          <th>
            <PremiumPlanHeader
              planModels={multiClientPlans}
              checkOut={showCheckoutPageModal}
              userCurrentPlan={userCurrentPlan}
            />
          </th>
          <th>
            <EnterprisePlanHeader isDisplayOnCPAPricingPage={true} />
          </th>
        </tr>
      </thead>
      <tbody className="body">
        {enterpriseTierFeatures
          .slice(0, enterpriseTierFeatures.length)
          .map((features, index) => {
            return (
              <tr key={index}>
                <td>
                  {features.map((f, i) => (
                    <div key={i}>{f.name}</div>
                  ))}
                </td>
                <td>{displayFeatureOnPlan(features, 'Basic', index + 1)}</td>
                <td>
                  {displayFeatureOnPlan(features, 'Premium 2', index + 1)}
                </td>
                <td>
                  {displayFeatureOnPlan(features, 'Enterprise', index + 1)}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default PlansTable;
