import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { cpaClientsGet, cpaDeleteClient } from '../../api/company';
import { LoadingProcess } from '../../app';
import { Header } from '../../common';
import ModalRoot from '../../common/modal/ModalRoot';
import { applyRSMClassName } from '../../common/utilities/_helpers';
import { ICPACompanyDetail } from '../../interfaces';
import CompanyList from './components/CompanyList';
import UpgradeFooter from './components/UpgradeFooter';

import { hideModal, showModal } from '../../common/modal/actions';

import TagManager from 'react-gtm-module';
import './MultiClientLanding.scss';

const MultiClientLanding: FunctionComponent = () => {
  const dispatch = useDispatch();

  const [companyList, setCompanyList] = useState<ICPACompanyDetail[]>([]);

  const fetchCompanyList = useCallback(async () => {
    const list: ICPACompanyDetail[] = await cpaClientsGet();
    setCompanyList(list);
    if (list.length === 0) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'account_sign_up_progress',
          step_completion_step: '5',
          step_completion_name: 'Add Companies to List',
          step_completion_detail: 'Multi-Company',
        },
      });
    }
  }, []);

  useEffect(() => {
    fetchCompanyList();
  }, [fetchCompanyList]);

  const showCreateClientModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'CREATE_ACCOUNT',
          modalProps: {
            open: true,
            hideModal: () => dispatch(hideModal()),
            isCreateClientOnly: true,
            title: 'Add Client',
          },
        },
      }),
    );
  };

  const handleDelete = async (companyId: number) => {
    await cpaDeleteClient(companyId);
    fetchCompanyList();
  };

  return (
    <>
      <div className={applyRSMClassName('multi-client-page-wrap')}>
        <div className="header-wrap">
          <Header setLoading={() => LoadingProcess.INIT} />
        </div>

        <div className="multi-client-main-container">
          <div className="companies-heading">Companies</div>
          <CompanyList
            companyList={companyList}
            onAddClientClick={showCreateClientModal}
            onDelete={handleDelete}
          />
          <UpgradeFooter />
        </div>
      </div>
      <ModalRoot />
    </>
  );
};

export default MultiClientLanding;
