import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../../common/buttons';
import DatepickerInput from '../../../common/datepicker/DatepickerInput';
import FormError from '../../../common/form-error/FormError';
import {
  hideSteppedModal,
  showSteppedModal,
} from '../../../common/modal/actions';
import Modal from '../../../common/modal/Modal';
import SelectableImage from '../../../common/selectable-image/SelectableImage';
import { defaultDateRegex } from '../../../common/utilities/_regex';
import { ILeaseModel } from '../../../interfaces';
import { IStore } from '../../../store';
import { LeaseForms, populateAllForms, populateSelectForms } from '../actions';
import { handleValidation } from '../validateEffectiveDate';

import calendar_low from '../../../assets/calendar_low.png';
import dozer_low from '../../../assets/dozer_low.png';

import TagManager from 'react-gtm-module';
import './AmendTypeModal.scss';

interface IAmendTypeModalProps {
  open: boolean;
  hideModal: () => void;
  otherProps: {
    selectedLease: ILeaseModel;
  };
}

const AmendTypeModal: FunctionComponent<IAmendTypeModalProps> = ({
  open,
  hideModal,
}) => {
  const dispatch = useDispatch();
  const selectedLease = useSelector((state: IStore) => {
    return state.leaseDetails.selectedLease;
  });
  const [showDateField, toggleDateField] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState('');
  const [dateError, setDateError] = useState('');
  const {
    leaseBeginDt,
    leaseEndDt,
    leaseModificationDate,
  } = selectedLease.header;

  // useEffect fires for every effectiveDate change
  // sets an error message if necessary
  useEffect(() => {
    setDateError(
      handleValidation(
        leaseBeginDt,
        leaseEndDt,
        effectiveDate,
        leaseModificationDate,
      ),
    );
  }, [effectiveDate, leaseBeginDt, leaseEndDt, leaseModificationDate]);

  const handleAmendClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'amend_lease_interaction',
        change_type: 'Override past financial data',
      },
    });

    dispatch(populateAllForms(selectedLease));
    dispatch(hideModal);
    dispatch(
      showSteppedModal({
        modal: {
          modalType: 'STEPPED_MODAL',
          modalProps: {
            open: true,
            hideModal: () => dispatch(hideSteppedModal()),
            title: 'Amend Lease',
            amendType: 'OVERRIDE',
          },
        },
      }),
    );
  };
  const handleModifyClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'amend_lease_interaction',
        change_type: 'Select effective date of change',
      },
    });
    toggleDateField(true);
  };

  const openSteppedModal = () => {
    dispatch(
      populateSelectForms(
        [LeaseForms.GENERAL, LeaseForms.DATE, LeaseForms.OPTIONS],
        selectedLease,
      ),
    );
    dispatch(hideModal);
    dispatch(
      showSteppedModal({
        modal: {
          modalType: 'STEPPED_MODAL',
          modalProps: {
            open: true,
            hideModal: () => dispatch(hideSteppedModal()),
            title: `Amending Lease as of ${effectiveDate}`,
            amendType: 'MODIFY',
            otherProps: { effectiveDate },
          },
        },
      }),
    );
  };

  return (
    <Modal
      title={`Let's determine your type of change`}
      open={open}
      onConfirm={() => undefined}
      hideModal={hideModal}
      neutralButtonText="Cancel"
    >
      <div className="amend-type-wrapper">
        <SelectableImage
          image={calendar_low}
          imageName="calendar"
          isSelected={showDateField}
          largeImageText="Select effective date of change"
          smallImageText={`Make a point-in-time modification or renewal`}
          onClick={handleModifyClick}
        />
        <SelectableImage
          image={dozer_low}
          imageName="dozer"
          largeImageText="Override past financial data"
          smallImageText="Correct errors made during lease entry"
          onClick={handleAmendClick}
        />
        <div className={`amend-type-date ${showDateField ? 'show' : ''}`}>
          <DatepickerInput
            onChange={(e) => setEffectiveDate(e.target.value)}
            name="effectiveDate"
            value={effectiveDate}
            placeholderText="mm/dd/yyyy"
          />
          <Button
            buttonType="positive"
            buttonText="Continue"
            onClick={openSteppedModal}
            disabled={!defaultDateRegex.test(effectiveDate) || !!dateError}
          />
          <FormError error={!!dateError} errorMessage={dateError} />
        </div>
      </div>
    </Modal>
  );
};

export default AmendTypeModal;
