import React, { FunctionComponent } from 'react';

import './Checkbox.scss';

const Checkbox: FunctionComponent<ICheckboxProps> = ({
  label,
  name,
  id,
  checked,
  onSelect,
  children,
}) => {
  return (
    <div
      className={`checkbox-wrapper ${process.env.REACT_APP_ENABLE_RSM ===
        'true' && 'rsm-class-checkbox'}`}
    >
      <input
        type="checkbox"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSelect(e, name)}
        checked={checked}
        name={name}
        id={id || name}
        className="checkbox-input"
      />
      <label htmlFor={id || name} className="checkbox-value">
        {label || children}
      </label>
    </div>
  );
};

interface ICheckboxProps {
  name: string;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, name: string) => void;
  checked: boolean;
  label?: string;
  id?: string;
}

export default Checkbox;
