import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import App from './app';
import { withTracker } from './common/google-analytics/withTracker';
import {
  createResourceContextObject,
  ResourceContext,
} from './common/resource-context';
import store from './store';

import './index.scss';

const bootstrap = async () => {
  const target = document.getElementById('root');

  const resources = await createResourceContextObject();

  document.title = resources.text('Global_SiteTitle', 'LeaseGuru');

  render(
    <ResourceContext.Provider value={resources}>
      <Provider store={store}>
        <BrowserRouter>
          <Route component={withTracker(App)} />
        </BrowserRouter>
      </Provider>
    </ResourceContext.Provider>,
    target,
  );
};
bootstrap();
