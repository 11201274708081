import { format } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import { Currency, StatusIndicator } from '../../common';
import { DEFAULT_DATE_FORMAT } from '../../common/utilities/_dates';
import { ILeaseHeaderModel } from '../../interfaces';

import './LeaseListItem.scss';

interface ILeaseListItemProps {
  lease: ILeaseHeaderModel;
  addToRecentList: (lease: ILeaseHeaderModel) => void;
}

const LeaseListItem: FunctionComponent<ILeaseListItemProps> = ({
  lease,
  addToRecentList,
}) => {
  const history = useHistory();

  const onClickLease = () => {
    if (window.innerWidth >= 480) {
      history.push(`/leases/${lease.leaseId}`);
    }

    addToRecentList(lease);
  };
  return (
    <div
      className="lease-item-wrapper"
      onClick={() => {
        onClickLease();
        addToRecentList(lease);
      }}
      id={`${lease.leaseId}`}
    >
      <div className="lease-name">
        <StatusIndicator statusLabel={lease.leaseStatus} />
        <span>{lease.assetName}</span>
        {lease.assetName.length > 49 ? (
          <div className="tooltip">{lease.assetName}</div>
        ) : null}
      </div>
      <span>
        {format(new Date(lease.nextPaymentDate), DEFAULT_DATE_FORMAT)}
      </span>
      <span>
        <Currency value={lease.nextPaymentAmt} />
      </span>
      <span>{lease.remainingPayments}</span>
      <span>{format(new Date(lease.leaseBeginDt), DEFAULT_DATE_FORMAT)}</span>
      <span>{format(new Date(lease.leaseEndDt), DEFAULT_DATE_FORMAT)}</span>
      <span>{lease.assetType}</span>
    </div>
  );
};

export default LeaseListItem;
