import { Form, FormikContext } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getAdminClientList, getUserClientList } from '../../api';
import { Autocomplete } from '../../common';
import { Button, ButtonGroup } from '../../common/buttons';
import { UserRole } from '../../common/utilities/_authHelpers';
import { checkIfUserIsGuest } from '../../common/utilities/_guestUserHelper';
import { formatDropOptions } from '../../common/utilities/_helpers';
import {
  AutocompleteType,
  ICompanySelectorRoleOption,
  IUserModel,
} from '../../interfaces';
import { AuthCard, AuthCardDividerFull, AuthCardSection } from './AuthCard';

import './CompanySelectionForm.scss';

export interface ICompanySelectionFormState {
  company?: number;
}

interface ICompanySelectionFormProps {
  formProps: FormikContext<ICompanySelectionFormState>;
  processing?: boolean;
  shouldDisableSubmit?: (formProps: FormikContext<any>) => boolean;
  handleBack?: () => void;
  authenticatedUser: IUserModel;
  clientList?: ICompanySelectorRoleOption[];
}

const CompanySelectionForm: FunctionComponent<ICompanySelectionFormProps> = ({
  formProps,
  handleBack,
  shouldDisableSubmit,
  processing,
  authenticatedUser,
  clientList,
}) => {
  const [clients, setClients] = useState<ICompanySelectorRoleOption[]>(
    clientList || [],
  );
  const [shouldDisplayCreateCompany, setShouldDisplayCreateCompany] = useState(
    false,
  );

  useEffect(() => {
    const loadClientsList = async () => {
      const clientResponse = checkIfUserIsGuest(authenticatedUser)
        ? await getUserClientList(authenticatedUser.userId ?? 0)
        : await getAdminClientList();

      if (clientResponse.length) {
        setClients(clientResponse);

        setShouldDisplayCreateCompany(
          clientResponse[0].userRole !== UserRole.ContributingUser &&
            authenticatedUser.userRole !== UserRole.LQAdmin,
        );
      }
    };

    if (!clientList) {
      loadClientsList();
    }
  }, [authenticatedUser, clientList]);

  return (
    <div className="company-select-wrap">
      <Form noValidate={true}>
        <AuthCard>
          <AuthCardSection>
            <div className="header">
              <h1 className="h1">Select Company</h1>
              <p>
                Please select the name of the company you want to view. You can
                begin typing the company name in the text field to search
                faster.
              </p>
            </div>
            <Autocomplete
              onSelection={(selectedItem) =>
                formProps.setFieldValue('company', selectedItem.value)
              }
              placeholder="Company Name..."
              options={formatDropOptions(
                clients,
                'id',
                'id',
                'name',
                'userRole',
              )}
              value={formProps.values.company}
              autoCompleteType={AutocompleteType.ClientSelector}
            />
            <ButtonGroup>
              {handleBack ? (
                <Button
                  buttonType="neutral"
                  buttonText="Back"
                  type="button"
                  onClick={handleBack}
                />
              ) : null}
              <Button
                buttonType="positive"
                buttonText="Sign In"
                type="submit"
                block={true}
                processing={processing}
                processingText="Signing In"
                disabled={
                  (shouldDisableSubmit && shouldDisableSubmit(formProps)) ||
                  processing ||
                  !formProps.values.company
                }
              />
            </ButtonGroup>
          </AuthCardSection>
          <div>
            <AuthCardDividerFull />

            {shouldDisplayCreateCompany ? (
              <>
                <div className="add-company-section">
                  <Link
                    className="sign-in-link"
                    to={{
                      pathname: '/get-started/create-company',
                      state: { existingUser: authenticatedUser },
                    }}
                  >
                    <p>Create your own company account</p>
                  </Link>
                </div>
                <AuthCardDividerFull />
              </>
            ) : null}

            <AuthCardSection>
              <p>
                To change companies, you will need to log out and log back in
                again, then select another company from this screen.
              </p>
            </AuthCardSection>
          </div>
        </AuthCard>
      </Form>
    </div>
  );
};

export default CompanySelectionForm;
