import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import TagManager from 'react-gtm-module';
import {
  cpaClientsGet,
  getActivePlans,
  getClient,
  getCurrentUser,
  getLeases,
  getLeasesByClient,
  getMappedIntegrationSettings,
  getMappedQBSettings,
  getMultiClientPlans,
  getQBAuthToken,
  verifyIntegrationAuth,
  verifyQBToken,
} from '../api';
import { Footer, Header } from '../common';
import { hideModal, showModal } from '../common/modal/actions';
import ModalRoot from '../common/modal/ModalRoot';
import UpgradeBanner from '../common/upgrade-banner/UpgradeBanner';
import {
  CheckIsMultiClientAdmin,
  isAdminGuestOrContributing,
} from '../common/utilities/_authHelpers';
import { loadAllThirdPartyScriptData } from '../common/utilities/_thirdPartyScripts';
import { FeatureFlagConfig, FeatureQueryProvider } from '../featureQuery';
import { SignIn, UnauthenticatedPage } from '../features/auth';
import { clearUser, saveUser } from '../features/auth/actions';
import CreateAccount from '../features/createAccount/CreateAccount';
import Dashboard from '../features/dashboard/Dashboard';
import Disclosures from '../features/disclosures/Disclosures';
import { ForgotPassword } from '../features/forgotPassword/ForgotPassword';
import { clearAllForms } from '../features/lease/actions';
import SteppedModalRoot from '../features/lease/components/SteppedModalRoot';
import { LeaseDetails } from '../features/leaseDetails';
import { clearLeases, storeLeases } from '../features/leaseList/actions';
import {
  storeCurrentAdminPlan,
  storeMultiClientPlans,
} from '../features/multiClient/actions';
import MultiClientLanding from '../features/multiClient/MultiClientLanding';
import { storePlans } from '../features/plans/actions';
import {
  checkIfUserHasAccessToAnyFeature,
  checkIfUserHasAccessToFeature,
} from '../features/plans/helpers';
import {
  PlansAndPricing,
  PlansAndPricingCPA,
} from '../features/plansAndPricing';
import { storeQBMappings } from '../features/quickbooks/actions';
import TotalOrgJe from '../features/totalOrgJe/TotalOrgJe';
import '../index.scss';
import {
  ClientIntegration,
  ICPACompanyDetail,
  ILeaseHeaderModel,
  IUserModel,
  PlanFeatureNames,
} from '../interfaces';
import { IStore } from '../store';
export enum LoadingProcess {
  INIT,
  UNAUTH,
  QB_AUTH,
}

const tagManagerArgs = {
  gtmId: 'GTM-NMMTQ8G',
};

TagManager.initialize(tagManagerArgs);

const App: FunctionComponent = () => {
  const dispatch = useDispatch();
  const loggedInUser: IUserModel = useSelector((state: IStore) => state.user);
  const [loading, setLoading] = useState<LoadingProcess>(LoadingProcess.INIT);
  const urlParams = new URLSearchParams(window.location.search);

  const features = useSelector((store: IStore) => {
    return store.user.company.plan?.features;
  });

  const userIsMultiClientAdmin = useMemo(() => {
    return CheckIsMultiClientAdmin(loggedInUser);
  }, [loggedInUser]);

  const userHasAccessToIntegrations = useMemo(
    () =>
      checkIfUserHasAccessToAnyFeature(
        [
          PlanFeatureNames.quickbooks,
          PlanFeatureNames.sage,
          PlanFeatureNames.xero,
        ],
        features,
        loggedInUser.userRole === 1,
      ),
    [features, loggedInUser],
  );

  const userHasAccessToViewDisclosureReport = useMemo(
    () =>
      checkIfUserHasAccessToFeature(
        PlanFeatureNames.disclosures,
        features,
        loggedInUser.userRole === 1,
      ),
    [features, loggedInUser],
  );

  const userHasAccessToViewTotalOrg = useMemo(
    () =>
      checkIfUserHasAccessToFeature(
        PlanFeatureNames.totalOrg,
        features,
        loggedInUser.userRole === 1,
      ),
    [features, loggedInUser],
  );

  useEffect(() => {
    const storeQuickBooksParams = async () => {
      if (urlParams.has('code') && urlParams.has('realmId')) {
        const code = urlParams.get('code');
        const realmId = urlParams.get('realmId');

        if (code && realmId) {
          // Get AuthToken
          if (loading !== LoadingProcess.QB_AUTH) {
            setLoading(LoadingProcess.QB_AUTH);
            await getQBAuthToken(code, realmId);
          }
        }
      }
    };
    storeQuickBooksParams();
  }, [urlParams, loading]);

  useEffect(() => {
    const fetchUser = async () => {
      if (!(loggedInUser && loggedInUser.userId)) {
        const userToken = sessionStorage.getItem('userToken');
        if (userToken) {
          try {
            let user: IUserModel = await getCurrentUser();

            if (user) {
              loadAllThirdPartyScriptData(user);
            }

            if (user.userRole === 1) {
              const companySelection = Number(
                sessionStorage.getItem('admin-company-selection'),
              );

              if (companySelection) {
                const selectedCompany = await getClient(companySelection);

                if (selectedCompany) {
                  user = { ...user, company: selectedCompany };
                }
              }
            }

            // load all plans
            if (user.company.jurisdiction) {
              await loadPlans(user.company.jurisdiction);
            }

            dispatch(saveUser(user));

            if (CheckIsMultiClientAdmin(user)) {
              await loadMultiClientPlans(user);

              if (await isCpaClient(user.company.clientId)) {
                const leases = await getLeasesByClient(
                  Number(user.company.clientId),
                );
                dispatch(storeLeases(leases));
              }
            }

            return user;
          } catch (error) {
            sessionStorage.setItem('userToken', '');
            if (loading !== LoadingProcess.UNAUTH) {
              setLoading(LoadingProcess.UNAUTH);
            }
          }
        } else {
          if (loading !== LoadingProcess.UNAUTH) {
            setLoading(LoadingProcess.UNAUTH);
          }
        }
      }
    };

    const isCpaClient = async (
      companyClientId: number | undefined,
    ): Promise<boolean> => {
      const clientList: ICPACompanyDetail[] = await cpaClientsGet();
      return (
        !!companyClientId &&
        clientList.map((_) => _.id).includes(companyClientId)
      );
    };

    const loadPlans = async (jurisdiction: string) => {
      const planData = await getActivePlans(jurisdiction);

      dispatch(storePlans(planData));
    };

    const loadMultiClientPlans = async (user: IUserModel) => {
      const planData = await getMultiClientPlans();
      dispatch(storeMultiClientPlans(planData));
      const plan = (user.parentCompany ?? user.company).plan;
      dispatch(storeCurrentAdminPlan(plan));
    };

    const loadLeases = async (user: IUserModel) => {
      if (user && user.userId) {
        let leases: ILeaseHeaderModel[] = [];
        try {
          // if they are an admin user, use the admin lease loader
          if (isAdminGuestOrContributing(user.userRole)) {
            if (user.company && user.company.clientId) {
              leases = await getLeasesByClient(user.company.clientId);
            } else {
              sessionStorage.setItem('userToken', '');
              dispatch(clearUser());
              dispatch(clearAllForms());
              dispatch(clearLeases());
            }
          } else {
            leases = await getLeases(Number(user.userId));
          }

          if (leases.length) {
            dispatch(storeLeases(leases));
          }

          return leases;
        } catch (error) {
          alert('There was an issue loading leases');
        }
      }
    };

    const loadQBMappings = async () => {
      if (loggedInUser && loggedInUser.company.clientId) {
        const mappedSettings = await getMappedQBSettings(
          loggedInUser.company.clientId,
        );
        if (mappedSettings) {
          dispatch(storeQBMappings(mappedSettings));
        }
      }
    };

    const loadIntegrationMappings = async () => {
      if (loggedInUser && loggedInUser.company.clientId) {
        const mappedSettings = await getMappedIntegrationSettings();
        if (mappedSettings) {
          dispatch(storeQBMappings(mappedSettings));
        }
      }
    };

    const handleVerifyQBToken = async () => {
      try {
        await verifyQBToken();
      } catch (error) {
        dispatch(
          showModal({
            modal: {
              modalType: 'QB_SYNC',
              modalProps: {
                open: true,
              },
            },
          }),
        );
      }
    };

    const handleLoginFlow = async () => {
      if (loggedInUser.userId) {
        await handleAuthenticatedFlow();
      } else {
        await handleUnauthenticatedFlow();
      }
    };

    const handleUnauthenticatedFlow = async () => {
      if (loading !== LoadingProcess.QB_AUTH) {
        await fetchUser();
      }
    };

    const erpIntegrationStation = async (
      currentIntegration: ClientIntegration,
    ) => {
      switch (currentIntegration) {
        case ClientIntegration.QUICKBOOKS:
          if (loading !== LoadingProcess.QB_AUTH) {
            await handleVerifyQBToken();
          }
          await loadQBMappings();
          break;
        case ClientIntegration.SAGE:
          await handleVerifySage();
          await loadIntegrationMappings();
          break;
      }
    };

    const handleVerifySage = async () => {
      try {
        await verifyIntegrationAuth();
      } catch (error) {
        dispatch(
          showModal({
            modal: {
              modalType: 'SAGE_LOGIN',
              modalProps: {
                open: true,
                hideModal: () => dispatch(hideModal()),
              },
            },
          }),
        );
      }
    };

    const handleAuthenticatedFlow = async () => {
      if (userHasAccessToIntegrations) {
        if (loggedInUser.company.clientIntegrations) {
          const currentIntegration: ClientIntegration =
            loggedInUser.company.clientIntegrations[0];

          await erpIntegrationStation(currentIntegration);
        }
      }

      if (!userIsMultiClientAdmin) {
        await loadLeases(loggedInUser);
      }
    };

    handleLoginFlow();

    ReactGA.initialize('UA-146065956-2');
  }, [
    loggedInUser,
    dispatch,
    loading,
    userHasAccessToIntegrations,
    userIsMultiClientAdmin,
  ]);

  //#region Feature Flag
  const { REACT_APP_FF_HOST = '' } = process.env;
  const { REACT_APP_FF_API_KEY = '' } = process.env;

  // provider will not be initialized until loggedInUser.company.clientId is available
  const clientId = loggedInUser?.company?.clientId || 0;

  const featureHubConfig: FeatureFlagConfig = {
    clientId,
    flagsInUse: [
      'Guru_Plans_Pricing',
      'Guru_Show_Disclosure_Export_With_LeaseQuery',
      'Guru_Future_Journal_Entries',
    ],
    host: REACT_APP_FF_HOST,
    apiKey: REACT_APP_FF_API_KEY,
    // API provided flags are used to bypass client-side connection to feature hub
    providedFlags: loggedInUser.company.featureFlags,
  };

  const unauthFeatureHubConfig: FeatureFlagConfig = {
    clientId: 1,
    flagsInUse: ['Guru_Plans_Pricing', 'Guru_CPA'],
    host: REACT_APP_FF_HOST,
    apiKey: REACT_APP_FF_API_KEY,
  };

  //#endregion

  if (loggedInUser && loggedInUser.userId) {
    return (
      <FeatureQueryProvider config={featureHubConfig}>
        <Switch>
          {/* full page views */}
          <Route exact path="/pricing" component={PlansAndPricing} />
          {userIsMultiClientAdmin ? (
            <Route
              exact
              path="/multicompany-pricing"
              component={PlansAndPricingCPA}
            />
          ) : null}
          {userIsMultiClientAdmin ? (
            <Route
              exact
              path="/multicompany-landing"
              component={MultiClientLanding}
            />
          ) : null}

          <div className="App-body">
            <Header setLoading={setLoading} />
            <UpgradeBanner />
            <main className="main-container">
              <Route exact path="/leases" component={Dashboard} />
              {userHasAccessToViewDisclosureReport ? (
                <Route
                  exact
                  path="/disclosures"
                  render={(props) => (
                    <Disclosures {...props} company={loggedInUser.company} />
                  )}
                />
              ) : null}
              {userHasAccessToViewTotalOrg ? (
                <Route
                  exact
                  path="/total-je"
                  render={(props) => (
                    <TotalOrgJe {...props} company={loggedInUser.company} />
                  )}
                />
              ) : null}
              <Route
                exact
                path={['/leases/:id', '/leases/:id/fullscreen']}
                component={LeaseDetails}
              />
              <Route exact path={['/leases/:id', '/leases/:id/fullscreen']} />
              <Redirect to="/leases" />
            </main>
            <SteppedModalRoot />
            <ModalRoot />
            <Footer />
          </div>
        </Switch>
      </FeatureQueryProvider>
    );
  } else if (loading === LoadingProcess.UNAUTH) {
    return (
      <FeatureQueryProvider config={unauthFeatureHubConfig}>
        <UnauthenticatedPage>
          <Switch>
            <Route
              exact
              path={[
                '/auth/sign-in',
                '/auth/sign-in-upgrade',
                '/auth/sign-in/:tier',
              ]}
              component={SignIn}
            />
            <Route
              exact
              path="/get-started/create-company"
              render={(props: any) => <CreateAccount {...props} />}
            />
            <Route
              exact
              path="/get-started/:linkManagerID?"
              component={CreateAccount}
            />
            <Route
              exact
              path="/get-started-upgrade/:tier"
              component={CreateAccount}
            />
            <Route
              exact
              path="/auth/forgot-password"
              component={ForgotPassword}
            />
            <Redirect to="/auth/sign-in" />
          </Switch>
          <SteppedModalRoot />
          <ModalRoot />
        </UnauthenticatedPage>
      </FeatureQueryProvider>
    );
  } else {
    return <></>;
  }
};

export default App;
