import { AnyAction } from 'redux';

import {
  populateAdditionalPaymentsForm,
  populateBasePaymentForm,
  populateDateForm,
  populateGeneralForm,
  populateOptionsForm,
  populateSelectForms,
} from '../../api';
import { LeaseActions, LeaseForms } from './actions';

const initialAddLeaseFormState: ILeaseState = {
  form: {},
};

export const leaseReducer = (
  state = initialAddLeaseFormState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case LeaseActions.SAVE_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          [payload.id]: payload.form,
        },
      };
    case LeaseActions.POPULATE_ALL_FORMS:
      return {
        ...state,
        form: {
          ...state.form,
          [LeaseForms.GENERAL]: populateGeneralForm(payload),
          [LeaseForms.DATE]: populateDateForm(payload),
          [LeaseForms.OPTIONS]: populateOptionsForm(payload),
          [LeaseForms.BASE]: populateBasePaymentForm(payload),
          [LeaseForms.ADDL]: populateAdditionalPaymentsForm(payload),
        },
      };
    case LeaseActions.POPULATE_SELECT_FORMS:
      const populatedForms = populateSelectForms(
        payload.forms,
        payload.selectedLease,
      );
      return {
        ...state,
        form: {
          ...state.form,
          ...populatedForms,
        },
      };
    case LeaseActions.OVERRIDE_SELECT_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          [payload.form]: payload.object,
        },
      };
    case LeaseActions.CLEAR_FORM:
      if (Array.isArray(payload.id)) {
        payload.id.map((i: string) => {
          return delete state.form![i];
        });
      } else {
        delete state.form!.payload.id;
      }
      return {
        ...state,
        form: {
          ...state.form,
        },
      };
    case LeaseActions.CLEAR_ALL_FORMS:
      return { ...state, ...initialAddLeaseFormState };
    case LeaseActions.SAVE_LEASE_DATA:
      return { ...state, leaseData: payload };
    case LeaseActions.SET_RELOAD_LEASES:
      return { ...state, reloadLeases: payload };
    case LeaseActions.SET_RELOAD_LEASE_DETAILS:
      return { ...state, reloadLeaseDetails: payload };
    default:
      return state;
  }
};

export interface ILeaseState {
  form?: {
    [id: string]: any;
  };
  leaseData?: any;
  reloadLeases?: boolean;
  reloadLeaseDetails?: boolean;
}
