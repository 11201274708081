import { Form, FormikContext } from 'formik';
import React, { FunctionComponent } from 'react';

import { ReactComponent as CheckSolid } from '../../../assets/check-solid.svg';
import invalidCred from '../../../assets/invalidCred.svg';
import { Input } from '../../../common';
import FormGrid from '../../../common/form-grid/FormGrid';
import FormItem from '../../../common/form-grid/FormItem';
import { AccentedHeader } from '../../../common/headers';
import { useResourceContext } from '../../../common/resource-context';
import { fieldHasError } from '../../../common/utilities/FormikHelpers';
import { AuthCard, AuthCardSection } from '../../auth/AuthCard';

export interface ICredentialsFormState {
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirmation?: string;
}

interface ICredentialsFormProps {
  formProps: FormikContext<ICredentialsFormState>;
  errorMessage?: string;
  // fromLinkManager?: boolean;
}

const CredentialsForm: FunctionComponent<ICredentialsFormProps> = ({
  formProps,
  errorMessage,
  // fromLinkManager,
}) => {
  const resources = useResourceContext();
  // RegExp to test if specific password criteria is met
  const regexLength = new RegExp('^.{8,20}$');
  const regexSpecial = new RegExp('[!@#$%^&*(),.?":{}|<>]');
  const regexUpperCase = new RegExp("'*[A-Z].*");
  const regexLowerCase = new RegExp("'*[a-z].*");
  const regexNumber = new RegExp('[0-9].*');

  return (
    <Form noValidate={true}>
      <AuthCard>
        <AuthCardSection>
          <AccentedHeader color="blue" centered>
            Perfect!
          </AccentedHeader>
          <p>Let's create your log in credentials</p>
          {errorMessage ? <p className="error">{errorMessage}</p> : null}
          <br />
          <>
            <FormGrid colWidth={2}>
              <Input
                label="First Name"
                name="firstName"
                value={formProps.values.firstName}
                error={fieldHasError(formProps, 'firstName')}
                errorMessage={formProps.errors.firstName}
                onChange={formProps.handleChange}
                required={false}
              />
              <Input
                label="Last Name"
                name="lastName"
                value={formProps.values.lastName}
                error={fieldHasError(formProps, 'lastName')}
                errorMessage={formProps.errors.lastName}
                onChange={formProps.handleChange}
              />
              <FormItem colSpan={1}>
                <Input
                  label="Password"
                  name="password"
                  type="password"
                  value={formProps.values.password}
                  error={fieldHasError(formProps, 'password')}
                  errorMessage={formProps.errors.password}
                  onChange={formProps.handleChange}
                />
                <br />
                <Input
                  label="Verify Password"
                  name="passwordConfirmation"
                  type="password"
                  error={fieldHasError(formProps, 'passwordConfirmation')}
                  value={formProps.values.passwordConfirmation}
                  errorMessage={formProps.errors.passwordConfirmation}
                  onChange={formProps.handleChange}
                />
              </FormItem>
              <div className="passwordReq">
                <h4>Password requirements:</h4>
                <div className="cred-section">
                  {regexLength.test(formProps.values.password) ? (
                    <CheckSolid className="pwrd-chck show" />
                  ) : (
                    <img src={invalidCred} alt="invalidCred" />
                  )}
                  <p>Between 8 and 20 characters</p>
                </div>
                <div className="cred-section">
                  {regexSpecial.test(formProps.values.password) ? (
                    <CheckSolid className="pwrd-chck show" />
                  ) : (
                    <img src={invalidCred} alt="invalidCred" />
                  )}
                  <p>One special character (such as ! @ # $ %)</p>
                </div>
                <div className="cred-section">
                  {regexUpperCase.test(formProps.values.password) ? (
                    <CheckSolid className="pwrd-chck show" />
                  ) : (
                    <img src={invalidCred} alt="invalidCred" />
                  )}
                  <p>One uppercase letter</p>
                </div>
                <div className="cred-section">
                  {regexLowerCase.test(formProps.values.password) ? (
                    <CheckSolid className="pwrd-chck show" />
                  ) : (
                    <img src={invalidCred} alt="invalidCred" />
                  )}
                  <p>One lowercase letter</p>
                </div>
                <div className="cred-section">
                  {regexNumber.test(formProps.values.password) ? (
                    <CheckSolid className="pwrd-chck show" />
                  ) : (
                    <img src={invalidCred} alt="invalidCred" />
                  )}
                  <p>One number</p>
                </div>
              </div>
            </FormGrid>
          </>
        </AuthCardSection>
        <div className="img-container">
          <img
            src={resources.img('CredentialsForm_Image')}
            alt="credentials"
            className="credentials"
          />
        </div>
      </AuthCard>
    </Form>
  );
};

export default CredentialsForm;
