import React from 'react';
import './Footer.scss';

const Footer = () => {
  return (
    <div
      className={`Footer ${process.env.REACT_APP_ENABLE_RSM === 'true' &&
        'rsm-class-footer'}`}
    >
      <div className="footer-content">
        <p className="footer-content-text-link">
          &copy; {new Date().getFullYear()} LEASEQUERY, LLC. ALL RIGHTS
          RESERVED.
        </p>
      </div>
    </div>
  );
};

export default Footer;
