import { ICompanyModel, IUserModel } from '../interfaces';
import { api, formatError } from './api';

export async function getCurrentUser() {
  let response;

  const rawResponse = await api.get('/CurrentUser');

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  response = rawResponse.data;

  return response;
}

export async function getClientUserToken(clientId: number) {
  const rawResponse = await api.get(`/ClientUserToken?clientId=${clientId}`);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function getCompanyUsers() {
  let response;

  const rawResponse = await api.get('/CompanyUsers');

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  response = rawResponse.data;

  return response;
}

export async function addCompanyUsers(email: string) {
  const rawResponse = await api.post('/AddCompanyUsers', JSON.stringify(email));

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function addGuestUsers(email: string) {
  const rawResponse = await api.post('/AddGuestUsers', JSON.stringify(email));

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function undoDeleteClientUser(userId: number, isGuest: boolean) {
  const rawResponse = await api.post(
    `/UndoDeleteClientUser?isGuest=${isGuest}`,
    userId,
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function resendUserAccess(user: IUserModel) {
  const rawResponse = await api.post('/ResendUserAccess', { ...user });

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function validateCompanyUserEmail(email: string) {
  let response;

  const rawResponse = await api.post(
    '/ValidateUserEmail',
    JSON.stringify(email),
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  response = rawResponse.status;

  return response;
}

export async function removeUser(userId: number) {
  let response;

  const rawResponse = await api.delete(`/RemoveUser/${userId}`);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  response = rawResponse.data;

  return response;
}

export async function getCompanyUserLinkManagerInfo(linkManagerID: string) {
  let response;

  const rawResponse = await api.get(`/GetUserfromGuid/${linkManagerID}`);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  response = rawResponse.data;

  return response;
}

export async function editUser(user: IUserModel, skipDomainCheck: boolean) {
  let response;

  const rawResponse = await api.put(`/EditUser/${skipDomainCheck}`, user);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  response = rawResponse.data;

  return response;
}

export async function editProfile(user: IUserModel, skipDomainCheck: boolean) {
  let response;

  const rawResponse = await api.put(`/EditProfile/${skipDomainCheck}`, user);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  response = rawResponse.data;

  return response;
}

export async function editCompany(company: ICompanyModel) {
  let response;

  const rawResponse = await api.put('/EditCompany', company);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  response = rawResponse.data;

  return response;
}

export async function addCompanyToUser(
  company: ICompanyModel,
): Promise<IUserModel> {
  const rawResponse = await api.post('/AddClient', company);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  api.defaults.headers.common.Authorization = `Bearer ${rawResponse.data.authToken.toString()}`;

  return rawResponse.data;
}

export async function toggleGuestUsers(allowGuestUser: boolean) {
  const rawResponse = await api.put(
    '/ToggleGuestUsers',
    JSON.stringify(allowGuestUser),
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function getGeneralLedgerItems() {
  const rawResponse = await api.get('/GeneralLedgers');

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}
