import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore, StoreEnhancer } from 'redux';
import thunk from 'redux-thunk';

import { IModal } from '../common/modal/types';
import { IGlossaryItem } from '../features/help/components/GlossaryItem';
import { ILeaseState } from '../features/lease/reducers';
import { IQuickBooksSettingsMapping } from '../features/quickbooks/QuickBooksSettingsModal';
import {
  ILeaseHeaderModel,
  ILeaseModel,
  IPlanModel,
  IUserModel,
} from '../interfaces';
import rootReducer from './reduxConfig';

export const history = createBrowserHistory();

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof Function;
  }
}

const initialState = {};
const enhancers = [];
const middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers as StoreEnhancer,
);

export interface IStore {
  // productItems: IProduct[];
  user: IUserModel;
  modal: IModal;
  plans: IPlanModel[];
  steppedModal: IModal;
  lease: ILeaseState;
  leaseList: ILeaseHeaderModel[];
  leaseDetails: {
    selectedLease: ILeaseModel;
  };
  glossaryItems: IGlossaryItem[];
  quickBooksSettings: IQuickBooksSettingsMapping;
  multiClientPlans: IPlanModel[];
  currentAdminPlan: IPlanModel;
}

export default store;
