import React, { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import close from '../../assets/close.svg';
import darkClose from '../../assets/darkClose.svg';
import rsmDarkClose from '../../assets/rsm-darkClose.svg';
import { IStore } from '../../store';
import { Button } from '../buttons';
import { hideModal } from './actions';
import Portal from './Portal';
import { IModalSizes } from './types';

import TagManager from 'react-gtm-module';
import './Modal.scss';

export function noScroll(overflow: string) {
  document.body.style.overflow = overflow;
}

const Modal = forwardRef<
  HTMLDivElement | null,
  React.PropsWithChildren<IModalProps>
>(
  (
    {
      children,
      title,
      open,
      modalSize = 'medium',
      positiveButtonText,
      secondaryButtonText,
      negativeButtonText,
      neutralButtonText,
      onCancel,
      onConfirm,
      onSecondary,
      processing = false,
      contentFooter,
      contentClass,
      mainClass,
      showCloseInHeader,
      showCloseButton,
      darkCloseButton = false,
      customCloseButton,
      headerClass,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const steppedModalOpen = useSelector(
      (state: IStore) => Object.keys(state.steppedModal).length !== 0,
    );

    const history = useHistory();
    const isMultiClientLandingPage =
      // multicompany-landing page requires a darker modal-background
      history.location.pathname === '/multicompany-landing';

    // if stepped modal is not present, run this useEffect
    useEffect(() => {
      if (!steppedModalOpen) {
        if (open) {
          noScroll('hidden');
        }

        TagManager.dataLayer({
          dataLayer: {
            event: 'modal_screen_view',
            modal_name: title,
          },
        });

        return () => noScroll('initial');
      }
    }, [open, steppedModalOpen, title]);

    const handleModalHide = () => {
      dispatch(hideModal());
    };
    return (
      <Portal>
        {open ? (
          <div className="modal">
            <div
              className={`modal-background ${isMultiClientLandingPage &&
                'black-background'}`}
            />
            <div
              className={`modal-wrapper ${process.env.REACT_APP_ENABLE_RSM ===
                'true' && 'rsm-class-modal'}`}
            >
              <div
                className={`modal-card ${`modal-card--${modalSize}`} ${
                  contentFooter ? 'modal-card--add-footer' : null
                }`}
                ref={ref}
              >
                {title ? (
                  <div className="modal-header">
                    <header
                      className={`modal-title ${headerClass} ${
                        negativeButtonText && !!!positiveButtonText
                          ? 'negative'
                          : ''
                      }`}
                    >
                      <div className="modal-header-title">{title}</div>
                    </header>
                  </div>
                ) : null}
                <main
                  className={`${mainClass && mainClass} ${
                    !contentFooter ? 'modal-main' : null
                  }`}
                >
                  {customCloseButton ? (
                    <div
                      className="modal-close-button"
                      onClick={handleModalHide}
                    >
                      {customCloseButton}
                    </div>
                  ) : showCloseButton ? (
                    <button
                      className="modal-close-button"
                      onClick={onCancel || handleModalHide}
                    >
                      <img
                        src={
                          darkCloseButton &&
                          process.env.REACT_APP_ENABLE_RSM === 'true'
                            ? rsmDarkClose
                            : darkCloseButton
                            ? darkClose
                            : close
                        }
                        alt="question mark"
                        className="tooltip-image"
                      />
                    </button>
                  ) : null}
                  <div
                    className={contentClass ? contentClass : 'modal-content'}
                  >
                    {children}
                  </div>
                </main>
                {contentFooter ? (
                  <div className="modal-content-footer">{contentFooter}</div>
                ) : null}
                {positiveButtonText ||
                neutralButtonText ||
                negativeButtonText ? (
                  <footer className="modal-footer">
                    {neutralButtonText ? (
                      <Button
                        buttonType="neutral"
                        buttonText={neutralButtonText}
                        onClick={onCancel || handleModalHide}
                      />
                    ) : null}
                    {negativeButtonText ? (
                      <Button
                        buttonType="negative"
                        buttonText={negativeButtonText}
                        onClick={onConfirm}
                        processing={processing}
                        disabled={processing}
                      />
                    ) : null}
                    {secondaryButtonText ? (
                      <Button
                        buttonType="positive-dark"
                        buttonText={secondaryButtonText}
                        onClick={onSecondary}
                        processing={processing}
                        disabled={processing}
                      />
                    ) : null}
                    {positiveButtonText ? (
                      <Button
                        buttonType="positive"
                        buttonText={positiveButtonText}
                        onClick={onConfirm}
                        processing={processing}
                        disabled={processing}
                      />
                    ) : null}
                  </footer>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </Portal>
    );
  },
);

interface IModalProps {
  open: boolean;
  onConfirm?: () => void;
  onSecondary?: () => void;
  hideModal?: () => void;
  title?: string;
  onCancel?: () => void;
  modalSize?: IModalSizes;
  positiveButtonText?: string;
  secondaryButtonText?: string;
  negativeButtonText?: string;
  neutralButtonText?: string;
  processing?: boolean;
  contentFooter?: React.ReactNode;
  contentClass?: string;
  mainClass?: string;
  showCloseInHeader?: boolean;
  showCloseButton?: boolean;
  darkCloseButton?: boolean;
  customCloseButton?: JSX.Element;
  blackOverlay?: boolean;
  headerClass?: string;
}

export default Modal;
