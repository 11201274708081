import React, { FunctionComponent } from 'react';

interface IConditionalWrapper {
  condition: boolean;
  wrapper: (children: React.ReactNode) => any;
}

const ConditionalWrapper: FunctionComponent<IConditionalWrapper> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper(children) : children);

export default ConditionalWrapper;
