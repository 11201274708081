import { AnyAction } from 'redux';
import { ILeaseModel } from '../../interfaces';
import { LeaseDetailsActions } from './actions';

const initialLeaseDetailsState: ILeaseDetailsState = {
  selectedLease: null,
};

export const leaseDetailsReducer = (
  state = initialLeaseDetailsState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case LeaseDetailsActions.SET_SELECTED_LEASE:
      return {
        ...state,
        selectedLease: payload,
      };
    case LeaseDetailsActions.UPDATE_SELECTED_LEASE_HEADER:
      return {
        ...state,
        selectedLease: {
          ...state.selectedLease,
          header: payload,
        },
      };
    case LeaseDetailsActions.CLEAR_SELECTED_LEASE:
      return {
        selectedLease: null,
      };
    default:
      return state;
  }
};

export interface ILeaseDetailsState {
  selectedLease: ILeaseModel | null;
}
