import React, { FunctionComponent } from 'react';

import './FormGrid.scss';

interface IFormItemProps {
  colSpan: ColumnSpan;
}

type ColumnSpan = 1 | 2;

const FormItem: FunctionComponent<IFormItemProps> = ({ colSpan, children }) => {
  return <div className={`form-grid-item col-span-${colSpan}`}>{children}</div>;
};

export default FormItem;
