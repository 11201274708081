import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import grid from '../../assets/Grid.svg';
import hamburger from '../../assets/hamburger.svg';
import Arrow from '../../assets/left-arrow.svg';
import lock from '../../assets/whitelock.svg';
import { Button } from '../../common/buttons';
import {
  hideModal,
  hideSteppedModal,
  showModal,
  showSteppedModal,
} from '../../common/modal/actions';
import { useResourceContext } from '../../common/resource-context';
import { CheckIsMultiClientAdmin } from '../../common/utilities/_authHelpers';
import { checkIfUserIsGuest } from '../../common/utilities/_guestUserHelper';
import { useFeatureFlags } from '../../featureQuery';
import { DashboardType, FeatureFlagKeys, PlanTier } from '../../interfaces';
import { IStore } from '../../store';
import { checkIfUserIsOnMaxPlan, getFeatureValue } from '../plans/helpers';
import './DashboardFilterHeader.scss';
import {
  getLeaseCountDescriptionMessage,
  getNextPlan,
  registerClickOutsideHandler,
  upgradeHandler,
} from './helpers/helpers';

interface IDashboardFilterHeaderProps {
  leaseCount: number;
  title: string;
  isQuickFilterEnabled: boolean;
  setDashboardView: (view: DashboardType) => void;
  currentView: DashboardType;
  disableQuickFilter: (set: boolean, filter: string) => void;
}

const DashboardFilterHeader: FunctionComponent<IDashboardFilterHeaderProps> = ({
  leaseCount,
  title,
  isQuickFilterEnabled,
  setDashboardView,
  currentView,
  disableQuickFilter,
}) => {
  const dispatch = useDispatch();

  const hideSteppedModalAction = () => {
    dispatch(hideSteppedModal());
  };

  const toggleGridView = () => {
    setDashboardView(DashboardType.GRID);
  };

  const toggleListView = () => {
    setDashboardView(DashboardType.LIST);
  };

  const showSubscriptionModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'SUBSCRIPTIONS',
          modalProps: {
            open: true,
            hideModal: () => {
              dispatch(hideModal());
            },
          },
        },
      }),
    );
  };

  const showUpgradeModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'UPGRADE_TIER',
          modalProps: {
            open: true,
            hideModal: () => {
              dispatch(hideModal());
            },
          },
        },
      }),
    );
  };

  const [leaseColor, setLeaseColor] = useState<string>();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const user = useSelector((state: IStore) => {
    return state.user;
  });

  const isGuestUser = checkIfUserIsGuest(user);

  const isCPAAdmin = CheckIsMultiClientAdmin(user);

  const allPlans = useSelector((state: IStore) => {
    return isCPAAdmin ? state.multiClientPlans : state.plans;
  });

  const currentAdminPlan = useSelector((state: IStore) => {
    return state.currentAdminPlan;
  });

  const pricingRoute = isCPAAdmin ? '/multicompany-pricing' : '/pricing';

  const features = user.company.plan?.features;

  const maxLeaseCount: number = features
    ? Number(getFeatureValue('Lease Count', features))
    : 0;

  const calculatedLeaseCount =
    maxLeaseCount - leaseCount > 0 ? maxLeaseCount - leaseCount : 0;

  const addLeaseModal = showSteppedModal({
    modal: {
      modalType: 'STEPPED_MODAL',
      modalProps: {
        open: true,
        hideModal: hideSteppedModalAction,
        title: 'Add New Lease',
      },
    },
  });

  const handleAddLease = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'cta_link_click',
        cta_location: 'Top-Right of Leases Screen',
        link_text: 'Add Lease',
      },
    });

    if (leaseCount < maxLeaseCount) {
      dispatch(addLeaseModal);
    } else {
      showUpgradeModal();
    }
    setIsVisible(false);
  };

  const userIsOnMaxPlan = isCPAAdmin
    ? currentAdminPlan.tier !== PlanTier.BASIC
    : checkIfUserIsOnMaxPlan(user, allPlans);

  const displayUpgradeOptions = () => {
    if (userIsOnMaxPlan) {
      showUpgradeModal();
    } else {
      showSubscriptionModal();
    }
  };

  useEffect(() => {
    if (leaseCount >= maxLeaseCount) {
      setLeaseColor('red');
    } else if (leaseCount < maxLeaseCount && leaseCount >= maxLeaseCount - 3) {
      setLeaseColor('green');
    } else {
      setLeaseColor('blue');
    }
  }, [leaseCount, maxLeaseCount]);

  const renderLeasePopUp = () => {
    return (
      <>
        <div className="carrot-left" />
        <div className="leaseCount-heading">
          <div className={`leaseCount ${leaseColor}`}>
            {calculatedLeaseCount}
          </div>
          <div className="leaseRemaining">
            Lease{calculatedLeaseCount === 1 ? '' : 's'} remaining
          </div>
        </div>
        {leaseCount >= maxLeaseCount ? (
          <div className="link" onClick={showSubscriptionModal}>
            Click here to learn about your upgrade options.
          </div>
        ) : (
          <div className="link" onClick={displayUpgradeOptions}>
            Need to add more leases? Click here to increase lease capacity.
          </div>
        )}
      </>
    );
  };

  const { getSingleFlag } = useFeatureFlags();

  const guruPlanPricingFlag = getSingleFlag(FeatureFlagKeys.PlansAndPricing);

  const currentPlan = isCPAAdmin ? currentAdminPlan : user.company.plan;

  const nextUpgradePlan = getNextPlan(currentPlan, allPlans, isCPAAdmin);

  const resources = useResourceContext();

  const enterpriseUrl = resources.text('EnterprisePricingUrl');

  const hidePopup = useMemo(
    () => (): void => {
      setIsVisible(false);
    },
    [],
  );
  const leaseCountRef = useRef(null);
  useEffect(() => {
    registerClickOutsideHandler(hidePopup, leaseCountRef);
  }, [hidePopup]);

  const renderNewLeasePopUp = () => {
    return (
      <>
        <div className="carrot-left" />
        <div className="leaseCount-heading">
          <div className={`leaseCount ${leaseColor}`}>
            {calculatedLeaseCount}
          </div>
          <div className="leaseRemaining">
            Lease{calculatedLeaseCount === 1 ? '' : 's'} remaining
          </div>
        </div>
        <div className="leaseCount-content">
          <div className="description">
            {getLeaseCountDescriptionMessage(
              user,
              userIsOnMaxPlan,
              nextUpgradePlan,
            )}
          </div>
          <div className="actions">
            <a className="view-all" href={pricingRoute}>
              View All Plans
            </a>
            <div>
              <button
                onClick={() =>
                  upgradeHandler(
                    userIsOnMaxPlan,
                    enterpriseUrl,
                    dispatch,
                    nextUpgradePlan,
                    allPlans,
                  )
                }
              >
                {userIsOnMaxPlan
                  ? 'Request demo'
                  : isCPAAdmin
                  ? 'Buy Now'
                  : 'UPGRADE'}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className={`header-filter ${process.env.REACT_APP_ENABLE_RSM ===
          'true' && 'rsm-class-dashboard-filter-header'}`}
      >
        <div>
          {isQuickFilterEnabled && (
            <>
              <img alt="arrow" src={Arrow} />
              <span
                onClick={() => {
                  disableQuickFilter(false, '');
                }}
              >
                Back
              </span>
            </>
          )}
        </div>

        <div>
          <div className="all-lease">
            {title}
            <div
              className={`count ${isVisible ? 'active' : ''} ${
                isGuestUser ? 'disable-hover' : ''
              }`}
              onClick={() =>
                !isGuestUser && setIsVisible((current) => !current)
              }
              ref={leaseCountRef}
            >
              <div
                className={`leaseCountPopUp ${isVisible &&
                  'display'} ${guruPlanPricingFlag && ' newPopup'}`}
              >
                {!isGuestUser &&
                  (guruPlanPricingFlag
                    ? renderNewLeasePopUp()
                    : renderLeasePopUp())}
              </div>
              <div
                onClick={() => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'lease_count_box_click',
                    },
                  });
                }}
                className={`count-box ${leaseColor}`}
              >
                {leaseCount}
              </div>
            </div>
          </div>
          <div className="header-filter-actions">
            <div
              className={`header-filter-actions-view-order ${
                !isGuestUser ? 'm-r-5' : ''
              }`}
            >
              <div
                className={`header-filter-actions-view-order-button list ${currentView ===
                  1 && 'active'}`}
                onClick={toggleListView}
              >
                <img className="hamburger" src={hamburger} alt="hamburger" />
                <span className="list-label">List</span>
              </div>
              <div
                className={`header-filter-actions-view-order-button grid ${currentView ===
                  0 && 'active'}`}
                onClick={toggleGridView}
              >
                <img className="hamburger" src={grid} alt="hamburger" />
                <span className="grid-label">Grid</span>
              </div>
            </div>
            {!isGuestUser && (
              <Button
                buttonType="positive-green"
                buttonText="Add Lease"
                onClick={handleAddLease}
              >
                {leaseCount >= maxLeaseCount && (
                  <img
                    src={lock}
                    alt="lock"
                    className="inner-btn-header-lock"
                  />
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardFilterHeader;
