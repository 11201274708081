import React, { FunctionComponent } from 'react';

import './Toggle.scss';

const Toggle: FunctionComponent<IToggleProps> = ({
  label,
  name,
  id,
  checked,
  onText,
  offText,
  onChange,
  showInPanel,
  disabled,
}) => {
  return (
    <div
      className={`toggle-wrapper ${
        showInPanel ? `toggle-wrapper--panel` : ''
      } ${disabled ? 'disabled' : ''}`}
    >
      <span className="toggle-question">{label}</span>
      <label className="toggle-label-wrap">
        <div className={`toggle ${checked ? 'toggle--checked' : ''}`}>
          <div
            id={id}
            className={`toggle-track ${disabled ? 'disabled' : ''}`}
          />
          <div className={`toggle-thumb ${disabled ? 'disabled' : ''}`} />
          <input
            disabled={disabled}
            name={name}
            type="checkbox"
            checked={checked}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChange(event.target.checked)
            }
            className="toggle-screenreader-only"
            id={id}
          />
        </div>
        <span
          className={`toggle-label-text ${
            checked ? 'toggle-label-text--checked' : ''
          } ${disabled ? 'disabled' : ''}`}
        >
          {checked ? onText || 'Yes' : offText || 'No'}
        </span>
      </label>
    </div>
  );
};

interface IToggleProps {
  name: string;
  checked?: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  onText?: string;
  offText?: string;
  id?: string;
  showInPanel?: boolean;
  disabled?: boolean;
}

export default Toggle;
