import { format, isAfter } from 'date-fns';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatRemoveCommas } from '../../../common/formatters/FormatInputCommas';
import { Datepicker, Input, Toggle } from '../../../common/index';
import { changeModalProp } from '../../../common/modal/actions';
import Tooltip, { TooltipPositions } from '../../../common/tooltips/Tooltip';
import { fieldHasError } from '../../../common/utilities/FormikHelpers';
import { IStore } from '../../../store';
import { IGlossaryItem } from '../../help/components/GlossaryItem';
import {
  clearForm,
  LeaseForms,
  overrideSelectForm,
  saveForm,
} from '../actions';
import { IOptionsFormState } from './OptionsForm';
import { DateFormSchema } from './validationSchemas';

import TagManager from 'react-gtm-module';
import { DEFAULT_DATE_FORMAT } from '../../../common/utilities/_dates';
import { getGTMDataLayerEventByAmendType } from '../../../common/utilities/_helpers';
import './add-lease-form-styles.scss';
import './DateForm.scss';

const DateForm = forwardRef((props, ref) => {
  const storedData = useSelector((state: IStore) => {
    return state.lease.form![LeaseForms.DATE];
  });
  const optionsFormValues = useSelector((state: IStore) => {
    return state.lease.form![LeaseForms.OPTIONS];
  });
  const generalFormValues = useSelector((state: IStore) => {
    return state.lease.form![LeaseForms.GENERAL];
  });
  const amendType = useSelector((state: IStore) => {
    return state.steppedModal!.modalProps!.amendType;
  });
  const effectiveDate: string | undefined = useSelector((state: IStore) => {
    return (
      state.steppedModal.modalProps &&
      state.steppedModal.modalProps.otherProps &&
      state.steppedModal.modalProps.otherProps.effectiveDate
    );
  });
  const leaseModificationDate = storedData?.leaseModificationDate;
  // if we have an effective date, that becomes the possession date for modify
  // of if previously modified,  that date becomes the possession/effective date
  // load up the lease data with effective in place of possession
  const modifiedStoredData =
    amendType === 'MODIFY' && effectiveDate
      ? { ...storedData, leaseBeginDt: new Date(effectiveDate) }
      : amendType === 'OVERRIDE' && leaseModificationDate
      ? { ...storedData, leaseBeginDt: new Date(leaseModificationDate) }
      : storedData;

  const initialData = modifiedStoredData ||
    storedData || {
      leaseBeginDt: undefined,
      leaseEndDt: undefined,
      usefulLifeMonths: undefined,
    };

  const glossaryItems = useSelector((state: IStore) => state.glossaryItems);

  const handleGetGlossaryItem = (term: string) =>
    glossaryItems.find((item: IGlossaryItem) => item.term === term);

  const dispatch = useDispatch();
  const formikRef = useRef<Formik<IDateFormState>>(null);
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState<IDateFormState>(initialData);
  const [formsCleared, setFormsCleared] = useState<boolean>(false);
  // this allows the user to navigate away and return while maintaining their value
  // if this was just defaulted to false, we would have to run handleRenewalCheck again on return visits
  const [showRenewalToggle, setShowRenewalToggle] = useState<boolean>(
    initialData.isRenewal !== undefined,
  );

  useImperativeHandle(ref, () => ({
    saveForm() {
      return handleSaveForm(formikRef.current!.state.values);
    },
    validate() {
      return formikRef.current!.submitForm();
    },
    isValid() {
      return isValid;
    },
  }));

  const onSubmit = (values: IDateFormState) => {
    setIsValid(true);
    setFormData(values);
    // if renewal toggle is yes, and amend type isn't already renew
    if (values.isRenewal && amendType === 'MODIFY') {
      dispatch(changeModalProp('amendType', 'RENEW'));
      // if renewal toggle is no, and amend type is still set to renew
    } else if (!values.isRenewal && amendType === 'RENEW') {
      dispatch(changeModalProp('amendType', 'MODIFY'));
    }

    TagManager.dataLayer({
      dataLayer: {
        event: getGTMDataLayerEventByAmendType(amendType),
        step_completion_step: '2',
        step_completion_name: 'Dates',
        lease_possession_date: format(
          new Date(values.leaseBeginDt as Date),
          DEFAULT_DATE_FORMAT,
        ),
        lease_end_date: format(
          new Date(values.leaseEndDt as Date),
          DEFAULT_DATE_FORMAT,
        ),
      },
    });

    dispatch(saveForm(LeaseForms.DATE, values));
  };

  const handleSaveForm = (values: IDateFormState) => {
    dispatch(saveForm(LeaseForms.DATE, values));
  };

  const handleRenewalCheck = (
    originalDate: Date,
    newDate: Date,
    formProps: FormikProps<IDateFormState>,
  ) => {
    // renewal checks are only valid for MODIFY and RENEW
    if (amendType === 'MODIFY' || amendType === 'RENEW') {
      // if the original date is after the newly entered date, set the default value of the toggle to false
      // else, set the value to undefined
      // reason: on form load, we check for initialData.isRenewal !== undefined to set the showRenewalToggle
      formProps.setFieldValue(
        'isRenewal',
        isAfter(newDate, originalDate) ? false : undefined,
      );

      // if isAfter is false, we need to change the title of the modal again
      // for when a user goes back and changes the date to before the original date again
      if (!isAfter(newDate, originalDate)) {
        dispatch(
          changeModalProp('title', `Amending lease as of ${effectiveDate}`),
        );
      }

      // only set showRenewalToggle if it is different
      if (showRenewalToggle !== isAfter(newDate, originalDate)) {
        setShowRenewalToggle(isAfter(newDate, originalDate));
      }
    }
  };

  function handleCriticalDateField(
    formProps: FormikProps<IDateFormState>,
    date: Date | null | undefined,
    fieldName: string | undefined,
  ) {
    // if date valid and fieldName present
    if (date && fieldName) {
      // if form is valid
      if (storedData) {
        // if dates are different
        if (storedData[fieldName] && storedData[fieldName] !== date) {
          // if they have already confirmed to clear forms
          if (!formsCleared) {
            const confirmed = window.confirm(
              `Changing this field will reset any data previously entered on Options, Base Rent, and Add'l Payments tabs. Are you sure?`,
            );

            // if user clicks yes, take date
            if (confirmed) {
              setFormsCleared(true);
              handleRenewalCheck(storedData[fieldName], date, formProps);
              formProps.setFieldValue(fieldName, date);
              dispatch(
                clearForm([
                  LeaseForms.OPTIONS,
                  LeaseForms.BASE,
                  LeaseForms.ADDL,
                ]),
              );
              // if amend type is modify,
              // populate the cap v op fields
              if (amendType === 'MODIFY' || amendType === 'RENEW') {
                const newOptionsFormValues: IOptionsFormState = {
                  canTransferTitle: optionsFormValues.canTransferTitle,
                  isSpecializedUse: optionsFormValues.isSpecializedUse,
                  isRenewalOption: false,
                  isMoreOptionsOpen: false,
                  isPurchaseOption:
                    optionsFormValues.purchaseOption.canBargainPurchase,
                  isTerminateOption: false,
                  renewalOptions: [],
                  purchaseOption: {
                    canBargainPurchase:
                      optionsFormValues.purchaseOption &&
                      optionsFormValues.purchaseOption.canBargainPurchase,
                    reasonableToPurchase: false,
                  },
                  terminateOption: { hasFee: false },
                };
                dispatch(
                  overrideSelectForm(LeaseForms.OPTIONS, newOptionsFormValues),
                );
              }

              // if user clicks no, reset date
            } else {
              formProps.setFieldValue(fieldName, storedData[fieldName]);
            }
          } else {
            handleRenewalCheck(storedData[fieldName], date, formProps);
            formProps.setFieldValue(fieldName, date);
          }
        } else {
          formProps.setFieldValue(fieldName, date);
        }
      } else {
        formProps.setFieldValue(fieldName, date);
      }
    }
  }

  const renderToolTip = (
    glossaryItem: IGlossaryItem | undefined,
    label: string,
    htmlFor: string,
    tooltipPosition: TooltipPositions = 'bot-mid-left',
    isRequired: boolean = true,
  ) => {
    return (
      <Tooltip
        wrappedElement={
          <label
            htmlFor={htmlFor}
            className={`form-input-label ${process.env.REACT_APP_ENABLE_RSM ===
              'true' && 'rsm-class-label'} ${isRequired ? 'required' : ''}`}
          >
            {label}
          </label>
        }
        tooltipPosition={tooltipPosition}
        header={glossaryItem ? glossaryItem.term : ''}
      >
        {glossaryItem ? glossaryItem.termDefinition : ''}
      </Tooltip>
    );
  };

  return (
    <div className="form-container">
      <Formik
        ref={formikRef}
        initialValues={formData}
        validationSchema={DateFormSchema(
          generalFormValues.isCapital === 'help',
        )}
        onSubmit={onSubmit}
      >
        {(formProps) => {
          return (
            <Form noValidate={true}>
              <div className="date-form form-body">
                <div className="date-form-picker-row">
                  <div className="possession-date">
                    {leaseModificationDate ? (
                      <div className={`form-input-label required`}>
                        Effective Date
                      </div>
                    ) : (
                      renderToolTip(
                        handleGetGlossaryItem('Possession Date'),
                        'Possession Date',
                        'leaseBeginDt',
                      )
                    )}
                    <Datepicker
                      name="leaseBeginDt"
                      onChange={(
                        date: Date | null | undefined,
                        name: string | undefined,
                      ) => {
                        handleCriticalDateField(formProps, date, name);
                      }}
                      selectedDate={formProps.values.leaseBeginDt}
                      error={fieldHasError(formProps, 'leaseBeginDt')}
                      errorMessage={formProps.errors.leaseBeginDt}
                      popperPlacement={'auto'}
                      preventOverflow={true}
                      required
                      disabled={
                        amendType === 'MODIFY' ||
                        amendType === 'RENEW' ||
                        leaseModificationDate
                      }
                    />
                  </div>
                  <div className="lease-end-date">
                    {renderToolTip(
                      handleGetGlossaryItem('Lease End Date'),
                      formProps.values.isRenewal
                        ? 'Renewal End Date'
                        : 'Lease End Date',
                      'leaseEndDt',
                    )}
                    <Datepicker
                      name="leaseEndDt"
                      onChange={(
                        date: Date | null | undefined,
                        name: string | undefined,
                      ) => {
                        handleCriticalDateField(formProps, date, name);
                      }}
                      error={fieldHasError(formProps, 'leaseEndDt')}
                      errorMessage={formProps.errors.leaseEndDt}
                      selectedDate={formProps.values.leaseEndDt}
                      popperPlacement={'auto'}
                      preventOverflow={true}
                      required
                    />
                  </div>
                  <div className="useful-life-in-months">
                    {renderToolTip(
                      handleGetGlossaryItem('Useful Life'),
                      'Useful Life In Months',
                      'usefulLifeMonths',
                      'bot-mid-right',
                      generalFormValues.isCapital === 'help',
                    )}
                    <Field
                      render={() => (
                        <Input
                          required={generalFormValues.isCapital === 'help'}
                          name="usefulLifeMonths"
                          type="number"
                          withComma={true}
                          value={formProps.values.usefulLifeMonths}
                          onChange={(e: any) =>
                            formatRemoveCommas(e, formProps)
                          }
                          error={fieldHasError(formProps, 'usefulLifeMonths')}
                          errorMessage={formProps.errors.usefulLifeMonths}
                        />
                      )}
                    />
                  </div>
                  {showRenewalToggle ? (
                    <div className="is-renewal-toggle">
                      <Toggle
                        name="isRenewal"
                        label="Are you renewing your lease?"
                        checked={formProps.values.isRenewal}
                        onChange={() => {
                          formProps.setFieldValue(
                            'isRenewal',
                            !formProps.values.isRenewal,
                          );
                          !formProps.values.isRenewal
                            ? dispatch(
                                changeModalProp(
                                  'title',
                                  `Renewing lease as of ${effectiveDate}`,
                                ),
                              )
                            : dispatch(
                                changeModalProp(
                                  'title',
                                  `Amending lease as of ${effectiveDate}`,
                                ),
                              );
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
});

export interface IDateFormState {
  leaseBeginDt?: Date;
  leaseEndDt?: Date;
  usefulLifeMonths?: number;
  isRenewal?: boolean;
  leaseModificationDate?: string | null;
}

export default DateForm;
