import React, { FunctionComponent } from 'react';
import RSMAddIcon from '../../../assets/add-circle-icon-rsm.svg';
import AddIcon from '../../../assets/add-circle-icon.svg';
import { isEnvRSM } from '../../../common/utilities/_helpers';

import './AddCompanyCard.scss';

export interface AddCompanyCardProps {
  onAddClientClick: () => void;
}

const AddCompanyCard: FunctionComponent<AddCompanyCardProps> = ({
  onAddClientClick,
}) => {
  const isRSM = isEnvRSM();

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      onAddClientClick();
    }
  };

  return (
    <div
      className="add-company-card-wrapper"
      tabIndex={0}
      onClick={onAddClientClick}
      onKeyDown={(e) => handleKeyDown(e)}
    >
      <div className="add-company-icon">
        <img src={isRSM ? RSMAddIcon : AddIcon} alt="add a company" />
      </div>
      <div className="add-company-text">Add Company</div>
    </div>
  );
};

export default AddCompanyCard;
