import { getUserClientList } from '../../api';
import { ICompanySelectorRoleOption, IUserModel } from '../../interfaces';

enum UserRole {
  LQAdmin = 1,
  ContributingUser = 3,
  GuestUser = 6,
  MultiClientAdmin = 11,
}

const doesUserHaveAccessToMultipleClients = async (
  userId: number = 0,
): Promise<{
  clientAccessList: ICompanySelectorRoleOption[];
  hasMultipleClientAccess: boolean;
}> => {
  const clientListResponse = await getUserClientList(userId);
  return {
    clientAccessList: clientListResponse,
    hasMultipleClientAccess: clientListResponse.length > 1,
  };
};

const setBearerToken = (authToken: string = ''): void => {
  sessionStorage.setItem('userToken', `Bearer ${authToken}`);
};

const setAdminSelection = (clientId: number): void => {
  sessionStorage.setItem('admin-company-selection', `${clientId}`);
};

const isAdminOrGuest = (userRole: number | undefined): boolean => {
  return userRole === UserRole.LQAdmin || userRole === UserRole.GuestUser;
};

const isAdminGuestOrContributing = (userRole: number | undefined): boolean => {
  return (
    userRole === UserRole.LQAdmin ||
    userRole === UserRole.GuestUser ||
    userRole === UserRole.ContributingUser ||
    userRole === UserRole.MultiClientAdmin
  );
};

const CheckIsMultiClientAdmin = (user: IUserModel): boolean => {
  return user.userRole === UserRole.MultiClientAdmin;
};

export {
  doesUserHaveAccessToMultipleClients,
  setBearerToken,
  setAdminSelection,
  isAdminOrGuest,
  UserRole,
  isAdminGuestOrContributing,
  CheckIsMultiClientAdmin,
};
