import { IPlanModel } from '../../../../interfaces';
import { IPlanFeatureTableItem } from '../../helpers/helpers';

export const buildPlanTableCPA = (
  entepriseFeatures: IPlanFeatureTableItem[][],
): IPlanFeatureTableItem[][] => {
  const featureList = entepriseFeatures;
  featureList[0][0].name = 'CPA Admin Users';
  featureList[0][1].name = 'Guest Users per Company';

  featureList.unshift([
    {
      id: 0,
      name: 'Leases per Company',
      isBasic: true,
      isPremium1: true,
      isPremium2: true,
      isPremium3: true,
      isPremium4: true,
      isPremium5: true,
    },
  ]);
  return featureList;
};

export const getMultiClientFeaturesValue = (
  multiClientPlans: IPlanModel[],
  tier: string,
  featureName: string,
  planModels?: IPlanModel[],
): string => {
  if (tier === 'Enterprise') {
    return 'Unlimited';
  }
  const planName = tier === 'Basic' ? 'CPA Plan Free 1' : 'CPA Plan 4';
  const plan = multiClientPlans.find((p) => p.name === planName);
  if (plan !== undefined) {
    const feature = plan.features.find((f) => f.name === featureName);
    return feature !== undefined ? feature.attributes[0].values[0] : '';
  }

  return '';
};

export const getMultiClientLeasesCount = (tier: string): string => {
  if (tier === 'Enterprise') {
    return 'Unlimited';
  } else if (tier === 'Basic') {
    return '2';
  } else {
    return '10';
  }
};
