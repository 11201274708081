import React from 'react';
import { AccentedHeader } from '../../common/headers';
import { hideModal } from '../../common/modal/actions';
import Modal from '../../common/modal/Modal';

import './UpdatePaymentConfirmationModal.scss';

const UpdatePaymentConfirmationModal = () => {
  return (
    <Modal
      open
      modalSize="small"
      hideModal={hideModal}
      neutralButtonText="Close"
    >
      <AccentedHeader color="blue" centered>
        Sweet!
      </AccentedHeader>
      <div className="confirmation-text">
        Your payment details have successfully been updated.
      </div>
    </Modal>
  );
};

export default UpdatePaymentConfirmationModal;
