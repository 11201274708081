import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';
export const WORDY_DATE_FORMAT = 'MMMM do yyyy';
export const API_DATE_FORMAT = 'yyyy-MM-dd';

export function getPercentCompleteOfDates(
  startDateString: string,
  endDateString: string,
): number {
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);
  const todayDate = new Date();

  const daysSinceStart: number = differenceInCalendarDays(todayDate, startDate);

  // if date is in the future, number will be negative
  if (daysSinceStart < 0) {
    return -1;
  }

  const totalDays: number = differenceInCalendarDays(endDate, startDate);

  const percentComplete: number = (daysSinceStart / totalDays) * 100;

  if (percentComplete >= 0) {
    return percentComplete;
  } else {
    return -1;
  }
}

export const getTimeRemaining = (endDateString: string): number =>
  differenceInCalendarDays(new Date(endDateString), new Date());

export const yearFromNow = (): number => {
  const todaysDate = new Date();
  return todaysDate.setFullYear(todaysDate.getFullYear() + 1);
};
