import React, { FunctionComponent } from 'react';
import {
  FeatureFlagKeys,
  ILeaseHeaderModel,
  IPlanFeatureModel,
} from '../../interfaces';
import LeaseListItem from './LeaseListItem';

import { useSelector } from 'react-redux';
import { useFeatureFlags } from '../../featureQuery';
import { IStore } from '../../store';
import { getFeatureValue } from '../plans/helpers';
import './DashboardLeaseList.scss';
import MaxLeaseListItem from './MaxLeaseListItem';

interface IDashboardLeaseListProps {
  leaseList: ILeaseHeaderModel[];
  addToRecentList: (lease: ILeaseHeaderModel) => void;
}

const DashboardLeaseList: FunctionComponent<IDashboardLeaseListProps> = ({
  leaseList,
  addToRecentList,
}) => {
  const { getSingleFlag } = useFeatureFlags();
  const guruPlanPricingFlag = getSingleFlag(FeatureFlagKeys.PlansAndPricing);

  const user = useSelector((state: IStore) => {
    return state.user;
  });
  const maxLeases = Number(
    getFeatureValue(
      'Lease Count',
      user.company.plan?.features as IPlanFeatureModel[],
    ),
  );

  return (
    <div>
      <div className="lease-list-items">
        {leaseList &&
          leaseList.map((lease) => (
            <LeaseListItem
              key={lease.leaseId}
              lease={lease}
              addToRecentList={addToRecentList}
            />
          ))}
        {guruPlanPricingFlag && leaseList && leaseList.length >= maxLeases ? (
          <MaxLeaseListItem
            leaseCount={leaseList.length}
            maxLeases={maxLeases}
          />
        ) : null}
      </div>
    </div>
  );
};

export default DashboardLeaseList;
