import { AnyAction } from 'redux';

import { QuickBooksActions } from './actions';

export const quickBooksReducer = (state = {}, { type, payload }: AnyAction) => {
  switch (type) {
    case QuickBooksActions.CLEAR_QUICKBOOKS:
      return {};
    case QuickBooksActions.STORE_QB_MAPPINGS:
      return payload.mapping;
    default:
      return state;
  }
};
