import { AnyAction } from 'redux';

import { GlossaryActions } from './actions';
import { IGlossaryItem } from './components/GlossaryItem';

const initialGlossaryState: IGlossaryItem[] = [];

export const glossaryReducer = (
  state = initialGlossaryState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case GlossaryActions.STORE_GLOSSARY_ITEMS:
      return payload.glossaryItems;
    default:
      return state;
  }
};
