import { IQuickBooksSettingsMapping } from './QuickBooksSettingsModal';

export enum QuickBooksActions {
  STORE_QB_MAPPINGS = 'STORE_QB_MAPPINGS',
  CLEAR_QUICKBOOKS = 'CLEAR_QUICKBOOKS',
}

export const storeQBMappings = (mapping: IQuickBooksSettingsMapping) => ({
  type: QuickBooksActions.STORE_QB_MAPPINGS,
  payload: { mapping },
});

export const clearQuickBooks = () => ({
  type: QuickBooksActions.CLEAR_QUICKBOOKS,
});
