import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';

const SteppedPortal: FunctionComponent = ({ children }) =>
  ReactDOM.createPortal(
    <div>{children}</div>,
    document.getElementById('stepped-modal-root') as HTMLElement,
  );

export default SteppedPortal;
