import React, { FunctionComponent } from 'react';
import Back from '../../assets/back-blue.svg';
import RSMBack from '../../assets/rsm-back-blue.svg';
import { useResourceContext } from '../../common/resource-context';
import './Header.scss';

const Header: FunctionComponent = () => {
  const resources = useResourceContext();

  const isRSM = process.env.REACT_APP_ENABLE_RSM === 'true';

  return (
    <div className="container">
      <img
        className="logo"
        src={resources.img('PlanAndPricingLogo')}
        alt={resources.text('HeaderLogoAlt')}
      />
      <div className="title">Plans and Pricing</div>
      <button
        className={`back ${isRSM && 'rsm'}`}
        onClick={() => window.history.back()}
      >
        <img src={`${isRSM ? RSMBack : Back}`} alt="Back to LeaseGuru" />
        <span>Back to LeaseGuru</span>
      </button>
    </div>
  );
};

export default Header;
