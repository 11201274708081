export interface IResourceContextObject {
  text: (key: string, failBack?: string) => string;
  formatText: (key: string, obj: any, failBack?: string) => string;
  img: (key: string, failBack?: string) => string;
}
export const createResourceContextObject = async (): Promise<IResourceContextObject> => {
  const resourceModule = await import(
    process.env.REACT_APP_RESOURCE_TEXT_FILE as string
  );
  const resourceTexts = resourceModule.default;

  const resourceContext = {
    text(key: string, failBack: string = '') {
      return resourceTexts[key] || failBack;
    },
    formatText(key: string, obj: any = {}, failBack: string = '') {
      const str = (resourceTexts[key] as string) || failBack;
      return str.replace(/\${(.*?)}/g, (_, g) => obj[g]);
    },
    img(key: string, failBack: string = '') {
      const fileName = resourceTexts[key];
      if (!fileName) {
        return failBack;
      }
      return `${process.env.PUBLIC_URL}${fileName}`;
    },
  };
  return resourceContext;
};
