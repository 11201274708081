import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { exportJournalEntryIntegration } from '../../api';
import GuruLogoSpinner from '../../common/loaders/GuruLogoSpinner';
import { hideModal } from '../../common/modal/actions';
import Modal from '../../common/modal/Modal';
import {
  ClientIntegration,
  ICompanyModel,
  ISageJournalEntryExportModel,
} from '../../interfaces';
import { IStore } from '../../store';

interface IIntegrationsExportJEModalProps {
  otherProps: {
    journalEntry: ISageJournalEntryExportModel;
    clientIntegration: ClientIntegration;
  };
}

const IntegrationsExportJEModal: FunctionComponent<IIntegrationsExportJEModalProps> = ({
  otherProps,
}) => {
  const dispatch = useDispatch();
  const company: ICompanyModel = useSelector(
    (state: IStore) => state.user.company,
  );
  const [processing, setProcessing] = useState(true);

  let integrationName = '';
  if (company.clientIntegrations !== undefined) {
    switch (company.clientIntegrations[0]) {
      case ClientIntegration.SAGE:
        integrationName = 'Sage';
        break;
      case ClientIntegration.XERO:
        integrationName = 'Xero';
        break;
      case ClientIntegration.QUICKBOOKS:
        integrationName = 'Quickbooks';
        break;
      default:
        integrationName = 'ERP';
        break;
    }
  }

  useEffect(() => {
    const exportJEToIntegration = async () => {
      try {
        const response = await exportJournalEntryIntegration(
          otherProps?.clientIntegration,
          otherProps.journalEntry,
          company.clientId,
        );
        if (response) {
          setProcessing(false);
          setTimeout(() => dispatch(hideModal()), 2000);
        }
      } catch (error) {
        dispatch(hideModal());
        alert('There was an issue exporting your Journal Entry');
      }
    };

    if (otherProps?.journalEntry) {
      exportJEToIntegration();
    }
  }, [otherProps, company.clientId, dispatch]);

  return (
    <Modal open={true} processing={processing}>
      <div className="formik-qb-wrapper">
        <div className="qb-loader">
          <h2>
            {processing
              ? `Syncing Journal Entry with ${integrationName}`
              : `Export to ${integrationName} Successful`}
          </h2>
          <GuruLogoSpinner inProgress={processing} />
        </div>
      </div>
    </Modal>
  );
};

export default IntegrationsExportJEModal;
