import React, { FunctionComponent } from 'react';
import TagManager from 'react-gtm-module';
import { useResourceContext } from '../../../common/resource-context';
import './EnterprisePlanHeader.scss';
import './PlanHeader.scss';

interface EnterprisePlanHeaderProps {
  isDisplayOnCPAPricingPage?: boolean;
}
const EnterprisePlanHeader: FunctionComponent<EnterprisePlanHeaderProps> = ({
  isDisplayOnCPAPricingPage,
}) => {
  const resources = useResourceContext();
  const contactUsHandler = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'upgrade_subscription_cta',
        cta_location: 'In-Product Plans and Pricing Screen',
        cta_creative: `Contact us - Request demo link click`,
      },
    });

    const enterpriseUrl = resources.text('EnterprisePricingUrl');
    window.open(enterpriseUrl, '_blank');
  };

  const isRSM = process.env.REACT_APP_ENABLE_RSM === 'true';

  return (
    <div className={`${isRSM && 'rsm'}`}>
      <div className="enterprise-plan-wrapper">
        <div className="card-title">LeaseQuery</div>
        <div className="lease-count">
          {isDisplayOnCPAPricingPage
            ? 'Unlimited companies'
            : 'Unlimited leases'}
        </div>
        <div className="demo">
          Request a
          <br />
          <div>Demo</div>
        </div>
        <div className="button">
          <button onClick={contactUsHandler}>Contact Us</button>
        </div>
      </div>
    </div>
  );
};

export default EnterprisePlanHeader;
