import format from 'date-fns/format';
import React, { Fragment, FunctionComponent } from 'react';

import {
  ConditionalWrapper,
  Divider,
  ExpandSection,
  Section,
} from '../../../../common';
import { DEFAULT_DATE_FORMAT } from '../../../../common/utilities/_dates';
import { formatCityStateZip } from '../../../../common/utilities/_strings';
import {
  ILeaseModel,
  IPaymentDetailModel,
  IPaymentModel,
  IRenewOptionsModel,
} from '../../../../interfaces';
import { getJurisdicitionValue } from '../LeaseDetailsHeader';
import AddDetailsGroup from './AddDetailsGroup';
import AddDetailsRow from './AddDetailsRow';

import './AdditionalDetails.scss';

interface IAdditionalDetailsProps {
  lease: ILeaseModel;
  jurisdiction: string | undefined;
}

const getRenewalLanguage = (key: number, value: number) => {
  let userText = '';
  switch (key) {
    case 1:
      userText = 'Day';
      break;
    case 2:
      userText = 'Week';
      break;
    case 3:
      userText = 'Month';
      break;
    case 4:
      userText = 'Year';
      break;
  }
  if (value !== 1) {
    userText += 's';
  }
  return userText;
};

const getFrequencyLanguage = (value: number) => {
  let userText = '';
  switch (value) {
    case 1:
      userText = 'Monthly';
      break;
    case 2:
      userText = 'Quarterly';
      break;
    case 6:
      userText = 'One Time';
      break;
    case 3:
      userText = 'Annual';
      break;
  }
  return userText;
};

const AdditionalDetails: FunctionComponent<IAdditionalDetailsProps> = ({
  lease,
  jurisdiction,
}) => {
  return (
    <div className="add-wrapper">
      <Section>
        <AddDetailsRow
          title="Lease Type"
          value={
            jurisdiction
              ? getJurisdicitionValue(jurisdiction, lease.header.isCapital)
              : ''
          }
        />
      </Section>
      <Section>
        <AddDetailsRow
          title="Fair Value"
          value={lease.header.fairValue?.toLocaleString()}
        />
      </Section>
      <ExpandSection label="Address" hideContentBorder>
        <AddDetailsRow title="Address" value={lease.header.address} />
        <Divider />
        <AddDetailsRow
          title="City / State / Zip"
          value={formatCityStateZip(
            lease.header.city,
            lease.header.state,
            lease.header.zip,
          )}
        />
      </ExpandSection>
      <Section>
        <AddDetailsRow
          title="Useful Life"
          value={`${
            lease.header.usefulLifeMonths
              ? `${lease.header.usefulLifeMonths} Months`
              : ''
          }`}
        />
      </Section>
      <Section>
        <AddDetailsRow
          title="Title Transfer at end of lease"
          value={lease.header.canTransferTitle ? 'Yes' : 'No'}
        />
      </Section>
      {lease.renewalOptions.length ? (
        <ExpandSection label="Option to Renew" hideContentBorder>
          {lease.renewalOptions.map((renewalOption: IRenewOptionsModel, i) => (
            <AddDetailsGroup key={i} header={`Renewal Option ${i + 1}`}>
              <AddDetailsRow
                title="Renew for how long"
                value={`${renewalOption.renewForHowLongValue} ${
                  renewalOption.renewForHowLongPeriod &&
                  renewalOption.renewForHowLongValue
                    ? getRenewalLanguage(
                        renewalOption.renewForHowLongPeriod,
                        renewalOption.renewForHowLongValue,
                      )
                    : ''
                }`}
              />
              <Divider />
              <AddDetailsRow
                title="Renewal Start Date"
                value={
                  renewalOption.renewStartDt
                    ? format(
                        new Date(renewalOption.renewStartDt),
                        DEFAULT_DATE_FORMAT,
                      )
                    : ''
                }
              />
              <Divider />
              <AddDetailsRow
                title="Renewal End Date"
                value={
                  renewalOption.renewEndDt
                    ? format(
                        new Date(renewalOption.renewEndDt),
                        DEFAULT_DATE_FORMAT,
                      )
                    : ''
                }
              />
              <Divider />
              <AddDetailsRow
                title="Reasonably certain to exercise this option?"
                value={renewalOption.reasonableToRenew ? 'Yes' : 'No'}
              />
            </AddDetailsGroup>
          ))}
        </ExpandSection>
      ) : null}
      {lease.purchaseOption && lease.purchaseOption.purchaseAmt ? (
        <ExpandSection label="Purchase Option" hideContentBorder>
          <AddDetailsRow
            title="Purchase Amount"
            value={lease.purchaseOption.purchaseAmt?.toLocaleString()}
          />
          <Divider />
          <AddDetailsRow
            title="Bargain Purchase Option"
            value={lease.purchaseOption.canBargainPurchase ? 'Yes' : 'No'}
          />
          <Divider />
          <AddDetailsRow
            title="Reasonably certain to exercise this option?"
            value={lease.purchaseOption.reasonableToPurchase ? 'Yes' : 'No'}
          />
        </ExpandSection>
      ) : null}
      {lease.terminateOption && lease.terminateOption.feeAmt ? (
        <Section>
          <AddDetailsRow
            title="Termination fee amount"
            value={lease.terminateOption.feeAmt?.toLocaleString()}
          />
        </Section>
      ) : null}

      <ExpandSection label="Base Rent" hideContentBorder>
        <>
          <AddDetailsRow
            title="Borrowing Rate"
            value={lease.basePayment.borrowingRate?.toString()}
          />
          <Divider />
          <AddDetailsRow
            title="Transition Rate"
            value={lease.basePayment.transitionRate?.toString()}
          />
          <Divider />
          <AddDetailsRow
            title="Deferred/Prepaid Rent"
            value={Number(lease.basePayment.beginningPrepaidDeferredRent)
              ?.toFixed(2)
              ?.toString()}
          />
          <Divider />
          <AddDetailsRow
            title="Incentives"
            value={Number(lease.basePayment.incentiveAmount)
              ?.toFixed(2)
              ?.toString()}
          />
          <Divider />
        </>

        {lease.basePayment.paymentDetail.map(
          (paymentDetail: IPaymentDetailModel, iii) =>
            lease.basePayment.paymentDetail.length > 1 ? (
              <ExpandSection
                label={`Payment ${iii + 1}`}
                key={iii}
                hideContentBorder
              >
                <AddDetailsRow
                  title="Base Rent"
                  value={paymentDetail.paymentAmt?.toLocaleString()}
                />
                <Divider />
                {paymentDetail.paymentFrequency !== undefined ? (
                  <>
                    <AddDetailsRow
                      title="Payment Frequency"
                      value={`${
                        paymentDetail.paymentFrequency
                          ? getFrequencyLanguage(paymentDetail.paymentFrequency)
                          : ''
                      }`}
                    />
                    <Divider />
                  </>
                ) : null}
                <AddDetailsRow
                  title="First Payment Date"
                  value={format(
                    new Date(paymentDetail.firstPaymentDt),
                    DEFAULT_DATE_FORMAT,
                  )}
                />
                <Divider />
                <AddDetailsRow
                  title="Last Payment Date"
                  value={format(
                    new Date(paymentDetail.lastPaymentDt),
                    DEFAULT_DATE_FORMAT,
                  )}
                />
                {paymentDetail.incrementDecrementAmt === 0 &&
                paymentDetail.isIncrementPct !== undefined ? null : (
                  <>
                    <Divider />
                    <AddDetailsRow
                      title="Increase/Decrease Annually By"
                      value={paymentDetail.incrementDecrementAmt?.toString()}
                    />
                    <Divider />
                    <AddDetailsRow
                      title="Increase/Decrease Annually Type"
                      value={
                        paymentDetail.isIncrementPct === true
                          ? 'Percentage Increase'
                          : 'Amount Increase'
                      }
                    />
                  </>
                )}
              </ExpandSection>
            ) : (
              <Fragment key={iii}>
                <AddDetailsRow
                  title="Base Rent"
                  value={paymentDetail.paymentAmt?.toLocaleString()}
                />
                <Divider />
                {paymentDetail.paymentFrequency !== undefined ? (
                  <>
                    <AddDetailsRow
                      title="Payment Frequency"
                      value={`${
                        paymentDetail.paymentFrequency
                          ? getFrequencyLanguage(paymentDetail.paymentFrequency)
                          : null
                      }`}
                    />
                    <Divider />
                  </>
                ) : null}
                <AddDetailsRow
                  title="First Payment Date"
                  value={format(
                    new Date(paymentDetail.firstPaymentDt),
                    DEFAULT_DATE_FORMAT,
                  )}
                />
                <Divider />
                <AddDetailsRow
                  title="Last Payment Date"
                  value={format(
                    new Date(paymentDetail.lastPaymentDt),
                    DEFAULT_DATE_FORMAT,
                  )}
                />
                {paymentDetail.isIncrementPct !== undefined &&
                paymentDetail.incrementDecrementAmt !== null ? (
                  <>
                    <Divider />
                    <AddDetailsRow
                      title="Increase/Decrease Annually By"
                      value={
                        paymentDetail.incrementDecrementAmt
                          ? paymentDetail.incrementDecrementAmt?.toString()
                          : ''
                      }
                    />
                    <Divider />
                    <AddDetailsRow
                      title="Increase/Decrease Annually Type"
                      value={
                        paymentDetail.isIncrementPct === true
                          ? 'Percentage Increase'
                          : 'Amount Increase'
                      }
                    />
                  </>
                ) : null}
              </Fragment>
            ),
        )}
      </ExpandSection>

      {lease.otherPayments.length ? (
        <ExpandSection label="Additional Payments" hideContentBorder>
          {lease.otherPayments.map((otherPayment: IPaymentModel, i) => (
            <AddDetailsGroup key={i} header={otherPayment.paymentName}>
              <AddDetailsRow
                title="Fixed or Variable payment?"
                value={otherPayment.isFixed ? 'Fixed' : 'Variable'}
              />
              <Divider />
              {otherPayment.paymentDetail.map(
                (payment: IPaymentDetailModel, ii) => (
                  <ConditionalWrapper
                    wrapper={(children: React.ReactNode) => (
                      <ExpandSection
                        label={`Payment ${ii + 1}`}
                        hideContentBorder
                      >
                        {children}
                      </ExpandSection>
                    )}
                    key={ii}
                    condition={otherPayment.paymentOptionType === 2}
                  >
                    <>
                      <AddDetailsRow
                        title="Amount/Frequency"
                        value={`${payment.paymentAmt?.toLocaleString()} ${
                          payment.paymentFrequency
                            ? getFrequencyLanguage(payment.paymentFrequency)
                            : ''
                        }`}
                      />
                      <Divider />
                      <AddDetailsRow
                        title="First Payment Date"
                        value={format(
                          new Date(payment.firstPaymentDt),
                          DEFAULT_DATE_FORMAT,
                        )}
                      />
                      {payment.lastPaymentDt ? (
                        <>
                          <Divider />
                          <AddDetailsRow
                            title="Last Payment Date"
                            value={format(
                              new Date(payment.lastPaymentDt),
                              DEFAULT_DATE_FORMAT,
                            )}
                          />
                        </>
                      ) : null}
                      {payment.isIncrementPct !== null ? (
                        <>
                          <Divider />
                          <AddDetailsRow
                            title="Annual Increase/Decrease"
                            value={`${payment.incrementDecrementAmt} ${
                              payment.isIncrementPct ? 'Percent' : ''
                            }`}
                          />
                        </>
                      ) : null}
                    </>
                  </ConditionalWrapper>
                ),
              )}
            </AddDetailsGroup>
          ))}
        </ExpandSection>
      ) : null}
    </div>
  );
};

export default AdditionalDetails;
