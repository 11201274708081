import React, { FunctionComponent } from 'react';

import './GlossaryItem.scss';

export interface IGlossaryItem {
  term: string;
  displayOrder: number;
  termDefinition: string;
}

interface IGlossaryItemProps {
  glossaryItem: IGlossaryItem;
  onClick: () => void;
}

const GlossaryItem: FunctionComponent<IGlossaryItemProps> = ({
  glossaryItem: { term },
  onClick,
}) => {
  return (
    <div className={`glossary-item-wrapper`} onClick={onClick}>
      <div className="glossary-item-title">{term}</div>
      <div className="glossary-item-caret" />
    </div>
  );
};

export default GlossaryItem;
