import { Form, Formik } from 'formik';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLease } from '../../../../api';
import { Input } from '../../../../common';
import { hideModal } from '../../../../common/modal/actions';

import Modal from '../../../../common/modal/Modal';
import { fieldHasError } from '../../../../common/utilities/FormikHelpers';
import {
  ILeaseHeaderModel,
  ILeaseModel,
  IUserModel,
} from '../../../../interfaces';
import { IStore } from '../../../../store';
import { setReloadLeaseDetails } from '../../../lease/actions';
import { LeaseGeneralDetailsFormSchema } from '../../../lease/add-lease-forms/validationSchemas';
import { updateSelectedLeaseHeader } from '../../actions';

import './LeaseGeneralDetailsModal.scss';

interface ILeaseGeneralDetailsModalProps {
  open: boolean;
}

const LeaseGeneralDetailsModal: FunctionComponent<ILeaseGeneralDetailsModalProps> = ({
  open,
}) => {
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();

  const selectedLease: ILeaseModel = useSelector((state: IStore) => {
    return state.leaseDetails.selectedLease;
  });

  const loggedInUser: IUserModel = useSelector((state: IStore) => state.user);

  const formikRef = useRef<Formik<ILeaseHeaderModel>>(null);

  const onSubmit = async (headerValues: ILeaseHeaderModel) => {
    setProcessing(true);

    const newSelectedLeaseData: any = {
      ...selectedLease,
      header: headerValues,
      leaseId: headerValues.leaseId,
      clientId: loggedInUser.company.clientId,
      userId: loggedInUser.userId,
    };

    // remove unnecessary data from object - API doesn't need these
    delete newSelectedLeaseData.amortizationSchedules;
    delete newSelectedLeaseData.capOpTestResults;

    await updateLease(newSelectedLeaseData, 'OVERRIDE');

    dispatch(updateSelectedLeaseHeader(headerValues));
    dispatch(setReloadLeaseDetails(true));
    dispatch(hideModal());
  };

  return (
    <Modal
      title="General Lease Details"
      open={open}
      neutralButtonText="Cancel"
      positiveButtonText="Save"
      onConfirm={() => formikRef.current?.submitForm()}
      processing={processing}
    >
      <Formik
        ref={formikRef}
        initialValues={selectedLease.header}
        validationSchema={LeaseGeneralDetailsFormSchema}
        onSubmit={onSubmit}
      >
        {(formProps) => {
          return (
            <Form noValidate={true}>
              <div className="lease-general-details-form">
                <Input
                  label="Asset Name"
                  required
                  name="assetName"
                  value={formProps.values.assetName}
                  error={fieldHasError(formProps, 'assetName')}
                  errorMessage={formProps.errors.assetName}
                  onChange={formProps.handleChange}
                  maxLength={50}
                />
                <Input
                  label="Description"
                  name="description"
                  value={formProps.values.description}
                  multiline
                  onChange={formProps.handleChange}
                />
                <Input
                  label="Serial/ID Number"
                  name="serialNbr"
                  value={formProps.values.serialNbr}
                  onChange={formProps.handleChange}
                />
                <Input
                  label="Address"
                  name="address"
                  value={formProps.values.address}
                  onChange={formProps.handleChange}
                />
                <div className="address-line-two">
                  <div className="city">
                    <Input
                      label="City"
                      name="city"
                      value={formProps.values.city}
                      onChange={formProps.handleChange}
                    />
                  </div>
                  <div className="state">
                    <Input
                      label="State"
                      name="state"
                      value={formProps.values.state}
                      onChange={formProps.handleChange}
                      maxLength={2}
                    />
                  </div>
                  <div className="zip">
                    <Input
                      label="zip"
                      name="zip"
                      value={formProps.values.zip}
                      onChange={formProps.handleChange}
                      maxLength={10}
                      error={fieldHasError(formProps, 'zip')}
                      errorMessage={formProps.errors.zip}
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default LeaseGeneralDetailsModal;
