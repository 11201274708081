import { findDocumentName, formatFileName } from './_regex';

export const isEmptyObject = (object: object) => {
  return Object.entries(object).length === 0 && object.constructor === Object;
};

export const formatDropOptions = (
  list: any[],
  key: string = 'key',
  value: string = 'value',
  label: string = value,
  userRole: string = '',
) => {
  return (
    list?.map((li) => ({
      key: li[key],
      value: li[value],
      label: li[label],
      userRole: li[userRole],
    })) || []
  );
};

export const processErrorMessage = (error: any) => {
  let defaultMessage = 'Uh oh! An unexpected error occured.';
  if (error && error.response && error.response.data) {
    const { data } = error.response;
    const { message } = data;
    if (message) {
      defaultMessage = String(message);
    } else if (error.message) {
      defaultMessage = String(error.message);
    }
  } else if (error && error.message) {
    defaultMessage = error.message;
  }
  return defaultMessage;
};

export const downloadFile = (blob: Blob, header: string) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  const fileName = getFileNameFromHeader(header);
  link.href = url;
  link.setAttribute('download', `${fileName.replace(formatFileName, '-')}`);
  document.body.appendChild(link);
  link.click();
};

const getFileNameFromHeader = (data: string) => {
  const matches = data.match(findDocumentName);
  if (matches && matches.length) {
    return matches[0].substring(9);
  }

  return 'Export.xlsx';
};

export const formatCurrencyValue = (value: number | undefined) => {
  if (!value) {
    return '';
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
};

export const isEnvRSM = () => {
  return process.env.REACT_APP_ENABLE_RSM === 'true';
};

export const applyRSMClassName = (selector: string) => {
  return isEnvRSM() ? `${selector} rsm-class-${selector}` : selector;
};

export const getGTMDataLayerEventByAmendType = (
  amendType: string | undefined,
): string => {
  return amendType === 'OVERRIDE'
    ? 'amend_lease_detail_change_progress'
    : 'add_new_lease_progress';
};
