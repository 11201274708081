import { IGlossaryItem } from './components/GlossaryItem';

export enum GlossaryActions {
  STORE_GLOSSARY_ITEMS = 'STORE_GLOSSARY_ITEMS',
}

export const storeGlossaryItems = (glossaryItems: IGlossaryItem[]) => ({
  type: GlossaryActions.STORE_GLOSSARY_ITEMS,
  payload: { glossaryItems },
});
