import React, { FunctionComponent, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  exportTotalOrgJournalEntry,
  getTotalOrgJournalEntries,
} from '../../api';
import image from '../../assets/exclamation.svg';
import { ExpandSection } from '../../common';
import { showModal } from '../../common/modal/actions';
import { downloadFile } from '../../common/utilities/_helpers';
import {
  ICompanyModel,
  IJournalEntryFilters,
  IJournalEntryModel,
  ILeaseHeaderModel,
  ITotalOrgJeExportModel,
  ITotalOrgJournalEntryModel,
} from '../../interfaces';
import { IStore } from '../../store';
import { checkBalance } from '../leaseDetails/components/BalanceChecker';
import { JEExportOptions } from '../leaseDetails/components/JournalEntriesTable/JournalEntriesContainer';
import { JournalEntriesTable } from '../leaseDetails/components/JournalEntriesTable/JournalEntriesTable';
import { LeaseJournalFilters } from '../leaseDetails/components/JournalEntriesTable/LeaseJournalFilters';
import './TotalOrgJe.scss';

interface ITotalOrgJeProps extends RouteComponentProps {
  company: ICompanyModel | undefined;
}

const TotalOrgJe: FunctionComponent<ITotalOrgJeProps> = ({ company }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<IJournalEntryFilters>({
    month: undefined,
    year: undefined,
    jurisdiction: undefined,
    isCapital: false,
  });
  const [valid, setValid] = useState<boolean>(false);
  const [firstLoad, setfirstLoad] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ITotalOrgJournalEntryModel[]>([]);
  const [totalOrgJe, setTotalOrgJe] = useState<IJournalEntryModel[]>([]);
  const leaseList: ILeaseHeaderModel[] | undefined = useSelector(
    (state: IStore) => state.leaseList,
  );
  const userCompanyData = useSelector((state: IStore) => {
    return state.user.company!;
  });
  // const quickBooksSettingsMapping: IQuickBooksSettingsMapping = useSelector(
  //   (state: IStore) => state.quickBooksSettings,
  // );

  const getTotalOrgJe = (totalOrgData: ITotalOrgJournalEntryModel[]) => {
    const totalOrgEntry = totalOrgData.find(
      (entry: ITotalOrgJournalEntryModel) =>
        entry.assetName === 'Total Organization',
    );

    return totalOrgEntry && setTotalOrgJe(totalOrgEntry.journalEntry);
  };

  const fetchEntries = async () => {
    try {
      if (company && company.clientId) {
        setLoading(true);

        TagManager.dataLayer({
          dataLayer: {
            event: 'journal_entry_interaction',
            journal_entry_action: 'Run Total Organization',
          },
        });

        const entries: any = await getTotalOrgJournalEntries(
          company.clientId,
          filters,
        );
        getTotalOrgJe(entries);
        setData([...entries]);
      }
    } catch (error) {
      alert(`There was an issue loading your journal entry selection`);
    }
    setLoading(false);
    if (firstLoad) {
      setfirstLoad(false);
    }
  };

  const onFiltersUpdate = (value: IJournalEntryFilters) => {
    setFilters(value);
    setValid(isFormValid(value));
  };

  const isFormValid = (value: IJournalEntryFilters) =>
    !Object.values(value).some((val) => val === '' || val === undefined);

  const getBeginOrEndDt = (dateToGet: 'begin' | 'end') => {
    let baseDate = new Date();

    if (leaseList && leaseList.length) {
      leaseList.forEach((lease) => {
        if (dateToGet === 'begin') {
          const leaseBeginDate = new Date(lease.leaseBeginDt);

          if (baseDate.getFullYear() > leaseBeginDate.getFullYear()) {
            baseDate = leaseBeginDate;
          }
        } else if (dateToGet === 'end') {
          const leaseEndDate = new Date(lease.leaseEndDt);

          if (baseDate.getFullYear() < leaseEndDate.getFullYear()) {
            baseDate = leaseEndDate;
          }
        }
      });
    }

    return baseDate.toString();
  };

  const exportTotalOrg = async (exportTo: JEExportOptions) => {
    if (data.length) {
      const { year, month, jurisdiction, isCapital } = filters;
      if (year && month && jurisdiction && isCapital !== undefined) {
        const journalEntryToExport: ITotalOrgJeExportModel = {
          year,
          month,
          isCapital,
          jurisdiction,
          journalEntries: data,
        };

        switch (exportTo) {
          case 'quickbooks':
            await handleExportTotalOrgQuickBooks(journalEntryToExport);
            break;
          default:
            const response = await exportTotalOrgJournalEntry(
              journalEntryToExport,
            );
            const { blob, cdHeader } = response;
            downloadFile(blob, cdHeader);
        }
      }
    }
  };

  const handleExportTotalOrgQuickBooks = (journalEntryToExport: any) => {
    dispatch(
      showModal({
        modal: {
          modalType: 'QB_TOTALORG_CONFIRMATION',
          modalProps: {
            open: true,
            otherProps: {
              journalEntryTotalOrg: journalEntryToExport,
            },
          },
        },
      }),
    );
  };

  return (
    <div className="total-org-wrapper">
      <div className="total-org-filter-section">
        <div className="total-org-header">Journal Entries</div>
        <div className="total-org-filters">
          <LeaseJournalFilters
            value={filters}
            loading={loading}
            onChange={onFiltersUpdate}
            leaseBeginDt={getBeginOrEndDt('begin')}
            leaseEndDt={getBeginOrEndDt('end')}
            onExecute={fetchEntries}
            valid={valid}
            showIsCapital
          />
        </div>
      </div>
      <div className="total-org-data-section">
        {data.length !== 0 ? (
          <>
            <JournalEntriesTable
              key={totalOrgJe.length}
              data={totalOrgJe}
              loading={false}
              headerText="Total Organization"
              exportJE={exportTotalOrg}
              isTotalOrg
              ERPConnected={
                userCompanyData.clientIntegrations &&
                userCompanyData.clientIntegrations.length !== 0
              }
            />
            <div className="total-org-leases">
              {data
                .filter((entry) => entry.assetName !== 'Total Organization')
                .map((entry, index) => (
                  <ExpandSection
                    key={index}
                    label={entry.assetName}
                    hideContentBorder
                    headerImage={
                      !checkBalance(entry.journalEntry).isBalanced ? (
                        <img src={image} alt="icon" />
                      ) : null
                    }
                  >
                    <JournalEntriesTable
                      key={entry.journalEntry.length}
                      data={entry.journalEntry}
                      loading={false}
                      isTotalOrg
                    />
                  </ExpandSection>
                ))}
            </div>
          </>
        ) : firstLoad ? null : (
          <div>No data for this selection</div>
        )}
      </div>
    </div>
  );
};

export default TotalOrgJe;
