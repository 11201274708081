import React, { FunctionComponent } from 'react';
import { FutureJournalEntriesUrl } from '../../app/resources/text.json';
import { ReactComponent as AlertIcon } from '../../assets/alert-icon.svg';
import { Button } from '../buttons';

import './MessageBanner.scss';

interface IMessageBannerProps {
  message: string;
}

const MessageBanner: FunctionComponent<IMessageBannerProps> = ({ message }) => {
  const handleButtonClick = () => {
    window.open(FutureJournalEntriesUrl, '_blank');
  };

  return (
    <div className="message-banner-wrapper">
      <div className="message-banner-message-wrapper">
        <AlertIcon />
        <p className="message-banner-message-text">{message || ''}</p>
      </div>
      <Button
        buttonType="positive"
        buttonText="Learn More"
        onClick={handleButtonClick}
      />
    </div>
  );
};

export default MessageBanner;
