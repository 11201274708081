import format from 'date-fns/format';
import { PaymentOptionType } from './../interfaces';

import {
  API_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
} from '../common/utilities/_dates';
import { isEmptyObject } from '../common/utilities/_helpers';
import { LeaseForms } from '../features/lease/actions';
import { IAdditionalFormState } from '../features/lease/add-lease-forms/AdditionalPaymentsForm';
import { IDateFormState } from '../features/lease/add-lease-forms/DateForm';
import { IGeneralFormState } from '../features/lease/add-lease-forms/GeneralForm';
import { IOptionsFormState } from '../features/lease/add-lease-forms/OptionsForm';
import { ILeaseState } from '../features/lease/reducers';
import {
  AmendTypes,
  IAddUpdateLeaseResponse,
  ICompanySelectorRoleOption,
  IDisclosuresModel,
  IJeExportModel,
  IJournalEntryExportModel,
  IJournalEntryFilters,
  ILeaseModel,
  IPaymentDetailModel,
  IPaymentModel,
  IRenewOptionsModel,
  ITotalOrgJeExportModel,
  IUserModel,
  JurisdictionValue,
} from '../interfaces';
import { api, formatError } from './api';

export async function getLeases(userId: number) {
  const rawResponse = await api.get(`/Leases/${userId}`);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function getLeasesByClient(clientId: number) {
  const rawResponse = await api.get(`/LeasesByClient/${clientId}`);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function getLeaseDetails(leaseId: string): Promise<ILeaseModel> {
  const rawResponse = await api.get(`/Lease/${leaseId}`);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function getAmortSchedule(leaseId: number, options: any) {
  const paymentType = (options && options.paymentType) || 'BASE';
  const jurisdiction =
    (options && options.jurisdiction) || JurisdictionValue.TOPIC_840;

  const rawResponse = await api.get(
    `/AmortSchedule/${leaseId}/${paymentType}/${jurisdiction}`,
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function getJournalEntries(
  leaseId: number,
  filters: IJournalEntryFilters,
) {
  const rawResponse = await api.get(
    `/JournalEntry/${leaseId}/${filters.year}/${filters.month}/${filters.jurisdiction}`,
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function getTotalOrgJournalEntries(
  clientId: number,
  filters: IJournalEntryFilters,
) {
  const rawResponse = await api.get(
    `/JournalEntryTotOrg/${clientId}/${filters.year}/${filters.month}/${filters.jurisdiction}/${filters.isCapital}`,
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function exportJournalEntry(
  journalEntry: IJournalEntryExportModel,
): Promise<IJeExportModel> {
  const rawResponse = await api.post(`/JournalEntryExport`, journalEntry, {
    responseType: 'blob',
  });

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return {
    blob: rawResponse.data,
    cdHeader: rawResponse.headers['content-disposition'],
  };
}

export async function exportTotalOrgJournalEntry(
  journalEntry: ITotalOrgJeExportModel,
): Promise<IJeExportModel> {
  const rawResponse = await api.post(
    `/JournalEntryTotalOrgExport`,
    journalEntry,
    {
      responseType: 'blob',
    },
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return {
    blob: rawResponse.data,
    cdHeader: rawResponse.headers['content-disposition'],
  };
}

export async function exportAmortSchedule(
  lease: ILeaseModel,
): Promise<IJeExportModel> {
  const rawResponse = await api.post(`/AmortizationScheduleExport`, lease, {
    responseType: 'blob',
  });

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return {
    blob: rawResponse.data,
    cdHeader: rawResponse.headers['content-disposition'],
  };
}

export async function getCurrencyList() {
  const rawResponse = await api.get(`/Currencies`);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function getUserClientList(userId: number) {
  const rawResponse = await api.get(`/UserClientList/${userId}`);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }
  const contributingClients: ICompanySelectorRoleOption[] = rawResponse.data.filter(
    (client: ICompanySelectorRoleOption) => client.userRole === 3,
  );

  const guestClients: ICompanySelectorRoleOption[] = rawResponse.data.filter(
    (client: ICompanySelectorRoleOption) => client.userRole === 6,
  );

  return [...contributingClients, ...guestClients];
}

export async function getAdminClientList() {
  const rawResponse = await api.get('/Clients');

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function getClient(clientId: number) {
  const rawResponse = await api.get(`/Client?clientId=${clientId}`);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function getDisclosureRpt(
  clientId: number,
  isIfrs: boolean,
  startDate: string,
  endDate: string,
): Promise<IDisclosuresModel[]> {
  const rawResponse = await api.get(
    `/DisclosureRptGet/${clientId}/${startDate}/${endDate}/${isIfrs}`,
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  const parsedJsonString = JSON.parse(rawResponse.data);

  return parsedJsonString;
}

export async function addLease(lease: any): Promise<IAddUpdateLeaseResponse> {
  const rawResponse = await api.post(`/Lease`, lease);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function updateLease(
  lease: any,
  amendType: AmendTypes,
): Promise<IAddUpdateLeaseResponse> {
  const rawResponse = await api.put(
    `${amendType === 'OVERRIDE' ? '/Lease' : '/LeaseModify'}`,
    lease,
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function terminateLease(
  leaseId: number,
  terminationDate: string,
  terminationAmt: number = 0,
) {
  // bug in Formik
  // "" being sent when user adds and removes number
  const terminationNumber = Number(terminationAmt);

  const rawResponse = await api.post(`/LeaseTerminate`, {
    leaseId,
    terminationDate,
    terminationAmt: terminationNumber,
  });

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function deleteLease(leaseId: number) {
  const rawResponse = await api.delete(`/Lease/${leaseId}`);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export const populateSelectForms = (
  forms: LeaseForms[],
  selectedLease: ILeaseModel,
) => {
  return forms.reduce((populatedForms: any, form: LeaseForms) => {
    switch (form) {
      case LeaseForms.GENERAL:
        return {
          ...populatedForms,
          [LeaseForms.GENERAL]: populateGeneralForm(selectedLease),
        };
      case LeaseForms.DATE:
        return {
          ...populatedForms,
          [LeaseForms.DATE]: populateDateForm(selectedLease),
        };
      case LeaseForms.OPTIONS:
        return {
          ...populatedForms,
          [LeaseForms.OPTIONS]: populateOptionsForm(selectedLease),
        };
      case LeaseForms.BASE:
        return {
          ...populatedForms,
          [LeaseForms.BASE]: populateBasePaymentForm(selectedLease),
        };
      case LeaseForms.ADDL:
        return {
          ...populatedForms,
          [LeaseForms.ADDL]: populateAdditionalPaymentsForm(selectedLease),
        };
      default:
        return populatedForms;
    }
  }, {});
};

export const populateGeneralForm = (
  selectedLease: ILeaseModel,
): IGeneralFormState => {
  return {
    assetName: selectedLease.header.assetName,
    description: selectedLease.header.description || '',
    serialNbr: selectedLease.header.serialNbr,
    address: selectedLease.header.address || '',
    city: selectedLease.header.city || '',
    state: selectedLease.header.state || '',
    zip: selectedLease.header.zip || '',
    assetType: selectedLease.header.assetType || '',
    iconURI: selectedLease.header.iconURI,
    fairValue: selectedLease.header.fairValue,
    isCapital: selectedLease.header.isCapital,
  };
};

export const populateDateForm = (
  selectedLease: ILeaseModel,
): IDateFormState => {
  return {
    leaseBeginDt: selectedLease.header.leaseBeginDt
      ? new Date(selectedLease.header.leaseBeginDt)
      : undefined,
    leaseEndDt: selectedLease.header.leaseEndDt
      ? new Date(selectedLease.header.leaseEndDt)
      : undefined,
    usefulLifeMonths: selectedLease.header.usefulLifeMonths,
    leaseModificationDate: selectedLease.header.leaseModificationDate,
  };
};

export const populateOptionsForm = (
  selectedLease: ILeaseModel,
): IOptionsFormState => {
  return {
    isSpecializedUse: selectedLease.header.isSpecializedUse,
    canTransferTitle: selectedLease.header.canTransferTitle,
    isRenewalOption: !!selectedLease.renewalOptions.length,
    isMoreOptionsOpen:
      selectedLease.purchaseOption &&
      selectedLease.terminateOption &&
      (selectedLease.purchaseOption.reasonableToPurchase ||
        !!selectedLease.purchaseOption.purchaseAmt ||
        !!selectedLease.terminateOption.feeAmt),
    isPurchaseOption:
      selectedLease.purchaseOption &&
      (selectedLease.purchaseOption.reasonableToPurchase ||
        !!selectedLease.purchaseOption.purchaseAmt ||
        selectedLease.purchaseOption.canBargainPurchase),
    isTerminateOption:
      selectedLease.terminateOption && !!selectedLease.terminateOption.feeAmt,
    purchaseOption: { ...selectedLease.purchaseOption },
    renewalOptions: [...selectedLease.renewalOptions],
    terminateOption: { ...selectedLease.terminateOption },
  };
};

export const populateBasePaymentForm = (
  selectedLease: ILeaseModel,
): IAdditionalFormState => {
  return {
    otherPayments: [
      {
        id: selectedLease.basePayment.id,
        paymentName: selectedLease.basePayment.paymentName,
        paymentOptionType: selectedLease.basePayment.paymentOptionType,
        borrowingRate: selectedLease.basePayment.borrowingRate,
        transitionRate: selectedLease.basePayment.transitionRate,
        beginningPrepaidDeferredRent: Number(
          selectedLease.basePayment.beginningPrepaidDeferredRent.toFixed(2),
        ),
        fairValue: selectedLease.header.fairValue,
        isFixed: selectedLease.basePayment.isFixed,
        incentiveAmount: Number(
          selectedLease.basePayment.incentiveAmount?.toFixed(2),
        ),
        paymentDetail: selectedLease.basePayment.paymentDetail?.map(
          (payment) => ({
            id: payment.id,
            paymentAmt: payment.paymentAmt,
            paymentFrequency: payment.paymentFrequency,
            firstPaymentDt: payment.firstPaymentDt
              ? format(new Date(payment.firstPaymentDt), DEFAULT_DATE_FORMAT)
              : undefined,
            lastPaymentDt: payment.lastPaymentDt
              ? format(new Date(payment.lastPaymentDt), DEFAULT_DATE_FORMAT)
              : undefined,
            isIncrementPct:
              payment.isIncrementPct == null
                ? undefined
                : payment.isIncrementPct,
            incrementDecrementAmt: payment.incrementDecrementAmt,
          }),
        ),
      },
    ],
  };
};

export const populateAdditionalPaymentsForm = (
  selectedLease: ILeaseModel,
): IAdditionalFormState => {
  return {
    otherPayments: selectedLease.otherPayments.map((otherPayment) => ({
      id: otherPayment.id,
      paymentName: otherPayment.paymentName,
      paymentOptionType: otherPayment.paymentOptionType,
      borrowingRate: otherPayment.borrowingRate || undefined,
      transitionRate: otherPayment.transitionRate,
      beginningPrepaidDeferredRent:
        otherPayment.beginningPrepaidDeferredRent || undefined,
      isFixed: otherPayment.isFixed,
      paymentDetail: otherPayment.paymentDetail.map((payment) => ({
        id: payment.id,
        paymentAmt: payment.paymentAmt,
        paymentFrequency: payment.paymentFrequency,
        firstPaymentDt: payment.firstPaymentDt
          ? format(new Date(payment.firstPaymentDt), DEFAULT_DATE_FORMAT)
          : undefined,
        lastPaymentDt: payment.lastPaymentDt
          ? format(new Date(payment.lastPaymentDt), DEFAULT_DATE_FORMAT)
          : undefined,
        isIncrementPct:
          payment.isIncrementPct == null ? undefined : payment.isIncrementPct,
        incrementDecrementAmt: payment.incrementDecrementAmt,
      })),
    })),
  };
};

export const buildLeaseModel = (
  lease: ILeaseState,
  user: IUserModel,
  leaseId?: number,
) => {
  const getPaymentOptionType = (payment: IPaymentModel) => {
    if (payment.paymentDetail.length > 1) {
      return PaymentOptionType.VARIES_NOT_CONTANT;
    } else if (
      payment.paymentDetail[0].incrementDecrementAmt !== 0 &&
      payment.paymentDetail[0].incrementDecrementAmt !== undefined
    ) {
      return PaymentOptionType.VARIES_CONSTANT;
    } else {
      return PaymentOptionType.SAME;
    }
  };

  let optionsData: any = [];
  if (lease.form![LeaseForms.OPTIONS].isRenewalOption) {
    optionsData = lease.form![LeaseForms.OPTIONS].renewalOptions.map(
      (option: IRenewOptionsModel) => {
        return {
          id: option.id,
          renewForHowLongValue: option.renewForHowLongValue,
          renewForHowLongPeriod: option.renewForHowLongPeriod,
          renewStartDt: option.renewStartDt,
          renewEndDt: option.renewEndDt,
          reasonableToRenew: option.reasonableToRenew,
        };
      },
    );
  }
  const otherPaymentsData = lease.form![LeaseForms.ADDL].otherPayments.map(
    (other: IPaymentModel) => {
      return {
        id: other.id,
        paymentName: other.paymentName,
        paymentOptionType: getPaymentOptionType(other),
        isFixed: other.isFixed,
        paymentDetail: other.paymentDetail.map((detail) => {
          return {
            id: detail.id,
            paymentAmt: detail.paymentAmt,
            paymentFrequency: detail.paymentFrequency,
            firstPaymentDt: detail.firstPaymentDt
              ? format(new Date(detail.firstPaymentDt), API_DATE_FORMAT)
              : '',
            lastPaymentDt: detail.lastPaymentDt
              ? format(new Date(detail.lastPaymentDt), API_DATE_FORMAT)
              : '',
            isIncrementPct: detail.isIncrementPct,
            incrementDecrementAmt: detail.incrementDecrementAmt,
          };
        }),
      };
    },
  );

  return {
    leaseId: leaseId && leaseId,
    userId: user.userId ? user.userId : '',
    clientId: user.company ? user.company!.clientId : '',
    isRenewal: lease.form![LeaseForms.DATE].isRenewal,
    header: {
      ...lease.form![LeaseForms.GENERAL],
      isCapital:
        lease.form![LeaseForms.GENERAL] &&
        lease.form![LeaseForms.GENERAL].isCapital !== 'help'
          ? lease.form![LeaseForms.GENERAL].isCapital
          : undefined,
      currency: user.company ? user.company.currency : '',
      fairValue: lease.form![LeaseForms.BASE].otherPayments[0].fairValue,
      leaseBeginDt:
        lease.form![LeaseForms.DATE] &&
        lease.form![LeaseForms.DATE].leaseBeginDt
          ? format(lease.form![LeaseForms.DATE].leaseBeginDt, API_DATE_FORMAT)
          : '',
      leaseEndDt:
        lease.form![LeaseForms.DATE] && lease.form![LeaseForms.DATE].leaseEndDt
          ? format(lease.form![LeaseForms.DATE].leaseEndDt, API_DATE_FORMAT)
          : '',
      usefulLifeMonths: lease.form![LeaseForms.DATE]
        ? lease.form![LeaseForms.DATE].usefulLifeMonths
        : '',
      canTransferTitle: lease.form![LeaseForms.OPTIONS]
        ? lease.form![LeaseForms.OPTIONS].canTransferTitle
        : false,
      isSpecializedUse: lease.form![LeaseForms.OPTIONS]
        ? lease.form![LeaseForms.OPTIONS].isSpecializedUse
        : false,
      leaseEndAlertDays: lease.form![LeaseForms.DATE]
        ? lease.form![LeaseForms.DATE].leaseEndAlertDays
        : [],
    },
    renewalOptions: optionsData,
    purchaseOption: !isEmptyObject(
      lease.form![LeaseForms.OPTIONS].purchaseOption,
    )
      ? {
          id: lease.form![LeaseForms.OPTIONS].purchaseOption.id,
          reasonableToPurchase: lease.form![LeaseForms.OPTIONS].purchaseOption
            .reasonableToPurchase,
          purchaseAmt: lease.form![LeaseForms.OPTIONS].purchaseOption
            .purchaseAmt
            ? lease.form![LeaseForms.OPTIONS].purchaseOption.purchaseAmt
            : null,
          canBargainPurchase: lease.form![LeaseForms.OPTIONS].purchaseOption
            .canBargainPurchase,
        }
      : null,
    terminateOption: !isEmptyObject(
      lease.form![LeaseForms.OPTIONS].terminateOption,
    )
      ? {
          id: lease.form![LeaseForms.OPTIONS].terminateOption.id,
          hasFee: lease.form![LeaseForms.OPTIONS].terminateOption.hasFee,
          feeAmt: lease.form![LeaseForms.OPTIONS].terminateOption.feeAmt,
        }
      : null,
    basePayment: {
      id: lease.form![LeaseForms.BASE].otherPayments[0].id,
      paymentName: 'BASE_PAYMENT',
      paymentOptionType: getPaymentOptionType(
        lease.form![LeaseForms.BASE].otherPayments[0],
      ),
      borrowingRate: lease.form![LeaseForms.BASE].otherPayments[0]
        .borrowingRate,
      transitionRate: lease.form![LeaseForms.BASE].otherPayments[0]
        .transitionRate,
      beginningPrepaidDeferredRent: Number(
        lease.form![LeaseForms.BASE].otherPayments[0]
          .beginningPrepaidDeferredRent,
      ),
      fairValue: lease.form![LeaseForms.BASE].otherPayments[0].fairValue,
      incentiveAmount:
        Number(lease.form![LeaseForms.BASE].otherPayments[0].incentiveAmount) ||
        null,
      isFixed: lease.form![LeaseForms.BASE].otherPayments[0].isFixed,
      paymentDetail: lease.form![
        LeaseForms.BASE
      ].otherPayments[0].paymentDetail.map((detail: IPaymentDetailModel) => {
        return {
          id: detail.id,
          paymentAmt: detail.paymentAmt,
          paymentFrequency: detail.paymentFrequency,
          firstPaymentDt: detail.firstPaymentDt
            ? format(new Date(detail.firstPaymentDt), API_DATE_FORMAT)
            : '',
          lastPaymentDt: detail.lastPaymentDt
            ? format(new Date(detail.lastPaymentDt), API_DATE_FORMAT)
            : '',
          isIncrementPct: detail.isIncrementPct,
          incrementDecrementAmt: detail.incrementDecrementAmt,
        };
      }),
    },
    otherPayments: otherPaymentsData,
  };
};
