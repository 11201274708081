import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Toggle } from '../../common';
import { hideModal, showModal } from '../../common/modal/actions';
import Tooltip from '../../common/tooltips/Tooltip';
import { IUserModel } from '../../interfaces';
import { IStore } from '../../store';
import { getFeatureValue } from '../plans/helpers';
import MoreUsersUpgradeLink from './MoreUsersUpgradeLink';

import { CheckIsMultiClientAdmin } from '../../common/utilities/_authHelpers';
import './GuestUserContainer.scss';

interface IGuestUserContainerProps {
  planAllowsGuestUser: boolean;
  userList?: IUserModel[];
  children: ReactNode;
  handleUpdateAllowGuestUserAccess: () => void;
  allowGuestUserAccess: boolean;
}

export default function GuestUserContainer({
  planAllowsGuestUser,
  handleUpdateAllowGuestUserAccess,
  allowGuestUserAccess,
  userList,
  children,
}: IGuestUserContainerProps): JSX.Element {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state: IStore) => {
    return state.user;
  });
  const features = loggedInUser.company.plan?.features;
  const maxUsers: number = features
    ? Number(getFeatureValue('Guest User', features))
    : 0;
  const maxUsersReached = userList && userList.length >= maxUsers;
  const isMultiClientAdmin = CheckIsMultiClientAdmin(loggedInUser);

  const showUpgradeModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'UPGRADE_TIER',
          modalProps: {
            open: true,
            hideModal: () => dispatch(hideModal()),
          },
        },
      }),
    );
  };

  return (
    <div className="guest_container">
      <div className="title">
        GUEST (read-only)
        <Tooltip header="Guest" tooltipPosition="centered-right">
          Guests have read-only access to your lease portfolio and can view
          lease details, journal entries, amortization schedules, and
          disclosures. A guest cannot add, amend, terminate or delete leases or
          change subscriptions or settings.
        </Tooltip>
      </div>
      {planAllowsGuestUser || isMultiClientAdmin ? (
        <>
          <div
            className={
              allowGuestUserAccess && maxUsersReached
                ? 'sub_container_guest'
                : 'sub_container_no_margin'
            }
          >
            <div className="toggle_row">
              <div className="title">Allow Guest Access?</div>
              <Toggle
                name="guestUsersActive"
                checked={allowGuestUserAccess}
                onChange={handleUpdateAllowGuestUserAccess}
              />
            </div>
            {allowGuestUserAccess && <Divider />}
            {children}
          </div>
          {allowGuestUserAccess && maxUsersReached ? (
            <MoreUsersUpgradeLink userType="guest" />
          ) : null}
        </>
      ) : (
        <div className="max-user-label">
          <label>Upgrade to a premium account to allow guest access.</label>
          <button className="button-request" onClick={showUpgradeModal}>
            Upgrade
          </button>
        </div>
      )}
    </div>
  );
}
