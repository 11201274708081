import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEnterpriseFeatureList } from '../../api';
import checkmark from '../../assets/checkmark.svg';
import Close from '../../assets/exit.svg';
import rsmClose from '../../assets/exitRSM.svg';
import rsmCheckmark from '../../assets/rsm-checkmark.svg';
import { Button } from '../../common/buttons';
import { AccentedHeader } from '../../common/headers';
import { useOutsideClick } from '../../common/hooks';
import { showModal } from '../../common/modal/actions';
import Modal from '../../common/modal/Modal';
import { useResourceContext } from '../../common/resource-context';
import { CheckIsMultiClientAdmin } from '../../common/utilities/_authHelpers';
import { applyRSMClassName, isEnvRSM } from '../../common/utilities/_helpers';
import {
  IPlanFeatureModel,
  IPlanModel,
  IUserModel,
  PlanTier,
} from '../../interfaces';
import { IStore } from '../../store';
import { getNextPlan } from '../dashboard/helpers/helpers';
import {
  checkIfUserIsOnMaxPlan,
  getFeatureDisplayText,
  getTierFeatureList,
} from '../plans/helpers';
import {
  getFeaturesByPlan,
  getPlanByName,
} from '../plansAndPricing/helpers/helpers';
import PlanCard from '../plansAndPricing/planCard/PlanCard';
import { SubscriptionModalPages } from './SubscriptionModal';
import './UpgradeModal.scss';

interface IUpgradeModalProps {
  open: boolean;
  hideModal: () => void;
  confirmAction: () => void;
  context?: any;
}

const UpgradeModal: React.FC<IUpgradeModalProps> = ({ hideModal }) => {
  const isRMS = isEnvRSM();
  const resources = useResourceContext();

  const [featureList, setFeatureList] = useState<IPlanFeatureModel[]>([]);

  const dispatch = useDispatch();

  const loggedInUser: IUserModel = useSelector((state: IStore) => {
    return state.user;
  });

  const isCPAAdmin = CheckIsMultiClientAdmin(loggedInUser);

  const plans = useSelector((store: IStore) => {
    if (isCPAAdmin) {
      return store.multiClientPlans;
    }
    return store.plans;
  });

  const currentAdminPlan = useSelector(
    (state: IStore) => state.currentAdminPlan,
  );

  const nextUpgradePlan = getNextPlan(
    loggedInUser.company.plan,
    plans,
    isCPAAdmin,
  );

  const [currentPlanInView, setCurrentPlanInView] = useState<
    IPlanModel | undefined
  >(nextUpgradePlan);

  const showSubscriptionModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'SUBSCRIPTIONS',
          modalProps: {
            open: true,
            initialSubscriptionModalPage: SubscriptionModalPages.payment,
            initialPlanFeatures: getFeaturesByPlan(
              currentPlanInView ? currentPlanInView.name : '',
              plans,
            ),
            initialSelectedPlan: getPlanByName(
              currentPlanInView ? currentPlanInView.name : '',
              plans,
            ),
          },
        },
      }),
    );
  };

  const showTierPageModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'SUBSCRIPTIONS',
          modalProps: {
            open: true,
            hideModal,
          },
        },
      }),
    );
  };

  const upgradeModalRef = useRef(null);

  useOutsideClick(upgradeModalRef, hideModal);

  const userIsOnMaxPlan = useMemo(() => {
    return isCPAAdmin
      ? currentAdminPlan.tier !== PlanTier.BASIC
      : checkIfUserIsOnMaxPlan(loggedInUser, plans);
  }, [currentAdminPlan.tier, isCPAAdmin, loggedInUser, plans]);

  const submitModal = () => {
    const enterpriseUrl = resources.text('EnterprisePricingUrl');
    if (userIsOnMaxPlan) {
      window.open(enterpriseUrl, '_blank');
    } else {
      showTierPageModal();
    }
  };

  useEffect(() => {
    const getFeatureList = async () => {
      let features = [];

      features = userIsOnMaxPlan
        ? await getEnterpriseFeatureList()
        : getTierFeatureList(PlanTier.PREMIUM, plans);

      setFeatureList(features);
    };

    getFeatureList();
  }, [userIsOnMaxPlan, plans]);

  if (userIsOnMaxPlan) {
    return (
      <Modal
        open={true}
        modalSize="vertical"
        neutralButtonText="Close"
        positiveButtonText={'Request Pricing'}
        onConfirm={submitModal}
        hideModal={hideModal}
        contentClass="upgrade-modal"
        contentFooter={
          <div className="upgrade-modal-footer-image-wrapper">
            <img
              src={resources.img('Upgrade_Image')}
              className="car"
              alt="car"
            />
          </div>
        }
      >
        <AccentedHeader color="blue" centered>
          Contact us about <br /> LeaseQuery
        </AccentedHeader>
        <div className="sub-title">Start enjoying these amazing features!</div>
        <div className="modal-text-content">
          <div className="upgrade-modal-text">
            {featureList.map((feature: IPlanFeatureModel) => {
              return (
                <div className="tier-feature-modal" key={feature.id}>
                  <span className="check-mark">
                    {' '}
                    <img
                      src={isRMS ? rsmCheckmark : checkmark}
                      alt="checkmark"
                    />
                  </span>
                  <span className="feature-item">
                    {getFeatureDisplayText(feature)}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      open={true}
      modalSize="vertical"
      onConfirm={submitModal}
      hideModal={hideModal}
      contentClass={applyRSMClassName('upgrade-modal')}
      customCloseButton={<img src={isRMS ? rsmClose : Close} alt="Close" />}
      contentFooter={
        <div className="upgrade-modal-footer-image-wrapper">
          <img src={resources.img('Upgrade_Image')} className="car" alt="car" />
        </div>
      }
      ref={upgradeModalRef}
    >
      <div className="title-text">Upgrade now for more Features</div>
      <div className="card-wrapper">
        <PlanCard
          selectedPlan={nextUpgradePlan!}
          setSelectedPlan={setCurrentPlanInView}
        >
          <div className="button-wrapper">
            <Button
              block={true}
              buttonText={
                CheckIsMultiClientAdmin(loggedInUser) ? 'Buy Now' : 'Upgrade'
              }
              buttonType="positive"
              onClick={showSubscriptionModal}
            />
          </div>
        </PlanCard>
      </div>
      <div className="view-all-plans">
        <a
          className={applyRSMClassName('info-email pricing-link')}
          href={
            CheckIsMultiClientAdmin(loggedInUser)
              ? '/multicompany-pricing'
              : '/pricing'
          }
        >
          View all plans and feature details side-by-side
        </a>
      </div>
    </Modal>
  );
};

export default UpgradeModal;
