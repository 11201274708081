import React, { FunctionComponent } from 'react';
import ModalRoot from '../../common/modal/ModalRoot';
import { useFeatureFlags } from '../../featureQuery';
import { FeatureFlagKeys } from '../../interfaces';
import Footer from './Footer';
import Header from './Header';
import PlansPage from './PlansPage';

const PlansAndPricing: FunctionComponent = () => {
  const { getSingleFlag } = useFeatureFlags();
  const guruPlanPricingFlag = getSingleFlag(FeatureFlagKeys.PlansAndPricing);
  return guruPlanPricingFlag ? (
    <>
      <Header />
      <PlansPage />
      <Footer />
      <ModalRoot />
    </>
  ) : null;
};

export default PlansAndPricing;
