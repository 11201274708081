import * as yup from 'yup';

export const PasswordSchema = yup
  .string()
  .label('Password')
  .min(8)
  .matches(
    /[A-Z]/,
    'Password must contain an uppercase character. Password must contain at least one: uppercase, lowercase, number & special character.',
  )
  .matches(
    /[a-z]/,
    'Password must contain an lowercase character. Password must contain at least one: uppercase, lowercase, number & special character.',
  )
  .matches(
    /[0-9]/,
    'Password must contain a number. Password must contain at least one: uppercase, lowercase, number & special character.',
  )
  .matches(
    /[-!@#$%^&*()_=+\][{}|;:,<.>/?]/,
    'Password must contain a special character. Password must contain at least one: uppercase, lowercase, number & special character.',
  );

export const PasswordSchemaNoMessage = yup
  .string()
  .label('Password')
  .min(8)
  .max(20)
  .matches(/[A-Z]/, 'Password does not meet requirements')
  .matches(/[a-z]/, 'Password does not meet requirements')
  .matches(/[0-9]/, 'Password does not meet requirements')
  .matches(
    /[-!@#$%^&*()_=+\][{}|;:,<.>/?]/,
    'Password does not meet requirements',
  );
