import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import './FilterExpandSection.scss';

const FilterExpandSection: FunctionComponent<IExpandSectionProps> = ({
  id,
  children,
  label,
  hasActiveFilter,
  isOpenAndActive,
  openSesame,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // force section open
    if (openSesame && hasActiveFilter) {
      setIsOpen(true);
    }
  }, [openSesame, hasActiveFilter]);

  useEffect(() => {
    if (isOpen && hasActiveFilter) {
      isOpenAndActive(true);
    }
    if (!isOpen || !hasActiveFilter) {
      isOpenAndActive(false);
    }
  }, [isOpen, hasActiveFilter, isOpenAndActive]);

  return (
    <div
      className={`filter-section-wrap ${isOpen && 'filter-section-wrap-open'}`}
    >
      <div
        className={`highlight-left ${
          hasActiveFilter
            ? isOpen
              ? `highlight-left--blue`
              : `highlight-left--blue`
            : isOpen
            ? `highlight-left--white`
            : null
        }`}
      />
      <div
        className="filter-clicky"
        onClick={() => setIsOpen((current) => !current)}
        id={id}
      >
        <div
          className={`filter-label ${
            hasActiveFilter ? `filter-active` : null
          } ${isOpen ? `filter-open` : null}`}
        >
          {label}
        </div>
        <div
          className={`filter-caret ${
            isOpen ? 'filter-caret--open' : 'filter-caret--closed'
          }`}
        />
      </div>
      <div />
      {isOpen && <div className="filter-section-content">{children}</div>}
    </div>
  );
};

interface IExpandSectionProps {
  id?: string;
  children: ReactNode;
  label?: string;
  hasActiveFilter: boolean;
  openSesame: boolean | number;
  isOpenAndActive: (isOpen: boolean) => void;
}

export default FilterExpandSection;
