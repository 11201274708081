import { IPaymentMethodModel } from '../interfaces';
import { formatError, stripeApi, taxApi } from './api';

export async function makeStripePayment(stripeParams: IPaymentMethodModel) {
  let response;
  const userToken = sessionStorage.getItem('userToken');
  const rawResponse = await stripeApi.post(
    '/SubscribeToPlan',
    {
      ...stripeParams,
    },
    { headers: { Authorization: `${userToken}` } },
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  response = rawResponse.data;

  return response;
}

export async function editPaymentMethod(paymentMethod: IPaymentMethodModel) {
  let response;
  const userToken = sessionStorage.getItem('userToken');
  const rawResponse = await stripeApi.put('/EditPaymentMethod', paymentMethod, {
    headers: { Authorization: `${userToken}` },
  });

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  response = rawResponse.data;

  return response;
}

export async function getCompanySubscription() {
  let response;
  const userToken = sessionStorage.getItem('userToken');
  const rawResponse = await stripeApi.get('/GetCurrentCompanySubscription', {
    headers: { Authorization: `${userToken}` },
  });

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  response = rawResponse.data;

  return response;
}

export async function getSubscriptionSettings() {
  let response;
  const userToken = sessionStorage.getItem('userToken');
  const rawResponse = await stripeApi.get('/RetrievePaymentMethod', {
    headers: { Authorization: `${userToken}` },
  });

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  response = rawResponse.data;

  return response;
}

export async function getProratedPreview(
  subscriptionId: string,
  customerId: string,
  planId: string,
) {
  const userToken = sessionStorage.getItem('userToken');

  const rawResponse = await stripeApi.get(
    `/GetProratedPreview/${subscriptionId}/${customerId}/${planId}`,
    {
      headers: { Authorization: `${userToken}` },
    },
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

// Get prorated price based on current date
export async function getProratedPriceForCurrentDate(
  tierToUpgradeStripeId: string,
  currentProrationDate: Date,
) {
  const userToken = sessionStorage.getItem('userToken');

  const rawResponse = await stripeApi.get('/GetProrationForCustomDate', {
    headers: { Authorization: `${userToken}` },
    params: {
      stripePlanId: tierToUpgradeStripeId,
      proparationDate: currentProrationDate,
    },
  });

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function getProratedPrice(tierToUpgradeStripeId: string) {
  const userToken = sessionStorage.getItem('userToken');

  const rawResponse = await stripeApi.get(
    `/GetProration?newStripePlan=${tierToUpgradeStripeId}`,
    {
      headers: { Authorization: `${userToken}` },
    },
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function generateTax(
  amount: any,
  postal: string,
  country: string,
) {
  const userToken = sessionStorage.getItem('userToken');

  const rawResponse = await taxApi
    .get(
      `/taxcalculation?Amount=${amount}&Postal=${postal}&Country=${country}`,
      {
        headers: { Authorization: `${userToken}` },
      },
    )
    .then((res) => {
      return { tax: res.data.totalTax, status: res.status };
    })
    .catch((error) => {
      if (error.response.status >= 300) {
        return {
          error: `Couldn't calculate tax with the entered contact information`,
          status: error.response.status,
        };
      }
    });
  return rawResponse;
}

export async function getCountryList() {
  const userToken = sessionStorage.getItem('userToken');

  const rawResponse = await taxApi.get(`/listcountries`, {
    headers: { Authorization: `${userToken}` },
  });

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}
