import { Dispatch } from 'redux';
import {
  CHANGE_MODAL_PROP,
  HIDE_MODAL,
  HIDE_STEPPED_MODAL,
  IModalAction,
  IModalProps,
  SHOW_MODAL,
  SHOW_STEPPED_MODAL,
} from './types';

export const showModal = ({ modal }: IModalAction) => (dispatch: Dispatch) => {
  dispatch({
    type: SHOW_MODAL,
    modal,
  });
};

export const showModal2 = ({ modal }: IModalAction) => ({
  type: SHOW_MODAL,
  payload: { modal },
});

export const hideModal = () => (dispatch: Dispatch) => {
  dispatch({
    type: HIDE_MODAL,
  });
};

export const showSteppedModal = ({ modal }: IModalAction) => (
  dispatch: Dispatch,
) => {
  dispatch({
    type: SHOW_STEPPED_MODAL,
    modal,
  });
};

export const hideSteppedModal = () => (dispatch: Dispatch) => {
  dispatch({
    type: HIDE_STEPPED_MODAL,
  });
};

export const changeModalProp = <T extends IModalProps, K extends keyof T>(
  property: K,
  value: T[K],
) => (dispatch: Dispatch) => {
  dispatch({
    type: CHANGE_MODAL_PROP,
    property,
    value,
  });
};
