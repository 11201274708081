import React, { Fragment, FunctionComponent } from 'react';

import './Divider.scss';

const Divider: FunctionComponent = () => (
  <Fragment>
    <hr className="form-divider" />
  </Fragment>
);

export default Divider;
