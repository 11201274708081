import { compareAsc, format } from 'date-fns';
import differenceInMonths from 'date-fns/differenceInMonths';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import React, { FunctionComponent, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { RouteComponentProps } from 'react-router-dom';
import { getDisclosureRpt } from '../../api';
import { monthOptions } from '../../common/utilities/_constants';
import { API_DATE_FORMAT } from '../../common/utilities/_dates';
import {
  ICompanyModel,
  IDisclosuresFilters,
  IDisclosuresModel,
} from '../../interfaces';
import './Disclosures.scss';
import DisclosuresFilters from './DisclosuresFilters';
import DisclosuresTable from './DisclosuresTable';

interface IDisclosuresProps extends RouteComponentProps {
  company: ICompanyModel | undefined;
}

const Disclosures: FunctionComponent<IDisclosuresProps> = ({ company }) => {
  const [filters, setFilters] = useState<IDisclosuresFilters>({
    startMonth: undefined,
    endMonth: undefined,
    year: undefined,
  });
  const [valid, setValid] = useState<boolean>(false);
  const [firstLoad, setfirstLoad] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IDisclosuresModel[]>([]);
  const [isTransitionInFuture, setIsTransitionInFuture] = useState<boolean>(
    false,
  );
  const [selectedYears, setSelectedYears] = useState<{
    startYear: number;
    endYear: number;
  }>();

  useEffect(() => {
    // compares transition date to current date and checks for ascending order
    if (company && company.transitionMonth && company.transitionYear) {
      const transitionInPast = compareAsc(
        new Date(),
        new Date(company.transitionYear, company.transitionMonth),
      );

      if (transitionInPast === 1) {
        setIsTransitionInFuture(false);
      } else {
        setIsTransitionInFuture(true);
      }
    }
  }, [company]);

  const fetchEntries = async () => {
    try {
      if (
        company &&
        company.clientId &&
        filters.year &&
        filters.startMonth &&
        filters.endMonth
      ) {
        setLoading(true);

        TagManager.dataLayer({
          dataLayer: {
            event: 'disclosure_report_interaction',
            disclosure_report_action: 'Run disclosure report',
          },
        });

        const startDate = new Date(filters.year, filters.startMonth - 1);
        startDate.setDate(1);

        let endDate = new Date(filters.year, filters.endMonth - 1);
        endDate.setDate(1);

        const difference = differenceInMonths(startDate, endDate);

        if (difference > 0) {
          endDate.setFullYear(filters.year + 1);
        }

        endDate = lastDayOfMonth(endDate);

        const startDateString = format(startDate, API_DATE_FORMAT);
        const endDateString = format(endDate, API_DATE_FORMAT);

        const disclosures: IDisclosuresModel[] = await getDisclosureRpt(
          company.clientId,
          company.jurisdiction === 'IFRS',
          startDateString,
          endDateString,
        );

        setData(disclosures);
        setSelectedYears({
          startYear: startDate.getFullYear(),
          endYear: endDate.getFullYear(),
        });
      }
    } catch (error) {
      alert(`There was an issue loading your journal entry selection`);
    }
    setLoading(false);
    if (firstLoad) {
      setfirstLoad(false);
    }
  };

  const onFiltersUpdate = (value: IDisclosuresFilters) => {
    setFilters(value);
    setValid(isFormValid(value));
  };

  const isFormValid = (value: IDisclosuresFilters) =>
    !Object.values(value).some((val) => val === '' || val === undefined);

  const getBeginYear = () => {
    let baseDate = new Date();

    if (company && company.transitionMonth && company.transitionYear) {
      const beginDate = new Date(
        company.transitionYear,
        company.transitionMonth - 1,
      );

      if (baseDate.getFullYear() > beginDate.getFullYear()) {
        baseDate = beginDate;
      }
    }

    return new Date(baseDate).getFullYear();
  };

  const getYearRange = () => {
    let yearRangeString = '';

    if (selectedYears) {
      yearRangeString = selectedYears.startYear.toString();

      if (selectedYears.startYear !== selectedYears.endYear) {
        yearRangeString = `${yearRangeString} - ${selectedYears.endYear}`;
      }
    }

    return yearRangeString;
  };

  const generateDisplayMonthYear = () => {
    if (company && company.transitionMonth && company.transitionYear) {
      const month = monthOptions[company.transitionMonth - 1].label;
      const year = company.transitionYear;
      return `of ${month} ${year}`;
    }
  };

  return (
    <div className="disclosures-wrapper">
      <div className="disclosures-filter-section">
        <div className="disclosures-header">Complete Disclosures Report</div>
        <div className="disclosures-sub-header">
          This report can only be run after your transition date{' '}
          {generateDisplayMonthYear()} and for a maximum of 12 months
        </div>
        <DisclosuresFilters
          value={filters}
          loading={loading}
          onChange={onFiltersUpdate}
          beginYear={getBeginYear()}
          endYear={new Date().getFullYear()}
          transitionMonth={company?.transitionMonth || 1}
          onExecute={fetchEntries}
          valid={valid}
          transitionIsInFuture={isTransitionInFuture}
        />
      </div>
      <div className="disclosures-data-section">
        {data.length ? (
          <DisclosuresTable
            yearRange={getYearRange()}
            data={data}
            jurisdiction={company && company.jurisdiction}
            // exportDisclosure={handleExportDisclosure}
          />
        ) : firstLoad ? null : (
          <div>No data for this selection</div>
        )}
      </div>
    </div>
  );
};

export default Disclosures;
