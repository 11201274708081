import isBefore from 'date-fns/isBefore';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask';

import {
  FilterTypes,
  IFilterCategories,
  IMinMaxModel,
} from '../../features/dashboard/Dashboard';
import { formatRemoveCommas } from '../formatters/FormatInputCommas';

import './FilterSelection.scss';

interface IFilterSectionProps {
  title: string;
  filterName: keyof IFilterCategories;
  onFilterSubmit: (
    filterValues: IMinMaxModel,
    filterName: keyof IFilterCategories,
    filterType: FilterTypes,
  ) => void;
  filterType: FilterTypes;
}

const initialFilterValueState: IMinMaxModel = {
  minValue: '',
  maxValue: '',
};

const FilterSelection = ({
  title,
  filterName,
  onFilterSubmit,
  filterType,
}: IFilterSectionProps) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    n: string,
    withCommas?: boolean,
  ) => {
    if (withCommas) {
      e.target.value = formatRemoveCommas(e) || e.target.value;
    }
    const newValues = { ...filterValues, [n]: e.target.value };
    setFilterValues(newValues);
  };

  const [filterValues, setFilterValues] = useState<IMinMaxModel>(
    initialFilterValueState,
  );

  const [errorMessage, setError] = useState<string>('');

  const clearFilterValues = () => {
    setFilterValues(initialFilterValueState);
  };

  const checkForFieldErrors = (values: IMinMaxModel): boolean => {
    const { minValue, maxValue } = values;
    let error = '';

    // check for null values in both fields
    if (!minValue || !maxValue) {
      error = 'Please enter values for both fields.';
    } else {
      // check for filter type
      switch (filterType) {
        case 'minMax':
          const minNumValue = Number(minValue);
          const maxNumValue = Number(maxValue);

          // only numbers
          if (isNaN(minNumValue) || isNaN(maxNumValue)) {
            error = 'Use numbers only';
            // check if max is greater than min
          } else if (minNumValue > maxNumValue) {
            error = 'Min cannot be larger than Max';
          }
          break;
        case 'date':
          const minDate = Date.parse(minValue);
          const maxDate = Date.parse(maxValue);

          // if the date isn't formatted correctly, NaN will be returned from Date.parse
          if (isNaN(minDate) || isNaN(maxDate)) {
            error = 'Use the format MM/DD/YYYY';
            // check if max date is after min date
          } else if (isBefore(maxDate, minDate)) {
            error = 'Date range must be in the correct order';
          }
          break;
      }
    }

    setError(error);
    return !!error;
  };

  const onSubmit = () => {
    const fieldsHaveError = checkForFieldErrors(filterValues);
    if (!fieldsHaveError) {
      clearFilterValues();
      onFilterSubmit(filterValues, filterName, filterType);
    }
  };

  if (filterType === 'minMax') {
    return (
      <div className="filter-wrapper">
        <div className="title">{title}</div>
        <div className="range">
          <NumberFormat
            placeholder="Min"
            value={filterValues.minValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e, 'minValue', true)
            }
            thousandSeparator={true}
            allowNegative={false}
          />
          <div className="spacer" />
          <NumberFormat
            placeholder="Max"
            value={filterValues.maxValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e, 'maxValue', true)
            }
            thousandSeparator={true}
            allowNegative={false}
          />
        </div>
        <div className="error">{errorMessage}</div>
        <button className="filter-button" onClick={() => onSubmit()}>
          Add Filter
        </button>
      </div>
    );
  } else {
    return (
      <div className="filter-wrapper">
        <div className="title">{title}</div>
        <div className="range">
          <MaskedInput
            placeholder={'MM/DD/YYYY'}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            value={filterValues.minValue !== null ? filterValues.minValue : ''}
            onChange={(e) => handleChange(e, 'minValue')}
          />
          <div className="spacer" />
          <MaskedInput
            placeholder={'MM/DD/YYYY'}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            value={filterValues.maxValue !== null ? filterValues.maxValue : ''}
            onChange={(e) => handleChange(e, 'maxValue')}
          />
        </div>
        <div className="error">{errorMessage}</div>
        <button className="filter-button" onClick={() => onSubmit()}>
          Add Filter
        </button>
      </div>
    );
  }
};
export default FilterSelection;
