import { format } from 'date-fns';
import {
  DEFAULT_DATE_FORMAT,
  yearFromNow,
} from '../../common/utilities/_dates';

export const renewalDate = (): string => {
  return format(yearFromNow(), DEFAULT_DATE_FORMAT);
};

export const formatToNumber = (price: string): number => {
  return parseInt(price.slice(1), 10);
};

export const displayCurrency = (value: number): string => {
  const formattedValue = (Math.round(value * 100) / 100).toFixed(2);
  const currencyString = '$ ' + formattedValue + ' USD';
  return currencyString;
};

export const toTitleCase = (str: string): string => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const formatPriceWithDecimal = (price: number): number | undefined => {
  if (!price) {
    return;
  }

  const decimalPrice = (price /= 100);

  return decimalPrice;
};
