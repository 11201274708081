import format from 'date-fns/format';
import React, { FunctionComponent } from 'react';
import DatePicker from 'react-datepicker';

import calendarIcon from '../../assets/calendar-icon.svg';
import rsmCalendarIcon from '../../assets/rsm-calendar-icon.svg';
import { useWindowSize } from '../hooks';
import { MOBILE_WIDTH_SIZE } from '../utilities/_constants';
import { DEFAULT_DATE_FORMAT } from '../utilities/_dates';

import 'react-datepicker/dist/react-datepicker.css';
import MaskedInput from 'react-text-mask';
import './Datepicker.scss';

interface IDatepickerProps {
  onChange: (date: Date | null | undefined, name: string) => void;
  name: string;
  selectedDate: Date | null | undefined;
  id?: string;
  placeholderText?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  withPortal?: boolean;
  fullWidth?: boolean;
  preventOverflow?: boolean;
  popperPlacement?: string;
}

const Datepicker: FunctionComponent<IDatepickerProps> = ({
  onChange,
  name,
  id,
  selectedDate,
  placeholderText,
  label,
  required,
  disabled,
  error,
  errorMessage,
  withPortal,
  fullWidth,
  preventOverflow,
  popperPlacement,
}) => {
  const windowWidth = useWindowSize();

  const handleNativeClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedDateString = format(
      new Date(event.target.value),
      DEFAULT_DATE_FORMAT,
    );

    if (formattedDateString) {
      const date = new Date(formattedDateString);

      if (date) {
        return onChange(date, name);
      }
    }
  };

  return (
    <div className={`datepicker-wrapper ${fullWidth ? 'full-width' : ''}`}>
      {label ? (
        <label
          htmlFor={id || name}
          className={`datepicker-label ${disabled ? 'disabled' : ''} ${
            required && !disabled ? 'required' : ''
          }`}
        >
          {label}
        </label>
      ) : null}
      <div className="datepicker-input-wrapper">
        {windowWidth.outerWidth >= MOBILE_WIDTH_SIZE ? (
          <>
            <DatePicker
              onChange={(date: Date | null | undefined) => onChange(date, name)}
              selected={selectedDate}
              name={name}
              id={id || name}
              placeholderText={disabled ? '' : placeholderText}
              autoComplete="off"
              required={required}
              disabled={disabled}
              showMonthDropdown
              showYearDropdown
              dropdownMode={'select'}
              className={`datepicker-input ${disabled ? 'disabled' : ''}`}
              withPortal={withPortal}
              popperPlacement={
                popperPlacement
                  ? popperPlacement
                  : preventOverflow
                  ? 'top'
                  : undefined
              }
              popperModifiers={
                preventOverflow
                  ? {
                      offset: { offset: '50%' },
                    }
                  : undefined
              }
              customInput={
                <MaskedInput
                  mask={[
                    /\d/,
                    /\d/,
                    '/',
                    /\d/,
                    /\d/,
                    '/',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                />
              }
            />
            <div
              className={`calendar-icon-container ${
                disabled ? 'disabled' : ''
              }`}
            >
              <img
                src={
                  process.env.REACT_APP_ENABLE_RSM === 'true'
                    ? rsmCalendarIcon
                    : calendarIcon
                }
                alt="calendar-icon"
                className="calendar-icon"
              />
            </div>
          </>
        ) : (
          <input
            type="date"
            onChange={handleNativeClick}
            className="datepicker-input"
            value={selectedDate ? format(selectedDate, 'YYYY-MM-DD') : ''}
            required={required}
            disabled={disabled}
          />
        )}
      </div>
      {error && (
        <span className="form-input-error-message">
          {errorMessage || 'There is an issue with this field'}
        </span>
      )}
    </div>
  );
};

export default Datepicker;
