import React, { FunctionComponent } from 'react';

import Modal from '../../common/modal/Modal';

import './ExternalToolsConfirmationModal.scss';

interface IExternalToolsConfirmationModalProps {
  open: boolean;
  hideModal: () => void;
  confirmAction: () => void;
}

const ExternalToolsConfirmationModal: FunctionComponent<IExternalToolsConfirmationModalProps> = ({
  open,
  hideModal,
  confirmAction,
}) => (
  <Modal
    open={open}
    modalSize="small"
    onConfirm={confirmAction}
    hideModal={hideModal}
    positiveButtonText="Yes"
    neutralButtonText="Cancel"
  >
    <div style={{ textAlign: 'center' }}>
      <p className="disconnect-confirmation">
        Are you sure you want to disconnect?
      </p>
    </div>
  </Modal>
);

export default ExternalToolsConfirmationModal;
