import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { Form, Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { editPaymentMethod } from '../../api';
import visa from '../../assets/1.png';
import discover from '../../assets/14.png';
import ms from '../../assets/2.png';
import american from '../../assets/22.png';
import lock from '../../assets/lock.svg';
import { Divider, Input, Section } from '../../common';
import FormError from '../../common/form-error/FormError';
import FormGrid from '../../common/form-grid/FormGrid';
import { hideModal, showModal } from '../../common/modal/actions';
import Modal from '../../common/modal/Modal';
import { processErrorMessage } from '../../common/utilities/_helpers';
import {
  ICompanyModel,
  IPaymentMethodModel,
  ISubscriptionSettings,
  IUserModel,
} from '../../interfaces';
import { IStore } from '../../store';
import { updateUserCompanySettings } from '../auth/actions';
import './UpdatePaymentModal.scss';

interface IUpdatePaymentFormState {
  addressLine1: string;
  city: string;
  zip: string;
}

interface IUpdatePaymentProps {
  context: ISubscriptionSettings;
  title: string;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || '');

const UpdatePaymentModal: React.FC<IUpdatePaymentProps> = ({ context }) => {
  const formikRef = useRef<Formik<IUpdatePaymentFormState>>(null);
  const loggedInUser: IUserModel = useSelector((state: IStore) => state.user);
  const [processing, setProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const companySubscription = useSelector((state: IStore) => {
    return state.user.company?.subscription;
  });
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const updatePaymentFormSchema = yup.object().shape({
    addressLine1: yup
      .string()
      .label('Address')
      .required(),
    city: yup
      .string()
      .label('City')
      .required(),
    zip: yup
      .string()
      .label('Zip')
      .required(),
  });

  const handBack = () => {
    dispatch(hideModal());
    dispatch(
      showModal({
        modal: {
          modalType: 'SUBSCRIPTION_SETTINGS',
          modalProps: {
            open: true,
            hideModal,
          },
        },
      }),
    );
  };

  const submitModal = () => {
    if (formikRef) {
      formikRef.current!.submitForm();
    }
  };

  const showConfirmationModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'UPDATE_PAYMENT_CONFIRMATION',
          modalProps: {
            open: true,
            hideModal,
          },
        },
      }),
    );
  };

  const handleSubmit = async (values: IUpdatePaymentFormState) => {
    setErrorMessage('');
    setProcessing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: loggedInUser.company.companyName,
          email: loggedInUser.email,
          address: {
            city: values.city,
            line1: values.addressLine1,
            postal_code: values.zip,
          },
        },
      });

      if (error || !paymentMethod) {
        setErrorMessage('There was an issue with your payment method');
        setProcessing(false);
        return;
      } else {
        const stripePaymentMethod: IPaymentMethodModel = {
          paymentMethodId: paymentMethod.id,
          companyEmail: loggedInUser.email,
          companyName: loggedInUser.company.companyName,
          companyAddress: values.addressLine1,
          city: values.city,
          zip: values.zip,
        };

        try {
          await editPaymentMethod(stripePaymentMethod);
          // update company
          const updatedCompany: ICompanyModel = {
            ...loggedInUser.company,
            subscription: {
              addressModel: {
                companyAddress: values.addressLine1,
                city: values.city,
                zip: values.zip,
              },
            },
          };
          dispatch(updateUserCompanySettings(updatedCompany));
          dispatch(hideModal());
          showConfirmationModal();
        } catch (error) {
          setErrorMessage(
            'Oops! Payment was unsuccessful. ' + processErrorMessage(error),
          );
        }
      }

      setProcessing(false);
    }
  };

  const handleStripeChange = (event: StripeCardElementChangeEvent) => {
    if (event.error) {
      setErrorMessage(event.error.message);
    } else {
      setErrorMessage('');
    }
  };

  const handleZipChange = (
    formProps: FormikProps<IUpdatePaymentFormState>,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.target.value = event.target.value.replace(/\D/g, '');
    formProps.handleChange(event);
  };

  return (
    <Modal
      title="Change Payment Details"
      open={true}
      modalSize="small"
      onCancel={handBack}
      onConfirm={submitModal}
      positiveButtonText="Save"
      neutralButtonText="Back"
      processing={processing}
    >
      <Formik
        ref={formikRef}
        initialValues={{
          addressLine1: companySubscription
            ? companySubscription.addressModel?.companyAddress
            : '',

          city: companySubscription
            ? companySubscription.addressModel?.city
            : '',
          zip: companySubscription ? companySubscription.addressModel?.zip : '',
        }}
        onSubmit={handleSubmit}
        validationSchema={updatePaymentFormSchema}
      >
        {(formProps: FormikProps<IUpdatePaymentFormState>) => {
          return (
            <Form noValidate={true} className="update-payment-wrapper">
              <div className="update-payment">
                <Section label="">
                  <Input
                    name="addressLine1"
                    label="Company Address"
                    onChange={formProps.handleChange}
                    value={formProps.values.addressLine1}
                    required
                  />
                  <FormGrid colWidth={3}>
                    <Input
                      id="city"
                      label="City"
                      name="city"
                      onChange={formProps.handleChange}
                      value={formProps.values.city}
                      required
                    />
                    <Input
                      label="Zip / Postal Code"
                      name="zip"
                      onChange={(e: React.ChangeEvent<any>) => {
                        handleZipChange(formProps, e);
                      }}
                      value={formProps.values.zip}
                      maxLength={5}
                      required
                    />
                  </FormGrid>
                </Section>
                <Divider />
                <Section label="">
                  <div className="card-element">
                    <CardElement
                      options={{
                        style: {
                          base: {
                            color: '#32325d',
                            fontFamily: '"Lato", sans-serif',
                            fontSmoothing: 'antialiased',
                            fontSize: '16px',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: '#fa755a',
                            iconColor: '#fa755a',
                          },
                        },
                      }}
                      onChange={handleStripeChange}
                    />
                    {errorMessage ? (
                      <FormError
                        error={!!errorMessage}
                        errorMessage={errorMessage}
                      />
                    ) : null}
                  </div>

                  <div className="secure">
                    <div className="secure-lock">
                      <img src={lock} alt="card" className="lock-image" />
                      <span>Your payment data is encrypted and secure.</span>
                    </div>
                    <div className="secure-card-images">
                      <img src={visa} alt="card" className="payment-logo" />
                      <img src={ms} alt="card" className="payment-logo" />
                      <img src={discover} alt="card" className="payment-logo" />
                      <img src={american} alt="card" className="payment-logo" />
                    </div>
                  </div>
                </Section>
              </div>
              <div className="message">
                This is NOT a charge. An update to your current subscription
                will be reflected on your next billing date.
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const WrappedPaymentPage: React.FC<IUpdatePaymentProps> = (props) => (
  <Elements stripe={stripePromise}>
    <UpdatePaymentModal {...props} />
  </Elements>
);

export default WrappedPaymentPage;
