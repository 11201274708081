import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { updateLease } from '../../../api';
import { Divider } from '../../../common';
import { hideModal } from '../../../common/modal/actions';
import Modal from '../../../common/modal/Modal';
import { ICapOpTestResultsModel } from '../../../interfaces';
import {
  clearAllForms,
  setReloadLeaseDetails,
  setReloadLeases,
} from '../actions';
import { IsCapitalValues } from '../add-lease-forms/GeneralForm';

import './CapOpResultsModal.scss';

interface ICapOpResultsModalProps {
  open: boolean;
  otherProps: {
    capOpTestResults: ICapOpTestResultsModel;
    isCapital: IsCapitalValues;
    leaseId: number;
    leaseData: any;
  };
}

export const getCapOpResultWording = (
  capOpTestKey: keyof ICapOpTestResultsModel,
  capOpTestResult: number,
): string => {
  switch (capOpTestKey) {
    case 'canTransfer':
      return capOpTestResult
        ? 'Title transfer at lease end'
        : 'There is no title transfer at lease end';
    case 'hasBargainPurchase':
      return capOpTestResult
        ? 'Bargain purchase option was selected'
        : 'There is no bargain purchase option';
    case 'usefulLife':
      return capOpTestResult
        ? 'The lease term is 75% or greater than the useful life of the asset'
        : 'The lease term is less than 75% of the useful life of the asset';
    case 'fairValue':
      return capOpTestResult
        ? 'The present value of the minimum lease payments is at least 90% of the fair value of the asset'
        : 'The present value of the minimum lease payments is less than 90% of the fair value of the asset';
    case 'isSpecialized':
      return capOpTestResult
        ? 'The asset is specialized in nature'
        : 'The asset is not specialized in nature';
    default:
      return '';
  }
};

export const isResultCapital = (
  usefulLife: string,
  fairValue: string,
  results: ICapOpTestResultsModel,
) => {
  if (usefulLife === undefined || usefulLife === '' || usefulLife === null) {
    delete results.usefulLife;
  }

  if (fairValue === undefined || fairValue === '' || fairValue === null) {
    delete results.fairValue;
  }

  return Object.values(results).some((result) => result === 1);
};

export const canShowResults = (
  results: ICapOpTestResultsModel,
  isCapital: boolean,
) => (Object.keys(results).length !== 5 ? isCapital : true);

const CapOpResultsModal: FunctionComponent<ICapOpResultsModalProps> = ({
  open,
  otherProps: { capOpTestResults, leaseData, isCapital, leaseId },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isProcessing, setProcessing] = useState(false);

  const {
    header: { usefulLifeMonths, fairValue },
  } = leaseData;

  const resultIsCapital = isResultCapital(
    usefulLifeMonths,
    fairValue,
    capOpTestResults,
  );

  const handleSaveAsClick = async () => {
    await updateLease(
      {
        ...leaseData,
        leaseId,
        header: { ...leaseData.header, isCapital: resultIsCapital },
      },
      'OVERRIDE',
    );
    dispatch(clearAllForms());
    dispatch(setReloadLeaseDetails(true));
    dispatch(setReloadLeases(true));
    dispatch(hideModal());
    history.push(`/leases/${leaseId}`);
  };

  const getResultsHeaderWording = () => {
    const userSelectedWording = isCapital ? (
      <>
        a <b>Capital</b>
      </>
    ) : (
      <>
        an <b>Operating</b>
      </>
    );
    const testResultWording = resultIsCapital ? (
      <>
        a <b>Capital</b>
      </>
    ) : (
      <>
        an <b>Operating</b>
      </>
    );

    // if the user left off either usefulLifeMonths or fairValue...
    // we can't tell them that we received test results...
    // unless the test came back as capital
    if (canShowResults(capOpTestResults, resultIsCapital)) {
      // user selected help
      if (isCapital === 'help') {
        if (resultIsCapital) {
          // test result is capital
          return (
            <div>
              This lease has been classified as a <b>Capital</b> lease because
              it has passed <b>one or more</b> of the following criteria:
            </div>
          );
        } else {
          // test result is capital
          return (
            <div>
              This lease has been classified as an <b>Operating</b> lease
              because it has passed <b>all</b> of the following criteria:
            </div>
          );
        }
      } else {
        // user selected capital or operating
        if (isCapital === resultIsCapital) {
          // result is the same as selected
          return (
            <div>
              You selected {userSelectedWording} lease. The following confirms
              that this classification is correct:
            </div>
          );
        } else {
          // result is different than selected
          return (
            <div>
              You selected {userSelectedWording} lease. Based on the following
              information, your lease has been classified as {testResultWording}
              . If you wish to make a change to your lease type, you may do so
              below.
            </div>
          );
        }
      }
    } else {
      return (
        <div>
          You selected {userSelectedWording} lease. We were unable to run the
          Capital vs Operating test being that no values were provided for{' '}
          <b>Useful Life</b> and/or <b>Fair Value</b>.
        </div>
      );
    }
  };

  const shouldShowExtraButtonOptions = () => {
    // if the user chose help, they always just continue
    if (isCapital === 'help') {
      return false;
    }

    // if the test result differs from the user selection
    if (isCapital !== resultIsCapital) {
      // and we have all 5 fields
      if (Object.keys(capOpTestResults).length === 5) {
        // show the extra button options
        return true;
      } else {
        // otherwise, only show the extra button options
        // if the result is capital
        return resultIsCapital;
      }
    }

    return false;
  };

  const handleConfirm = () => {
    dispatch(hideModal());
  };

  const showExtraButtonOptions = shouldShowExtraButtonOptions();

  return (
    <Modal
      title="Capital vs Operating Test"
      open={open}
      processing={isProcessing}
      onConfirm={handleConfirm}
      onSecondary={() => {
        setProcessing(true);
        handleSaveAsClick();
      }}
      secondaryButtonText={
        showExtraButtonOptions
          ? `Save as ${resultIsCapital ? 'Capital' : 'Operating'}`
          : undefined
      }
      positiveButtonText={
        showExtraButtonOptions
          ? `Continue as ${isCapital ? 'Capital' : 'Operating'}`
          : 'Continue'
      }
    >
      <>
        <div className="cap-op-results-wrapper">
          <div>
            {getResultsHeaderWording()}
            {capOpTestResults ? (
              <>
                <div className="cap-op-criteria">
                  <div className="cap-op-criteria-item">
                    <div className="cap-op-criteria-wording">
                      Title transfer at lease end
                    </div>
                    <div
                      className={`cap-op-criteria-result ${
                        resultIsCapital && capOpTestResults.canTransfer
                          ? 'positive'
                          : ''
                      }`}
                    >
                      {capOpTestResults.canTransfer ? 'YES' : 'NO'}
                    </div>
                  </div>
                  <Divider />
                  <div className="cap-op-criteria-item">
                    <div className="cap-op-criteria-wording">
                      Bargain purchase option was selected
                    </div>
                    <div
                      className={`cap-op-criteria-result ${
                        resultIsCapital && capOpTestResults.hasBargainPurchase
                          ? 'positive'
                          : ''
                      }`}
                    >
                      {capOpTestResults.hasBargainPurchase ? 'YES' : 'NO'}
                    </div>
                  </div>
                  <Divider />
                  <div className="cap-op-criteria-item">
                    <div className="cap-op-criteria-wording">
                      The lease term is 75% or greater than the useful life of
                      the asset
                    </div>
                    <div
                      className={`cap-op-criteria-result ${
                        usefulLifeMonths !== undefined &&
                        usefulLifeMonths !== '' &&
                        usefulLifeMonths !== null
                          ? resultIsCapital && capOpTestResults.usefulLife === 1
                            ? 'positive'
                            : ''
                          : 'negative'
                      }`}
                    >
                      {usefulLifeMonths !== undefined &&
                      usefulLifeMonths !== '' &&
                      usefulLifeMonths !== null
                        ? capOpTestResults.usefulLife
                          ? 'YES'
                          : 'NO'
                        : 'N/A'}
                    </div>
                  </div>
                  <Divider />
                  <div className="cap-op-criteria-item">
                    <div className="cap-op-criteria-wording">
                      The present value of the minimum lease payments is at
                      least 90% of the fair value of the asset
                    </div>
                    <div
                      className={`cap-op-criteria-result ${
                        fairValue !== undefined &&
                        fairValue !== '' &&
                        fairValue !== null
                          ? resultIsCapital && capOpTestResults.fairValue === 1
                            ? 'positive'
                            : ''
                          : 'negative'
                      }`}
                    >
                      {fairValue !== undefined &&
                      fairValue !== '' &&
                      fairValue !== null
                        ? capOpTestResults.fairValue
                          ? 'YES'
                          : 'NO'
                        : 'N/A'}
                    </div>
                  </div>
                  <Divider />
                  <div className="cap-op-criteria-item">
                    <div className="cap-op-criteria-wording">
                      The asset is specialized in nature
                    </div>
                    <div
                      className={`cap-op-criteria-result ${
                        resultIsCapital && capOpTestResults.isSpecialized
                          ? 'positive'
                          : ''
                      }`}
                    >
                      {capOpTestResults.isSpecialized ? 'YES' : 'NO'}
                    </div>
                  </div>
                </div>
                {showExtraButtonOptions ? (
                  <div>
                    <b className="cap-op-warning">WARNING:</b> An incorrect
                    lease classification will impact your amortization schedule
                    and calculations.
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </>
    </Modal>
  );
};

export default CapOpResultsModal;
