/**
 * Find leading and trailing whitespace characters.
 */
export const findLeadTrailWhtspace: RegExp = new RegExp(
  /(^\s+|[^a-zA-Z0-9 ]+|\s+$)/,
  'g',
);

/**
 * Find whitespace characters.
 */
export const findWhtspace: RegExp = new RegExp(/\s+/, 'g');

/**
 * Find any illegal filename characters
 */
export const formatFileName: RegExp = new RegExp(/[/\\?%*:|"<>]/, 'g');

/**
 * Find the text between filename= and ;
 */
export const findDocumentName: RegExp = new RegExp(
  /filename=((['"]).*?\2|[^;\n]*)/,
  'g',
);

/**
 * Allows only MM/DD/YYYY format
 */
// https://stackoverflow.com/questions/8647893/regular-expression-leap-years-and-more
export const defaultDateRegex = /((0[13578]|1[02])[\\/.](0[1-9]|[12][0-9]|3[01])[\\/.](18|19|20)[0-9]{2})|((0[469]|11)[\\/.](0[1-9]|[12][0-9]|30)[\\/.](18|19|20)[0-9]{2})|((02)[\\/.](0[1-9]|1[0-9]|2[0-8])[\\/.](18|19|20)[0-9]{2})|((02)[\\/.]29[\\/.](((18|19|20)(04|08|[2468][048]|[13579][26]))|2000))/;

export const validZip = /^[0-9]{5}(?:-[0-9]{4})?$/;

export const negativeCurrency = /\((\d+)\)/;
