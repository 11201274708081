import { IClientIndustriesModel, ICPACompanyDetail } from '../interfaces';
import { api, formatError } from './api';

export async function clientIndustriesGet(): Promise<IClientIndustriesModel[]> {
  let response;

  const rawResponse = await api.get('/ClientIndustries');

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  response = rawResponse.data;

  return response;
}

export async function cpaClientsGet(): Promise<ICPACompanyDetail[]> {
  const rawResponse = await api.get('/CPAClients');

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}

export async function cpaDeleteClient(clientId: number) {
  const rawResponse = await api.delete(`/DeleteCPAClient/${clientId}`);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}
