import React, { FunctionComponent } from 'react';

import './FormGrid.scss';

interface IFormGridProps {
  colWidth: GridColumns;
}

type GridColumns = 1 | 2 | 3;

const FormGrid: FunctionComponent<IFormGridProps> = ({
  colWidth,
  children,
}) => {
  return <div className={`form-grid col-width-${colWidth}`}>{children}</div>;
};

export default FormGrid;
