import { ReactTableDefaults } from 'react-table';

import { Currency } from '../../../../common';
import {
  IReactTableColumns,
  ITableColumnNames,
  MonthCell,
} from '../../../../common/tables/react-table';
import {
  IAmort840Model,
  IAmort842IFRSModel,
  IAmortCapModel,
} from '../../../../interfaces';

export const AmortSchedColumnDefaults = {
  ...ReactTableDefaults.column,
  minWidth: 200,
};

// All available columns for the table
export enum AmortSchedColType {
  Year,
  Month,
  Cash,
  Expense,
  DeferredPrepaidRent,
  CumulativeBalance,
  STDefferedRent,
  LTDefferedRent,
  STPrepaidRent,
  LTPrepaidRent,
  LiabilityLeaseExpense,
  LiabilityReduction,
  TotalLiability,
  STLeaseLiability,
  LTLeaseLiability,
  AssetLeaseExpense,
  NetAssetBalance,
  TotalLeaseExpense,
  AccuDepreciation,
  GrossAssetBalance,
  DepreciationExpense,
  InterestExpense,
  IFRSExpense,
}

export enum ScheduleTypes {
  TOPIC_840BR = '840BR',
  TOPIC_840FT = '840FT',
  TOPIC_842BR = '842BR',
  TOPIC_842FT = '842FT',
  IFRS_16BR = '16BR',
  IFRS_16FT = '16FT',
  CAPITAL_BR = 'CAPBR',
  CAPITAL_FT = 'CAPFT',
}

interface IScheduleModel {
  [key: string]: {
    title: string;
    columns: AmortSchedColType[];
  };
}

// Force columns that are always shown in the table
const AmortSchedFixedColumns: AmortSchedColType[] = [
  AmortSchedColType.Year,
  AmortSchedColType.Month,
  AmortSchedColType.Cash,
];

// Optional columns that appear in the column selection dropdown
export const Amort840Columns: AmortSchedColType[] = [
  AmortSchedColType.Expense,
  AmortSchedColType.DeferredPrepaidRent,
  AmortSchedColType.CumulativeBalance,
  AmortSchedColType.STDefferedRent,
  AmortSchedColType.LTDefferedRent,
  AmortSchedColType.STPrepaidRent,
  AmortSchedColType.LTPrepaidRent,
];

export const Amort842Columns: AmortSchedColType[] = [
  AmortSchedColType.LiabilityLeaseExpense,
  AmortSchedColType.LiabilityReduction,
  AmortSchedColType.TotalLiability,
  AmortSchedColType.STLeaseLiability,
  AmortSchedColType.LTLeaseLiability,
  AmortSchedColType.NetAssetBalance,
  AmortSchedColType.AssetLeaseExpense,
  AmortSchedColType.TotalLeaseExpense,
];

export const AmortIFRSColumns: AmortSchedColType[] = [
  AmortSchedColType.IFRSExpense,
  AmortSchedColType.LiabilityReduction,
  AmortSchedColType.TotalLiability,
  AmortSchedColType.STLeaseLiability,
  AmortSchedColType.LTLeaseLiability,
  AmortSchedColType.NetAssetBalance,
  AmortSchedColType.DepreciationExpense,
  AmortSchedColType.TotalLeaseExpense,
  AmortSchedColType.AccuDepreciation,
  AmortSchedColType.GrossAssetBalance,
];

export const AmortCapitalColumns: AmortSchedColType[] = [
  AmortSchedColType.InterestExpense,
  AmortSchedColType.LiabilityReduction,
  AmortSchedColType.TotalLiability,
  AmortSchedColType.STLeaseLiability,
  AmortSchedColType.LTLeaseLiability,
  AmortSchedColType.DepreciationExpense,
  AmortSchedColType.NetAssetBalance,
  AmortSchedColType.AccuDepreciation,
  AmortSchedColType.GrossAssetBalance,
];

export const Schedules: IScheduleModel = {
  [ScheduleTypes.TOPIC_840BR]: {
    title: 'Topic 840 Base Rent',
    columns: Amort840Columns,
  },
  [ScheduleTypes.TOPIC_840FT]: {
    title: 'Topic 840 Total Fixed Consideration',
    columns: Amort840Columns,
  },
  [ScheduleTypes.TOPIC_842BR]: {
    title: 'Topic 842 Base Rent',
    columns: Amort842Columns,
  },
  [ScheduleTypes.TOPIC_842FT]: {
    title: 'Topic 842 Total Fixed Consideration',
    columns: Amort842Columns,
  },
  [ScheduleTypes.IFRS_16BR]: {
    title: 'IFRS 16 Base Rent',
    columns: AmortIFRSColumns,
  },
  [ScheduleTypes.IFRS_16FT]: {
    title: 'IFRS 16 Total Fixed Consideration',
    columns: AmortIFRSColumns,
  },
  [ScheduleTypes.CAPITAL_BR]: {
    title: 'Topic 840/IAS 17 Base Rent',
    columns: AmortCapitalColumns,
  },
  [ScheduleTypes.CAPITAL_FT]: {
    title: 'Topic 840/IAS 17 Total Fixed Consideration',
    columns: AmortCapitalColumns,
  },
};

// A mapping from column type to names to be used in column headers and other locations
export const AmortSchedColNames: ITableColumnNames = {
  [AmortSchedColType.Year]: {
    name: 'Year',
    shortName: 'Year',
  },
  [AmortSchedColType.Month]: {
    name: 'Month',
    shortName: 'Month',
  },
  [AmortSchedColType.Cash]: {
    name: 'Cash',
    shortName: 'Cash',
  },
  [AmortSchedColType.Expense]: {
    name: 'Expense',
    shortName: 'Expense',
  },
  [AmortSchedColType.IFRSExpense]: {
    name: 'Interest Expense',
    shortName: 'Interest Expense',
  },
  [AmortSchedColType.DeferredPrepaidRent]: {
    name: '(Deferred)/Prepaid Rent',
    shortName: '(Deferred)/Prepaid Rent',
  },
  [AmortSchedColType.CumulativeBalance]: {
    name: 'Cumulative Balance',
    shortName: 'Cumulative Balance',
  },
  [AmortSchedColType.STDefferedRent]: {
    name: 'Short Term Deferred Rent',
    shortName: 'Short Term Deferred Rent',
  },
  [AmortSchedColType.LTDefferedRent]: {
    name: 'Long Term Deferred Rent',
    shortName: 'Long Term Deferred Rent',
  },
  [AmortSchedColType.STPrepaidRent]: {
    name: 'Short Term Prepaid Rent',
    shortName: 'Short Term Prepaid Rent',
  },
  [AmortSchedColType.LTPrepaidRent]: {
    name: 'Long Term Prepaid Rent',
    shortName: 'Long Term Prepaid Rent',
  },
  [AmortSchedColType.LiabilityLeaseExpense]: {
    name: 'Liability Lease Expense',
    shortName: 'Liability Lease Expense',
  },
  [AmortSchedColType.LiabilityReduction]: {
    name: 'Liability Reduction',
    shortName: 'Liability Reduction',
  },
  [AmortSchedColType.TotalLiability]: {
    name: 'Total Liability',
    shortName: 'Total Liability',
  },
  [AmortSchedColType.STLeaseLiability]: {
    name: 'ST Lease Liability',
    shortName: 'ST Lease Liability',
  },
  [AmortSchedColType.LTLeaseLiability]: {
    name: 'LT Lease Liability',
    shortName: 'LT Lease Liability',
  },
  [AmortSchedColType.NetAssetBalance]: {
    name: 'Net Asset Balance',
    shortName: 'Net Asset Balance',
  },
  [AmortSchedColType.AssetLeaseExpense]: {
    name: 'Asset Lease Expense',
    shortName: 'Asset Lease Expense',
  },
  [AmortSchedColType.TotalLeaseExpense]: {
    name: 'Total Lease Expense',
    shortName: 'Total Lease Expense',
  },
  [AmortSchedColType.AccuDepreciation]: {
    name: 'Accumulated Depreciation',
    shortName: 'Accumulated Depreciation',
  },
  [AmortSchedColType.GrossAssetBalance]: {
    name: 'Gross Asset Balance',
    shortName: 'Gross Asset Balance',
  },
  [AmortSchedColType.DepreciationExpense]: {
    name: 'Depreciation Expense',
    shortName: 'Depreciation Expense',
  },
  [AmortSchedColType.InterestExpense]: {
    name: 'Interest Expense',
    shortName: 'Interest Expense',
  },
};

// The react-table specification for the table's columns.
const AmortSchedReactTableCols: IReactTableColumns<
  IAmort840Model | IAmort842IFRSModel | IAmortCapModel
> = {
  [AmortSchedColType.Year]: {
    id: AmortSchedColType[AmortSchedColType.Year],
    Header: AmortSchedColNames[AmortSchedColType.Year].shortName,
    accessor: 'year',
    width: 100,
    resizable: false,
    fixed: 'left',
  },
  [AmortSchedColType.Month]: {
    id: AmortSchedColType[AmortSchedColType.Month],
    Header: AmortSchedColNames[AmortSchedColType.Month].shortName,
    accessor: 'month',
    Cell: MonthCell,
    width: 100,
    resizable: false,
    fixed: 'left',
  },
  [AmortSchedColType.Cash]: {
    id: AmortSchedColType[AmortSchedColType.Cash],
    Header: AmortSchedColNames[AmortSchedColType.Cash].shortName,
    accessor: 'cash',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.Expense]: {
    id: AmortSchedColType[AmortSchedColType.Expense],
    Header: AmortSchedColNames[AmortSchedColType.Expense].shortName,
    accessor: 'expense',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.IFRSExpense]: {
    id: AmortSchedColType[AmortSchedColType.IFRSExpense],
    Header: AmortSchedColNames[AmortSchedColType.IFRSExpense].shortName,
    accessor: 'expense',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.DeferredPrepaidRent]: {
    id: AmortSchedColType[AmortSchedColType.DeferredPrepaidRent],
    Header: AmortSchedColNames[AmortSchedColType.DeferredPrepaidRent].shortName,
    accessor: 'prepaidRent',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.CumulativeBalance]: {
    id: AmortSchedColType[AmortSchedColType.CumulativeBalance],
    Header: AmortSchedColNames[AmortSchedColType.CumulativeBalance].shortName,
    accessor: 'cumulativeBalance',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.STDefferedRent]: {
    id: AmortSchedColType[AmortSchedColType.STDefferedRent],
    Header: AmortSchedColNames[AmortSchedColType.STDefferedRent].shortName,
    accessor: 'shortTermDeferredRent',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.LTDefferedRent]: {
    id: AmortSchedColType[AmortSchedColType.LTDefferedRent],
    Header: AmortSchedColNames[AmortSchedColType.LTDefferedRent].shortName,
    accessor: 'longTermDeferredRent',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.STPrepaidRent]: {
    id: AmortSchedColType[AmortSchedColType.STPrepaidRent],
    Header: AmortSchedColNames[AmortSchedColType.STPrepaidRent].shortName,
    accessor: 'shortTermPrepaidRent',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.LTPrepaidRent]: {
    id: AmortSchedColType[AmortSchedColType.LTPrepaidRent],
    Header: AmortSchedColNames[AmortSchedColType.LTPrepaidRent].shortName,
    accessor: 'longTermPrepaidRent',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.LiabilityLeaseExpense]: {
    id: AmortSchedColType[AmortSchedColType.LiabilityLeaseExpense],
    Header:
      AmortSchedColNames[AmortSchedColType.LiabilityLeaseExpense].shortName,
    accessor: 'expense',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.LiabilityReduction]: {
    id: AmortSchedColType[AmortSchedColType.LiabilityReduction],
    Header: AmortSchedColNames[AmortSchedColType.LiabilityReduction].shortName,
    accessor: 'liabilityReduction',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.TotalLiability]: {
    id: AmortSchedColType[AmortSchedColType.TotalLiability],
    Header: AmortSchedColNames[AmortSchedColType.TotalLiability].shortName,
    accessor: 'totalLiability',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.STLeaseLiability]: {
    id: AmortSchedColType[AmortSchedColType.STLeaseLiability],
    Header: AmortSchedColNames[AmortSchedColType.STLeaseLiability].shortName,
    accessor: 'stLeaseLiability',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.LTLeaseLiability]: {
    id: AmortSchedColType[AmortSchedColType.LTLeaseLiability],
    Header: AmortSchedColNames[AmortSchedColType.LTLeaseLiability].shortName,
    accessor: 'ltLeaseLiability',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.NetAssetBalance]: {
    id: AmortSchedColType[AmortSchedColType.NetAssetBalance],
    Header: AmortSchedColNames[AmortSchedColType.NetAssetBalance].shortName,
    accessor: 'netAssetBalance',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.AssetLeaseExpense]: {
    id: AmortSchedColType[AmortSchedColType.AssetLeaseExpense],
    Header: AmortSchedColNames[AmortSchedColType.AssetLeaseExpense].shortName,
    accessor: 'assetLeaseExpense',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.TotalLeaseExpense]: {
    id: AmortSchedColType[AmortSchedColType.TotalLeaseExpense],
    Header: AmortSchedColNames[AmortSchedColType.TotalLeaseExpense].shortName,
    accessor: 'totalLeaseExpense',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.AccuDepreciation]: {
    id: AmortSchedColType[AmortSchedColType.AccuDepreciation],
    Header: AmortSchedColNames[AmortSchedColType.AccuDepreciation].shortName,
    accessor: 'accumulatedDepreciation',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.GrossAssetBalance]: {
    id: AmortSchedColType[AmortSchedColType.GrossAssetBalance],
    Header: AmortSchedColNames[AmortSchedColType.GrossAssetBalance].shortName,
    accessor: 'grossAssetBalance',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.DepreciationExpense]: {
    id: AmortSchedColType[AmortSchedColType.DepreciationExpense],
    Header: AmortSchedColNames[AmortSchedColType.DepreciationExpense].shortName,
    accessor: 'depreciationExpense',
    resizable: false,
    Cell: Currency,
  },
  [AmortSchedColType.InterestExpense]: {
    id: AmortSchedColType[AmortSchedColType.InterestExpense],
    Header: AmortSchedColNames[AmortSchedColType.InterestExpense].shortName,
    accessor: 'interestExpense',
    resizable: false,
    Cell: Currency,
  },
};

/**
 * Builds a list of columns to display based on a fixed set of hardcoded columns as well as user provided
 * optional columns
 *
 * @param requestedColumns The optional columns as requested by the caller
 */
export function amortSchedColumnConfiguration(
  requestedColumns: AmortSchedColType[] = [],
) {
  const initialColumns = AmortSchedFixedColumns.map(
    (col) => AmortSchedReactTableCols[col],
  );
  const optionalColumns = requestedColumns
    .filter((col) => AmortSchedColType[col])
    .map((col) => AmortSchedReactTableCols[col]);
  return initialColumns.concat(optionalColumns);
}
