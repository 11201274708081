import React, { Fragment, FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import logo from '../../../assets/add-large.svg';
import rsmlogo from '../../../assets/rsm-add-large.svg';
import {
  hideSteppedModal,
  showSteppedModal,
} from '../../../common/modal/actions';

import './LeaseCard.scss';

const AddLeaseCard: FunctionComponent = () => {
  const dispatch = useDispatch();
  const hideSteppedModalAction = () => {
    dispatch(hideSteppedModal());
  };
  const addLease = () => {
    dispatch(
      showSteppedModal({
        modal: {
          modalType: 'STEPPED_MODAL',
          modalProps: {
            open: true,
            hideModal: hideSteppedModalAction,
            title: 'Add New Lease',
          },
        },
      }),
    );
  };

  return (
    <div onClick={addLease} className="lease-card add-lease-card">
      <Fragment>
        <img
          src={`${
            process.env.REACT_APP_ENABLE_RSM === 'true' ? rsmlogo : logo
          }`}
          alt="Add Lease"
        />
        <span className="add-lease-card-text">ADD NEW</span>
      </Fragment>
    </div>
  );
};

export default AddLeaseCard;
