import React, { FunctionComponent, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Modal from '../../common/modal/Modal';
import { PlanFeatureNames } from '../../interfaces';
import { IStore } from '../../store';
import { checkIfUserHasAccessToFeature } from '../plans/helpers';
import QuickBooksERPSection from './QuickBooksERPSection';
import SageERPSection from './SageERPSection';
import XeroERPSection from './XeroERPSection';

import './ExternalToolsModal.scss';

interface IExternalToolsModalProps {
  open: boolean;
}

const ExternalToolsModal: FunctionComponent<IExternalToolsModalProps> = ({
  open,
}) => {
  const loggedInUser = useSelector((state: IStore) => {
    return state.user;
  });

  const userCompanyData = loggedInUser.company;

  const features = userCompanyData.plan?.features;

  const userHasAccessToQuickbooks = useMemo(
    () =>
      checkIfUserHasAccessToFeature(
        PlanFeatureNames.quickbooks,
        features,
        loggedInUser.userRole === 1,
      ),
    [features, loggedInUser],
  );

  const userHasAccessToSage = useMemo(
    () =>
      checkIfUserHasAccessToFeature(
        PlanFeatureNames.sage,
        features,
        loggedInUser.userRole === 1,
      ),
    [features, loggedInUser],
  );

  const userHasAccessToXero = useMemo(
    () =>
      checkIfUserHasAccessToFeature(
        PlanFeatureNames.xero,
        features,
        loggedInUser.userRole === 1,
      ),
    [features, loggedInUser],
  );

  return (
    <Modal title="External Tools" open={open} neutralButtonText="Cancel">
      <div className="external-tools-wrapper">
        Choose the ERP you'd like to connect.
        <div className="red-text">
          Note: You can only be connected to one ERP. To change your ERP
          integration, you must disconnect first.
        </div>
        {userHasAccessToQuickbooks ? (
          <QuickBooksERPSection
            userCompanyData={userCompanyData}
            userHasAccess={userHasAccessToQuickbooks}
          />
        ) : null}
        {userHasAccessToSage ? (
          <SageERPSection
            userCompanyData={userCompanyData}
            userHasAccess={userHasAccessToSage}
          />
        ) : null}
        {userHasAccessToXero ? (
          <XeroERPSection
            userCompanyData={userCompanyData}
            userHasAccess={userHasAccessToXero}
          />
        ) : null}
      </div>
    </Modal>
  );
};

export default ExternalToolsModal;
