import React, { Fragment, FunctionComponent } from 'react';

import { IGlossaryItem } from './GlossaryItem';

import './GlossaryItemDetails.scss';

interface IGlossaryItemDetailsProps {
  glossaryItem: IGlossaryItem | undefined;
  onBack: () => void;
}

export const formatGlossaryItemDetails = (glossaryItem: IGlossaryItem) =>
  glossaryItem.termDefinition.split('\\n').map((item, key) => (
    <Fragment key={key}>
      {item}
      <br />
    </Fragment>
  ));

const GlossaryItemDetails: FunctionComponent<IGlossaryItemDetailsProps> = ({
  glossaryItem,
  onBack,
}) => {
  return (
    <div
      className={`glossary-item-details-wrapper ${
        glossaryItem ? 'item-selected' : ''
      }`}
    >
      {glossaryItem ? (
        <>
          <div className="git-nav" onClick={onBack}>
            <div className="git-nav-caret" />
            <div className="git-nav-text">Back</div>
          </div>
          <div className="git-item-wrapper">
            <div className="git-title">{glossaryItem.term}</div>
            <div className="git-message">
              {formatGlossaryItemDetails(glossaryItem)}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default GlossaryItemDetails;
