import React, { FunctionComponent } from 'react';
import './PlansPage.scss';
import PlansTable from './plansTable/PlansTable';

const PlansPage: FunctionComponent = () => {
  const isRSM = process.env.REACT_APP_ENABLE_RSM === 'true';

  return (
    <div className="page-container">
      <div className={`title ${isRSM && 'rsm'}`}>Select Your Plan</div>
      <div className="sub-title">
        Choose a plan that fits your company's needs.
      </div>
      <PlansTable />
    </div>
  );
};

export default PlansPage;
