import Downshift from 'downshift';
import React, { useEffect, useState } from 'react';

import FormError from '../form-error/FormError';

import './Select.scss';

const Select = <T extends {}>({
  name,
  id,
  required,
  label,
  onClick,
  value,
  placeholder,
  options,
  error,
  errorMessage,
  disabled,
}: ISelectProps<T>) => {
  const [itemLabel, setItemLabel] = useState('');

  useEffect(() => {
    const selectedOption = options.find((option) => option.value === value);
    if (selectedOption) {
      setItemLabel(selectedOption.label);
    }
  }, [itemLabel, options, value]);

  return (
    <div>
      <Downshift
        onChange={(selectedItem) => {
          if (selectedItem) {
            onClick(name, selectedItem);
            setItemLabel(selectedItem.label);
          }
        }}
        selectedItem={value ? value : ''}
        itemToString={(item) => (item ? item.label : '')}
      >
        {({
          isOpen,
          getToggleButtonProps,
          getItemProps,
          highlightedIndex,
          getLabelProps,
          getMenuProps,
        }) => (
          <div
            className={`select-wrapper ${process.env.REACT_APP_ENABLE_RSM ===
              'true' && 'rsm-class-select'}`}
          >
            {label ? (
              <label
                {...getLabelProps()}
                htmlFor={id}
                className={`select-label ${required ? 'required' : ''} `}
              >
                {label}
              </label>
            ) : null}
            <button
              {...getToggleButtonProps()}
              className={`select-button ${error ? 'error' : ''}`}
              disabled={disabled ? disabled : false}
              id={`button-${id}`}
            >
              {value !== undefined ? (
                itemLabel
              ) : (
                <div className="select-placeholder">{placeholder}</div>
              )}
              {disabled ? null : (
                <div
                  className={`select-arrow-box ${
                    error ? 'select-arrow-box--error' : ''
                  }`}
                >
                  <div
                    className={`select-arrow ${
                      error ? 'select-arrow--error' : ''
                    }`}
                  />
                </div>
              )}
            </button>
            <ul
              {...getMenuProps()}
              className={isOpen ? 'select-menu' : 'select-menu--closed'}
              id={`ul-${id}`}
            >
              {isOpen
                ? options.map((item, index) => (
                    <li
                      {...getItemProps({
                        index,
                        item,
                      })}
                      key={`${item.key}-${item.value}-${index}`}
                      className={
                        highlightedIndex === index ? 'option-active' : ''
                      }
                    >
                      {item.label}
                    </li>
                  ))
                : null}
            </ul>
          </div>
        )}
      </Downshift>
      <FormError error={error} errorMessage={errorMessage} />
    </div>
  );
};

interface ISelectProps<T> {
  name: string;
  onClick: (name: string, option: ISelectOptions<T>) => void;
  options: Array<ISelectOptions<T>>;
  id?: string;
  value?: T;
  item?: ISelectOptions<T>;
  label?: string;
  placeholder?: string;
  required?: boolean;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}

export interface ISelectOptions<T> {
  key: number;
  value: T;
  label: string;
}

export default Select;
