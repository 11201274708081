import React, { FunctionComponent } from 'react';

import './FormError.scss';

const FormError: FunctionComponent<IFormErrorProps> = ({
  error,
  errorMessage,
  size,
}) => {
  return error ? (
    <div className={`form-error-message ${size}`}>
      {errorMessage || 'There is an issue with this field'}
    </div>
  ) : null;
};

interface IFormErrorProps {
  error: boolean | undefined;
  errorMessage?: string;
  size?: string;
}

export default FormError;
