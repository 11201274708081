import format from 'date-fns/format';
import get from 'lodash/get';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { deleteLease } from '../../../api';
import {
  CircleIcon,
  Currency,
  DateProgressBar,
  Dropdown,
  StatusIndicator,
} from '../../../common';
import {
  hideSteppedModal,
  showModal,
  showModal as showModalAction,
  showSteppedModal,
} from '../../../common/modal/actions';
import Tooltip from '../../../common/tooltips/Tooltip';
import { DEFAULT_DATE_FORMAT } from '../../../common/utilities/_dates';
import { checkIfUserIsGuest } from '../../../common/utilities/_guestUserHelper';
import { formatCityStateZip } from '../../../common/utilities/_strings';
import {
  ICapOpTestResultsModel,
  ILeaseHeaderModel,
  ILeaseModel,
  PlanFeatureNames,
} from '../../../interfaces';
import { IStore } from '../../../store';
import { populateAllForms } from '../../lease/actions';
import { AssetIcon } from '../../lease/add-lease-forms/GeneralForm';
import {
  canShowResults,
  getCapOpResultWording,
  isResultCapital,
} from '../../lease/components/CapOpResultsModal';
import { deleteStoredLease } from '../../leaseList/actions';
import { getFeatureValue } from '../../plans/helpers';

import TagManager from 'react-gtm-module';
import lg_check from '../../../assets/lg-check.png';
import pen_edit from '../../../assets/pen-edit.svg';
import '../LeaseDetails.scss';

interface IProps extends RouteComponentProps {
  lease: ILeaseModel;
  leaseHeader: ILeaseHeaderModel;
  jurisdiction: string | undefined;
  capOpTestResults: ICapOpTestResultsModel;
}

export const getJurisdicitionValue = (
  jurisdiction: string,
  isCapital: boolean,
) =>
  jurisdiction === 'IFRS'
    ? 'Finance Lease'
    : isCapital
    ? 'Capital Lease'
    : 'Operating Lease';

const LeaseDetailsHeader: FunctionComponent<IProps> = ({
  lease,
  leaseHeader,
  jurisdiction,
  history,
  capOpTestResults,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const selectedLease = useSelector((state: IStore) => {
    return state.leaseDetails.selectedLease;
  });

  const user = useSelector((state: IStore) => {
    return state.user;
  });

  const isGuestUser = checkIfUserIsGuest(user);

  const features = user.company.plan?.features;

  const maxLeaseCount: number = features
    ? Number(getFeatureValue(PlanFeatureNames.leaseCount, features))
    : 0;

  const leaseList: ILeaseHeaderModel[] = useSelector((state: IStore) => {
    return state.leaseList;
  });

  const handleUpdateLease = async () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'amend_lease_start',
      },
    });
    dispatch(
      showModalAction({
        modal: {
          modalType: 'AMEND_TYPE',
          modalProps: {
            open: true,
            hideModal: () =>
              dispatch({
                type: 'HIDE_MODAL',
              }),
          },
        },
      }),
    );
  };

  const handleDropdownToggle = (open?: boolean) => {
    setOpen(open || !isOpen);
  };

  async function handleDeleteLease() {
    if (leaseHeader.leaseId) {
      await deleteLease(leaseHeader.leaseId);
      dispatch(deleteStoredLease(leaseHeader.leaseId));
    }
  }

  function handleDuplicate() {
    dispatch(
      populateAllForms({
        ...selectedLease,
        header: { ...selectedLease.header, assetName: '' },
      }),
    );
    dispatch(
      showSteppedModal({
        modal: {
          modalType: 'STEPPED_MODAL',
          modalProps: {
            open: true,
            hideModal: () => dispatch(hideSteppedModal()),
            title: 'Duplicate Lease',
            amendType: 'DUPLICATE',
          },
        },
      }),
    );
  }

  function handleDelete() {
    dispatch(
      showModalAction({
        modal: {
          modalType: 'CONFIRMATION',
          modalProps: {
            open: true,
            hideModal: () =>
              dispatch({
                type: 'HIDE_MODAL',
              }),
            confirmAction: async () => {
              await handleDeleteLease();
              history.push('/leases');
              dispatch({
                type: 'HIDE_MODAL',
              });
            },
            confirmText: `Continuing with this action will permanently delete the lease: ${leaseHeader.assetName}`,
            buttonText: 'Delete',
          },
        },
      }),
    );
  }

  async function handleTerminate() {
    dispatch(
      showModalAction({
        modal: {
          modalType: 'CONFIRMATION',
          modalProps: {
            open: true,
            hideModal: () =>
              dispatch({
                type: 'HIDE_MODAL',
              }),
            confirmAction: () => {
              dispatch({
                type: 'HIDE_MODAL',
              });
              dispatch(
                showModalAction({
                  modal: {
                    modalType: 'TERMINATION',
                    modalProps: {
                      open: true,
                      hideModal: () =>
                        dispatch({
                          type: 'HIDE_MODAL',
                        }),
                    },
                  },
                }),
              );
            },
            confirmText:
              'You are about to terminate your lease. This cannot be undone.',
            buttonText: 'Continue',
          },
        },
      }),
    );
  }

  const getDropdownOptions = () => {
    const options = [
      {
        label: 'Delete',
        key: 'delete',
        clickAction: () => {
          handleDelete();
        },
      },
    ];

    if (leaseHeader.leaseStatus !== 'Terminated') {
      options.splice(
        0,
        0,
        {
          label: 'Amend',
          key: 'update',
          clickAction: () => {
            handleUpdateLease();
          },
        },
        {
          label: 'Terminate',
          key: 'terminate',
          clickAction: () => {
            handleTerminate();
          },
        },
      );
    }

    if (leaseList.length < maxLeaseCount) {
      const deleteIndex = options.findIndex(
        (option) => option.key === 'delete',
      );

      options.splice(deleteIndex, 0, {
        label: 'Duplicate',
        key: 'duplicate',
        clickAction: () => {
          handleDuplicate();
        },
      });
    }

    return options.map((action) => {
      return (
        <li
          id={`Dropdown-menu-item-${action.key}`}
          className="dropdown-menu--item link-text"
          key={action.key}
          onClick={action.clickAction}
        >
          {action.label}
        </li>
      );
    });
  };

  const resultCanBeShown = (
    usefulLife: string = '',
    fairValue: string = '',
    results: ICapOpTestResultsModel,
    isCapital: boolean,
  ) => {
    const resultIsCapital = isResultCapital(usefulLife, fairValue, results);

    if (isCapital !== resultIsCapital) {
      return false;
    }

    return canShowResults(results, resultIsCapital);
  };

  const resultsCanBeShown = resultCanBeShown(
    leaseHeader.usefulLifeMonths?.toString(),
    leaseHeader.fairValue?.toString(),
    capOpTestResults,
    leaseHeader.isCapital,
  );

  // fix for showing correct next/current payment
  const amortizationSchedules = lease && lease.amortizationSchedules;
  let currentPaymentAmt = leaseHeader && leaseHeader.currentPaymentAmt;
  let nextPaymentAmt = leaseHeader && leaseHeader.nextPaymentAmt;

  const currentPaymentDate = format(
    new Date(leaseHeader.currentPaymentDate),
    DEFAULT_DATE_FORMAT,
  );

  const nextPaymentDate = format(
    new Date(leaseHeader.nextPaymentDate),
    DEFAULT_DATE_FORMAT,
  );

  const showLeaseGeneralDetailsModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'LEASE_GENERAL_DETAILS',
          modalProps: {
            open: true,
          },
        },
      }),
    );
  };

  const additionalPayments = lease.otherPayments;
  const variablePayments: any[] = [];

  additionalPayments.forEach((payment) => {
    if (payment.isFixed === false) {
      variablePayments.push(payment.paymentDetail[0]);
    }
  });

  const getAnnualIncrementAmt = (
    monthCount: number,
    incrementDecrementAmt: number,
    isIncrementPct: boolean,
    paymentAmt: number,
  ) => {
    let incrementAmt = 0;

    if (monthCount >= 12 && monthCount % 12 === 0) {
      incrementAmt = isIncrementPct
        ? paymentAmt * (incrementDecrementAmt / 100)
        : incrementDecrementAmt;
    }

    return incrementAmt;
  };

  const getVariablePayments = (date: any) => {
    let variablePayment = 0;

    variablePayments.forEach((payment) => {
      let paymentAmt = payment.paymentAmt;
      const frequency = payment.paymentFrequency;
      const incrementDecrementAmt = payment.incrementDecrementAmt;
      const isIncrementPct = payment.isIncrementPct;

      // - 1 Monthly
      // - 2 Quarterly
      // - 6 One Time

      const firstPaymentDate = format(
        new Date(payment.firstPaymentDt),
        DEFAULT_DATE_FORMAT,
      );

      const lastPaymentDate = format(
        new Date(payment.lastPaymentDt),
        DEFAULT_DATE_FORMAT,
      );

      const paymentDate = moment(date);
      const startDate = moment(firstPaymentDate);
      const endDate = moment(lastPaymentDate);

      if (paymentDate.isBetween(startDate, endDate, 'months', '[]')) {
        let monthCount = 0;

        switch (frequency) {
          case 1:
            while (
              endDate > startDate ||
              startDate.format('M') === endDate.format('M')
            ) {
              if (incrementDecrementAmt && incrementDecrementAmt > 0) {
                paymentAmt += getAnnualIncrementAmt(
                  monthCount,
                  incrementDecrementAmt,
                  isIncrementPct,
                  paymentAmt,
                );
              }

              if (paymentDate.isSame(startDate, 'months')) {
                break;
              }

              startDate.add(1, 'month');
              monthCount++;
            }

            variablePayment += paymentAmt;
            break;
          case 2:
            while (
              endDate > startDate ||
              startDate.format('M') === endDate.format('M')
            ) {
              if (incrementDecrementAmt && incrementDecrementAmt > 0) {
                paymentAmt += getAnnualIncrementAmt(
                  monthCount,
                  incrementDecrementAmt,
                  isIncrementPct,
                  paymentAmt,
                );
              }

              if (
                (monthCount === 0 || monthCount % 3 === 0) &&
                paymentDate.isSame(startDate, 'months')
              ) {
                variablePayment += paymentAmt;
              }

              if (paymentDate.isSame(startDate, 'months')) {
                break;
              }

              startDate.add(1, 'month');
              monthCount++;
            }
            break;
          case 6:
            if (paymentDate.isSame(startDate, 'months')) {
              variablePayment += paymentAmt;
            }
            break;
        }
      }
    });

    return variablePayment;
  };

  if (amortizationSchedules.fixedtotal) {
    const currentMonth = currentPaymentDate.split('/')[0];
    const currentYear = currentPaymentDate.split('/')[2];
    const nextMonth = nextPaymentDate.split('/')[0];
    const nextYear = nextPaymentDate.split('/')[2];

    for (const schedule of Object.values(amortizationSchedules.fixedtotal)) {
      if (schedule && schedule.length > 0) {
        // TODO: fix once backend is giving us the variable current and next payments
        // eslint-disable-next-line no-loop-func
        schedule.forEach((entry: any) => {
          const entryDate = `${entry.month}/01/${entry.year}`;
          const variablePayment = getVariablePayments(entryDate);

          if (
            entry.month === Number(currentMonth) &&
            entry.year === Number(currentYear)
          ) {
            currentPaymentAmt = entry.cash + variablePayment;
          } else if (
            entry.month === Number(nextMonth) &&
            entry.year === Number(nextYear)
          ) {
            nextPaymentAmt = entry.cash + variablePayment;
          }
        });
      }
    }
  }

  return (
    <div className="lease-details-header">
      <div className="description-row">
        <div className="description-col">
          <CircleIcon iconURI={get(AssetIcon, leaseHeader.assetType)} />
          <div className="lease-summary">
            <StatusIndicator statusLabel={leaseHeader.leaseStatus} />
            {!isGuestUser && (
              <Tooltip
                image={pen_edit}
                showImageOnHover
                tooltipPosition="top-right"
                className="edit-lease-details-icon"
                miniTooltip
                onClick={() => showLeaseGeneralDetailsModal()}
              >
                Edit General Details
              </Tooltip>
            )}
            <div className="h1 lease-name" title={leaseHeader.assetName}>
              {leaseHeader.assetName}
            </div>
            {jurisdiction !== 'IFRS' ? (
              <Tooltip
                header="Capital vs Operating Lease"
                wrappedElement={
                  <div className="lease-description">
                    {jurisdiction
                      ? getJurisdicitionValue(
                          jurisdiction,
                          leaseHeader.isCapital,
                        )
                      : ''}
                  </div>
                }
              >
                <div>
                  {resultsCanBeShown ? (
                    leaseHeader.isCapital ? (
                      <p>
                        This lease has been classified as a <b>Capital</b> lease
                        because it has passed <b>one or more</b> of the
                        following criteria:
                      </p>
                    ) : (
                      <p>
                        This lease has been classified as an <b>Operating</b>{' '}
                        lease because it has passed <b>all</b> of the following
                        criteria:
                      </p>
                    )
                  ) : (
                    <div>
                      You selected{' '}
                      {leaseHeader.isCapital ? (
                        <>
                          a <b>Capital</b>
                        </>
                      ) : (
                        <>
                          an <b> Operating</b>
                        </>
                      )}{' '}
                      lease. We are unable to provide any details about the
                      lease classification for one of the following reasons:
                      <ul>
                        <li>
                          We were unable to run the Capital vs Operating test
                          being that no values were provided for{' '}
                          <b>Useful Life</b> and/or <b>Fair Value</b>
                        </li>
                        <br />
                        <li>A user overrode the test results</li>
                      </ul>
                    </div>
                  )}
                  {resultsCanBeShown ? (
                    <div className="cap-op-criteria">
                      <div className="cap-op-img-wrapper">
                        {capOpTestResults.canTransfer ||
                        !leaseHeader.isCapital ? (
                          <img src={lg_check} alt="leaseguru check" />
                        ) : (
                          <div />
                        )}
                      </div>
                      <div className="cap-op-criteria-wording">
                        {getCapOpResultWording(
                          'canTransfer',
                          capOpTestResults.canTransfer,
                        )}
                      </div>
                      <div className="cap-op-img-wrapper">
                        {capOpTestResults.hasBargainPurchase ||
                        !leaseHeader.isCapital ? (
                          <img src={lg_check} alt="leaseguru check" />
                        ) : (
                          <div />
                        )}
                      </div>
                      <div className="cap-op-criteria-wording">
                        {getCapOpResultWording(
                          'hasBargainPurchase',
                          capOpTestResults.hasBargainPurchase,
                        )}
                      </div>
                      <div className="cap-op-img-wrapper">
                        {capOpTestResults.usefulLife ||
                        !leaseHeader.isCapital ? (
                          <img src={lg_check} alt="leaseguru check" />
                        ) : (
                          <div />
                        )}
                      </div>
                      <div className="cap-op-criteria-wording">
                        {getCapOpResultWording(
                          'usefulLife',
                          capOpTestResults.usefulLife,
                        )}
                      </div>
                      <div className="cap-op-img-wrapper">
                        {capOpTestResults.fairValue ||
                        !leaseHeader.isCapital ? (
                          <img src={lg_check} alt="leaseguru check" />
                        ) : (
                          <div />
                        )}
                      </div>
                      <div className="cap-op-criteria-wording">
                        {getCapOpResultWording(
                          'fairValue',
                          capOpTestResults.fairValue,
                        )}
                      </div>
                      <div className="cap-op-img-wrapper">
                        {capOpTestResults.isSpecialized ||
                        !leaseHeader.isCapital ? (
                          <img src={lg_check} alt="leaseguru check" />
                        ) : (
                          <div />
                        )}
                      </div>
                      <div className="cap-op-criteria-wording">
                        {getCapOpResultWording(
                          'isSpecialized',
                          capOpTestResults.isSpecialized,
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Tooltip>
            ) : (
              <div className="lease-description">
                {jurisdiction
                  ? getJurisdicitionValue(jurisdiction, leaseHeader.isCapital)
                  : ''}
              </div>
            )}
            <div className="small-text serial-nbr">
              {formatCityStateZip(
                leaseHeader.city,
                leaseHeader.state,
                leaseHeader.zip,
              )}
            </div>
            <div className="lease-description">{leaseHeader.description}</div>
          </div>
        </div>
        <div className="description-col">
          {!isGuestUser && (
            <div className="Dropdown action">
              <button
                type="button"
                className={`dropdown-toggle ${process.env
                  .REACT_APP_ENABLE_RSM === 'true' && 'rsm-class'}`}
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                ) => {
                  e.stopPropagation();
                  handleDropdownToggle();
                }}
              >
                {'Action Menu'}
                <i className={'dropdown-arrow'} />
              </button>
              <Dropdown
                onClose={() => handleDropdownToggle(false)}
                open={isOpen}
              >
                {getDropdownOptions()}
              </Dropdown>
            </div>
          )}
        </div>
      </div>
      <div className="lease-payments">
        <div className="progress">
          <DateProgressBar
            startDate={leaseHeader.leaseBeginDt}
            endDate={leaseHeader.leaseEndDt}
            moreDetail
          />
        </div>
        <div className="payment-col">
          <span className="blue-label">Current Payment</span>
          {leaseHeader.currentPaymentDate &&
          leaseHeader.currentPaymentDate !== '0001-01-01T00:00:00' ? (
            <>
              <span className="small-normal-text payment-amount">
                <Currency value={currentPaymentAmt} />
              </span>
              <span className="small-normal-text">
                {format(
                  new Date(leaseHeader.currentPaymentDate),
                  DEFAULT_DATE_FORMAT,
                )}
              </span>
            </>
          ) : (
            <span>{String.fromCharCode(8212)}</span>
          )}
        </div>
        <div className="payment-col">
          <span className="blue-label">Next Payment</span>
          {leaseHeader.nextPaymentDate &&
          leaseHeader.nextPaymentDate !== '0001-01-01T00:00:00' ? (
            <>
              <span className="small-normal-text payment-amount">
                <Currency value={nextPaymentAmt} />
              </span>
              <span className="small-normal-text">
                {format(
                  new Date(leaseHeader.nextPaymentDate),
                  DEFAULT_DATE_FORMAT,
                )}
              </span>
            </>
          ) : (
            <span>{String.fromCharCode(8212)}</span>
          )}
        </div>
        <div className="payment-col">
          <span className="blue-label">PAYMENTS REMAINING</span>
          <span className="small-normal-text payment-amount">
            {leaseHeader.remainingPayments}
          </span>
        </div>
      </div>
    </div>
  );
};

export default withRouter(LeaseDetailsHeader);
