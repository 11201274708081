import React, { FunctionComponent, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { LoadingProcess } from '../../app';
import { KnowledgeBaseUrl } from '../../app/resources/text.json';
import lock from '../../assets/bluelock.svg';
import helpIcon from '../../assets/help-icon.svg';
import questionThingyRSMBlue1 from '../../assets/rsmBlue1QuestionThingy.svg';
import yellowStar from '../../assets/yellowStar.svg';
import { useFeatureFlags } from '../../featureQuery';
import { clearUser } from '../../features/auth/actions';
import HelpPanel from '../../features/help/HelpPanel';
import { clearAllForms } from '../../features/lease/actions';
import { clearLeases } from '../../features/leaseList/actions';
import {
  clearCurrentAdminPlan,
  clearMultiClientPlans,
} from '../../features/multiClient/actions';
import { clearPlans } from '../../features/plans/actions';
import {
  checkIfUserHasAccessToAnyFeature,
  checkIfUserHasAccessToFeature,
  checkIfUserIsOnMaxPlan,
  findNextAvailableUpgrade,
} from '../../features/plans/helpers';
import { clearQuickBooks } from '../../features/quickbooks/actions';
import { SubscriptionModalPages } from '../../features/subscriptions/SubscriptionModal';
import {
  DropdownHeaderItemKeys,
  FeatureFlagKeys,
  IHeaderDropdownItem,
  IPlanModel,
  PlanFeatureNames,
} from '../../interfaces';
import { IStore } from '../../store';
import Dropdown from '../dropdown/Dropdown';
import { hideModal, showModal } from '../modal/actions';
import { useResourceContext } from '../resource-context';
import { CheckIsMultiClientAdmin } from '../utilities/_authHelpers';
import {
  checkIfUserIsGuest,
  getListOfGuestUserHeaderDropdownItems,
} from '../utilities/_guestUserHelper';
import { isEnvRSM } from '../utilities/_helpers';
import { clearAllThirdPartyData } from '../utilities/_thirdPartyScripts';
import './Header.scss';

interface IProps extends RouteComponentProps {
  setLoading: (status: LoadingProcess) => void;
}

const Header: FunctionComponent<IProps> = ({ history, setLoading }) => {
  const resources = useResourceContext();
  const dispatch = useDispatch();

  const [openDropdown, setOpenDropdown] = useState(false);
  const [openHelpDropdown, setOpenHelpDropdown] = useState(false);
  const [showGlossaryPanel, setShowGlossaryPanel] = useState(false);

  const { getSingleFlag } = useFeatureFlags();
  const guruPlanPricingFlag = getSingleFlag(FeatureFlagKeys.PlansAndPricing);

  const user = useSelector((store: IStore) => {
    return store.user;
  });

  const plans = useSelector((store: IStore) => {
    return store.plans;
  });

  const isUserOnMaxPlan = useMemo(() => checkIfUserIsOnMaxPlan(user, plans), [
    user,
    plans,
  ]);

  const isGuestUser = checkIfUserIsGuest(user);

  const isMultiClientAdmin = CheckIsMultiClientAdmin(user);
  const isMultiClientLandingPage =
    history.location.pathname === '/multicompany-landing';

  const { company } = user;

  const leasesText: string = 'Leases';
  const journalEntriesText: string = 'Journal Entries';
  const disclosuresText: string = 'Disclosures';
  const externalToolsText: string = 'External Tools';
  const profileText: string = 'Profile';
  const upgradeYourPlanText: string = 'Upgrade Your Plan';
  const subscriptionSettings: string = 'Subscription Settings';
  const manageUsersText: string = 'Manage Users';
  const companySettingsText: string = 'Company Settings';
  const logoutText: string = 'Logout';
  const topText: string = 'Top';
  const helpText: string = 'Help';
  const knowledgeBaseText: string = 'Knowledge Base';
  const glossaryText: string = 'Glossary';

  const features = company.plan?.features;

  const userHasAccessToIntegrations = useMemo(
    () =>
      checkIfUserHasAccessToAnyFeature(
        [
          PlanFeatureNames.quickbooks,
          PlanFeatureNames.sage,
          PlanFeatureNames.xero,
        ],
        features,
        user.userRole === 1,
      ),
    [features, user],
  );

  const integrationsIsEnabled =
    process.env.REACT_APP_ENABLE_EXTERNAL_TOOLS === 'true';

  const userHasAccessToViewTotalOrg = useMemo(
    () =>
      checkIfUserHasAccessToFeature(
        PlanFeatureNames.totalOrg,
        features,
        user.userRole === 1,
      ),
    [features, user],
  );

  const userHasAccessToViewDisclosureReport = useMemo(
    () =>
      checkIfUserHasAccessToFeature(
        PlanFeatureNames.disclosures,
        features,
        user.userRole === 1,
      ),
    [features, user],
  );

  const handleKnowledgeBaseClick = () => {
    tagManagerhandler(helpText, knowledgeBaseText);
    setOpenHelpDropdown(false);
    window.open(KnowledgeBaseUrl, '_blank');
  };

  const handleGlossaryClick = () => {
    tagManagerhandler(helpText, glossaryText);
    setShowGlossaryPanel(!showGlossaryPanel);
    setOpenHelpDropdown(false);
  };

  const toggleDropdown = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setOpenDropdown(!openDropdown);
  };

  const toggleHelpDropdown = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setOpenHelpDropdown(!openHelpDropdown);
  };

  const closeDropdown = () => {
    setOpenDropdown(false);
  };

  const closeHelpDropdown = () => {
    setOpenHelpDropdown(false);
  };

  const logout = () => {
    const item: IHeaderDropdownItem | undefined = headerDropdownItems.find(
      (i: IHeaderDropdownItem) => i.key === DropdownHeaderItemKeys.logout,
    );
    if (item && item.clickAction) {
      item.clickAction();
    }
  };

  const showUpgradeModal = (tagManagerLinkText: string) => {
    tagManagerLinkText === externalToolsText
      ? tagManagerhandler(companySettingsText, externalToolsText)
      : tagManagerhandler(topText, tagManagerLinkText);

    dispatch(
      showModal({
        modal: {
          modalType: 'UPGRADE_TIER',
          modalProps: {
            open: true,
            hideModal: () => {
              dispatch(hideModal());
            },
          },
        },
      }),
    );
    closeDropdown();
  };

  const getCompanyInfoLabel = () => {
    if (isGuestUser) {
      return 'Company Information';
    }

    return companySettingsText;
  };

  const headerDropdownItems: IHeaderDropdownItem[] = [
    {
      // Profile
      label: profileText,
      key: DropdownHeaderItemKeys.profile,
      lastOfSection: !integrationsIsEnabled && !isMultiClientAdmin,
      clickAction: () => {
        tagManagerhandler(companySettingsText, profileText);
        dispatch(
          showModal({
            modal: {
              modalType: 'USER_SETTINGS',
              modalProps: {
                open: true,
                hideModal: () => {
                  dispatch(hideModal());
                },
                title: profileText,
              },
            },
          }),
        );
        closeDropdown();
      },
    },
    {
      // Subscription Settings
      label: subscriptionSettings,
      key: DropdownHeaderItemKeys.subscriptionSettings,
      lastOfSection: isMultiClientAdmin,
      clickAction: () => {
        tagManagerhandler(companySettingsText, subscriptionSettings);
        dispatch(
          showModal({
            modal: {
              modalType: 'SUBSCRIPTION_SETTINGS',
              modalProps: {
                open: true,
                hideModal: () => {
                  dispatch(hideModal());
                },
              },
            },
          }),
        );
        closeDropdown();
      },
    },
    {
      // Company Settings
      label: getCompanyInfoLabel(),
      key: DropdownHeaderItemKeys.companySettings,
      clickAction: () => {
        tagManagerhandler(companySettingsText, getCompanyInfoLabel());
        dispatch(
          showModal({
            modal: {
              modalType: 'COMPANY_SETTINGS',
              modalProps: {
                open: true,
                hideModal: () => {
                  dispatch(hideModal());
                },
                title: 'Settings',
              },
            },
          }),
        );
        closeDropdown();
      },
    },
    {
      // Manage Users
      label: manageUsersText,
      key: DropdownHeaderItemKeys.addUsers,
      lastOfSection: true,
      clickAction: () => {
        tagManagerhandler(companySettingsText, manageUsersText);
        dispatch(
          showModal({
            modal: {
              modalType: 'MANAGE_USERS',
              modalProps: {
                open: true,
                hideModal: () => {
                  dispatch(hideModal());
                },
                title: manageUsersText,
              },
            },
          }),
        );

        closeDropdown();
      },
    },
    {
      // Logout
      label: logoutText,
      key: DropdownHeaderItemKeys.logout,
      clickAction: async () => {
        tagManagerhandler(companySettingsText, logoutText);
        setLoading(LoadingProcess.UNAUTH);
        sessionStorage.setItem('userToken', '');
        sessionStorage.setItem('admin-company-selection', '');
        sessionStorage.setItem('showUpgradeBanner', '');

        TagManager.dataLayer({ dataLayer: { event: 'logout' } });

        clearAllThirdPartyData();

        dispatch(clearUser());
        dispatch(clearAllForms());
        dispatch(clearLeases());
        dispatch(clearQuickBooks());
        dispatch(clearPlans());
        dispatch(clearMultiClientPlans());
        dispatch(clearCurrentAdminPlan());
      },
    },
  ];

  const headerLogoClickHandler = () => {
    history.push('/leases');
    TagManager.dataLayer({
      dataLayer: {
        event: 'navigation_link_click',
        nav_menu: 'Header Logo',
      },
    });
  };

  const tagManagerhandler = (navMenu: string, linkText: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'navigation_link_click',
        nav_menu: navMenu,
        link_text: linkText,
      },
    });
  };

  // TODO: Temp until integrations go to prod
  const externalToolsTemp: IHeaderDropdownItem = {
    label: externalToolsText,
    key: DropdownHeaderItemKeys.externalTools,
    lastOfSection: integrationsIsEnabled,
    clickAction: () => {
      userHasAccessToIntegrations
        ? dispatch(
            showModal({
              modal: {
                modalType: 'EXTERNAL_TOOLS',
                modalProps: {
                  open: true,
                  hideModal: () => {
                    dispatch(hideModal());
                  },
                  title: externalToolsText,
                },
              },
            }),
          )
        : showUpgradeModal(externalToolsText);
      closeDropdown();
    },
  };

  const upgradeYourPlan: IHeaderDropdownItem = {
    label: upgradeYourPlanText,
    key: DropdownHeaderItemKeys.upgradeYourPlan,
    icon: (
      <img src={yellowStar} alt="dropdown-star" className="dropdown-star" />
    ),
    highlightLabel: true,
    clickAction: () => {
      tagManagerhandler(companySettingsText, upgradeYourPlanText);
      if (isUserOnMaxPlan) {
        const enterpriseUrl = resources.text('EnterprisePricingUrl');
        window.open(enterpriseUrl, '_blank');
        closeDropdown();
        return;
      }

      const availablePlanToUpgradeTo = findNextAvailableUpgrade(
        plans,
        company.plan!,
      );

      showCheckoutModal(availablePlanToUpgradeTo);

      closeDropdown();
    },
  };

  const showCheckoutModal = (
    availablePlanToUpgradeTo: IPlanModel | undefined,
  ) => {
    dispatch(
      showModal({
        modal: {
          modalType: 'SUBSCRIPTIONS',
          modalProps: {
            open: true,
            hideModal,
            initialSubscriptionModalPage: SubscriptionModalPages.payment,
            initialPlanFeatures: availablePlanToUpgradeTo?.features,
            initialSelectedPlan: availablePlanToUpgradeTo,
          },
        },
      }),
    );
  };

  // TODO: Temp until premium features go to prod
  const getDropdownOptions = () => {
    // limit dropdown options if guest user
    if (isGuestUser) {
      const listOfGuestUserHeaderDropdownItems = getListOfGuestUserHeaderDropdownItems(
        headerDropdownItems,
      );

      return listOfGuestUserHeaderDropdownItems;
    }

    // limit dropdown options if multi-client user
    if (isMultiClientAdmin) {
      const logoutItem = headerDropdownItems.slice(-1);
      if (isMultiClientLandingPage) {
        const displayItems = headerDropdownItems.splice(0, 2);
        return [...displayItems, ...logoutItem];
      } else {
        const displayItems = headerDropdownItems.splice(2, 2);
        return [...displayItems, ...logoutItem];
      }
    }

    if (guruPlanPricingFlag) {
      headerDropdownItems.splice(1, 0, upgradeYourPlan);
    }

    if (integrationsIsEnabled) {
      headerDropdownItems.splice(1, 0, externalToolsTemp);
    }

    return headerDropdownItems;
  };

  const isRSM = isEnvRSM();

  //#region Multi-Client Landing Page Header

  if (isMultiClientLandingPage) {
    return (
      <div className="Header">
        <div className="header-content">
          <img
            className="header-logo no-click"
            src={resources.img('HeaderLogo')}
            alt={resources.text('HeaderLogoAlt')}
          />
          <div className="nav-wrapper" />
          <div className="header-right">
            <div className="header-dropdown">
              <div className="Dropdown header">
                <button
                  type="button"
                  className="dropdown-toggle"
                  onClick={toggleDropdown}
                >
                  {user && `${user.firstName + ` ` + user.lastName}`}
                  <i className="dropdown-arrow" />
                </button>
                <Dropdown onClose={closeDropdown} open={openDropdown}>
                  {getDropdownOptions().map((item: IHeaderDropdownItem) => {
                    return (
                      <div key={`${item.key}-index`}>
                        <li
                          id={`Dropdown-menu-item-${item.key}`}
                          className="dropdown-menu--item link-text"
                          key={item.key}
                          onClick={item.clickAction}
                        >
                          {item.icon ? item.icon : null}
                          <span
                            className={`${
                              item.highlightLabel ? 'label-color-highlight' : ''
                            }`}
                          >
                            {item.label}
                          </span>
                        </li>
                        {item.lastOfSection && (
                          <div className="dropdown-menu--hr" />
                        )}
                      </div>
                    );
                  })}
                </Dropdown>
              </div>
            </div>
            <div className="header-dropdown">
              <div className="Dropdown header">
                <button
                  type="button"
                  className="dropdown-toggle help"
                  onClick={toggleHelpDropdown}
                >
                  Help
                  <img
                    src={isRSM ? questionThingyRSMBlue1 : helpIcon}
                    alt="question mark"
                    className="tooltip-image"
                  />
                </button>
                <div className="help-dropdown-menu">
                  <Dropdown onClose={closeHelpDropdown} open={openHelpDropdown}>
                    <li
                      className="dropdown-menu--item link-text"
                      onClick={handleKnowledgeBaseClick}
                    >
                      {knowledgeBaseText}
                    </li>
                    <li
                      className="dropdown-menu--item link-text"
                      onClick={handleGlossaryClick}
                    >
                      {glossaryText}
                    </li>
                  </Dropdown>
                </div>
                <HelpPanel
                  showPanel={showGlossaryPanel}
                  toggleShowPanel={handleGlossaryClick}
                />
              </div>
            </div>
          </div>

          <div className="header-dropdown-mobile">
            {user && `${user.firstName + ` ` + user.lastName}`}
            <button type="button" className="header-logout" onClick={logout}>
              Logout
            </button>
          </div>
        </div>
      </div>
    );
  }

  //#endregion Multi-Client Landing Page Header

  return (
    <div className={`Header ${isRSM && 'rsm-class-header'}`}>
      <div className="header-content">
        <img
          className="header-logo"
          src={resources.img('HeaderLogo')}
          alt={resources.text('HeaderLogoAlt')}
          onClick={() => {
            history.push('/leases');
            headerLogoClickHandler();
          }}
        />
        <div className="nav-wrapper">
          <NavLink
            to="/leases"
            className="nav-item"
            activeClassName="active"
            onClick={() => tagManagerhandler(topText, leasesText)}
          >
            {leasesText}
          </NavLink>
          {userHasAccessToViewTotalOrg ? (
            <NavLink
              to="/total-je"
              className="nav-item"
              activeClassName="active"
              onClick={() => tagManagerhandler(topText, journalEntriesText)}
            >
              {journalEntriesText}
            </NavLink>
          ) : (
            <button
              onClick={() => showUpgradeModal(journalEntriesText)}
              className="block-link"
              data-effect="mfp-zoom-in"
            >
              {journalEntriesText}
              <img src={lock} alt="header-lock" className="header-lock" />
            </button>
          )}
          {userHasAccessToViewDisclosureReport ? (
            <NavLink
              to="/disclosures"
              className="nav-item"
              activeClassName="active"
              onClick={() => tagManagerhandler(topText, disclosuresText)}
            >
              {disclosuresText}
            </NavLink>
          ) : (
            <button
              onClick={() => showUpgradeModal(disclosuresText)}
              className="block-link"
            >
              {disclosuresText}
              <img src={lock} alt="header-lock" className="header-lock" />
            </button>
          )}
        </div>
        <div className="header-right">
          <div className="header-dropdown">
            <div className={`Dropdown header ${isRSM && 'rsm-class-dropdown'}`}>
              <button
                type="button"
                className="dropdown-toggle"
                onClick={toggleDropdown}
              >
                {user.company && user.company.companyName}
                <i className={'dropdown-arrow'} />
              </button>
              <Dropdown onClose={closeDropdown} open={openDropdown}>
                {getDropdownOptions().map(
                  (item: IHeaderDropdownItem, index: number) => {
                    return (
                      <div key={`${item.key}-index`}>
                        <li
                          id={`Dropdown-menu-item-${item.key}`}
                          className="dropdown-menu--item link-text"
                          key={item.key}
                          onClick={item.clickAction}
                        >
                          {item.icon ? item.icon : null}
                          <span
                            className={`${
                              item.highlightLabel ? 'label-color-highlight' : ''
                            }`}
                          >
                            {item.label}
                          </span>
                          {!userHasAccessToIntegrations &&
                          item.key === DropdownHeaderItemKeys.externalTools ? (
                            <img
                              src={lock}
                              alt="header-lock"
                              className="dropdown-lock"
                            />
                          ) : null}
                        </li>
                        {item.lastOfSection && (
                          <div className="dropdown-menu--hr" />
                        )}
                      </div>
                    );
                  },
                )}
              </Dropdown>
            </div>
          </div>
          <div className="header-dropdown">
            <div className={`Dropdown header ${isRSM && 'rsm-class-dropdown'}`}>
              <button
                type="button"
                className="dropdown-toggle help"
                onClick={toggleHelpDropdown}
              >
                Help
                <img
                  src={isRSM ? questionThingyRSMBlue1 : helpIcon}
                  alt="question mark"
                  className="tooltip-image"
                />
              </button>
              <div className="help-dropdown-menu">
                <Dropdown onClose={closeHelpDropdown} open={openHelpDropdown}>
                  <li
                    className="dropdown-menu--item link-text"
                    onClick={handleKnowledgeBaseClick}
                  >
                    {knowledgeBaseText}
                  </li>
                  <li
                    className="dropdown-menu--item link-text"
                    onClick={handleGlossaryClick}
                  >
                    Glossary
                  </li>
                </Dropdown>
              </div>
              <HelpPanel
                showPanel={showGlossaryPanel}
                toggleShowPanel={handleGlossaryClick}
              />
            </div>
          </div>
        </div>

        <div className="header-dropdown-mobile">
          {user.company ? user.company.companyName : null}
          <button type="button" className="header-logout" onClick={logout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Header);
