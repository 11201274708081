import React, { FunctionComponent } from 'react';
import { useFeatureFlags } from '../../../featureQuery';

import { FeatureFlagKeys, ILeaseHeaderModel } from '../../../interfaces';
import { LeaseCard } from '../index';
import AddLeaseCard from './AddLeaseCard';
import MaxLeaseCard from './MaxLeaseCard';
import MaxLeaseCardGrid from './MaxLeaseCardGrid';

interface IProps {
  leases: ILeaseHeaderModel[];
  leaseCount: number;
  maxLeases: any;
  addToRecentList: (lease: ILeaseHeaderModel) => void;
  isQuickFilterEnabled: boolean;
  isGuestUser: boolean;
}

const LeasesCardView: FunctionComponent<IProps> = ({
  leases,
  leaseCount,
  maxLeases,
  addToRecentList,
  isQuickFilterEnabled,
  isGuestUser,
}) => {
  const { getSingleFlag } = useFeatureFlags();
  const guruPlanPricingFlag = getSingleFlag(FeatureFlagKeys.PlansAndPricing);

  return (
    <div className="leases-card-view">
      {leases &&
        leases.map((lease) => (
          <LeaseCard
            key={lease.leaseId}
            lease={lease}
            addToRecentList={addToRecentList}
          />
        ))}
      {!isQuickFilterEnabled && !isGuestUser ? (
        <>
          {leaseCount >= maxLeases ? (
            guruPlanPricingFlag ? (
              <MaxLeaseCardGrid leaseCount={leaseCount} maxLeases={maxLeases} />
            ) : (
              <MaxLeaseCard />
            )
          ) : (
            <AddLeaseCard />
          )}
        </>
      ) : null}
    </div>
  );
};

export default LeasesCardView;
