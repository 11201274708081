import { ILeaseModel } from '../../interfaces';
import { IAdditionalFormState } from './add-lease-forms/AdditionalPaymentsForm';
import { IDateFormState } from './add-lease-forms/DateForm';
import { IGeneralFormState } from './add-lease-forms/GeneralForm';
import { IOptionsFormState } from './add-lease-forms/OptionsForm';

export enum LeaseForms {
  GENERAL = 'GENERAL',
  DATE = 'DATE',
  OPTIONS = 'OPTIONS',
  BASE = 'BASE',
  ADDL = 'ADDL',
}

export enum LeaseActions {
  SAVE_FORM = 'SAVE_FORM',
  CLEAR_FORM = 'CLEAR_FORM',
  CLEAR_ALL_FORMS = 'CLEAR_ALL_FORMS',
  SAVE_LEASE_DATA = 'SAVE_LEASE_DATA',
  SET_RELOAD_LEASES = 'SET_RELOAD_LEASES',
  POPULATE_ALL_FORMS = 'POPULATE_ALL_FORMS',
  SET_RELOAD_LEASE_DETAILS = 'SET_RELOAD_LEASE_DETAILS',
  POPULATE_SELECT_FORMS = 'POPULATE_SELECT_FORMS',
  OVERRIDE_SELECT_FORM = 'OVERRIDE_SELECT_FORM',
}

export const saveForm = (
  id: string,
  form:
    | IGeneralFormState
    | IDateFormState
    | IAdditionalFormState
    | IOptionsFormState,
) => ({
  type: LeaseActions.SAVE_FORM,
  payload: { id, form },
});

export const populateAllForms = (selectedLease: ILeaseModel) => ({
  type: LeaseActions.POPULATE_ALL_FORMS,
  payload: selectedLease,
});

export const populateSelectForms = (
  forms: LeaseForms[],
  selectedLease: ILeaseModel,
) => ({
  type: LeaseActions.POPULATE_SELECT_FORMS,
  payload: { forms, selectedLease },
});

export const overrideSelectForm = (
  form: LeaseForms,
  object:
    | IGeneralFormState
    | IDateFormState
    | IAdditionalFormState
    | IOptionsFormState,
) => ({ type: LeaseActions.OVERRIDE_SELECT_FORM, payload: { form, object } });

export const clearForm = (id: string | string[]) => ({
  type: LeaseActions.CLEAR_FORM,
  payload: { id },
});

export const clearAllForms = () => ({
  type: LeaseActions.CLEAR_ALL_FORMS,
});

export const saveLeaseData = (lease: any) => ({
  type: LeaseActions.SAVE_LEASE_DATA,
  payload: lease,
});

export const setReloadLeases = (reload: boolean) => ({
  type: LeaseActions.SET_RELOAD_LEASES,
  payload: reload,
});

export const setReloadLeaseDetails = (reload: boolean) => ({
  type: LeaseActions.SET_RELOAD_LEASE_DETAILS,
  payload: reload,
});
