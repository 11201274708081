import { Form, FormikContext } from 'formik';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Input } from '../../../common';
import { Button } from '../../../common/buttons';
import { AccentedHeader } from '../../../common/headers';
import {
  fieldHasError,
  shouldDisableSubmit,
} from '../../../common/utilities/FormikHelpers';
import { AuthCard, AuthCardSection } from '../../auth/AuthCard';

export interface IEmailConfirmationFormState {
  email: string;
}

interface IEmailConfirmationFormProps {
  formProps: FormikContext<IEmailConfirmationFormState>;
  processing?: boolean;
}

const EmailConfirmationForm: FunctionComponent<IEmailConfirmationFormProps> = ({
  formProps,
  processing,
}) => {
  return (
    <Form noValidate={true}>
      <AuthCard>
        <AuthCardSection>
          <AccentedHeader color="black" centered>
            Forgot Your Password?
          </AccentedHeader>
          <br />
          <Input
            label="Email Address"
            required
            name="email"
            type="email"
            error={fieldHasError(formProps, 'email')}
            errorMessage={formProps.errors.email}
            value={formProps.values.email}
            onChange={formProps.handleChange}
          />
          <div className="submit">
            <Button
              buttonType="positive"
              buttonText="Send Email"
              type="submit"
              block={true}
              processing={processing}
              disabled={shouldDisableSubmit(formProps) || processing}
            />
          </div>
          <div className="back">
            <Link to="/auth/sign-in">
              <Button buttonType="neutral" buttonText="Cancel" block={true} />
            </Link>
          </div>
        </AuthCardSection>
      </AuthCard>
    </Form>
  );
};

export default EmailConfirmationForm;
