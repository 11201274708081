import React, { FunctionComponent } from 'react';
import { useResourceContext } from '../../common/resource-context';
import './Footer.scss';

const Footer: FunctionComponent = () => {
  const resources = useResourceContext();
  const isRSM = process.env.REACT_APP_ENABLE_RSM === 'true';

  return (
    <div className="footer">
      Questions? Contact us at &nbsp;
      <a
        href={resources.img('General_Email_MailTo')}
        className={`${isRSM && 'rsm'}`}
      >
        {resources.img('General_Email_Address')}
      </a>
    </div>
  );
};

export default Footer;
