import React, { useEffect, useState } from 'react';

import Filter from '../../assets/filter.svg';
import RSMFilter from '../../assets/rsmfilter.svg';
import FilterSelection from '../../common/filter/FilterSelection';
import FilterButtons from '../../common/pills/FilterButtons';
import MultiSelectPill from '../../common/pills/MultiSelectPill';
import FilterExpandSection from '../../common/sections/FilterExpandSection';
import { FilterTypes, IFilterCategories, IMinMaxModel } from './Dashboard';

import './FilterContainer.scss';

interface IFilterContainerProps {
  activeFilters: IFilterCategories;
  onFilterSubmit: (
    filterValues: IMinMaxModel | string,
    filterName: keyof IFilterCategories,
    filterType: FilterTypes,
  ) => void;
  onFilterRemove: (
    filterName: keyof IFilterCategories,
    index: number,
    filterType: FilterTypes,
  ) => void;
  onClearAllFilters: () => void;
}

const FilterContainer = ({
  activeFilters,
  onFilterSubmit,
  onFilterRemove,
  onClearAllFilters,
}: IFilterContainerProps) => {
  const [forceOpenSection, setForceOpenSection] = useState<boolean>(false);
  const [buttonValue, setButtonValue] = useState('hide');
  const [openAndActiveSections, setOpenAndActiveSections] = useState<string[]>(
    [],
  );
  const [activeFilterSections, setActiveFilterSections] = useState<string[]>(
    [],
  );

  useEffect(() => {
    const listOfActive: string[] = [];
    const currentFilters: any = activeFilters;
    Object.keys(currentFilters).forEach((filter) => {
      if (currentFilters[filter].length) {
        listOfActive.push(filter);
      }
    });
    setActiveFilterSections(listOfActive);
  }, [activeFilters, openAndActiveSections]);

  const clickShowClearButton = (buttonProp: string) => {
    if (buttonProp === 'seeAll') {
      // force all sections with filters to open
      setForceOpenSection(true);
      setOpenAndActiveSections(activeFilterSections);
    }
    if (buttonProp === 'clearAll') {
      // reset all values
      onClearAllFilters();
      setForceOpenSection(false);
      setOpenAndActiveSections([]);
    }
  };

  useEffect(() => {
    if (
      // all sections with filters are open
      activeFilterSections.length !== 0 &&
      activeFilterSections.sort().every((value, index) => {
        return value === openAndActiveSections.sort()[index];
      })
    ) {
      setButtonValue('clearAll');
    } else if (
      // all active sections with filters are not open
      activeFilterSections.length !== 0 &&
      !activeFilterSections.sort().every((value, index) => {
        return value === openAndActiveSections.sort()[index];
      })
    ) {
      setButtonValue('seeAll');
      setForceOpenSection(false);
    } else {
      // zero active filters
      setButtonValue('hide');
      setForceOpenSection(false);
    }
  }, [activeFilterSections, activeFilters, openAndActiveSections]);

  const onOpenAndActive = (isOpen: boolean, sectionTitle: string) => {
    const updatedOpenAndActive: string[] = [...openAndActiveSections];
    if (updatedOpenAndActive.includes(sectionTitle) && isOpen) {
      return;
    }
    if (isOpen === true) {
      updatedOpenAndActive.push(sectionTitle);
      setOpenAndActiveSections(updatedOpenAndActive);
    }
    if (isOpen === false && updatedOpenAndActive.includes(sectionTitle)) {
      updatedOpenAndActive.splice(
        updatedOpenAndActive.indexOf(sectionTitle),
        1,
      );
      setOpenAndActiveSections(updatedOpenAndActive);
    }
  };

  return (
    <div className="side-filters">
      <div className="filter-header">
        <div className="filter-gradient" />
        <div className="filter-header-label">
          <img
            src={
              process.env.REACT_APP_ENABLE_RSM === 'true' ? RSMFilter : Filter
            }
            alt="filter"
          />
          <label>FILTERS</label>
          <div className="filter-show">
            <button
              onClick={() => clickShowClearButton(buttonValue)}
              className={buttonValue}
            >
              {buttonValue !== 'hide' && buttonValue === 'clearAll'
                ? 'Clear All'
                : 'See All'}
              <br />
              Active Filters
            </button>
          </div>
        </div>
      </div>

      <div className="filter-section">
        <div className="filter">
          <FilterExpandSection
            label="Next Payment Date"
            hasActiveFilter={activeFilters.nextPaymentDate.length !== 0}
            openSesame={
              activeFilters.nextPaymentDate.length && forceOpenSection && true
            }
            isOpenAndActive={(isOpen: boolean) =>
              onOpenAndActive(isOpen, 'nextPaymentDate')
            }
          >
            <FilterSelection
              title="Time Frame"
              filterType="date"
              filterName="nextPaymentDate"
              onFilterSubmit={onFilterSubmit}
            />
            {activeFilters.nextPaymentDate.map(
              (item: IMinMaxModel, index: number) => (
                <MultiSelectPill
                  type="date"
                  min={item.minValue}
                  max={item.maxValue}
                  key={index}
                  onRemove={() =>
                    onFilterRemove('nextPaymentDate', index, 'date')
                  }
                />
              ),
            )}
          </FilterExpandSection>

          <FilterExpandSection
            label="Next Payment Amount"
            hasActiveFilter={activeFilters.nextPaymentAmt.length !== 0}
            openSesame={
              activeFilters.nextPaymentAmt.length && forceOpenSection && true
            }
            isOpenAndActive={(isOpen: boolean) =>
              onOpenAndActive(isOpen, 'nextPaymentAmt')
            }
          >
            <FilterSelection
              title="Amount Range"
              filterName="nextPaymentAmt"
              onFilterSubmit={onFilterSubmit}
              filterType="minMax"
            />
            {activeFilters.nextPaymentAmt.map(
              (item: IMinMaxModel, index: number) => (
                <MultiSelectPill
                  type="minMax"
                  min={item.minValue}
                  max={item.maxValue}
                  key={index}
                  onRemove={() =>
                    onFilterRemove('nextPaymentAmt', index, 'minMax')
                  }
                />
              ),
            )}
          </FilterExpandSection>

          <FilterExpandSection
            label="Remaining Payments"
            hasActiveFilter={activeFilters.remainingPayments.length !== 0}
            openSesame={
              activeFilters.remainingPayments.length && forceOpenSection && true
            }
            isOpenAndActive={(isOpen: boolean) =>
              onOpenAndActive(isOpen, 'remainingPayments')
            }
          >
            <FilterSelection
              title="Quantity Range"
              filterName="remainingPayments"
              onFilterSubmit={onFilterSubmit}
              filterType="minMax"
            />
            {activeFilters.remainingPayments.map(
              (item: IMinMaxModel, index: number) => (
                <MultiSelectPill
                  type="minMax"
                  min={item.minValue}
                  max={item.maxValue}
                  key={index}
                  onRemove={() =>
                    onFilterRemove('remainingPayments', index, 'minMax')
                  }
                />
              ),
            )}
          </FilterExpandSection>

          <FilterExpandSection
            label="Start Date"
            hasActiveFilter={activeFilters.leaseBeginDt.length !== 0}
            openSesame={
              activeFilters.leaseBeginDt.length && forceOpenSection && true
            }
            isOpenAndActive={(isOpen: boolean) =>
              onOpenAndActive(isOpen, 'leaseBeginDt')
            }
          >
            <FilterSelection
              title="Time Frame"
              filterType="date"
              onFilterSubmit={onFilterSubmit}
              filterName="leaseBeginDt"
            />
            {activeFilters.leaseBeginDt.map(
              (item: IMinMaxModel, index: number) => (
                <MultiSelectPill
                  type="date"
                  min={item.minValue}
                  max={item.maxValue}
                  key={index}
                  onRemove={() => onFilterRemove('leaseBeginDt', index, 'date')}
                />
              ),
            )}
          </FilterExpandSection>

          <FilterExpandSection
            label="End Date"
            hasActiveFilter={activeFilters.leaseEndDt.length !== 0}
            openSesame={
              activeFilters.leaseEndDt.length && forceOpenSection && true
            }
            isOpenAndActive={(val: boolean) =>
              onOpenAndActive(val, 'leaseEndDt')
            }
          >
            <FilterSelection
              title="Time Frame"
              filterType="date"
              onFilterSubmit={onFilterSubmit}
              filterName="leaseEndDt"
            />
            {activeFilters.leaseEndDt.map(
              (item: IMinMaxModel, index: number) => (
                <MultiSelectPill
                  type="date"
                  min={item.minValue}
                  max={item.maxValue}
                  key={index}
                  onRemove={() => onFilterRemove('leaseEndDt', index, 'date')}
                />
              ),
            )}
          </FilterExpandSection>

          <FilterExpandSection
            label="Asset Type"
            hasActiveFilter={activeFilters.assetType.length !== 0}
            openSesame={
              activeFilters.assetType.length && forceOpenSection && true
            }
            isOpenAndActive={(val: boolean) =>
              onOpenAndActive(val, 'assetType')
            }
          >
            <FilterButtons
              onFilterRemove={onFilterRemove}
              onFilterSubmit={onFilterSubmit}
              activeTypes={activeFilters.assetType}
            />
          </FilterExpandSection>
        </div>
      </div>
    </div>
  );
};
export default FilterContainer;
