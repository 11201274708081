import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from '../../../common/modal/actions';
import { SubscriptionModalPages } from '../../subscriptions/SubscriptionModal';
import './MaxCompanyCard.scss';
const MaxCompanyCard: FunctionComponent = () => {
  const dispatch = useDispatch();
  const showUpgradeCPAClientModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'SUBSCRIPTIONS',
          modalProps: {
            open: true,
            hideModal: () => dispatch(hideModal()),
            initialSubscriptionModalPage: SubscriptionModalPages.payment,
          },
        },
      }),
    );
  };
  return (
    <div className="max-company-card-wrapper">
      <div className="card-title">Purchase now to add up to 5 companies.</div>
      <div className="card-description">$500 / company, yearly</div>
      <button type="button" onClick={showUpgradeCPAClientModal}>
        BUY NOW
      </button>
    </div>
  );
};

export default MaxCompanyCard;
