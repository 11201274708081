import React from 'react';
import { useResourceContext } from '../../common/resource-context';

import './ManageUsersModal.scss';

interface IMoreUsersUpgradeLinkProps {
  userType: string;
}

const MoreUsersUpgradeLink: React.FC<IMoreUsersUpgradeLinkProps> = ({
  userType,
}) => {
  const resources = useResourceContext();
  const enterpriseUrl = resources.text('EnterprisePricingUrl');

  return (
    <div className={`need-more-users-${userType}`}>
      <label>
        Need more {userType} users?&nbsp;
        <a href={enterpriseUrl} target="_blank" rel="noreferrer noopener">
          Talk to us about Enterprise.
        </a>
      </label>
    </div>
  );
};

export default MoreUsersUpgradeLink;
