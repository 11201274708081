import React, { FunctionComponent } from 'react';

import './Headers.scss';

interface IAccentedHeaderProps {
  color?: HeaderColors;
  centered?: boolean;
}

type HeaderColors = 'blue' | 'black';

const AccentedHeader: FunctionComponent<IAccentedHeaderProps> = ({
  color = 'blue',
  centered = true,
  children,
}) => {
  return (
    <div className={`header-wrapper ${centered ? '--centered' : ''}`}>
      <div className={`accented-header --${color}`}>{children}</div>
    </div>
  );
};

export default AccentedHeader;
