import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { getClient, getClientUserToken, getLeasesByClient } from '../../../api';

import {
  ICompanyModel,
  ICPACompanyDetail,
  IUserModel,
} from '../../../interfaces';
import { IStore } from '../../../store';
import { saveUser } from '../../auth/actions';
import { storeLeases } from '../../leaseList/actions';
import DeleteCompany from './DeleteCompany';

import { setBearerToken } from '../../../common/utilities/_authHelpers';
import './CompanyCard.scss';

export interface CompanyCardProps {
  company: ICPACompanyDetail;
  onDelete: (companyId: number) => void;
}

function CompanyCard({ company, onDelete }: CompanyCardProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const cpaUser: IUserModel = useSelector((state: IStore) => state.user);

  const handleClick = async () => {
    const selectedCompany: ICompanyModel = await getClient(company.id);
    const leases = await getLeasesByClient(Number(company.id));
    const newToken: string = await getClientUserToken(Number(company.id));

    if (selectedCompany && leases && newToken) {
      const updatedUser = {
        ...cpaUser,
        company: selectedCompany,
        authToken: newToken,
      };

      setBearerToken(newToken);
      dispatch(saveUser(updatedUser));
      dispatch(storeLeases(leases));

      history.push('/leases');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      handleClick();
    }
  };

  return (
    <>
      <div
        className="company-card-wrapper"
        onClick={handleClick}
        onKeyDown={(e) => handleKeyDown(e)}
      >
        <DeleteCompany company={company} onDelete={onDelete} />
        <div className="company-name-wrapper">
          <div className="company-name">{company.name}</div>
        </div>
        <div className="lease-number">
          NUMBER OF LEASES
          <span className="lease-count">{company.leaseCount}</span>
        </div>
      </div>
    </>
  );
}

export default CompanyCard;
