import buyNowCalculator from './buyNowCalculator.svg';
import building from './circle-icons/building.svg';
import car from './circle-icons/car.svg';
import construction from './circle-icons/construction.svg';
import equipment from './circle-icons/equipment.svg';
import food from './circle-icons/food.svg';
import gears from './circle-icons/gears.svg';
import lab from './circle-icons/lab.svg';
import land from './circle-icons/land.svg';
import manufacturing from './circle-icons/manufacturing.svg';
import map from './circle-icons/map.svg';
import medical from './circle-icons/medical.svg';
import natural from './circle-icons/natural.svg';
import office from './circle-icons/office.svg';
import other from './circle-icons/other.svg';
import retail from './circle-icons/retail.svg';
import server from './circle-icons/server.svg';
import tools from './circle-icons/tools.svg';
import truck from './circle-icons/truck.svg';
import vehicle from './circle-icons/vehicle.svg';
import warehouse from './circle-icons/warehouse.svg';
import active from './status-indicator-icons/active-lease-icon.svg';
import expired from './status-indicator-icons/expired-lease-icon.svg';
import expiresSoon from './status-indicator-icons/expiring-soon-lease-icon.svg';

export const statusIcons: IImageFiles = {
  active,
  expired,
  expiresSoon,
};

export const circleIcons: IImageFiles = {
  car,
  construction,
  food,
  gears,
  lab,
  manufacturing,
  map,
  medical,
  natural,
  building,
  office,
  retail,
  equipment,
  land,
  vehicle,
  other,
  server,
  tools,
  truck,
  warehouse,
  buyNowCalculator,
};

interface IImageFiles {
  [key: string]: string;
}
