import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { Form, Formik, FormikProps, FormikState } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  getActivePlans,
  getClient,
  getCountryList,
  getCurrentUser,
  makeStripePayment,
} from '../../api';
import visa from '../../assets/1.png';
import discover from '../../assets/14.png';
import ms from '../../assets/2.png';
import american from '../../assets/22.png';
import checkmark from '../../assets/checkmark.svg';
import lock from '../../assets/lock.svg';
import { Autocomplete, Divider, Input, Section } from '../../common';
import { IAutocompleteOption } from '../../common/autocomplete/Autocomplete';
import { Button } from '../../common/buttons';
import FormError from '../../common/form-error/FormError';
import FormGrid from '../../common/form-grid/FormGrid';
import { useResourceContext } from '../../common/resource-context';
import { processErrorMessage } from '../../common/utilities/_helpers';
import { loadGetSiteControlData } from '../../common/utilities/_thirdPartyScripts';
import { fieldHasError } from '../../common/utilities/FormikHelpers';
import { saveUser } from '../../features/auth/actions';
import { storePlans } from '../../features/plans/actions';
import {
  ICompanyModel,
  IPaymentMethodModel,
  IPlanFeatureModel,
  IPlanModel,
  IUserModel,
} from '../../interfaces';
import { IStore } from '../../store';
import { updateUserCompanySettings } from '../auth/actions';
import {
  filterFeaturesByActive,
  getFeatureDisplayText,
} from '../plans/helpers';
import { displayCurrency, renewalDate, toTitleCase } from './helpers';
import { IPaymentFormState, validateCountryZip } from './helpers/taxHelpers';
import './OldPaymentPage.scss';
import { SubscriptionModalPages } from './SubscriptionModal';

export interface ICheckoutFormState {
  addressLine1: string;
  city: string;
  zip: string;
  country?: string;
}

interface IPaymentPageProps {
  proratedPrice?: number;
  selectedPlan: IPlanModel;
  setNavigation: (navigateTo: SubscriptionModalPages) => void;
  setTotalWithTax: (val: string) => void;
}

interface ICountryOptions {
  key: number;
  value: string;
  label: string;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || '');

const OldPaymentPage: React.FC<IPaymentPageProps> = ({
  proratedPrice,
  selectedPlan,
  setNavigation,
  setTotalWithTax,
}) => {
  const resources = useResourceContext();
  const loggedInUser: IUserModel = useSelector((state: IStore) => state.user);
  const formikRef = useRef<Formik<ICheckoutFormState>>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const companySubscription = useSelector((state: IStore) => {
    return state.user.company?.subscription;
  });

  const selectedPlanActiveFeatures = useMemo(
    () => filterFeaturesByActive(selectedPlan.features),
    [selectedPlan.features],
  );

  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();

  const PaymentFormSchema = (USA: boolean) =>
    yup.object().shape({
      addressLine1: yup
        .string()
        .label('Address')
        .required(),
      city: yup
        .string()
        .label('City')
        .required(),
      country: yup
        .string()
        .label('Country')
        .required(' '),
      zip: USA
        ? yup
            .string()
            .min(5, 'Please enter a valid Zip/Postal Code')
            .label('Zip')
            .required('Zip / Postal Code is a required field')
        : yup
            .string()
            .required('Zip / Postal Code is a required field')
            .label('Zip'),
    });

  const handleBack = () => {
    setNavigation(SubscriptionModalPages.calculator);
  };

  const handleSubmit = async (values: ICheckoutFormState) => {
    setErrorMessage('');
    setProcessing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: loggedInUser.company.companyName,
          email: loggedInUser.email,
          address: {
            city: values.city,
            line1: values.addressLine1,
            postal_code: values.zip,
          },
        },
      });

      if (error || !paymentMethod) {
        setErrorMessage('There was an issue with your payment method');
        setProcessing(false);
        return;
      } else {
        const stripePaymentMethod: IPaymentMethodModel = {
          paymentMethodId: paymentMethod.id,
          companyEmail: loggedInUser.email,
          companyName: loggedInUser.company.companyName,
          companyAddress: values.addressLine1,
          city: values.city,
          zip: values.zip,
          planId: selectedPlan?.id.toString(),
          stripePlanId: selectedPlan?.stripePlanId,
          country: values.country,
        };

        try {
          const response = await makeStripePayment(stripePaymentMethod);

          if (response) {
            setNavigation(SubscriptionModalPages.confirm);
            const updatedCompany: ICompanyModel = {
              ...loggedInUser.company,

              subscription: {
                addressModel: {
                  companyAddress: values.addressLine1,
                  city: values.city,
                  zip: values.zip,
                  country: values.country,
                },
              },
            };
            dispatch(updateUserCompanySettings(updatedCompany));

            // reload the user
            let user: IUserModel = await getCurrentUser();

            loadGetSiteControlData(user);

            if (user.userRole === 1) {
              const companySelection = Number(
                sessionStorage.getItem('admin-company-selection'),
              );

              if (companySelection) {
                const selectedCompany = await getClient(companySelection);
                if (selectedCompany) {
                  user = { ...user, company: selectedCompany };
                }
              }
            }

            // load all LeaseGuru Plans
            await loadPlans();

            dispatch(saveUser(user));
          }
        } catch (error) {
          setErrorMessage(
            'Oops! Payment was unsuccessful. ' + processErrorMessage(error),
          );
        }
      }

      setProcessing(false);
    }
  };

  const loadPlans = async () => {
    if (loggedInUser.company?.jurisdiction) {
      const planData = await getActivePlans(loggedInUser.company.jurisdiction);

      dispatch(storePlans(planData));
    }
  };

  const handleStripeChange = (event: StripeCardElementChangeEvent) => {
    if (event.error) {
      setErrorMessage(event.error.message);
    } else {
      setErrorMessage('');
    }
  };

  const initalPaymentFormState: IPaymentFormState = {
    total: undefined,
    subTotal: proratedPrice
      ? displayCurrency(proratedPrice)
      : displayCurrency(selectedPlan.price),
    tax: undefined,
    displayZipError: false,
    taxError: undefined,
    disablePay: true,
    isUSA: true,
    taxInProgress: 'Enter contact information to calculate tax',
    totalWithTax: '',
  };

  const [paymentFormState, updatePaymentFormState] = useState<
    IPaymentFormState
  >(initalPaymentFormState);

  const [countryList, setCountries] = useState<ICountryOptions[]>([
    { key: 0, value: '', label: '' },
  ]);
  const subtotalAsNumber = proratedPrice || selectedPlan.price;
  const proratedRenewalDate = loggedInUser.company?.subscriptionEndDate;

  useEffect(() => {
    const generateCountryList = async (): Promise<void> => {
      const listOfCountries = await getCountryList();
      const formattedListOfCountries: ICountryOptions[] = [];
      listOfCountries.forEach(
        (country: { name: string; code: string }, index: number) => {
          formattedListOfCountries.push({
            key: index,
            label: toTitleCase(country.name),
            value: country.code,
          });
        },
      );
      setCountries(formattedListOfCountries);
    };
    generateCountryList();
  }, []);

  const isCountryZipError = (e: FormikState<ICheckoutFormState>): boolean => {
    return paymentFormState.displayZipError && e.errors.zip ? true : false;
  };

  const handleCountryOrZipChange = (
    formProps: FormikProps<ICheckoutFormState>,
    selectedCountry?: IAutocompleteOption<string>,
    zipInputEvent?: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (selectedCountry) {
      formProps.setFieldValue('country', selectedCountry.value);
      processTaxValidation({
        ...formProps.values,
        country: selectedCountry?.value ?? '',
      });
    }
    if (zipInputEvent) {
      formProps.handleChange(zipInputEvent);
      processTaxValidation({
        ...formProps.values,
        zip: zipInputEvent.target.value,
      });
    }
  };

  const processTaxValidation = async (props: ICheckoutFormState) => {
    const updatedPaymentFormState = await validateCountryZip(
      props,
      subtotalAsNumber,
      formikRef.current!,
      paymentFormState,
      updatePaymentFormState,
    );

    updatePaymentFormState(updatedPaymentFormState);
    setTotalWithTax(updatedPaymentFormState.totalWithTax);
  };

  return (
    <Formik
      ref={formikRef}
      validateOnChange={true}
      initialValues={{
        addressLine1: companySubscription
          ? companySubscription.addressModel?.companyAddress
          : '',
        city: companySubscription ? companySubscription.addressModel?.city : '',
        zip: '',
        country: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={PaymentFormSchema(paymentFormState.isUSA)}
    >
      {(formProps: FormikProps<ICheckoutFormState>) => {
        return (
          <Form noValidate={true}>
            <div className="old-payment-page-wrapper">
              <div>
                <Section label="Company Information">
                  <Input
                    name="addressLine1"
                    label="Company Address"
                    onChange={formProps.handleChange}
                    value={formProps.values.addressLine1}
                    error={fieldHasError(formProps, 'addressLine1')}
                    errorMessage={formProps.errors.addressLine1}
                    required
                  />
                  <FormGrid colWidth={3}>
                    <Input
                      id="city"
                      label="City"
                      name="city"
                      onChange={formProps.handleChange}
                      value={formProps.values.city}
                      error={fieldHasError(formProps, 'city')}
                      errorMessage={formProps.errors.city}
                      required
                    />
                    <div className="form-input-wrapper">
                      <Autocomplete
                        label="Country"
                        value={formProps.values.country}
                        options={countryList}
                        onSelection={(e: IAutocompleteOption<string>) =>
                          handleCountryOrZipChange(formProps, e)
                        }
                        onSelectionClear={() =>
                          formProps.setFieldValue('country', '')
                        }
                        error={isCountryZipError(formProps)}
                        errorMessage={
                          paymentFormState.displayZipError
                            ? ' '
                            : formProps.errors.country
                        }
                        errorSize={'small'}
                        required
                      />
                    </div>
                    <Input
                      label="Zip / Postal Code"
                      name="zip"
                      onChange={(e: React.ChangeEvent<any>) =>
                        handleCountryOrZipChange(formProps, undefined, e)
                      }
                      value={formProps.values.zip}
                      error={isCountryZipError(formProps)}
                      errorMessage={formProps.errors.zip}
                      required
                      maxLength={paymentFormState.isUSA ? 5 : 15}
                      type="number"
                    />
                  </FormGrid>
                </Section>
                <Section label="Payment Information">
                  <div className="card-element">
                    <CardElement
                      options={{
                        style: {
                          base: {
                            color: '#32325d',
                            fontFamily: '"Lato", sans-serif',
                            fontSmoothing: 'antialiased',
                            fontSize: '16px',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: '#fa755a',
                            iconColor: '#fa755a',
                          },
                        },
                      }}
                      onChange={handleStripeChange}
                    />
                    {errorMessage ? (
                      <FormError
                        error={!!errorMessage}
                        errorMessage={errorMessage}
                      />
                    ) : null}
                  </div>

                  <div className="secure">
                    <div className="secure-lock">
                      <img src={lock} alt="card" className="lock-image" />
                      <span>Your payment data is encrypted and secure.</span>
                    </div>
                    <div className="secure-card-images">
                      <img src={visa} alt="card" className="payment-logo" />
                      <img src={ms} alt="card" className="payment-logo" />
                      <img src={discover} alt="card" className="payment-logo" />
                      <img src={american} alt="card" className="payment-logo" />
                    </div>
                  </div>
                  <Divider />
                  <div className="paymentBox">
                    <div className="subtotalRow">
                      <div className="subtotalLabel">Subtotal</div>
                      <div className="subtotal">
                        <span className="price">
                          {proratedPrice
                            ? displayCurrency(proratedPrice)
                            : paymentFormState.subTotal}
                        </span>
                      </div>
                    </div>
                    <div className="taxRow">
                      <div className="taxLabel">Tax</div>
                      {paymentFormState.tax ? (
                        <div className="tax">{paymentFormState.tax}</div>
                      ) : paymentFormState.taxError ? (
                        <div className="tax error">
                          {paymentFormState.taxError}
                        </div>
                      ) : (
                        <div className="tax placeholder">
                          {paymentFormState.taxInProgress}
                        </div>
                      )}
                    </div>
                    <div className="totalRow">
                      <div className="totalLabel">Today's Total</div>
                      <div className="total">
                        <span className="price">
                          {paymentFormState.total
                            ? paymentFormState.total
                            : proratedPrice
                            ? displayCurrency(proratedPrice)
                            : paymentFormState.subTotal}
                        </span>
                      </div>
                    </div>
                  </div>
                </Section>
              </div>
              <div className="payment-summary">
                <div className="payment-summary-details">
                  <div className="summary-details-header">
                    <div className="plan-title-box">
                      {`${selectedPlan?.displayName} Plan`}
                    </div>
                  </div>
                  <div className="plan-summary-features">
                    <div className="short-description">
                      {resources.text('Global_SiteTitle') || `LeaseGuru`}{' '}
                      {`${selectedPlan?.displayName} (12 Months)`}
                    </div>
                    {selectedPlan &&
                      selectedPlanActiveFeatures?.map(
                        (feature: IPlanFeatureModel) => {
                          return (
                            <div className="tier-feature" key={feature.id}>
                              <span className="check-mark">
                                <img src={checkmark} alt="checkmark" />
                              </span>
                              <span className="feature-item">
                                {getFeatureDisplayText(feature)}
                              </span>
                            </div>
                          );
                        },
                      )}
                  </div>
                </div>
                <div className="payment-submission">
                  <div className="trial-message">
                    Subscription renews annually and purchases made can't be
                    refunded. Contact{' '}
                    <a
                      href={resources.text(
                        'General_Email_MailTo',
                        'mailto:info@leaseguru.com',
                      )}
                      className="mailTo"
                    >
                      {resources.text(
                        'General_Email_Address',
                        'info@leaseguru.com',
                      )}
                    </a>{' '}
                    for questions.
                    <br />
                    <br />
                    {proratedPrice
                      ? `Your renewal date remains ${proratedRenewalDate}. Your plan amount has been prorated accordingly.`
                      : `Your subscription will automatically renew on ${renewalDate()}.`}
                  </div>
                </div>
              </div>
            </div>
            <Divider />
            <div className="buttonBar">
              <Button
                buttonType="positive-green"
                buttonText="Pay"
                type="submit"
                processing={processing}
                disabled={processing || paymentFormState.disablePay}
              />
              <Button
                buttonType="neutral"
                buttonText="Back"
                onClick={handleBack}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const WrappedPaymentPage: React.FC<IPaymentPageProps> = (props) => (
  <Elements stripe={stripePromise}>
    <OldPaymentPage {...props} />
  </Elements>
);

export default WrappedPaymentPage;
