import React, { FunctionComponent } from 'react';
import ModalRoot from '../../common/modal/ModalRoot';
import { applyRSMClassName } from '../../common/utilities/_helpers';
import { useFeatureFlags } from '../../featureQuery';
import { FeatureFlagKeys } from '../../interfaces';
import Footer from './Footer';
import Header from './Header';
import './PlansAndPricingCPA.scss';
import PlansTable from './planTableCPA/PlansTable';

const PlansAndPricingCPA: FunctionComponent = () => {
  const { getSingleFlag } = useFeatureFlags();
  const guruPlanPricingFlag = getSingleFlag(FeatureFlagKeys.PlansAndPricing);

  return guruPlanPricingFlag ? (
    <>
      <Header />
      <div className={applyRSMClassName('cpa-page-container')}>
        <div className="left-content">
          <div className="title">Multi-Company Plans</div>
          <div className="sub-title">
            Ideal for CPA firms and other Accounting firms looking to manage
            lease portfolios for multiple companies or clients.
          </div>
          <div className="sub-title">
            Enter leases and manage the data for the companies you are handling.
            Clients can be added as a guest (read-only) user to their company
            account to view data and journal entries.
          </div>
        </div>
        <div className="right-content">
          <PlansTable />
        </div>
      </div>
      <Footer />
      <ModalRoot />
    </>
  ) : null;
};

export default PlansAndPricingCPA;
