import React, { FunctionComponent, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { getEnterpriseFeatureList } from '../../../api';
import checkmarkna from '../../../assets/checkmark-na.svg';
import checkmark from '../../../assets/checkmark.svg';
import rsmCheckmark from '../../../assets/rsm-checkmark.svg';
import { showModal } from '../../../common/modal/actions';
import {
  IPlanFeatureModel,
  IPlanModel,
  IUserModel,
  PremiumPlanSubTier,
} from '../../../interfaces';
import { IStore } from '../../../store';
import { SubscriptionModalPages } from '../../subscriptions/SubscriptionModal';
import {
  buildPlanTableDetail,
  getFeaturesByPlan,
  getPlanByName,
  getUsersOnPlan,
  IPlanFeatureTableItem,
  mapPlanDisplayNameToPlanSubTier,
} from '../helpers/helpers';
import BasicPlanHeader from './BasicPlanHeader';
import EnterprisePlanHeader from './EnterprisePlanHeader';
import './PlansTable.scss';
import PremiumPlanHeader from './PremiumPlanHeader';

const PlansTable: FunctionComponent = () => {
  const plans: IPlanModel[] = useSelector((store: IStore) => {
    return store.plans;
  });
  const loggedInUser: IUserModel = useSelector((state: IStore) => state.user);
  const userCurrentPlan = loggedInUser.company.plan;
  const [enterpriseTierFeatures, setEnterpriseTierFeatures] = useState<
    IPlanFeatureTableItem[][]
  >([]);
  const isRSM = process.env.REACT_APP_ENABLE_RSM === 'true';

  const premiumPlans = plans.filter((p) => p.tier === 'Premium');

  useEffect(() => {
    const getEnterpriseFeatures = async () => {
      const features: IPlanFeatureModel[] = await getEnterpriseFeatureList(
        false,
      );
      const summaryFeatures = features.sort((a, b) =>
        a.orderNumber < b.orderNumber ? -1 : 1,
      );
      setEnterpriseTierFeatures(buildPlanTableDetail(summaryFeatures, plans));
    };
    getEnterpriseFeatures();
  }, [plans]);

  const displayFeatureOnPlan = (
    features: IPlanFeatureTableItem[],
    planName: string,
    index: number,
  ) => {
    return features.map((f, i) => {
      planName = mapPlanDisplayNameToPlanSubTier(planName);
      if (f.name === 'Contributing Users') {
        return <div key={i * index}>{getUsersOnPlan(planName, plans)}</div>;
      }
      const checked = (
        <img
          src={isRSM ? rsmCheckmark : checkmark}
          alt="check"
          key={i * index}
        />
      );
      const unChecked = <img src={checkmarkna} alt="check" key={i * index} />;
      switch (planName) {
        case 'Basic':
          return f.isBasic ? checked : unChecked;
        case PremiumPlanSubTier.PREMIUM_1:
          return f.isPremium1 ? checked : unChecked;
        case PremiumPlanSubTier.PREMIUM_2:
          TagManager.dataLayer({
            dataLayer: {
              event: 'upgrade_subscription_cta',
              cta_location: 'In-Product Plans and Pricing Screen',
              cta_creative: 'Buy Now - 6-10 Leases Premium',
            },
          });
          return f.isPremium2 ? checked : unChecked;
        case PremiumPlanSubTier.PREMIUM_3:
          TagManager.dataLayer({
            dataLayer: {
              event: 'upgrade_subscription_cta',
              cta_location: 'In-Product Plans and Pricing Screen',
              cta_creative: 'Buy Now - 11-15 Leases Premium',
            },
          });
          return f.isPremium3 ? checked : unChecked;
        case PremiumPlanSubTier.PREMIUM_4:
          return f.isPremium4 ? checked : unChecked;
        case PremiumPlanSubTier.PREMIUM_5:
          return f.isPremium5 ? checked : unChecked;
        default:
          return checked;
      }
    });
  };

  const dispatch = useDispatch();
  const showCheckoutPageModal = (planName: string) => {
    dispatch(
      showModal({
        modal: {
          modalType: 'SUBSCRIPTIONS',
          modalProps: {
            open: true,
            initialSubscriptionModalPage: SubscriptionModalPages.payment,
            initialPlanFeatures: getFeaturesByPlan(planName, plans),
            initialSelectedPlan: getPlanByName(planName, plans),
          },
        },
      }),
    );
  };

  return (
    <table className="table">
      <thead className="header">
        <tr>
          <th />
          <th>
            <BasicPlanHeader userCurrentPlan={userCurrentPlan} />
          </th>
          <th colSpan={premiumPlans.length}>
            <PremiumPlanHeader
              planModels={premiumPlans}
              checkOut={showCheckoutPageModal}
              userCurrentPlan={userCurrentPlan}
            />
          </th>
          <th>
            <EnterprisePlanHeader />
          </th>
        </tr>
      </thead>
      <tbody className="body">
        {enterpriseTierFeatures
          .slice(0, enterpriseTierFeatures.length)
          .map((features, index) => {
            return (
              <tr key={index}>
                <td>
                  {features.map((f, i) => (
                    <div key={i}>{f.name}</div>
                  ))}
                </td>
                <td>{displayFeatureOnPlan(features, 'Basic', index + 1)}</td>
                {premiumPlans.map((p, i) => (
                  <td key={i}>
                    {displayFeatureOnPlan(features, p.displayName, i + 1)}
                  </td>
                ))}
                <td>
                  {displayFeatureOnPlan(features, 'Enterprise', index + 1)}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default PlansTable;
