import React, { FunctionComponent } from 'react';
import { Button } from '../../common/buttons';
import StyledLink from '../../common/link/StyledLink';
import { useResourceContext } from '../../common/resource-context';
import { AuthCard, AuthCardSection } from './AuthCard';

import './SignInSuspended.scss';

interface ISignInSuspendedProps {
  setIsUserSuspended: (value: boolean) => void;
}

const SignInSuspended: FunctionComponent<ISignInSuspendedProps> = ({
  setIsUserSuspended,
}) => {
  const resources = useResourceContext();
  const goBack = () => {
    setIsUserSuspended(false);
  };
  return (
    <AuthCard>
      <AuthCardSection>
        <div className="suspended-container">
          <div className="header">Sorry! You no longer have access.</div>
          <div className="message">
            Please contact the primary account holder to restore your access by
            upgrading to the premium account. If you don't know the account
            holder, contact us at{' '}
            <StyledLink
              href={resources.text(
                'General_Email_MailTo',
                'mailto:info@leaseguru.com',
              )}
            >
              {resources.text('General_Email_Address', 'info@leaseguru.com')}
            </StyledLink>
          </div>
          <Button onClick={goBack} buttonType="positive" buttonText="okay" />
        </div>
      </AuthCardSection>
    </AuthCard>
  );
};

export default SignInSuspended;
