import { Form, Formik, FormikProps } from 'formik';
import get from 'lodash/get';
import React, {
  Fragment,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import {
  getMappedQBSettings,
  getQBAccountList,
  submitQBAccountSettings,
} from '../../api';
import { Autocomplete, Divider } from '../../common';
import { IAutocompleteOption } from '../../common/autocomplete/Autocomplete';
import GuruLogoSpinner from '../../common/loaders/GuruLogoSpinner';
import { hideModal, showModal } from '../../common/modal/actions';
import Modal from '../../common/modal/Modal';
import { useResourceContext } from '../../common/resource-context';
import {
  IJournalEntryExportModel,
  ITotalOrgJeExportModel,
} from '../../interfaces';
import { IStore } from '../../store';
import { storeQBMappings } from './actions';

import { RequiredStringSchema } from 'yup/lib/string';
import './QuickBooksSettingsModal.scss';

interface IQuickBooksSettingsModalProps {
  open: boolean;
  otherProps?: {
    exportJE?: string[];
    journalEntry: IJournalEntryExportModel;
    journalEntryTotalOrg?: ITotalOrgJeExportModel;
  };
}

export interface IAccountListItem {
  id: string;
  name: string;
  accountNbr: string | null;
}

export interface IQuickBooksSettingsMapping {
  cash?: string;
  expense?: string;
  shortTermPreRent?: string;
  longTermPreRent?: string;
  shortTermDefRent?: string;
  longTermDefRent?: string;
  shortTermLiability?: string;
  longTermLiability?: string;
  depreciationExpense?: string;
  accumulatedDepreciation?: string;
  rouAsset?: string;
  equity?: string;
  interestExpense?: string;
  otherExpense?: string;
  assetBalance?: string;
  terminationGain?: string;
  terminationLoss?: string;
  begPrepaidRent?: string;
  begDefRent?: string;
}

const QBLabels: { [key: string]: string } = {
  cash: 'Cash',
  expense: 'Lease Expense',
  shortTermPreRent: 'ST Prepaid Rent',
  longTermPreRent: 'LT Prepaid Rent',
  shortTermDefRent: 'ST Deferred Rent',
  longTermDefRent: 'LT Deferred Rent',
  shortTermLiability: 'ST Lease Liability',
  longTermLiability: 'LT Lease Liability',
  depreciationExpense: 'Depreciation Expense',
  accumulatedDepreciation: 'Accumulated Depreciation',
  rouAsset: 'ROU Asset',
  equity: 'Equity',
  interestExpense: 'Interest Expense',
  otherExpense: 'Other Expense',
  assetBalance: 'Capital Lease Asset',
  terminationGain: 'Gain on Lease Termination',
  terminationLoss: 'Loss on Lease Termination',
  begPrepaidRent: 'Beginning Prepaid Rent',
  begDefRent: 'Beginning Deferred Rent',
};

const schema = yup.object().shape({
  cash: yup
    .string()
    .label(QBLabels.cash)
    .nullable(),
  expense: yup
    .string()
    .label(QBLabels.expense)
    .nullable(),
  shortTermPreRent: yup
    .string()
    .label(QBLabels.shortTermPreRent)
    .nullable(),
  longTermPreRent: yup
    .string()
    .label(QBLabels.longTermPreRent)
    .nullable(),
  shortTermDefRent: yup
    .string()
    .label(QBLabels.shortTermDefRent)
    .nullable(),
  longTermDefRent: yup
    .string()
    .label(QBLabels.longTermDefRent)
    .nullable(),
  shortTermLiability: yup
    .string()
    .label(QBLabels.shortTermLiability)
    .nullable(),
  longTermLiability: yup
    .string()
    .label(QBLabels.longTermLiability)
    .nullable(),
  depreciationExpense: yup
    .string()
    .label(QBLabels.depreciationExpense)
    .nullable(),
  accumulatedDepreciation: yup
    .string()
    .label(QBLabels.accumulatedDepreciation)
    .nullable(),
  rouAsset: yup
    .string()
    .label(QBLabels.rouAsset)
    .nullable(),
  equity: yup
    .string()
    .label(QBLabels.equity)
    .nullable(),
  interestExpense: yup
    .string()
    .label(QBLabels.interestExpense)
    .nullable(),
  otherExpense: yup
    .string()
    .label(QBLabels.otherExpense)
    .nullable(),
  assetBalance: yup
    .string()
    .label(QBLabels.assetBalance)
    .nullable(),
  terminationGain: yup
    .string()
    .label(QBLabels.terminationGain)
    .nullable(),
  terminationLoss: yup
    .string()
    .label(QBLabels.terminationLoss)
    .nullable(),
  begPrepaidRent: yup
    .string()
    .label(QBLabels.begPrepaidRent)
    .nullable(),
  begDefRent: yup
    .string()
    .label(QBLabels.begDefRent)
    .nullable(),
});

const generateQBSchema = (requiredFields: string[]) => {
  const generated: {
    [key: string]: RequiredStringSchema<string | undefined>;
  } = {};

  requiredFields.forEach(
    (rf) =>
      (generated[rf] = yup
        .string()
        .required()
        .label(QBLabels[rf])),
  );

  return yup.object().shape(generated);
};

const initialValues = {
  cash: '',
  expense: '',
  shortTermPreRent: '',
  longTermPreRent: '',
  shortTermDefRent: '',
  longTermDefRent: '',
  shortTermLiability: '',
  longTermLiability: '',
  depreciationExpense: '',
  accumulatedDepreciation: '',
  rouAsset: '',
  equity: '',
  interestExpense: '',
  otherExpense: '',
  assetBalance: '',
  terminationGain: '',
  terminationLoss: '',
  begDefRent: '',
  begPrepaidRent: '',
};

const QuickBooksSettingsModal: FunctionComponent<IQuickBooksSettingsModalProps> = ({
  open,
  otherProps,
}) => {
  const resources = useResourceContext();
  const dispatch = useDispatch();
  const userCompanyData = useSelector((state: IStore) => {
    return state.user.company!;
  });
  const [accountList, setAccountList] = useState<IAccountListItem[]>([]);
  const [initialData, setInitialData] = useState<IQuickBooksSettingsMapping>(
    initialValues,
  );
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const attemptMapping = (accList: IAccountListItem[]) => {
      const newData: { [key: string]: string } = {};

      // alphabetize account list
      const sortedAccountList = accList.sort((a, b) =>
        a.name > b.name ? 1 : -1,
      );

      // alphabetize QBLabels
      const sortedQBAccounts = Object.entries(QBLabels).sort((a, b) =>
        QBLabels[a[1]] > QBLabels[b[1]] ? 1 : -1,
      );

      // loop through qb labels
      sortedQBAccounts.forEach(([key, label]) => {
        // see if anything in account list is equal to current qb label
        const foundAccountIndex = sortedAccountList.findIndex(
          (account) => account.name === label,
        );

        // if so, add to autoMapList and remove from account list
        if (foundAccountIndex !== -1) {
          // get the number from account list
          const account = accList.find((acc) => acc.name === label);
          if (account?.id) {
            newData[key] = `${
              account.accountNbr ? `${account.accountNbr} - ` : ''
            }${account.id}`;
            sortedAccountList.splice(foundAccountIndex, 1);
          }
        }
      });

      return {
        ...initialValues,
        ...newData,
      };
    };

    const fetchAsyncData = async (clientId: number) => {
      const mappedSettings = await getMappedQBSettings(clientId);
      const accountListResponse = await getQBAccountList(clientId);

      if (accountListResponse) {
        if (mappedSettings) {
          setInitialData(mappedSettings);
          dispatch(storeQBMappings(mappedSettings));
        } else {
          setInitialData(attemptMapping([...accountListResponse]));
        }

        setAccountList(accountListResponse);
      }
    };

    if (userCompanyData.clientId) {
      fetchAsyncData(userCompanyData.clientId);
    }
  }, [userCompanyData.clientId, dispatch, otherProps]);

  const formikRef = useRef<Formik<IQuickBooksSettingsMapping>>(null);

  const submitModal = () => {
    if (formikRef) {
      formikRef.current!.submitForm();
    }
  };

  const handleFormSubmit = async (qbAccounts: IQuickBooksSettingsMapping) => {
    setProcessing(true);
    if (userCompanyData.clientId) {
      await submitQBAccountSettings(userCompanyData.clientId, qbAccounts);
      dispatch(storeQBMappings(qbAccounts));
      dispatch(hideModal());

      if (otherProps?.exportJE) {
        dispatch(
          showModal({
            modal: {
              modalType: 'QB_EXPORT_JE',
              modalProps: {
                open: true,
                otherProps: { journalEntry: otherProps.journalEntry },
              },
            },
          }),
        );
      }
      if (otherProps?.journalEntryTotalOrg !== undefined) {
        dispatch(
          showModal({
            modal: {
              modalType: 'QB_TOTALORG_EXPORT_JE',
              modalProps: {
                open: true,
                otherProps: {
                  journalEntryTotalOrg: otherProps?.journalEntryTotalOrg,
                },
              },
            },
          }),
        );
      }
      setProcessing(false);
    }
  };

  const formattedAccountList = accountList.map((acc: any) => ({
    key: acc.id,
    value: acc.name,
    label: `${acc.accountNbr ? `${acc.accountNbr} - ` : ''}${acc.name}`,
  }));

  const getFilteredOptions = (qbAccounts: IQuickBooksSettingsMapping) => {
    // find how many options we need to filter out
    const filteredQBAccounts = Object.values(qbAccounts).filter(
      (account) => account !== '',
    );

    // new option so formattedAccountList is not effected by splice
    const newFormattedAccountList = [...formattedAccountList];

    // if there are no current options, no need to filter
    if (filteredQBAccounts.length !== 0) {
      // for every filteredQBAccounts
      // remove the index of newFormattedAccountList that share the same quickBooksId
      filteredQBAccounts.forEach((formatFilteredItem) =>
        newFormattedAccountList.splice(
          newFormattedAccountList.findIndex(
            (tempOption) => tempOption.key === formatFilteredItem,
          ),
          1,
        ),
      );
    }

    // sort options alphabetically
    return newFormattedAccountList.sort((a, b) => (a.value > b.value ? 1 : -1));
  };

  const getAccountValue = (id: string) =>
    formattedAccountList.find((account) => account.key === id)?.value;

  const filteredAccountsForExport = (account: string) =>
    otherProps?.exportJE
      ? otherProps.exportJE.findIndex((je) => je === account) !== -1
      : account;

  return (
    <Modal
      title="QuickBooks Account Set Up"
      open={open}
      onConfirm={submitModal}
      neutralButtonText="Cancel"
      positiveButtonText={
        otherProps?.exportJE !== undefined ? 'Export' : 'Save'
      }
      processing={processing}
    >
      <Formik
        ref={formikRef}
        initialValues={initialData}
        onSubmit={handleFormSubmit}
        validateOnChange={false}
        validationSchema={
          otherProps?.exportJE ? generateQBSchema(otherProps.exportJE) : schema
        }
        enableReinitialize={true}
      >
        {(formProps: FormikProps<IQuickBooksSettingsMapping>) => {
          return (
            <div className="formik-qb-wrapper">
              {accountList && accountList.length !== 0 ? (
                <Form noValidate={true} className="qb-form-wrapper">
                  <div className="qb-blurb">
                    To start exporting to QuickBooks, please match the{' '}
                    {resources.text('Global_SiteTitle', 'LeaseGuru')} Accounts
                    with the respective QuickBooks accounts.
                  </div>
                  <div className="qb-header">
                    <div>
                      {resources.text('Global_SiteTitle', 'LeaseGuru')} Accounts
                    </div>
                    <div>QuickBooks Accounts</div>
                  </div>
                  <hr />
                  {Object.keys(formProps.values)
                    .sort((a, b) => (QBLabels[a] > QBLabels[b] ? 1 : -1))
                    .filter((account) => filteredAccountsForExport(account))
                    .map((account, i) => (
                      <Fragment key={i}>
                        <div className="qb-mapping-wrapping">
                          <label htmlFor={`${account}`} className="qb-id">
                            {QBLabels[account]}
                          </label>
                          <Autocomplete
                            onSelection={(
                              selectedItem: IAutocompleteOption<
                                IAccountListItem
                              >,
                            ) => {
                              if (selectedItem?.key) {
                                formProps.setFieldValue(
                                  account,
                                  selectedItem.key,
                                );
                              }
                            }}
                            value={getAccountValue(
                              get(formProps.values, account),
                            )}
                            options={formattedAccountList}
                            filterableOptions={getFilteredOptions(
                              formProps.values,
                            )}
                            error={get(formProps.errors, account)}
                            errorMessage={get(formProps.errors, account)}
                            onSelectionClear={() => {
                              formProps.setFieldValue(account, '');
                            }}
                            placeholder="Enter your QuickBooks account..."
                          />
                        </div>
                        {i !==
                        Object.keys(formProps.values).filter((value) =>
                          filteredAccountsForExport(value),
                        ).length -
                          1 ? (
                          <Divider />
                        ) : null}
                      </Fragment>
                    ))}
                </Form>
              ) : (
                <div className="qb-loader">
                  <h2>Syncing with QuickBooks</h2>
                  <GuruLogoSpinner inProgress />
                </div>
              )}
            </div>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default QuickBooksSettingsModal;
