import { addDays, format, parseISO } from 'date-fns';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { InlineButton } from '../../../common/buttons';
import { API_DATE_FORMAT } from '../../../common/utilities/_dates';
import { joinPath } from '../../../common/utilities/FormikHelpers';
import { IRenewOptionsModel } from '../../../interfaces';
import { RenewalOption } from '../index';

export const initialRenewalOption: IRenewOptionsModel = {
  renewForHowLongValue: 0,
  renewForHowLongPeriod: undefined,
  renewStartDt: '',
  renewEndDt: '',
  earliestNoticeDt: undefined,
  latestNoticeDt: undefined,
  reasonableToRenew: false,
};

const RenewalOptionsList: FunctionComponent<IRenewalOptionsListProps> = ({
  formProps,
  path,
}) => {
  const [renewalOptions, setRenewalOptions] = useState<IRenewOptionsModel[]>(
    [],
  );

  const handleAddRenewalOption = () => {
    const newOption = { ...initialRenewalOption };
    const lastRenewalOption: IRenewOptionsModel =
      renewalOptions[renewalOptions.length - 1];
    if (lastRenewalOption && lastRenewalOption.renewEndDt) {
      const renewStartDt: Date = addDays(
        parseISO(lastRenewalOption.renewEndDt),
        1,
      );
      newOption.renewStartDt = format(renewStartDt, API_DATE_FORMAT);
      newOption.renewEndDt = format(renewStartDt, API_DATE_FORMAT);
    }
    formProps.setFieldValue(path, [...renewalOptions, newOption]);
  };

  const lastIndexedRenewal =
    formProps.values.renewalOptions[formProps.values.renewalOptions.length - 1];
  const lastRenewalIndex = formProps.values.renewalOptions.lastIndexOf(
    lastIndexedRenewal,
  );

  const handleDeleteRenewal = (id: number) => {
    const newOption = formProps.values.renewalOptions;
    newOption.splice(id, 1);
    formProps.setFieldValue(joinPath('renewalOptions', path), ...newOption);
  };

  useEffect(() => {
    setRenewalOptions(formProps.values.renewalOptions);
  }, [formProps.values.renewalOptions]);

  return (
    <>
      {renewalOptions.map((option, index) => {
        return (
          <RenewalOption
            key={index}
            index={index}
            option={option}
            formProps={formProps}
            path={path}
            renewalOptions={renewalOptions}
            deleteRenewal={handleDeleteRenewal}
            hideButton={index !== lastRenewalIndex || index === 0}
          />
        );
      })}
      {renewalOptions.length < 5 && (
        <InlineButton
          buttonText="Add Another Renewal Option"
          onClick={handleAddRenewalOption}
          iconType="blue-plus"
        />
      )}
    </>
  );
};

interface IRenewalOptionsListProps {
  formProps: any;
  path: string;
}

export default RenewalOptionsList;
