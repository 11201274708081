import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { exportJournalEntryQuickBooks } from '../../api';
import GuruLogoSpinner from '../../common/loaders/GuruLogoSpinner';
import { hideModal } from '../../common/modal/actions';
import Modal from '../../common/modal/Modal';
import { ICompanyModel, IJournalEntryExportModel } from '../../interfaces';
import { IStore } from '../../store';

interface IQuickBooksExportJEModalProps {
  otherProps: {
    journalEntry: IJournalEntryExportModel;
  };
}

const QuickBooksExportJEModal: FunctionComponent<IQuickBooksExportJEModalProps> = ({
  otherProps,
}) => {
  const dispatch = useDispatch();
  const company: ICompanyModel = useSelector(
    (state: IStore) => state.user.company,
  );
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    const exportJEToQB = async () => {
      try {
        const response = await exportJournalEntryQuickBooks(
          otherProps.journalEntry,
          company.clientId,
        );
        if (response) {
          setProcessing(false);
          setTimeout(() => dispatch(hideModal()), 2000);
        }
      } catch (error) {
        dispatch(hideModal());
        alert('There was an issue exporting your Journal Entry');
      }
    };

    if (otherProps?.journalEntry) {
      exportJEToQB();
    }
  }, [otherProps, company.clientId, dispatch]);

  return (
    <Modal open={true} processing={processing}>
      <div className="formik-qb-wrapper">
        <div className="qb-loader">
          <h2>
            {processing
              ? 'Syncing Journal Entry with QuickBooks'
              : 'Export to QuickBooks Successful'}
          </h2>
          <GuruLogoSpinner inProgress={processing} />
        </div>
      </div>
    </Modal>
  );
};

export default QuickBooksExportJEModal;
