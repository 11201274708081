import React from 'react';
import arrowLeftRSM from '../../../../assets/arrow-left-rsm.svg';
import arrowLeft from '../../../../assets/arrow-left.svg';
import arrowRightRSM from '../../../../assets/arrow-right-rsm.svg';
import arrowRight from '../../../../assets/arrow-right.svg';
import { isEnvRSM } from '../../../../common/utilities/_helpers';

import './Counter.scss';

interface CounterProps {
  onArrowLeft: () => void;
  onArrowRight: () => void;
  price: string;
  priceIncrement: string;
  currentRange: string;
  rangeTitle: string;
  disabled: { left: boolean; right: boolean };
  isCPAUpgrade?: boolean;
}

export default function Counter({
  onArrowLeft,
  onArrowRight,
  price,
  priceIncrement,
  currentRange,
  rangeTitle,
  disabled,
  isCPAUpgrade,
}: CounterProps) {
  const isRSM = isEnvRSM();

  const leftIcon = isRSM ? arrowLeftRSM : arrowLeft;
  const rightIcon = isRSM ? arrowRightRSM : arrowRight;

  const { left, right } = disabled;

  const hideBothOptions = left && right;

  return (
    <div className="main-display">
      <div className="content">
        <div className={'price' + (isCPAUpgrade ? ' cpa' : '')}>{price}</div>
        <div className="price-increment">{priceIncrement}</div>
      </div>

      {isCPAUpgrade && (
        <div className="price-description">$500 per company</div>
      )}

      <div className="counter">
        <button
          className="unset-button"
          onClick={onArrowLeft}
          type="button"
          disabled={left}
        >
          {!hideBothOptions && (
            <img
              src={leftIcon}
              alt="arrow-left"
              className={`arrow-button ${left && 'disabled'}`}
            />
          )}
        </button>
        <div className="count-display">{currentRange}</div>
        <button
          className="unset-button"
          onClick={onArrowRight}
          type="button"
          disabled={right}
        >
          {!hideBothOptions && (
            <img
              src={rightIcon}
              alt="arrow-right"
              className={`arrow-button ${right && 'disabled'}`}
            />
          )}
        </button>
      </div>
      <div className="range-title">{rangeTitle}</div>
    </div>
  );
}
