import React, { FunctionComponent, ReactNode } from 'react';

import './Section.scss';

const Section: FunctionComponent<ISectionProps> = ({ children, label }) => (
  <div className="section-wrap">
    {label ? <div className="section-label">{label}</div> : null}
    {children}
  </div>
);

interface ISectionProps {
  children: ReactNode;
  label?: string;
}

export default Section;
