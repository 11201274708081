import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox } from '../../common';
import { showModal } from '../../common/modal/actions';
import Modal from '../../common/modal/Modal';
import {
  IJournalEntryModel,
  ITotalOrgJournalEntryModel,
} from '../../interfaces';

import { getQBAccountList } from '../../api/quickbooks';
import carImage from '../../assets/carpremium.svg';
import { IStore } from '../../store';
import {
  IAccountListItem,
  IQuickBooksSettingsMapping,
} from './QuickBooksSettingsModal';

import './QuickBooksTotalOrgConfirmationModal.scss';

interface IQuickBooksTotalOrgConfirmationModalProps {
  otherProps: {
    journalEntryTotalOrg: any;
  };
}

const QuickBooksTotalOrgConfirmationModal: FunctionComponent<IQuickBooksTotalOrgConfirmationModalProps> = ({
  otherProps,
}) => {
  const userCompanyData = useSelector((state: IStore) => {
    return state.user.company;
  });
  const dispatch = useDispatch();
  const isMoreThan5Leases =
    otherProps.journalEntryTotalOrg.journalEntries.slice(1).length > 5;
  const [assetName, setAssetName] = useState<string[]>([]);
  const quickBooksSettingsMapping: IQuickBooksSettingsMapping = useSelector(
    (state: IStore) => state.quickBooksSettings,
  );
  useEffect(() => {
    const leases = otherProps.journalEntryTotalOrg.journalEntries.map(
      (je: ITotalOrgJournalEntryModel) => {
        return je.assetName;
      },
    );
    setAssetName(leases);
  }, [otherProps.journalEntryTotalOrg.journalEntries]);

  const handleExport = async () => {
    otherProps.journalEntryTotalOrg.journalEntries = otherProps.journalEntryTotalOrg.journalEntries
      .slice(1) // Removes Total Org
      .filter((je: ITotalOrgJournalEntryModel) =>
        assetName.includes(je.assetName),
      );

    // if the user has previously mapped settings
    if (Object.keys(quickBooksSettingsMapping).length !== 0) {
      // get account list from QB
      const accountListResponse: IAccountListItem[] = await getQBAccountList(
        userCompanyData.clientId!,
      );

      // for every mapped account
      // checks if mapped value exists
      // then checks if the value is still a valid account from QB
      // if all is true, return the key
      const mappedAccounts = Object.entries(quickBooksSettingsMapping).map(
        ([key, value]) =>
          value &&
          accountListResponse.findIndex((account) => account.id === value) !==
            -1 &&
          key,
      );

      const exportAccountNames: string[] = [];

      // for every journalEntry that doesn't have a mappedAccount, add to exportAccountNames
      otherProps.journalEntryTotalOrg.journalEntries.forEach(
        (totalOrg: ITotalOrgJournalEntryModel) => {
          totalOrg.journalEntry.forEach((je: IJournalEntryModel) => {
            if (mappedAccounts.indexOf(je.acctType) === -1) {
              exportAccountNames.push(je.acctType);
            }
          });
        },
      );

      // if exportAccountNames is empty, all accounts have been mapped
      if (exportAccountNames.length === 0) {
        dispatch(
          showModal({
            modal: {
              modalType: 'QB_TOTALORG_EXPORT_JE',
              modalProps: {
                open: true,
                otherProps: {
                  journalEntryTotalOrg: otherProps.journalEntryTotalOrg,
                },
              },
            },
          }),
        );
      } else {
        // have the user map missing account mappings
        dispatch(
          showModal({
            modal: {
              modalType: 'QB_SETTINGS',
              modalProps: {
                open: true,
                otherProps: {
                  exportJE: exportAccountNames,
                  journalEntryTotalOrg: otherProps.journalEntryTotalOrg,
                },
              },
            },
          }),
        );
      }
    } else {
      // if they have no mappings, prompt for all accounts
      dispatch(
        showModal({
          modal: {
            modalType: 'QB_SETTINGS',
            modalProps: {
              open: true,
              title: 'QuickBooks Settings',
              otherProps: {
                exportJE: otherProps.journalEntryTotalOrg.journalEntries.map(
                  (je: IJournalEntryModel) => je.acctType,
                ),
                journalEntryTotalOrg: otherProps.journalEntryTotalOrg,
              },
            },
          },
        }),
      );
    }
  };

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const isChecked = event.target.checked;
    let newData = [...assetName];

    if (isChecked) {
      newData.push(name);
    } else {
      newData = newData.filter((x) => {
        return x !== name;
      });
    }

    setAssetName(newData);
  };

  return otherProps.journalEntryTotalOrg.journalEntries ? (
    <Modal
      modalSize="small"
      open={true}
      neutralButtonText="cancel"
      positiveButtonText="export"
      onConfirm={handleExport}
      contentFooter={<img className="car-image" src={carImage} alt="car-img" />}
    >
      <div className="total-export-confirmation">
        <div className="confirmation-title">Are you Sure?</div>
        <div className="confirmation-blurb">
          We are about to export all journal entries to Quickbooks Online.
          Please uncheck the leases you want to exclude.
        </div>
        <div
          className={
            isMoreThan5Leases ? 'leases-checkbox-expanded' : 'leases-checkbox'
          }
        >
          {otherProps.journalEntryTotalOrg.journalEntries
            .slice(1)
            .map((je: ITotalOrgJournalEntryModel, index: number) => (
              <Checkbox
                key={index}
                name={je.assetName}
                label={
                  je.assetName.length > 20
                    ? je.assetName.slice(0, 20) + '...'
                    : je.assetName
                }
                checked={assetName.indexOf(je.assetName) !== -1}
                onSelect={handleSelect}
              />
            ))}
        </div>
      </div>
    </Modal>
  ) : null;
};

export default QuickBooksTotalOrgConfirmationModal;
