import { format, isValid } from 'date-fns';
import * as yup from 'yup';

import {
  MAX_ADDITIONAL_PAYMENTS,
  MAX_PAYMENT_STREAMS,
} from '../../../common/utilities/_constants';
import { WORDY_DATE_FORMAT } from '../../../common/utilities/_dates';
import { validZip } from '../../../common/utilities/_regex';
import { PaymentFrequencyType, RenewalPeriodType } from '../../../interfaces';
import { LeaseIcons } from './ChooseIconModal';
import { IsCapitalValues } from './GeneralForm';

const GeneralFormSchema = yup.object().shape({
  assetName: yup
    .string()
    .label('Asset Name')
    .required(),
  assetType: yup
    .string()
    .label('Asset Type')
    .required(),
  iconURI: yup
    .string()
    .label('Icon')
    .oneOf(['', ...Object.values(LeaseIcons)])
    .required(),
  description: yup.string(),
  serialIDNumber: yup.string(),
  address: yup.string(),
  city: yup.string(),
  state: yup.string(),
  zip: yup.string().matches(validZip, 'Please enter a valid ZIP'),
});

const LeaseGeneralDetailsFormSchema = yup.object().shape({
  assetName: yup
    .string()
    .label('Asset Name')
    .required(),
  description: yup.string(),
  serialIDNumber: yup.string(),
  address: yup.string(),
  city: yup.string(),
  state: yup.string(),
  zip: yup.string().matches(validZip, 'Please enter a valid ZIP'),
});

const DateFormSchema = (usefulLifeRequired: boolean) =>
  yup.object().shape(
    {
      leaseBeginDt: yup
        .date()
        .label('Possession Date')
        .when('leaseEndDt', (leaseEndDt: Date, schema: yup.DateSchema) => {
          return (
            leaseEndDt &&
            schema.max(leaseEndDt, (opts: any) => {
              const max: string = format(opts.max, WORDY_DATE_FORMAT);
              return `Possession Date must be before Lease End Date (${max})`;
            })
          );
        })
        .required(),
      leaseEndDt: yup
        .date()
        .label('Lease End Date')
        .when('leaseBeginDt', (leaseBeginDt: Date, schema: yup.DateSchema) => {
          return (
            leaseBeginDt &&
            schema.min(leaseBeginDt, (opts: any) => {
              const min: string = format(opts.min, WORDY_DATE_FORMAT);
              return `Lease End Date must be after Possession Date (${min})`;
            })
          );
        })
        .required(),
      usefulLifeMonths: yup
        .number()
        .integer('Please enter a whole number')
        .required(() =>
          usefulLifeRequired ? 'Useful Life is a required field' : null,
        )
        .min(0)
        .max(9999)
        .transform((v: number) => (isNaN(v) ? undefined : v))
        .label('Useful Life'),
      leaseEndAlertDays: yup
        .array()
        .label('Alert Days')
        .when('hasAlerts', {
          is: true,
          then: yup
            .array()
            .min(1, 'Alert Days field must have at least 1 item')
            .required(),
          otherwise: yup.array(),
        }),
    },
    [['leaseBeginDt', 'leaseEndDt']],
  );

const OptionsFormSchema = yup.object().shape({
  canTransferTitle: yup.boolean().required(),
  isRenewalOption: yup.boolean().required(),
  isMoreOptionsOpen: yup.boolean().required(),
  isPurchaseOption: yup.boolean().required(),
  isTerminateOption: yup.boolean().required(),
  renewalOptions: yup.array().when('isRenewalOption', {
    is: true,
    then: yup
      .array(
        yup.object().shape({
          renewForHowLongValue: yup
            .number()
            .integer('Please enter a whole number')
            .typeError('Value must be greater than or equal to 1')
            .label('Value')
            .positive('Value must be greater than or equal to 1')
            .required(),
          renewForHowLongPeriod: yup
            .number()
            .label('Period')
            .oneOf([
              RenewalPeriodType.DAYS,
              RenewalPeriodType.WEEKS,
              RenewalPeriodType.MONTHS,
              RenewalPeriodType.YEARS,
            ])
            .required(),
          renewStartDt: yup
            .string()
            .label('Start Date')
            .required(),
          renewEndDt: yup
            .string()
            .label('End Date')
            .required(),
          reasonableToRenew: yup.boolean().required(),
        }),
      )
      .min(1)
      .max(5)
      .required(),
  }),
  purchaseOption: yup.object().when('isPurchaseOption', {
    is: true,
    then: yup.object().shape({
      reasonableToPurchase: yup.boolean(),
      purchaseAmt: yup
        .number()
        .min(0)
        .label('Purchase Amount')
        .transform((v: number) => (isNaN(v) ? undefined : v)),
      canBargainPurchase: yup.boolean(),
    }),
  }),
  terminateOption: yup.object().when('isTerminateOption', {
    is: true,
    then: yup.object().shape({
      hasFee: yup.boolean(),
      feeAmt: yup
        .number()
        .label('Fee Amount')
        .when(['hasFee'], {
          is: (hasFee: boolean) => {
            return hasFee;
          },
          then: yup
            .number()
            .typeError('Value must be greater than or equal to 1')
            .positive('Value must be greater than or equal to 1')
            .required(),
          otherwise: yup.number(),
        }),
    }),
  }),
});

const BasePaymentFormSchema = (minDate: Date, maxDate: Date) =>
  yup.object({
    paymentName: yup
      .string()
      .label('Name')
      .required(),
    borrowingRate: yup
      .number()
      .min(0)
      .typeError('Value must be greater than or equal to 0')
      .label('Borrowing Rate'),
    transitionRate: yup
      .number()
      .min(0)
      .transform((v: number) => (isNaN(v) ? undefined : v))
      .label('Transition Rate'),
    beginningPrepaidDeferredRent: yup
      .number()
      .transform((v: number) => (isNaN(v) ? undefined : v))
      .label('Deferred/Prepaid Rent'),
    isFixed: yup.boolean(),
    paymentDetail: yup
      .array(
        yup.object({
          paymentAmt: yup
            .number()
            .typeError('Value must be greater than or equal to 1')
            .positive('Value must be greater than or equal to 1')
            .label('Payment Amount')
            .required(),
          paymentFrequency: yup
            .number()
            .label('Payment Frequency')
            .oneOf([
              PaymentFrequencyType.MONTHLY,
              PaymentFrequencyType.QUARTERLY,
              PaymentFrequencyType.ONE_TIME,
              PaymentFrequencyType.ANNUAL,
            ])
            .required(),
          firstPaymentDt: yup
            .date()
            .label('First Payment Date')
            .min(minDate, (opts: any) => {
              const min: string = format(opts.min, WORDY_DATE_FORMAT);
              return `Please select a date on or after the Possession Date (${min})`;
            })
            .max(maxDate, (opts: any) => {
              const max: string = format(opts.max, WORDY_DATE_FORMAT);
              return `Please select a date on or before the effective end date (${max})`;
            })
            .required('First Payment Date is required')
            .typeError('Please enter a valid mm/dd/yyyy date'),
          lastPaymentDt: yup
            .date()
            .label('Last Payment Date')
            .when(
              'firstPaymentDt',
              (firstPaymentDt: Date, schema: yup.DateSchema) => {
                if (isValid(firstPaymentDt)) {
                  return schema
                    .min(firstPaymentDt, (opts: any) => {
                      const min: string = format(opts.min, WORDY_DATE_FORMAT);
                      return `Please select a date on or after the First Payment Date (${min})`;
                    })
                    .max(maxDate, (opts: any) => {
                      const max: string = format(opts.max, WORDY_DATE_FORMAT);
                      return `Please select a date on or before the effective end date (${max})`;
                    });
                }

                return schema;
              },
            )
            .required('Last Payment Date is required')
            .typeError('Please enter a valid mm/dd/yyyy date'),
          isIncrementPct: yup.boolean().label('Increase/Decrease Type'),
          incrementDecrementAmt: yup
            .number()
            .transform((v: number) => (isNaN(v) ? undefined : v))
            .label('Increase/Decrease Annually By'),
        }),
      )
      .min(1)
      .max(MAX_PAYMENT_STREAMS)
      .required(),
  });

const BaseRentFormSchema = (
  minDate: Date,
  maxDate: Date,
  isCapital: IsCapitalValues,
) =>
  yup.object({
    otherPayments: yup.array(
      yup
        .object({
          borrowingRate: yup
            .number()
            .typeError('Value must be greater than or equal to 1')
            .positive('Value must be greater than or equal to 1')
            .label('Borrowing Rate')
            .required(),
          fairValue: yup
            .number()
            .required(() =>
              isCapital === 'help' ? 'Fair value is a required field' : null,
            )
            .positive(() =>
              isCapital === 'help'
                ? 'Value must be greater than or equal to 1'
                : '',
            )
            .transform((v: number) => (isNaN(v) ? undefined : v))
            .label('Fair Value'),
          beginningPrepaidDeferredRent: yup
            .number()
            .transform((v: number) => (isNaN(v) ? undefined : v))
            .label('Deferred/Prepaid Rent'),
        })
        .concat(BasePaymentFormSchema(minDate, maxDate)),
    ),
  });

const AdditionalPaymentsFormSchema = (minDate: Date, maxDate: Date) =>
  yup.object({
    otherPayments: yup
      .array(BasePaymentFormSchema(minDate, maxDate))
      .max(MAX_ADDITIONAL_PAYMENTS),
  });

export {
  GeneralFormSchema,
  LeaseGeneralDetailsFormSchema,
  DateFormSchema,
  OptionsFormSchema,
  BaseRentFormSchema,
  AdditionalPaymentsFormSchema,
};
