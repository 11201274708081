// tslint:disable:no-console
// eslint-disable no-console

import {
  ClientContext,
  EdgeFeatureHubConfig,
  FeatureHubPollingClient,
  Readyness,
} from 'featurehub-javascript-client-sdk';
import { useCallback, useEffect, useState } from 'react';
import { FeatureFlagConfig, FeatureFlags } from './models';

export function useFeatureHub(config: FeatureFlagConfig): FeatureFlags {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>({});

  const buildConfig = useCallback(
    (host: string, apiKey: string): EdgeFeatureHubConfig => {
      return new EdgeFeatureHubConfig(host, apiKey);
    },
    [],
  );

  const buildClient = useCallback(
    async (
      ffConfig: EdgeFeatureHubConfig,
      clientId: number,
    ): Promise<ClientContext> => {
      return ffConfig
        .newContext()
        .userKey(clientId.toString())
        .build();
    },
    [],
  );

  const fetchFlagsByClient = useCallback(
    async (clientConfig: FeatureFlagConfig): Promise<void> => {
      const { clientId, flagsInUse, host, apiKey, pollingTime } = clientConfig;
      const builtFeatures: FeatureFlags = {};

      if (!clientId || !host || !apiKey) {
        if (process.env.NODE_ENV === 'development') {
          console.info(
            'FeatureHub.io Connection Cannot Be Made - Missing Credentials',
          );
        }

        // default all flags to false
        flagsInUse.forEach((flag) => {
          builtFeatures[flag.toString().toLocaleLowerCase()] = false;
        });

        setFeatureFlags(builtFeatures);
        return;
      }

      const fhConfig = buildConfig(host, apiKey);
      const fhClient = await buildClient(fhConfig, clientId);

      fhConfig.addReadynessListener((readyness) => {
        if (readyness === Readyness.Ready) {
          // get all flags for client default to false
          flagsInUse.forEach((flag) => {
            builtFeatures[flag.toString().toLocaleLowerCase()] =
              fhClient.getFlag(flag.toString()) ?? false;
          });

          setFeatureFlags(builtFeatures);
        }
      });

      // configure polling
      if (pollingTime) {
        fhConfig.edgeServiceProvider(
          (repo, c) => new FeatureHubPollingClient(repo, c, pollingTime),
        );
      }
    },
    [buildClient, buildConfig],
  );

  const formatProvidedFlags = (flags: FeatureFlags): FeatureFlags => {
    const formattedFlags: FeatureFlags = {};
    const flagKeys = Object.keys(flags);
    flagKeys.forEach((key) => {
      formattedFlags[key.toLocaleLowerCase()] = flags[key];
    });

    return formattedFlags;
  };

  useEffect(() => {
    // API provided flags
    const apiProvidedFlags = config.providedFlags;
    if (apiProvidedFlags) {
      setFeatureFlags(formatProvidedFlags(apiProvidedFlags));
      return;
    }

    fetchFlagsByClient(config);
  }, [config, fetchFlagsByClient]);

  return featureFlags;
}
