import React, { FunctionComponent, useState } from 'react';
import TagManager from 'react-gtm-module';
import lock from '../../assets/bluelock.svg';
import './DisclosuresTable.scss';

const DisclosureExportWithLQ: FunctionComponent = () => {
  const [showTooltipDiv, setShowTooltipDiv] = useState<boolean>(false);

  const showTooltip = () => {
    setShowTooltipDiv(true);
  };

  const hideTooltip = () => {
    setShowTooltipDiv(false);
  };

  const tagManagerHandler = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'disclosure_report_interaction',
        disclosure_report_action: 'Export with LeaseQuery',
      },
    });
  };

  return (
    <>
      <div onMouseOver={showTooltip} onMouseLeave={hideTooltip}>
        <img
          src={lock}
          alt="header-lock"
          className="header-lock export-with-lease-query-text"
        />
        <div className="link-text export-with-lease-query-text export-text">
          <span>Export with </span>
          <a
            onClick={() => tagManagerHandler()}
            href="http://leasequery.com/leaseguru/export-reports"
            target="_blank"
            rel="noopener noreferrer"
          >
            LeaseQuery
          </a>
        </div>
        <div>
          {showTooltipDiv && (
            <div className="export-with-lease-query-popup">
              Edit, export, share, or access custom reports only with
              LeaseQuery.
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DisclosureExportWithLQ;
