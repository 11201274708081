import { ILeaseHeaderModel } from '../../interfaces';

export enum LeaseListActions {
  STORE_LEASES = 'STORE_LEASES',
  CLEAR_LEASES = 'CLEAR_LEASES',
  DELETE_STORED_LEASE = 'DELETE_STORED_LEASE',
}

export const storeLeases = (leaseList: ILeaseHeaderModel[]) => ({
  type: LeaseListActions.STORE_LEASES,
  payload: { leaseList },
});
export const clearLeases = () => ({
  type: LeaseListActions.CLEAR_LEASES,
});

export const deleteStoredLease = (leaseId: number) => ({
  type: LeaseListActions.DELETE_STORED_LEASE,
  payload: { leaseId },
});
