import { ILeaseHeaderModel, ILeaseModel } from '../../interfaces';

export enum LeaseDetailsActions {
  SET_SELECTED_LEASE = 'SET_SELECTED_LEASE',
  CLEAR_SELECTED_LEASE = 'CLEAR_SELECTED_LEASE',
  UPDATE_SELECTED_LEASE_HEADER = 'UPDATE_SELECTED_LEASE_HEADER',
}

export const setSelectedLease = (leaseDetails: ILeaseModel) => ({
  type: LeaseDetailsActions.SET_SELECTED_LEASE,
  payload: leaseDetails,
});

export const updateSelectedLeaseHeader = (leaseHeader: ILeaseHeaderModel) => ({
  type: LeaseDetailsActions.UPDATE_SELECTED_LEASE_HEADER,
  payload: leaseHeader,
});

export const clearSelectedLease = () => ({
  type: LeaseDetailsActions.CLEAR_SELECTED_LEASE,
});
