import React, { FunctionComponent, useRef, useState } from 'react';

import questionThingy from '../../assets/questionThingy.svg';
import rsmQuestionThingy from '../../assets/rsmQuestionThingy.svg';
import { useOutsideClick } from '../hooks';

import './Tooltip.scss';

export type TooltipPositions =
  | 'bot-mid-left'
  | 'bot-left'
  | 'bot-mid-right'
  | 'bot-right'
  | 'top-left'
  | 'top-right'
  | 'inline-right'
  | 'centered-right';

export interface ITooltipProps {
  tooltipPosition?: TooltipPositions;
  header?: string;
  wrappedElement?: string | React.ReactNode;
  footer?: React.ReactNode;
  image?: string;
  className?: string;
  showImageOnHover?: boolean;
  miniTooltip?: boolean;
  onClick?:
    | ((event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void)
    | undefined;
}

const Tooltip: FunctionComponent<ITooltipProps> = ({
  tooltipPosition = 'bot-mid-left',
  header,
  footer,
  wrappedElement,
  children,
  image,
  showImageOnHover,
  className,
  miniTooltip,
  onClick,
}) => {
  const ref = useRef(null);
  const [open, setOpen] = useState<boolean>(false);

  useOutsideClick(ref, () => setOpen(false));

  return (
    <div className={`tooltip-wrapped-element ${className}`}>
      {wrappedElement}
      <div className={`tooltip-wrapper`} ref={ref}>
        <img
          src={
            image
              ? image
              : process.env.REACT_APP_ENABLE_RSM === 'true'
              ? rsmQuestionThingy
              : questionThingy
          }
          alt="question mark"
          onClick={
            !showImageOnHover
              ? () => setOpen(!open)
              : onClick
              ? (e) => onClick(e)
              : undefined
          }
          onMouseEnter={showImageOnHover ? () => setOpen(!open) : undefined}
          onMouseLeave={showImageOnHover ? () => setOpen(!open) : undefined}
          className={image ? 'tooltip-prop-image' : 'tooltip-image'}
        />
        <div
          className={`tooltip-content ${
            open ? 'open' : ''
          } tooltip-location--${tooltipPosition} ${
            miniTooltip ? 'tooltip-content-mini' : ''
          }`}
        >
          {header ? <div className="tooltip-header">{header}</div> : null}
          <div className={`tooltip-body`}>{children}</div>
          {footer ? <div className="tooltip-footer">{footer}</div> : null}
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
