import React, { FunctionComponent, ReactNode } from 'react';
import Toggle from '../toggle/Toggle';

import './ToggleSection.scss';

const ToggleSection: FunctionComponent<IToggleSectionProps> = ({
  name,
  id,
  children,
  isOpen,
  label,
  onClick,
  hideBorder,
  disabled,
}) => {
  return (
    <div className={`${hideBorder ? '' : 'radio-section-wrap'}`}>
      <Toggle
        name={name}
        label={label}
        checked={isOpen}
        onChange={onClick}
        id={id}
        disabled={disabled}
      />
      {isOpen && <div className="radio-section-content">{children}</div>}
    </div>
  );
};

interface IToggleSectionProps {
  name: string;
  children: ReactNode;
  isOpen: boolean;
  onClick: () => void;
  id?: string;
  label?: string;
  hideBorder?: boolean;
  disabled?: boolean;
}

export default ToggleSection;
