export const formatCityStateZip = (
  city: string,
  state: string,
  zip: string,
) => {
  if (city || state || zip) {
    return `${city ? city : ''}${state ? `${city ? ', ' : ''}${state}` : ''} ${
      zip ? zip : ''
    }`;
  }
  return '';
};
