import React, { useState } from 'react';

import { CircleIcon } from '../../../common/index';
import Modal from '../../../common/modal/Modal';

import './ChooseIconModal.scss';

export enum LeaseIcons {
  OFFICE = 'office',
  CAR = 'car',
  NATURAL = 'natural',
  CONSTRUCTION = 'construction',
  WAREHOUSE = 'warehouse',
  FOOD = 'food',
  GEARS = 'gears',
  LAB = 'lab',
  MANUFACTURING = 'manufacturing',
  MAP = 'map',
  MEDICAL = 'medical',
  RETAIL = 'retail',
  SERVER = 'server',
  TOOLS = 'tools',
  TRUCK = 'truck',
}
interface IChooseIconModalProps {
  title: string;
  open: boolean;
  hideModal: () => void;
  context: any;
}

const ChooseIconModal = ({
  title,
  open,
  hideModal,
  context,
}: IChooseIconModalProps) => {
  const formProps = context;
  const iconURI = formProps.values.iconURI;

  const [selectedIcon, setSelectedIcon] = useState(iconURI);

  const handleClick = (name: string) => {
    setSelectedIcon(name === selectedIcon ? '' : name);
  };

  const onSave = () => {
    formProps.setFieldValue('iconURI', selectedIcon);
    hideModal();
  };

  const builtInIcons: string[] = Object.values(LeaseIcons);

  return (
    <Modal
      title={title}
      open={open}
      positiveButtonText="Save"
      onConfirm={onSave}
      hideModal={hideModal}
    >
      <div className="choose-icon-modal-container">
        <div className="choose-icon-modal">
          <div className="icons-container">
            {builtInIcons.map((iconName) => {
              return (
                <div
                  key={iconName}
                  className={`icon ${selectedIcon === iconName && 'selected'}`}
                  onClick={() => handleClick(iconName)}
                >
                  <CircleIcon iconURI={iconName} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChooseIconModal;
