import React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IStore } from '../../store';
import { IModal } from './types';

import ManageUsersModal from '../../features/admin/ManageUsersModal';
import CompanySettingsModal from '../../features/companySettings/CompanySettingsModal';
import RegeneratingFinancialsModal from '../../features/companySettings/RegeneratingFinancialsModal';
import ExternalToolsConfirmationModal from '../../features/externalTools/ExternalToolsConfirmationModal';
import ExternalToolsModal from '../../features/externalTools/ExternalToolsModal';
import IntegrationsExportJEModal from '../../features/integrations/IntegrationsExportJEModal';
import IntegrationsSettingsModal from '../../features/integrations/IntegrationsSettingsModal';
import ChooseIconModal from '../../features/lease/add-lease-forms/ChooseIconModal';
import AmendTypeModal from '../../features/lease/components/AmendTypeModal';
import CapOpResultsModal from '../../features/lease/components/CapOpResultsModal';
import TerminationModal from '../../features/lease/components/TerminationModal';
import { LeaseGeneralDetailsModal } from '../../features/leaseDetails/components/LeaseGeneralDetailsModal';
import CreateClientModal from '../../features/multiClient/components/CreateClientModal';
import QuickBooksExportJEModal from '../../features/quickbooks/QuickBooksExportJEModal';
import QuickBooksSettingsModal from '../../features/quickbooks/QuickBooksSettingsModal';
import QuickBooksSync from '../../features/quickbooks/QuickBooksSync';
import QuickBooksTotalOrgConfirmationModal from '../../features/quickbooks/QuickBooksTotalOrgConfirmationModal';
import QuickBooksTotalOrgExportJEModal from '../../features/quickbooks/QuickBooksTotalOrgExportJEModal';
import SageLogin from '../../features/sage/SageLogin';
import SubscriptionModal from '../../features/subscriptions/SubscriptionModal';
import SubscriptionSettingsModal from '../../features/subscriptions/SubscriptionSettingsModal';
import UpdatePaymentConfirmationModal from '../../features/subscriptions/UpdatePaymentConfirmationModal';
import UpdatePaymentModal from '../../features/subscriptions/UpdatePaymentModal';
import UpgradeModal from '../../features/subscriptions/UpgradeModal';
import UserSettingsModal from '../../features/userSettings/UserSettingsModal';
import ConfirmationModal from '../confirmation/ConfirmationModal';

const MODAL_COMPONENTS: IModalComponents = {
  COMPANY_SETTINGS: CompanySettingsModal,
  USER_SETTINGS: UserSettingsModal,
  MANAGE_USERS: ManageUsersModal,
  UPGRADE_TIER: UpgradeModal,
  SUBSCRIPTIONS: SubscriptionModal,
  SUBSCRIPTION_SETTINGS: SubscriptionSettingsModal,
  UPDATE_PAYMENT: UpdatePaymentModal,
  UPDATE_PAYMENT_CONFIRMATION: UpdatePaymentConfirmationModal,
  CHOOSE_ICON: ChooseIconModal,
  AMEND_TYPE: AmendTypeModal,
  CONFIRMATION: ConfirmationModal,
  TERMINATION: TerminationModal,
  REGENERATING_FINANCIALS: RegeneratingFinancialsModal,
  CAP_OP_RESULTS: CapOpResultsModal,
  QB_SETTINGS: QuickBooksSettingsModal,
  QB_EXPORT_JE: QuickBooksExportJEModal,
  QB_TOTALORG_EXPORT_JE: QuickBooksTotalOrgExportJEModal,
  QB_TOTALORG_CONFIRMATION: QuickBooksTotalOrgConfirmationModal,
  QB_SYNC: QuickBooksSync,
  EXTERNAL_TOOLS: ExternalToolsModal,
  EXTERNAL_TOOLS_CONFIRMATION: ExternalToolsConfirmationModal,
  SAGE_LOGIN: SageLogin,
  INTEGRATIONS_SETTINGS: IntegrationsSettingsModal,
  INTEGRATIONS_EXPORT_JE: IntegrationsExportJEModal,
  LEASE_GENERAL_DETAILS: LeaseGeneralDetailsModal,
  CREATE_ACCOUNT: CreateClientModal,
};

const ModalRoot = ({ modal: { modalType, modalProps } }: IModalRootProps) => {
  if (!modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];

  return <SpecificModal {...modalProps} />;
};

interface IModalComponents {
  [key: string]: any;
}

interface IModalRootProps {
  dispatch: Dispatch;
  modal: IModal;
}

export default connect((state: IStore) => ({
  modal: state.modal,
}))(ModalRoot);
