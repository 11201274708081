import { Form, Formik, FormikProps } from 'formik';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { editCompany } from '../../api';
import Divider from '../../common/divider/Divider';
import Input from '../../common/input/Input';
import { showModal } from '../../common/modal/actions';
import Modal from '../../common/modal/Modal';
import Select, { ISelectOptions } from '../../common/select/Select';
import { monthOptions, yearOptions1 } from '../../common/utilities/_constants';
import { checkIfUserIsGuest } from '../../common/utilities/_guestUserHelper';
import { fieldHasError } from '../../common/utilities/FormikHelpers';
import { ICompanyModel } from '../../interfaces';
import { IStore } from '../../store';
import { updateUserCompanySettings } from '../auth/actions';
import { setReloadLeaseDetails, setReloadLeases } from '../lease/actions';

import lg_logo_check from '../../assets/lg-logo-check.svg';
import lg_logo_circle from '../../assets/lg-logo-circle.svg';
import './CompanySettingsModal.scss';

interface ICompanyModalProps {
  open: boolean;
  hideModal: () => void;
  title?: string;
  errorMessage: string | null;
}

const CompanySettingsModalRefactored: FunctionComponent<ICompanyModalProps> = ({
  open,
  hideModal,
}) => {
  const user = useSelector((state: IStore) => {
    return state.user;
  });

  const isGuestUser = checkIfUserIsGuest(user);

  // get store data to prepopulate form and initialize
  const { company } = user;

  const initialData = company;
  const formikRef = useRef<Formik<ICompanyModel>>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const dispatch = useDispatch();

  // cache loading images before the "regnerating financials" modal load
  useEffect(() => {
    const logoCircleImage = new Image();
    logoCircleImage.src = lg_logo_circle;
    const logoCheckImage = new Image();
    logoCheckImage.src = lg_logo_check;
  }, []);

  // company options
  const companyTypeOptions: Array<ISelectOptions<number>> = [
    { key: 2, value: 2, label: 'Private' },
    { key: 1, value: 1, label: 'Public' },
  ];
  const jurisdictionOptions: Array<ISelectOptions<string>> = [
    { key: 0, value: 'GAAP', label: 'GAAP' },
    { key: 1, value: 'IFRS', label: 'IFRS' },
  ];
  const ifrsApproachOptions: Array<ISelectOptions<string>> = [
    { key: 1, value: '1', label: 'Cumulative Approach 1' },
    { key: 2, value: '2', label: 'Cumulative Approach 2' },
    { key: 3, value: 'F', label: 'Full Retrospective Approach' },
  ];

  // form requirements
  const schema = yup.object().shape({
    companyName: yup.string().required('Name of Company is required'),
    companyType: yup
      .number()
      .required('Company Type is required')
      .oneOf(companyTypeOptions.map((val) => val.value)),
    transitionMonth: yup
      .number()
      .required('Effective Month is required')
      .oneOf(monthOptions.map((val) => val.value)),
    transitionYear: yup
      .number()
      .required('Effective Year is required')
      .oneOf(yearOptions1.map((val) => val.value)),
    jurisdiction: yup
      .string()
      .required('Jurisdiction is required')
      .oneOf(jurisdictionOptions.map((val) => val.value)),
    ifrsApproach: yup.string().when('jurisdiction', {
      is: 'IFRS',
      then: yup
        .string()
        .required('IFRS Approach is required')
        .oneOf(ifrsApproachOptions.map((val) => val.value)),
      otherwise: yup.string().nullable(),
    }),
  });

  const didUserUpdateDates = (values: ICompanyModel): boolean =>
    values.transitionMonth !== initialData.transitionMonth ||
    values.transitionYear !== initialData.transitionYear;

  // form submission
  const handleSubmit = async (values: ICompanyModel) => {
    setProcessing(true);
    hideModal();

    const userUpdatedDates = didUserUpdateDates(values);

    if (userUpdatedDates) {
      showRegeneratingFinancialModal();
    }

    // submit updated settings
    await editCompany(values);

    if (userUpdatedDates) {
      setTimeout(() => {
        dispatch(setReloadLeases(true));
        dispatch(setReloadLeaseDetails(true));
        hideModal();
      }, 2500);
    }

    // fetch updated date from store
    dispatch(updateUserCompanySettings(values));
  };

  const submitModal = () => {
    if (formikRef) {
      formikRef.current!.submitForm();
    }
  };

  const showRegeneratingFinancialModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'REGENERATING_FINANCIALS',
          modalProps: {
            open: true,
            hideModal,
          },
        },
      }),
    );
  };

  return (
    <Modal
      title="Company Settings"
      open={open}
      onConfirm={submitModal}
      hideModal={hideModal}
      neutralButtonText={isGuestUser ? 'Close' : 'Cancel'}
      positiveButtonText={isGuestUser ? undefined : 'Save'}
      processing={processing}
    >
      <Formik
        ref={formikRef}
        initialValues={initialData}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validationSchema={schema}
      >
        {(formProps: FormikProps<ICompanyModel>) => {
          return (
            <Form noValidate={true} className="companySettingsContainer">
              <div className="question-wrap">
                <label htmlFor="companyName" className="question-label">
                  Name of company
                </label>
                {isGuestUser ? (
                  <p>{formProps.values.companyName}</p>
                ) : (
                  <Input
                    name="companyName"
                    onChange={(
                      event: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >,
                    ) => {
                      formProps.handleChange(event);
                    }}
                    value={formProps.values.companyName}
                    error={fieldHasError(formProps, 'companyName')}
                    errorMessage={formProps.errors.companyName}
                  />
                )}
              </div>
              <Divider />
              <div className="question-wrap">
                <label htmlFor="classification" className="question-label">
                  This company is...
                </label>
                {isGuestUser ? (
                  <p>
                    {
                      companyTypeOptions.find(
                        (companyTypeOption) =>
                          companyTypeOption.value ===
                          formProps.values.companyType,
                      )?.label
                    }
                  </p>
                ) : (
                  <Select
                    name="companyType"
                    options={companyTypeOptions}
                    onClick={(_: string, option: ISelectOptions<number>) => {
                      formProps.setFieldValue('companyType', option.value);
                    }}
                    value={formProps.values.companyType}
                    error={fieldHasError(formProps, 'companyType')}
                    errorMessage={formProps.errors.companyType}
                  />
                )}
              </div>
              <Divider />
              <div className="question-wrap">
                <label htmlFor="transitionMonth" className="question-label">
                  Effective month
                </label>
                {isGuestUser ? (
                  <p>
                    {
                      monthOptions.find(
                        (monthOption) =>
                          monthOption.value ===
                          formProps.values.transitionMonth,
                      )?.label
                    }
                  </p>
                ) : (
                  <Select
                    name="transitionMonth"
                    options={monthOptions}
                    onClick={(_: string, option: ISelectOptions<number>) => {
                      formProps.setFieldValue('transitionMonth', option.value);
                    }}
                    value={formProps.values.transitionMonth}
                    error={fieldHasError(formProps, 'transitionMonth')}
                    errorMessage={formProps.errors.transitionMonth}
                    disabled={isGuestUser}
                  />
                )}
              </div>
              <Divider />
              <div className="question-wrap">
                <label htmlFor="transitionYear" className="question-label">
                  Effective year
                </label>
                {isGuestUser ? (
                  <p>
                    {
                      yearOptions1.find(
                        (yearOption) =>
                          yearOption.value === formProps.values.transitionYear,
                      )?.label
                    }
                  </p>
                ) : (
                  <Select
                    name="transitionYear"
                    options={yearOptions1}
                    onClick={(_: string, option: ISelectOptions<number>) => {
                      formProps.setFieldValue('transitionYear', option.value);
                    }}
                    value={formProps.values.transitionYear}
                    error={fieldHasError(formProps, 'transitionYear')}
                    errorMessage={formProps.errors.transitionYear}
                    disabled={isGuestUser || company.jurisdiction === 'IFRS'}
                  />
                )}
              </div>
              <Divider />
              <div className="question-wrap">
                <label htmlFor="jurisdiction" className="question-label">
                  Jurisdiction
                </label>
                {isGuestUser ? (
                  <p>
                    {
                      jurisdictionOptions.find(
                        (jurisdictionOption) =>
                          jurisdictionOption.value ===
                          formProps.values.jurisdiction,
                      )?.label
                    }
                  </p>
                ) : (
                  <Select
                    name="jurisdiction"
                    options={jurisdictionOptions}
                    onClick={(_: string, option: ISelectOptions<string>) =>
                      formProps.setFieldValue('jurisdiction', option.value)
                    }
                    value={formProps.values.jurisdiction}
                    error={fieldHasError(formProps, 'jurisdiction')}
                    errorMessage={formProps.errors.jurisdiction}
                    disabled
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CompanySettingsModalRefactored;
