import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { getLeaseDetails } from '../../api';
import { Button } from '../../common/buttons';
import { hideModal, showModal } from '../../common/modal/actions';
import { ILeaseModel, PlanFeatureNames } from '../../interfaces';
import { IStore } from '../../store';
import { setReloadLeaseDetails } from '../lease/actions';
import { checkIfUserHasAccessToFeature } from '../plans/helpers';
import { setSelectedLease } from './actions';
import {
  AdditionalDetails,
  AmortSchedContainer,
  JournalEntriesContainer,
  LeaseDetailsHeader,
} from './index';

import 'react-table-hoc-fixed-columns/lib/styles.css'; // This must be imported after react-table.css
import 'react-table/react-table.css';
import lock from '../../assets/bluelock.svg';
import { useResourceContext } from '../../common/resource-context';
import './LeaseDetails.scss';

interface ILeaseDetailTab {
  key: LeaseTabs;
  name: string;
  onClick: () => void;
}

enum LeaseTabs {
  AMORT_SCHED,
  JOURNAL_ENTRIES,
  ADDIT_DETAILS,
}

const LeaseDetails: FunctionComponent = () => {
  const resources = useResourceContext();
  const [lease, setLease] = useState<ILeaseModel>();
  const [loading, setLoading] = useState<boolean>(true);

  const dispatch = useDispatch();
  const { id } = useParams();

  const shouldReload = useSelector(
    (state: IStore) => state.lease.reloadLeaseDetails,
  );

  const loggedInUser = useSelector((state: IStore) => state.user);

  const jurisdiction = loggedInUser.company.jurisdiction;

  const features = loggedInUser.company.plan?.features;

  const userHasAccessToViewAmortSchedule = useMemo(
    () =>
      checkIfUserHasAccessToFeature(
        PlanFeatureNames.viewAmort,
        features,
        loggedInUser.userRole === 1,
      ),
    [features, loggedInUser],
  );

  const userHasAccessToViewIndJournalEntries = useMemo(
    () =>
      checkIfUserHasAccessToFeature(
        PlanFeatureNames.viewIndJournalEntries,
        features,
        loggedInUser.userRole === 1,
      ),
    [features, loggedInUser],
  );

  const getInitialTab = () => {
    if (userHasAccessToViewAmortSchedule) {
      return LeaseTabs.AMORT_SCHED;
    } else if (userHasAccessToViewIndJournalEntries) {
      return LeaseTabs.JOURNAL_ENTRIES;
    } else {
      return LeaseTabs.ADDIT_DETAILS;
    }
  };

  const [selectedTab, setSelectedTab] = useState<LeaseTabs>(getInitialTab());

  const showUpgradeModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'UPGRADE_TIER',
          modalProps: {
            open: true,
            hideModal: () => {
              dispatch(hideModal());
            },
          },
        },
      }),
    );
  };

  const leaseDetailTabs: ILeaseDetailTab[] = [
    {
      key: LeaseTabs.AMORT_SCHED,
      name: 'Amortization Schedule',
      onClick: () => {
        userHasAccessToViewAmortSchedule
          ? setSelectedTab(LeaseTabs.AMORT_SCHED)
          : showUpgradeModal();
      },
    },
    {
      key: LeaseTabs.JOURNAL_ENTRIES,
      name: 'Journal Entries',
      onClick: () => {
        userHasAccessToViewIndJournalEntries
          ? setSelectedTab(LeaseTabs.JOURNAL_ENTRIES)
          : showUpgradeModal();
      },
    },
    {
      key: LeaseTabs.ADDIT_DETAILS,
      name: 'Additional Details',
      onClick: () => {
        setSelectedTab(LeaseTabs.ADDIT_DETAILS);
      },
    },
  ];

  const loadLeaseDetails = useCallback(async () => {
    try {
      if (id) {
        const leaseDetails: ILeaseModel = await getLeaseDetails(id);
        dispatch(setSelectedLease(leaseDetails));
        setLease(leaseDetails);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (shouldReload) {
      dispatch(setReloadLeaseDetails(false));
    }

    if (loading || shouldReload) {
      loadLeaseDetails();
    }
  }, [shouldReload, loadLeaseDetails, dispatch, loading]);

  const handleRefreshPage = () => {
    window.location.reload();
  };

  const renderTabContent = () => {
    if (lease) {
      switch (selectedTab) {
        case LeaseTabs.JOURNAL_ENTRIES: {
          return (
            <JournalEntriesContainer
              leaseId={lease.header.leaseId}
              isCapital={lease.header.isCapital}
            />
          );
        }
        case LeaseTabs.ADDIT_DETAILS: {
          return (
            <AdditionalDetails lease={lease} jurisdiction={jurisdiction} />
          );
        }
        default: {
          return (
            <AmortSchedContainer
              basePayment={lease.basePayment}
              amortizationSchedules={lease.amortizationSchedules}
              leaseHeader={lease.header}
            />
          );
        }
      }
    }
  };

  const handleTabLockCheck = (tab: ILeaseDetailTab) => {
    const lockElement = (
      <img src={lock} alt="header-lock" className="dropdown-lock" />
    );

    switch (tab.key) {
      case LeaseTabs.AMORT_SCHED:
        return userHasAccessToViewAmortSchedule ? null : lockElement;
      case LeaseTabs.JOURNAL_ENTRIES:
        return userHasAccessToViewIndJournalEntries ? null : lockElement;
      default:
        return null;
    }
  };

  const renderLeaseDetails = () => {
    if (lease) {
      return (
        <>
          <div className="lease-details-header-container">
            <LeaseDetailsHeader
              lease={lease}
              leaseHeader={lease.header}
              jurisdiction={jurisdiction}
              capOpTestResults={lease.capOpTestResults}
            />
          </div>
          <div className="lease-details-tab-container">
            <ul className="tab-row">
              {leaseDetailTabs.map((tab: ILeaseDetailTab) => {
                return (
                  <li
                    className={`tab-name ${
                      selectedTab === tab.key ? 'selected' : ''
                    }`}
                    key={tab.key}
                    onClick={() => {
                      tab.onClick();
                    }}
                    id={`${tab.key}-${tab.name}`}
                  >
                    {tab.name}
                    {handleTabLockCheck(tab)}
                  </li>
                );
              })}
            </ul>
            {renderTabContent()}
          </div>
        </>
      );
    }
  };

  return (
    <div
      className={`lease-details ${process.env.REACT_APP_ENABLE_RSM === 'true' &&
        'rsm-class-lease-details'}`}
    >
      {lease ? (
        renderLeaseDetails()
      ) : loading ? null : (
        <>
          <br />
          <div className="h1">There was an issue loading your lease</div>
          <div className="h2">Please click the refresh button below</div>
          <div>
            If the issue persists, please contact us at{' '}
            <a
              href={resources.text(
                'General_Email_MailTo',
                'mailto:info@leaseguru.com',
              )}
            >
              {resources.text('General_Email_Address', 'info@leaseguru.com')}
            </a>
          </div>
          <br />
          <Button
            buttonType="positive"
            buttonText="Refresh"
            onClick={handleRefreshPage}
          />
        </>
      )}
    </div>
  );
};

export default LeaseDetails;
