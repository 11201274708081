import React, { FunctionComponent } from 'react';
import NumberFormat from 'react-number-format';

import './Input.scss';

const Input: FunctionComponent<IInputProps> = ({
  label,
  name,
  required,
  id,
  onChange,
  multiline,
  value,
  disabled,
  error,
  errorMessage,
  placeholder,
  type = 'text',
  maxLength,
  withComma,
  className,
  allowNegative = false,
  decimalScale,
}) => (
  <div
    className={`form-input-wrapper ${process.env.REACT_APP_ENABLE_RSM ===
      'true' && 'rsm-class-input'}`}
  >
    {label ? (
      <label
        htmlFor={id || name}
        className={`form-input-label ${required ? 'required' : ''} ${
          disabled ? 'disabled' : ''
        } `}
      >
        {label}
      </label>
    ) : null}
    {multiline ? (
      <textarea
        className={`form-multiline ${error ? 'error' : ''}`}
        name={name}
        rows={3}
        maxLength={maxLength || 400}
        required={required}
        disabled={disabled}
        id={id || name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    ) : withComma || type === 'number' ? (
      <NumberFormat
        value={value !== null && value !== undefined ? value : ''}
        placeholder={placeholder}
        onChange={onChange}
        thousandSeparator={withComma}
        id={id || name}
        className={`form-input ${error ? 'error' : ''}`}
        name={name}
        required={required}
        maxLength={maxLength || 80}
        disabled={disabled}
        allowNegative={allowNegative}
        decimalScale={decimalScale}
      />
    ) : (
      <input
        type={type}
        className={`${className && className} form-input ${
          error ? 'error' : ''
        }`}
        name={name}
        required={required}
        maxLength={maxLength || 80}
        disabled={disabled}
        id={id || name}
        onChange={onChange}
        value={value !== null && value !== undefined ? value : ''}
        placeholder={placeholder}
      />
    )}
    {error && (
      <span className="form-input-error-message">
        {errorMessage || 'There is an issue with this field'}
      </span>
    )}
  </div>
);

interface IInputProps {
  name: string;
  onChange: ({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  label?: string;
  value?: string | number;
  required?: boolean;
  multiline?: boolean;
  error?: boolean;
  errorMessage?: string;
  id?: string;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  maxLength?: number;
  withComma?: boolean;
  className?: string;
  allowNegative?: boolean;
  decimalScale?: number;
}

export default Input;
