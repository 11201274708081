import { format } from 'date-fns';
import addDays from 'date-fns/addDays';
import isValid from 'date-fns/isValid';
import { Field, FormikProps } from 'formik';
import get from 'lodash/get';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { Divider, ExpandSection, Input } from '../../../common';
import DatepickerInput from '../../../common/datepicker/DatepickerInput';
import { formatRemoveCommas } from '../../../common/formatters/FormatInputCommas';
import Select, { ISelectOptions } from '../../../common/select/Select';
import Tooltip, { TooltipPositions } from '../../../common/tooltips/Tooltip';
import { MAX_PAYMENT_STREAMS } from '../../../common/utilities/_constants';
import { DEFAULT_DATE_FORMAT } from '../../../common/utilities/_dates';
import {
  fieldHasError,
  joinPath,
} from '../../../common/utilities/FormikHelpers';
import {
  AmendTypes,
  IPaymentDetailModel,
  PaymentFrequencyType,
} from '../../../interfaces';
import { IStore } from '../../../store';
import { IGlossaryItem } from '../../help/components/GlossaryItem';
import { IAdditionalFormState } from '../add-lease-forms/AdditionalPaymentsForm';

import '../add-lease-forms/add-lease-form-styles.scss';
import '../add-lease-forms/BaseRentForm.scss';
import './PaymentForm.scss';

interface IPaymentFormProps {
  formProps: FormikProps<IAdditionalFormState>;
  path: string;
  index?: number;
  isCapital?: boolean;
  showTooltips?: boolean;
  leaseBeginDt?: Date;
  leaseEndDt?: Date;
  isAddlPayments: boolean;
}

const PaymentForm: FunctionComponent<IPaymentFormProps> = ({
  formProps,
  path,
  isCapital = false,
  leaseBeginDt,
  leaseEndDt,
  isAddlPayments,
}) => {
  return (
    <div className="payment-form">
      {isAddlPayments ? (
        <div className="addtl-payment-detail-inputs">
          <PaymentFormDetails
            formProps={formProps}
            path={path}
            leaseBeginDt={leaseBeginDt}
            leaseEndDt={leaseEndDt}
            isAddlPayments
          />
        </div>
      ) : (
        <>
          <BaseRentPayments
            formProps={formProps}
            path={path}
            leaseType={isCapital}
          />
          <div className="payment-detail-inputs">
            <div className="blacklabel">Base Rent</div>
            <PaymentFormDetails
              formProps={formProps}
              path={path}
              leaseBeginDt={leaseBeginDt}
              leaseEndDt={leaseEndDt}
              isAddlPayments={false}
            />
          </div>
        </>
      )}
    </div>
  );
};

const BaseRentPayments = ({
  formProps,
  path,
  leaseType,
}: {
  formProps: FormikProps<IAdditionalFormState>;
  path: string;
  leaseType: any;
}) => {
  const amendType: AmendTypes | undefined = useSelector(
    (state: IStore) => state.steppedModal.modalProps?.amendType,
  );

  const glossaryItems = useSelector((state: IStore) => state.glossaryItems);

  const handleGetGlossaryItem = (term: string) =>
    glossaryItems.find((item: IGlossaryItem) => item.term === term);

  const renderToolTip = (
    glossaryItem: IGlossaryItem | undefined,
    tooltipPosition: TooltipPositions = 'bot-mid-left',
    required: boolean = false,
  ) => {
    return (
      <Tooltip
        wrappedElement={
          <label className={`form-input-label ${required ? 'required' : ''}`}>
            {glossaryItem ? glossaryItem.term : ''}
          </label>
        }
        header={glossaryItem ? glossaryItem.term : ''}
        tooltipPosition={tooltipPosition}
      >
        {glossaryItem ? glossaryItem.termDefinition : ''}
      </Tooltip>
    );
  };
  return (
    <>
      <div className="payment-form-row">
        <div className="form-input-wrapper">
          {renderToolTip(
            handleGetGlossaryItem('Borrowing Rate'),
            'bot-mid-left',
            true,
          )}
          <Input
            name={joinPath('borrowingRate', path)}
            label=""
            required
            type="number"
            value={get(formProps.values, joinPath('borrowingRate', path))}
            error={fieldHasError(formProps, joinPath('borrowingRate', path))}
            errorMessage={get(
              formProps.errors,
              joinPath('borrowingRate', path),
            )}
            onChange={formProps.handleChange}
          />
          <div className="percentPlaceholder">%</div>
        </div>
        <div className="form-input-wrapper">
          {renderToolTip(handleGetGlossaryItem('Transition Rate'))}
          <Input
            name={joinPath('transitionRate', path)}
            label=""
            type="number"
            value={get(formProps.values, joinPath('transitionRate', path))}
            onChange={formProps.handleChange}
          />
          <div className="percentPlaceholder">%</div>
        </div>
      </div>

      <div className="payment-form-row">
        <div className="form-input-wrapper">
          {renderToolTip(
            handleGetGlossaryItem('Fair Value'),
            'bot-left',
            leaseType === 'help',
          )}
          <Input
            name={joinPath('fairValue', path)}
            type="number"
            withComma={true}
            required={leaseType === 'help'}
            value={get(formProps.values, joinPath('fairValue', path))}
            onChange={(e: any) => formatRemoveCommas(e, formProps)}
            error={fieldHasError(formProps, joinPath('fairValue', path))}
            errorMessage={get(formProps.errors, joinPath('fairValue', path))}
          />
        </div>
        {/* Don't show beginningPrepaidDeferredRent if it is an amend */}
        {amendType === 'MODIFY' || amendType === 'RENEW' ? null : (
          <>
            <div className="form-input-wrapper">
              <Input
                name={joinPath('beginningPrepaidDeferredRent', path)}
                label="Deferred/Prepaid Rent"
                type="number"
                decimalScale={2}
                withComma={true}
                value={get(
                  formProps.values,
                  joinPath('beginningPrepaidDeferredRent', path),
                )}
                onChange={(e: any) => formatRemoveCommas(e, formProps)}
                error={fieldHasError(
                  formProps,
                  joinPath('beginningPrepaidDeferredRent', path),
                )}
                errorMessage={get(
                  formProps.errors,
                  joinPath('beginningPrepaidDeferredRent', path),
                )}
                allowNegative={true}
              />
            </div>
            {/* Don't show Incentive if it is an amend */}
            <div className="form-input-wrapper">
              {renderToolTip(
                handleGetGlossaryItem('Incentives'),
                'bot-mid-right',
                leaseType === 'help',
              )}
              <Input
                name={joinPath('incentiveAmount', path)}
                type="number"
                decimalScale={2}
                required={leaseType === 'help'}
                value={get(formProps.values, joinPath('incentiveAmount', path))}
                onChange={formProps.handleChange}
                error={fieldHasError(
                  formProps,
                  joinPath('incentiveAmount', path),
                )}
                errorMessage={get(
                  formProps.errors,
                  joinPath('incentiveAmount', path),
                )}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

const PaymentFormDetails = ({
  formProps,
  path,
  leaseBeginDt,
  leaseEndDt,
  isAddlPayments,
}: {
  formProps: FormikProps<IAdditionalFormState>;
  path: string;
  leaseBeginDt?: Date;
  leaseEndDt?: Date;
  isAddlPayments: boolean;
}) => {
  const initialDetail = {
    paymentFrequency: undefined,
    paymentAmt: undefined,
    firstPaymentDt: leaseBeginDt,
    lastPaymentDt: leaseEndDt,
    isIncrementPct: undefined,
    incrementDecrementAmt: undefined,
  };

  const handleAddPaymentStream = () => {
    function getPreviousLastPaymentDate() {
      const paymentDetailArray: IPaymentDetailModel[] = get(
        formProps.values,
        joinPath(`paymentDetail`, path),
      );

      const previousPaymentDetail: IPaymentDetailModel = get(
        formProps.values,
        joinPath(`paymentDetail[${paymentDetailArray.length - 1}]`, path),
      );
      return previousPaymentDetail.lastPaymentDt
        ? new Date(previousPaymentDetail.lastPaymentDt)
        : null;
    }

    const previousLastPaymentDate: Date | null = getPreviousLastPaymentDate();

    if (isValid(previousLastPaymentDate)) {
      const newDetail = {
        ...initialDetail,
        firstPaymentDt: previousLastPaymentDate
          ? format(addDays(previousLastPaymentDate, 1), DEFAULT_DATE_FORMAT)
          : undefined,
        lastPaymentDt: undefined,
      };
      formProps.setFieldValue(joinPath('paymentDetail', path), [
        ...get(formProps.values, joinPath('paymentDetail', path)),
        newDetail,
      ]);
    }
  };

  const handleDelete = (id: number) => {
    const payments = get(formProps.values, joinPath(`paymentDetail`, path));
    payments.splice(id, 1);
    formProps.setFieldValue(joinPath('paymentDetail', path), payments);
  };

  const renderPaymentDetails = (index: number) => {
    return (
      <>
        <div className="form-row">
          <Input
            name={joinPath(`paymentDetail[${index}].paymentAmt`, path)}
            label="Payment Amount"
            required
            type="number"
            withComma={true}
            value={get(
              formProps.values,
              joinPath(`paymentDetail[${index}].paymentAmt`, path),
            )}
            error={fieldHasError(
              formProps,
              joinPath(`paymentDetail[${index}].paymentAmt`, path),
            )}
            errorMessage={get(
              formProps.errors,
              joinPath(`paymentDetail[${index}].paymentAmt`, path),
            )}
            onChange={(e: any) => formatRemoveCommas(e, formProps)}
          />
          <Select
            name={joinPath(`paymentDetail[${index}].paymentFrequency`, path)}
            label="Payment Frequency"
            id={`pmnt-freq-not-${index}-${joinPath('paymentOptionType', path)}`}
            required
            value={get(
              formProps.values,
              joinPath(`paymentDetail[${index}].paymentFrequency`, path),
            )}
            error={fieldHasError(
              formProps,
              joinPath(`paymentDetail[${index}].paymentFrequency`, path),
            )}
            errorMessage={get(
              formProps.errors,
              joinPath(`paymentDetail[${index}].paymentFrequency`, path),
            )}
            onClick={(
              name: string,
              option: ISelectOptions<PaymentFrequencyType>,
            ) => {
              formProps.setFieldValue(
                joinPath(`paymentDetail[${index}].paymentFrequency`, path),
                option.value,
              );
            }}
            options={frequencyOptions}
          />
          <Field
            name={joinPath(`paymentDetail[${index}].firstPaymentDt`, path)}
            validate={(value: any) => {
              const previousLastPaymentDate = new Date(
                get(
                  formProps.values,
                  joinPath(`paymentDetail[${index - 1}].lastPaymentDt`, path),
                ),
              );
              const dateVal =
                typeof value === 'string' ? new Date(value) : value;

              if (previousLastPaymentDate >= dateVal) {
                return `Select a date after Schedule ${index} Last Payment Date`;
              } else if (!isValid(dateVal)) {
                return 'Please enter a valid date';
              }

              return '';
            }}
            render={() => (
              <div className="form-input-wrapper">
                <DatepickerInput
                  name={joinPath(
                    `paymentDetail[${index}].firstPaymentDt`,
                    path,
                  )}
                  label="First Payment Date"
                  required
                  value={get(
                    formProps.values,
                    joinPath(`paymentDetail[${index}].firstPaymentDt`, path),
                  )}
                  error={fieldHasError(
                    formProps,
                    joinPath(`paymentDetail[${index}].firstPaymentDt`, path),
                  )}
                  errorMessage={get(
                    formProps.errors,
                    joinPath(`paymentDetail[${index}].firstPaymentDt`, path),
                  )}
                  onChange={formProps.handleChange}
                  placeholderText="mm/dd/yyyy"
                />
              </div>
            )}
          />
          <div className="form-input-wrapper">
            <DatepickerInput
              name={joinPath(`paymentDetail[${index}].lastPaymentDt`, path)}
              label="Last Payment Date"
              required
              value={get(
                formProps.values,
                joinPath(`paymentDetail[${index}].lastPaymentDt`, path),
              )}
              error={fieldHasError(
                formProps,
                joinPath(`paymentDetail[${index}].lastPaymentDt`, path),
              )}
              errorMessage={get(
                formProps.errors,
                joinPath(`paymentDetail[${index}].lastPaymentDt`, path),
              )}
              onChange={formProps.handleChange}
              placeholderText="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="increase-decrease-form-row">
          <label className="increase-decrease-label" htmlFor="increaseDecrease">
            If your payment remains the
            <br /> same, skip these fields
          </label>
          <Input
            name={joinPath(
              `paymentDetail[${index}].incrementDecrementAmt`,
              path,
            )}
            label="Increase/Decrease Annually By"
            placeholder="(optional)"
            type="number"
            withComma={true}
            value={get(
              formProps.values,
              joinPath(`paymentDetail[${index}].incrementDecrementAmt`, path),
            )}
            error={fieldHasError(
              formProps,
              joinPath(`paymentDetail[${index}].incrementDecrementAmt`, path),
            )}
            errorMessage={get(
              formProps.errors,
              joinPath(`paymentDetail[${index}].incrementDecrementAmt`, path),
            )}
            onChange={formProps.handleChange}
            allowNegative={true}
          />
          <Select
            name={joinPath(`paymentDetail[${index}].isIncrementPct`, path)}
            label="Type"
            placeholder="(optional)"
            id={`pmnt-is-incr-perc-${index}-${joinPath(
              'paymentOptionType',
              path,
            )}`}
            value={get(
              formProps.values,
              joinPath(`paymentDetail[${index}].isIncrementPct`, path),
            )}
            error={fieldHasError(
              formProps,
              joinPath(`paymentDetail[${index}].isIncrementPct`, path),
            )}
            errorMessage={get(
              formProps.errors,
              joinPath(`paymentDetail[${index}].isIncrementPct`, path),
            )}
            onClick={(name: string, option: ISelectOptions<boolean>) => {
              formProps.setFieldValue(
                joinPath(`paymentDetail[${index}].isIncrementPct`, path),
                option.value,
              );
            }}
            options={incrementDecrementOption}
          />
        </div>
      </>
    );
  };

  return (
    <>
      {get(formProps.values, joinPath('paymentDetail', path)).map(
        (_: any, index: number) => {
          const paymentAmt =
            get(
              formProps.values,
              joinPath(`paymentDetail[${index}].paymentAmt`, path),
            ) || '';
          const paymentFreq =
            get(
              formProps.values,
              joinPath(`paymentDetail[${index}].paymentFrequency`, path),
            ) || '';
          let paymentFreqLabel = '';
          const selectedOption = frequencyOptions.find(
            (option) => option.value === paymentFreq,
          );
          if (selectedOption) {
            paymentFreqLabel = '/' + selectedOption.label;
          }
          const firstPaymtDate = get(
            formProps.values,
            joinPath(`paymentDetail[${index}].firstPaymentDt`, path),
          );
          const lastPaymtDate = get(
            formProps.values,
            joinPath(`paymentDetail[${index}].lastPaymentDt`, path),
          );
          const labelHeaderContent = `${paymentAmt}${paymentFreqLabel}`;
          const labelHeaderDate =
            firstPaymtDate !== '' &&
            lastPaymtDate !== '' &&
            firstPaymtDate !== undefined &&
            lastPaymtDate !== undefined
              ? `${firstPaymtDate} - ${lastPaymtDate}`
              : '';
          return (
            <div key={index}>
              {get(formProps.values, joinPath('paymentDetail', path)).length ===
              1 ? (
                renderPaymentDetails(index)
              ) : (
                <>
                  <Divider />
                  <ExpandSection
                    label={`Custom Payment ${index + 1}:`}
                    hideContentBorder
                    open={index !== 0}
                    labelContent={labelHeaderContent}
                    labelContentCenter={labelHeaderDate}
                    handleDelete={
                      !isAddlPayments ? () => handleDelete(index) : undefined
                    }
                    error={
                      get(
                        formProps.errors,
                        joinPath(`paymentDetail[${index}]`, path),
                      ) &&
                      Object.keys(
                        get(
                          formProps.errors,
                          joinPath(`paymentDetail[${index}]`, path),
                        ),
                      )?.length !== 0
                    }
                  >
                    {renderPaymentDetails(index)}
                  </ExpandSection>
                </>
              )}
            </div>
          );
        },
      )}
      <br />
      {get(formProps.values, joinPath('paymentDetail', path)).length <
      MAX_PAYMENT_STREAMS ? (
        <button
          className="action link-text button-link"
          type="submit"
          onClick={handleAddPaymentStream}
        >
          + Add Custom Payment
        </button>
      ) : null}
    </>
  );
};

export const frequencyOptions = [
  { key: 1, value: PaymentFrequencyType.MONTHLY, label: 'Monthly' },
  {
    key: 2,
    value: PaymentFrequencyType.QUARTERLY,
    label: 'Quarterly',
  },
  {
    key: 6,
    value: PaymentFrequencyType.ONE_TIME,
    label: 'One Time',
  },
  {
    key: PaymentFrequencyType.ANNUAL,
    value: PaymentFrequencyType.ANNUAL,
    label: 'Annual',
  },
];

const incrementDecrementOption = [
  { key: 1, value: true, label: 'Percent' },
  { key: 2, value: false, label: 'Amount' },
];

export default PaymentForm;
