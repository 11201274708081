import React, { FunctionComponent } from 'react';

import './ProgressBar.scss';

interface IProgressBarProps {
  startNumber: number;
  endNumber: number;
}

const ProgressBar: FunctionComponent<IProgressBarProps> = ({
  startNumber,
  endNumber,
}) => {
  return (
    <div className="progress-layout">
      <div className="progress-wrap">
        <div className="progress-bar-container">
          <div
            className="progress-bar-value"
            style={{ width: `${renderPercentage(startNumber / endNumber)}%` }}
          />
        </div>
      </div>
    </div>
  );
};

function renderPercentage(progress: number) {
  const percent = 100 - progress * 100;
  if (percent < 0) {
    return 0;
  } else {
    return percent;
  }
}

export default ProgressBar;
