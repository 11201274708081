import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  format,
  parseISO,
} from 'date-fns';
import get from 'lodash/get';
import React, { FunctionComponent, useEffect } from 'react';

import { Input, Select, Toggle } from '../../../common';
import {
  API_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
} from '../../../common/utilities/_dates';
import { fieldHasError } from '../../../common/utilities/FormikHelpers';
import { IRenewOptionsModel, RenewalPeriodType } from '../../../interfaces';

import '../../../common/input/Input.scss';
import './RenewalOption.scss';

export interface IRenewalOption {
  option: IRenewOptionsModel;
  formProps: any;
  path: string;
  index: number;
  renewalOptions: IRenewOptionsModel[];
  deleteRenewal: (id: number) => void;
  hideButton?: boolean;
}

const RenewalOption: FunctionComponent<IRenewalOption> = ({
  formProps,
  path,
  renewalOptions,
  index,
  deleteRenewal,
  hideButton,
}) => {
  const pathWithIndex = `${path}[${index}]`;

  const renewPeriodOptions = [
    { key: 1, value: RenewalPeriodType.DAYS, label: 'Days' },
    { key: 2, value: RenewalPeriodType.WEEKS, label: 'Weeks' },
    { key: 3, value: RenewalPeriodType.MONTHS, label: 'Months' },
    { key: 4, value: RenewalPeriodType.YEARS, label: 'Years' },
  ];

  const calculateRenewEndDt = (
    start: Date,
    amount: number = 0,
    period: RenewalPeriodType | null,
  ) => {
    // -1 day from each to make the date calculation inclusive of the start and end day
    let renewEndndDate: Date;
    switch (period) {
      case RenewalPeriodType.DAYS:
        renewEndndDate = addDays(start, amount - 1);
        break;
      case RenewalPeriodType.WEEKS:
        renewEndndDate = addDays(addWeeks(start, amount), -1);
        break;
      case RenewalPeriodType.MONTHS:
        renewEndndDate = addDays(addMonths(start, amount), -1);
        break;
      case RenewalPeriodType.YEARS:
        renewEndndDate = addDays(addYears(start, amount), -1);
        break;
      default:
        renewEndndDate = start;
        break;
    }

    return format(renewEndndDate, API_DATE_FORMAT);
  };

  const disableReasonableToRenew = () => {
    let shouldDisable = false;
    // checks to see if there are more than 1 renewals
    const previous = renewalOptions[index - 1];

    if (previous && !previous.reasonableToRenew) {
      shouldDisable = true;
    }
    // check to see if there is a next renewal
    const next = renewalOptions[index + 1];
    if (next && next.reasonableToRenew) {
      shouldDisable = true;
    }
    return shouldDisable;
  };

  useEffect(() => {
    const previousRenewal = renewalOptions[index - 1];

    if (previousRenewal) {
      const prevStartDate = previousRenewal.renewStartDt;
      const prevEndDate = previousRenewal.renewEndDt;

      if (prevStartDate && prevEndDate) {
        const newStartDate = format(
          addDays(parseISO(prevEndDate), 1),
          API_DATE_FORMAT,
        );

        const currentStartDate = format(
          parseISO(get(formProps.values, `${pathWithIndex}.renewStartDt`)),
          API_DATE_FORMAT,
        );

        if (newStartDate !== currentStartDate) {
          const currentRenewValue = renewalOptions[index].renewForHowLongValue;
          const currentRenewPeriod =
            renewalOptions[index].renewForHowLongPeriod;

          if (currentRenewValue && currentRenewPeriod) {
            const newEndDate = calculateRenewEndDt(
              parseISO(newStartDate),
              currentRenewValue,
              currentRenewPeriod,
            );

            formProps.setFieldValue(
              `${pathWithIndex}.renewStartDt`,
              newStartDate,
            );

            formProps.setFieldValue(`${pathWithIndex}.renewEndDt`, newEndDate);
          }
        }
      }
    }
  }, [formProps, index, pathWithIndex, renewalOptions]);

  return (
    <div className="renewal-option">
      {hideButton ? null : (
        <button
          className="action link-text button-link renewalRemovalButton"
          type="button"
          onClick={() => deleteRenewal(index)}
        >
          Remove
        </button>
      )}
      <div className="how-long-row">
        <div className="renew-for-how-long">
          <div
            className={`form-input-label ${process.env.REACT_APP_ENABLE_RSM ===
              'true' && 'rsm-class-label'}`}
          >
            Renew for how long?
          </div>
          <div className="form-inputs">
            <Input
              name={`${pathWithIndex}.renewForHowLongValue`}
              onChange={(
                event: React.ChangeEvent<
                  HTMLInputElement | HTMLTextAreaElement
                >,
              ) => {
                formProps.handleChange(event);
                // calculate renew end date
                const renewEndDt = calculateRenewEndDt(
                  parseISO(
                    get(formProps.values, `${pathWithIndex}.renewStartDt`),
                  ),
                  parseInt(event.target.value, 10) || 0,
                  get(
                    formProps.values,
                    `${pathWithIndex}.renewForHowLongPeriod`,
                  ),
                );

                formProps.setFieldValue(
                  `${pathWithIndex}.renewEndDt`,
                  renewEndDt,
                );
              }}
              value={get(
                formProps.values,
                `${pathWithIndex}.renewForHowLongValue`,
              )}
              type="number"
              error={fieldHasError(
                formProps,
                `${pathWithIndex}.renewForHowLongValue`,
              )}
              errorMessage={get(
                formProps.errors,
                `${pathWithIndex}.renewForHowLongValue`,
              )}
            />
            <Select
              id={`renewForHowLongSelect-${index}`}
              name={`${pathWithIndex}.renewForHowLongPeriod`}
              onClick={(name, selectedOption) => {
                formProps.setFieldValue(
                  `${pathWithIndex}.renewForHowLongPeriod`,
                  selectedOption.value,
                );
                // calculate renew end date
                const renewEndDt = calculateRenewEndDt(
                  parseISO(
                    get(formProps.values, `${pathWithIndex}.renewStartDt`),
                  ),
                  get(
                    formProps.values,
                    `${pathWithIndex}.renewForHowLongValue`,
                  ),
                  selectedOption.value,
                );

                formProps.setFieldValue(
                  `${pathWithIndex}.renewEndDt`,
                  renewEndDt,
                );
              }}
              options={renewPeriodOptions}
              value={get(
                formProps.values,
                `${pathWithIndex}.renewForHowLongPeriod`,
              )}
              error={fieldHasError(
                formProps,
                `${pathWithIndex}.renewForHowLongPeriod`,
              )}
              errorMessage={get(
                formProps.errors,
                `${pathWithIndex}.renewForHowLongPeriod`,
              )}
            />
          </div>
        </div>
        <div className="renewal-start-date">
          <div
            className={`form-input-label ${process.env.REACT_APP_ENABLE_RSM ===
              'true' && 'rsm-class-label'}`}
          >
            Renewal Start Date
          </div>
          <div className="renewal-date">
            {get(formProps.values, `${pathWithIndex}.renewStartDt`) &&
              format(
                parseISO(
                  get(formProps.values, `${pathWithIndex}.renewStartDt`),
                ),
                DEFAULT_DATE_FORMAT,
              )}
          </div>
        </div>
        <div className="renewal-end-date">
          <div
            className={`form-input-label ${process.env.REACT_APP_ENABLE_RSM ===
              'true' && 'rsm-class-label'}`}
          >
            Renewal End Date
          </div>
          <div className="renewal-date">
            {get(formProps.values, `${pathWithIndex}.renewStartDt`) &&
              format(
                parseISO(get(formProps.values, `${pathWithIndex}.renewEndDt`)),
                DEFAULT_DATE_FORMAT,
              )}
          </div>
        </div>
      </div>
      <Toggle
        id={`reasonablyCertainToRenew-${index}`}
        name={`${pathWithIndex}.reasonableToRenew`}
        label="Reasonably certain to exercise this option?"
        checked={get(formProps.values, `${pathWithIndex}.reasonableToRenew`)}
        onChange={(checked: boolean) => {
          formProps.setFieldValue(
            `${pathWithIndex}.reasonableToRenew`,
            checked,
          );
        }}
        showInPanel={true}
        disabled={disableReasonableToRenew()}
      />
    </div>
  );
};

export default RenewalOption;
