import { Form, Formik, FormikProps } from 'formik';
import get from 'lodash/get';
import React, {
  Fragment,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { RequiredStringSchema } from 'yup/lib/string';
import {
  getAccountList,
  getMappedIntegrationSettings,
  submitIntegrationAccountSettings,
} from '../../api';
import { Autocomplete, Divider } from '../../common';
import { IAutocompleteOption } from '../../common/autocomplete/Autocomplete';
import GuruLogoSpinner from '../../common/loaders/GuruLogoSpinner';
import { hideModal, showModal } from '../../common/modal/actions';
import Modal from '../../common/modal/Modal';
import { useResourceContext } from '../../common/resource-context';
import { ClientIntegration, IJournalEntryExportModel } from '../../interfaces';
import { IStore } from '../../store';
import { storeQBMappings } from '../quickbooks/actions';
import { storeIntegrationMappings } from './actions';

import './IntegrationsSettingsModal.scss';

interface IIntegrationsSettingsModalProps {
  open: boolean;
  otherProps: {
    exportJE?: string[];
    journalEntry?: IJournalEntryExportModel;
    clientIntegration: ClientIntegration;
  };
}

export interface IAccountListItem {
  id: string;
  name: string;
  accountNbr: string | null;
}

export interface IIntegrationSettingsMapping {
  cash?: string;
  expense?: string;
  shortTermPreRent?: string;
  longTermPreRent?: string;
  shortTermDefRent?: string;
  longTermDefRent?: string;
  shortTermLiability?: string;
  longTermLiability?: string;
  depreciationExpense?: string;
  accumulatedDepreciation?: string;
  rouAsset?: string;
  equity?: string;
  interestExpense?: string;
  otherExpense?: string;
  assetBalance?: string;
  terminationGain?: string;
  terminationLoss?: string;
  begPrepaidRent?: string;
  begDefRent?: string;
}

const IntegrationLabels: { [key: string]: string } = {
  cash: 'Cash',
  expense: 'Lease Expense',
  shortTermPreRent: 'ST Prepaid Rent',
  longTermPreRent: 'LT Prepaid Rent',
  shortTermDefRent: 'ST Deferred Rent',
  longTermDefRent: 'LT Deferred Rent',
  shortTermLiability: 'ST Lease Liability',
  longTermLiability: 'LT Lease Liability',
  depreciationExpense: 'Depreciation Expense',
  accumulatedDepreciation: 'Accumulated Depreciation',
  rouAsset: 'ROU Asset',
  equity: 'Equity',
  interestExpense: 'Interest Expense',
  otherExpense: 'Other Expense',
  assetBalance: 'Capital Lease Asset',
  terminationGain: 'Gain on Lease Termination',
  terminationLoss: 'Loss on Lease Termination',
  begPrepaidRent: 'Beginning Prepaid Rent',
  begDefRent: 'Beginning Deferred Rent',
};

const schema = yup.object().shape({
  cash: yup
    .string()
    .label(IntegrationLabels.cash)
    .nullable(),
  expense: yup
    .string()
    .label(IntegrationLabels.expense)
    .nullable(),
  shortTermPreRent: yup
    .string()
    .label(IntegrationLabels.shortTermPreRent)
    .nullable(),
  longTermPreRent: yup
    .string()
    .label(IntegrationLabels.longTermPreRent)
    .nullable(),
  shortTermDefRent: yup
    .string()
    .label(IntegrationLabels.shortTermDefRent)
    .nullable(),
  longTermDefRent: yup
    .string()
    .label(IntegrationLabels.longTermDefRent)
    .nullable(),
  shortTermLiability: yup
    .string()
    .label(IntegrationLabels.shortTermLiability)
    .nullable(),
  longTermLiability: yup
    .string()
    .label(IntegrationLabels.longTermLiability)
    .nullable(),
  depreciationExpense: yup
    .string()
    .label(IntegrationLabels.depreciationExpense)
    .nullable(),
  accumulatedDepreciation: yup
    .string()
    .label(IntegrationLabels.accumulatedDepreciation)
    .nullable(),
  rouAsset: yup
    .string()
    .label(IntegrationLabels.rouAsset)
    .nullable(),
  equity: yup
    .string()
    .label(IntegrationLabels.equity)
    .nullable(),
  interestExpense: yup
    .string()
    .label(IntegrationLabels.interestExpense)
    .nullable(),
  otherExpense: yup
    .string()
    .label(IntegrationLabels.otherExpense)
    .nullable(),
  assetBalance: yup
    .string()
    .label(IntegrationLabels.assetBalance)
    .nullable(),
  terminationGain: yup
    .string()
    .label(IntegrationLabels.terminationGain)
    .nullable(),
  terminationLoss: yup
    .string()
    .label(IntegrationLabels.terminationLoss)
    .nullable(),
  begPrepaidRent: yup
    .string()
    .label(IntegrationLabels.begPrepaidRent)
    .nullable(),
  begDefRent: yup
    .string()
    .label(IntegrationLabels.begDefRent)
    .nullable(),
});

const generateIntegrationSchema = (requiredFields: string[]) => {
  const generated: {
    [key: string]: RequiredStringSchema<string | undefined>;
  } = {};

  requiredFields.forEach(
    (rf) =>
      (generated[rf] = yup
        .string()
        .required()
        .label(IntegrationLabels[rf])),
  );

  return yup.object().shape(generated);
};

const initialValues = {
  cash: '',
  expense: '',
  shortTermPreRent: '',
  longTermPreRent: '',
  shortTermDefRent: '',
  longTermDefRent: '',
  shortTermLiability: '',
  longTermLiability: '',
  depreciationExpense: '',
  accumulatedDepreciation: '',
  rouAsset: '',
  equity: '',
  interestExpense: '',
  otherExpense: '',
  assetBalance: '',
  terminationGain: '',
  terminationLoss: '',
  begDefRent: '',
  begPrepaidRent: '',
};

const IntegrationsSettingsModal: FunctionComponent<IIntegrationsSettingsModalProps> = ({
  open,
  otherProps,
}) => {
  const resources = useResourceContext();
  const dispatch = useDispatch();
  const userCompanyData = useSelector((state: IStore) => {
    return state.user.company!;
  });
  const [accountList, setAccountList] = useState<IAccountListItem[]>([]);
  const [initialData, setInitialData] = useState<IIntegrationSettingsMapping>(
    initialValues,
  );
  const [processing, setProcessing] = useState(false);

  let integrationName = '';
  switch (otherProps?.clientIntegration) {
    case ClientIntegration.SAGE:
      integrationName = 'Sage';
      break;
    case ClientIntegration.XERO:
      integrationName = 'Xero';
      break;
    case ClientIntegration.QUICKBOOKS:
      integrationName = 'Quickbooks';
      break;
    default:
      integrationName = '';
      break;
  }

  useEffect(() => {
    const attemptMapping = (accList: IAccountListItem[]) => {
      const newData: { [key: string]: string } = {};

      // alphabetize account list
      const sortedAccountList = accList.sort((a, b) =>
        a.name > b.name ? 1 : -1,
      );

      // alphabetize IntegrationLabels
      const sortedIntegrationAccounts = Object.entries(
        IntegrationLabels,
      ).sort((a, b) =>
        IntegrationLabels[a[1]] > IntegrationLabels[b[1]] ? 1 : -1,
      );

      // loop through Integration labels
      sortedIntegrationAccounts.forEach(([key, label]) => {
        // see if anything in account list is equal to current Integration label
        const foundAccountIndex = sortedAccountList.findIndex(
          (account) => account.name === label,
        );

        // if so, add to autoMapList and remove from account list
        if (foundAccountIndex !== -1) {
          // get the number from account list
          const account = accList.find((acc) => acc.name === label);
          if (account?.id) {
            newData[key] = `${
              account.accountNbr ? `${account.accountNbr} - ` : ''
            }${account.id}`;
            sortedAccountList.splice(foundAccountIndex, 1);
          }
        }
      });

      return {
        ...initialValues,
        ...newData,
      };
    };

    const fetchAsyncData = async () => {
      const mappedSettings = await getMappedIntegrationSettings();
      const accountListResponse = await getAccountList(
        otherProps.clientIntegration,
      );

      if (accountListResponse) {
        if (mappedSettings) {
          setInitialData(mappedSettings);
          dispatch(storeQBMappings(mappedSettings));
        } else {
          setInitialData(attemptMapping([...accountListResponse]));
        }

        setAccountList(accountListResponse);
      }
    };

    fetchAsyncData();
  }, [dispatch, otherProps.clientIntegration]);

  const formikRef = useRef<Formik<IIntegrationSettingsMapping>>(null);

  const submitModal = () => {
    if (formikRef) {
      formikRef.current!.submitForm();
    }
  };

  const handleFormSubmit = async (accounts: IIntegrationSettingsMapping) => {
    setProcessing(true);
    if (userCompanyData.clientId) {
      await submitIntegrationAccountSettings(accounts);
      dispatch(storeIntegrationMappings(accounts));
      dispatch(hideModal());
      if (otherProps?.exportJE) {
        dispatch(
          showModal({
            modal: {
              modalType: 'INTEGRATIONS_EXPORT_JE',
              modalProps: {
                open: true,
                otherProps: { journalEntry: otherProps.journalEntry },
              },
            },
          }),
        );
      }
      setProcessing(false);
    }
  };

  const formattedAccountList = accountList.map((acc: any) => ({
    key: acc.id,
    value: acc.name,
    label: `${acc.accountNbr ? `${acc.accountNbr} - ` : ''}${acc.name}`,
  }));

  const getFilteredOptions = (accounts: IIntegrationSettingsMapping) => {
    // find how many options we need to filter out
    const filteredAccounts = Object.values(accounts).filter(
      (account) => account !== '',
    );

    // new option so formattedAccountList is not effected by splice
    const newFormattedAccountList = [...formattedAccountList];

    // if there are no current options, no need to filter
    if (filteredAccounts.length !== 0) {
      // for every filtered Accounts
      // remove the index of newFormattedAccountList that share the same AccountId
      filteredAccounts.forEach((formatFilteredItem) =>
        newFormattedAccountList.splice(
          newFormattedAccountList.findIndex(
            (tempOption) => tempOption.key === formatFilteredItem,
          ),
          1,
        ),
      );
    }

    // sort options alphabetically
    return newFormattedAccountList.sort((a, b) => (a.value > b.value ? 1 : -1));
  };

  const getAccountValue = (id: string) =>
    formattedAccountList.find(
      (account) =>
        account.label
          .substr(0, account.label.indexOf('-'))
          .replace(/\s+/g, '') === id,
    )?.value;

  const filteredAccountsForExport = (account: string) =>
    otherProps?.exportJE
      ? otherProps.exportJE.findIndex((je) => je === account) !== -1
      : account;

  return (
    <Modal
      title={`${integrationName} Account Set Up`}
      open={open}
      onConfirm={submitModal}
      neutralButtonText="Cancel"
      positiveButtonText={
        otherProps?.exportJE !== undefined ? 'Export' : 'Save'
      }
      processing={processing}
    >
      <Formik
        ref={formikRef}
        initialValues={initialData}
        onSubmit={handleFormSubmit}
        validateOnChange={false}
        validationSchema={
          otherProps?.exportJE
            ? generateIntegrationSchema(otherProps.exportJE)
            : schema
        }
        enableReinitialize={true}
      >
        {(formProps: FormikProps<IIntegrationSettingsMapping>) => {
          return (
            <div className="formik-integration-wrapper">
              {accountList && accountList.length !== 0 ? (
                <Form noValidate={true} className="integration-form-wrapper">
                  <div className="integration-blurb">
                    To start exporting to {integrationName}, please match the
                    {resources.text(
                      'IntegrationsSettingModal_IntegrationText',
                      'LeaseGuru',
                    )}{' '}
                    accounts with the respective
                    {' ' + integrationName + ' '}
                    accounts.
                  </div>
                  <div className="integration-header">
                    <div>LeaseGuru Accounts</div>
                    <div>{integrationName} Accounts</div>
                  </div>
                  <hr />
                  {Object.keys(formProps.values)
                    .sort((a, b) =>
                      IntegrationLabels[a] > IntegrationLabels[b] ? 1 : -1,
                    )
                    .filter((account) => filteredAccountsForExport(account))
                    .map((account, i) => (
                      <Fragment key={i}>
                        <div className="integration-mapping-wrapping">
                          <label
                            htmlFor={`${account}`}
                            className="integration-id"
                          >
                            {IntegrationLabels[account]}
                          </label>
                          <Autocomplete
                            onSelection={(
                              selectedItem: IAutocompleteOption<
                                IAccountListItem
                              >,
                            ) => {
                              if (selectedItem?.label) {
                                const item = selectedItem.label
                                  .substr(0, selectedItem.label.indexOf('-'))
                                  .replace(/\s+/g, '');
                                formProps.setFieldValue(account, item);
                              }
                            }}
                            value={getAccountValue(
                              get(formProps.values, account),
                            )}
                            options={formattedAccountList}
                            filterableOptions={getFilteredOptions(
                              formProps.values,
                            )}
                            error={get(formProps.errors, account)}
                            errorMessage={get(formProps.errors, account)}
                            onSelectionClear={() => {
                              formProps.setFieldValue(account, '');
                            }}
                            placeholder={`Enter your ${integrationName} account...`}
                          />
                        </div>
                        {i !==
                        Object.keys(formProps.values).filter((value) =>
                          filteredAccountsForExport(value),
                        ).length -
                          1 ? (
                          <Divider />
                        ) : null}
                      </Fragment>
                    ))}
                </Form>
              ) : (
                <div className="spin-loader">
                  <h2>Syncing with {integrationName}</h2>
                  <GuruLogoSpinner inProgress />
                </div>
              )}
            </div>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default IntegrationsSettingsModal;
