import { Form, Formik, FormikContext, FormikValues } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as yup from 'yup';

import { editProfile, editUser } from '../../api';
import Divider from '../../common/divider/Divider';
import Input from '../../common/input/Input';
import Modal from '../../common/modal/Modal';
import { PasswordSchema } from '../../common/utilities/_schemas';
import { fieldHasError } from '../../common/utilities/FormikHelpers';
import { IUserEditModel, IUserModel } from '../../interfaces';
import { IStore } from '../../store';
import { saveUser } from '../auth/actions';

import './UserSettingsModal.scss';

enum ScreenView {
  Main,
  EditUser,
  EditPassword,
}

const schemas = {
  [ScreenView.EditUser]: yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup
      .string()
      .required('Email address is required')
      .email('Email address must be a valid email.'),
  }),
  [ScreenView.EditPassword]: yup.object().shape({
    password: PasswordSchema.required('Please enter your new password'),
    passwordConfirmation: yup
      .string()
      .required('Please confirm your new password')
      .oneOf([yup.ref('password')], 'Passwords must match'),
    currentPassword: yup
      .string()
      .required('Please confirm your current password'),
  }),
};

class UserSettingsModal extends Component<
  IUserSettingsModalProps,
  IUserSettingsState
> {
  public state: IUserSettingsState = {
    screenView: ScreenView.Main,
    firstName: this.props.user.firstName,
    lastName: this.props.user.lastName,
    email: this.props.user.email,
    password: undefined,
    passwordConfirmation: undefined,
    errorMessage: null,
  };
  private currentFormProps: FormikContext<any> | null = null;

  public onSubmit = async (
    values: IUserEditModel,
    resetForm?: (nextValues?: FormikValues) => void,
  ) => {
    this.setState({ errorMessage: null });

    try {
      const userValues = {
        ...values,
        userId: this.props.user.userId,
        currentEmail: this.props.user.email,
        company: this.props.user.company,
      };
      delete userValues.passwordConfirmation;

      if (values.email) {
        await editProfile(userValues, false);
      } else {
        await editUser(userValues, false);
      }

      const user = {
        ...this.props.user,
        firstName: values.firstName || this.props.user.firstName,
        lastName: values.lastName || this.props.user.lastName,
        email: values.email || this.props.user.email,
      };

      this.props.saveUser(user);

      if (resetForm) {
        resetForm({});
      }

      this.switchScreen(ScreenView.Main);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // trigger the validation on the currentpassword field
        this.currentFormProps?.setFieldError(
          'currentPassword',
          'The entered password is incorrect. Please try again.',
        );
      } else {
        this.setState({ errorMessage: error.response.data.message });
      }
    }
  };

  public submitModal = async () => {
    if (this.state.screenView === ScreenView.Main) {
      this.props.hideModal();
    } else if (this.currentFormProps) {
      this.currentFormProps.submitForm();
    }
  };

  public onCancel = () => {
    if (this.state.screenView !== ScreenView.Main) {
      this.switchScreen(ScreenView.Main);
    } else {
      this.props.hideModal();
    }
  };

  public switchScreen = (screenView: ScreenView) => {
    this.setState({
      errorMessage: null,
      screenView,
    });
  };

  public render() {
    const { firstName, lastName, email } = this.props.user;
    return (
      <Modal
        title="Profile"
        open={this.props.open}
        onConfirm={this.submitModal}
        neutralButtonText={
          this.state.screenView === ScreenView.Main ? 'Close' : 'Cancel'
        }
        positiveButtonText={
          this.state.screenView === ScreenView.Main ? undefined : 'Save'
        }
        onCancel={this.onCancel}
        hideModal={this.props.hideModal}
      >
        {this.state.screenView === ScreenView.Main && (
          <div className="userSettingsContainer">
            <div className="question-wrap">
              <div className="info">
                <div className="name">
                  {firstName} {lastName}
                </div>
                <div className="email">{email}</div>
              </div>
              <div className="edit">
                <span
                  className="link-text blue-label"
                  onClick={() => this.switchScreen(ScreenView.EditUser)}
                >
                  Edit
                </span>
              </div>
            </div>
            <Divider />
            <div className="question-wrap">
              <label htmlFor="name" className="question-label">
                Password
              </label>
              <div className="edit">
                **************
                <span
                  className="link-text blue-label"
                  onClick={() => this.switchScreen(ScreenView.EditPassword)}
                >
                  Edit
                </span>
              </div>
            </div>
          </div>
        )}
        {this.state.screenView === ScreenView.EditUser && (
          <Formik
            key={ScreenView.EditUser}
            initialValues={{
              firstName,
              lastName,
              email,
            }}
            validationSchema={schemas[ScreenView.EditUser]}
            onSubmit={(values: IUserEditModel) => this.onSubmit(values)}
          >
            {(formProps: FormikContext<IUserEditModel>) => {
              this.currentFormProps = formProps;
              return (
                <Form className="userSettingsEditContainer" noValidate={true}>
                  <p className="errorMessage">{this.state.errorMessage}</p>
                  <div className="question-wrap">
                    <label htmlFor="name" className="question-label">
                      First Name
                    </label>
                    <Input
                      name="firstName"
                      onChange={formProps.handleChange}
                      value={formProps.values.firstName}
                      error={fieldHasError(formProps, 'firstName')}
                      errorMessage={formProps.errors.firstName}
                    />
                  </div>
                  <Divider />
                  <div className="question-wrap">
                    <label htmlFor="name" className="question-label">
                      Last Name
                    </label>
                    <Input
                      name="lastName"
                      onChange={formProps.handleChange}
                      value={formProps.values.lastName}
                      error={fieldHasError(formProps, 'lastName')}
                      errorMessage={formProps.errors.lastName}
                    />
                  </div>
                  <Divider />
                  <div className="question-wrap">
                    <label htmlFor="name" className="question-label">
                      Email Address
                    </label>
                    <Input
                      name="email"
                      onChange={formProps.handleChange}
                      value={formProps.values.email}
                      error={fieldHasError(formProps, 'email')}
                      errorMessage={formProps.errors.email}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
        {this.state.screenView === ScreenView.EditPassword && (
          <Formik
            key={ScreenView.EditPassword}
            initialValues={{}}
            validationSchema={schemas[ScreenView.EditPassword]}
            onSubmit={(values: IUserEditModel, { resetForm }: FormikValues) => {
              return this.onSubmit(values, resetForm);
            }}
          >
            {(formProps: FormikContext<IUserEditModel>) => {
              this.currentFormProps = formProps;
              return (
                <Form className="userSettingsEditContainer" noValidate={true}>
                  <p className="errorMessage">{this.state.errorMessage}</p>
                  <div className="question-wrap">
                    <label htmlFor="name" className="question-label">
                      Current Password
                    </label>
                    <Input
                      name="currentPassword"
                      type="password"
                      onChange={formProps.handleChange}
                      value={formProps.values.currentPassword}
                      error={fieldHasError(formProps, 'currentPassword')}
                      errorMessage={formProps.errors.currentPassword}
                    />
                  </div>
                  <Divider />
                  <div className="question-wrap">
                    <label htmlFor="name" className="question-label">
                      New Password
                    </label>
                    <Input
                      name="password"
                      type="password"
                      onChange={formProps.handleChange}
                      value={formProps.values.password}
                      error={fieldHasError(formProps, 'password')}
                      errorMessage={formProps.errors.password}
                    />
                  </div>
                  <Divider />
                  <div className="question-wrap">
                    <label htmlFor="name" className="question-label">
                      Confirm New Password
                    </label>
                    <Input
                      name="passwordConfirmation"
                      type="password"
                      onChange={formProps.handleChange}
                      value={formProps.values.passwordConfirmation}
                      error={fieldHasError(formProps, 'passwordConfirmation')}
                      errorMessage={formProps.errors.passwordConfirmation}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </Modal>
    );
  }
}

interface IUserSettingsModalProps extends IConnectedProps {
  title: string;
  open: boolean;
  hideModal: () => void;
  saveUser: (user: IUserModel) => void;
}

interface IUserSettingsState extends IUserEditModel {
  screenView: ScreenView;
  errorMessage: string | null;
}

interface IConnectedProps {
  user: IUserModel;
}

function mapStateToProps(store: IStore): IConnectedProps {
  return {
    user: store.user || {},
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      saveUser,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsModal);
