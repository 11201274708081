import { api, formatError } from './api';

export async function getDefinitions() {
  const rawResponse = await api.get(`/GetAllDefinitions`);

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  return rawResponse.data;
}
