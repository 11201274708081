import { Form, FormikContext } from 'formik';
import React, { FunctionComponent } from 'react';
import { Input, Select } from '../../../common';
import FormGrid from '../../../common/form-grid/FormGrid';
import RadioGroup from '../../../common/radio-group/RadioGroup';
import {
  IFRS_TRANSITION_YEAR,
  monthOptions,
  yearOptions1,
} from '../../../common/utilities/_constants';
import { formatDropOptions } from '../../../common/utilities/_helpers';
import { fieldHasError } from '../../../common/utilities/FormikHelpers';
import { IClientIndustriesModel } from '../../../interfaces';
import { AuthCard, AuthCardSection } from '../../auth/AuthCard';

export interface ICompanyInfoFormState {
  companyName: string;
  companyType: number;
  industryType?: number;
  jurisdiction: string;
  leaseCountEstimation?: number;
  transitionMonth: number | undefined;
  transitionYear: number | undefined;
}

interface ICompanyInfoFormProps {
  formProps: FormikContext<ICompanyInfoFormState>;
  industryList?: IClientIndustriesModel[] | any;
  isCreateClientOnly?: boolean;
}

const CompanyInfoForm: FunctionComponent<ICompanyInfoFormProps> = ({
  formProps,
  industryList,
  isCreateClientOnly,
}) => {
  const industryItemList = formatDropOptions(
    industryList,
    'id',
    'id',
    'industryName',
  );

  const companyTitle = isCreateClientOnly ? 'the company' : 'your company';
  const companyNameErrorMsg = isCreateClientOnly
    ? formProps.errors.companyName?.replace('your company', 'the company’s')
    : formProps.errors.companyName;
  const companyIndustryErrorMsg = `Please enter ${
    isCreateClientOnly ? 'the company’s' : 'your'
  } industry type`;

  const getLeaseCountEstimation = () => {
    return !isCreateClientOnly ? (
      <>
        <label className="try-question-label">
          How many leases does your company have? (estimated)
        </label>
        <Input
          name="leaseCountEstimation"
          error={fieldHasError(formProps, 'leaseCountEstimation')}
          errorMessage="Please enter an estimated number of leases"
          value={formProps.values.leaseCountEstimation}
          onChange={formProps.handleChange}
          required={true}
          type="number"
          allowNegative={false}
          decimalScale={0}
        />
      </>
    ) : null;
  };
  return (
    <Form noValidate={true}>
      <AuthCard>
        <AuthCardSection>
          <label className="try-question-label">
            What is the name of {companyTitle}?
          </label>
          <Input
            name="companyName"
            error={fieldHasError(formProps, 'companyName')}
            errorMessage={companyNameErrorMsg}
            value={formProps.values.companyName}
            onChange={formProps.handleChange}
            required={false}
          />
          <label className="try-question-label">
            What industry best fits {companyTitle}?
          </label>
          <Select
            name="industryType"
            placeholder="Select industry type"
            error={fieldHasError(formProps, 'industryType')}
            errorMessage={companyIndustryErrorMsg}
            value={formProps.values.industryType}
            onClick={(_: any, option: any) => {
              formProps.setValues({
                ...formProps.values,
                industryType: option.value,
              });
            }}
            options={industryItemList}
          />
          {getLeaseCountEstimation()}
          {isCreateClientOnly && (
            <div className="companyTypeWrapper">
              <label className="try-question-label">
                Is the company public or private?
              </label>
              <RadioGroup
                name="companyType"
                onSelect={(_, selectedValue: number) => {
                  formProps.setFieldValue('companyType', selectedValue);
                }}
                value={formProps.values.companyType}
                options={[
                  { key: 2, value: 2, label: 'Private' },
                  { key: 1, value: 1, label: 'Public' },
                ]}
              />
            </div>
          )}

          <label className="try-question-label">
            What rules {isCreateClientOnly ? 'is the company ' : 'are you '}
            reporting under?
          </label>
          <RadioGroup
            name="jurisdiction"
            groupLabel=""
            onSelect={(_, selectedValue: string) => {
              formProps.setFieldValue('jurisdiction', selectedValue);
              formProps.setFieldValue(
                'transitionYear',
                selectedValue === 'IFRS' ? IFRS_TRANSITION_YEAR : undefined,
              );
            }}
            value={formProps.values.jurisdiction}
            options={[
              { key: 0, value: 'GAAP', label: 'GAAP' },
              { key: 1, value: 'IFRS', label: 'IFRS' },
            ]}
          />
          {!isCreateClientOnly && (
            <div>
              <label className="try-question-label">
                When are you transitioning to the new lease accounting rules?
              </label>

              <FormGrid colWidth={2}>
                <Select
                  name="transitionMonth"
                  placeholder="Select Month"
                  error={fieldHasError(formProps, 'transitionMonth')}
                  errorMessage={formProps.errors.transitionMonth}
                  value={formProps.values.transitionMonth}
                  onClick={(_: any, option: any) => {
                    formProps.setValues({
                      ...formProps.values,
                      transitionMonth: option.value,
                    });
                  }}
                  options={monthOptions}
                />
                <Select
                  name="transitionYear"
                  placeholder="Select Year"
                  error={fieldHasError(formProps, 'transitionYear')}
                  errorMessage={formProps.errors.transitionYear}
                  value={formProps.values.transitionYear}
                  onClick={(_: any, option: any) => {
                    formProps.setValues({
                      ...formProps.values,
                      transitionYear: option.value,
                    });
                  }}
                  options={yearOptions1}
                  disabled={formProps.values.jurisdiction === 'IFRS'}
                />
              </FormGrid>
            </div>
          )}
        </AuthCardSection>
      </AuthCard>
    </Form>
  );
};

export default CompanyInfoForm;
