import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../common/modal/actions';
import Modal from '../../../common/modal/Modal';
import CreateAccount from '../../../features/createAccount/CreateAccount';
import './CreateClientModal.scss';
const CreateClientModal: FunctionComponent = () => {
  const dispatch = useDispatch();

  return (
    <Modal
      open={true}
      mainClass="modal-content-wrapper"
      title="ADD COMPANY"
      showCloseButton={true}
      modalSize="small"
      headerClass="modal-title-override"
    >
      <CreateAccount
        isCreateClientOnly={true}
        onClientCreateHandlder={dispatch(hideModal)}
      />
    </Modal>
  );
};

export default CreateClientModal;
