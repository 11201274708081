import React, { FunctionComponent } from 'react';

import './SelectableImage.scss';

interface ISelectableImageProps {
  image?: string;
  imageName: string;
  onClick: () => void;
  isSelected?: boolean;
  largeImageText?: string;
  smallImageText?: string;
}

const SelectableImage: FunctionComponent<ISelectableImageProps> = ({
  image,
  imageName,
  onClick,
  isSelected = false,
  largeImageText,
  smallImageText,
}) => (
  <div
    className={`selectable-image-wrapper ${isSelected ? 'selected' : ''}`}
    onClick={onClick}
  >
    <img src={image} alt={imageName} className="selectable-image" />
    {largeImageText || smallImageText ? (
      <div className="selectable-image-footer">
        {largeImageText ? (
          <p className="large-image-text">{largeImageText}</p>
        ) : null}
        {smallImageText ? (
          <p className="small-image-text">{smallImageText}</p>
        ) : null}
      </div>
    ) : null}
  </div>
);

export default SelectableImage;
