import format from 'date-fns/format';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  CircleIcon,
  Currency,
  DateProgressBar,
  StatusIndicator,
} from '../../../common';
import { DEFAULT_DATE_FORMAT } from '../../../common/utilities/_dates';
import { ILeaseHeaderModel } from '../../../interfaces';
import './LeaseCard.scss';

interface IProps extends RouteComponentProps {
  lease: ILeaseHeaderModel;
  addToRecentList: (lease: ILeaseHeaderModel) => void;
}

class LeaseCard extends Component<IProps> {
  public onClickLease() {
    const { lease } = this.props;
    this.props.addToRecentList(lease);
    if (window.innerWidth >= 480) {
      this.props.history.push(`/leases/${lease.leaseId}`);
    }
  }

  public generateIcon = () => {
    let icon;
    switch (this.props.lease.assetType) {
      case 'Building':
        icon = 'building';
        break;
      case 'Vehicle':
        icon = 'vehicle';
        break;
      case 'Equipment':
        icon = 'equipment';
        break;
      case 'Land':
        icon = 'land';
        break;
      case 'Other':
        icon = 'other';
        break;
      default:
        icon = undefined;
    }
    return icon;
  };

  public render() {
    const { lease } = this.props;
    return (
      <div
        className={`lease-card ${process.env.REACT_APP_ENABLE_RSM === 'true' &&
          'rsm-class-lease-card'}`}
        onClick={() => this.onClickLease()}
        id={`${lease.leaseId}`}
      >
        <div className="top-half">
          <div className="top-row">
            <div
              className={`assetType ${lease.assetType === 'Land' && 'land'}`}
            >
              {lease.assetType}
            </div>
            <div className="lease-card-status-indicator">
              <StatusIndicator statusLabel={lease.leaseStatus} />
            </div>
          </div>
          <div className="lease-card-icon-row">
            <div className="lease-card-icon">
              <CircleIcon iconURI={this.generateIcon()} />
            </div>
            <div className="lease-card-title">
              <span>{lease.assetName}</span>
            </div>
          </div>
          <div className="lease-card-summary">
            <div className="lease-card-description">{lease.description}</div>
          </div>
        </div>

        <div className="lease-card-details">
          <span className="lease-dates-label">LEASE DATES</span>
          <div className="lease-card-dates">
            <DateProgressBar
              startDate={lease.leaseBeginDt}
              endDate={lease.leaseEndDt}
            />
          </div>
          <div className="lease-card-payment">
            <span className="lease-label">REMAINING PMT</span>
            <span className="small-bold lease-card-payment-amount">
              {lease.remainingPayments}
            </span>
          </div>
          <div className="lease-card-payment">
            <span className="lease-label">CURRENT PMT</span>
            {lease.currentPaymentDate &&
            lease.currentPaymentDate !== '0001-01-01T00:00:00' ? (
              <>
                <span className="small-bold lease-card-payment-amount">
                  <Currency value={lease.currentPaymentAmt} />
                </span>
                <span className="small-text lease-card-payment-date">
                  {format(
                    new Date(lease.currentPaymentDate),
                    DEFAULT_DATE_FORMAT,
                  )}
                </span>
              </>
            ) : (
              <span className="small-text lease-card-payment-date">
                {String.fromCharCode(8211)}
              </span>
            )}
          </div>
          <div className="lease-card-payment">
            <span className="lease-label">NEXT PMT</span>
            {lease.nextPaymentDate &&
            lease.nextPaymentDate !== '0001-01-01T00:00:00' ? (
              <>
                <span className="small-bold lease-card-payment-amount">
                  <Currency value={lease.nextPaymentAmt} />
                </span>
                <span className="small-text lease-card-payment-date">
                  {format(new Date(lease.nextPaymentDate), DEFAULT_DATE_FORMAT)}
                </span>
              </>
            ) : (
              <span className="small-text lease-card-payment-date">
                {String.fromCharCode(8211)}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }

  // private renderSerialNbr(lease: ILeaseHeaderModel) {
  //   if (!!lease.city && !!lease.state) {
  //     return (
  //       <Fragment>
  //         {lease.serialNbr && (
  //           <>
  //             <span>{`${lease.serialNbr} `}</span>
  //             <span>&#9679;</span>
  //           </>
  //         )}
  //         <span>{` ${lease.city}, ${lease.state}`}</span>
  //       </Fragment>
  //     );
  //   } else {
  //     return lease.serialNbr ? lease.serialNbr : '';
  //   }
  // }
}

export default withRouter(LeaseCard);
