import React, { FunctionComponent } from 'react';
import './AuthCard.scss';

export const AuthCard: FunctionComponent = (props) => {
  return <div className="card">{props.children}</div>;
};

export const AuthCardSection: FunctionComponent = (props) => {
  return <div className="section">{props.children}</div>;
};

export const AuthCardDivider: FunctionComponent = () => {
  return <div className="auth-divider" />;
};

export const AuthCardDividerFull: FunctionComponent = () => {
  return <div className="dividerFull" />;
};
