import React, { Dispatch, MutableRefObject } from 'react';
import { showModal } from '../../../common/modal/actions';
import { CheckIsMultiClientAdmin } from '../../../common/utilities/_authHelpers';
import { IPlanFeatureModel, IPlanModel, IUserModel } from '../../../interfaces';
import {
  getFeaturesByPlan,
  getPlanByName,
  mapPlanDisplayNameToPlanSubTier,
} from '../../plansAndPricing/helpers/helpers';
import { SubscriptionModalPages } from '../../subscriptions/SubscriptionModal';

export const getNextPlan = (
  userPlan: IPlanModel | undefined,
  allPlans: IPlanModel[],
  isCPAAdmin: boolean,
): IPlanModel | undefined => {
  const premiumPlans = isCPAAdmin
    ? ['CPA Plan 1', 'CPA Plan 2', 'CPA Plan 3', 'CPA Plan 4', 'CPA Plan 5']
    : allPlans
        .filter((p) => p.tier === 'Premium')
        .map((p) => mapPlanDisplayNameToPlanSubTier(p.displayName));

  if (userPlan === undefined || userPlan.name === undefined) {
    return allPlans.find((p) => p.name.endsWith(premiumPlans[0]));
  }

  let nextPlanName: string = premiumPlans[0];
  const currentPlanIndex = premiumPlans.findIndex((p) =>
    userPlan.name.endsWith(p),
  );
  if (currentPlanIndex > -1 && currentPlanIndex < premiumPlans.length) {
    nextPlanName = premiumPlans[currentPlanIndex + 1];
  }

  return allPlans.find((p) => p.name.endsWith(nextPlanName));
};

export const getPlanSummary = (plan: IPlanModel | undefined): string => {
  if (plan === undefined) {
    return '';
  }

  let leaseCounts: string = '0';
  const pickedFeature: IPlanFeatureModel | undefined = plan?.features.find(
    (feature: IPlanFeatureModel) => feature.name === 'Lease Count',
  );

  if (pickedFeature) {
    leaseCounts = pickedFeature.attributes[0].values[0];
  }
  return `${leaseCounts} leases for $${plan?.price}/yr`;
};

export const registerClickOutsideHandler = (
  hidePopover: () => void,
  leaseCountRef: MutableRefObject<HTMLElement | null>,
): (() => void) => {
  const clickHandler = (e: MouseEvent): void => {
    const { current: leaseCountButton } = leaseCountRef;
    const isLeaseCountButtonClicked =
      leaseCountButton && leaseCountButton.contains(e.target as Node);
    if (isLeaseCountButtonClicked) {
      return;
    } else {
      hidePopover();
    }
  };

  const documentElem = document.body;
  documentElem.addEventListener('click', clickHandler);
  return (): void => {
    documentElem.removeEventListener('click', clickHandler);
  };
};

export const getLeaseCountDescriptionMessage = (
  user: IUserModel,
  userIsOnMaxPlan: boolean,
  nextUpgradePlan: IPlanModel | undefined,
) => {
  const isMultiClientAdmin = CheckIsMultiClientAdmin(user);
  const plan = user.company.plan;

  if (userIsOnMaxPlan) {
    return (
      <div className="description">
        For unlimited leases and advanced features, consider upgrading to
        <span> LeaseQuery</span>.
      </div>
    );
  }

  if (isMultiClientAdmin) {
    return (
      <div className="description">
        Purchase now to add up to 10 leases per company. Up to 5 companies at{' '}
        <span>$500 per company, yearly.</span>
      </div>
    );
  }

  let description: unknown;
  if (plan === undefined || plan.displayName === 'Basic') {
    description = (
      <>
        <span> {getPlanSummary(nextUpgradePlan)}</span>, plus premium features.
      </>
    );
  } else {
    description = (
      <>
        <span> {getPlanSummary(nextUpgradePlan)}</span>.
      </>
    );
  }

  return (
    <div className="description">
      For more leases, upgrade to LeaseGuru Premium at
      {description}
    </div>
  );
};

const showSubscriptionModal = (
  dispatch: Dispatch<any>,
  nextUpgradePlan: IPlanModel | undefined,
  allPlans: IPlanModel[],
) => {
  dispatch(
    showModal({
      modal: {
        modalType: 'SUBSCRIPTIONS',
        modalProps: {
          open: true,
          initialSubscriptionModalPage: SubscriptionModalPages.payment,
          initialPlanFeatures: getFeaturesByPlan(
            nextUpgradePlan ? nextUpgradePlan.name : '',
            allPlans,
          ),
          initialSelectedPlan: getPlanByName(
            nextUpgradePlan ? nextUpgradePlan.name : '',
            allPlans,
          ),
        },
      },
    }),
  );
};
export const upgradeHandler = (
  userIsOnMaxPlan: boolean,
  enterpriseUrl: string,
  dispatch: Dispatch<any>,
  nextUpgradePlan: IPlanModel | undefined,
  allPlans: IPlanModel[],
) => {
  if (userIsOnMaxPlan) {
    window.open(enterpriseUrl, '_blank');
  } else {
    showSubscriptionModal(dispatch, nextUpgradePlan, allPlans);
  }
};
