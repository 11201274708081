import { format } from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import get from 'lodash/get';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ExpandSection, Input } from '../../../common';
import { InlineButton } from '../../../common/buttons';
import RadioGroup from '../../../common/radio-group/RadioGroup';
import { MAX_ADDITIONAL_PAYMENTS } from '../../../common/utilities/_constants';
import { DEFAULT_DATE_FORMAT } from '../../../common/utilities/_dates';
import {
  fieldHasError,
  joinPath,
} from '../../../common/utilities/FormikHelpers';
import { IStore } from '../../../store';
import { LeaseForms, saveForm } from '../actions';
import PaymentForm from '../components/PaymentForm';
import { getEndDate, IBaseFormState } from './BaseRentForm';
import { AdditionalPaymentsFormSchema } from './validationSchemas';

import TagManager from 'react-gtm-module';
import { getGTMDataLayerEventByAmendType } from '../../../common/utilities/_helpers';
import './AdditionalPaymentsForm.scss';

const AdditionalPaymentsForm = forwardRef((props, ref) => {
  const amendType = useSelector((state: IStore) => {
    return state.steppedModal!.modalProps!.amendType;
  });
  const storedData = useSelector((state: IStore) => {
    return state.lease.form![LeaseForms.ADDL];
  });
  const { leaseBeginDt, leaseEndDt } = useSelector((state: IStore) => {
    return state.lease.form![LeaseForms.DATE];
  }) || { leaseBeginDt: Date(), leaseEndDt: Date() };
  const initialData = storedData || {
    additionalPayments: false,
    otherPayments: [],
  };
  const renewalOptions = useSelector((state: IStore) => {
    return state.lease.form![LeaseForms.OPTIONS]?.renewalOptions || [];
  });
  const dispatch = useDispatch();
  const formikRef = useRef<Formik<IAdditionalFormState>>(null);
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState<IAdditionalFormState>(initialData);

  useImperativeHandle(ref, () => ({
    saveForm() {
      return handleSaveForm(formikRef.current!.state.values);
    },
    validate() {
      return formikRef.current!.submitForm();
    },
    isValid() {
      return isValid;
    },
  }));

  const onSubmit = async (values: IAdditionalFormState) => {
    setIsValid(true);
    setFormData({ ...initialData, ...values });

    TagManager.dataLayer({
      dataLayer: {
        event: getGTMDataLayerEventByAmendType(amendType),
        step_completion_step: '5',
        step_completion_name: 'Additional Payments',
      },
    });

    dispatch(saveForm(LeaseForms.ADDL, { ...initialData, ...values }));
  };

  const handleSaveForm = async (values: IAdditionalFormState) => {
    dispatch(saveForm(LeaseForms.ADDL, { ...initialData, ...values }));
  };

  return (
    <div className="form-container">
      <Formik
        ref={formikRef}
        initialValues={formData}
        validationSchema={AdditionalPaymentsFormSchema(
          leaseBeginDt,
          getEndDate(leaseEndDt, renewalOptions),
        )}
        onSubmit={onSubmit}
        validateOnChange={false}
      >
        {(formProps) => {
          return (
            <Form noValidate={true}>
              <div className="form-body">
                <div className="renewal-options-row">
                  <label className="renewal-options-label">
                    Do you have any other payments (such as CAM, Tax, or
                    Insurance)?
                  </label>
                  <br />
                  <OtherPayment
                    formProps={formProps}
                    path={''}
                    leaseBeginDt={leaseBeginDt}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
});

export interface IAdditionalFormState {
  otherPayments: IBaseFormState[];
  additionalPayments?: boolean;
}

export default AdditionalPaymentsForm;

const OtherPayment = ({
  formProps,
  path,
  leaseBeginDt,
}: {
  formProps: FormikProps<IAdditionalFormState>;
  path: string;
  leaseBeginDt: Date;
}) => {
  const initialDetail = {
    paymentName: '',
    paymentOptionType: undefined,
    isFixed: false,
    paymentDetail: [
      {
        paymentFrequency: undefined,
        paymentAmt: undefined,
        firstPaymentDt: format(new Date(leaseBeginDt), DEFAULT_DATE_FORMAT),
        lastPaymentDt: undefined,
        isIncrementPct: undefined,
        incrementDecrementAmt: 0,
      },
    ],
  };
  const handleAddPayment = () => {
    const newDetail = { ...initialDetail };
    formProps.setFieldValue(joinPath('otherPayments', path), [
      ...get(formProps.values, joinPath('otherPayments', path)),
      newDetail,
    ]);
  };

  const handleRemovePayment = (index: number) => {
    const newOtherPayments = formProps.values.otherPayments;
    newOtherPayments.splice(index, 1);
    formProps.setFieldValue(joinPath('otherPayments', path), [
      ...newOtherPayments,
    ]);
  };

  return (
    <div className="addit-pay-form-wrapper">
      {get(formProps.values, joinPath('otherPayments', path)).map(
        (payment: any, index: number) => {
          return (
            <div key={index}>
              <div className="form-content-outline">
                <ExpandSection
                  label={
                    get(
                      formProps.values,
                      joinPath(`otherPayments[${index}].paymentName`, path),
                    ) === ''
                      ? `Schedule ${index + 1}`
                      : get(
                          formProps.values,
                          joinPath(`otherPayments[${index}].paymentName`, path),
                        )
                  }
                  labelContent={
                    get(
                      formProps.values,
                      joinPath(`otherPayments[${index}].isFixed`, path),
                    )
                      ? '(Fixed)'
                      : '(Variable)'
                  }
                  labelContentCenter={
                    get(
                      formProps.values,
                      joinPath(`otherPayments[${index}].paymentDetail`, path),
                    ).length + ' Custom Payments'
                  }
                  hideContentBorder
                  open
                  handleDelete={() => handleRemovePayment(index)}
                >
                  <Input
                    name={joinPath(`otherPayments[${index}].paymentName`, path)}
                    label="Payment Name"
                    required
                    value={get(
                      formProps.values,
                      joinPath(`otherPayments[${index}].paymentName`, path),
                    )}
                    onChange={formProps.handleChange}
                    error={fieldHasError(
                      formProps,
                      joinPath(`otherPayments[${index}].paymentName`, path),
                    )}
                    errorMessage={get(
                      formProps.errors,
                      joinPath(`otherPayments[${index}].paymentName`, path),
                    )}
                    maxLength={40}
                  />
                  <RadioGroup
                    options={[
                      { key: 1, value: false, label: 'Variable' },
                      { key: 2, value: true, label: 'Fixed' },
                    ]}
                    groupLabel="This Payment is:"
                    onSelect={(
                      e: React.ChangeEvent<HTMLInputElement>,
                      selectedValue: boolean,
                    ) => {
                      formProps.setFieldValue(
                        joinPath(`otherPayments[${index}].isFixed`, path),
                        selectedValue,
                      );
                    }}
                    value={get(
                      formProps.values,
                      joinPath(`otherPayments[${index}].isFixed`, path),
                    )}
                    name={`otherPayments-${index}-isFixed`}
                  />
                  <br />
                  <PaymentForm
                    formProps={formProps}
                    path={`otherPayments[${index}]`}
                    index={index}
                    leaseBeginDt={leaseBeginDt}
                    isAddlPayments
                  />
                </ExpandSection>
              </div>
            </div>
          );
        },
      )}
      {get(formProps.values, joinPath('otherPayments', path)).length <
      MAX_ADDITIONAL_PAYMENTS ? (
        <InlineButton
          id={`addPaymentBtn${path}`}
          buttonText="Add Additional Payment"
          onClick={handleAddPayment}
          iconType="blue-plus"
          type="button"
        />
      ) : null}
    </div>
  );
};
