import React, { FunctionComponent } from 'react';
import { Button } from '../../common/buttons';
import Select, { ISelectOptions } from '../../common/select/Select';
import { monthOptions } from '../../common/utilities/_constants';
import { IDisclosuresFilters, JurisdictionValue } from '../../interfaces';
import '../leaseDetails/components/JournalEntriesTable/FiltersContainer.scss';

interface IProps {
  value: IDisclosuresFilters;
  loading: boolean;
  onChange: (value: IDisclosuresFilters) => void;
  onExecute: () => void;
  valid: boolean;
  beginYear: number;
  endYear: number;
  transitionMonth: number;
  transitionIsInFuture: boolean;
}

const DisclosuresFilters: FunctionComponent<IProps> = ({
  value,
  loading = false,
  onChange,
  onExecute,
  beginYear,
  endYear,
  valid,
  transitionMonth,
  transitionIsInFuture,
}) => {
  const getYearOptions = () => {
    const yearOptions = [];

    yearOptions.push({
      key: beginYear,
      value: beginYear,
      label: beginYear.toString(),
    });

    if (endYear !== beginYear) {
      for (let index = beginYear + 1; index <= endYear; index++) {
        yearOptions.push({
          key: index,
          value: index,
          label: index.toString(),
        });
      }
    }

    return yearOptions;
  };

  const isDateBeforeTransition = () => {
    if (value.startMonth === undefined) {
      return false;
    }
    if (value.year === beginYear && value.startMonth < transitionMonth) {
      return true;
    }
  };

  // validates the date message if running disclosures for future periods
  const isDateOutOfRange = () => {
    let dateOutOfRange = false;
    if (value.startMonth && value.year && value.endMonth) {
      const currentDate = new Date();
      currentDate.setDate(1);
      const selectedDate = new Date(value.year, value.startMonth - 1);
      const selectedEndDate = new Date(value.year, value.endMonth - 1);
      const isCurrentYear =
        currentDate.getFullYear() === value.year &&
        selectedEndDate < selectedDate;

      dateOutOfRange =
        selectedDate > currentDate ||
        selectedEndDate > currentDate ||
        isCurrentYear;

      isDateBeforeTransition();
    }
    return dateOutOfRange;
  };

  const onFilterChange = (
    name: string,
    option: ISelectOptions<number | JurisdictionValue | boolean>,
  ) => {
    onChange({ ...value, [name]: option.value });
  };

  return (
    <div className="disclosure-filters-wrapper">
      <div className="filters-container">
        <div className="filters-months-container">
          <Select
            name="startMonth"
            id="disclosure-filter-startMonth"
            label="START MONTH"
            placeholder="Select Month"
            onClick={onFilterChange}
            value={value.startMonth}
            options={monthOptions}
            disabled={transitionIsInFuture}
          />
          <Select
            name="year"
            id="disclosure-filter-year"
            label="START YEAR"
            placeholder="Select Year"
            onClick={onFilterChange}
            value={value.year}
            options={getYearOptions()}
            disabled={transitionIsInFuture}
          />
          <Select
            name="endMonth"
            id="disclosure-filter-endMonth"
            label="END MONTH"
            placeholder="Select Month"
            onClick={onFilterChange}
            value={value.endMonth}
            options={monthOptions}
            disabled={transitionIsInFuture}
          />
        </div>
        <div className="filters-apply-button">
          <Button
            buttonType="positive"
            buttonText="Run"
            disabled={
              loading ||
              !valid ||
              isDateOutOfRange() ||
              isDateBeforeTransition() ||
              transitionIsInFuture
            }
            processing={loading}
            onClick={onExecute}
          />
        </div>
      </div>
      {value.startMonth && value.year && isDateOutOfRange() && (
        <p className="outOfRangeMsg">
          Sorry! We can't run disclosures beyond the current month.
        </p>
      )}
      {isDateBeforeTransition() && (
        <p className="outOfRangeMsg">
          This report can only be run after your transition date.
        </p>
      )}
    </div>
  );
};

export default DisclosuresFilters;
