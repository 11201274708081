import { IIntegrationSettingsMapping } from './IntegrationsSettingsModal';

export enum IntegrationActions {
  STORE_INTEGRATION_MAPPINGS = 'STORE_INTEGRATION_MAPPINGS',
  CLEAR_INTEGRATION = 'CLEAR_INTEGRATION',
}

export const storeIntegrationMappings = (
  mapping: IIntegrationSettingsMapping,
) => ({
  type: IntegrationActions.STORE_INTEGRATION_MAPPINGS,
  payload: { mapping },
});

export const clearIntegrations = () => ({
  type: IntegrationActions.CLEAR_INTEGRATION,
});
