import React, { FunctionComponent, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import {
  exportJournalEntry,
  getAccountList,
  getJournalEntries,
  getQBAccountList,
} from '../../../../api';
import { showModal } from '../../../../common/modal/actions';
import { downloadFile } from '../../../../common/utilities/_helpers';
import {
  ClientIntegration,
  IJournalEntryExportModel,
  IJournalEntryFilters,
  IJournalEntryModel,
  ILeaseHeaderModel,
  ISageJournalEntryLines,
} from '../../../../interfaces';
import { IStore } from '../../../../store';
import {
  IAccountListItem,
  IQuickBooksSettingsMapping,
} from '../../../quickbooks/QuickBooksSettingsModal';
import './JournalEntriesContainer.scss';
import { JournalEntriesTable } from './JournalEntriesTable';
import { LeaseJournalFilters } from './LeaseJournalFilters';

interface IProps {
  leaseId: number;
  isCapital: boolean;
}

export type JEExportOptions =
  | 'xero'
  | 'sage'
  | 'quickbooks'
  | 'download'
  | 'totalOrg';

const JournalEntriesContainer: FunctionComponent<IProps> = ({
  leaseId,
  isCapital,
}) => {
  const dispatch = useDispatch();
  const initialFilters: IJournalEntryFilters = {
    month: undefined,
    year: undefined,
    jurisdiction: undefined,
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [firstLoad, setfirstLoad] = useState<boolean>(true);
  const [valid, setValid] = useState<boolean>(false);
  const [data, setData] = useState<IJournalEntryModel[]>([]);
  const [filters, setFilters] = useState<IJournalEntryFilters>(initialFilters);
  const selectedLease:
    | ILeaseHeaderModel
    | undefined = useSelector((state: IStore) =>
    state.leaseList.find((lease) => lease.leaseId === leaseId),
  );
  const quickBooksSettingsMapping: IQuickBooksSettingsMapping = useSelector(
    (state: IStore) => state.quickBooksSettings,
  );
  const userCompanyData = useSelector((state: IStore) => {
    return state.user.company!;
  });

  const leaseDetails = useSelector((state: IStore) => {
    return state.leaseDetails.selectedLease.header;
  });

  const fetchEntries = async () => {
    try {
      setLoading(true);
      TagManager.dataLayer({
        dataLayer: {
          event: 'journal_entry_interaction',
          journal_entry_action: 'Run Individual',
        },
      });
      const entries: IJournalEntryModel[] = await getJournalEntries(
        leaseId,
        filters,
      );
      setData(entries);
    } catch (error) {
      alert(`There was an issue loading your journal entry selection`);
    }
    setLoading(false);
    if (firstLoad) {
      setfirstLoad(false);
    }
  };

  const exportJE = async (exportTo: JEExportOptions) => {
    if (data.length) {
      if (selectedLease) {
        const { assetName } = selectedLease;
        const { year, month, jurisdiction } = filters;
        if (year && month && jurisdiction) {
          const journalEntryToExport: IJournalEntryExportModel = {
            leaseId,
            assetName,
            year,
            month,
            isCapital,
            jurisdiction,
            journalEntries: data,
          };
          try {
            if (exportTo === 'download') {
              const response = await exportJournalEntry(journalEntryToExport);
              const { blob, cdHeader } = response;
              downloadFile(blob, cdHeader);
            } else if (exportTo === 'sage') {
              // TODO: Fix journalentry to export unmapped accounts
              const sageJournalEntryLines: ISageJournalEntryLines[] = data.map(
                (value: IJournalEntryModel) => ({
                  acctType: value.acctType,
                  transactionAmount:
                    value.debit === 0 ? -1 * value.credit : value.debit,
                  locationId: '100',
                  departmentId: '',
                  allocationId: '',
                  memo: '',
                }),
              );

              const sageJournalEntryToExport = {
                journalSymbol: 'GJ',
                description: leaseDetails.assetName,
                postingDate: leaseDetails.leaseBeginDt,
                referenceNumber: leaseDetails.leaseId.toString(),
                journalEntryLines: sageJournalEntryLines,
              };

              // if the user has previously mapped settings
              if (Object.values(quickBooksSettingsMapping).length !== 0) {
                // get account list from QB
                const accountListResponse: IAccountListItem[] = await getAccountList(
                  ClientIntegration.SAGE,
                );

                // for every mapped account
                // checks if mapped value exists
                // then checks if the value is still a valid account from QB
                // if all is true, return the key
                const mappedAccounts = Object.entries(
                  quickBooksSettingsMapping,
                ).map(
                  ([key, value]) =>
                    value &&
                    accountListResponse.findIndex(
                      (account) => account.accountNbr === value,
                    ) !== -1 &&
                    key,
                );

                const exportAccountNames: string[] = [];

                // for every journalEntry that doesn't have a mappedAccount, add to exportAccountNames
                journalEntryToExport.journalEntries.forEach(
                  (je: IJournalEntryModel) => {
                    if (mappedAccounts.indexOf(je.acctType) === -1) {
                      exportAccountNames.push(je.acctType);
                    }
                  },
                );

                // if exportAccountNames is empty, all accounts have been mapped
                if (exportAccountNames.length === 0) {
                  dispatch(
                    showModal({
                      modal: {
                        modalType: 'INTEGRATIONS_EXPORT_JE',
                        modalProps: {
                          open: true,
                          otherProps: {
                            journalEntry: sageJournalEntryToExport,
                          },
                        },
                      },
                    }),
                  );
                } else {
                  // have the user map missing account mappings
                  dispatch(
                    showModal({
                      modal: {
                        modalType: 'INTEGRATIONS_SETTINGS',
                        modalProps: {
                          open: true,
                          otherProps: {
                            exportJE: exportAccountNames,
                            journalEntry: sageJournalEntryToExport,
                            clientIntegration: ClientIntegration.SAGE,
                          },
                        },
                      },
                    }),
                  );
                }
              } else {
                // if they have no mappings, prompt for all accounts
                dispatch(
                  showModal({
                    modal: {
                      modalType: 'INTEGRATIONS_SETTINGS',
                      modalProps: {
                        open: true,
                        title: 'QuickBooks Settings',
                        otherProps: {
                          exportJE: journalEntryToExport.journalEntries.map(
                            (je) => je.acctType,
                          ),
                          journalEntry: sageJournalEntryToExport,
                          clientIntegration: ClientIntegration.SAGE,
                        },
                      },
                    },
                  }),
                );
              }
            } else if (exportTo === 'quickbooks') {
              // if the user has previously mapped settings
              if (Object.keys(quickBooksSettingsMapping).length !== 0) {
                // get account list from QB
                const accountListResponse: IAccountListItem[] = await getQBAccountList(
                  userCompanyData.clientId!,
                );

                // for every mapped account
                // checks if mapped value exists
                // then checks if the value is still a valid account from QB
                // if all is true, return the key
                const mappedAccounts = Object.entries(
                  quickBooksSettingsMapping,
                ).map(
                  ([key, value]) =>
                    value &&
                    accountListResponse.findIndex(
                      (account) => account.id === value,
                    ) !== -1 &&
                    key,
                );

                const exportAccountNames: string[] = [];

                // for every journalEntry that doesn't have a mappedAccount, add to exportAccountNames
                journalEntryToExport.journalEntries.forEach(
                  (je: IJournalEntryModel) => {
                    if (mappedAccounts.indexOf(je.acctType) === -1) {
                      exportAccountNames.push(je.acctType);
                    }
                  },
                );

                // if exportAccountNames is empty, all accounts have been mapped
                if (exportAccountNames.length === 0) {
                  dispatch(
                    showModal({
                      modal: {
                        modalType: 'QB_EXPORT_JE',
                        modalProps: {
                          open: true,
                          otherProps: { journalEntry: journalEntryToExport },
                        },
                      },
                    }),
                  );
                } else {
                  // have the user map missing account mappings
                  dispatch(
                    showModal({
                      modal: {
                        modalType: 'QB_SETTINGS',
                        modalProps: {
                          open: true,
                          otherProps: {
                            exportJE: exportAccountNames,
                            journalEntry: journalEntryToExport,
                          },
                        },
                      },
                    }),
                  );
                }
              } else {
                // if they have no mappings, prompt for all accounts
                dispatch(
                  showModal({
                    modal: {
                      modalType: 'QB_SETTINGS',
                      modalProps: {
                        open: true,
                        title: 'QuickBooks Settings',
                        otherProps: {
                          exportJE: journalEntryToExport.journalEntries.map(
                            (je) => je.acctType,
                          ),
                          journalEntry: journalEntryToExport,
                        },
                      },
                    },
                  }),
                );
              }
            }
          } catch (error) {
            // TODO: better errors
            alert('There was an issue exporting your Journal Entry.');
          }
        }
      }
    }
  };

  const isFormValid = (value: IJournalEntryFilters) => {
    return !Object.values(value).some((val) => val === '' || val === undefined);
  };

  const onFiltersUpdate = (value: IJournalEntryFilters) => {
    setFilters(value);
    setValid(isFormValid(value));
  };

  return (
    <div className="je-container">
      <LeaseJournalFilters
        value={filters}
        onChange={onFiltersUpdate}
        onExecute={fetchEntries}
        loading={loading}
        valid={valid}
        leaseBeginDt={selectedLease ? selectedLease.leaseBeginDt : ''}
        leaseEndDt={selectedLease ? selectedLease.leaseEndDt : ''}
      />
      {data.length !== 0 ? (
        <JournalEntriesTable
          key={data.length}
          data={data}
          loading={loading}
          exportJE={exportJE}
          // TODO: Temporary solution until export je works for more than QB
          ERPConnected={
            userCompanyData.clientIntegrations &&
            userCompanyData.clientIntegrations.length !== 0 &&
            userCompanyData.clientIntegrations[0] ===
              ClientIntegration.QUICKBOOKS
          }
        />
      ) : firstLoad ? null : (
        <div>No data for this selection</div>
      )}
    </div>
  );
};

export default JournalEntriesContainer;
