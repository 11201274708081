import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import lock from '../../assets/bluelock.svg';

import { editCompany } from '../../api';
import { InlineButton } from '../../common/buttons';
import { hideModal, showModal } from '../../common/modal/actions';
import { ClientIntegration, ICompanyModel } from '../../interfaces';
import { updateUserCompanySettings } from '../auth/actions';

interface ISageERPSectionProps {
  userCompanyData: ICompanyModel;
  userHasAccess: boolean;
}

const SageERPSection: FunctionComponent<ISageERPSectionProps> = ({
  userCompanyData,
  userHasAccess,
}) => {
  const dispatch = useDispatch();

  const handleSageManageAccounts = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'INTEGRATIONS_SETTINGS',
          modalProps: {
            open: true,
            hideModal: () => dispatch(hideModal()),
            otherProps: {
              clientIntegration: ClientIntegration.SAGE,
            },
          },
        },
      }),
    );
  };

  const handleSageConnect = async () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'SAGE_LOGIN',
          modalProps: {
            open: true,
            hideModal: () => dispatch(hideModal()),
          },
        },
      }),
    );
  };

  const handleSageDisconnect = async () => {
    await editCompany({
      ...userCompanyData,
      clientIntegrations: userCompanyData.clientIntegrations?.filter(
        (ci) => ci !== ClientIntegration.SAGE,
      ),
    });

    dispatch(
      updateUserCompanySettings({
        ...userCompanyData,
        clientIntegrations: [],
      }),
    );
    dispatch(hideModal());
  };

  return (
    <div className="erp-section">
      <img src={require(`../../assets/sage-logo.svg`)} alt="sage-img" />
      <div className="erp-buttons">
        {!!userCompanyData.clientIntegrations &&
        userCompanyData.clientIntegrations?.findIndex(
          (integration) => integration === ClientIntegration.SAGE,
        ) !== -1 ? (
          <>
            <InlineButton
              buttonText="manage accounts"
              onClick={handleSageManageAccounts}
              type="button"
            />
            <InlineButton
              buttonText="disconnect from sage"
              onClick={handleSageDisconnect}
              type="button"
            />
          </>
        ) : (
          <>
            <InlineButton
              buttonText="connect"
              onClick={() => {
                if (userHasAccess) {
                  handleSageConnect();
                }
              }}
              type="button"
              disabled={userCompanyData.clientIntegrations?.some(
                (int) => int !== ClientIntegration.SAGE,
              )}
            />

            {!userHasAccess && (
              <img src={lock} alt="header-lock" className="header-lock" />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SageERPSection;
