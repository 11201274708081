import React, { FunctionComponent } from 'react';
import GuruLogoSpinner from '../../common/loaders/GuruLogoSpinner';
import Modal from '../../common/modal/Modal';

import './RegeneratingFinancialsModal.scss';

interface IProps {
  open: boolean;
  hideModal: () => void;
}

const RegeneratingFinancialsModal: FunctionComponent<IProps> = ({
  open,
  hideModal,
}) => (
  <Modal modalSize="small" open={open} hideModal={hideModal}>
    <div className="waiting-modal-content">
      <GuruLogoSpinner inProgress />
      <div style={{ marginTop: '20px' }}>
        Regenerating Financials...
        <p>Please wait, this may take some time.</p>
      </div>
    </div>
  </Modal>
);
export default RegeneratingFinancialsModal;
