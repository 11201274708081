import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';

import close from '../../assets/close.svg';
import { hideModal, showModal } from '../../common/modal/actions';
import { useFeatureFlags } from '../../featureQuery';
import {
  findNextAvailableUpgrade,
  getTierFeatureList,
  sortPlansByMostExpensive,
} from '../../features/plans/helpers';
import { SubscriptionModalPages } from '../../features/subscriptions/SubscriptionModal';
import {
  FeatureFlagKeys,
  IPlanModel,
  IUserModel,
  PlanTier,
} from '../../interfaces';
import { IStore } from '../../store';
import './UpgradeBanner.scss';

const UpgradeBanner: React.FC = () => {
  const loggedInUser: IUserModel = useSelector((state: IStore) => state.user);
  const plans: IPlanModel[] = useSelector((store: IStore) => {
    return store.plans;
  });

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem('showUpgradeBanner') === 'true') {
      setOpen(true);
    } else if (sessionStorage.getItem('showUpgradeBanner') !== 'false') {
      const planTier = loggedInUser?.company?.plan?.tier;
      if (planTier === PlanTier.BASIC || planTier === PlanTier.TRIAL) {
        sessionStorage.setItem('showUpgradeBanner', 'true');
        setOpen(true);
      }
    }
    return () => sessionStorage.removeItem('showUpgradeBanner');
  }, [loggedInUser]);

  const sortedPlans = sortPlansByMostExpensive(plans);
  const premiumTierFeatures = getTierFeatureList(PlanTier.PREMIUM, sortedPlans);

  const { getSingleFlag } = useFeatureFlags();
  const guruPlanPricingFlag = getSingleFlag(FeatureFlagKeys.PlansAndPricing);

  const handleClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'upgrade_subscription_cta',
        cta_location: 'Notification Bar',
        cta_creative:
          'Add More Leases + Access Premium Features | Upgrade to Premium',
      },
    });
    const modalPropsConfig = {
      initialSubscriptionModalPage: guruPlanPricingFlag
        ? SubscriptionModalPages.payment
        : SubscriptionModalPages.calculator,
      initialSelectedPlan: guruPlanPricingFlag
        ? findNextAvailableUpgrade(plans, loggedInUser.company.plan!)
        : undefined,
      initialPlanFeatures: guruPlanPricingFlag
        ? undefined
        : premiumTierFeatures,
    };

    dispatch(
      showModal({
        modal: {
          modalType: 'SUBSCRIPTIONS',
          modalProps: {
            open: true,
            hideModal: () => {
              dispatch(hideModal());
            },
            ...modalPropsConfig,
          },
        },
      }),
    );
  };

  const onCloseBanner = () => {
    sessionStorage.setItem('showUpgradeBanner', 'false');
    setOpen(false);
  };

  return open ? (
    <div
      className={`upgrade-banner ${process.env.REACT_APP_ENABLE_RSM ===
        'true' && 'rsm-banner'}`}
    >
      Add More Leases + Access Premium Features
      <span className="seperate-line">|</span>{' '}
      <button
        className="action link-text button-link upgrade-link"
        type="button"
        onClick={handleClick}
      >
        Upgrade to Premium
      </button>
      <button className="banner-close-icon" onClick={onCloseBanner}>
        <img src={close} alt="question mark" className="tooltip-image" />
      </button>
    </div>
  ) : null;
};

export default UpgradeBanner;
