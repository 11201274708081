import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from '../../common/modal/actions';
import Modal from '../../common/modal/Modal';
import { useResourceContext } from '../../common/resource-context';
import { SubscriptionModalPages } from '../subscriptions/SubscriptionModal';

import Close from '../../assets/exit.svg';
import './BuyNowPopUp.scss';

export interface IBuyNowPlanInfo {
  numOfLeases: number;
  cost: string;
  planId: string;
}

interface IBuyNowPopUpProps {
  setIsPopUpOpen: (toggle: boolean) => void;
  leaseCountInfo: IBuyNowPlanInfo;
}

export default function BuyNowPopUp({
  setIsPopUpOpen,
  leaseCountInfo,
}: IBuyNowPopUpProps) {
  const resources = useResourceContext();
  const dispatch = useDispatch();

  const deleteSelectedPlan = () => {
    setTimeout(() => {
      sessionStorage.removeItem('leaseCount');
    }, 1000);
  };

  const exitPopUp = () => {
    setIsPopUpOpen(false);
    deleteSelectedPlan();
  };

  const goToPayment = () => {
    setIsPopUpOpen(false);
    dispatch(
      showModal({
        modal: {
          modalType: 'SUBSCRIPTIONS',
          modalProps: {
            open: true,
            hideModal: () => {
              dispatch(hideModal());
            },
            buyNowSelectedPlan: leaseCountInfo.planId,
            initialSubscriptionModalPage: SubscriptionModalPages.payment,
          },
        },
      }),
    );
    deleteSelectedPlan();
  };

  return (
    <Modal open={true} modalSize="small" hideModal={hideModal}>
      <div className="popUpWrapper">
        <div className="topRow">
          <div className="placeHolder" />
          <div className="close">
            <img src={Close} alt={'Close'} onClick={exitPopUp} />
          </div>
        </div>
        <div className="textWrapper">
          <div className="welcomeText">
            {resources.text('BuyNowPopUp_WelcomeText', 'Welcome to LeaseGuru!')}
          </div>
          <div className="subText">
            Looks like you need to account for {leaseCountInfo.numOfLeases}{' '}
            leases.
          </div>
        </div>
        <div className="cardWrapper">
          <div className="freeCard" onClick={exitPopUp}>
            <div className="heading">
              Start exploring with our <b>Basic Plan</b> first.
            </div>
            <div className="freeText">free</div>
            <div className="parenthesisText">(up to 3 leases)</div>
          </div>
          <div className="paidCard" onClick={goToPayment}>
            <div className="heading">
              Purchase our <b>Premium Plan</b> to account for all of your
              leases.
            </div>
            <div className="paidCost">{leaseCountInfo.cost}</div>
            <div className="parenthesisText">(per month, billed annually)</div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
