import {
  CHANGE_MODAL_PROP,
  HIDE_MODAL,
  HIDE_STEPPED_MODAL,
  IModal,
  ModalActionTypes,
  SHOW_MODAL,
  SHOW_STEPPED_MODAL,
} from './types';

const initialState: IModal = {};

export const modalReducer = (
  state = initialState,
  action: ModalActionTypes,
) => {
  switch (action.type) {
    case SHOW_MODAL: {
      return {
        modalType: action.modal.modalType,
        modalProps: action.modal.modalProps,
      };
    }
    case HIDE_MODAL: {
      return initialState;
    }
    default:
      return state;
  }
};

export const steppedModalReducer = (
  state = initialState,
  action: ModalActionTypes,
) => {
  switch (action.type) {
    case SHOW_STEPPED_MODAL: {
      return {
        modalType: action.modal.modalType,
        modalProps: action.modal.modalProps,
      };
    }
    case HIDE_STEPPED_MODAL: {
      return initialState;
    }
    case CHANGE_MODAL_PROP: {
      if (state.modalProps) {
        return {
          modalType: state.modalType,
          modalProps: {
            ...state.modalProps,
            [action.property]: action.value,
          },
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};
