import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import verticalDots from '../../../assets/vertical-ellipsis.svg';
import { useOutsideClick } from '../../../common/hooks';
import { showModal } from '../../../common/modal/actions';
import { ICPACompanyDetail } from '../../../interfaces';

import './DeleteCompany.scss';

export interface DeleteCompanyProps {
  company: ICPACompanyDetail;
  onDelete: (companyId: number) => void;
}

const DeleteCompany = ({ company, onDelete }: DeleteCompanyProps) => {
  const dispatch = useDispatch();

  const [showToolTip, setShowToolTip] = useState(false);

  const deleteDialogRef = useRef(null);

  useOutsideClick(deleteDialogRef, () => setShowToolTip(false));

  const stopProp = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    stopProp(e);
    setShowToolTip(true);
  };

  const displayDeleteConfirmation = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'CONFIRMATION',
          modalProps: {
            title: 'Are you sure?',
            areYouSureText: false,
            open: true,
            hideModal: () =>
              dispatch({
                type: 'HIDE_MODAL',
              }),
            confirmAction: () => {
              onDelete(company.id);
              dispatch({
                type: 'HIDE_MODAL',
              });
            },
            confirmText: (
              <>
                Continuing with this action will permanently delete the company{' '}
                <b>{company.name}.</b>
              </>
            ),
            buttonText: 'Delete',
          },
        },
      }),
    );
  };

  return (
    <div ref={deleteDialogRef}>
      <img
        src={verticalDots}
        alt="dots"
        className="vertical-dots"
        onClick={handleDeleteClick}
      />
      <div
        className={`delete-dialog-wrapper ${showToolTip ? 'open' : 'closed'}`}
        onClick={stopProp}
      >
        <div className="delete-menu-item" onClick={displayDeleteConfirmation}>
          Delete Company
        </div>
      </div>
    </div>
  );
};

export default DeleteCompany;
