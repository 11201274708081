import { Form, FormikContext } from 'formik';
import React, { FunctionComponent, ReactNode } from 'react';

import { Input, Select } from '../../../common/';
import FormGrid from '../../../common/form-grid/FormGrid';
import {
  monthOptions,
  yearOptions1,
} from '../../../common/utilities/_constants';
import { formatDropOptions } from '../../../common/utilities/_helpers';
import { fieldHasError } from '../../../common/utilities/FormikHelpers';
import { IGeneralLedgerModel } from '../../../interfaces';
import { AuthCard, AuthCardSection } from '../../auth/AuthCard';
import { ITryItNowState } from '../CreateAccount';

export interface IDateInfoFormState {
  generalLedger?: string;
  otherSoftware: string;
  transitionMonth: number | undefined;
  transitionYear: number | undefined;
}

interface IDateInfoFormProps {
  formProps: FormikContext<IDateInfoFormState>;
  formData: ITryItNowState;
  isCreateClientOnly?: boolean;
  generalLedgerList: IGeneralLedgerModel[] | undefined;
}

const DateInfoForm: FunctionComponent<IDateInfoFormProps> = ({
  formProps,
  formData,
  isCreateClientOnly,
  generalLedgerList,
}) => {
  const generalLedgerItemList = formatDropOptions(
    generalLedgerList ?? [],
    'id',
    'id',
    'application',
  );

  const renderStepThreeForm = (): ReactNode => {
    if (isCreateClientOnly) {
      return (
        <>
          <label className="try-question-label">
            When is the company planning on transitioning to the new lease
            accounting rules?
          </label>
          <FormGrid colWidth={2}>
            <Select
              name="transitionMonth"
              placeholder="Select Month"
              error={fieldHasError(formProps, 'transitionMonth')}
              errorMessage={formProps.errors.transitionMonth}
              value={formProps.values.transitionMonth}
              onClick={(_: any, option: any) => {
                formProps.setValues({
                  ...formProps.values,
                  transitionMonth: option.value,
                });
              }}
              options={monthOptions}
            />
            <Select
              name="transitionYear"
              placeholder="Select Year"
              error={fieldHasError(formProps, 'transitionYear')}
              errorMessage={formProps.errors.transitionYear}
              value={formProps.values.transitionYear}
              onClick={(_: any, option: any) => {
                formProps.setValues({
                  ...formProps.values,
                  transitionYear: option.value,
                });
              }}
              options={yearOptions1}
              disabled={formData.jurisdiction === 'IFRS'}
            />
          </FormGrid>
        </>
      );
    } else {
      return (
        <>
          <label className="try-question-label">
            What General Ledger software does your company use?
          </label>
          <Select
            name="generalLedger"
            placeholder="Select General Ledger Software"
            error={fieldHasError(formProps, 'generalLedger')}
            errorMessage="Please select general ledger software"
            value={formProps.values.generalLedger}
            onClick={(_: any, option: any) => {
              formProps.setValues({
                ...formProps.values,
                generalLedger: option.label,
              });
            }}
            options={generalLedgerItemList}
          />
          {formProps.values.generalLedger === 'Other' && (
            <Input
              name="otherSoftware"
              placeholder="Please Specify"
              error={fieldHasError(formProps, 'otherSoftware')}
              errorMessage="Please specify general ledger software"
              value={formProps.values.otherSoftware}
              onChange={formProps.handleChange}
              required={false}
            />
          )}
        </>
      );
    }
  };

  return (
    <Form noValidate={true}>
      <AuthCard>
        <AuthCardSection>{renderStepThreeForm()}</AuthCardSection>
      </AuthCard>
    </Form>
  );
};

export default DateInfoForm;
