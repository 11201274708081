import React, { FunctionComponent } from 'react';
import { AccentedHeader } from '../../../common/headers';
import { useResourceContext } from '../../../common/resource-context';
import { AuthCard, AuthCardSection } from '../../auth/AuthCard';

interface IExpiredWarningProps {
  warningText?: string;
}

const ExpiredWarning: FunctionComponent<IExpiredWarningProps> = ({
  warningText,
}) => {
  const resources = useResourceContext();
  return (
    <AuthCard>
      <AuthCardSection>
        <AccentedHeader color="blue" centered>
          {warningText}
        </AccentedHeader>
      </AuthCardSection>
      <div
        className="img-container"
        style={{ overflow: 'hidden', height: '177px' }}
      >
        <img src={resources.img('ExpiredWarning_Image')} alt="warning" />
      </div>
    </AuthCard>
  );
};

export default ExpiredWarning;
