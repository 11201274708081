import React, { Fragment, FunctionComponent } from 'react';

import './ButtonLink.scss';

const ButtonLink: FunctionComponent<ILinkProps> = ({
  text,
  id,
  onClick,
  styles,
}) => {
  return (
    <Fragment>
      <button
        className={`${styles ? styles : ''} button-link`}
        onClick={onClick}
        id={id}
      >
        {text}
      </button>
    </Fragment>
  );
};

interface ILinkProps {
  text: string;
  id?: string;
  onClick?: () => void;
  styles?: string;
}

export default ButtonLink;
