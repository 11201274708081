import React from 'react';

import {
  FilterTypes,
  FilterValueTypes,
  IFilterCategories,
} from '../../features/dashboard/Dashboard';

import './FilterButtons.scss';
import PillButton from './PillButton';

interface IFilterButtonProps {
  activeTypes: string[];
  onFilterSubmit: (
    filterValues: FilterValueTypes,
    filterName: keyof IFilterCategories,
    filterType: FilterTypes,
  ) => void;
  onFilterRemove: (
    filterName: keyof IFilterCategories,
    index: number,
    filterType: FilterTypes,
  ) => void;
}

const FilterButtons = ({
  activeTypes,
  onFilterRemove,
  onFilterSubmit,
}: IFilterButtonProps) => {
  const assetTypeList: string[] = [
    'Building',
    'Equipment',
    'Land',
    'Vehicle',
    'Other',
  ];

  const toggleSelected = (incomingAssetType: string) => {
    if (activeTypes.includes(incomingAssetType)) {
      onFilterRemove(
        'assetType',
        activeTypes.indexOf(incomingAssetType),
        'text',
      );
    } else {
      onFilterSubmit(incomingAssetType, 'assetType', 'text');
    }
  };

  return (
    <div className="button-wrapper">
      <div className="title">TYPE FILTER(S)</div>
      <div className="button-pills">
        {assetTypeList.map((asset: string) => {
          return (
            <PillButton
              assetType={asset}
              isSelected={activeTypes.includes(asset)}
              toggleSelected={() => toggleSelected(asset)}
              key={asset}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FilterButtons;
