import React, { FunctionComponent } from 'react';

import './StyledLink.scss';

interface IStyledLinkProps {
  href?: string;
}

const StyledLink: FunctionComponent<IStyledLinkProps> = ({
  href,
  children,
}) => (
  <a href={href} className="styled-link">
    {children}
  </a>
);

export default StyledLink;
