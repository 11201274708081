import { IPlanModel } from '../../interfaces';

export enum MultiCLientPlanActions {
  STORE_MULTI_CLIENT_PLANS = 'STORE_MULTI_CLIENT_PLANS',
  CLEAR_MULTI_CLIENT_PLANS = 'CLEAR_MULTI_CLIENT_PLANS',
  STORE_CURRENT_ADMIN_PLAN = 'STORE_CURRENT_ADMIN_PLAN',
}

export enum CurrentAdminPlanActions {
  STORE_CURRENT_ADMIN_PLAN = 'STORE_CURRENT_ADMIN_PLAN',
  CLEAR_CURRENT_ADMIN_PLAN = 'CLEAR_CURRENT_ADMIN_PLAN',
}
export const storeMultiClientPlans = (multiClientPlans: IPlanModel[]) => ({
  type: MultiCLientPlanActions.STORE_MULTI_CLIENT_PLANS,
  payload: { multiClientPlans },
});

export const clearMultiClientPlans = () => ({
  type: MultiCLientPlanActions.CLEAR_MULTI_CLIENT_PLANS,
});

export const storeCurrentAdminPlan = (
  currentAdminPlan: IPlanModel | undefined,
) => ({
  type: CurrentAdminPlanActions.STORE_CURRENT_ADMIN_PLAN,
  payload: { currentAdminPlan },
});

export const clearCurrentAdminPlan = () => ({
  type: CurrentAdminPlanActions.CLEAR_CURRENT_ADMIN_PLAN,
});
