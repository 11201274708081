import * as yup from 'yup';

import { monthOptions, yearOptions1 } from '../../common/utilities/_constants';
import { PasswordSchemaNoMessage } from '../../common/utilities/_schemas';

export enum StepNumber {
  LinkExpired,
  Welcome,
  CompanyInfo,
  DateInfo,
  Credentials,
  Verify,
  ChoosePlan,
  Default,
  LeaseCount,
  CompanyType,
}

export const schemas = {
  [StepNumber.Welcome]: yup.object().shape({
    emailAddress: yup
      .string()
      .required('Company Email Address is required')
      .email('Company Email Address must be a valid email.'),
  }),
  [StepNumber.CompanyInfo]: yup.object().shape({
    companyName: yup.string().required('Please enter your company name'),
    industryType: yup.number().required(),
    jurisdiction: yup.string().required(),
  }),
  [StepNumber.DateInfo]: yup.object().shape({
    transitionMonth: yup
      .number()
      .required('Month is required')
      .oneOf(monthOptions.map((val) => val.value)),
    transitionYear: yup
      .number()
      .required('Year is required')
      .oneOf(yearOptions1.map((val) => val.value)),
  }),
  [StepNumber.Credentials]: yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    password: PasswordSchemaNoMessage.required('Password is required'),
    passwordConfirmation: PasswordSchemaNoMessage.required(
      'Verify Password is required',
    ).oneOf([yup.ref('password')], 'Passwords must match'),
  }),
  [StepNumber.Verify]: yup.object().shape({
    verificationCode: yup
      .string()
      .required('A verification number is required'),
  }),
  [StepNumber.ChoosePlan]: yup.object().shape({
    selectedUserType: yup.number().required(),
  }),
  [StepNumber.LeaseCount]: yup.object().shape({
    leaseCountEstimation: yup
      .number()
      .integer()
      .min(0)
      .required(),
  }),
  [StepNumber.CompanyType]: yup.object().shape({
    companyType: yup.number().required(),
  }),
};
