import { AnyAction } from 'redux';
import { IUserModel } from '../../interfaces';
import { UserActions } from './actions';

const initialUserStoreState: IUserModel = {
  userId: undefined,
  userRole: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  company: {},
  authToken: undefined,
  isSuspended: undefined,
  createdDate: undefined,
};

export const userReducer = (
  state = initialUserStoreState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case UserActions.SAVE_USER:
      return { ...state, ...getUser(payload && payload.user) };
    case UserActions.CLEAR_USER:
      return { ...initialUserStoreState };
    case UserActions.UPDATE_USER_COMPANY_SETTINGS:
      return { ...state, company: { ...payload.userCompany } };
    default:
      return state;
  }
};

// helper function in case the user is null
const getUser = (userValue: IUserModel | null) => {
  if (userValue) {
    return { ...userValue };
  } else {
    return {};
  }
};
