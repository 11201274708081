import React, { useContext } from 'react';
import { IResourceContextObject } from './createResourceContextObject';

export const ResourceContext = React.createContext<IResourceContextObject>({
  text: () => '',
  formatText: () => '',
  img: () => '',
});

export const useResourceContext = () => useContext(ResourceContext);
