import React, { FunctionComponent, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';

import { getDefinitions } from '../../api';
import { Input, Panel } from '../../common';
import { IStore } from '../../store';
import { storeGlossaryItems } from './actions';
import GlossaryItem, { IGlossaryItem } from './components/GlossaryItem';
import GlossaryItemDetails from './components/GlossaryItemDetails';

import './HelpPanel.scss';

interface IHelpPanelProps {
  showPanel: boolean;
  toggleShowPanel: () => void;
}

const HelpPanel: FunctionComponent<IHelpPanelProps> = ({
  showPanel,
  toggleShowPanel,
}) => {
  const dispatch = useDispatch();
  const glossaryItems: IGlossaryItem[] = useSelector(
    (state: IStore) => state.glossaryItems,
  );
  const [selectedItem, setSelectedItem] = useState<IGlossaryItem>();
  const [searchText, setSearchText] = useState('');
  const [filteredItems, setFilteredItems] = useState<IGlossaryItem[]>([]);

  useEffect(() => {
    const getGlossaryItems = async () => {
      const items: IGlossaryItem[] = await getDefinitions();

      const sortedGlossaryItems = items.sort((a, b) =>
        a.term > b.term ? 1 : -1,
      );

      if (items) {
        setFilteredItems(sortedGlossaryItems);

        dispatch(storeGlossaryItems(sortedGlossaryItems));
      }
    };

    getGlossaryItems();
  }, [dispatch]);

  const handleFilter = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearchText(e.target.value);
    setFilteredItems(
      glossaryItems.filter((item) =>
        item.term
          .toLowerCase()
          .trim()
          .includes(e.target.value.toLowerCase().trim()),
      ),
    );
    TagManager.dataLayer({
      dataLayer: {
        event: 'glossary_search',
        'search term': e.target.value,
        number_of_results: glossaryItems.filter((item) =>
          item.term
            .toLowerCase()
            .trim()
            .includes(e.target.value.toLowerCase().trim()),
        ).length,
      },
    });
  };

  const handleGlossaryClick = (term: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'glossary_term_click',
        glossary_category: 'Common Terms',
        link_text: term,
      },
    });
    setSearchText('');
    setFilteredItems(glossaryItems);
    setSelectedItem(glossaryItems.find((item) => item.term === term));
  };

  const handlePanelClose = () => {
    toggleShowPanel();
    setSelectedItem(undefined);
  };

  return (
    <div>
      <Panel
        isOpen={showPanel}
        title="Common Terms"
        closePanel={handlePanelClose}
      >
        <div className="glossary-wrapper">
          <div
            className={`glossary-details ${
              !!selectedItem ? 'item-selected' : ''
            }`}
          >
            <GlossaryItemDetails
              glossaryItem={selectedItem}
              onBack={() => setSelectedItem(undefined)}
            />
          </div>

          <div
            className={`glossary-items ${
              !!selectedItem ? 'item-selected' : ''
            }`}
          >
            <Input
              name="glossary-search"
              onChange={handleFilter}
              value={searchText}
              placeholder="Enter text to search..."
            />
            <div className="glossary-items-list">
              {filteredItems.map((item, index) => (
                <GlossaryItem
                  key={index}
                  glossaryItem={item}
                  onClick={() => handleGlossaryClick(item.term)}
                />
              ))}
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default HelpPanel;
