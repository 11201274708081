import { CellInfo, Column, ReactTableDefaults } from 'react-table';

import { ITableColumnNames } from '../../../../common/tables/react-table';

export const JournalEntryColumnDefaults = {
  ...ReactTableDefaults.column,
  minWidth: 160,
};

// TODO: A temporary interface defining the data structure that backs each table row
export interface IJournalEntryRow {
  acctName: string;
  debit: number;
  credit: number;
}

// All available columns for the table
export enum JournalEntryColType {
  Account,
  Debit,
  Credit,
}

// Force columns that are always shown in the table
const JournalEntryColumn: JournalEntryColType[] = [
  JournalEntryColType.Account,
  JournalEntryColType.Debit,
  JournalEntryColType.Credit,
];

// A mapping from column type to names to be used in column headers and other locations
export const JournalEntryColNames: ITableColumnNames = {
  [JournalEntryColType.Account]: {
    name: 'Account',
    shortName: 'Account',
  },
  [JournalEntryColType.Debit]: {
    name: 'Debit',
    shortName: 'Debit',
  },
  [JournalEntryColType.Credit]: {
    name: 'Credit',
    shortName: 'Credit',
  },
};

// The react-table specification for the table's columns.
let JournalEntryReactTableCols: { [p: number]: Column<IJournalEntryRow> };
JournalEntryReactTableCols = {
  [JournalEntryColType.Account]: {
    id: JournalEntryColType[JournalEntryColType.Account],
    Header: JournalEntryColNames[JournalEntryColType.Account].shortName,
    accessor: 'acctName',
    resizable: false,
  },
  [JournalEntryColType.Debit]: {
    id: JournalEntryColType[JournalEntryColType.Debit],
    Header: JournalEntryColNames[JournalEntryColType.Debit].shortName,
    accessor: 'debit',
    Cell: CurrencyCellBlank0,
    width: 185,
    resizable: false,
  },
  [JournalEntryColType.Credit]: {
    id: JournalEntryColType[JournalEntryColType.Credit],
    Header: JournalEntryColNames[JournalEntryColType.Credit].shortName,
    accessor: 'credit',
    Cell: CurrencyCellBlank0,
    width: 185,
    resizable: false,
  },
};

function CurrencyCellBlank0(props: CellInfo) {
  if (props.value <= 0) {
    return '';
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(props.value);
}

export function journalEntryColumnConfiguration() {
  return JournalEntryColumn.map((col) => JournalEntryReactTableCols[col]);
}
