import { IQuickBooksSettingsMapping } from '../features/quickbooks/QuickBooksSettingsModal';
import {
  IJeExportModel,
  IJournalEntryExportModel,
  ITotalOrgJournalEntryModel,
} from '../interfaces';
import { formatError, quickbooksApi } from './api';

const getUserToken = () => sessionStorage.getItem('userToken');

// /LG are only calls needing userToken

export async function getQBAuthUrl(clientId: number) {
  let response;

  const rawResponse = await quickbooksApi.get(`/QBO/AuthUrl/${clientId}`, {
    withCredentials: false,
  });

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }
  response = rawResponse.data;

  return response;
}

export async function getQBAuthToken(authCode: string, realmId: string) {
  let response;

  const rawResponse = await quickbooksApi.get(
    `/QBO/AuthToken/${authCode}/${realmId}`,
    {
      withCredentials: true,
    },
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }
  response = rawResponse.data;

  return response;
}

export async function submitQBAccountSettings(
  clientId: number,
  data: IQuickBooksSettingsMapping,
) {
  let response;

  const rawResponse = await quickbooksApi.post(
    `/LG/AccountSettings/${clientId}`,
    data,
    { headers: { Authorization: `${getUserToken()}` } },
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }
  response = rawResponse.data;

  return response;
}

export async function exportJournalEntryQuickBooks(
  journalEntry: IJournalEntryExportModel,
  clientId?: number,
): Promise<IJeExportModel> {
  let response;

  try {
    const rawResponse = await quickbooksApi.post(
      `/QBO/ExportJE`,
      { ...journalEntry, clientId },
      {
        withCredentials: true,
      },
    );

    if (rawResponse.status >= 300) {
      throw formatError(rawResponse);
    }

    response = rawResponse.data;
  } catch (error) {
    if (
      error.response?.data === 'Missing Token' ||
      error.response?.status === 401
    ) {
      redirectToQuickBooksAuthUrl(clientId!);
    }
  }

  return response;
}

export async function exportTotalOrgJournalEntryQuickBooks(
  journalEntry: ITotalOrgJournalEntryModel,
  clientId?: number,
): Promise<IJeExportModel> {
  let response;

  try {
    const rawResponse = await quickbooksApi.post(
      `/QBO/ExportTotalOrgJE`,
      { ...journalEntry, clientId },
      {
        withCredentials: true,
      },
    );

    if (rawResponse.status >= 300) {
      throw formatError(rawResponse);
    }

    response = rawResponse.data;
  } catch (error) {
    if (
      error.response?.data === 'Missing Token' ||
      error.response?.status === 401
    ) {
      redirectToQuickBooksAuthUrl(clientId!);
    }
  }

  return response;
}

export async function getMappedQBSettings(clientId: number) {
  let response;

  const rawResponse = await quickbooksApi.get(
    `/LG/AccountSettings/${clientId}`,
    {
      headers: { Authorization: `${getUserToken()}` },
    },
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }
  response = rawResponse.data;

  return response;
}

export async function verifyQBToken() {
  let response;

  const rawResponse = await quickbooksApi.get(`/QBO/VerifyToken`, {
    withCredentials: true,
  });

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  response = rawResponse.data;

  return response;
}

export async function getQBAccountList(clientId: number) {
  let response;
  try {
    const rawResponse = await quickbooksApi.get(`/QBO/AccountList`, {
      withCredentials: true,
    });

    if (rawResponse.status >= 300) {
      throw formatError(rawResponse);
    }

    response = rawResponse.data;
  } catch (error) {
    if (
      error.response?.data === 'Missing Token' ||
      error.response?.status === 401
    ) {
      redirectToQuickBooksAuthUrl(clientId);
    }
  }

  return response;
}

export async function redirectToQuickBooksAuthUrl(clientId: number) {
  const authUrl = await getQBAuthUrl(clientId);

  window.open(authUrl, '_self');
}

export async function revokeQBAuthToken() {
  let response;

  const rawResponse = await quickbooksApi.post(`/QBO/RevokeAuthToken`, null, {
    withCredentials: true,
  });

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }

  response = rawResponse.data;

  return response;
}
