import { AnyAction } from 'redux';

import { ILeaseHeaderModel } from '../../interfaces';
import { LeaseListActions } from './actions';

const initialLeaseState: ILeaseHeaderModel[] = [];

export const leaseListReducer = (
  state = initialLeaseState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case LeaseListActions.STORE_LEASES:
      return payload.leaseList;
    case LeaseListActions.CLEAR_LEASES:
      return initialLeaseState;
    case LeaseListActions.DELETE_STORED_LEASE:
      return state.filter(
        (lease: ILeaseHeaderModel) => lease.leaseId !== payload.leaseId,
      );
    default:
      return state;
  }
};
