import React, { FunctionComponent } from 'react';

import lg_logo_check from '../../assets/lg-logo-check.svg';
import lg_logo_circle from '../../assets/lg-logo-circle.svg';

import './GuruLogoSpinner.scss';

interface IGuruLogoSpinnerProps {
  inProgress: boolean;
}

const GuruLogoSpinner: FunctionComponent<IGuruLogoSpinnerProps> = ({
  inProgress,
}) => {
  return (
    <div className="guru-logo-spinner">
      <img className={`guru-logo-check `} src={lg_logo_check} alt="check" />
      <img
        className={`guru-logo-circle ${inProgress ? 'in-progress' : ''}`}
        src={lg_logo_circle}
        alt="circle"
      />
    </div>
  );
};

export default GuruLogoSpinner;
