import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { JournalEntriesBannerText } from '../../../../app/resources/text.json';
import { Button } from '../../../../common/buttons';
import MessageBanner from '../../../../common/message-banner/MessageBanner';
import RadioGroup from '../../../../common/radio-group/RadioGroup';
import Select, { ISelectOptions } from '../../../../common/select/Select';
import { monthOptions } from '../../../../common/utilities/_constants';
import { useFeatureFlags } from '../../../../featureQuery';
import {
  FeatureFlagKeys,
  IJournalEntryFilters,
  JurisdictionValue,
} from '../../../../interfaces';
import { IStore } from '../../../../store';
import './FiltersContainer.scss';

interface IProps {
  value: IJournalEntryFilters;
  loading: boolean;
  onChange: (value: IJournalEntryFilters) => void;
  onExecute: () => void;
  valid: boolean;
  leaseBeginDt: string;
  leaseEndDt: string;
  showIsCapital?: boolean;
}

export const LeaseJournalFilters: FunctionComponent<IProps> = ({
  value,
  loading = false,
  onChange,
  onExecute,
  leaseBeginDt,
  leaseEndDt,
  valid,
  showIsCapital,
}) => {
  const company = useSelector((state: IStore) => state.user.company);

  const { getSingleFlag } = useFeatureFlags();
  const futureJournalEntriesFlag = getSingleFlag(
    FeatureFlagKeys.FutureJournalEntries,
  );

  // validates the date message if running JE for future periods
  const isDateOutOfRange = () => {
    let dateOutOfRange = false;
    if (value.month && value.year) {
      const currentDate = new Date();
      currentDate.setDate(1);
      const selectedDate = new Date(value.year, value.month - 1);
      dateOutOfRange = selectedDate > currentDate;
    }
    return dateOutOfRange;
  };

  const getYearOptions = () => {
    const yearOptions = [];

    const currentYear = new Date().getFullYear();
    const leaseEndYear = new Date(leaseEndDt).getFullYear();

    const endYear =
      leaseEndYear && leaseEndYear <= currentYear
        ? leaseEndYear
        : currentYear + 1;

    if (leaseBeginDt && endYear) {
      const beginYear = new Date(leaseBeginDt).getFullYear();

      if (beginYear) {
        yearOptions.push({
          key: beginYear,
          value: beginYear,
          label: beginYear.toString(),
        });

        if (endYear !== beginYear) {
          for (let index = beginYear + 1; index <= endYear; index++) {
            yearOptions.push({
              key: index,
              value: index,
              label: index.toString(),
            });
          }
        }
      }
    } else {
      yearOptions.push({
        key: endYear,
        value: endYear,
        label: endYear.toString(),
      });
    }
    return yearOptions;
  };

  const typeOptions = {
    stat: [
      {
        key: 0,
        value: JurisdictionValue.TOPIC_840,
        label: 'Statutory Accounting (Topic 840)/IAS 17',
      },
    ],
    ifrs: [{ key: 1, value: JurisdictionValue.IFRS, label: 'IFRS 16' }],
    gaap: [
      {
        key: 2,
        value: JurisdictionValue.TOPIC_842,
        label: 'GAAP Topic 840/842',
      },
    ],
  };

  const getTypeOptions = (): Array<ISelectOptions<JurisdictionValue>> =>
    company && company.jurisdiction === 'IFRS'
      ? [...typeOptions.stat, ...typeOptions.ifrs]
      : [...typeOptions.stat, ...typeOptions.gaap];

  const onFilterChange = (
    name: string,
    option: ISelectOptions<number | JurisdictionValue | boolean>,
  ) => {
    onChange({ ...value, [name]: option.value });
  };

  const showJournalEntriesBanner =
    value.month && value.year && isDateOutOfRange() && futureJournalEntriesFlag;

  return (
    <>
      <div className="filters-container">
        {showIsCapital ? (
          <RadioGroup
            name="isCapital"
            groupLabel="LEASE TYPE"
            onSelect={(
              e: React.ChangeEvent<HTMLInputElement>,
              selectedValue: boolean,
            ) => {
              onChange({ ...value, isCapital: selectedValue });
            }}
            value={value.isCapital || false}
            options={[
              { key: 0, value: false, label: 'Operating' },
              { key: 1, value: true, label: 'Capital' },
            ]}
          />
        ) : null}
        <div className="filters-months-container">
          <Select
            id="selectMonth"
            name="month"
            label="MONTH"
            placeholder="Select Month"
            onClick={onFilterChange}
            value={value.month}
            options={monthOptions}
          />
          <Select
            id="selectYear"
            name="year"
            label="YEAR"
            placeholder="Select Year"
            onClick={onFilterChange}
            value={value.year}
            options={getYearOptions()}
          />
          <Select
            id="selectJurisdictionType"
            name="jurisdiction"
            label="TYPE"
            placeholder="Select Type"
            onClick={onFilterChange}
            value={value.jurisdiction}
            options={getTypeOptions()}
          />
        </div>
        <div className="filters-apply-button">
          <Button
            buttonType="positive"
            buttonText="Run"
            disabled={loading || !valid || isDateOutOfRange()}
            processing={loading}
            onClick={onExecute}
          />
        </div>
      </div>
      {showJournalEntriesBanner && (
        <MessageBanner message={JournalEntriesBannerText} />
      )}
    </>
  );
};
