import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import { Form, Formik, FormikProps } from 'formik';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { getLeases, getLeasesByClient, terminateLease } from '../../../api';
import { Input } from '../../../common';
import DatepickerInput from '../../../common/datepicker/DatepickerInput';
import Modal from '../../../common/modal/Modal';
import { DEFAULT_DATE_FORMAT } from '../../../common/utilities/_dates';
import { IStore } from '../../../store';
import { storeLeases } from '../../leaseList/actions';
import { setReloadLeaseDetails } from '../actions';

import './TerminationModal.scss';

interface ITerminationModalProps {
  open: boolean;
  hideModal: () => void;
}

interface ITerminationFormState {
  terminationDate: string;
  terminationAmt: number | undefined;
}

const TerminationModal: FunctionComponent<ITerminationModalProps> = ({
  open,
  hideModal,
}) => {
  const dispatch = useDispatch();
  const selectedLease = useSelector((state: IStore) => {
    return state.leaseDetails.selectedLease;
  });
  const user = useSelector((state: IStore) => state.user);

  const [processing, setProcessing] = useState<boolean>(false);

  const terminationMinDate = addMonths(
    new Date(selectedLease.header.leaseBeginDt),
    1,
  );
  const terminationMaxDate = new Date(selectedLease.header.leaseEndDt);

  const schema = yup.object().shape({
    terminationDate: yup
      .date()
      .required('Termination Date is required')
      .typeError('Please enter a valid date')
      .min(terminationMinDate, (opts: any) => {
        const min: string = format(opts.min, DEFAULT_DATE_FORMAT);
        return `Please enter a date at least one month after the Possession Date (${min})`;
      })
      .max(terminationMaxDate, (opts: any) => {
        const max: string = format(opts.max, DEFAULT_DATE_FORMAT);
        return `Please select a date on or before the Lease End Date (${max})`;
      }),
    terminationAmt: yup
      .number()
      .min(0)
      .label('This field'),
  });

  const handleSubmit = async (values: ITerminationFormState) => {
    const { terminationAmt, terminationDate } = values;

    setProcessing(true);

    if (selectedLease && selectedLease.header.leaseId) {
      await terminateLease(
        selectedLease.header.leaseId,
        terminationDate,
        terminationAmt,
      );
      dispatch(setReloadLeaseDetails(true));
      // check if admin
      const leases =
        user.userRole === 1 && user.company && user.company.clientId
          ? await getLeasesByClient(user.company.clientId)
          : await getLeases(Number(user.userId));
      if (leases.length) {
        dispatch(storeLeases(leases));
      }

      hideModal();
    }
  };

  const formikRef = useRef<Formik<ITerminationFormState>>(null);

  const submitModal = () => {
    if (formikRef) {
      formikRef.current!.submitForm();
    }
  };

  return (
    <Modal
      open={open}
      modalSize="small"
      onConfirm={submitModal}
      hideModal={hideModal}
      negativeButtonText="Terminate"
      neutralButtonText="Cancel"
      title="Let's terminate your lease"
      processing={processing}
    >
      <Formik
        ref={formikRef}
        initialValues={{
          terminationDate: '',
          terminationAmt: undefined,
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
        validateOnChange={false}
      >
        {(formProps: FormikProps<ITerminationFormState>) => {
          return (
            <Form noValidate={true} className="termination-form">
              <DatepickerInput
                label="Termination Date"
                name="terminationDate"
                onChange={formProps.handleChange}
                value={formProps.values.terminationDate}
                error={!!formProps.errors.terminationDate}
                errorMessage={formProps.errors.terminationDate}
                placeholderText="mm/dd/yyyy"
                required
              />
              <Input
                label="Net Cash Paid/Credit (Received)"
                name="terminationAmt"
                onChange={formProps.handleChange}
                value={formProps.values.terminationAmt}
                error={!!formProps.errors.terminationAmt}
                errorMessage={formProps.errors.terminationAmt}
                type="number"
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default TerminationModal;
