import {
  DropdownHeaderItemKeys,
  IHeaderDropdownItem,
  IUserModel,
} from '../../interfaces';

/**
 * Defines guest user functionality
 */
const GUEST_USER_CONFIG = {
  guestUserRoleId: 6,

  guestUserHeaderDropdownItems: [
    DropdownHeaderItemKeys.profile,
    DropdownHeaderItemKeys.companySettings,
    DropdownHeaderItemKeys.logout,
  ],
};

function checkIfUserIsGuest(user: IUserModel) {
  return user.userRole === GUEST_USER_CONFIG.guestUserRoleId;
}

function getListOfGuestUserHeaderDropdownItems(
  headerDropdownItems: IHeaderDropdownItem[],
) {
  const listOfGuestUserHeaderDropdownItems = GUEST_USER_CONFIG.guestUserHeaderDropdownItems.reduce(
    (
      headerDropdownItemsToReturn: IHeaderDropdownItem[],
      guestUserDropdownItemKey,
    ) => {
      const foundHeaderDropdownItem = headerDropdownItems.find(
        (headerDropdownItem) =>
          headerDropdownItem.key === guestUserDropdownItemKey,
      );

      if (foundHeaderDropdownItem) {
        return [...headerDropdownItemsToReturn, foundHeaderDropdownItem];
      } else {
        return headerDropdownItemsToReturn;
      }
    },
    [],
  );

  return listOfGuestUserHeaderDropdownItems;
}

function filterUsersByType(users: IUserModel[]) {
  const contributingUsers = users.filter(
    (x) => x.userRole !== GUEST_USER_CONFIG.guestUserRoleId,
  );
  const guestUsers = users.filter(
    (x) => x.userRole === GUEST_USER_CONFIG.guestUserRoleId,
  );

  return { contributingUsers, guestUsers };
}

export {
  GUEST_USER_CONFIG,
  checkIfUserIsGuest,
  getListOfGuestUserHeaderDropdownItems,
  filterUsersByType,
};
