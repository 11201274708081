import { addDays, format, isAfter } from 'date-fns';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import { DEFAULT_DATE_FORMAT } from '../../common/utilities/_dates';

enum ErrorOptions {
  beforeBeginDate = 'Please enter a date that is after your lease start date ',
  afterEndDate = 'Please enter a date that is before your lease end date ',
  sameBeginDate = 'You have selected an effective date that is the same as the lease start date. If you are trying to correct an error, please choose to Override past financial data.',
  notValidDate = 'Please enter a valid date',
  invalidModificationDate = 'You must enter an effective date after the month of your last modification. Please enter an effective date on or after ',
  valid = '',
}

const formatErrorValue = (displayDate: Date, error: ErrorOptions): string => {
  const message: string = error + format(displayDate, DEFAULT_DATE_FORMAT);
  return message;
};

const validateModificationDate = (
  leaseModificationDate: Date,
  enteredDate: Date,
  leaseEndDate: Date,
): string => {
  // the first day of the month following the previous modification date
  const firstValidNewModificationDate: Date = addDays(
    lastDayOfMonth(leaseModificationDate),
    1,
  );

  if (isBefore(enteredDate, firstValidNewModificationDate)) {
    return formatErrorValue(
      firstValidNewModificationDate,
      ErrorOptions.invalidModificationDate,
    );
  } else if (isAfter(enteredDate, leaseEndDate)) {
    return formatErrorValue(leaseEndDate, ErrorOptions.afterEndDate);
  }
  return ErrorOptions.valid;
};

export const handleValidation = (
  leaseBeginDate: string,
  leaseEndDate: string,
  enteredDate: string,
  leaseModificationDate: string | null,
) => {
  const effectiveDateAsDate: Date = new Date(leaseBeginDate);
  const endDateAsDate: Date = new Date(leaseEndDate);
  const enteredDateAsDate: Date = new Date(enteredDate);

  // if modified previously
  if (leaseModificationDate) {
    return validateModificationDate(
      new Date(leaseModificationDate),
      enteredDateAsDate,
      endDateAsDate,
    );
  }

  // entered date same as lease start date
  else if (
    isSameDay(effectiveDateAsDate, enteredDateAsDate) &&
    !leaseModificationDate
  ) {
    return ErrorOptions.sameBeginDate;
  }

  // entered date is before lease start
  else if (isBefore(enteredDateAsDate, effectiveDateAsDate)) {
    return formatErrorValue(effectiveDateAsDate, ErrorOptions.beforeBeginDate);
  }

  // entered date is after lease end date
  else if (isAfter(enteredDateAsDate, endDateAsDate)) {
    return formatErrorValue(endDateAsDate, ErrorOptions.afterEndDate);
  }

  return ErrorOptions.valid;
};
