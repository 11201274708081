import React, { FunctionComponent } from 'react';
import ReactTable, { RowInfo } from 'react-table';

import { ButtonLink } from '../../common/buttons';
import { useFeatureFlags } from '../../featureQuery';
import { FeatureFlagKeys, IDisclosuresModel } from '../../interfaces';
import DisclosureExportWithLQ from './DisclosuresExportWithLQ';

import './DisclosuresTable.scss';

interface IDisclosuresTableProps {
  data: IDisclosuresModel[];
  jurisdiction: string | undefined;
  exportDisclosure?: () => void;
  yearRange: string;
}

const DisclosuresTable: FunctionComponent<IDisclosuresTableProps> = ({
  data,
  jurisdiction,
  exportDisclosure,
  yearRange,
}) => {
  const { getSingleFlag } = useFeatureFlags();
  const showExportWithLeaseQuery = getSingleFlag(
    FeatureFlagKeys.Disclosures_ExportWithLeaseQuery,
  );

  const DisclosuresTableColumns = [
    {
      Header: 'Description',
      accessor: 'description',
      width: 600,
    },
    {
      Header: yearRange,
      accessor: 'amount',
    },
  ];

  return (
    <>
      <div className="table-container">
        <div className="table-header">
          <div className="header-title">
            {jurisdiction && jurisdiction === 'IFRS' ? 'IFRS' : 'Topic 842'}
          </div>
          <div className="header-actions">
            {exportDisclosure ? (
              <ButtonLink
                text="Export to Excel"
                styles="action link-text"
                onClick={exportDisclosure}
              />
            ) : null}
            <div className="actions-div">
              {showExportWithLeaseQuery && <DisclosureExportWithLQ />}
            </div>
          </div>
        </div>
        <div className="disclosures-table">
          <ReactTable
            columns={DisclosuresTableColumns}
            data={data}
            getTrProps={(finalState: any, rowInfo: RowInfo | undefined) => {
              return rowInfo
                ? {
                    className:
                      rowInfo.row.description === 'Total Lease Cost'
                        ? 'bold-row'
                        : '',
                  }
                : {};
            }}
            resizable={false}
            sortable={false}
            showPagination={false}
            minRows={1}
          />
        </div>
      </div>
    </>
  );
};

export default DisclosuresTable;
