import React, { FunctionComponent } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';

import { hideModal, showModal } from '../../../common/modal/actions';
import { useResourceContext } from '../../../common/resource-context';
import { IStore } from '../../../store';
import { checkIfUserIsOnMaxPlan } from '../../plans/helpers';

import cta from '../../../assets/cta.svg';
import './MaxLeaseCard.scss';

const MaxLeaseCard: FunctionComponent = () => {
  const resources = useResourceContext();
  const user = useSelector((state: IStore) => {
    return state.user;
  });

  const allPlans = useSelector((state: IStore) => {
    return state.plans;
  });

  const userIsOnMaxPlan = checkIfUserIsOnMaxPlan(user, allPlans);

  const showSubscriptionModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'SUBSCRIPTIONS',
          modalProps: {
            open: true,
            hideModal: () => {
              dispatch(hideModal());
            },
          },
        },
      }),
    );
  };

  const displayUpgradeOptions = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'upgrade_subscription_cta',
        cta_location: 'My Leases Screen Callout',
        cta_creative: 'Max Number of Leases Reached - Upgrade Button',
      },
    });

    if (userIsOnMaxPlan) {
      dispatch(
        showModal({
          modal: {
            modalType: 'UPGRADE_TIER',
            modalProps: {
              open: true,
              hideModal: () => dispatch(hideModal()),
            },
          },
        }),
      );
    } else {
      showSubscriptionModal();
    }
  };

  const dispatch = useDispatch();

  return (
    <div className="max-lease-card-wrapper">
      <div className="max-lease-card-body">
        <span className="max-lease-header">
          You've reached the max number of leases under your current plan.
        </span>
        <span className="max-lease-description">
          {userIsOnMaxPlan
            ? `${resources.text(
                'MaxLeaseCard_UpgradeText',
                'Upgrade to our enterprise solution, LeaseQuery to add more leases',
              )}`
            : 'Upgrade to add more leases'}
        </span>
        <button className="cta-button" onClick={() => displayUpgradeOptions()}>
          {userIsOnMaxPlan ? 'Request Pricing' : 'Upgrade'}
        </button>
      </div>
      <div className="image-footer">
        <img src={cta} alt="cta" className="cta-image" />
      </div>
    </div>
  );
};

export default MaxLeaseCard;
