import { Form, Formik } from 'formik';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { editCompany, integrationAuth } from '../../api';
import { Input } from '../../common';
import { Button } from '../../common/buttons';
import FormError from '../../common/form-error/FormError';
import Modal from '../../common/modal/Modal';
import { useResourceContext } from '../../common/resource-context';
import { ClientIntegration } from '../../interfaces';
import { IStore } from '../../store';
import { updateUserCompanySettings } from '../auth/actions';

import './SageLogin.scss';

export interface ISageLoginState {
  companyId: string;
  userId: string;
  userPassword: string;
}

interface ISageLoginProps {
  hideModal: () => void;
}

const schema = yup.object().shape({
  companyId: yup.string().required('Company ID is required'),
  userId: yup.string().required('User ID is required'),
  userPassword: yup.string().required('Password is required'),
});

const SageLogin: FunctionComponent<ISageLoginProps> = ({ hideModal }) => {
  const resources = useResourceContext();
  const formikRef = useRef<Formik<ISageLoginState>>(null);
  const dispatch = useDispatch();
  const userCompanyData = useSelector((state: IStore) => {
    return state.user.company!;
  });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);

  const onSubmit = async (sageLoginData: ISageLoginState) => {
    const SAGE_DEFAULT_ERROR =
      'We are having trouble connecting to Sage at this time.';

    // SAGE Auth
    if (userCompanyData.clientId) {
      setProcessing(true);

      try {
        const authResponse = await integrationAuth<ISageLoginState>(
          ClientIntegration.SAGE,
          sageLoginData,
        );

        if (authResponse) {
          // update company with Sage integration
          await editCompany({
            ...userCompanyData,
            clientIntegrations: [ClientIntegration.SAGE],
          });

          dispatch(
            updateUserCompanySettings({
              ...userCompanyData,
              clientIntegrations: [ClientIntegration.SAGE],
            }),
          );

          dispatch(hideModal);
        } else {
          setProcessing(false);
          setErrorMessage(SAGE_DEFAULT_ERROR);
        }
      } catch (error) {
        setProcessing(false);

        if (error?.data?.message) {
          setErrorMessage(error.data.message);
        } else {
          setErrorMessage(SAGE_DEFAULT_ERROR);
        }
      }
    } else {
      setProcessing(false);
      setErrorMessage(SAGE_DEFAULT_ERROR);
    }
  };

  return (
    <Modal
      open={true}
      modalSize="small"
      showCloseInHeader
      title="Sage Intacct Login"
    >
      <div className="sage-login-wrapper">
        <p>
          Please enter your credentials below to connect{' '}
          {resources.text('SageLogin_CredentialText', 'LeaseGuru')} and Sage
          Intacct
        </p>
        <FormError error={!!errorMessage} errorMessage={errorMessage} />
        <Formik
          ref={formikRef}
          initialValues={{
            companyId: '',
            userId: '',
            userPassword: '',
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
          validateOnChange={false}
        >
          {(formProps) => {
            return (
              <Form noValidate={true}>
                <Input
                  label="Company ID"
                  required
                  name="companyId"
                  value={formProps.values.companyId}
                  onChange={formProps.handleChange}
                  error={!!formProps.errors.companyId}
                  errorMessage={formProps.errors.companyId}
                />
                <Input
                  label="User ID"
                  required
                  name="userId"
                  value={formProps.values.userId}
                  onChange={formProps.handleChange}
                  error={!!formProps.errors.userId}
                  errorMessage={formProps.errors.userId}
                />
                <Input
                  label="Password"
                  required
                  name="userPassword"
                  type="password"
                  value={formProps.values.userPassword}
                  onChange={formProps.handleChange}
                  error={!!formProps.errors.userPassword}
                  errorMessage={formProps.errors.userPassword}
                />
                <Button
                  buttonType="positive"
                  buttonText="Connect to Sage Intacct"
                  type="submit"
                  processing={processing}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default SageLogin;
