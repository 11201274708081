import React, { useEffect, useState } from 'react';
import {
  FeatureFlags,
  FeatureQueryContext,
  FeatureQueryProviderProps,
} from './models';
import { useFeatureHub } from './useFeatureHub';

const FeatureQuery = React.createContext<FeatureQueryContext>({
  flags: {},
  getSingleFlag: () => false,
});

FeatureQuery.displayName = 'FeatureQueryContext';

function FeatureQueryProvider({
  config,
  children,
}: FeatureQueryProviderProps): JSX.Element {
  const [flags, setFlags] = useState<FeatureFlags>({});
  const currentFlags = useFeatureHub(config);

  // fetch and set flags on clientId change
  useEffect(() => {
    setFlags(currentFlags);
  }, [currentFlags]);

  // get specific flag by flagKey
  const getSingleFlag = (flagKey: string): boolean => {
    // coerce to boolean default to false
    return !!flags?.[flagKey.toLocaleLowerCase()] ?? false;
  };

  return (
    <FeatureQuery.Provider value={{ flags, getSingleFlag }}>
      {children}
    </FeatureQuery.Provider>
  );
}

const useFeatureFlags = (): FeatureQueryContext =>
  React.useContext(FeatureQuery);

export { FeatureQueryProvider, useFeatureFlags };
