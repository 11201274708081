import React, { createRef, ReactElement, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import {
  addLease,
  buildLeaseModel,
  getLeases,
  getLeasesByClient,
  updateLease,
} from '../../../api';

import { Button } from '../../../common/buttons';
import { showModal } from '../../../common/modal/actions';
import { noScroll } from '../../../common/modal/Modal';
import { IModalAction } from '../../../common/modal/types';
import {
  AmendTypes,
  IAddUpdateLeaseResponse,
  ILeaseHeaderModel,
  IUserModel,
} from '../../../interfaces';
import { IStore } from '../../../store';
import { storeLeases } from '../../leaseList/actions';
import {
  clearAllForms,
  LeaseForms,
  saveLeaseData,
  setReloadLeaseDetails,
  setReloadLeases,
} from '../actions';
import {
  AdditionalPaymentsForm,
  BaseRentForm,
  DateForm,
  GeneralForm,
  OptionsForm,
} from '../add-lease-forms';
import { ILeaseState } from '../reducers';
import SteppedPortal from './SteppedPortal';

import TagManager from 'react-gtm-module';
import { ReactComponent as CheckSolid } from '../../../assets/check-solid.svg';
import close from '../../../assets/close.svg';
import './SteppedModal.scss';

class SteppedModal extends React.Component<
  ISteppedModalProps,
  ISteppedModalState
> {
  public state: ISteppedModalState = {
    currentStep: 0,
    previousStep: -1,
    validSteps: {},
    nextButtonDisabled: false,
    redirect: false,
    errorMessage: null,
    processing: false,
  };
  private ref = createRef<any>();

  public componentDidMount() {
    if (this.props.open) {
      noScroll('hidden');
      // check for if this is an update
      if (this.props.amendType === 'OVERRIDE') {
        this.setState({
          validSteps: { 0: true, 1: true, 2: true, 3: true, 4: true },
        });
      }
      TagManager.dataLayer({
        dataLayer: {
          event:
            this.props.amendType === 'OVERRIDE'
              ? 'amend_lease_detail_change_start'
              : 'add_new_lease_start',
        },
      });
    }
  }

  public componentWillUnmount() {
    noScroll('unset');
  }

  public render() {
    const {
      currentStep,
      validSteps,
      nextButtonDisabled,
      redirect,
      errorMessage,
      processing,
    } = this.state;
    const { open, title } = this.props;
    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: 'sign-up',
          }}
        />
      );
    }
    return (
      <SteppedPortal>
        {open ? (
          <div className="stepped-modal">
            <div className="modal-background" />
            <div
              className={`modal-wrapper ${process.env.REACT_APP_ENABLE_RSM ===
                'true' && 'rsm-class-modal'}`}
            >
              <div className="step-modal-layout">
                <header className="modal-title">
                  {title}

                  <button
                    className="modal-header-close"
                    onClick={this.handleCancel}
                  >
                    <img
                      src={close}
                      alt="question mark"
                      className="tooltip-image"
                    />
                  </button>
                </header>

                <nav className="step-modal-nav">
                  <ul role="menu">
                    {steps.map((step: IModalStep, i) => (
                      <span key={i}>
                        <li
                          onClick={() => this.handleNavigation(step.step)}
                          id={`${title}-${step.title}`}
                          className={`${
                            currentStep === step.step
                              ? 'step-modal-menu-active'
                              : ''
                          } ${this.handleStyleLogic(step)}`}
                        >
                          {step.title}
                          {validSteps[step.step] ? (
                            <CheckSolid className="step-icon" />
                          ) : null}
                        </li>
                      </span>
                    ))}
                  </ul>
                </nav>
                <main className="step-modal-main">
                  <div className="step-modal-content">
                    <div className="form-error-message">{errorMessage}</div>
                    {this.handleRenderForm(steps[currentStep])}
                  </div>
                </main>
                <footer className="modal-footer">
                  {currentStep > 0 && (
                    <Button
                      onClick={this.handleBack}
                      buttonText="Back"
                      buttonType="neutral"
                    />
                  )}

                  <Button
                    onClick={this.handleNext}
                    buttonType="positive"
                    disabled={nextButtonDisabled}
                    processing={processing}
                    buttonText={
                      currentStep + 1 === steps.length ? 'Save' : 'Next'
                    }
                  />
                </footer>
              </div>
            </div>
          </div>
        ) : null}
      </SteppedPortal>
    );
  }

  private handleCancel = () => {
    this.props.clearAllForms();
    this.props.hideModal();
  };

  private async handleLeaseUpdate(amendType: AmendTypes) {
    const { otherProps, lease, user, isCapital } = this.props;

    const userChangedIsCapital = this.props.lease.form![LeaseForms.GENERAL]
      .isCapital;

    let { leaseId } = this.props;

    // if trying to update from anywhere except lease details page,
    // will need to pass in leaseId into otherProps
    if (!leaseId && otherProps) {
      leaseId = otherProps.leaseId;
    }

    const leaseData = buildLeaseModel(lease, user, leaseId);

    this.props.saveLeaseData(leaseData);

    let leaseUpdateResponse: IAddUpdateLeaseResponse = {
      leaseId: undefined,
      capOpTestResult: null,
    };

    try {
      if (amendType === 'MODIFY' || amendType === 'RENEW') {
        if (otherProps) {
          const { effectiveDate } = otherProps;
          if (effectiveDate) {
            leaseUpdateResponse = await updateLease(
              { ...leaseData, modifyEffectiveDate: effectiveDate },
              amendType,
            );
          }
        }
      } else {
        leaseUpdateResponse = await updateLease(leaseData, amendType);
      }

      this.props.clearAllForms();
      this.props.setReloadLeaseDetails(true);

      // check if admin
      const leases =
        user.userRole === 1 && user.company && user.company.clientId
          ? await getLeasesByClient(user.company.clientId)
          : await getLeases(Number(user.userId));

      const newLeaseHeader: ILeaseHeaderModel = leases.find(
        (leaseHeader: ILeaseHeaderModel) => leaseHeader.leaseId === leaseId,
      );

      if (newLeaseHeader) {
        const { isCapital: newIsCapital } = newLeaseHeader;
        if (newIsCapital !== isCapital && amendType === 'MODIFY') {
          alert(
            `Based on the information entered, your lease type has changed. This lease will be classified as ${
              newIsCapital ? `Capital` : `Operating`
            } from ${this.props.otherProps &&
              this.props.otherProps.effectiveDate} forward.`,
          );
        }
      }

      if (leases.length) {
        this.props.storeLeases(leases);
      }

      this.props.hideModal();

      // only show results if override and not IFRS client
      if (amendType === 'OVERRIDE' && user.company?.jurisdiction !== 'IFRS') {
        this.props.showModal({
          modal: {
            modalType: 'CAP_OP_RESULTS',
            modalProps: {
              open: true,
              otherProps: {
                capOpTestResults: leaseUpdateResponse.capOpTestResult,
                leaseData,
                isCapital: userChangedIsCapital,
                leaseId: leaseUpdateResponse.leaseId,
              },
            },
          },
        });
      }

      if (amendType === 'OVERRIDE') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'amend_lease_detail_change_complete',
          },
        });
      }
    } catch (error) {
      this.setState({
        nextButtonDisabled: false,
        errorMessage: 'Unable to update lease',
      });
    }
  }

  private async handleLeaseAdd() {
    const leaseData = buildLeaseModel(this.props.lease, this.props.user);
    const isCapital = this.props.lease.form![LeaseForms.GENERAL].isCapital;
    this.props.saveLeaseData(leaseData);
    try {
      const addLeaseResponse = await addLease(leaseData);
      this.props.clearAllForms();
      this.props.setReloadLeases(true);
      this.props.hideModal();

      if (this.props.user.company?.jurisdiction !== 'IFRS') {
        this.props.showModal({
          modal: {
            modalType: 'CAP_OP_RESULTS',
            modalProps: {
              open: true,
              otherProps: {
                capOpTestResults: addLeaseResponse.capOpTestResult,
                leaseData,
                isCapital,
                leaseId: addLeaseResponse.leaseId,
              },
            },
          },
        });
      }
      TagManager.dataLayer({
        dataLayer: {
          event: 'add_new_lease_complete',
        },
      });
      this.props.history.push(`/leases/${addLeaseResponse.leaseId}`);
      this.props.setReloadLeaseDetails(true);
    } catch (e) {
      this.setState({
        nextButtonDisabled: false,
        errorMessage: 'Unable to add lease',
      });
    }
  }

  private resetScroll() {
    const el: HTMLElement | null = document.getElementById('modal-main');
    if (el && el.scrollTop !== 0) {
      el.scrollTo(0, 0);
    }
  }

  private handleRenderForm(step: IModalStep): ReactNode {
    if (step && step.component) {
      return React.cloneElement(step.component, {
        ref: this.ref,
      });
    }
  }

  private handleValidation = async (stepName: IModalStep) => {
    const { previousStep } = this.state;
    let isValid: boolean = false;
    if (!!this.ref.current) {
      await this.ref.current.validate();
      isValid = await this.ref.current.isValid();
      this.setState({
        validSteps: {
          ...this.state.validSteps,
          [stepName.step]: isValid,
        },
        previousStep:
          !isValid && previousStep > stepName.step
            ? stepName.step
            : previousStep,
      });
    }
    return isValid;
  };

  // The user made the form invalid.
  //   Loop through and remove any valid steps greater than current step
  private invalidateFutureSteps = (currentStep: number) => {
    const { validSteps } = this.state;
    const newSteps: {
      [key: string]: boolean;
    } = {};
    for (const step in validSteps) {
      if (Number(step) < currentStep) {
        newSteps[step] = true;
      }
    }
    this.setState({
      validSteps: newSteps,
    });
  };

  private handleBack = async () => {
    const { currentStep } = this.state;

    this.setState({
      currentStep: currentStep - 1,
    });
  };

  private handleNext = async () => {
    const { currentStep } = this.state;
    await this.handleValidation(steps[currentStep]);
    if (currentStep < steps.length && this.state.validSteps[currentStep]) {
      this.setState({ nextButtonDisabled: true });
      if (currentStep + 1 === steps.length) {
        this.setState({ errorMessage: null, processing: true });
        if (this.props.amendType && this.props.amendType !== 'DUPLICATE') {
          this.handleLeaseUpdate(this.props.amendType);
        } else {
          this.handleLeaseAdd();
        }
      } else {
        // if the current step is in the valid steps array
        this.resetScroll();
        this.setState({
          nextButtonDisabled: false,
          currentStep: currentStep + 1,
          processing: false,
        });
      }
    } else {
      this.invalidateFutureSteps(currentStep);
    }
  };

  private handleNavigation = async (stepToNavigate: number) => {
    const { currentStep, previousStep, validSteps } = this.state;
    const nextPrevStep =
      previousStep === -1 || previousStep < currentStep
        ? currentStep
        : previousStep;

    let isValid: boolean = false;

    if (stepToNavigate <= currentStep) {
      if (validSteps[currentStep]) {
        isValid = await this.handleValidation(steps[currentStep]);
        if (isValid && this.userCanNav(stepToNavigate)) {
          this.setState({
            currentStep: stepToNavigate,
            previousStep: nextPrevStep,
          });
        } else {
          this.invalidateFutureSteps(currentStep);
        }
      } else {
        this.setState({
          currentStep: stepToNavigate,
          previousStep: nextPrevStep,
        });
        this.ref.current!.saveForm();
      }
    } else {
      isValid = await this.handleValidation(steps[currentStep]);
      if (isValid && this.userCanNav(stepToNavigate)) {
        this.setState({
          currentStep: stepToNavigate,
          previousStep: nextPrevStep,
        });
      } else {
        this.invalidateFutureSteps(currentStep);
      }
    }
  };

  private userCanNav = (stepToNavigate: number) => {
    const isStepPreviousOrCurrent = stepToNavigate <= this.state.currentStep;
    const isSavedPrevious = this.state.previousStep === stepToNavigate;
    const isStepValid = this.state.validSteps[stepToNavigate];
    return isStepPreviousOrCurrent || isStepValid || isSavedPrevious;
  };

  private handleStyleLogic = (step: IModalStep): string => {
    const { validSteps, previousStep } = this.state;
    const { form } = this.props.lease;
    const currentForm = form ? form[step.key] : null;

    // mark step as invalid
    if (!currentForm && validSteps[step.step]) {
      this.toggleStepValidity(step.step, false);
    }

    //  resets previous step to be current step
    if (!currentForm && previousStep > step.step) {
      this.setState({ previousStep: step.step });
    }

    return this.addNavClass(step.step, previousStep);
  };

  private addNavClass(stepNumber: number, previousStep: number) {
    if (stepNumber === 0 || stepNumber === previousStep) {
      return 'can-nav';
    }
    if (this.userCanNav(stepNumber)) {
      return 'can-nav';
    } else {
      return '';
    }
  }

  private toggleStepValidity = (step: number, isValid?: boolean) =>
    this.setState({
      validSteps: {
        ...this.state.validSteps,
        [step]: isValid || !this.state.validSteps[step],
      },
    });
}

const steps: IModalStep[] = [
  {
    step: 0,
    key: LeaseForms.GENERAL,
    title: 'General',
    component: <GeneralForm />,
  },
  {
    step: 1,
    key: LeaseForms.DATE,
    title: 'Dates',
    component: <DateForm />,
  },
  {
    step: 2,
    key: LeaseForms.OPTIONS,
    title: 'Options',
    component: <OptionsForm />,
  },
  {
    step: 3,
    key: LeaseForms.BASE,
    title: 'Base Rent',
    component: <BaseRentForm />,
  },
  {
    step: 4,
    key: LeaseForms.ADDL,
    title: "Add'l Payments",
    component: <AdditionalPaymentsForm />,
  },
];

export interface IModalStep {
  step: number;
  key: string;
  title: string;
  component: ReactElement;
  isValid?: boolean;
}

interface ISteppedModalProps
  extends IConnectedProps,
    RouteComponentProps,
    IDispatchProps {
  hideModal: () => void;
  open: boolean;
  steps?: IModalStep[];
  title: string;
  amendType?: AmendTypes;
  otherProps?: { effectiveDate: string; leaseId: number };
}

interface ISteppedModalState {
  currentStep: number;
  previousStep: number;
  validSteps: {
    [key: string]: boolean;
  };
  nextButtonDisabled: boolean;
  redirect: boolean;
  errorMessage: string | null;
  processing: boolean;
}

interface IConnectedProps {
  lease: ILeaseState;
  user: IUserModel;
  leaseId: number | undefined;
  isCapital: boolean;
}

interface IDispatchProps {
  saveLeaseData: (lease: any) => void;
  clearAllForms: () => void;
  setReloadLeases: (reload: boolean) => void;
  setReloadLeaseDetails: (reload: boolean) => void;
  storeLeases: (leaseList: ILeaseHeaderModel[]) => void;
  showModal: (modal: IModalAction) => void;
}

function mapDispatchToProps(dispatch: Dispatch): IDispatchProps {
  return bindActionCreators(
    {
      clearAllForms,
      saveLeaseData,
      setReloadLeases,
      setReloadLeaseDetails,
      storeLeases,
      showModal,
    },
    dispatch,
  );
}

function mapStateToProps(store: IStore): IConnectedProps {
  const selectedLease = store.leaseDetails.selectedLease;
  let leaseId;

  if (selectedLease) {
    leaseId = selectedLease.header.leaseId;
  }

  return {
    lease: store.lease,
    user: store.user,
    leaseId,
    isCapital:
      store.leaseDetails.selectedLease &&
      store.leaseDetails.selectedLease.header.isCapital,
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SteppedModal),
);
