import format from 'date-fns/format';
import React, { FunctionComponent } from 'react';

import {
  DEFAULT_DATE_FORMAT,
  getPercentCompleteOfDates,
  getTimeRemaining,
} from '../utilities/_dates';

import './DateProgressBar.scss';

const DateProgressBar: FunctionComponent<IDateProgressBarProps> = ({
  startDate,
  endDate,
  moreDetail,
}) => {
  const progress: number = getPercentCompleteOfDates(startDate, endDate);
  const daysRemaining: number = getTimeRemaining(endDate);
  return (
    <div
      className={`date-progress-layout ${process.env.REACT_APP_ENABLE_RSM ===
        'true' && 'rsm-class-date-progress'}`}
    >
      <div className="date-progress-wrap">
        <div className="date-progress-time-remain">
          {getProgressWording(progress, daysRemaining)}
        </div>
        <div
          className={`date-progress-bar-container ${
            progress === -1 ? 'date-progress-bar--future' : ''
          } ${progress > 100 ? 'date-progress-bar--expired' : ''}`}
        >
          <div
            className="date-progress-bar-value"
            style={{ width: `${renderPercentage(progress)}%` }}
          />
        </div>
      </div>
      {moreDetail ? (
        <>
          <span className="start-date">
            <span className="more-details-date">
              {format(new Date(startDate), DEFAULT_DATE_FORMAT)}
            </span>
            <span className="more-details-label">START DATE</span>
          </span>
          <span className="end-date">
            <span className="more-details-date">
              {format(new Date(endDate), DEFAULT_DATE_FORMAT)}
            </span>
            <span className="more-details-label">END DATE</span>
          </span>
        </>
      ) : (
        <>
          <span className="small-bold start-date">
            {format(new Date(startDate), DEFAULT_DATE_FORMAT)}
          </span>
          <span className="small-bold end-date">
            {format(new Date(endDate), DEFAULT_DATE_FORMAT)}
          </span>
        </>
      )}
    </div>
  );
};

interface IDateProgressBarProps {
  startDate: string;
  endDate: string;
  moreDetail?: boolean;
}

function getProgressWording(progress: number, daysRemaining: number) {
  if (progress === -1) {
    return 'Future';
  } else if (daysRemaining < 0) {
    return 'Expired';
  } else if (daysRemaining > 60) {
    return '';
  } else {
    return getTimeRemainingWording(daysRemaining);
  }
}

function getTimeRemainingWording(daysRemaining: number): string {
  if (daysRemaining && daysRemaining < 364) {
    return `${daysRemaining} day${daysRemaining !== 1 ? 's' : ''} remaining`;
  } else if (daysRemaining === 0) {
    return '0 days remaining';
  } else {
    return 'DATE_ERROR';
  }
}

function renderPercentage(progress: number) {
  const percent = 100 - progress;
  if (percent < 0) {
    return 0;
  } else {
    return percent;
  }
}

export default DateProgressBar;
