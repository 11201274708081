import React from 'react';

import './RadioGroup.scss';

const RadioGroup = <T extends {}>({
  options,
  groupLabel,
  value,
  onSelect,
  required,
  name,
}: IRadioGroupProps<T>) => {
  return (
    <fieldset className="radio-group-fieldset">
      {groupLabel ? (
        <legend className={`${required ? 'radio-required' : ''}`}>
          {groupLabel}
        </legend>
      ) : null}
      <div className="radio-group-root" role="group">
        {options.map((option: IRadioGroupOption<T>, index: number) => (
          <div
            className="radio-input-wrapper"
            key={`${index}-${name}-${option.value}`}
          >
            <input
              className="radio-input"
              name={name}
              id={`${index}-${name}-${option.value}`}
              type="radio"
              checked={value === option.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onSelect(e, option.value)
              }
            />
            <label
              className="radio-label"
              htmlFor={`${index}-${name}-${option.value}`}
            >
              {option.label}{' '}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

interface IRadioGroupProps<T> {
  options: Array<IRadioGroupOption<T>>;
  groupLabel?: string;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, value: T) => void;
  name: string;
  value: T;
  required?: boolean;
}

export interface IRadioGroupOption<T> {
  key: number;
  value: T;
  label: string;
}

export default RadioGroup;
