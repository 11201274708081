import React from 'react';
import checkmark from '../../../../assets/checkmark.svg';
import rsmCheckmark from '../../../../assets/rsm-checkmark.svg';
import { isEnvRSM } from '../../../../common/utilities/_helpers';
import { IPlanFeatureModel } from '../../../../interfaces';

import './FeatureList.scss';

export interface FeatureListProps {
  featureList: IPlanFeatureModel[];
}

export default function FeatureList({
  featureList,
}: FeatureListProps): JSX.Element {
  const isRSM = isEnvRSM();

  return (
    <div className="features-container">
      {featureList.map((feature: IPlanFeatureModel) => {
        return (
          <div className="tier-feature" key={feature.id}>
            <span className="check-mark">
              <img src={isRSM ? rsmCheckmark : checkmark} alt="checkmark" />
            </span>
            <span className="feature-item">{feature.description}</span>
          </div>
        );
      })}
    </div>
  );
}
