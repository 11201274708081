import React from 'react';
import { Button } from '../../../common/buttons';
import { useResourceContext } from '../../../common/resource-context';

const WelcomeFooter: React.FC = () => {
  const resources = useResourceContext();
  const privacyPolicyLink = resources.text('PrivacyPolicyLink');
  const termsAndConditionsLink = resources.text('TermsAndConditionsLink');

  return (
    <div className="welcome-footer">
      <div className="policies-terms">
        By clicking “Start”, you agree to our <br />
        <a
          href={privacyPolicyLink}
          target="_blank"
          rel="noopener noreferrer"
          className="terms-link"
        >
          Privacy Policy
        </a>{' '}
        and{' '}
        <a
          href={termsAndConditionsLink}
          target="_blank"
          rel="noopener noreferrer"
          className="terms-link"
        >
          Terms and Conditions
        </a>
        .
      </div>
      <div className="try-button-wrapper">
        <Button buttonText="Start" buttonType="positive" type="submit" />
      </div>
    </div>
  );
};

export default WelcomeFooter;
