import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  AmortModels,
  IAmortSchedModel,
  ILeaseHeaderModel,
  IPaymentModel,
} from '../../../../interfaces';
import { IStore } from '../../../../store';
import { ScheduleTypes } from './amort-sched-columns';
import { AmortSchedTable } from './AmortSchedTable';

interface IProps {
  basePayment: IPaymentModel;
  amortizationSchedules: IAmortSchedModel;
  leaseHeader: ILeaseHeaderModel;
}

const AmortSchedContainer: FunctionComponent<IProps> = ({
  basePayment,
  amortizationSchedules,
  leaseHeader,
}) => {
  const [schedule, setSchedule] = useState<AmortModels | undefined>();
  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleTypes>();
  const company = useSelector((state: IStore) => state.user.company);

  const getSchedule = useCallback(
    (scheduleType: ScheduleTypes) => {
      setSelectedSchedule(scheduleType);
      switch (scheduleType) {
        case ScheduleTypes.TOPIC_840BR:
          setSchedule(amortizationSchedules.baserent[840] || []);
          break;
        case ScheduleTypes.TOPIC_840FT:
          setSchedule(amortizationSchedules.fixedtotal[840] || []);
          break;
        case ScheduleTypes.TOPIC_842BR:
          setSchedule(amortizationSchedules.baserent[842] || []);
          break;
        case ScheduleTypes.TOPIC_842FT:
          setSchedule(amortizationSchedules.fixedtotal[842] || []);
          break;
        case ScheduleTypes.IFRS_16BR:
          setSchedule(amortizationSchedules.baserent.ifrs || []);
          break;
        case ScheduleTypes.IFRS_16FT:
          setSchedule(amortizationSchedules.fixedtotal.ifrs || []);
          break;
        case ScheduleTypes.CAPITAL_BR:
          setSchedule(amortizationSchedules.baserent.capital || []);
          break;
        case ScheduleTypes.CAPITAL_FT:
          setSchedule(amortizationSchedules.fixedtotal.capital || []);
          break;
        default:
          return;
      }
    },
    [amortizationSchedules],
  );

  const getRateText = () => {
    // if 840, don't show a rate
    if (
      selectedSchedule === ScheduleTypes.TOPIC_840FT ||
      selectedSchedule === ScheduleTypes.TOPIC_840BR
    ) {
      return '';
    }

    if (leaseHeader) {
      // if capital, show borrowing rate
      if (leaseHeader.isCapital) {
        return `Borrowing Rate: ${basePayment.borrowingRate}%`;
      }
      if (
        company &&
        company.transitionYear &&
        company.transitionMonth &&
        leaseHeader.leaseBeginDt
      ) {
        const transitionDate = new Date(
          company.transitionYear,
          company.transitionMonth - 1,
        );
        const beginDate = new Date(leaseHeader.leaseBeginDt);
        if (transitionDate && beginDate) {
          // if transition date is greater that leaseBeginDt, show transition
          if (transitionDate > beginDate) {
            return `Transition Rate: ${basePayment.transitionRate}%`;
          } else {
            return `Borrowing Rate: ${basePayment.borrowingRate}%`;
          }
        }
      }
    }
    return '';
  };

  useEffect(() => {
    const { isCapital } = leaseHeader;
    let scheduleToGet = isCapital
      ? ScheduleTypes.CAPITAL_BR
      : company && company.jurisdiction === 'IFRS'
      ? ScheduleTypes.IFRS_16BR
      : ScheduleTypes.TOPIC_840BR;

    const today = new Date();
    const currentMonth = today.getMonth() + 1; // 0 based index
    const currentYear = today.getFullYear();
    if (
      scheduleToGet === ScheduleTypes.TOPIC_840BR &&
      ((company.transitionYear && currentYear > company.transitionYear) ||
        (company.transitionYear &&
          currentYear === company.transitionYear &&
          company.transitionMonth &&
          currentMonth >= company.transitionMonth))
    ) {
      scheduleToGet = ScheduleTypes.TOPIC_842BR;
    }

    getSchedule(scheduleToGet);
  }, [getSchedule, leaseHeader, company]);

  return (
    <div className="amort-sched-table-container">
      <AmortSchedTable
        schedule={schedule}
        selectedSchedule={selectedSchedule}
        rateText={getRateText()}
        loading={false}
        getSchedule={getSchedule}
        jurisdiction={company && company.jurisdiction}
      />
    </div>
  );
};

export default AmortSchedContainer;
