export interface IRouteParams {
  id: string;
}

export interface IUserModel {
  userId?: number;
  userRole?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  authToken?: string;
  company: ICompanyModel;
  isSuspended?: boolean;
  createdDate?: Date;
  linkManagerCode?: string;
  clientUser?: IClientUser;
  canUndoUser?: boolean;
  parentCompany?: ICompanyModel;
}

export interface IClientUser {
  isDeleted: boolean;
  isSuspended: boolean;
}

export interface IUserAddModel {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  password?: string;
  companyName?: string;
  transitionMonth?: number;
  transitionYear?: number;
  userId?: number;
  companyType?: number;
  clientIndustryId?: number;
  jurisdiction?: string;
  ifrsApproach?: string;
  isFromUserAccess?: boolean;
  userTypeId?: number;
  clientTypeId?: number;
  leaseCountEstimation?: number;
  linkManagerCode?: string;
  linkManagerId?: number;
  generalLedger?: string;
}

export interface IUserEditModel {
  userId?: number;
  email?: string;
  currentPassword?: string;
  password?: string;
  passwordConfirmation?: string;
  firstName?: string;
  lastName?: string;
}

export interface IPaymentMethodModel {
  paymentMethodId?: string;
  companyEmail?: string;
  companyName?: string;
  companyAddress?: string;
  city?: string;
  state?: string;
  zip?: string;
  planTier?: PlanTier;
  country?: string;
  planId?: string;
  stripePlanId?: string;
  prorationDate?: Date;
}

export interface IProratePreview {
  proratedAmount?: number;
  nextInvoiceDate?: Date;
}

export interface IPlanFeatureAttributeModel {
  id: number;
  featureId: number;
  name: string;
  isActive: boolean;
  values: string[];
}

export enum PlanFeatureNames {
  quickbooks = 'QuickBooks Integration',
  sage = 'Sage Intacct Integration',
  xero = 'Xero Integration',
  totalOrg = 'View Total Org Journal Entries',
  disclosures = 'View Disclosures Reports',
  leaseCount = 'Lease Count',
  users = 'Users',
  viewAmort = 'View Amortization Schedules',
  exportAmort = 'Export Amortization Schedules',
  viewIndJournalEntries = 'View Journal Entries',
  exportJE = 'Export Journal Entries',
  exportTotalOrg = 'Export Total Org Journal Entries',
  jurisdiction = 'Jurisdiction',
  guest = 'Guest User',
  clientCount = 'Client Count',
}

export interface IStaticTierFeature {
  id: number;
  tier: PlanTier;
  featureDescription: string;
  orderNumber: number;
  groupNumber: number;
  isNewPlanPage: boolean;
}

export interface IPlanFeatureModel {
  id: number;
  name: PlanFeatureNames | string;
  description: string;
  isActive: boolean;
  attributes: IPlanFeatureAttributeModel[];
  orderNumber: number;
  groupNumber: number;
  isNewPlanPage: boolean;
}

export interface IProratedData {
  amountDue: number;
}

export interface IPlanModel {
  id: number;
  name: string;
  description: string;
  features: IPlanFeatureModel[];
  price: number;
  stripePlanId: string;
  isActive: boolean;
  tier: PlanTier;
  displayName: string;
}

export interface ICompanyModel {
  clientId?: number;
  clientIndustryId?: number;
  clientIntegrations?: ClientIntegration[];
  companyName?: string;
  companyType?: number;
  contactEmail?: string;
  currency?: number;
  ifrsApproach?: string | null;
  jurisdiction?: JurisdictionValue | string;
  plan?: IPlanModel;
  subscription?: ISubscriptionModel;
  transitionMonth?: number;
  transitionYear?: number;
  trialEndDate?: string;
  subscriptionEndDate?: string;
  allowGuestUser?: boolean;
  featureFlags?: IFeatureFlagModel;
}

export interface IFeatureFlagModel {
  [key: string]: boolean;
}

export interface ISubscriptionModel {
  trialEnd?: Date;
  addressModel: IBillingAddressModel;
}

export interface ISubscriptionSettings {
  paymentDate: Date;
  paymentMethod: IPaymentSettingsModel;
}

export interface IPaymentSettingsModel {
  id: string;
  card: ICardModel;
}

export interface ICardModel {
  brand: string;
  last4: string;
}

export interface IBillingAddressModel {
  companyAddress: string;
  city: string;
  zip: string;
  country?: string;
}

export enum ClientIntegration {
  SAGE = 4,
  QUICKBOOKS = 6,
  XERO = 7,
}

export interface IClientIndustriesModel {
  id: number;
  industryName: string;
}

export interface IGeneralLedgerModel {
  id: number;
  application: string;
}

export interface ILeaseHeaderModel {
  isCapital: boolean;
  leaseId: number;
  assetName: string;
  serialNbr: string;
  canTransferTitle: boolean;
  address: string;
  city: string;
  state: string; // limit 2 char
  zip: string;
  assetType: AssetTypes;
  description: string;
  currency: string;
  iconURI: string;
  leaseBeginDt: string; // YYYY-MM-DD
  leaseEndDt: string; // YYYY-MM-DD
  usefulLifeMonths?: number;
  currentPaymentAmt: number; // decimal
  currentPaymentDate: string;
  nextPaymentAmt: number; // decimal
  nextPaymentDate: string;
  remainingAmt: number; // decimal
  remainingPayments: number;
  leaseStatus: string;
  fairValue?: number;
  isSpecializedUse: boolean;
  leaseModificationDate: string | null;
}

export interface IAddUpdateLeaseResponse {
  leaseId: number | undefined;
  capOpTestResult: {
    canTransfer: number;
    hasBargainPurchase: number;
    usefulLife: number;
    fairValue: number;
    isSpecialized: number;
  } | null;
}

export interface ILeaseModel {
  header: ILeaseHeaderModel;
  renewalOptions: IRenewOptionsModel[];
  purchaseOption: IPurchaseOptionModel;
  terminateOption: ITerminateOptionModel;
  basePayment: IPaymentModel;
  otherPayments: IPaymentModel[];
  amortizationSchedules: IAmortSchedModel;
  capOpTestResults: ICapOpTestResultsModel;
}

export interface ICapOpTestResultsModel {
  canTransfer: number;
  hasBargainPurchase: number;
  usefulLife: number;
  fairValue: number;
  isSpecialized: number;
}

export interface IRenewOptionsModel {
  id?: number;
  renewForHowLongValue?: number;
  renewForHowLongPeriod?: RenewalPeriodType;
  renewStartDt?: string;
  renewEndDt?: string;
  earliestNoticeDt?: string;
  latestNoticeDt?: string;
  reasonableToRenew: boolean;
}

export interface IAlertModel {
  shouldSendAlerts: boolean;
  nbrOfDays?: number;
  sendAlertDaysType?: AlertDaysType;
  sendAlertOnEarliestDate?: boolean;
}

export interface IPurchaseOptionModel {
  id?: number;
  reasonableToPurchase: boolean;
  earliestNoticeDt?: string;
  latestNoticeDt?: string;
  purchaseAmt?: number;
  canBargainPurchase: boolean;
}

export interface ITerminateOptionModel {
  id?: number;
  hasFee: boolean;
  feeAmt?: number;
}

export interface IPaymentModel {
  id?: number;
  paymentName: string;
  paymentOptionType?: PaymentOptionType;
  borrowingRate: number;
  transitionRate: number;
  beginningPrepaidDeferredRent: number;
  fairValue: number;
  isFixed?: boolean;
  incentiveAmount?: number;
  paymentDetail: IPaymentDetailModel[];
}

export interface IPaymentDetailModel {
  id?: number;
  paymentFrequency?: PaymentFrequencyType;
  paymentAmt: number;
  firstPaymentDt: string;
  lastPaymentDt: string;
  isIncrementPct?: boolean;
  incrementDecrementAmt: number;
}

export interface IProrationModel {
  amountDue: number;
  amountPaid: number;
  amountRemaining: number;
  periodEnd: string;
  periodStart: string;
  subtotal: number;
  tax: number | null;
  taxPercent: number | null;
  total: number;
}

export type AmortModels =
  | IAmort840Model[]
  | IAmort842IFRSModel[]
  | IAmortCapModel[];

export interface IAmort840Model extends IBaseAmortModel {
  expense: number;
  prepaidRent: number;
  cumulativeBalance: number;
  shortTermDeferredRent: number;
  shortTermPrepaidRent: number;
  longTermDeferredRent: number;
  longTermPrepaidRent: number;
}

export interface IAmort842IFRSModel extends IBaseAmortModel {
  expense: number;
  accumulatedDepreciation: number;
  assetLeaseExpense: number;
  depreciationExpense: number;
  grossAssetBalance: number;
  liabilityReduction: number;
  ltLeaseLiability: number;
  netAssetBalance: number;
  stLeaseLiability: number;
  totalLeaseExpense: number;
  totalLiability: number;
}

interface IBaseAmortModel {
  cash: number;
  year: number;
  month: number;
}

export interface IAmortCapModel extends IBaseAmortModel {
  interestExpense: number;
  liabilityReduction: number;
  totalLiability: number;
  stLeaseLiability: number;
  ltLeaseLiability: number;
  depreciationExpense: number;
  netAssetBalance: number;
  totalLeaseExpense: number;
  accumulatedDepreciation: number;
  grossAssetBalance: number;
}

export interface IAmortSchedModel {
  baserent: IPaymentTypeModel;
  fixedtotal: IPaymentTypeModel;
}

interface IPaymentTypeModel {
  840: IAmort840Model[];
  842: IAmort842IFRSModel[];
  capital: IAmortCapModel[];
  ifrs: IAmort842IFRSModel[];
}

export interface IJournalEntryModel {
  acctName: string;
  acctType: string;
  debit: number;
  credit: number;
}

export interface ITotalOrgJeExportModel extends IBaseJeExportModel {
  journalEntries: ITotalOrgJournalEntryModel[];
}

export interface IJournalEntryExportModel extends IBaseJeExportModel {
  leaseId: number;
  clientId?: number;
  assetName: string;
  journalEntries: IJournalEntryModel[];
}

interface IBaseJeExportModel {
  year: number;
  month: number;
  isCapital: boolean;
  jurisdiction: string;
}

export interface ITotalOrgJournalEntryModel {
  assetName: string;
  journalEntry: IJournalEntryModel[];
}

export interface IDisclosuresModel {
  amount: string;
  description: string;
}

export interface IJeExportModel {
  blob: Blob;
  cdHeader: string;
}

export interface IJournalEntryFilters {
  year?: number;
  month?: number;
  jurisdiction?: JurisdictionValue;
  isCapital?: boolean;
}

export interface IDisclosuresFilters {
  year?: number;
  startMonth?: number;
  endMonth?: number;
}

export interface ICompanySelectorRoleOption {
  id: number;
  name: string;
  userRole: number;
}

export enum AutocompleteType {
  ClientSelector,
}

export enum DashboardType {
  GRID,
  LIST,
}

export enum PaymentFrequencyType {
  MONTHLY = 1,
  QUARTERLY = 2,
  ONE_TIME = 6,
  ANNUAL = 3,
}

export enum PaymentOptionType {
  VARIES_CONSTANT = 1,
  VARIES_NOT_CONTANT = 2,
  SAME = 3,
}

export enum AlertDaysType {
  BEFORE = 1,
  AFTER = 2,
}

export enum RenewalPeriodType {
  DAYS = 1,
  WEEKS = 2,
  MONTHS = 3,
  YEARS = 4,
}

export enum JurisdictionValue {
  TOPIC_840 = '840',
  TOPIC_842 = '842',
  GAAP = 'GAAP',
  IFRS = 'IFRS',
}

export enum VerificationCodes {
  VerifyEmail = 1,
  SendVerificationCode = 2,
  ResetPassword = 3,
}

// export enum PlanSubscriptionTypes {
//   TRIAL = 'Free Trial',
//   BASIC = 'Basic',
//   PREMIUM = 'Premium',
//   ENTERPRISE = 'Enterprise',
// }

export enum PlanTier {
  TRIAL = 'Trial',
  BASIC = 'Basic',
  PREMIUM = 'Premium',
  ENTERPRISE = 'Enterprise',
  Legacy = 'Legacy',
}

export enum PremiumPlanSubTier {
  PREMIUM_1 = 'Premium 1',
  PREMIUM_2 = 'Premium 2',
  PREMIUM_3 = 'Premium 3',
  PREMIUM_4 = 'Premium 4',
  PREMIUM_5 = 'Premium 5',
}

export type AssetTypes =
  | 'Land'
  | 'Building'
  | 'Vehicle'
  | 'Equipment'
  | 'Other';

export type AmendTypes = 'MODIFY' | 'OVERRIDE' | 'RENEW' | 'DUPLICATE';

export interface ISageJournalEntryLines {
  acctType: string;
  transactionAmount: number;
  departmentId?: string;
  allocationId?: string;
  memo?: string;
  locationId: string;
}

export interface ISageJournalEntryExportModel {
  journalSymbol?: string;
  postingDate?: string;
  referenceNumber?: string;
  desctiption?: string;
  journalEntryLines: ISageJournalEntryLines[];
}

export enum DropdownHeaderItemKeys {
  profile = 'profile',
  companySettings = 'companySettings',
  addUsers = 'addUsers',
  subscriptionSettings = 'subscriptionSettings',
  externalTools = 'externalTools',
  logout = 'logout',
  upgradeYourPlan = 'upgradeYourPlan',
}

export interface IHeaderDropdownItem {
  label: string;
  key: string;
  clickAction?: () => void;
  lastOfSection?: boolean;
  icon?: React.ReactNode;
  highlightLabel?: boolean;
}

export enum FeatureFlagKeys {
  PlansAndPricing = 'Guru_Plans_Pricing',
  GuruCPA = 'Guru_CPA',
  Disclosures_ExportWithLeaseQuery = 'Guru_Show_Disclosure_Export_With_LeaseQuery',
  FutureJournalEntries = 'Guru_Future_Journal_Entries',
}

export interface ICPACompanyDetail {
  id: number;
  name: string;
  leaseCount: number;
}
