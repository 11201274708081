import { IJournalEntryModel } from '../../../interfaces/index';

export const checkBalance = (entries: IJournalEntryModel[]) => {
  let isBalanced = false;
  let totalCreditSum: number = 0;
  let totalDebitSum: number = 0;
  entries.forEach((e: IJournalEntryModel) => {
    totalCreditSum += e.credit;
    totalDebitSum += e.debit;
  });
  const totalCreditToFixed: string = totalCreditSum.toFixed(2);
  const totalDebitToFixed: string = totalDebitSum.toFixed(2);
  if (totalCreditToFixed === totalDebitToFixed) {
    isBalanced = true;
  }
  return { totalCreditSum, totalDebitSum, isBalanced };
};
