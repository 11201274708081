import React from 'react';
import TagManager from 'react-gtm-module';
import Clock from '../../assets/clock.svg';
import Link from '../../assets/link.svg';
import RSMLink from '../../assets/rsmlink.svg';
// import Star from '../../assets/star.svg';
import './QuickLinks.scss';

import { ILeaseHeaderModel } from '../../interfaces';

interface IQuickLinksProps {
  leaseList: ILeaseHeaderModel[];
  setFilterHeader: (title: string) => void;
  updateQuickFilter: (isSet: boolean, filter: string) => void;
}

const QuickLinks = ({
  leaseList,
  setFilterHeader,
  updateQuickFilter,
}: IQuickLinksProps) => {
  const numOfExpiredLeases = leaseList.filter((item, index) => {
    return item.leaseStatus === 'Expired';
  }).length;

  const numOfExpiringLeases = leaseList.filter((item, index) => {
    return item.leaseStatus === 'Expires Soon';
  }).length;

  const recentlyViewedText: string = 'Recently Viewed';
  const expiredText: string = 'Expired';
  const expiringIn90DaysText: string = 'Expiring in 0-90 days';
  const tagManagerHandler = (linkText: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'navigation_link_click',
        nav_menu: 'Quicklinks',
        link_text: linkText,
      },
    });
  };

  return (
    <div className="quick-links-wrapper">
      <div className="quick-links-header">
        <div className="my-leases">
          <div className="label-box">
            <label>My Leases</label>
          </div>
        </div>
        <div className="quick-links-section">
          <div className="title">
            <img
              src={`${
                process.env.REACT_APP_ENABLE_RSM === 'true' ? RSMLink : Link
              }`}
              alt="link"
              className="quick-links-image"
            />
            <label>QUICKLINKS</label>
          </div>
          <div
            className="quick-link"
            onClick={() => {
              updateQuickFilter(true, 'recently-viewed');
              setFilterHeader(recentlyViewedText);
              tagManagerHandler(recentlyViewedText);
            }}
          >
            <img src={Clock} alt="link" />
            <label>{recentlyViewedText}</label>
          </div>
          <div
            className="quick-link"
            onClick={() => {
              updateQuickFilter(true, 'expired');
              setFilterHeader(expiredText);
              tagManagerHandler(expiredText);
            }}
          >
            <span className="expired-count">{numOfExpiredLeases}</span>
            <label>{expiredText}</label>
          </div>
          <div
            className="quick-link"
            onClick={() => {
              updateQuickFilter(true, 'expiring');
              setFilterHeader(expiringIn90DaysText);
              tagManagerHandler(expiringIn90DaysText);
            }}
          >
            <span className="expiring-count">{numOfExpiringLeases}</span>
            <label>{expiringIn90DaysText}</label>
          </div>
        </div>
      </div>
    </div>
  );
};
export default QuickLinks;
