import { Form, FormikContext } from 'formik';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Input } from '../../../common';
import { AccentedHeader } from '../../../common/headers';
import { useResourceContext } from '../../../common/resource-context';
import { fieldHasError } from '../../../common/utilities/FormikHelpers';
import { AuthCard, AuthCardSection } from '../../auth/AuthCard';
import WelcomeFooter from './WelcomeFooter';

import './WelcomeForm.scss';

export interface IWelcomeFormState {
  emailAddress: string;
}

interface IWelcomeFormProps {
  formProps: FormikContext<IWelcomeFormState>;
  errorMessage?: string;
  fromLinkManager?: boolean;
  buyNow?: boolean;
}

const WelcomeForm: FunctionComponent<IWelcomeFormProps> = ({
  formProps,
  fromLinkManager,
  errorMessage,
  buyNow,
}) => {
  const resources = useResourceContext();
  const WELCOME_TEXT = `Please enter your company email address below to get started.`;
  const BUY_NOW_TEXT = () => {
    return (
      <>
        <p>
          Enter your company email address to sign up for{' '}
          <b>LeaseGuru Premium</b>.
        </p>
        <p>
          Or{' '}
          <Link to="/sign-in" className="sign-in-link">
            login
          </Link>{' '}
          to upgrade an existing account.
        </p>
      </>
    );
  };

  return (
    <Form noValidate={true} className="welcome-form">
      <AuthCard>
        <AuthCardSection>
          <AccentedHeader color="blue" centered>
            We're glad you’re here!
          </AccentedHeader>
          {!fromLinkManager && !buyNow && <p>{WELCOME_TEXT}</p>}
          {buyNow && (
            <>
              <p>{BUY_NOW_TEXT()}</p>
            </>
          )}

          {errorMessage ? <p className="error">{errorMessage}</p> : null}
          <br />
          <br />
          {!fromLinkManager ? (
            <Input
              label="Company Email Address"
              name="emailAddress"
              type="email"
              value={formProps.values.emailAddress}
              error={fieldHasError(formProps, 'emailAddress')}
              errorMessage={formProps.errors.emailAddress}
              onChange={formProps.handleChange}
              placeholder="email@company.com"
            />
          ) : null}
        </AuthCardSection>
        <div className="img-container">
          <img
            src={resources.img('WelcomeForm_Image')}
            alt="cityscape"
            className="cityscape"
          />
        </div>
        <WelcomeFooter />
      </AuthCard>
    </Form>
  );
};

export default WelcomeForm;
