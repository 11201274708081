import {
  ClientIntegration,
  IJeExportModel,
  ISageJournalEntryExportModel,
} from '../interfaces';
import { formatError, integrationApi } from './api';

const ClientNames = {
  4: 'SageIntacct',
  6: 'Quickbooks',
  7: 'Xero',
};

const getUserToken = () => sessionStorage.getItem('userToken');

interface IIntegrationResponse {
  data: {
    message: string;
  };
}

export interface IVerifyAuthResponse {
  targetApplication: 'sageIntacct';
}

export async function integrationAuth<T>(
  integrationType: ClientIntegration,
  data: T,
) {
  try {
    const rawResponse = await integrationApi.post(
      `/Connect/${ClientNames[integrationType]}`,
      data,
      {
        headers: { Authorization: `${getUserToken()}` },
      },
    );

    return rawResponse.statusText;
  } catch (error) {
    throw error.response;
  }
}

export async function getAuthorizationUrl(integrationType: ClientIntegration) {
  let response;

  const rawResponse = await integrationApi.get(
    `/AuthorizationUrl/${ClientNames[integrationType]}`,
    {
      headers: { Authorization: `${getUserToken()}` },
    },
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }
  response = rawResponse.data;

  return response;
}

export async function redirectToAuthorizationUrl(
  integrationType: ClientIntegration,
) {
  const authUrl = await getAuthorizationUrl(integrationType);

  window.open(authUrl, '_self');
}

export async function verifyIntegrationAuth(): Promise<IVerifyAuthResponse> {
  try {
    const rawResponse = await integrationApi.get(`/ValidateLogin`, {
      headers: { Authorization: `${getUserToken()}` },
      withCredentials: true,
    });
    if (rawResponse.status >= 300) {
      throw formatError(rawResponse);
    }
    return rawResponse.data;
  } catch (error) {
    if (error.response.status !== 400) {
      throw formatError(error.response);
    }
    return error;
  }
}

export async function getAccountList(integrationType: ClientIntegration) {
  let response;
  try {
    const rawResponse = await integrationApi.get(`/AccountList`, {
      headers: { Authorization: `${getUserToken()}` },
      withCredentials: true,
    });

    if (rawResponse.status >= 300) {
      throw formatError(rawResponse);
    }

    response = rawResponse.data;
  } catch (error) {
    if (
      error.response?.data === 'Missing Token' ||
      error.response?.status === 401
    ) {
      await redirectToAuthorizationUrl(integrationType);
    }
  }

  return response;
}

export async function disconnectApplication() {
  try {
    const rawResponse = await integrationApi.get(`/Disconnect`, {
      headers: { Authorization: `${getUserToken()}` },
      withCredentials: true,
    });
    if (rawResponse.status >= 300) {
      throw formatError(rawResponse);
    }
    return rawResponse.status;
  } catch (error) {
    throw formatError(error.response);
  }
}

export async function submitIntegrationAccountSettings<T>(data: T) {
  let response;

  const rawResponse = await integrationApi.post(
    `/AccountCrossReference`,
    data,
    { headers: { Authorization: `${getUserToken()}` } },
  );

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }
  response = rawResponse.data;

  return response;
}

export async function getMappedIntegrationSettings() {
  let response;

  const rawResponse = await integrationApi.get(`/AccountCrossReference`, {
    headers: { Authorization: `${getUserToken()}` },
  });

  if (rawResponse.status >= 300) {
    throw formatError(rawResponse);
  }
  response = rawResponse.data;

  return response;
}

export async function exportJournalEntryIntegration(
  integrationType: ClientIntegration,
  journalEntry: ISageJournalEntryExportModel,
  clientId?: number,
): Promise<IJeExportModel> {
  let response;

  try {
    const rawResponse = await integrationApi.post(
      `/CreateJournalEntry`,
      { ...journalEntry, clientId },
      { headers: { Authorization: `${getUserToken()}` } },
    );

    if (rawResponse.status >= 300) {
      throw formatError(rawResponse);
    }

    response = rawResponse.data;
  } catch (error) {
    if (
      error.response?.data === 'Missing Token' ||
      error.response?.status === 401
    ) {
      await redirectToAuthorizationUrl(integrationType);
    }
  }

  return response;
}
