import { Form, FormikContext } from 'formik';
import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';

import { Input } from '../../common';
import { Button, ButtonGroup } from '../../common/buttons';
import { AccentedHeader } from '../../common/headers';
import {
  fieldHasError,
  shouldDisableSubmit,
} from '../../common/utilities/FormikHelpers';
import { StepNumber } from '../createAccount/schemas';
import { AuthCard, AuthCardDivider, AuthCardSection } from './AuthCard';

import './VerificationForm.scss';

export interface IVerificationFormState {
  verificationCode: string;
}

interface IVerificationFormProps {
  formProps: FormikContext<IVerificationFormState>;
  emailAddress: string;
  setStepNumber: Dispatch<SetStateAction<number>>;
  stepNumber: number;
  errorMessage?: string;
  processing?: boolean;
  buyNow?: boolean;
  isMulti?: boolean;
}

const VerificationForm: FunctionComponent<IVerificationFormProps> = ({
  formProps,
  emailAddress,
  setStepNumber,
  stepNumber,
  errorMessage,
  processing,
  buyNow,
  isMulti,
}) => {
  return (
    <Form
      noValidate={true}
      className={`verification-form-wrapper ${buyNow && 'buy-now'}`}
    >
      <AuthCard>
        <AuthCardSection>
          <AccentedHeader color="blue" centered>
            Verification Sent
          </AccentedHeader>
          <div className="verification-messaging">
            <p>
              Please check your email at <b>{emailAddress}</b>
            </p>
            <p>
              If you have not received an email, one of the following may have
              happened:
            </p>
          </div>
          <ul>
            {stepNumber === StepNumber.Verify ? (
              <li>You entered your email incorrectly</li>
            ) : (
              <li>A matching account does not exist</li>
            )}
            <li>The email was sent to your spam folder</li>
          </ul>
          {errorMessage ? (
            <>
              <br />
              <p className="error">{errorMessage}</p>
            </>
          ) : null}
        </AuthCardSection>
        <AuthCardDivider />
        <AuthCardSection>
          <div>
            <Input
              label="Verification Code"
              name="verificationCode"
              type="verificationCode"
              value={formProps.values.verificationCode}
              error={fieldHasError(formProps, 'verificationCode')}
              errorMessage={formProps.errors.verificationCode}
              onChange={formProps.handleChange}
            />
          </div>
        </AuthCardSection>
        <AuthCardDivider />
        <AuthCardSection>
          <ButtonGroup>
            <Button
              buttonType="neutral"
              buttonText="Back"
              onClick={() => {
                // forgot password
                if (stepNumber === StepNumber.Welcome) {
                  setStepNumber(stepNumber - 1);
                  // create account
                } else if (stepNumber === StepNumber.Verify) {
                  if (isMulti) {
                    setStepNumber(StepNumber.Credentials);
                  } else {
                    setStepNumber(StepNumber.DateInfo);
                  }
                }
              }}
            />
            <Button
              buttonType="positive"
              buttonText={buyNow ? 'Continue to Checkout' : 'Confirm'}
              processing={processing}
              disabled={shouldDisableSubmit(formProps) || processing}
              type="submit"
            />
          </ButtonGroup>
        </AuthCardSection>
      </AuthCard>
    </Form>
  );
};

export default VerificationForm;
