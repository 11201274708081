import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { redirectToQuickBooksAuthUrl } from '../../api';
import { Button } from '../../common/buttons';
import { AccentedHeader } from '../../common/headers';
import Modal from '../../common/modal/Modal';

import './QuickBooksSync.scss';

import { IStore } from '../../store';

const QuickBooksSync = () => {
  const userCompanyData = useSelector((state: IStore) => {
    return state.user.company;
  });
  const [processing, setProcessing] = useState(false);

  const handleRedirectClick = () => {
    setProcessing(true);
    redirectToQuickBooksAuthUrl(userCompanyData.clientId!);
  };

  return (
    <Modal open={true} modalSize="small">
      <div className="qb-sync-wrapper">
        <AccentedHeader>Welcome Back!</AccentedHeader>
        <div>
          It looks like your QuickBooks login has expired or you have moved to a
          different computer.
        </div>
        <div>We will redirect you to the QuickBooks login screen.</div>
        <br />
        <Button
          buttonType="positive"
          buttonText="Sync to QuickBooks"
          onClick={handleRedirectClick}
          disabled={processing}
          processing={processing}
        />
      </div>
    </Modal>
  );
};

export default QuickBooksSync;
