import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProratedPriceForCurrentDate } from '../../api';

import Modal from '../../common/modal/Modal';
import { IModalSizes } from '../../common/modal/types';
import { CheckIsMultiClientAdmin } from '../../common/utilities/_authHelpers';
import { useFeatureFlags } from '../../featureQuery';
import {
  IPlanFeatureModel,
  IPlanModel,
  IProratedData,
  IUserModel,
} from '../../interfaces';
import { IStore } from '../../store';
import CalculatorPage from './CalculatorPage';
import { displayCurrency, formatPriceWithDecimal } from './helpers';
import { initEmptySelectedPlan } from './helpers/initEmptySelectedPlan';
import { calculateTax } from './helpers/taxHelpers';
import OldPaymentPage from './OldPaymentPage';
import PaymentConfirmationPage from './PaymentConfirmationPage';
import PaymentPage from './PaymentPage';
import TierPage from './TierPage';

interface ISubscriptionModalProps {
  open: boolean;
  initialSubscriptionModalPage?: SubscriptionModalPages;
  initialPlanFeatures?: IPlanFeatureModel[];
  initialSelectedPlan?: IPlanModel;
  customCloseButton?: JSX.Element;
}

interface SubscriptionPropConfig {
  [key: number]: {
    contentClass?: string;
    mainClass?: string;
    modalSize?: IModalSizes;
    title?: string;
  };
}

export enum SubscriptionModalPages {
  tier,
  calculator,
  payment,
  confirm,
}

const SubscriptionModal: React.FC<ISubscriptionModalProps> = ({
  initialSubscriptionModalPage,
  initialPlanFeatures,
  initialSelectedPlan,
  customCloseButton,
}) => {
  const [subscriptionModalPage, setSubscriptionModalPage] = useState<
    SubscriptionModalPages
  >(
    initialSubscriptionModalPage
      ? initialSubscriptionModalPage
      : SubscriptionModalPages.tier,
  );

  const [selectedPlan, setSelectedPlan] = useState<IPlanModel | undefined>(
    initialSelectedPlan ?? undefined,
  );

  const [additionalLeasesRequested, setAdditionalLeasesRequested] = useState<
    number
  >(0);

  const [selectedTierFeatures, setSelectedTierFeatures] = useState<
    IPlanFeatureModel[]
  >(initialPlanFeatures ? initialPlanFeatures : []);
  const [proratedAmount, setProratedAmount] = useState<number>(0);
  const [totalWithTax, setTotalWithTax] = useState<string | undefined>();
  const [taxAmount, setTaxAmount] = useState<number | undefined>();
  const [taxError, setTaxError] = useState<string | undefined>();
  const [currentProrationDate, setCurrentProrationDate] = useState<
    Date | undefined
  >();
  const [taxData, setTaxData] = useState<{ zip: string; country: string }>({
    zip: '',
    country: '',
  });
  const loggedInUser: IUserModel = useSelector((state: IStore) => state.user);
  const isMultiClientAdmin = CheckIsMultiClientAdmin(loggedInUser);

  useEffect(() => {
    // generate total
    if (taxAmount !== undefined && selectedPlan) {
      const subtotal =
        proratedAmount > 0
          ? formatPriceWithDecimal(proratedAmount)!
          : selectedPlan?.price;

      const calculatedTotal = subtotal + taxAmount;

      setTotalWithTax(displayCurrency(calculatedTotal));
    }
  }, [taxAmount, proratedAmount, selectedPlan]);

  const handleSetProratedPrice = useCallback(
    async (selectedStripePlanId: string, currentDate: Date) => {
      setCurrentProrationDate(currentDate);
      const proratedValues: IProratedData = await getProratedPriceForCurrentDate(
        selectedStripePlanId,
        currentDate,
      );

      if (proratedValues) {
        setProratedAmount(proratedValues.amountDue);
      }
    },
    [],
  );

  const handleSetSelectedPlan = useCallback(
    (selected: IPlanModel | undefined) => {
      setSelectedPlan(selected);
      const currentDate = new Date();
      if (selected) {
        handleSetProratedPrice(selected.stripePlanId, currentDate);
      }
    },
    [handleSetProratedPrice],
  );

  useEffect(() => {
    // if initialSelectedPlan is provided, set selected plan and calculate proration
    if (!isMultiClientAdmin) {
      handleSetSelectedPlan(initialSelectedPlan);
    }
  }, [handleSetSelectedPlan, initialSelectedPlan, isMultiClientAdmin]);

  const handleSetTaxAmount = useCallback(
    async (amount: number) => {
      const { zip, country } = taxData;
      if (zip.length === 5 && country === 'US') {
        const taxResponse: any = await calculateTax(amount, taxData?.zip);

        if (taxResponse.error) {
          setTaxError(taxResponse.error);
          setTaxAmount(undefined);
          return;
        }

        setTaxAmount(taxResponse.tax);
      } else if (country !== 'US' && !!country && zip.length > 0) {
        // no tax for countries other than USA
        setTaxAmount(0);
      } else {
        // default display
        setTaxAmount(undefined);
        setTaxError(undefined);
        setTotalWithTax(undefined);
      }
    },
    [taxData],
  );

  useEffect(() => {
    // on plan/country/zip change, calculate and apply tax
    const prorated =
      proratedAmount > 0 ? formatPriceWithDecimal(proratedAmount) : undefined;

    const amount = prorated ?? selectedPlan?.price!;
    handleSetTaxAmount(amount);
  }, [taxData, proratedAmount, selectedPlan, handleSetTaxAmount]);

  const handleNavigation = (navigateTo: SubscriptionModalPages) => {
    setSubscriptionModalPage(navigateTo);
  };

  const { getSingleFlag } = useFeatureFlags();
  const guruPlanPricingFlag = getSingleFlag('Guru_Plans_Pricing');

  const subscriptionPropConfig: SubscriptionPropConfig = {
    [SubscriptionModalPages.calculator]: {
      contentClass: 'calculator-modal',
      mainClass: 'calculator-modal-main',
      title: '',
    },

    [SubscriptionModalPages.payment]: {
      contentClass: guruPlanPricingFlag ? 'payment-modal' : undefined,
    },

    [SubscriptionModalPages.confirm]: {
      modalSize: 'small',
    },

    [SubscriptionModalPages.tier]: {
      title: 'Plans and Pricing',
    },
  };

  const handlePageSelection = () => {
    switch (subscriptionModalPage) {
      case SubscriptionModalPages.calculator:
        return (
          <CalculatorPage
            setSelectedPlan={handleSetSelectedPlan}
            selectedPlan={selectedPlan}
            setNavigation={handleNavigation}
            tierFeatures={selectedTierFeatures}
            setAdditionalLeasesRequested={setAdditionalLeasesRequested}
            additionalLeasesRequested={additionalLeasesRequested}
          />
        );

      case SubscriptionModalPages.payment:
        return guruPlanPricingFlag ? (
          <PaymentPage
            setSelectedPlan={handleSetSelectedPlan}
            proratedPrice={formatPriceWithDecimal(proratedAmount)}
            currentProrationDate={currentProrationDate}
            selectedPlan={selectedPlan ?? initEmptySelectedPlan()}
            setNavigation={handleNavigation}
            setTaxData={setTaxData}
            taxAmount={taxAmount}
            taxErrorText={taxError}
            totalWithTax={totalWithTax}
          />
        ) : (
          <OldPaymentPage
            proratedPrice={formatPriceWithDecimal(proratedAmount)}
            selectedPlan={selectedPlan ?? initEmptySelectedPlan()}
            setNavigation={handleNavigation}
            setTotalWithTax={setTotalWithTax}
          />
        );
      case SubscriptionModalPages.tier:
        return (
          <TierPage
            setSelectedTierFeatures={setSelectedTierFeatures}
            setNavigation={handleNavigation}
          />
        );
      case SubscriptionModalPages.confirm:
        return <PaymentConfirmationPage totalWithTax={totalWithTax} />;
    }
  };

  return (
    <Modal
      title={subscriptionPropConfig[subscriptionModalPage].title || 'Checkout'}
      open={true}
      modalSize={
        subscriptionPropConfig[subscriptionModalPage].modalSize || 'large'
      }
      showCloseButton
      mainClass={subscriptionPropConfig[subscriptionModalPage].mainClass}
      contentClass={subscriptionPropConfig[subscriptionModalPage].contentClass}
      customCloseButton={customCloseButton}
    >
      {handlePageSelection()}
    </Modal>
  );
};

export default SubscriptionModal;
