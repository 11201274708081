import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import {
  editCompany,
  redirectToQuickBooksAuthUrl,
  revokeQBAuthToken,
} from '../../api';
import lock from '../../assets/bluelock.svg';
import { InlineButton } from '../../common/buttons';
import { hideModal, showModal } from '../../common/modal/actions';
import { ClientIntegration, ICompanyModel } from '../../interfaces';
import { updateUserCompanySettings } from '../auth/actions';

interface IQuickBooksERPSectionProps {
  userCompanyData: ICompanyModel;
  userHasAccess: boolean;
}

const QuickBooksERPSection: FunctionComponent<IQuickBooksERPSectionProps> = ({
  userCompanyData,
  userHasAccess,
}) => {
  const dispatch = useDispatch();
  const handleQuickBooksConnect = async () => {
    // currently only allowing a single integration although API allows for more than 1
    redirectToQuickBooksAuthUrl(userCompanyData.clientId!);
  };

  const handleQuickbooksManageAccounts = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'QB_SETTINGS',
          modalProps: {
            open: true,
            hideModal: () => dispatch(hideModal()),
          },
        },
      }),
    );
  };

  const showQBConfirmationModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'EXTERNAL_TOOLS_CONFIRMATION',
          modalProps: {
            open: true,
            hideModal: () => dispatch(hideModal()),
            confirmAction: handleQuickbooksDisconnect,
          },
        },
      }),
    );
  };

  const handleQuickbooksDisconnect = async () => {
    await editCompany({
      ...userCompanyData,
      clientIntegrations: userCompanyData.clientIntegrations?.filter(
        (ci) => ci !== ClientIntegration.QUICKBOOKS,
      ),
    });
    await revokeQBAuthToken();
    dispatch(
      updateUserCompanySettings({
        ...userCompanyData,
        clientIntegrations: [],
      }),
    );
    dispatch(hideModal());
  };

  return (
    <div className="erp-section">
      <img
        src={require(`../../assets/quickbooks-logo.svg`)}
        alt="quickbooks-img"
      />
      <div className="erp-buttons">
        {!!userCompanyData.clientIntegrations &&
        userCompanyData.clientIntegrations?.findIndex(
          (integration) => integration === ClientIntegration.QUICKBOOKS,
        ) !== -1 ? (
          <>
            <InlineButton
              buttonText="manage accounts"
              onClick={handleQuickbooksManageAccounts}
              type="button"
            />
            <InlineButton
              buttonText="disconnect from quickbooks"
              onClick={showQBConfirmationModal}
              type="button"
            />
          </>
        ) : (
          <>
            <button
              onClick={() => {
                if (userHasAccess) {
                  handleQuickBooksConnect();
                }
              }}
              className="erp-branded-button"
              disabled={userCompanyData.clientIntegrations?.some(
                (int) => int !== ClientIntegration.QUICKBOOKS,
              )}
            >
              <img
                src={require('../../assets/quickbooks-connect-btn.png')}
                alt="connect-quickbooks-img"
              />
            </button>

            {!userHasAccess && (
              <img src={lock} alt="header-lock" className="header-lock" />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default QuickBooksERPSection;
