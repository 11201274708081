import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import {
  disconnectApplication,
  editCompany,
  integrationAuth,
  redirectToAuthorizationUrl,
  verifyIntegrationAuth,
} from '../../api';
import lock from '../../assets/bluelock.svg';
import { InlineButton } from '../../common/buttons';
import { hideModal, showModal } from '../../common/modal/actions';
import { ClientIntegration, ICompanyModel } from '../../interfaces';
import { updateUserCompanySettings } from '../auth/actions';

interface IXeroERPSectionProps {
  userCompanyData: ICompanyModel;
  userHasAccess: boolean;
}

const XeroERPSection: FunctionComponent<IXeroERPSectionProps> = ({
  userCompanyData,
  userHasAccess,
}) => {
  const dispatch = useDispatch();

  const showPopup = (data: any) => {
    dispatch(
      showModal({
        modal: {
          modalType: 'CONFIRMATION',
          modalProps: {
            open: true,
            hideModal: () =>
              dispatch({
                type: 'HIDE_MODAL',
              }),
            confirmAction: () => {
              dispatch({
                type: 'HIDE_MODAL',
              });
            },
            confirmText: `${data}`,
            buttonText: 'Ok',
          },
        },
      }),
    );
  };

  const handleConnect = () => {
    const integrationType = ClientIntegration.XERO;

    editCompany({
      ...userCompanyData,
      clientIntegrations: [integrationType],
    });
    integrationAuth(integrationType, {});
    redirectToAuthorizationUrl(integrationType);
  };

  const handleManageAccounts = async () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'INTEGRATIONS_SETTINGS',
          modalProps: {
            open: true,
            hideModal: () => dispatch(hideModal()),
            otherProps: {
              clientIntegration: ClientIntegration.XERO,
            },
          },
        },
      }),
    );
  };

  const handleValidateLogin = async () => {
    const result = await verifyIntegrationAuth();
    showPopup(JSON.stringify(result, null, 2));
  };

  const handleDisconnect = async () => {
    await editCompany({
      ...userCompanyData,
      clientIntegrations: userCompanyData.clientIntegrations?.filter(
        (ci) => ci !== ClientIntegration.XERO,
      ),
    });
    await disconnectApplication();
    dispatch(
      updateUserCompanySettings({
        ...userCompanyData,
        clientIntegrations: [],
      }),
    );
    dispatch(hideModal());
  };

  return (
    <div className="erp-section">
      <img src={require(`../../assets/xerologo.svg`)} alt="sage-img" />
      <div className="erp-buttons">
        {!!userCompanyData.clientIntegrations &&
        userCompanyData.clientIntegrations?.findIndex(
          (integration) => integration === ClientIntegration.XERO,
        ) !== -1 ? (
          <>
            <InlineButton
              buttonText="validateLogin"
              onClick={handleValidateLogin}
              type="button"
            />
            <InlineButton
              buttonText="Manage Accounts"
              onClick={handleManageAccounts}
              type="button"
            />
            <InlineButton
              buttonText="Disconnect"
              onClick={handleDisconnect}
              type="button"
            />
          </>
        ) : (
          <>
            <InlineButton
              buttonText="Connect"
              onClick={() => {
                if (userHasAccess) {
                  handleConnect();
                }
              }}
              type="button"
              disabled={userCompanyData.clientIntegrations?.some(
                (int) => int !== ClientIntegration.XERO,
              )}
            />

            {!userHasAccess && (
              <img src={lock} alt="header-lock" className="header-lock" />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default XeroERPSection;
