import { ICompanyModel, IUserModel } from '../../interfaces';

export enum UserActions {
  SAVE_USER = 'SAVE_USER',
  CLEAR_USER = 'CLEAR_USER',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_COMPANY = 'UPDATE_USER_COMPANY',
  UPDATE_USER_COMPANY_SETTINGS = 'UPDATE_USER_COMPANY_SETTINGS',
}

export const saveUser = (user: IUserModel) => ({
  type: UserActions.SAVE_USER,
  payload: { user },
});

export const clearUser = () => ({
  type: UserActions.CLEAR_USER,
});

export const updateUserCompanySettings = (userCompany: ICompanyModel) => ({
  type: UserActions.UPDATE_USER_COMPANY_SETTINGS,
  payload: { userCompany },
});
