import { IPlanModel } from '../../interfaces';

export enum PlanActions {
  STORE_PLANS = 'STORE_PLANS',
  CLEAR_PLANS = 'CLEAR_PLANS',
}

export const storePlans = (plans: IPlanModel[]) => ({
  type: PlanActions.STORE_PLANS,
  payload: { plans },
});

export const clearPlans = () => ({
  type: PlanActions.CLEAR_PLANS,
});
