import { IsCapitalValues } from '../../features/lease/add-lease-forms/GeneralForm';
import { SubscriptionModalPages } from '../../features/subscriptions/SubscriptionModal';
import {
  AmendTypes,
  IJournalEntryExportModel,
  IPlanFeatureModel,
  IPlanModel,
  ISageJournalEntryExportModel,
  ITotalOrgJeExportModel,
} from '../../interfaces';
import { ClientIntegration } from './../../interfaces/index';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_STEPPED_MODAL = 'SHOW_STEPPED_MODAL';
export const HIDE_STEPPED_MODAL = 'HIDE_STEPPED_MODAL';
export const CHANGE_MODAL_PROP = 'CHANGE_MODAL_PROP';

export type ModalTypes =
  | 'USER_SETTINGS'
  | 'MANAGE_USERS'
  | 'COMPANY_SETTINGS'
  | 'UPGRADE_TIER'
  | 'SUBSCRIPTIONS'
  | 'UPDATE_PAYMENT'
  | 'UPDATE_PAYMENT_CONFIRMATION'
  | 'SUBSCRIPTION_SETTINGS'
  | 'CHOOSE_ICON'
  | 'STEPPED_MODAL'
  | 'AMEND_TYPE'
  | 'CONFIRMATION'
  | 'TERMINATION'
  | 'CAP_OP_RESULTS'
  | 'REGENERATING_FINANCIALS'
  | 'QB_SETTINGS'
  | 'QB_SYNC'
  | 'QB_EXPORT_JE'
  | 'QB_TOTALORG_EXPORT_JE'
  | 'QB_TOTALORG_CONFIRMATION'
  | 'EXTERNAL_TOOLS'
  | 'EXTERNAL_TOOLS_CONFIRMATION'
  | 'SAGE_LOGIN'
  | 'INTEGRATIONS_SETTINGS'
  | 'INTEGRATIONS_EXPORT_JE'
  | 'LEASE_GENERAL_DETAILS'
  | 'CREATE_ACCOUNT';

export interface IModal {
  modalType?: ModalTypes;
  modalProps?: IModalProps;
}

export interface IModalProps {
  open: boolean;
  hideModal?: () => void;
  otherProps?: {
    effectiveDate?: string;
    capOpTestResults?: {
      canTransfer: number;
      hasBargainPurchase: number;
      usefulLife: number;
      fairValue: number;
      isSpecialized: number;
    } | null;
    leaseData?: any;
    isCapital?: IsCapitalValues;
    leaseId?: number;
    journalEntry?: IJournalEntryExportModel | ISageJournalEntryExportModel;
    journalEntryTotalOrg?: ITotalOrgJeExportModel;
    exportJE?: string[];
    clientIntegration?: ClientIntegration;
  };
  title?: string;
  context?: any;
  amendType?: AmendTypes;
  confirmAction?: () => void;
  confirmText?: string | JSX.Element;
  areYouSureText?: boolean;
  buttonText?: string;
  buyNowSelectedPlan?: string;
  initialSubscriptionModalPage?: SubscriptionModalPages;
  initialPlanFeatures?: IPlanFeatureModel[];
  initialSelectedPlan?: IPlanModel;
  customCloseButton?: JSX.Element;
  isCreateClientOnly?: boolean;
}

export interface IModalAction {
  [key: string]: IModal;
  modal: IModal;
}

export type IModalSizes = 'vertical' | 'small' | 'medium' | 'large' | 'xlarge';

interface IShowModalAction {
  type: typeof SHOW_MODAL;
  modal: IModal;
}

interface IHideModalAction {
  type: typeof HIDE_MODAL;
}

interface IShowSteppedModalAction {
  type: typeof SHOW_STEPPED_MODAL;
  modal: IModal;
}

interface IHideSteppedModalAction {
  type: typeof HIDE_STEPPED_MODAL;
}

interface IChangeModalPropAction {
  type: typeof CHANGE_MODAL_PROP;
  property: keyof IModalProps;
  value: any;
}

export type ModalActionTypes =
  | IShowModalAction
  | IHideModalAction
  | IShowSteppedModalAction
  | IHideSteppedModalAction
  | IChangeModalPropAction;
