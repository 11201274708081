import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Input } from '../../common';

import { Button } from '../../common/buttons';
import {
  IPlanFeatureModel,
  IPlanModel,
  PlanFeatureNames,
  PlanTier,
} from '../../interfaces';
import { IStore } from '../../store';
import {
  getFeatureValue,
  getMaxLeasePlan,
  getPlanByNumberOfLeases,
  getPlanTierPriceRange,
  getTierFeatureList,
} from '../plans/helpers';
import { SubscriptionModalPages } from './SubscriptionModal';
import TierCard from './TierCard';

import { useResourceContext } from '../../common/resource-context';
import './CalculatorPage.scss';

interface ICalculatorPageProps {
  setNavigation: (navigateTo: SubscriptionModalPages) => void;
  setSelectedPlan: (selectedPlan: IPlanModel | undefined) => void;
  setAdditionalLeasesRequested: (inputLeases: number) => void;
  additionalLeasesRequested: number;
  tierFeatures: IPlanFeatureModel[];
  selectedPlan?: IPlanModel;
}

const CalculatorPage: React.FC<ICalculatorPageProps> = ({
  setNavigation,
  tierFeatures,
  setSelectedPlan,
  selectedPlan,
  setAdditionalLeasesRequested,
  additionalLeasesRequested,
}) => {
  const resources = useResourceContext();
  const [enterpriseUpgradeRequired, setEnterpriseUpgradeRequired] = useState(
    false,
  );

  const plans = useSelector((store: IStore) => {
    return store.plans;
  });

  const currentUserPlan = useSelector((state: IStore) => {
    return state.user.company.plan;
  });

  const selectedPlanNumberOfLeases =
    selectedPlan &&
    Number(getFeatureValue(PlanFeatureNames.leaseCount, selectedPlan.features));

  const currentMaxLeaseCount: number = currentUserPlan?.features
    ? Number(
        getFeatureValue(PlanFeatureNames.leaseCount, currentUserPlan.features),
      )
    : 0;

  const premiumTierPlanPrice = getPlanTierPriceRange(plans, PlanTier.PREMIUM);

  const handleLeaseCountInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const inputLeaseCount = e.target.value ? parseInt(e.target.value, 10) : 0;

    if (inputLeaseCount === 0) {
      setSelectedPlan(undefined);
    } else {
      const leasesNeeded = inputLeaseCount + currentMaxLeaseCount;

      const planNeeded = getPlanByNumberOfLeases(
        plans,
        leasesNeeded,
        PlanTier.PREMIUM,
      );

      if (planNeeded) {
        // if a plan is found that fits their lease needs, select that plan
        setSelectedPlan(planNeeded);
        setEnterpriseUpgradeRequired(false);
      } else {
        // if a plan isn't found to meet their needs, get max lease plan
        const maxLeasePlan = getMaxLeasePlan(plans);

        setSelectedPlan(maxLeasePlan);

        // notify user that an upgrade to enterprise is required
        setEnterpriseUpgradeRequired(true);
      }
    }

    setAdditionalLeasesRequested(inputLeaseCount);
  };

  const getPlanPriceToShow = () => {
    if (selectedPlan && selectedPlan.price) {
      return `$${selectedPlan.price}`;
    }

    if (currentUserPlan && currentUserPlan.price >= 0) {
      return `$${currentUserPlan.price}`;
    }

    return '';
  };

  const getSelectedPlanLeaseCountToShow = () => {
    if (selectedPlan) {
      const selectedPlanLeaseCount = getFeatureValue(
        PlanFeatureNames.leaseCount,
        selectedPlan.features,
      );

      if (selectedPlanLeaseCount) {
        return selectedPlanLeaseCount;
      }
    }

    return '';
  };

  const navigateToEnterprise = () => {
    const enterpriseUrl = resources.text('EnterprisePricingUrl');
    window.open(enterpriseUrl, '_blank');
  };

  // get and set premium features if navigating directly to calculator, bypassing tier page
  const premiumTierFeatures = getTierFeatureList(PlanTier.PREMIUM, plans);
  if (tierFeatures.length === 0) {
    tierFeatures = premiumTierFeatures;
  }

  return (
    <>
      <div
        className={`wrapper ${process.env.REACT_APP_ENABLE_RSM === 'true' &&
          'rsm-class-calculator'}`}
      >
        <div className={'left-container'}>
          <div className={'top-wrapper'}>
            <span>Upgrade your plan for more leases!</span>
            <div className={'lease-calculator'}>
              <div className={'scale'}>
                <div className={'dot'} />

                <div className={'bar'} />

                <div className={'dot'} />

                <div className={'angle'} />
              </div>

              <div>
                <div className={'item'}>
                  <span className={'header'}>{currentMaxLeaseCount}</span>
                  <div className={'content'}>
                    Current
                    <br />
                    Plan Lease Count
                  </div>
                </div>

                <div className={'center-item'}>
                  <span>How many leases do you need to add?</span>
                  <Input
                    name="add-lease-input"
                    onChange={handleLeaseCountInputChange}
                    value={additionalLeasesRequested}
                    maxLength={4}
                    required
                  />
                </div>

                <div className={'item'}>
                  <span className={'header'}>
                    {currentMaxLeaseCount + additionalLeasesRequested}
                  </span>
                  <div className={'content'}>
                    Total Lease
                    <br />
                    Count
                  </div>
                </div>

                <span
                  className={'warning'}
                  style={{
                    visibility: enterpriseUpgradeRequired
                      ? 'initial'
                      : 'hidden',
                  }}
                >
                  There is currently a maximum lease capacity of{' '}
                  {selectedPlanNumberOfLeases} leases.
                </span>
              </div>
            </div>
            <div
              className="offering-side"
              style={{
                visibility: 'initial',
              }}
            >
              <button
                type={'button'}
                className={'modal-link'}
                onClick={() => {
                  setNavigation(SubscriptionModalPages.tier);
                }}
              >
                Click here to see all of our offerings side-by-side
              </button>
            </div>
          </div>

          <div className={'bottom-wrapper'}>
            {enterpriseUpgradeRequired ? (
              <>
                <div>
                  <div>
                    <div className="offering-enterprise">
                      Looks like our <b>Enterprise Solution</b>
                      <br />
                      <span>is perfect for you!</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="calculator-button-wrap">
                    <Button
                      onClick={navigateToEnterprise}
                      buttonType={'positive'}
                      buttonText={'Request Pricing'}
                      disabled={false}
                      classes="calculator-button"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div>
                    Up to{' '}
                    {selectedPlan
                      ? getSelectedPlanLeaseCountToShow()
                      : currentMaxLeaseCount}{' '}
                    leases for :
                  </div>

                  <div>{getPlanPriceToShow()}</div>

                  <div>/ year, billed annually</div>
                </div>

                <div>
                  <div className="calculator-button-wrap">
                    {!!!selectedPlan ? (
                      <Button
                        buttonType="positive"
                        buttonText={'Current Plan'}
                        classes="calculator-button"
                        disabled={true}
                      />
                    ) : (
                      <Button
                        onClick={() => {
                          setSelectedPlan(selectedPlan);
                          setNavigation(SubscriptionModalPages.payment);
                        }}
                        buttonType="positive"
                        buttonText={'Upgrade'}
                        classes="calculator-button"
                        disabled={enterpriseUpgradeRequired}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className={'right-container'}>
          <span>Features includes...</span>
          <TierCard
            tierTitle="Premium"
            tierPrice={premiumTierPlanPrice}
            tierFeatures={tierFeatures}
            isCalculator={true}
            setNavigation={() =>
              setNavigation(SubscriptionModalPages.calculator)
            }
            upgradeText={
              <button
                type={'button'}
                className={'modal-link'}
                onClick={() => {
                  setNavigation(SubscriptionModalPages.tier);
                }}
              >
                See all of our offerings side-by-side
              </button>
            }
          />
        </div>
      </div>
    </>
  );
};

export default CalculatorPage;
