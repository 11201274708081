import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import { exportAmortSchedule } from '../../../../api';
import lock from '../../../../assets/bluelock.svg';
import { ReactComponent as CheckSolid } from '../../../../assets/check-solid.svg';
import { Dropdown } from '../../../../common';
import { ButtonLink } from '../../../../common/buttons';
import { hideModal, showModal } from '../../../../common/modal/actions';
import { monthOptions } from '../../../../common/utilities/_constants';
import { downloadFile } from '../../../../common/utilities/_helpers';
import { AmortModels, PlanFeatureNames } from '../../../../interfaces';
import { IStore } from '../../../../store';
import { checkIfUserHasAccessToFeature } from '../../../plans/helpers';
import '../table-styles.scss';
import {
  AmortSchedColNames,
  AmortSchedColType,
  amortSchedColumnConfiguration,
  AmortSchedColumnDefaults,
  Schedules,
  ScheduleTypes,
} from './amort-sched-columns';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

interface IProps {
  schedule: AmortModels | undefined;
  rateText: string;
  loading: boolean;
  getSchedule: (scheduleType: ScheduleTypes) => void;
  selectedSchedule: ScheduleTypes | undefined;
  jurisdiction?: string;
}

interface IColumnState {
  column: AmortSchedColType;
  active: boolean;
}

interface IScheduleDropItem {
  label: string;
  key: ScheduleTypes;
  clickAction: () => void;
}

interface IDropDownOptions {
  [key: string]: IScheduleDropItem[];
}

enum AmortDropdowns {
  EDIT_COLUMNS,
  SCHED_TYPE,
}

export const AmortSchedTable: FunctionComponent<IProps> = ({
  schedule,
  rateText,
  getSchedule,
  selectedSchedule,
  jurisdiction,
}) => {
  const [columnOptions, setColumnOptions] = useState<IColumnState[]>([]);
  const companySettings = useSelector((state: IStore) => state.user.company);
  const selectedLease = useSelector(
    (state: IStore) => state.leaseDetails.selectedLease,
  );
  const loggedInUser = useSelector((state: IStore) => state.user);

  const userCompanyData = loggedInUser.company;

  const features = userCompanyData.plan?.features;

  const userHasAccessToExportAmort = useMemo(
    () =>
      checkIfUserHasAccessToFeature(
        PlanFeatureNames.exportAmort,
        features,
        loggedInUser.userRole === 1,
      ),
    [features, loggedInUser],
  );

  useEffect(() => {
    if (selectedSchedule) {
      const columns = Schedules[selectedSchedule].columns;
      if (columns) {
        setColumnOptions(
          columns.map((col) => ({
            column: col,
            active: true,
          })),
        );
      }
    }
  }, [selectedSchedule]);

  const [open, setOpen] = useState<AmortDropdowns | null>();

  const dispatch = useDispatch();

  const tableColumns = amortSchedColumnConfiguration(
    columnOptions
      .filter((option) => option.active)
      .map((option) => option.column),
  );

  const onDropdownOpen = (dropdown: AmortDropdowns) => {
    setOpen(dropdown);
  };

  const onDropdownClose = () => {
    setOpen(null);
  };

  const editColDropOptions = columnOptions.map((option) => {
    const { active, column } = option;
    return (
      <li
        id={`Dropdown-menu-item-${AmortSchedColNames[column].name}`}
        className={`dropdown-menu--item link-text ${active ? '' : 'inactive'}`}
        key={AmortSchedColNames[column].name}
        onClick={() => {
          setColumnOptions(
            columnOptions.map((op) => {
              if (op.column === column) {
                return { ...op, active: !op.active };
              }
              return op;
            }),
          );
        }}
      >
        {active && <CheckSolid className="step-icon" />}{' '}
        {AmortSchedColNames[column].name}
      </li>
    );
  });

  const getDropdownOptions = () => {
    let optionsToMap: IScheduleDropItem[] = [];
    if (selectedSchedule) {
      if (
        selectedSchedule === ScheduleTypes.CAPITAL_BR ||
        selectedSchedule === ScheduleTypes.CAPITAL_FT
      ) {
        optionsToMap = dropDownOptions.capital;
      } else {
        jurisdiction && jurisdiction === 'GAAP'
          ? (optionsToMap = [...dropDownOptions[840], ...dropDownOptions[842]])
          : (optionsToMap = [...dropDownOptions.ifrs]);
      }
    }
    return mapSchedule(optionsToMap);
  };

  const mapSchedule = (dropItems: IScheduleDropItem[]) =>
    dropItems.map((item: IScheduleDropItem) => {
      return (
        <li
          id={`Dropdown-menu-item-${item.key}`}
          className="dropdown-menu--item link-text"
          key={item.key}
          onClick={item.clickAction}
        >
          {item.label}
        </li>
      );
    });

  const dropDownOptions: IDropDownOptions = {
    capital: [
      {
        label: 'Topic 840/IAS 17 Base Rent',
        key: ScheduleTypes.CAPITAL_BR,
        clickAction: () => {
          getSchedule(ScheduleTypes.CAPITAL_BR);
          setOpen(null);
        },
      },
      {
        label: 'Topic 840/IAS 17 Total Fixed Consideration',
        key: ScheduleTypes.CAPITAL_FT,
        clickAction: () => {
          getSchedule(ScheduleTypes.CAPITAL_FT);
          setOpen(null);
        },
      },
    ],
    840: [
      {
        label: 'Topic 840 Base Rent',
        key: ScheduleTypes.TOPIC_840BR,
        clickAction: () => {
          getSchedule(ScheduleTypes.TOPIC_840BR);
          setOpen(null);
        },
      },
      {
        label: 'Topic 840 Total Fixed Consideration',
        key: ScheduleTypes.TOPIC_840FT,
        clickAction: () => {
          getSchedule(ScheduleTypes.TOPIC_840FT);
          setOpen(null);
        },
      },
    ],
    ifrs: [
      {
        label: 'IFRS 16 Base Rent',
        key: ScheduleTypes.IFRS_16BR,
        clickAction: () => {
          getSchedule(ScheduleTypes.IFRS_16BR);
          setOpen(null);
        },
      },
      {
        label: 'IFRS 16 Total Fixed Consideration',
        key: ScheduleTypes.IFRS_16FT,
        clickAction: () => {
          getSchedule(ScheduleTypes.IFRS_16FT);
          setOpen(null);
        },
      },
    ],
    842: [
      {
        label: 'Topic 842 Base Rent',
        key: ScheduleTypes.TOPIC_842BR,
        clickAction: () => {
          getSchedule(ScheduleTypes.TOPIC_842BR);
          setOpen(null);
        },
      },
      {
        label: 'Topic 842 Total Fixed Consideration',
        key: ScheduleTypes.TOPIC_842FT,
        clickAction: () => {
          getSchedule(ScheduleTypes.TOPIC_842FT);
          setOpen(null);
        },
      },
    ],
  };

  const getMonthText = (monthOption: number) => {
    const month = monthOptions.find((option) => option.value === monthOption);
    return month ? month.label : '';
  };

  const getNoDataMessage = () => {
    let message = 'No schedule for this selection';
    if (
      companySettings &&
      companySettings.transitionMonth &&
      companySettings.transitionYear
    ) {
      if (
        selectedSchedule === ScheduleTypes.IFRS_16BR ||
        selectedSchedule === ScheduleTypes.IFRS_16FT
      ) {
        message = `There is no IFRS 16 schedule because this lease ended prior to the transition date of ${getMonthText(
          companySettings.transitionMonth,
        )} ${companySettings.transitionYear}`;
      } else if (
        selectedSchedule === ScheduleTypes.TOPIC_842BR ||
        selectedSchedule === ScheduleTypes.TOPIC_842FT
      ) {
        message = `There is no 842 schedule because this lease ended prior to the transition date of ${getMonthText(
          companySettings.transitionMonth,
        )} ${companySettings.transitionYear}`;
      }
    }
    return message;
  };

  const handleAmortExport = async () => {
    if (selectedLease) {
      const response = await exportAmortSchedule(selectedLease);
      const { blob, cdHeader } = response;
      downloadFile(blob, cdHeader);
    }
  };

  const showUpgradeModal = () => {
    dispatch(
      showModal({
        modal: {
          modalType: 'UPGRADE_TIER',
          modalProps: {
            open: true,
            hideModal: () => dispatch(hideModal()),
          },
        },
      }),
    );
  };

  const exportAndTagManagerHandler = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'amortization_schedule_interaction',
        amortization_schedule_action: 'Export to Excel',
      },
    });
    userHasAccessToExportAmort ? handleAmortExport() : showUpgradeModal();
  };

  return (
    <div>
      <div className="table-container">
        <div className="table-header">
          <div className="header-label">
            <div className="schedule-title">
              {selectedSchedule ? Schedules[selectedSchedule].title : ''}
            </div>
            <div>{rateText}</div>
          </div>
          <div className="header-actions">
            <ButtonLink
              id="selectAmortSchedule"
              onClick={() => onDropdownOpen(AmortDropdowns.SCHED_TYPE)}
              text="Select Schedule"
              styles="action link-text"
            />
            <div className="Dropdown">
              <Dropdown
                open={open === AmortDropdowns.SCHED_TYPE}
                onClose={onDropdownClose}
              >
                {getDropdownOptions()}
              </Dropdown>
            </div>
            <ButtonLink
              id="editColumns"
              onClick={() => onDropdownOpen(AmortDropdowns.EDIT_COLUMNS)}
              text={'Show/Hide Columns'}
              styles="action link-text"
            />
            <div className="div-lock">
              <ButtonLink
                id="exportToExcel"
                onClick={() => exportAndTagManagerHandler()}
                text={'Export to Excel'}
                styles="action link-text"
              />
              {userHasAccessToExportAmort ? null : (
                <img src={lock} alt="header-lock" className="header-lock" />
              )}
            </div>
            <div className="Dropdown">
              <Dropdown
                open={open === AmortDropdowns.EDIT_COLUMNS}
                onClose={onDropdownClose}
              >
                {editColDropOptions}
              </Dropdown>
            </div>
          </div>
        </div>
        {schedule ? (
          schedule.length !== 0 ? (
            <ReactTableFixedColumns
              key={schedule.length}
              columns={tableColumns}
              column={AmortSchedColumnDefaults}
              data={schedule}
              minRows={1}
              defaultPageSize={schedule ? schedule.length : 0}
              showPagination={false}
              sortable={false}
            />
          ) : (
            <div className="h2">{getNoDataMessage()}</div>
          )
        ) : null}
      </div>
    </div>
  );
};
