import { Form, FormikContext } from 'formik';
import React, { FunctionComponent } from 'react';
import { Input } from '../../../common';
import { Button } from '../../../common/buttons';
import {
  fieldHasError,
  shouldDisableSubmit,
} from '../../../common/utilities/FormikHelpers';
import { AuthCard, AuthCardSection } from '../../auth/AuthCard';

export interface IUpdatePassFormState {
  password: string;
  confirmPassword: string;
}

interface IUpdatePassFormProps {
  formProps: FormikContext<IUpdatePassFormState>;
  processing?: boolean;
  errorMessage?: string;
}

const UpdatePassForm: FunctionComponent<IUpdatePassFormProps> = ({
  formProps,
  processing,
  errorMessage,
}) => {
  return (
    <Form noValidate={true}>
      <AuthCard>
        <AuthCardSection>
          <div className="header">
            <h2 className="h2">Please update your password</h2>
            {errorMessage ? <p className="error">{errorMessage}</p> : null}
          </div>
          <Input
            label="New Password"
            required
            name="password"
            type="password"
            error={fieldHasError(formProps, 'password')}
            errorMessage={formProps.errors.password}
            value={formProps.values.password}
            onChange={formProps.handleChange}
          />
          <Input
            label="Confirm New Password"
            required
            name="confirmPassword"
            type="password"
            error={fieldHasError(formProps, 'confirmPassword')}
            errorMessage={formProps.errors.confirmPassword}
            value={formProps.values.confirmPassword}
            onChange={formProps.handleChange}
          />
          <div className="submit">
            <Button
              buttonType="positive"
              buttonText="Update Password"
              type="submit"
              processing={processing}
              block={true}
              disabled={shouldDisableSubmit(formProps) || processing}
            />
          </div>
        </AuthCardSection>
      </AuthCard>
    </Form>
  );
};

export default UpdatePassForm;
